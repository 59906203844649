import React from 'react';
import s from './LiveIcon.module.scss';
import cn from 'classnames';

interface Props {
  size?: React.ReactText;
  className?: string;
}

export const LiveIcon = ({ size, className }: Props) => {
  return (
    <span
      className={cn(s.liveIcon, className)}
      style={{ width: size, height: size }}
    />
  );
};

export default LiveIcon;
