import {
  getTTPUser,
  setSelectedOrganization,
  getSelectedOrganization,
  getActiveApps,
  getClientCredential,
  getEventAuthorization,
  fetchTTPUserByToken,
  fetchCommunity as getCommunity,
  fetchCurrentUserRoles as getCurrentUserRoles,
  saveOrganizationStep as saveOrganizationData,
  subscribeToNewsLetterFFF as subscribeToNSLetterFFF,
  getOrganizationByUen,
  acceptTerms,
  getSectorsTypeahead,
} from './api';

import {
  isTTPTokenExpired,
  logout,
  getTokenFromAuthApp,
  getAuthInfosByPriority,
  setAuthCookie,
  getLoggedAs,
  getLanguage,
} from 'utils';

import * as actions from './actions';
import { actions as paramsActions } from 'store/Params';
import {
  AppThunk,
  AuthCookie,
  Language,
  Organization,
  ResourceData,
  RootState,
} from 'store/types';
import { USER_ROLE, User } from './types';
import { Dispatch } from 'react';
import { Organization as IOrganization } from 'interfaces/Organization';
import { selectAppInfo } from 'store/Params/selectors';

export const setAuthUser = (
  user: AuthCookie,
  language?: Language,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const { navCommunity, authorizations } = getState().auth;

    dispatch(actions.setAuthToken(user.token));
    dispatch(actions.setExpiresIn(user.expiresIn));
    dispatch(actions.setTokenCreatedAt(user.createdAt));
    dispatch(
      actions.fetchAuthUser(
        getTTPUser(user.id, user.token)
          .then(
            (res) => {
              const user: User = res.data.data[0];

              if (user?.id) {
                const lng = language ?? getLanguage(user.language);
                if (lng) {
                  dispatch(paramsActions.setLanguage(lng));
                }
                dispatch(actions.setAuthUser(user));

                dispatch(
                  actions.setAuthLoggedAs(
                    getLoggedAs(authorizations, user.id, navCommunity?.id),
                  ),
                );

                return user;
              } else {
                dispatch(actions.initializeAuth());
                return null;
              }
            },
            (err) => err,
          )
          .catch((err) => {
            dispatch(actions.setAuthLoggedAs(USER_ROLE.ROLE_GUEST));
          }),
      ),
    );
  };
};

export interface LoginParams {
  communityId?: number | string | null;
  language?: Language;
  refreshUser?: boolean;
  scope?: string;
}

export interface UaParams {
  token?: number | string | null;
  userId?: number | string | null;
  withoutHeader?: boolean;
}

export const loginWithToken = (
  token: string,
  params: LoginParams = {},
): AppThunk<void> => {
  const { language: queryLanguage, communityId, refreshUser, scope } = params;

  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = state.auth.user;
    const currentUserId = currentUser?.id ?? 0;
    const lng = queryLanguage ?? getLanguage(localStorage.getItem('lng'));
    const appInfo = selectAppInfo(state);

    // SHOW LOADER (USER FETCHING)
    dispatch(actions.setAuthFetching(true));

    const fetchUser = async (): Promise<User | null> => {
      try {
        const response = await fetchTTPUserByToken(token);
        return response.data.data;
      } catch (e) {
        return null;
      }
    };

    const user = await fetchUser();
    const isOfffcourse = appInfo.id === 'OFFFCOURSE';

    if (user?.id) {
      const { authInfos, expired, isLowSecurity } = getAuthInfosByPriority(
        isOfffcourse,
      );
      const isTokenExpired = !authInfos || expired;
      const needTokenUpdate = isLowSecurity && authInfos?.token !== token;

      if (user.id != currentUserId || isTokenExpired || needTokenUpdate) {
        // AUTHENTICATED WITH DIFFERENT USER

        logout(appInfo); // Remove the old user from locale storage
        dispatch(actions.initializeAuth({ fetching: true }));
        dispatch(actions.setAuthToken(user.token.access_token));
        dispatch(actions.setExpiresIn(String(user.token.expires)));
        dispatch(
          actions.setTokenCreatedAt(
            String(+user.token.expires * 1000 - new Date().getTime()),
          ),
        );
        const language = lng ?? getLanguage(user.language);
        if (language) {
          dispatch(paramsActions.setLanguage(language));
        }
        dispatch(actions.setAuthUser(user));
        dispatch(actions.setAuthLoggedAs(USER_ROLE.ROLE_USER));

        // SET COOKIE ??
        setAuthCookie(user, appInfo, scope);
      } else {
        // AUTHENTICATED WITH THE SAME CONNECTED USER
        if (authInfos && refreshUser) {
          dispatch(setAuthUser(authInfos, lng));
        } else {
          const language = lng ?? getLanguage(user.language);
          if (language) {
            dispatch(paramsActions.setLanguage(language));
          }
        }
      }
    } else {
      // INVALID TOKEN / LOGOUT
      dispatch(actions.initializeAuth());
      if (lng) {
        dispatch(paramsActions.setLanguage(lng));
      }
      logout(appInfo);
    }

    try {
      await dispatch(fetchNavCommunity({ defaultCommunityId: communityId }));
    } catch (e) {}

    // HIDE LOADER (USER FETCHING)
    dispatch(actions.setAuthFetching(false));
  };
};

export const login = (
  params: LoginParams = {},
  uaParams?: UaParams,
): AppThunk<void> => {
  const { language: queryLanguage, communityId, refreshUser } = params;
  const lng = queryLanguage ?? getLanguage(localStorage.getItem('lng'));

  return (dispatch, getState) => {
    const state = getState();
    const appInfo = selectAppInfo(state);
    const isOfffcourse = appInfo.id === 'OFFFCOURSE';

    const {
      authInfos: loginInfo,
      expired: loginExpired,
    } = getAuthInfosByPriority(isOfffcourse);

    let authInfos = loginInfo;
    let expired = loginExpired;
    const tokenCreatedAt = new Date();
    const sevenDaysInSeconds = 7 * 86400;
    const tokenExpiresIn =
      Math.floor(tokenCreatedAt.getTime() / 1000) + sevenDaysInSeconds;

    if (uaParams) {
      authInfos = {
        token: uaParams.token as string,
        id: parseInt(uaParams.userId as string),
        email: '',
        createdAt: Math.floor(tokenCreatedAt.getTime() / 1000).toString(),
        expiresIn: tokenExpiresIn.toString(),
        fullName: '',
      };
      expired = false;
    }

    if (!authInfos || expired) {
      dispatch(actions.initializeAuth());
      if (lng) {
        dispatch(paramsActions.setLanguage(lng));
      }
      logout(appInfo);
      return;
    }

    const auth = state.auth;

    if (
      auth &&
      auth.user?.id &&
      authInfos?.token === auth.token &&
      !refreshUser
    ) {
      if (lng) {
        dispatch(paramsActions.setLanguage(lng));
      }
    } else if (authInfos) {
      dispatch(setAuthUser(authInfos, lng));
      // dispatch(actions.setAuthLoggedAs(USER_ROLE.ROLE_USER));
    } else {
      if (lng) {
        dispatch(paramsActions.setLanguage(lng));
      }
      dispatch(actions.setAuthLoggedAs(USER_ROLE.ROLE_GUEST));
    }
    dispatch(fetchNavCommunity({ defaultCommunityId: communityId }));
  };
};

export const loginApp = (): AppThunk<void> => (dispatch, getState) => {
  const auth = getState().auth;
  if (isTTPTokenExpired(auth.appToken)) {
    return dispatch(
      actions.fetchAppToken(
        getClientCredential().then((resp) => {
          return getTokenFromAuthApp(resp.data.token);
        }),
      ),
    );
  }
};

export const changeCommunityIfExist = (
  organization: Organization | null,
): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const { user, token, authorizations } = state.auth;

  const isExist = user?.memberOfCommunities?.find(
    (com) => +(organization?.id ?? -1) === +com.id,
  );

  if (!(isExist || organization == null)) {
    return;
  }

  dispatch(actions.setNavCommunity(organization));

  dispatch(
    actions.setAuthLoggedAs(
      getLoggedAs(authorizations, user?.id, organization?.id),
    ),
  );

  if (organization && token) {
    setSelectedOrganization({
      token,
      organizationId: organization.id,
    });
  }
};

export const changeNavCommunity = (organizationId: number): AppThunk<void> => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { token, user, authorizations } = state.auth;

  dispatch(
    actions.setAuthLoggedAs(
      getLoggedAs(authorizations, user?.id, organizationId),
    ),
  );

  if (!token) {
    return false;
  }

  setSelectedOrganization({
    token,
    organizationId,
  });
};

export const fetchNavCommunity = (
  params: {
    defaultCommunityId?: number | string | null;
  } = {},
): AppThunk<void> => (dispatch, getState) => {
  const { token, navCommunity, user, authorizations } = getState().auth;

  if (!token || token === '') {
    return null;
  }

  const { defaultCommunityId } = params;
  const defaultCommunity = user?.memberOfCommunities?.find(
    (com) => +(defaultCommunityId ?? 0) === com.id,
  );

  if (defaultCommunity) {
    dispatch(
      actions.setAuthLoggedAs(
        getLoggedAs(authorizations, user?.id, navCommunity?.id),
      ),
    );

    return dispatch(actions.setNavCommunity(defaultCommunity));
  }

  return getSelectedOrganization(token).then((resp) => {
    if (
      resp?.data?.data?.organization &&
      (!navCommunity || navCommunity.id !== resp.data.data.organization.id)
    ) {
      const { organization } = resp.data.data;
      const chosed = user?.memberOfCommunities?.filter(
        (com) => organization.id === com.id,
      );

      dispatch(
        actions.setAuthLoggedAs(
          getLoggedAs(authorizations, user?.id, organization.id),
        ),
      );

      dispatch(
        actions.setNavCommunity(
          user && user.memberOfCommunities && chosed && chosed.length > 0
            ? chosed[0]
            : organization,
        ),
      );
    }
  });
};

export const fetchCommunity = (
  communityId: string | number,
): AppThunk<void> => (dispatch, getState) => {
  const {
    auth: { token: userToken, user, appToken, navCommunity },
  } = getState();
  const token = userToken !== '' ? userToken : appToken.token;

  return getCommunity(token, communityId).then((resp) => {
    return resp.data.data;
  });
};

export const fetchActiveApps = (): AppThunk<void> | null => (
  dispatch,
  getState,
) => {
  const { token, navCommunity } = getState().auth;
  if (!token) {
    return null;
  }
  return dispatch(
    actions.setActiveApps(getActiveApps(token, navCommunity?.id ?? 0)),
  );
};

export const fetchEventAuthorization = (): AppThunk<void> | null => (
  dispatch,
  getState,
) => {
  const { token, user, navCommunity } = getState().auth;
  if (!token || !user || !user.memberOfCommunities) {
    return null;
  }

  dispatch(
    actions.fetchEventAuthorization(
      getEventAuthorization({
        token,
        userId: user.id,
        communityIds: user.memberOfCommunities.map(({ id }) => id),
      })
        .then((res) => {
          const authorizations = res.data?.data;
          dispatch(
            actions.setAuthLoggedAs(
              getLoggedAs(authorizations, user.id, navCommunity?.id),
            ),
          );
          return authorizations;
        })
        .catch(() => {
          dispatch(actions.setAuthLoggedAs(USER_ROLE.ROLE_USER));
        }),
    ),
  );
};

export const fetchCurrentUserRoles = (): AppThunk<void> => (
  dispatch,
  getState,
) => {
  const {
    auth: { token: userToken, appToken },
  } = getState();
  const token = userToken !== '' ? userToken : appToken.token;

  return getCurrentUserRoles(token).then((resp) => {
    const data = resp?.data?.data?.roles;
    if (!data) {
      throw new Error();
    }
    return data;
  });
};

export const fetchOrganizationByUen = (
  uen: string,
  discr: string = '',
): AppThunk<void> => (dispatch, getState) => {
  const {
    auth: { token: userToken, appToken },
  } = getState();
  const token = userToken !== '' ? userToken : appToken.token;

  return getOrganizationByUen(token, uen, discr).then((resp) => {
    return resp.data.data;
  });
};

export const fetchSectorsTypeahead = (
  keyword: string,
  lng: Language,
): AppThunk<void> => (dispatch, getState) => {
  const {
    auth: { token: userToken, appToken },
  } = getState();
  const token = userToken !== '' ? userToken : appToken.token;

  return getSectorsTypeahead(token, keyword, lng).then((resp) => {
    return resp.data.data;
  });
};

/* ------- Onboarding registration ------ */

export const saveOrganizationStep = (
  organizationData: ResourceData<IOrganization>,
) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.saveOrganizationData(
      saveOrganizationData({ token, organizationData }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

export const acceptOrganizationTerms = (orgId: ResourceData<string>) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return acceptTerms({ token, orgId }).then((resp) => {
    return resp.data.data;
  });
};

export const subscribeToNewsLetterFFF = (email: string) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return subscribeToNSLetterFFF({ token, email }).then((resp) => {
    return resp.data.data;
  });
};
