import {
  DELETE_EVENT_FAVORITE,
  EventActionTypes,
  FETCH_EVENT_IDS,
  FETCH_EVENTS,
  FETCH_EVENTS_STATS,
  FETCH_USER_REGISTERED_EVENTS_STATS,
  RESET_EVENT_FAVORITE,
  RESET_EVENTS,
  RESET_EVENTS_FAVORITE,
  SAVE_EVENT_CLASSIFICATION_RANK,
  SAVE_EVENT_FAVORITE,
  SET_EVENT_CLASSIFICATION_RANK,
  SET_EVENT_FAVORITE,
} from './types';
import { paginationActionsCreator } from 'store/Pagination/actions';
import { GenericReducerTypes } from 'store/types';
import {
  addFiltersCreator,
  filterActionsCreator,
  initializeFilterCreator,
} from 'store/Filter/actions';

export const resetEvents = (
  reducerName: GenericReducerTypes = 'LIST',
): EventActionTypes => ({
  type: RESET_EVENTS,
  meta: reducerName,
});

export const fetchEvents = (
  reducerName: GenericReducerTypes,
  resp: any,
): EventActionTypes => ({
  type: FETCH_EVENTS,
  payload: resp,
  meta: reducerName,
});

export const fetchEventIds = (
  reducerName: GenericReducerTypes,
  resp: any,
): EventActionTypes => ({
  type: FETCH_EVENT_IDS,
  payload: resp,
  meta: reducerName,
});

export const fetchEventsStats = (
  reducerName: GenericReducerTypes,
  resp: any,
) => ({
  type: FETCH_EVENTS_STATS,
  payload: resp,
  meta: reducerName,
});

export const fetchUserRegisteredEventsStats = (
  reducerName: GenericReducerTypes,
  resp: any,
) => ({
  type: FETCH_USER_REGISTERED_EVENTS_STATS,
  payload: resp,
  meta: reducerName,
});

export const resetEventsFavorite = (
  reducerName: GenericReducerTypes = 'LIST',
): EventActionTypes => ({
  type: RESET_EVENTS_FAVORITE,
  meta: reducerName,
});

export const resetEventFavorite = (
  reducerName: GenericReducerTypes = 'LIST',
): EventActionTypes => ({
  type: RESET_EVENT_FAVORITE,
  meta: reducerName,
});

export const setEventFavorite = (
  isEventFavoriteRemoved: boolean,
  isEventFavoriteAdded: boolean,
  eventFavoriteTab: string,
  reducerName: GenericReducerTypes = 'LIST',
): EventActionTypes => ({
  type: SET_EVENT_FAVORITE,
  meta: reducerName,
  isEventFavoriteRemoved,
  isEventFavoriteAdded,
  eventFavoriteTab,
});

export const setEventClassificationRank = (
  previousRankOfEvent: number,
  updatedRankEventId: number,
  updatedRank: number,
  reducerName: GenericReducerTypes = 'LIST',
): EventActionTypes => ({
  type: SET_EVENT_CLASSIFICATION_RANK,
  meta: reducerName,
  previousRankOfEvent,
  updatedRankEventId,
  updatedRank,
});

export const deleteEventFavorite = (resp: any): EventActionTypes => ({
  type: DELETE_EVENT_FAVORITE,
  payload: resp,
  meta: 'DELETE_EVENT_FAVORITE',
});

export const saveEventFavorite = (resp: any): EventActionTypes => ({
  type: SAVE_EVENT_FAVORITE,
  payload: resp,
  meta: 'SAVE_EVENT_FAVORITE',
});

export const saveEventClassificationRank = (resp: any): EventActionTypes => ({
  type: SAVE_EVENT_CLASSIFICATION_RANK,
  payload: resp,
  meta: 'SAVE_EVENT_CLASSIFICATION_RANK',
});

// Pagination actions
export const [setEventPage, setEventPageSize] = paginationActionsCreator(
  'LIST',
);
export const [
  setSearchEventPage,
  setSearchEventPageSize,
] = paginationActionsCreator('SEARCH');
export const [
  setPopularEventPage,
  setPopularEventPageSize,
] = paginationActionsCreator('POPULAR');

// Filter actions
export const [addEventFilter, removeEventFilter] = filterActionsCreator(
  'SEARCH',
);

export const addEventFilters = addFiltersCreator('SEARCH');
export const initializeEventFilter = initializeFilterCreator('SEARCH');

export default {
  resetEvents,
  fetchEvents,
  fetchEventIds,
  fetchEventsStats,
  fetchUserRegisteredEventsStats,

  // Pagination
  setEventPage,
  setEventPageSize,
  setPopularEventPage,
  setPopularEventPageSize,

  // Filters
  initializeEventFilter,
  addEventFilter,
  addEventFilters,
  removeEventFilter,

  // add and delete
  saveEventFavorite,
  deleteEventFavorite,

  // set and reset Event Favorite
  setEventFavorite,
  resetEventsFavorite,

  //save and set Event Rank
  saveEventClassificationRank,
  setEventClassificationRank,
};
