import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import s from './ActionButton.module.scss';
import cn from 'classnames';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg';

interface Props {
  name: string;
  link: string;
  isPathName?: boolean;
  theme?: 'green' | 'redOrange' | 'blue';
  isPrimary?: boolean;
  isChecked?: boolean;
  isExternal?: boolean;
  classname?: string;
  onClick?: () => void;
  style?: CSSProperties;
  linkStyle?: CSSProperties;
  svgClassname?: string;
  target?: string;
  rel?: string;
}

const ActionButton = ({
  name,
  link,
  isPrimary,
  isChecked,
  isExternal,
  theme,
  style,
  onClick,
  classname,
  linkStyle,
  target,
  rel,
  isPathName,
  svgClassname,
}: Props) => {
  return (
    <div
      className={cn(classname, s.button_wrapper)}
      style={style}
      onClick={onClick}
    >
      <Link
        className={theme && s[theme]}
        to={isPathName ? { pathname: link } : link}
        style={linkStyle}
        target={target}
        rel={rel}
      >
        {isChecked && (
          <CheckMarkIcon
            width="16"
            height="16"
            fill="#FFF"
            className={cn('m-r-s', svgClassname)}
          />
        )}
        <span>{name}</span>
        {isPrimary && (
          <ArrowRightIcon
            width="16"
            height="16"
            fill="#FFF"
            className={cn('m-l-s', svgClassname)}
          />
        )}
        {isExternal && (
          <ExternalLinkIcon
            width="16"
            height="16"
            fill="#FFF"
            className={cn('m-l-s', svgClassname)}
          />
        )}
      </Link>
    </div>
  );
};

export default ActionButton;
