import { SET_ROOMS, SlotActionTypes, Room, SET_SEARCH } from './types';
import { paginationActionsCreator } from 'store/Pagination/actions';
import { GenericReducerTypes, SLOTS_RESOURCE } from 'store/types';

export const setRooms = (
  rooms: Room[],
  reducerName: GenericReducerTypes,
): SlotActionTypes => ({
  type: SET_ROOMS,
  meta: reducerName,
  rooms,
});

export const setSearch = (search: string): SlotActionTypes => ({
  type: SET_SEARCH,
  meta: SLOTS_RESOURCE,
  search,
});

// Pagination actions
export const [setSlotPage, setSlotPageSize] = paginationActionsCreator('SLOTS');

export default {
  setRooms,
  setSearch,
};
