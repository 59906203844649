export interface Notif {}

export interface NotifsState {
  fetching: boolean;
  fetched: boolean;
  items: Notif[];
  error?: any;
  nbResult: number;
}

export const RESET_NOTIFS = 'RESET_NOTIFS';
export const FETCH_NOTIFS = 'FETCH_NOTIFS';
export const FETCH_NOTIFS_PENDING = 'FETCH_NOTIFS_PENDING';
export const FETCH_NOTIFS_FULFILLED = 'FETCH_NOTIFS_FULFILLED';
export const FETCH_NOTIFS_REJECTED = 'FETCH_NOTIFS_REJECTED';

export interface FetchNotifsAction {
  type:
    | typeof FETCH_NOTIFS
    | typeof FETCH_NOTIFS_PENDING
    | typeof FETCH_NOTIFS_FULFILLED
    | typeof FETCH_NOTIFS_REJECTED;
  payload: any;
}

export interface ResetNotifsAction {
  type: typeof RESET_NOTIFS;
  payload: any;
}

export type NotifsActionTypes = FetchNotifsAction | ResetNotifsAction;
