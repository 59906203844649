import { GenericReducerTypes, RootState } from 'store/types';
import { Filter } from 'services/Filter';
import { Dispatch } from 'redux';
import { getRecourceList } from './api';
import actions from './actions';
import { FetchParams } from './types';

export interface FetchResourceType<Resource> extends FetchParams {
  name: GenericReducerTypes;
  requestUrl: string;
  dispatch: Dispatch;
  getState: () => RootState;
  serializer?: (data: Resource[]) => Resource[];
}

export const fetchResource = <Resource>({
  name,
  requestUrl,
  dispatch,
  getState,
  serializer,
  ...params
}: FetchResourceType<Resource>) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;

  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.fetchResource(
      name,
      getRecourceList({
        requestUrl,
        token,
        ...params,
      }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: serializer ? serializer(data) : data, nbResult };
      }),
    ),
  );
};

export const fetchResourceHelper = (
  requestUrl: string,
  params: FetchParams,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;

  const token = userToken !== '' ? userToken : appToken.token;

  return getRecourceList({
    requestUrl,
    token,
    ...params,
  }).then((res) => {
    const { data, nbResult } = res.data;
    return { data, nbResult };
  });
};

export type FetchResourceThunkParams<Resource = any> = Omit<
  FetchResourceType<Resource>,
  'dispatch' | 'getState'
>;
export const fetchResourceThunk = <Resource = any>(
  params: FetchResourceThunkParams<Resource>,
) => (dispatch: Dispatch, getState: () => RootState) => {
  return fetchResource<Resource>({
    ...params,
    dispatch,
    getState,
  });
};
