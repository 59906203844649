import { useEffect, useRef } from 'react';

export const useOuterClick = (callback: any) => {
  const callbackRef = useRef<any>();
  const innerRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  const handleClick = (e: any) => {
    if (
      innerRef.current &&
      callbackRef.current &&
      !innerRef.current.contains(e.target)
    )
      callbackRef.current(e);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return innerRef;
};
