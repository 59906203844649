import React, { useState } from 'react';
import styles from './EventLayoutHover.module.scss';
import { ReactComponent as HashIcon } from 'assets/icons/hash.svg';

interface Props {
  setShowAddTags: (showAddTags: boolean) => void;
  showAddTags: boolean;
}

const EventLayoutHover = ({ setShowAddTags, showAddTags }: Props) => {
  return (
    <div className={styles.eventLayoutHover}>
      <button
        className={styles.tagsAddButton}
        onClick={() => setShowAddTags(!showAddTags)}
      >
        <HashIcon width="20px" height="20px" />
      </button>
    </div>
  );
};

export default EventLayoutHover;
