import {
  ResourceActionTypes,
  RESET_RESOURCE,
  FETCH_RESOURCE_PENDING,
  FETCH_RESOURCE_FULFILLED,
  FETCH_RESOURCE_REJECTED,
} from '../types';
import { DataResourceState } from './types';

const initialResourceState = () => ({
  fetching: false,
  fetched: false,
  data: null,
  error: null,
  nbResult: 0,
});

export const reducer = <Resource>(
  state: DataResourceState<Resource> = initialResourceState(),
  action: ResourceActionTypes<Resource>,
): DataResourceState<Resource> => {
  switch (action.type) {
    case RESET_RESOURCE: {
      return initialResourceState();
    }
    case FETCH_RESOURCE_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        data: null,
      };
    }
    case FETCH_RESOURCE_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data,
        nbResult: nbResult,
      };
    }
    case FETCH_RESOURCE_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        data: null,
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
