import {
  CurrentPartnerState,
  CurrentPartnerActionTypes,
  RESET_CURRENT_PARTNER,
  FETCH_CURRENT_PARTNER_PENDING,
  FETCH_CURRENT_PARTNER_FULFILLED,
  FETCH_CURRENT_PARTNER_REJECTED,
  SAVE_CURRENT_PARTNER_FULFILLED,
  SET_CURRENT_PARTNER,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};

export const reducer = (
  state: CurrentPartnerState = initialState,
  action: CurrentPartnerActionTypes,
) => {
  switch (action.type) {
    case RESET_CURRENT_PARTNER: {
      return initialState;
    }
    case SET_CURRENT_PARTNER: {
      return {
        ...state,
        items: [action.partner],
      };
    }
    case SAVE_CURRENT_PARTNER_FULFILLED: {
      const { data: partner } = action.payload;
      const oldPartner = state.items[0];

      return {
        ...state,
        items: [
          {
            ...partner,
            extras: partner.extras ?? oldPartner?.extras,
            userPartnerRoles:
              partner.userPartnerRoles ?? oldPartner?.userPartnerRoles,
          },
        ],
      };
    }
    case FETCH_CURRENT_PARTNER_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_CURRENT_PARTNER_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case FETCH_CURRENT_PARTNER_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
