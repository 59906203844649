import { combineReducers } from 'redux';
import { reducer as resource } from 'store/Resource';
import { createNamedWrapperReducer } from 'store/services';
import { ResourceState, ResourceActionTypes } from 'store/Resource/types';
import { CHANNEL_RESOURCE } from 'store/types';
import { Channel } from './types';

const reducer = combineReducers({
  channels: createNamedWrapperReducer<
    ResourceState<Channel>,
    ResourceActionTypes<Channel>
  >(resource, CHANNEL_RESOURCE),
});

export default reducer;
