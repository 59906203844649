export const fetchMedias = (resp) => ({
  type: 'FETCH_MEDIAS',
  payload: resp,
});

export const resetMedias = (resp) => ({
  type: 'RESET_MEDIAS',
});

export const saveMedia = (resp) => ({
  type: 'SAVE_MEDIA',
  payload: resp,
});

export const addMedia = (media) => ({
  type: 'ADD_MEDIA',
  media,
});

export const deleteMedia = (id) => ({
  type: 'DELETE_MEDIA',
  id,
});

export const resetDocs = (resp) => ({
  type: 'RESET_DOCS',
});

export const fetchDocs = (resp) => ({
  type: 'FETCH_DOCS',
  payload: resp,
});

export const fetchFavDocs = (resp) => ({
  type: 'FETCH_FAV_DOCS',
  payload: resp,
});

export const reduceFav = (id) => ({
  type: 'REDUCE_FAV',
  id,
});

export const setDocsSaved = (saved) => ({
  type: 'SET_DOCS_SAVED',
  saved,
});

export const saveDoc = (resp) => ({
  type: 'SAVE_DOC',
  payload: resp,
});

export const deleteDoc = (resp) => ({
  type: 'DELETE_DOC',
  payload: resp,
});

export const addUploadingMedias = (items) => ({
  type: 'ADD_UPLOADING_MEDIAS',
  items,
});

export const setUploadingMedias = (items) => ({
  type: 'SET_UPLOADING_MEDIAS',
  items,
});

//Media viewer
export const closeMediaViewer = (viewer) => ({
  type: 'CLOSE_MEDIA_VIEWER',
  viewer,
});

export const openMediaViewer = (viewer) => ({
  type: 'OPEN_MEDIA_VIEWER',
  viewer,
});

export const setCurrentMedia = (media) => ({
  type: 'SET_CURRENT_MEDIA_VIEWER',
  media: media,
});

//social
export const addLike = (resp) => {
  return {
    type: 'ADD_LIKE',
    payload: resp,
  };
};
export const addDisLike = (resp) => {
  return {
    type: 'ADD_DISLIKE',
    payload: resp,
  };
};
export const addToFavorite = (resp) => {
  return {
    type: 'ADD_TO_FAV',
    payload: resp,
  };
};

//comments
export const resetComments = (resp) => ({
  type: 'RESET_COMMENTS',
});

export const fetchComments = (resp) => ({
  type: 'FETCH_COMMENTS',
  payload: resp,
});

export const saveComment = (resp) => ({
  type: 'SAVE_COMMENT',
  payload: resp,
});

export default {
  saveMedia,
  addMedia,
  deleteMedia,
  addUploadingMedias,
  fetchMedias,
  setUploadingMedias,
  resetDocs,
  fetchDocs,
  fetchFavDocs,
  saveDoc,
  deleteDoc,
  closeMediaViewer,
  openMediaViewer,
  setCurrentMedia,
  resetMedias,
  setDocsSaved,
  reduceFav,
  //social
  addLike,
  addDisLike,
  addToFavorite,
  //comments
  resetComments,
  fetchComments,
  saveComment,
};
