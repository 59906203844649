import * as api from './api';
import actions from './actions';
import { Dispatch } from 'react';
import { selectToken } from 'store/Auth';
import { RootState, TTPSort } from 'store/types';
import { Filter } from 'services/Filter';

export const fetchUserOrders = (
  userId: number,
  filters?: Filter[],
  sort?: TTPSort[],
) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const token = selectToken(state);

  return dispatch(
    actions.fetchOrders(
      api.getUserOrders({ token, userId, filters, sort }).then((res) => {
        const { data, nbResult } = res.data;
        return { data, nbResult };
      }),
    ),
  );
};

export const cancelOrder = (guestId: number, orderId: number) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const token = selectToken(state);

  return dispatch(
    actions.cancelOrder(
      api.cancelOrder({ token, guestId, orderId }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};
