import { map } from 'lodash';
import {
  SubscriptionState,
  SubscriptionActionTypes,
  RESET_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTION_DATA_PENDING,
  FETCH_SUBSCRIPTION_DATA_FULFILLED,
  FETCH_SUBSCRIPTION_DATA_REJECTED,
  SAVE_SUBSCRIPTION_DATA_FULFILLED,
  SAVE_SUBSCRIPTION_DATA_REJECTED,
  SAVE_SUBSCRIPTION_DATA_PENDING,
  CHECK_SUBSCRIPTION_COUPON_FULFILLED,
  DELETE_SUBSCRIPTION_ADDRESS_FULFILLED,
} from './types';

const initialState = {
  fetching: false,
  processing: false,
  fetched: false,
  items: [],
  coupons: [],
  error: null,
  nbResult: 0,
};

export const reducer = (
  state: SubscriptionState = initialState,
  action: SubscriptionActionTypes,
): SubscriptionState => {
  switch (action.type) {
    case RESET_SUBSCRIPTIONS: {
      return initialState;
    }
    case FETCH_SUBSCRIPTION_DATA_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_SUBSCRIPTION_DATA_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        coupons: data?.[0]?.couponsDetails
          ? map(data?.[0]?.couponsDetails, (coupon) => coupon)
          : [],
        nbResult: nbResult,
      };
    }
    case SAVE_SUBSCRIPTION_DATA_PENDING: {
      return {
        ...state,
        processing: true,
      };
    }
    case SAVE_SUBSCRIPTION_DATA_REJECTED: {
      return {
        ...state,
        processing: false,
      };
    }
    case SAVE_SUBSCRIPTION_DATA_FULFILLED: {
      const { data } = action.payload;

      if (data) {
        return {
          ...state,
          processing: false,
          error: null,
          items: [data],
          coupons: data?.couponsDetails
            ? map(data?.couponsDetails, (coupon) => coupon)
            : [],
          nbResult: 1,
        };
      }

      return {
        ...state,
        processing: false,
      };
    }
    case CHECK_SUBSCRIPTION_COUPON_FULFILLED: {
      const { data } = action.payload;

      if (data) {
        const coupons = [...state.coupons].filter(
          (item) => item.code != data.code,
        );

        return {
          ...state,
          coupons: [...coupons, data],
        };
      }

      return state;
    }
    case DELETE_SUBSCRIPTION_ADDRESS_FULFILLED: {
      const { data } = action.payload;
      const guest = state.items[0];

      if (guest) {
        return {
          ...state,
          items: [{ ...guest, invoicingData: data ?? [] }],
        };
      }

      return state;
    }
    case FETCH_SUBSCRIPTION_DATA_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        coupons: [],
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
