import {
  PUSH_SOURCE_TOKEN,
  CLEAR_SOURCES_TOKENS,
  SourceTokenActionTypes,
} from './types';

export const pushSourceToken = (
  sourceName: string,
  sourceToken: any,
): SourceTokenActionTypes => ({
  type: PUSH_SOURCE_TOKEN,
  sourceName,
  sourceToken,
});

export const clearSourcesTokens = (): SourceTokenActionTypes => ({
  type: CLEAR_SOURCES_TOKENS,
});

export default { pushSourceToken, clearSourcesTokens };
