import { SetShowFaqAction, SET_SHOW_FAQ } from './types';

export const setShowFaq = (showFaq: any): SetShowFaqAction => {
  return {
    type: SET_SHOW_FAQ,
    showFaq,
  };
};

export default { setShowFaq };
