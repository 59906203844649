export interface Address {
  id: number;
  // user: id; ??
  fullName: string;
  street: string;
  number: string;
  address2: string;
  city: string;
  province: string;
  zipCode: string;
  country: string;
  type: ADDRESS_TYPE;
}

export enum ADDRESS_TYPE {
  PRIVATE = 'PRIVATE',
  PROFESSIONAL = 'PROFESSIONAL',
  PERSONAL = 'PERSONAL',
  BILLING = 'BILLING',
}
