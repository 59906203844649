import { connect } from 'react-redux';
import { RootState } from 'store/types';
import StepCollaboratorsData from './StepCollaboratorsData';
import {
  fetchCollaborators,
  saveCollaborators,
  deleteCollaborator,
  updateCollaborator,
  uploadCollaborators,
  getCollaboratorByEmail,
} from 'store/Collaborators/thunks';
import { addCollaborator } from 'store/Collaborators/actions';

const mapStateToProps = (state: RootState) => ({
  collaborators: state.collaborators.collaborators[0],
  fetched: state.collaborators.fetched,
  fetching: state.collaborators.fetching,
  error: state.collaborators.error,
  language: state.params.lng,
  saved: state.collaborators.saved,
  saving: state.collaborators.saving,
  deleted: state.collaborators.deleted,
  deleting: state.collaborators.deleting,
  userFetched: state.collaborators.userFetched,
  userFetching: state.collaborators.userFetching,
  data: state.collaborators.data,
  newCollab: state.collaborators.newCollab,
});

export default connect(mapStateToProps, {
  fetchCollaborators,
  saveCollaborators,
  deleteCollaborator,
  addCollaborator,
  updateCollaborator,
  uploadCollaborators,
  getCollaboratorByEmail,
})(StepCollaboratorsData);
