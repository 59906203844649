import { AnyAction } from 'redux';
import { Filter } from 'services/Filter';
import { GenericReducerTypes } from 'store/types';

export const initializeFilterCreator = (
  reducerName: GenericReducerTypes,
) => (): AnyAction => ({
  type: `INITIALIZE_${reducerName}_FILTER`,
  meta: reducerName,
});

export const addFilterCreator = (reducerName: GenericReducerTypes) => (
  filter: Filter,
): AnyAction => ({
  type: `ADD_${reducerName}_FILTER`,
  meta: reducerName,
  filter,
});

export const addFiltersCreator = (reducerName: GenericReducerTypes) => (
  filters: Filter[],
): AnyAction => ({
  type: `ADD_${reducerName}_FILTERS`,
  meta: reducerName,
  filters,
});

export const removeFilterCreator = (reducerName: GenericReducerTypes) => (
  filter: Filter,
): AnyAction => ({
  type: `REMOVE_${reducerName}_FILTER`,
  meta: reducerName,
  filter,
});

export const filterActionsCreator = (reducerName: GenericReducerTypes) => [
  addFilterCreator(reducerName),
  removeFilterCreator(reducerName),
];
