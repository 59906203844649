import {
  AUTOLOGIN_PARAM_KEYS,
  HTTP_SCHEME,
  LANGUAGES,
  TTP_LOGO_EVENT_URL,
  TTP_LOGO_OFFFCOURSE_URL,
  TTP_PORTAL_EVENT_URL,
  TTP_PORTAL_OFFF_COURSE_URL,
} from 'config';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { AppInfoData } from 'store/Params/AppInfo';
import { Language } from 'store/types';
import { getUrl, parseJson } from './common';
import CryptoJS from 'crypto-js';
import { UaParams } from '../store/Auth/thunks';

export const getLocalStorageRedirectUrl = () =>
  localStorage.getItem('portal_event_redirect_url');

export const setLocalStorageRedirectUrl = (redirectUrl?: string) => {
  if (redirectUrl != null) {
    localStorage.setItem('portal_event_redirect_url', redirectUrl);
  }
  if (redirectUrl == null) {
    localStorage.removeItem('portal_event_redirect_url');
  }
};

/**
 * Doesn't support nested objects
 *
 * @param object
 */
export const getFormData = (object: Record<string, any>) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export const getApiPagination = (page: number, pageSize: number) => ({
  limit: pageSize,
  start: (page - 1) * pageSize,
});

export const getScrollTop = (element: Element | null) => {
  if (element) return element.scrollTop;
  return (
    window.pageYOffset ??
    document.documentElement.scrollTop ??
    document.body.scrollTop ??
    0
  );
};

export const getHeight = (element: Element | null) =>
  element?.clientHeight ??
  (element as HTMLElement)?.offsetHeight ??
  element?.scrollHeight ??
  0;

interface LinkData {
  key: string;
  value: string | number;
}
export const bindLinkData = (
  link: string,
  data: LinkData[],
  params?: string,
) => {
  let url = link;
  data.forEach(({ key, value }) => {
    url = url.replace(key, String(value));
  });
  return url.concat(params ? `?${params}` : '');
};

export const appendParamsToLink = (
  link: string | undefined,
  params: Array<[string, string]>,
) => {
  if (!link || isEmpty(link)) {
    return link;
  }

  try {
    const url = new URL(link);
    params.forEach((param) => {
      url.searchParams.set(param[0], param[1]);
    });
    return url.toString();
  } catch (err) {
    return link;
  }
};

export const appendStringParamsToLink = (
  link: string | undefined,
  params: string,
) => {
  if (!link || isEmpty(link)) {
    return link;
  }

  let url = link ?? '';
  url += (url.split('?')[1] ? '&' : '?') + params;
  return url;
};

export const getCroppedImageUrl = (
  imageUrl: string,
  width: number = 0,
  height: number = 0,
  optimize: boolean = true,
) => {
  if (isEmpty(imageUrl) || (!width && !height)) {
    return '';
  }

  // Todo use webp when it supported by landa
  const extension = optimize ? 'jpg' : imageUrl.split('.').pop();
  const options = [];

  if (width) {
    options.push(`w${width}`);
  }

  if (height) {
    options.push(`h${height}`);
  }

  return `${imageUrl}/${options.join('-')}-noEnlarge.${extension}`;
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0 || isNaN(bytes)) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Resize and maintain the aspect ratio
 * @param srcWidth
 * @param srcHeight
 * @param width
 * @param height
 */
export const getResizedDimensions = (
  srcWidth: number,
  srcHeight: number,
  width?: number,
  height?: number,
) => {
  const scaleFactor = width
    ? width / srcWidth
    : height
    ? height / srcHeight
    : 0;

  return {
    width: width ?? srcWidth * scaleFactor,
    height: height ?? srcHeight * scaleFactor,
  };
};

export const parseBoolean = (
  value: string | null | number | boolean | undefined,
) => {
  if (value === null || value === undefined) {
    return false;
  }

  switch (value) {
    case 'false':
      return false;
    case '0':
      return false;
    case 0:
      return false;
    case 'true':
      return true;
    case '1':
      return true;
    case 1:
      return true;
    default:
      return value === true;
  }
};

/**
 * @param timestamp in miliseconds
 * @param expiryTime  in seconds
 */
export const isDataExpired = (timestamp: number, expiryTime: number) => {
  return new Date().getTime() > (timestamp ?? 0) + expiryTime * 1000;
};

export const isPastByXTime = (
  date: string,
  time: number,
  unit: 'hours' | 'minutes' | 'seconds',
) => {
  const gmtCurrentdate = new Date().toLocaleString('EN', {
    timeZone: 'UTC',
  });
  const expectedTime = moment(date).add(time, unit);
  return moment(gmtCurrentdate).isAfter(expectedTime);
};

export const isPastDate = (date: string) => {
  if (isEmpty(date)) {
    return true;
  }

  return moment().isAfter(moment(date));
};

export const isLive = (startDateTime: string, endDateTime: string) => {
  if (isEmpty(startDateTime) || isEmpty(endDateTime)) {
    return false;
  }

  return moment().isBetween(moment(startDateTime), moment(endDateTime));
};

export const getLanguage = (lng?: string | null): Language | undefined => {
  const language: Language | undefined = (lng ?? '').trim() as Language;
  return LANGUAGES.includes(language) ? language : undefined;
};

export function setLocalStorageWithExpiry(
  key: string,
  value: any,
  ttl: number = 0,
) {
  const now = new Date();

  /**
   * `item` is an object which contains the original value
   *  as well as the time when it's supposed to expire
   */
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = parseJson(itemStr);

  if (typeof item !== 'object' || !item.value || !item.expiry) {
    return null;
  }

  const now = new Date();
  if (now.getTime() > item.expiry) {
    /** If the item is expired, delete the item from storage */
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
export const appendUrlScheme = (url: string) => {
  if (isEmpty(url)) {
    return url;
  }

  if (!/^https?:\/\//i.test(url)) {
    return `${HTTP_SCHEME}//${url}`;
  }

  return url;
};

export interface LinkType {
  url: string;
  text: string;
}

export const prepareAppInfo = (currentHostname: string) => {
  const offfCourseUrl = getUrl(TTP_PORTAL_OFFF_COURSE_URL);
  const portalEventUrl = getUrl(TTP_PORTAL_EVENT_URL);

  switch (currentHostname) {
    case offfCourseUrl.hostname:
      return {
        id: 'OFFFCOURSE',
        name: 'oFFFcourse',
        url: offfCourseUrl.origin,
        logo: { url: TTP_LOGO_OFFFCOURSE_URL, hasName: true },
        domain: 'offfcourse.be',
      } as AppInfoData;
    case portalEventUrl.hostname:
      return {
        id: 'EVENT',
        name: 'Event',
        url: portalEventUrl.origin,
        logo: { url: TTP_LOGO_EVENT_URL },
        domain: 'tamtam.pro',
      } as AppInfoData;
  }
};

/**
 * Deletes multiple query search params.
 */
export const deleteQueryParams = (
  query: URLSearchParams,
  paramKeys: string[],
) => paramKeys.forEach((key) => query.delete(key));

/**
 * Filter autologin query params and return new URLSearchParams that contains only original params
 * passed in gotoUrl.
 */
export const filterAutologinQuery = (query: URLSearchParams) => {
  const queryParams = decodeURIComponent(query.toString());
  const filteredQueryParams = queryParams.split('/autologin')[0];
  const filteredQuery = new URLSearchParams(filteredQueryParams);
  deleteQueryParams(filteredQuery, AUTOLOGIN_PARAM_KEYS);

  return filteredQuery;
};

/**
 * Gets a the value of a search param, and then deletes it.
 */
export const getAndPopQueryParam = (query: URLSearchParams, name: string) => {
  const queryParam = query.get(name);
  query.delete(name);

  return queryParam;
};

export const decodeQueryParams = (params: string, urlHashKey: string) => {
  // encryptMethod: AES-256-CBC
  const aesNumber = 256;
  let encryptMethodLength = aesNumber;

  const json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(params)),
  );

  const salt = CryptoJS.enc.Hex.parse(json.salt);
  const iv = CryptoJS.enc.Hex.parse(json.iv);

  const encrypted = json.ciphertext; // no need to base64 decode.

  let iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }
  encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  let hashKey = CryptoJS.PBKDF2(urlHashKey, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  let decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  const originalText = decrypted.toString(CryptoJS.enc.Utf8);

  const tabQueryParams = originalText.split(',');
  let queryToken = '';
  let userId = '';
  let withoutHeader = false;
  tabQueryParams.map((item) => {
    const tmp = item.split('=');
    if (tmp.length > 0) {
      if (tmp[0] === 'token') {
        queryToken = tmp[1];
      } else if (tmp[0] === 'userId') {
        userId = tmp[1];
      } else if (tmp[0] === 'without_header') {
        withoutHeader = true;
      }
    }
  });
  return { token: queryToken, userId, withoutHeader } as UaParams;
};
