export interface FlashMessageState {
  openFlash: boolean;
  message: string;
  status: string;
}

export type FlashMessageStatus = 'SUCCESS' | 'ERROR' | 'WARNING';

export const DISPLAY_FLASH_MESSAGE = 'DISPLAY_FLASH_MESSAGE';
export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE';

export interface DisplayFlashMessageAction {
  type: typeof DISPLAY_FLASH_MESSAGE;
  status: FlashMessageStatus;
  message: string;
}

export interface HideFlashMessageAction {
  type: typeof HIDE_FLASH_MESSAGE;
}

export type FlashMessageActionTypes =
  | HideFlashMessageAction
  | DisplayFlashMessageAction;
