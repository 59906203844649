import Filter from './Filter';
import { SingleValueOperator } from './types';

export type SingleValueType = string | number;

class SingleValueFilter<T extends SingleValueType> extends Filter<T> {
  constructor(
    protected _property: string,
    protected _operator: SingleValueOperator,
    private _value: T,
  ) {
    super(_property, _operator);
  }

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
  }

  serialize = () => ({
    property: this.property,
    operator: this.operator,
    value: this.value,
  });

  isEqual = ({ property, operator, value }: SingleValueFilter<T>) =>
    this.property === property &&
    this.operator === operator &&
    this.value === value;

  clone = (): SingleValueFilter<T> => {
    return new SingleValueFilter<T>(
      this.property,
      this.operator as SingleValueOperator,
      this.value,
    );
  };
}

export default SingleValueFilter;
