import { WithPaginationAndFilterState } from 'store/types';
export interface NameWrappedAction {
  meta: string;
}
export const RESET_PACKS = 'RESET_PACKS';
export const FETCH_PACKS = 'FETCH_PACKS';
export const FETCH_PACKS_PENDING = 'FETCH_PACKS_PENDING';
export const FETCH_PACKS_FULFILLED = 'FETCH_PACKS_FULFILLED';
export const FETCH_PACKS_REJECTED = 'FETCH_PACKS_REJECTED';

export const FETCH_SUMMARY = 'FETCH_SUMMARY';
export const FETCH_SUMMARY_PENDING = 'FETCH_SUMMARY_PENDING';
export const FETCH_SUMMARY_FULFILLED = 'FETCH_SUMMARY_FULFILLED';
export const FETCH_SUMMARY_REJECTED = 'FETCH_SUMMARY_REJECTED';

export const FETCH_ALL_PACKS = 'FETCH_ALL_PACKS';
export const FETCH_ALL_PACKS_PENDING = 'FETCH_ALL_PACKS_PENDING';
export const FETCH_ALL_PACKS_FULFILLED = 'FETCH_ALL_PACKS_FULFILLED';
export const FETCH_ALL_PACKS_REJECTED = 'FETCH_ALL_PACKS_REJECTED';

export const SAVE_PLACE = 'SAVE_PLACE';
export const SAVE_PLACE_PENDING = 'SAVE_PLACE_PENDING';
export const SAVE_PLACE_FULFILLED = 'SAVE_PLACE_FULFILLED';
export const SAVE_PLACE_REJECTED = 'SAVE_PLACE_REJECTED';

export const CANCEL_PLACE = 'CANCEL_PLACE';
export const CANCEL_PLACE_PENDING = 'CANCEL_PLACE_PENDING';
export const CANCEL_PLACE_FULFILLED = 'CANCEL_PLACE_FULFILLED';
export const CANCEL_PLACE_REJECTED = 'CANCEL_PLACE_REJECTED';

export const CHOOSE_PACK = 'CHOOSE_PACK';
export const CHOOSE_PACK_PENDING = 'CHOOSE_PACK_PENDING';
export const CHOOSE_PACK_FULFILLED = 'CHOOSE_PACK_FULFILLED';
export const CHOOSE_PACK_REJECTED = 'CHOOSE_PACK_REJECTED';

export const SET_FIDUCIARY = 'SET_FIDUCIARY';
export const SET_FIDUCIARY_PENDING = 'SET_FIDUCIARY_PENDING';
export const SET_FIDUCIARY_FULFILLED = 'SET_FIDUCIARY_FULFILLED';
export const SET_FIDUCIARY_REJECTED = 'SET_FIDUCIARY_REJECTED';

export interface ResetPacksAction extends NameWrappedAction {
  type: typeof RESET_PACKS;
}

export interface FetchPackSummaryAction extends NameWrappedAction {
  type:
    | typeof FETCH_SUMMARY
    | typeof FETCH_SUMMARY_PENDING
    | typeof FETCH_SUMMARY_FULFILLED
    | typeof FETCH_SUMMARY_REJECTED;
  payload: any;
}

export interface FetchPacksAction extends NameWrappedAction {
  type:
    | typeof FETCH_PACKS
    | typeof FETCH_PACKS_PENDING
    | typeof FETCH_PACKS_FULFILLED
    | typeof FETCH_PACKS_REJECTED;
  payload: any;
}
export interface FetchAllPacksAction extends NameWrappedAction {
  type:
    | typeof FETCH_ALL_PACKS
    | typeof FETCH_ALL_PACKS_PENDING
    | typeof FETCH_ALL_PACKS_FULFILLED
    | typeof FETCH_ALL_PACKS_REJECTED;
  payload: any;
}
export interface SavePlaceAction {
  type:
    | typeof SAVE_PLACE
    | typeof SAVE_PLACE_PENDING
    | typeof SAVE_PLACE_FULFILLED
    | typeof SAVE_PLACE_REJECTED;
  payload: any;
}
export interface CancelPlaceAction {
  type:
    | typeof CANCEL_PLACE
    | typeof CANCEL_PLACE_PENDING
    | typeof CANCEL_PLACE_FULFILLED
    | typeof CANCEL_PLACE_REJECTED;
  payload: any;
}

export interface ChoosePackAction extends NameWrappedAction {
  type:
    | typeof CHOOSE_PACK
    | typeof CHOOSE_PACK_PENDING
    | typeof CHOOSE_PACK_FULFILLED
    | typeof CHOOSE_PACK_REJECTED;
  payload: any;
}

export interface SetFiduciaryAction extends NameWrappedAction {
  type:
    | typeof SET_FIDUCIARY
    | typeof SET_FIDUCIARY_PENDING
    | typeof SET_FIDUCIARY_FULFILLED
    | typeof SET_FIDUCIARY_REJECTED;
  payload: any;
}

export interface PacksState extends WithPaginationAndFilterState {
  fetching: boolean;
  fetched: boolean;
  saving: boolean;
  canceling: boolean;
  saved: boolean;
  items: any[];
  packList: any[];
  error: any;
  nbResult: number;
  packSummary: any[];
  fetchingSummary: boolean;
  fetchedSummary: boolean;
}

export type PacksActionTypes =
  | FetchPacksAction
  | ResetPacksAction
  | SavePlaceAction
  | CancelPlaceAction
  | FetchAllPacksAction
  | ChoosePackAction
  | SetFiduciaryAction
  | FetchPackSummaryAction;
