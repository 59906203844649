import {
  FlashMessageActionTypes,
  DISPLAY_FLASH_MESSAGE,
  HIDE_FLASH_MESSAGE,
  FlashMessageStatus,
} from './types';

export const hideFlashMessage = (): FlashMessageActionTypes => ({
  type: HIDE_FLASH_MESSAGE,
});

export const displayFlashMessage = (
  status: FlashMessageStatus = 'SUCCESS',
  message: string = '',
): FlashMessageActionTypes => ({
  type: DISPLAY_FLASH_MESSAGE,
  status,
  message,
});

export default { hideFlashMessage, displayFlashMessage };
