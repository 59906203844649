import React, { memo } from 'react';
import styles from './TTPForm.module.scss';
import { Checkbox, CheckboxProps } from 'antd';
import { ErrorMessage, useField } from 'formik';
import { LayoutTheme } from 'components/Layout/services';
import classNames from 'classnames';

interface Props extends CheckboxProps {
  label: React.ReactNode;
  theme?: LayoutTheme;
}

export const TTPCheckBox = ({
  label,
  theme = '',
  className,
  ...props
}: Props) => {
  return (
    <Checkbox
      className={classNames(className, styles.checkbox, styles[theme])}
      {...props}
    >
      {label}
    </Checkbox>
  );
};

export const TTPCheckBoxField = ({
  ...props
}: Props & { name: string; errorsClassName?: string }) => {
  const [field, meta, helpers] = useField({
    name: props.name,
    type: 'checkbox',
  });

  const isChecked = field.value === props.value;
  return (
    <TTPCheckBox
      {...field}
      {...props}
      checked={isChecked}
      onChange={() => helpers.setValue(isChecked ? '' : props.value)}
    >
      <ErrorMessage
        name={props.name}
        component="p"
        className={props.errorsClassName}
      />
    </TTPCheckBox>
  );
};

export default memo(TTPCheckBox);
