import axios from 'axios';
import { escapeSpecialChars, getApiPagination } from 'utils';
import { FetchParams } from './types';

interface GetResourceType extends FetchParams {
  requestUrl: string;
  token: string;
}

export const getRecourceList = ({
  requestUrl,
  token,
  filters = [],
  sort = [],
  fields = [],
  page = 1,
  pageSize = 8,
  nolimit,
  queryParams = {},
  communityId,
  isCommunityPartner,
  isHome,
}: GetResourceType) => {
  const filter = filters.map((filter) => filter.serialize());
  const sortBy = [...sort];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: escapeSpecialChars(JSON.stringify(filter)),
      sort: JSON.stringify(sortBy),
      ...(nolimit ? { nolimit: 1 } : getApiPagination(page, pageSize)),
      ...(fields.length > 0 ? { fields: fields.join(',') } : {}),
      ...queryParams,
      communityId,
      isCommunityPartner,
      isHome,
    },
  });
};
