import React, { useState } from 'react';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import classnames from 'classnames';
import styles from './ModalCancelRegistration.module.scss';
import CloseModal from '../CloseModal';
import t from 'i18n';
import { Event } from 'store/Events/types';
import { getByLanguage } from 'utils';
import { useDispatch } from 'react-redux';
import { declineRegistration } from 'store/Guests/thunks';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import { useLanguage } from 'hooks/useLanguage';
import Markdown from 'markdown-to-jsx';

type ModalCancelRegistration = {
  event: Event;
};

interface Props {
  modal: ModalState<ModalCancelRegistration>;
  onCloseModal: () => void;
}

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: '15px',
    top: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    width: '566px',
    height: 'fit-content',
  },
};

export default function ModalCancelRegistration({
  modal,
  onCloseModal,
}: Props) {
  const {
    data: { event },
  } = modal;
  const dispatch = useDispatch();
  const language = useLanguage();
  const name = getByLanguage(event, 'name', language);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCancel = () => {
    if (isProcessing) {
      return;
    }
    onCloseModal();
  };

  const handleSubmit = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    dispatch(declineRegistration(event.id) as any)
      .then(() => {
        toast.success(
          <strong>{t('unregisteredForEventSuccessfully')}</strong>,
          {
            autoClose: 10000,
          },
        );
      })
      .finally(() => {
        setIsProcessing(false);
        onCloseModal();
      });
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={false}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div className={classnames(styles.eventFreeRegistrationModal)}>
        <CloseModal onClose={onCloseModal} />
        <div>
          <h3>{t('inscription.desinscription_title')}</h3>
          <Markdown className={styles.descriptionMessage}>
            {t('inscription.desinscription_message').replace('{name}', name)}
          </Markdown>
        </div>
        <div className={styles.actions}>
          <button
            onClick={handleCancel}
            className={classnames(styles.action, styles.cancel)}
          >
            {t('Keep')}
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className={classnames(
              styles.action,
              isProcessing && styles.disabled,
            )}
            disabled={isProcessing}
          >
            {isProcessing && <ClipLoader size={14} color="#fff" loading />}
            {t('Cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
}
