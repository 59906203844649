import { Address } from 'interfaces/Address';
import { Dispatch } from 'react';
import { saveResource } from 'store/Resource/actions';
import { RootState } from 'store/types';
import * as api from './api';
import actions from './actions';

export const saveAddress = (data: Partial<Address>) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    saveResource(
      'SHIPPING_ADDRESS_RESOURCE',
      api.saveAddress({ token, userId: user?.id, data }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

export const validateOrganizationNumber = (uen: string) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.validateOrganizationNumber(
      api
        .validateOrganizationNumber({
          token,
          uen,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};
