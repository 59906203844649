import { cloneDeep } from 'lodash';
import {
  FormActionTypes,
  FormState,
  SYNC_FORM_VALUES,
  RESET_FORM_VALUES,
} from './types';

const initialState = {};

const reducer = (state: FormState = initialState, action: FormActionTypes) => {
  switch (action.type) {
    case SYNC_FORM_VALUES: {
      const { values, name } = action;
      return { ...state, [name]: cloneDeep(values) };
    }
    case RESET_FORM_VALUES: {
      const { name } = action;

      const newState = { ...state };
      delete newState[name];

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
