import { Event } from 'store/Events/types';

export const getRankedEventsOptions = (
  events: Event[],
  event: Event,
  maxSlideAllowed: number,
) => {
  const filteredEvents = events.filter((iteratedEvent) => {
    if (iteratedEvent.eventClassificationRank)
      return (
        event.eventClassificationRank != iteratedEvent.eventClassificationRank
      );
  });
  const ranksOptions = filteredEvents.map((iteratedEvent) => ({
    value: iteratedEvent.eventClassificationRank,
    label: iteratedEvent.eventClassificationRank,
  }));
  if (
    !event.eventClassificationRank &&
    filteredEvents.length < maxSlideAllowed
  ) {
    ranksOptions.push({
      value: filteredEvents.length + 1,
      label: filteredEvents.length + 1,
    });
  }
  return ranksOptions;
};
