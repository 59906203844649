import React, { useEffect, useState } from 'react';
import styles from './GuestAddress.module.scss';
import cn from 'classnames';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import AddAddress from './AddAddress';
import { Address } from './Address';
import { Address as AddressType } from 'store/Guests/Invoicing/types';
import _ from 'i18n';
import { LayoutTheme } from 'components/Layout/services';
import { useDispatch } from 'react-redux';
import { setTTPDialogCustomData } from 'store/Params/actions';
import { DIALOG_TYPES } from 'store/Params/TTPDialog';
import { deleteAddress } from 'store/Guests/thunks';
import {
  GuestAddress as GuestAddressType,
  GuestState,
} from 'store/Guests/types';
import { getGuestAddressFromInvoiceAddress, onError } from 'utils';
import moment from 'moment';
interface Props {
  invoicings: AddressType[];
  billingSignature?: string;
  billingCompanyNumber?: string;
  defaultUen?: string;
  defaultOpenForm?: boolean;
  theme?: LayoutTheme;
  titleClassName?: string;
  title?: string;
  horizontalInputs?: boolean;
  guestsResource: GuestState;
  onSelectAddress?: (invoice: GuestAddressType | null, reset?: boolean) => void;
  postDeleteAddress?: (invoice: GuestAddressType) => void;
  onOpenForm?: (isOpened: boolean) => void;
  updateAddress: (signature: string, data: GuestAddressType) => any;
  getGuestData?: () => void;
}

export const GuestAddress = ({
  theme,
  invoicings,
  billingSignature,
  billingCompanyNumber,
  defaultUen,
  defaultOpenForm,
  titleClassName,
  title,
  horizontalInputs,
  guestsResource: {
    addressUpdated: addressUpdated,
    addressUpdating: addressUpdating,
  },
  onSelectAddress,
  onOpenForm,
  updateAddress,
  getGuestData,
}: Props) => {
  const dispatch = useDispatch();
  const [manualAddresses, setManualAddresses] = useState<GuestAddressType[]>(
    [],
  );
  const [showForm, toggleShowForm] = useState(defaultOpenForm);
  const [addressToUpdate, setAddressToUpdate] = useState<
    AddressType | GuestAddressType | undefined
  >();
  const allAddresses = [...invoicings, ...manualAddresses];
  const showSwitcher = allAddresses.length > 0;

  useEffect(() => {
    if (!showForm) {
      setAddressToUpdate(undefined);
    }
  }, [showForm, addressUpdating, addressUpdated]);

  useEffect(() => {
    if (!addressUpdating && addressUpdated) {
      handleToggleForm(false);
      setAddressToUpdate(undefined);
    }
  }, [addressUpdating, addressUpdated]);

  const handleToggleForm = (value: boolean) => {
    toggleShowForm(value);
    onOpenForm?.(value);
  };

  const handleDelete = (address: AddressType) => {
    if (address?.source === 'guest') {
      return onError(
        undefined,
        _('billing_address_cannot_be_modified_or_deleted'),
      );
    }
    dispatch(
      setTTPDialogCustomData({
        approvedAsyncAction: () => {
          if (address.isGuestAddress) {
            const result = dispatch(
              deleteAddress(address.signature, true, address.guestId),
            );
            getGuestData && getGuestData();
            return result;
          }
          const result = dispatch(deleteAddress(address.signature));
          getGuestData && getGuestData();
          return result;
        },
        message: _('Do you really want to delete this address?'),
        title: _('Confirm delete'),
        type: DIALOG_TYPES.DELETE,
      }),
    );
  };

  const handleUpdate = (
    address: AddressType | GuestAddressType | undefined,
  ) => {
    if ((address as AddressType)?.source === 'guest') {
      return onError(
        undefined,
        _('billing_address_cannot_be_modified_or_deleted'),
      );
    }
    toggleShowForm(true);
    setAddressToUpdate(address);
  };

  const handleDeleteManualAddresses = (address: GuestAddressType) => {
    setManualAddresses(
      manualAddresses.filter(
        (add) => add.billingSignature !== address.billingSignature,
      ),
    );
  };
  const handleUpdateManualAddresses = (address: GuestAddressType) => {
    toggleShowForm(true);
    setAddressToUpdate(address);
  };
  const handleOnSubmitUpdate = (data: any) => {
    if ((addressToUpdate as AddressType)?.signature) {
      updateAddress((addressToUpdate as AddressType)?.signature, data);
    } else {
      const date = moment().format();
      const newAddress: GuestAddressType = {
        ...data,
        billingSignature: date,
      };
      setManualAddresses(
        manualAddresses.map((add) => {
          if (
            add.billingSignature ==
            (addressToUpdate as GuestAddressType).billingSignature
          ) {
            return { add, ...newAddress };
          }
          return add;
        }),
      );
      handleToggleForm(false);
    }
  };

  const isShowForm = showForm || allAddresses.length === 0;

  return (
    <div className={styles.guestAddress}>
      <div className={styles.header}>
        <div className={cn(styles.title, titleClassName)}>
          {title ?? _('Billing data')} :
        </div>
        {showSwitcher && (
          <span
            className={styles.navTo}
            onClick={() => {
              // onSelectAddress?.(null, showForm);
              handleToggleForm(!showForm);
            }}
          >
            <span>
              {isShowForm ? _('Existing addresses') : _('New address')}
            </span>
            <ArrowRightIcon className="m-l-xs" width="12px" fill="#6D7F92" />
          </span>
        )}
      </div>
      <div className="m-t-s">
        {isShowForm ? (
          <AddAddress
            theme={theme}
            horizontalInputs={horizontalInputs}
            defaultUen={defaultUen ?? ''}
            onSubmit={(data) => {
              const date = moment().format();
              const newAddress: GuestAddressType = {
                ...data,
                billingSignature: date,
              };

              setManualAddresses((address) => [newAddress, ...address]);
              onSelectAddress?.(newAddress);
              handleToggleForm(false);
            }}
            onCancel={() => handleToggleForm(false)}
            addressToUpdate={addressToUpdate}
            onSubmitUpdate={handleOnSubmitUpdate}
          />
        ) : (
          [
            manualAddresses.map((address, index) => (
              <div className="m-b-s" key={`address-${index}`}>
                <Address
                  theme={theme}
                  data={{ type: 'GUEST', address }}
                  onClick={() => onSelectAddress?.(address)}
                  isSelected={billingSignature === address.billingSignature}
                  onDelete={() => handleDeleteManualAddresses(address)}
                  onUpdate={() => handleUpdateManualAddresses(address)}
                />
              </div>
            )),
            invoicings.map((address, index) => (
              <div className="m-b-s" key={`${address.signature}-${index}`}>
                <Address
                  theme={theme}
                  data={{ type: 'INVOICING', address }}
                  onClick={() =>
                    onSelectAddress?.(
                      getGuestAddressFromInvoiceAddress(address),
                    )
                  }
                  isSelected={billingSignature === address.signature}
                  onDelete={() => handleDelete(address)}
                  onUpdate={() => handleUpdate(address)}
                />
              </div>
            )),
          ]
        )}
      </div>
    </div>
  );
};

export default GuestAddress;
