import { PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';
import {
  AuthState,
  AuthActionTypes,
  SET_AUTH,
  SET_EXPIRES_IN,
  SET_AUTH_LOGGED_AS_POST,
  SET_AUTH_LOGGED_AS,
  SET_AUTH_TOKEN,
  FETCH_AUTH_USER_PENDING,
  FETCH_AUTH_USER_FULFILLED,
  FETCH_AUTH_USER_REJECTED,
  SET_AUTH_USER,
  SET_TOKEN_CREATED_AT,
  SET_ACTIVE_APPS_PENDING,
  SET_ACTIVE_APPS_FULFILLED,
  SET_ACTIVE_APPS_REJECTED,
  SET_NAV_COMMUNITY,
  INITIALIZE_AUTH,
  FETCH_APP_TOKEN_PENDING,
  FETCH_APP_TOKEN_REJECTED,
  FETCH_APP_TOKEN_FULFILLED,
  USER_ROLE,
  FETCH_EVENT_AUTHORIZATION_FULFILLED,
  FETCH_EVENT_AUTHORIZATION_PENDING,
  SET_AUTH_FETCHING,
  SET_USER_PREMIUM_STATUS,
  SET_USER_PREMIUM_SHARED,
} from './types';

const initialState = {
  authorizations: [],
  authorizationFetched: false,
  authorizationFetching: false,
  fetching: false,
  fetched: false,
  token: '',
  expiresIn: null,
  createdAt: null,
  user: null,
  error: null,
  loggedAs: USER_ROLE.ROLE_GUEST,
  loggedAsPost: null,
  navCommunity: null,
  currentPortal: 'WORK',
  activeApps: {
    fetching: false,
    fetched: false,
    result: null,
    error: null,
  },
  appTokenFetching: false,
  appToken: {
    token: '',
    expiresIn: undefined,
    createdAt: undefined,
    scope: '',
    tokenType: '',
  },
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS.NONE,
  userPremiumShared: false,
};

const reducer = (state: AuthState = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case INITIALIZE_AUTH: {
      const newState = action.initialState;
      return { ...initialState, ...newState, appToken: state.appToken };
    }
    case SET_AUTH: {
      return action.auth == null ? { ...initialState } : { ...action.auth };
    }
    case SET_AUTH_USER: {
      return { ...state, user: action.user };
    }
    case SET_AUTH_FETCHING: {
      return { ...state, fetching: action.fetching };
    }
    case SET_AUTH_TOKEN: {
      return { ...state, token: action.token };
    }
    case FETCH_APP_TOKEN_PENDING: {
      return { ...state, appTokenFetching: true };
    }
    case FETCH_APP_TOKEN_REJECTED: {
      return { ...state, appTokenFetching: false };
    }
    case FETCH_APP_TOKEN_FULFILLED: {
      return { ...state, appToken: action.payload, appTokenFetching: false };
    }
    case SET_NAV_COMMUNITY: {
      return { ...state, navCommunity: action.community };
    }
    case SET_EXPIRES_IN: {
      return { ...state, expiresIn: action.expiresIn };
    }
    case SET_TOKEN_CREATED_AT: {
      return { ...state, createdAt: action.createdAt };
    }
    case SET_AUTH_LOGGED_AS: {
      return { ...state, loggedAs: action.loggedAs };
    }
    case SET_AUTH_LOGGED_AS_POST: {
      return { ...state, loggedAsPost: action.loggedAsPost };
    }
    case FETCH_AUTH_USER_PENDING: {
      return { ...state, fetching: true };
    }
    case FETCH_AUTH_USER_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: action.payload,
      };
    }
    case FETCH_AUTH_USER_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    }
    case SET_ACTIVE_APPS_PENDING: {
      return {
        ...state,
        activeApps: { ...state.activeApps, fetching: true },
      };
    }
    case SET_ACTIVE_APPS_FULFILLED: {
      return {
        ...state,
        activeApps: {
          ...state.activeApps,
          fetching: false,
          fetched: true,
          result: action.payload.data.data,
        },
      };
    }
    case SET_ACTIVE_APPS_REJECTED: {
      return {
        ...state,
        activeApps: {
          ...state.activeApps,
          fetching: false,
          error: action.payload,
        },
      };
    }
    case FETCH_EVENT_AUTHORIZATION_PENDING: {
      return { ...state, authorizationFetching: true };
    }
    case FETCH_EVENT_AUTHORIZATION_FULFILLED: {
      return {
        ...state,
        authorizations: action.payload,
        authorizationFetched: true,
      };
    }
    case SET_USER_PREMIUM_STATUS: {
      return { ...state, userPremiumStatus: action.userPremiumStatus };
    }
    case SET_USER_PREMIUM_SHARED: {
      return { ...state, userPremiumShared: action.userPremiumShared };
    }
    default:
      return state;
  }
};

export default reducer;
