import _ from 'i18n';
import { Organization } from 'interfaces/Organization';
import { upperFirst } from 'lodash';

export type OrganizationFormValues = ReturnType<typeof getFormInitialValues>;
export type SectorTypeahead = {
  id: number;
  title: string;
};

export const getFormInitialValues = (orgData: Organization | null) => {
  return {
    uen: orgData?.uen ?? '',
    name: orgData?.name ?? '',
    abbreviation: orgData?.abbreviation ?? '',
    url: orgData?.website ?? '',
    address: orgData?.address1 ?? '',
    zipCode: orgData?.zipCode ?? '',
    country: orgData?.country ?? '',
    legalForm: orgData?.legalForm ?? '',
    sector: orgData?.sector.id ?? null,
    vat: orgData?.tva ?? '',
    languages: orgData?.languagePreferences ?? [],
    terms: orgData?.hasAgreedTerms ? '1' : '0',
  };
};

export const getSectorOptions = (sectors: SectorTypeahead[]) => {
  const filteredSectors = sectors.filter(
    (sector, index, sectorsArray) =>
      sectorsArray.findIndex(
        (s) => s.title.toLowerCase() === sector.title.toLowerCase(),
      ) === index,
  );

  return filteredSectors.map(({ id, title }) => ({
    value: id,
    label: upperFirst(title.toLowerCase()),
  }));
};

export const vatOptions = [
  { value: 'VAT_SYSTEM_VAT', label: _('VAT_SYSTEM_VAT') },
  { value: 'VAT_SYSTEM_PARTIAL_VAT', label: _('VAT_SYSTEM_PARTIAL_VAT') },
  { value: 'VAT_SYSTEM_MIXED_VAT', label: _('VAT_SYSTEM_MIXED_VAT') },
  { value: 'VAT_SYSTEM_NO_VAT', label: _('VAT_SYSTEM_NO_VAT') },
];

export const languageOptions = [
  { value: 'fr', label: _('French') },
  { value: 'nl', label: _('Dutch') },
  { value: 'en', label: _('English') },
];

export const countriesOptions = [
  { value: 'BE', label: _('Belgium') },
  { value: 'FR', label: _('France') },
  { value: 'NL', label: _('Netherlands') },
  { value: 'GB', label: _('United Kingdom') },
  { value: 'LU', label: _('Luxembourg') },
  { value: 'DE', label: _('Germany') },
  { value: 'IE', label: _('Ireland') },
  { value: 'IT', label: _('Italy') },
  { value: 'DK', label: _('Denmark') },
  { value: 'FI', label: _('Finland') },
  { value: 'ES', label: _('Spain') },
  { value: 'GI', label: _('Gibraltar') },
  { value: 'LI', label: _('Liechtenstein') },
  { value: 'LU', label: _('Luxembourg') },
  { value: 'MC', label: _('Monaco') },
  { value: 'NO', label: _('Norway') },
  { value: 'PT', label: _('Portugal') },
  { value: 'SM', label: _('San Marino') },
  { value: 'SE', label: _('Sweden') },
  { value: 'CH', label: _('Switzerland') },
  { value: 'VA', label: _('Vatican City') },
  { value: 'AD', label: _('Andorra') },
  { value: 'AT', label: _('Austria') },
];

export const legalFormOptions = [
  { value: 'SCRIS', label: _('legalForms.SCRIS') },
  { value: 'SCISR', label: _('legalForms.SCISR') },
  { value: 'SCRL', label: _('legalForms.SCRL') },
  { value: 'SCRLP', label: _('legalForms.SCRLP') },
  { value: 'SC', label: _('legalForms.SC') },
  { value: 'SNC', label: _('legalForms.SNC') },
  { value: 'S.COM', label: _('legalForms.S.COM') },
  { value: 'SCA', label: _('legalForms.SCA') },
  { value: 'SA', label: _('legalForms.SA') },
  { value: 'SPRL', label: _('legalForms.SPRL') },
  { value: 'SRL', label: _('legalForms.SRL') },
  { value: 'SAGR', label: _('legalForms.SAGR') },
  { value: 'ES', label: _('legalForms.ES') },
  { value: 'BI', label: _('legalForms.BI') },
  { value: 'RESP.TVA', label: _('legalForms.RESP.TVA') },
  { value: 'NC', label: _('legalForms.NC') },
];
