import { SetSubMenuDirectionAction, SET_SUB_MENU_DIRECTION } from './types';

export const setSubMenuDirection = (
  subMenuDirection: SetSubMenuDirectionAction['subMenuDirection'],
): SetSubMenuDirectionAction => ({
  type: SET_SUB_MENU_DIRECTION,
  subMenuDirection,
});

export default { setSubMenuDirection };
