import { useEffect, useState } from 'react';
import { calculateTimeDifference } from '../utils';

export const useCountdown = (
  startDateTime: string,
  counterTimeout: number,
  endDateTime?: string,
) => {
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeDifference(startDateTime, endDateTime),
  );
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter <= counterTimeout) {
      setTimeout(() => {
        setTimeLeft(calculateTimeDifference(startDateTime, endDateTime));
        setCounter(counter + 1);
      }, 1000);
    }
  });

  return { ...timeLeft, counter };
};
