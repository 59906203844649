import { WithPaginationAndFilterState } from 'store/types';

export interface NameWrappedAction {
  meta: string;
}

export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_PENDING = 'FETCH_TAGS_PENDING';
export const FETCH_TAGS_FULFILLED = 'FETCH_TAGS_FULFILLED';
export const FETCH_TAGS_REJECTED = 'FETCH_TAGS_REJECTED';
export const FETCH_EVENT_WITH_TAGS = 'FETCH_EVENT_WITH_TAGS';
export const FETCH_EVENT_WITH_TAGS_PENDING = 'FETCH_EVENT_WITH_TAGS_PENDING';
export const FETCH_EVENT_WITH_TAGS_FULFILLED =
  'FETCH_EVENT_WITH_TAGS_FULFILLED';
export const FETCH_EVENT_WITH_TAGS_REJECTED = 'FETCH_EVENT_WITH_TAGS_REJECTED';
export const UPDATE_EVENT_TAGS = 'UPDATE_EVENT_TAGS';
export const UPDATE_EVENT_TAGS_PENDING = 'UPDATE_EVENT_TAGS_PENDING';
export const UPDATE_EVENT_TAGS_FULFILLED = 'UPDATE_EVENT_TAGS_FULFILLED';
export const UPDATE_EVENT_TAGS_REJECTED = 'UPDATE_EVENT_TAGS_REJECTED';

export interface FetchTagsAction extends NameWrappedAction {
  type:
    | typeof FETCH_TAGS
    | typeof FETCH_TAGS_PENDING
    | typeof FETCH_TAGS_FULFILLED
    | typeof FETCH_TAGS_REJECTED;
  payload: any;
}

export interface FetchEventWithTagsAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENT_WITH_TAGS
    | typeof FETCH_EVENT_WITH_TAGS_PENDING
    | typeof FETCH_EVENT_WITH_TAGS_FULFILLED
    | typeof FETCH_EVENT_WITH_TAGS_REJECTED;
  payload: any;
}

export interface UpdateEventTagsAction extends NameWrappedAction {
  type:
    | typeof UPDATE_EVENT_TAGS
    | typeof UPDATE_EVENT_TAGS_PENDING
    | typeof UPDATE_EVENT_TAGS_FULFILLED
    | typeof UPDATE_EVENT_TAGS_REJECTED;
  payload: any;
}

export interface TagsState extends WithPaginationAndFilterState {
  fetching: boolean;
  fetched: boolean;
  eventFetching: boolean;
  eventFetched: boolean;
  items: TagType[];
  eventWithTags: EventWithTags[];
  error: any;
  nbResult: number;
}

export type TagsActionTypes =
  | FetchTagsAction
  | FetchEventWithTagsAction
  | UpdateEventTagsAction;

export interface TagType {
  nameFr: string;
  nameEn: string;
  nameNl: string;
  sanitizedNameFr: string;
  counter: number;
  blogCounter: number;
  eventCounter: number;
  mediaCounter: number;
  forumCounter: number;
  webtoolCounter: number;
  isAbbreviation: boolean;
  isUniversal: boolean;
  isSuperTag: boolean;
  isSynonym: boolean;
  isHandled: boolean;
  status: string;
  id: number;
  idInFlux: number;
  createdAt: string;
  synonymsCount: number;
}
export interface TagOption {
  id: number;
}
export interface EventWithTags {
  id: number;
  updatedAt: string;
  createdAt: string;
  startDateTime: string;
  endDateTime: string;
  eventDate: string;
  client: number;
  tag: TagType[];
}
