import axios from 'axios';
import { TTP_API_URL } from 'config';
import { Filter, MultiValueFilter } from 'services/Filter';
import { TTPSort } from 'store/types';
import { getApiPagination } from 'utils';

export const SLOT_FIELDS = [
  'id',
  'createdAt',
  'startDateTime',
  'nameFr',
  'nameNl',
  'nameEn',
  'descriptionFr',
  'descriptionNl',
  'descriptionEn',
  'pictureUrlFr',
  'pictureUrlNl',
  'pictureUrlEn',
  'webinarUrlFr',
  'webinarUrlNl',
  'webinarUrlEn',
  'room',
  'event',
  'partners_full',
  'speakers_full',
  'languages',
  'placesNumber',
  'subscribersNumber',
  'status',
  'webinarReplayVideoFr',
  'webinarReplayVideoNl',
  'webinarReplayVideoEn',
  'webinarVideoEn',
  'webinarVideoFr',
  'webinarVideoNl',
  'isPostPlayVideo',
  'zoomMeetingUrl',
  'files',
  'isWebinar',
  'roomName',
  'type',
  'youtubeVideoLink',
];

export const getSlots = ({
  token,
  filters = [],
  sort = [],
  ids = [],
  page,
  pageSize,
  nolimit,
}: {
  token: string;
  sort?: TTPSort[];
  filters?: Filter[];
  ids: number[];
  page: number;
  pageSize: number;
  nolimit?: boolean;
}) => {
  const requestUrl = `${TTP_API_URL}/event/slot`;
  const fields = SLOT_FIELDS;
  const filter = [
    ...filters,
    new MultiValueFilter('event.id', 'in', ids),
  ].map((filter) => filter.serialize());

  const sortBy = [...sort, { property: 'startDateTime', dir: 'DESC' }];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sortBy),
      fields: fields.join(','),
      ...(nolimit ? { nolimit: 1 } : getApiPagination(page, pageSize)),
    },
  });
};
