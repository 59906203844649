import { QuestionsActionTypes, FETCH_QUESTIONS } from './types';

export const fetchQuestions = (resp: any): { payload: any; type: string } => ({
  type: FETCH_QUESTIONS,
  payload: resp,
});

export default {
  fetchQuestions,
};
