import { WithPaginationAndFilterState } from 'store/types';

export interface NameWrappedAction {
  meta: string;
}

export const FETCH_EVENTS_CATEGORIES = 'FETCH_EVENTS_CATEGORIES';
export const FETCH_EVENTS_CATEGORIES_PENDING =
  'FETCH_EVENTS_CATEGORIES_PENDING';
export const FETCH_EVENTS_CATEGORIES_FULFILLED =
  'FETCH_EVENTS_CATEGORIES_FULFILLED';
export const FETCH_EVENTS_CATEGORIES_REJECTED =
  'FETCH_EVENTS_CATEGORIES_REJECTED';

export interface FetchEventsCategoriesAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENTS_CATEGORIES
    | typeof FETCH_EVENTS_CATEGORIES_PENDING
    | typeof FETCH_EVENTS_CATEGORIES_FULFILLED
    | typeof FETCH_EVENTS_CATEGORIES_REJECTED;
  payload: any;
}

export interface EventsCategoriesState extends WithPaginationAndFilterState {
  fetching: boolean;
  fetched: boolean;
  items: any[];
  error: any;
  nbResult: number;
}

export type EventsCategoriesActionTypes = FetchEventsCategoriesAction;
