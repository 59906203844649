import moment from 'moment';
import removeMd from 'remove-markdown';
import t from 'i18n';

export const DEFAULT_CALENDAR_NAME = 'TTP Calendar';
export const DEFAULT_FILE_NAME = 'ttp_calendar.ics';
export const DEFAULT_BUTTON_NAME = 'Add to calendar';

export type CalendarType = {
  service: 'Google' | 'Outlook' | 'Office365' | 'Yahoo' | 'iCal';
  label?: string;
};

export type CalendarEventType = {
  id: number;
  title: string;
  description: string;
  location: string;
  startTime: string;
  endTime: string;
  timezone?: string;
};

export enum CalendarSizes {
  S = 1,
  M = 2,
}

/**
 * Builds the url to add calendar for the right calendar service. As a fallback,
 * the ics file is provided for download.
 * @param calendar calendar service
 * @param isMulti single or multiple events
 * @param calendarData calendar event data or ics file s3 url
 * @param calName calendar name
 * @returns url to add the calendar events
 */
export const buildCalendarUrl = (
  calendar: CalendarType,
  isMulti: boolean | undefined,
  calendarData: CalendarEventType | string,
  calName: string,
) => {
  switch (calendar.service) {
    case 'Google':
      return getGoogleUrl(isMulti, calendarData);
    case 'Yahoo':
      return getYahooUrl(isMulti, calendarData);
    case 'Outlook':
      return getOutlookUrl(isMulti, calendarData, calName, 'live');
    case 'Office365':
      return getOutlookUrl(isMulti, calendarData, calName, 'office');
    default:
      return getICalUrl(isMulti, calendarData);
  }
};

/**
 * Builds google url to add events in its calendar. (single and multi event)
 * @param isMulti single or multiple events
 * @param calendarData calendar event data or ics file s3 url
 * @returns url to add the calendar events in google calendar
 */
export const getGoogleUrl = (
  isMulti: boolean | undefined,
  calendarData: CalendarEventType | string,
) => {
  const BASE_URL = 'https://calendar.google.com/calendar';

  if (isMulti) {
    let icsFileUrl = calendarData as string;
    icsFileUrl = icsFileUrl?.replace(/^https:\/\//i, 'http://');
    return BASE_URL + '?cid=' + icsFileUrl + '&pli=1';
  }

  const event = calendarData as CalendarEventType;
  const { title, location, description, timezone } = event;
  const startDate = formatTime(event.startTime);
  const endDate = formatTime(event.endTime);

  const args = {
    action: 'TEMPLATE',
    text: title,
    dates: `${startDate}/${endDate}`,
    location: location,
    details: removeMd(description, { stripListLeaders: false }),
    ctz: timezone || '',
  };

  const urlSearchParams = new URLSearchParams(args);
  return BASE_URL + '/event?' + urlSearchParams.toString();
};

/**
 * Builds Yahoo url to add events in its calendar. (single event)
 * @param isMulti single or multiple events
 * @param calendarData calendar event data or ics file s3 url
 * @returns url to add the calendar events in Yahoo calendar
 */
export const getYahooUrl = (
  isMulti: boolean | undefined,
  calendarData: CalendarEventType | string,
) => {
  const BASE_URL = 'https://calendar.yahoo.com';

  if (isMulti) {
    return getICalUrl(true, calendarData);
  }

  const event = calendarData as CalendarEventType;
  const { title, location, description, timezone } = event;
  const startDate = formatTime(event.startTime);
  const endDate = formatTime(event.endTime);

  const args = {
    v: '60',
    view: 'd',
    type: '20',
    title: title,
    st: startDate,
    et: endDate,
    in_loc: location,
    desc: removeMd(description, { stripListLeaders: false }),
    ctz: timezone || '',
  };

  const urlSearchParams = new URLSearchParams(args);
  return BASE_URL + '?' + urlSearchParams.toString();
};

/**
 * Builds Outlook (office & live) url to add events in its calendar. (single and multi events)
 * @param isMulti single or multiple events
 * @param calendarData calendar event data or ics file s3 url
 * @param calName calendar name
 * @returns url to add the calendar events in Outlook calendar
 */
export const getOutlookUrl = (
  isMulti: boolean | undefined,
  calendarData: CalendarEventType | string,
  calName: string,
  accountType: 'live' | 'office',
) => {
  const BASE_URL =
    accountType === 'office'
      ? 'https://outlook.office.com/calendar/0'
      : 'https://outlook.live.com/calendar/0';

  if (isMulti) {
    const icsFileUrl = calendarData as string;
    return BASE_URL + '/addfromweb?url=' + icsFileUrl + `&name=${calName}`;
  }

  const event = calendarData as CalendarEventType;
  const { title, location, description } = event;
  const startDate = moment.utc(event.startTime).format();
  const endDate = moment.utc(event.endTime).format();

  const args = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: startDate,
    enddt: endDate,
    subject: title,
    location: location,
    body: removeMd(description, { stripListLeaders: false }),
  };

  const urlSearchParams = new URLSearchParams(args);
  return BASE_URL + '/deeplink/compose?' + urlSearchParams.toString();
};

/**
 * Builds ics file for download for the fallback cases. (single and multi events)
 * @param isMulti single or multiple events
 * @param calendarData calendar event data or ics file s3 url
 * @returns download url of the ics file
 */
export const getICalUrl = (
  isMulti: boolean | undefined,
  calendarData: CalendarEventType | string,
) => {
  if (isMulti) {
    const icsFileUrl = calendarData as string;
    return icsFileUrl;
  }

  const event = calendarData as CalendarEventType;
  const icsContent = createICS([event]).join('\n');
  const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
  const url = window.URL.createObjectURL(blob);

  return url;
};

/**
 * Creates the ics file content for a set of events
 * @param events calendar events
 * @param calName calendar name
 * @returns ics content as array of the content rows (for flexibility)
 */
export const createICS = (
  events: CalendarEventType[],
  calName: string = DEFAULT_CALENDAR_NAME,
) => {
  let icsCalEvents: string[] = [];

  events.map((event) => {
    const descTxt = removeMd(event.description, { stripListLeaders: false });
    icsCalEvents = [
      ...icsCalEvents,
      'BEGIN:VEVENT',
      `UID:${event.id}@tamtam.pro`,
      `DTSTAMP:${formatTime(moment().toString())}`,
      `DTSTART:${formatTime(event.startTime)}`,
      `SUMMARY:${event.title}`,
      `DTEND:${formatTime(event.endTime)}`,
      `DESCRIPTION:${descTxt}`,
      `LOCATION:${event.location}`,
      'END:VEVENT',
    ];
  });

  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//pro.tamtam.cal//NONSGML v1.0//EN',
    'METHOD:PUBLISH',
    'CALSCALE:GREGORIAN',
    `NAME:${calName}`,
    `X-WR-CALNAME:${calName}`,
    ...icsCalEvents,
    'END:VCALENDAR',
  ];
};

/**
 * [HELPER]
 * Converts date into a format that is supported by different calendar services
 * @param date date to convert
 * @returns converted date
 */
const formatTime = (date: string) => {
  const formattedDate = moment(date).utc().format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
};

/**
 * [HELPER]
 * Validate the filename with the desired file extension
 * @param fileName
 * @param extension
 * @returns the validated filename
 */
export const validateFileName = (
  fileName: string,
  extension: string,
): string => {
  if (!fileName || fileName.length === 0) {
    return '';
  }

  const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
  return `${fileName.replace(' ', '_')}${
    ext !== extension ? `.${extension}` : ''
  }`;
};

/**
 * [HELPER]
 * Get the default calendar services
 * @param forMultiEvents
 * @returns CalendarType[]
 */
export const getDefaultCalendars = (
  forMultiEvents?: boolean,
): CalendarType[] => {
  const commonCalendars: CalendarType[] = [
    { service: 'Google' },
    { service: 'Outlook' },
    { service: 'Office365', label: 'Office 365' },
    { service: 'iCal', label: `${t('Calendar')} (ICS)` },
  ];

  return forMultiEvents
    ? commonCalendars
    : [...commonCalendars, { service: 'Yahoo' }];
};

/**
 * [HELPER]
 * Get the calendar button style based on the given size
 * @param size
 * @returns style object
 */
export const getCalendarButtonStyles = (size: CalendarSizes) => {
  switch (size) {
    case CalendarSizes.S:
      return {
        fontSize: '12px',
        loaderSize: 15,
        dropdownSize: '9px',
        btnHeight: '32px',
      };
    case CalendarSizes.M:
      return {
        fontSize: '16px',
        loaderSize: 20,
        dropdownSize: '12px',
        btnHeight: '42px',
      };
  }
};
