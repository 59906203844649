import React, { memo, useState } from 'react';
import styles from './TTPForm.module.scss';
import classNames from 'classnames';
import { TTPInputProps } from './types';
import { isEmpty } from 'lodash';
import { ErrorMessage, Field, useField } from 'formik';
import { ReactComponent as HelpIcon } from 'assets/icons/alert-circle.svg';
import ReactTooltip from 'react-tooltip';
import InputMask, {
  BeforeMaskedStateChangeStates,
  InputState,
} from 'react-input-mask';

interface Props extends TTPInputProps {
  tooltip?: string;
  tooltipIcon?: React.ReactNode;
  isInputMask?: boolean;
  mask?: string;
  beforeMaskStateChange?: (states: BeforeMaskedStateChangeStates) => InputState;
}

export const TTPInput = ({
  customizeError,
  theme = '',
  name,
  label,
  className,
  wrapperClassName,
  required,
  value,
  isHorizontal,
  labelClassName,
  tooltip,
  tooltipIcon,
  children,
  hasError,
  isInputMask,
  mask,
  beforeMaskStateChange,
  ...inputProps
}: Props) => {
  const renderTooltip = (message: string) => {
    return (
      <span className="flex-container align-middle m-l-xs">
        {tooltipIcon ?? (
          <span data-tip data-for={`tooltip-${name}`}>
            <HelpIcon
              height="14px"
              width="14px"
              fill="#B2BCC6"
              className="m-l-xs"
            />
          </span>
        )}
        <ReactTooltip
          id={`tooltip-${name}`}
          effect="float"
          type="light"
          className={classNames('portal-tooltip', styles.tooltip)}
          html={true}
        >
          {message}
        </ReactTooltip>
      </span>
    );
  };
  return (
    <div
      className={classNames(styles.ttpField, wrapperClassName, styles[theme])}
    >
      <div className={classNames(isHorizontal && styles.hGroup)}>
        {label && (
          <label
            htmlFor={`input-${name}`}
            className={classNames(
              styles.inputLabel,
              labelClassName,
              'flex-container',
            )}
          >
            {label}
            {required && <span className={styles.required}>*</span>}
            {tooltip && renderTooltip(tooltip)}
          </label>
        )}
        <div className={styles.inputWrapper}>
          {isInputMask ? (
            <InputMask
              id={`input-${name}`}
              beforeMaskedStateChange={beforeMaskStateChange}
              name={name}
              className={classNames(
                styles.field,
                hasError && styles.hasError,
                className,
                !isEmpty(value) ? styles.notEmpty : '',
              )}
              value={value}
              mask={mask ?? ''}
              alwaysShowMask={true}
              {...inputProps}
            />
          ) : (
            <input
              name={name}
              className={classNames(
                styles.field,
                hasError && styles.hasError,
                className,
                !isEmpty(value) ? styles.notEmpty : '',
              )}
              value={value}
              {...inputProps}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

// export const TTPInputField = (props: Props) => <Field as={TTPInput} {...props} />;

export const TTPInputField = ({ ...props }: Props) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <TTPInput
      {...field}
      {...props}
      hasError={!isEmpty(meta.error) && meta.touched}
    >
      {props.customizeError ? (
        ''
      ) : (
        <ErrorMessage name={props.name} component="p" />
      )}
    </TTPInput>
  );
};

export default memo(TTPInput);
