import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideFlashMessage } from 'store/Params/FlashMessage/actions';

import FlashMessageComponent from './FlashMessageComponent';

class FlashMessage extends Component {
  componentDidUpdate() {
    const { hideFlashMessage } = this.props;
    setTimeout(() => {
      hideFlashMessage();
    }, 5000);
  }
  render() {
    return <FlashMessageComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  message: state.params.flashMessage.message,
  status: state.params.flashMessage.status,
  openFlash: state.params.flashMessage.openFlash,
});

export default connect(mapStateToProps, { hideFlashMessage })(FlashMessage);
