import React, { memo } from 'react';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import UpComingEventPopup from 'Tools/UpComingEventPopup/UpComingEventPopup.wrap';
import { Event } from 'store/Events/types';

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: 0,
    top: '50%',
    bottom: 'unset',
    maxHeight: '90%',
    borderRadius: '20px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '100%',
    maxWidth: '852px',
  },
};

interface Props {
  modal: ModalState<Event>;
  onCloseModal: () => void;
}

export default memo(function ModalUpComingEventPopup({
  modal,
  onCloseModal,
}: Props) {
  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
    >
      <div
        className="rmodal"
        style={{
          overflowY: 'auto',
          maxHeight: '90vh',
        }}
      >
        <CloseModal onClose={onCloseModal} />
        <div>
          <UpComingEventPopup
            event={modal.data}
            isRegisteredToPremium
            {...modal.data['user-registered']}
          />
        </div>
      </div>
    </Modal>
  );
});
