import {
  CollaboratorsActionTypes,
  FETCH_COLLABORATORS,
  SAVE_COLLABORATORS,
  DELETE_COLLABORATOR,
  ADD_COLLABORATOR,
  FETCH_USER,
  FETCH_USERS,
  UPLOAD_COLLABORATORS,
} from './types';

export const fetchCollaborators = (
  resp: any,
): { payload: any; type: string } => ({
  type: FETCH_COLLABORATORS,
  payload: resp,
});

export const fetchUsers = (resp: any): { payload: any; type: string } => ({
  type: FETCH_USERS,
  payload: resp,
});

export const fetchUser = (resp: any): { payload: any; type: string } => ({
  type: FETCH_USER,
  payload: resp,
});

export const saveCollaborators = (
  resp: any,
): { payload: any; type: string } => ({
  type: SAVE_COLLABORATORS,
  payload: resp,
});

export const uploadCollaborators = (
  resp: any,
): { payload: any; type: string } => ({
  type: UPLOAD_COLLABORATORS,
  payload: resp,
});

export const deleteCollaborator = (
  resp: any,
): { payload: any; type: string } => ({
  type: DELETE_COLLABORATOR,
  payload: resp,
});

export const addCollaborator = (
  collaborators: any,
): CollaboratorsActionTypes => ({
  type: ADD_COLLABORATOR,
  payload: collaborators,
});

export default {
  fetchCollaborators,
  saveCollaborators,
  deleteCollaborator,
  addCollaborator,
  fetchUser,
  fetchUsers,
  uploadCollaborators,
};
