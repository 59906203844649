import {
  UpComingEventPopupActionTypes,
  RESET_POPUP_COUNTER,
  SET_POPUP_COUNTER,
} from './types';

export const resetPopupCounter = (): UpComingEventPopupActionTypes => ({
  type: RESET_POPUP_COUNTER,
});

export const setPopupCounter = (
  popupShowCounter: number,
): UpComingEventPopupActionTypes => ({
  type: SET_POPUP_COUNTER,
  popupShowCounter,
});

export default {
  resetPopupCounter,
  setPopupCounter,
};
