export interface SocialState {
  likeAdding: boolean;
  likeAdded: boolean;
  dislikeAdding: boolean;
  dislikeAdded: boolean;
  favAdding: boolean;
  favAdded: boolean;
  result: any;
  error: any;
}

export const ADD_LIKE = 'ADD_LIKE';
export const ADD_LIKE_PENDING = 'ADD_LIKE_PENDING';
export const ADD_LIKE_FULFILLED = 'ADD_LIKE_FULFILLED';
export const ADD_LIKE_REJECTED = 'ADD_LIKE_REJECTED';
export const ADD_DISLIKE = 'ADD_DISLIKE';
export const ADD_DISLIKE_PENDING = 'ADD_DISLIKE_PENDING';
export const ADD_DISLIKE_FULFILLED = 'ADD_DISLIKE_FULFILLED';
export const ADD_DISLIKE_REJECTED = 'ADD_DISLIKE_REJECTED';
export const ADD_TO_FAV = 'ADD_TO_FAV';
export const ADD_TO_FAV_PENDING = 'ADD_TO_FAV_PENDING';
export const ADD_TO_FAV_FULFILLED = 'ADD_TO_FAV_FULFILLED';
export const ADD_TO_FAV_REJECTED = 'ADD_TO_FAV_REJECTED';

export interface AddLikeAction {
  type:
    | typeof ADD_LIKE
    | typeof ADD_LIKE_PENDING
    | typeof ADD_LIKE_FULFILLED
    | typeof ADD_LIKE_REJECTED;
  payload: any;
}
export interface AddDisLikeAction {
  type:
    | typeof ADD_DISLIKE
    | typeof ADD_DISLIKE_PENDING
    | typeof ADD_DISLIKE_FULFILLED
    | typeof ADD_DISLIKE_REJECTED;
  payload: any;
}
export interface AddToFavAction {
  type:
    | typeof ADD_TO_FAV
    | typeof ADD_TO_FAV_PENDING
    | typeof ADD_TO_FAV_FULFILLED
    | typeof ADD_TO_FAV_REJECTED;
  payload: any;
}

export type SocialActionTypes =
  | AddLikeAction
  | AddDisLikeAction
  | AddToFavAction;
