import React from 'react';
import s from './Summary.module.scss';
import t from 'i18n';
import cn from 'classnames';
import UserSummary from 'components/Tools/Summary/UserSummary/UserSummary';
import DiscountSummary from 'components/Tools/Summary/DiscountSummary/DiscountSummary';
import PlansSummary from 'components/Tools/Summary/PlansSummary/PlansSummary';
import OptionsSummary from 'components/Tools/Summary/OptionsSummary/OptionsSummaryBasic';
import AddressSummary from 'components/Tools/Summary/AddressSummary/AddressSummary';
import {
  Coupon,
  Guest,
  GuestAddress,
  UserInscriptionState,
} from 'store/Guests/types';
import {
  getByLanguage,
  getEventOptionPrice,
  getEventPlanPrice,
  getGuestFinancial,
  parseJson,
} from 'utils';
import { REGISTRATION_FULL_STEPS } from '../services';
import { EventPlan } from 'interfaces/EventPlan';
import { Language } from 'store/types';
import { EventOption } from 'interfaces/EventOption';
import { Address } from 'interfaces/Address';

interface Props {
  style?: React.CSSProperties;
  selectedCoupons: Coupon[];
  guest: Guest;
  plan?: EventPlan;
  options?: EventOption[];
  language: Language;
  step: REGISTRATION_FULL_STEPS;
  ref?: React.RefObject<any>;
  invoiceAddress?: Partial<GuestAddress>;
  shippingAddress?: Address;
  goToStep: (step: REGISTRATION_FULL_STEPS) => void;
}

interface OptionData {
  option: EventOption;
  price: number;
  originalPrice: number;
}
interface SummaryState {
  options: OptionData[];
}
export default class Summary extends React.Component<Props, SummaryState> {
  state = { options: [] };
  divEndRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.divEndRef = React.createRef();
  }

  componentDidMount() {
    this.initializeOptions();
    setTimeout(() => this.scrollDown(), 0);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { guest, selectedCoupons, options } = this.props;

    if (
      prevProps.options !== options ||
      prevProps.guest !== guest ||
      prevProps.selectedCoupons !== selectedCoupons
    ) {
      this.initializeOptions();
    }
  }

  initializeOptions = () => {
    const { guest, selectedCoupons, options } = this.props;

    this.setState({
      options:
        options?.map((option) => {
          const { price, originalPrice } = getEventOptionPrice(
            option,
            selectedCoupons,
            guest,
          );

          return {
            option,
            price,
            originalPrice,
          };
        }) ?? [],
    });
  };

  scrollDown = () => {
    this.divEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  renderPlan = () => {
    const {
      guest,
      selectedCoupons,
      plan,
      language,
      step,
      goToStep,
    } = this.props;

    if (!plan || step < REGISTRATION_FULL_STEPS.PLAN) return null;

    const name = getByLanguage(plan, 'name', language);
    const description = getByLanguage(plan, 'description', language);
    const { price, originalPrice } = getEventPlanPrice(
      plan,
      selectedCoupons,
      guest,
    );
    // const priceHelper = price > 0 ? price : t('Free');

    return (
      <PlansSummary
        planData={{
          name,
          description,
          accessibleDays: plan.accessibleDays,
          displayedTrainingMinutes: plan.displayedTrainingMinutes,
          price,
          originalPrice,
        }}
        onEdit={() => goToStep(REGISTRATION_FULL_STEPS.PLAN)}
        hideEdit={step <= REGISTRATION_FULL_STEPS.PLAN}
      />
    );
  };

  renderOptions = () => {
    const {
      guest,
      selectedCoupons,
      language,
      options,
      step,
      goToStep,
    } = this.props;

    if (
      step < REGISTRATION_FULL_STEPS.OPTIONS ||
      !options ||
      options?.length < 1
    ) {
      return null;
    }

    return (
      <OptionsSummary
        optionsData={this.state.options.map(
          ({ option, price, originalPrice }) => {
            const name = getByLanguage(option, 'name', language);
            const description = getByLanguage(option, 'description', language);

            return {
              optionName: name,
              description,
              price,
              originalPrice,
            };
          },
        )}
        onEdit={() => goToStep(REGISTRATION_FULL_STEPS.OPTIONS)}
        hideEdit={step <= REGISTRATION_FULL_STEPS.OPTIONS}
      />
    );
  };

  renderTotalPrice = () => {
    const { plan, selectedCoupons, guest } = this.props;

    if (!plan) {
      return '--';
    }

    const { price } = getEventPlanPrice(plan, selectedCoupons, guest);
    const total = this.state.options.reduce(
      (acc, op2: OptionData) => acc + op2.price,
      price,
    );

    const {
      paidInvoices,
      unpaidInvoices,
      toRefund,
      refunded,
    } = getGuestFinancial(guest, false);

    const alreadyPaid = paidInvoices + unpaidInvoices - toRefund - refunded;
    const totalHelp = alreadyPaid ? total - alreadyPaid : total;
    const isRefund = totalHelp < 0;

    // eslint-disable-next-line no-console
    console.log(
      '%c ----  ----',
      'background: yellow; font-size: 22px; color: red;',
      {
        paidInvoices,
        unpaidInvoices,
        toRefund,
        refunded,
        _alreadyPaid: alreadyPaid,
      },
    );

    return (
      <div className="p-s">
        {alreadyPaid ? (
          <div>
            <div
              className="flex-container align-justify align-middle m-b-xs"
              style={{ fontWeight: 'normal' }}
            >
              <span>{t('Total amount')} : </span>
              <span>
                {total.toFixed(2)}
                <span className="m-l-xs">€ {t('htva')}</span>
              </span>
            </div>
            <div
              className="flex-container align-justify align-middle m-b-xs"
              style={{ fontWeight: 'normal' }}
            >
              <span>{t('Amount already paid')} : </span>
              <span>
                {alreadyPaid.toFixed(2)}
                <span className="m-l-xs">€ {t('htva')}</span>
              </span>
            </div>
          </div>
        ) : null}
        <div className="flex-container align-justify align-middle">
          <span>{t(isRefund ? 'Refund' : 'Total to pay')} : </span>
          <span>
            {Math.abs(totalHelp).toFixed(2)}
            <span className="m-l-xs">€ {t('htva')}</span>
          </span>
        </div>
      </div>
    );
  };

  render() {
    const {
      style,
      guest,
      selectedCoupons,
      invoiceAddress,
      shippingAddress,
      language,
      goToStep,
    } = this.props;
    const userInscriptionState =
      parseJson<UserInscriptionState>(guest?.userInscriptionState) ?? null;

    const {
      firstName,
      lastName,
      mainEmail,
      mainPhone,
      birthday,
      organization,
      function: profession,
    } = userInscriptionState ?? {};

    return (
      <div className={s.wrapper} style={style}>
        <div className={cn('p-s', s.content)}>
          <h3 className={cn(s.header, 'm-b-s')}>{t('Summary')} :</h3>
          <UserSummary
            userData={{
              firstName,
              lastName,
              email: mainEmail,
              phone: mainPhone,
              birthday,
              organization,
              profession,
            }}
            hasTitle={true}
            onEdit={() => goToStep(REGISTRATION_FULL_STEPS.PERSONAL_DATA)}
          />
          {selectedCoupons.length > 0 && (
            <DiscountSummary
              coupons={selectedCoupons}
              onEdit={() => goToStep(REGISTRATION_FULL_STEPS.PLAN)}
              language={language}
            />
          )}
          {this.renderPlan()}
          {this.renderOptions()}
          {invoiceAddress && (
            <AddressSummary data={{ address: invoiceAddress, type: 'GUEST' }} />
          )}
          {shippingAddress && (
            <AddressSummary
              title={t('Shipping address')}
              data={{ address: shippingAddress, type: 'ADDRESS' }}
            />
          )}
          <div ref={this.divEndRef}>{/* USED TO SCROLL DOWN */}</div>
        </div>

        <div className={cn(s.price_summary)}>{this.renderTotalPrice()}</div>
      </div>
    );
  }
}
