import { useEffect, useState } from 'react';
import moment from 'moment';

export interface DiffTimeType {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const initialDiffTime = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const useDiffTime = (dateTo?: string, dateFrom?: string) => {
  const [diffTime, setDiffTime] = useState<DiffTimeType>(initialDiffTime);

  useEffect(() => {
    const dateToTime = moment(dateTo);
    const dateFromTime = moment(dateFrom);

    if (dateToTime.isAfter(dateFromTime)) {
      const interval = setInterval(() => {
        const editedDateTime = moment(dateTo);
        const dateATime = moment(dateFrom);
        const diffDays = editedDateTime.diff(dateATime, 'days');
        const diffHours = editedDateTime
          .subtract(diffDays, 'days')
          .diff(dateATime, 'hours');
        const diffminutes = editedDateTime
          .subtract(diffHours, 'hours')
          .diff(dateATime, 'minutes');
        const diffseconds = editedDateTime
          .subtract(diffminutes, 'minutes')
          .diff(dateATime, 'seconds');

        setDiffTime({
          days: diffDays,
          hours: diffHours,
          minutes: diffminutes,
          seconds: diffseconds,
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dateFrom, dateTo]);

  return diffTime;
};
