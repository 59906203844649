import { Language } from 'store/types';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export type LanguageState = Language;

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: string;
}

export type LanguageOptionsType = { [key in Language]: boolean };
