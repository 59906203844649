import React, { useState } from 'react';
import s from './StepPack.module.scss';
import cn from 'classnames';
import t from 'i18n';
import { URLS } from 'router';
import { User } from 'store/Auth/types';
import { Event } from 'store/Events/types';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'components/Common/LocalLoader/LocalLoader';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsContent from '../../../Common/StepsContent';
import StepsControl from '../../../Common/StepsControl';
import TTPCheckBox from 'components/Common/TTPForm/TTPCheckBox';
import {
  formatDateFromTo,
  onError,
  onSuccess,
  groupPacks,
  getEventActivePacks,
  getPrivacyTermsUrl,
} from 'utils';
import { REGISTRATION_PACK_STEPS } from '../../services';
import { PackOrder } from 'interfaces/PackOrder';
import PackContainer from './PackContainer';
import { Language } from 'store/types';
import { useQuery } from 'hooks/useQuery';
import { Organization } from 'interfaces/Organization';
import NotFound from 'Common/NotFound/NotFound';
import { EventPack } from 'interfaces/EventPack';
import { CURRENT_CONGRESS_ID } from 'config';

interface Props {
  user: User;
  event: Event;
  selectedOrg?: Organization;
  isSaving: boolean;
  packFetching: boolean;
  language: Language;
  choosePack: (eventId: string, packId: string, userId: string) => any;
  setPackOrderFiduciary: (packOrderId: string, organizationId: string) => any;
  packs?: any;
  goToStep: (step: REGISTRATION_PACK_STEPS) => void;
  setOutputData: (outputData: PackOrder) => void;
  closeModal: () => void;
  nbResult: number;
  fetched: boolean;
}

const StepPack = ({
  user,
  event,
  selectedOrg,
  isSaving,
  packFetching,
  language,
  choosePack,
  setPackOrderFiduciary,
  goToStep,
  setOutputData,
  closeModal,
  packs,
  nbResult,
  fetched,
}: Props) => {
  const receivedPackId = useQuery().get('packId');
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [isPrivacyTermsChecked, setIsPrivacyTermsChecked] = useState<boolean>(
    false,
  );
  const [packsAvailability, setPacksAvailability] = useState<boolean>(false);
  const [selectedPack, setSelectedPack] = useState<string>(
    receivedPackId ? receivedPackId : '0',
  );
  const activePacks = getEventActivePacks(packs);
  const groupedPacks = groupPacks(activePacks, language);
  const showPrivacyTerms = event.id == CURRENT_CONGRESS_ID;
  const privacyTermsLink = getPrivacyTermsUrl(language);

  const completeStep = (outputData: PackOrder) => {
    setOutputData(outputData);
    goToStep(
      selectedOrg
        ? REGISTRATION_PACK_STEPS.PAYMENT
        : REGISTRATION_PACK_STEPS.ORGANIZATION,
    );
  };

  const handleNextStep = async () => {
    try {
      const {
        value: { data: packOrder },
      } = await choosePack(
        event.id.toString(),
        selectedPack,
        user.id.toString(),
      );

      if (selectedOrg) {
        const {
          value: { data: packOrderWithOrg },
        } = await setPackOrderFiduciary(
          `${packOrder.id}`,
          `${selectedOrg?.id}`,
        );
        onSuccess(packOrderWithOrg);
        return completeStep(packOrderWithOrg);
      }

      onSuccess(packOrder);
      return completeStep(packOrder);
    } catch (error) {
      onError(error);
    }
  };

  const renderTerms = () => {
    return (
      <TTPCheckBox
        theme={'orangeRoyal'}
        name="terms"
        className={'m-r-l'}
        disabled={isSaving}
        checked={isTermsChecked}
        onChange={() => setIsTermsChecked(!isTermsChecked)}
        label={
          <span className={s.acceptConditions}>
            {t('inscription.terms_p1')}&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={URLS.terms.event}
            >
              {t('inscription.terms_p2')}
            </a>
          </span>
        }
      />
    );
  };

  const renderPrivacyTerms = () => {
    if (!showPrivacyTerms) {
      return null;
    }
    return (
      <TTPCheckBox
        theme={'orangeRoyal'}
        name="privacyTerms"
        className={'m-r-l'}
        disabled={isSaving}
        checked={isPrivacyTermsChecked}
        onChange={() => setIsPrivacyTermsChecked(!isPrivacyTermsChecked)}
        label={
          <span className={s.acceptConditions}>
            {t('inscription.privacy_terms_p1')}&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={privacyTermsLink}
            >
              {t('inscription.privacy_terms_p2')}
            </a>
          </span>
        }
      />
    );
  };

  const renderPacks = () => {
    if ((fetched && nbResult == 0) || packsAvailability) {
      return (
        <div style={{ minHeight: '90vh' }}>
          <NotFound text="No packs found or not available" />
        </div>
      );
    } else {
      return groupedPacks?.map(({ label, description, packs }) => (
        <div className={cn('cell align-self-top small-4')} key={label}>
          <PackContainer
            label={label}
            description={description}
            packs={packs}
            fetching={packFetching}
            fetched={fetched}
            setSelectedPack={setSelectedPack}
            selectedPack={selectedPack}
            language={language}
            setPacksAvailability={setPacksAvailability}
            eventId={event.id}
            //date={formatDateFromTo(item.startDate, item.endDate, language)}
          />
        </div>
      ));
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <StepsContent className={s.step_content}>
      <LocalLoaderWrapper>
        <div className="flex-1">
          <h3 className={cn(s.title, 'p-x-l m-t-m')}>
            {t('Select the pack that suits you')} :
          </h3>
        </div>
        <div
          className="flex-container flex-dir-column align-middle p-x-l m-t-s"
          style={{ height: '60vh', overflowY: 'auto' }}
        >
          <LocalLoaderWrapper style={{ width: '100%', height: '100%' }}>
            <div className="grid-x grid-padding-x align-center">
              {renderPacks()}
            </div>
            <LocalLoader loading={packFetching} />
          </LocalLoaderWrapper>
        </div>
        <LocalLoader loading={isSaving} />
      </LocalLoaderWrapper>

      <div style={{ height: '70vh' }} />

      <StepsControl>
        <div className="p-s p-x-l flex-container">
          <GoBack
            onClick={() => handleCancel()}
            text={t('Cancel')}
            hideIcon={true}
            className="m-r-s"
            disabled={isSaving}
          />
          <div className="flex-container m-l-auto align-middle">
            {renderPrivacyTerms()}
            {renderTerms()}
            <NextStep
              className="m-l-auto"
              theme="orangeRoyal"
              text={t('Next step')}
              disabled={
                isSaving ||
                !isTermsChecked ||
                (!isPrivacyTermsChecked && showPrivacyTerms) ||
                selectedPack == '0'
              }
              onClick={handleNextStep}
            />
          </div>
        </div>
      </StepsControl>
    </StepsContent>
  );
};

export default StepPack;
