import { Language } from 'store/types';

export interface NameWrappedAction {
  meta: string;
}
export interface RubricsState {
  fetching: boolean;
  fetched: boolean;
  items: any[];
  error: any;
  nbResult: number;
}
export const FETCH_RUBRICS_PENDING = 'FETCH_RUBRICS_PENDING';
export const FETCH_RUBRICS_FULFILLED = 'FETCH_RUBRICS_FULFILLED';
export const FETCH_RUBRICS_REJECTED = 'FETCH_RUBRICS_REJECTED';

export interface FetchRubricsAction {
  type:
    | typeof FETCH_RUBRICS_PENDING
    | typeof FETCH_RUBRICS_FULFILLED
    | typeof FETCH_RUBRICS_REJECTED;
  payload: any;
}

export type RubricsTypes = FetchRubricsAction;

export interface Rubric {
  author: any[];
  csScope: string[];
  domains: any[];
  groups: any[];
  id: number;
  isPrivate: boolean;
  order: number;
  pages: any[];
  privateGroups: any[];
  publishedAt: string;
  questions: any[];
  source: any;
  status: string;
  theme: any;
  titleEn: string;
  titleFr: string;
  titleNl: string;
}
