import _ from 'i18n';

export const attributionFilter = (
  CollaboratorsCount: number,
  AttributedCont: number,
  UnAttributedCont: number,
) => [
  {
    label: 'all',
    value: _('AllS'),
    count: CollaboratorsCount,
  },
  {
    label: 'in',
    value: _('Registered'),
    count: AttributedCont,
  },
  {
    label: 'nin',
    value: _('Not registered'),
    count: UnAttributedCont,
  },
];

export const CollabCount = (collaborators: any) => {
  return Array.isArray(collaborators) ? collaborators.length : 0;
};

export const truncText = (text: string) => {
  if (text.length > 26) return text.slice(0, 26) + '...';
  else return text;
};
