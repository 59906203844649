import React, { useEffect, useRef, useState } from 'react';
import s from './StepOrganizationData.module.scss';
import cn from 'classnames';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsContent from '../../../Common/StepsContent';
import StepsControl from '../../../Common/StepsControl';
import OrganizationOption from './OrganizationOption';
import RoundedButton from 'components/ui/RoundedButton/RoundedButton';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ROLE_TYPE, ROLE_TYPE_STATUS, USER_ROLE } from 'store/Auth/types';
import { useLoggedAs } from 'hooks/useLoggedAs';
import OrganizationForm from './OrganizationForm';
import {
  getOrganizationsOfRoleType,
  onError,
  onSuccess,
  onWarning,
  parseBoolean,
} from 'utils';
import { Organization } from 'interfaces/Organization';
import { ResourceData } from 'store/types';
import { REGISTRATION_PACK_STEPS } from '../../services';
import TTPCheckBox from 'components/Common/TTPForm/TTPCheckBox';
import { TTP_HOME_URL } from 'config';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'components/Common/LocalLoader/LocalLoader';
import { useLanguage } from 'hooks/useLanguage';
import { PackOrder } from 'interfaces/PackOrder';
import { Event } from 'store/Events/types';

interface Props {
  packOrder: PackOrder | null;
  event: Event;
  fetchCurrentUserRoles: () => any;
  acceptOrganizationTerms: (id: string) => any;
  setPackOrderFiduciary: (packOrderId: string, organizationId: string) => any;
  saveOrganizationStep: (organizationData: ResourceData<Organization>) => any;
  setOutputData: (organization: Organization, packOrder: PackOrder) => void;
  goToStep: (step: REGISTRATION_PACK_STEPS) => void;
}

const StepOrganizationData = ({
  packOrder,
  event,
  fetchCurrentUserRoles,
  saveOrganizationStep,
  acceptOrganizationTerms,
  setPackOrderFiduciary,
  setOutputData,
  goToStep,
}: Props) => {
  const lng = useLanguage();
  const [isOrgsFetching, setIsOrgsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isForm, setIsForm] = useState<boolean>(false);
  const [isTermsShown, setIsTermsShown] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const orgToEdit = useRef<Organization | null>(null);
  const loggedAs = useLoggedAs();
  const isAdmin = loggedAs === USER_ROLE.ROLE_ADMIN;

  useEffect(() => {
    (async () => {
      setIsOrgsFetching(true);
      try {
        const userRoles = await fetchCurrentUserRoles();
        const organizationsList: Organization[] = getOrganizationsOfRoleType(
          userRoles,
          ROLE_TYPE.ROLE_LEGAL_REP,
          [
            ROLE_TYPE_STATUS.ROLE_STATUS_ADMIN,
            ROLE_TYPE_STATUS.ROLE_STATUS_MANAGER,
          ],
        );

        if (organizationsList.length === 0) {
          setIsForm(true);
        } else {
          setOrganizations(organizationsList);
          if (organizationsList.length === 1) {
            setSelectedOrg(organizationsList[0]);
          }
        }
      } catch (error) {
        onError();
      }
      setIsOrgsFetching(false);
    })();
  }, []);

  const completeStep = (organization: Organization, packOrder: PackOrder) => {
    setOutputData(organization, packOrder);
    goToStep(REGISTRATION_PACK_STEPS.PAYMENT);
  };

  const handleSaveStep = async (data: ResourceData<Organization>) => {
    if (data && packOrder) {
      try {
        setIsSaving(true);
        const {
          value: { data: savedOrg },
        } = await saveOrganizationStep(data);

        const {
          value: { data: packOrderWithOrg },
        } = await setPackOrderFiduciary(`${packOrder.id}`, `${savedOrg?.id}`);

        completeStep(savedOrg, packOrderWithOrg);
        return onSuccess(packOrderWithOrg);
      } catch (error) {
        return onError();
      } finally {
        setIsSaving(false);
      }
    }

    return onError();
  };

  const handleNextStep = async () => {
    if (selectedOrg && packOrder) {
      const promises = [
        isTermsShown ? acceptOrganizationTerms(`${selectedOrg.id}`) : null,
        setPackOrderFiduciary(`${packOrder.id}`, `${selectedOrg.id}`),
      ];

      try {
        setIsSaving(true);
        const [, packOrderResp] = await Promise.all(promises);
        completeStep(selectedOrg, packOrderResp?.value?.data);
        return onSuccess(packOrderResp);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.statusCode === 403 &&
          error?.response?.data?.type === 'access_denied'
            ? t('organization_terms_access_denied').replace(
                ':organization',
                selectedOrg.name,
              )
            : undefined;

        return onError(undefined, errorMessage, undefined, true);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleOnEdit = (organization: Organization) => {
    orgToEdit.current = organization;
    setIsForm(true);
  };

  const handleOnCreate = () => {
    orgToEdit.current = null;
    setIsForm(true);
  };

  const handleOnSelect = (org: Organization | null) => {
    setSelectedOrg(org);
    if (!parseBoolean(org?.hasAgreedTerms)) {
      setIsTermsShown(true);
      onWarning(null, 'You must accept the terms and conditions');
    } else {
      setIsTermsShown(false);
    }
  };

  const renderOrganizations = () => {
    let blocks: React.ReactNode[] = organizations.map((org) => (
      <div key={org.id} className="cell medium-11 large-8 m-y-xs">
        <OrganizationOption
          organization={org}
          onSelect={handleOnSelect}
          onEdit={handleOnEdit}
          isSelected={org.id === selectedOrg?.id}
          hasRadioBtn={organizations.length !== 1}
          language={lng}
        />
      </div>
    ));

    if (isOrgsFetching) {
      blocks = [
        <div key={'f1'} className="cell medium-12 large-8 m-y-xs">
          <OrganizationOption.Fetching />
        </div>,
      ];
    }

    return (
      <div className={cn('grid-x align-center', s.org_options)}>{blocks}</div>
    );
  };

  const renderTerms = () => {
    return (
      <TTPCheckBox
        theme={'orangeRoyal'}
        name="terms"
        className={'m-r-l'}
        checked={selectedOrg?.hasAgreedTerms}
        onChange={(e) =>
          setSelectedOrg({
            ...selectedOrg,
            hasAgreedTerms: e.target.checked,
          } as Organization)
        }
        label={
          <span className={s.acceptConditions}>
            {t('inscription.terms_p1')}&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${TTP_HOME_URL}/privacy-and-terms`}
            >
              {t('org.inscription.terms.p2')}
            </a>
          </span>
        }
      />
    );
  };

  // TODO
  //if (!isAdmin) return null;

  if (isForm) {
    return (
      <StepsContent className={cn(s.step_content, 'p-x-l')}>
        <div className="flex-1 m-y-l">
          <h3 className={s.title}>{t('Enter your organisation data')} :</h3>
        </div>
        <div style={{ height: '60vh' }}>
          <OrganizationForm
            data={orgToEdit.current}
            actionsClassName={s.form_actions}
            onSubmit={handleSaveStep}
            onCancel={() => setIsForm(false)}
            onPreviousStep={
              organizations.length === 0
                ? () => goToStep(REGISTRATION_PACK_STEPS.PACK)
                : undefined
            }
            event={event}
          />
        </div>
      </StepsContent>
    );
  }

  return (
    <StepsContent className={s.step_content}>
      <LocalLoaderWrapper>
        <div className="flex-1">
          <h3 className={cn(s.title, 'p-x-l m-t-l')}>
            {t('Select your organization')} :
          </h3>
        </div>

        <div
          className="flex-container flex-dir-column align-middle p-x-l m-t-s"
          style={{ height: '60vh' }}
        >
          {renderOrganizations()}
          {!isOrgsFetching && (
            <div>
              <RoundedButton
                value={t('Register with a new organisation')}
                size={2}
                textStyle={{
                  size: '16px',
                  weight: 500,
                  isUpper: true,
                }}
                bgColors={{ default: 'transparent' }}
                outlineStyle={{ color: '#B2BCC6' }}
                icon={<PlusIcon fill="#6D7F92" width="12px" height="12px" />}
                shadow={{ level: 2 }}
                className={cn('m-t-l m-b-s', s.register_btn)}
                onClick={handleOnCreate}
              />
            </div>
          )}
        </div>
        <LocalLoader loading={isSaving} />
      </LocalLoaderWrapper>

      <StepsControl>
        <div className="p-s p-x-l flex-container">
          <GoBack
            text={t('Previous step')}
            onClick={() => goToStep(REGISTRATION_PACK_STEPS.PACK)}
            disabled={isSaving}
            className="m-r-s"
          />
          <div className="flex-container m-l-auto align-middle">
            {isTermsShown && renderTerms()}
            <NextStep
              className="m-l-auto"
              theme="orangeRoyal"
              text={t('Next step')}
              disabled={
                selectedOrg === null || !selectedOrg.hasAgreedTerms || isSaving
              }
              onClick={handleNextStep}
            />
          </div>
        </div>
      </StepsControl>
    </StepsContent>
  );
};

export default StepOrganizationData;
