import {
  EventsCategoriesActionTypes,
  EventsCategoriesState,
  FETCH_EVENTS_CATEGORIES_PENDING,
  FETCH_EVENTS_CATEGORIES_FULFILLED,
  FETCH_EVENTS_CATEGORIES_REJECTED,
} from './types';
import { paginationInitialState } from 'store/Pagination/reducer';
import { filterInitialState } from 'store/Filter/reducer';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
  ...paginationInitialState,
  ...filterInitialState,
};

export const reducer = (
  state: EventsCategoriesState = initialState,
  action: EventsCategoriesActionTypes,
) => {
  switch (action.type) {
    case FETCH_EVENTS_CATEGORIES_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_EVENTS_CATEGORIES_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: state.page === 1 ? [...data[0]] : [...state.items, ...data[0]],
        nbResult: nbResult,
      };
    }
    case FETCH_EVENTS_CATEGORIES_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
