import t from 'i18n';
import { LayoutTheme } from 'components/Layout/services';
import { Cycle } from 'store/Cycle/types';
import { Event } from 'store/Events/types';
import { Language } from 'store/types';
import {
  adaptEventDates,
  bindCycleLink,
  bindPremiumOfferLink,
  bindWebinarLink,
  formatDateEndOfReplay,
  getByLanguage,
  getCyclePrice,
  isCycleSeason,
  isEventLive,
  isEventPast,
  isEventReplayable,
  isEventUpcoming,
  isPremiumOffer,
  parseBoolean,
  speakersToString,
} from 'utils';
import { Order } from 'store/Guests/types';
import { isEmpty } from 'lodash';
import { URLS } from 'router';

interface CustomDataType {
  themeColor: LayoutTheme;
  titleLabel?: string;
  isPremiumOffer?: boolean;
  isSeason?: boolean;
  isCycle?: boolean;
  isEvent?: boolean;
  isUpcoming?: boolean;
  isExpired?: boolean;
  isLive?: boolean;
  isReplayable?: boolean;
  cycleTrainingHours?: number;
  receptionLink?: string;
  eventId?: number;
  isCertificateNotIncluded?: number;
  title?: string;
}

const getCycleOriginalPrice = (cycle: Cycle, isMember: boolean) => {
  const { memberPrice, nonMemberPrice } = getCyclePrice(cycle);
  return isMember ? memberPrice.originalPrice : nonMemberPrice.originalPrice;
};

export const getPreviewData = (order: Order, language: Language) => {
  let refData = order.events as Event | Cycle;
  const previewType = refData?.hasOwnProperty('eventsCount')
    ? 'CYCLE'
    : 'EVENT';
  const isMember = !isEmpty(order.appWorkload?.guestChoice?.member);
  const selectedDateId = order.appWorkload?.selectedDateId;
  if (previewType === 'EVENT' && selectedDateId) {
    refData = adaptEventDates(refData as Event, selectedDateId);
  }

  const customData: CustomDataType =
    previewType === 'EVENT'
      ? {
          isEvent: true,
          eventId: refData.id,
          isCertificateNotIncluded: refData.isCertificateNotIncluded,
          themeColor: 'blue',
          isUpcoming: isEventUpcoming(refData as Event),
          isExpired: isEventPast(refData as Event),
          isReplayable: isEventReplayable(refData as Event),
          isLive: isEventLive(refData as Event),
          receptionLink: bindWebinarLink(
            URLS.webinar.home,
            refData.id,
            selectedDateId ? `dateIndex=${selectedDateId}` : '',
          ),
          title: getByLanguage(refData, 'name', language),
        }
      : isPremiumOffer(refData as Cycle)
      ? {
          isPremiumOffer: true,
          themeColor: 'redOrange',
          titleLabel: t('Premium subscription'),
          receptionLink: bindPremiumOfferLink(
            URLS.premiumOffer.root,
            refData.id,
          ),
        }
      : isCycleSeason(refData as Cycle)
      ? {
          isSeason: true,
          themeColor: 'orange',
          titleLabel: t('season'),
          cycleTrainingHours: (refData as Cycle).totalCertifiedHours,
          receptionLink: bindCycleLink(URLS.cycle.home, refData.id),
          title: getByLanguage(refData, 'name', language),
        }
      : {
          isCycle: true,
          themeColor: 'purple',
          titleLabel: t('Cycle'),
          isReplayable: parseBoolean(refData.isReplayable),
          cycleTrainingHours: (refData as Cycle).totalCertifiedHours,
          receptionLink: bindCycleLink(URLS.cycle.home, refData.id),
          title: getByLanguage(refData, 'name', language),
        };

  const logoUrl =
    previewType === 'EVENT'
      ? getByLanguage(refData as Event, 'urlBanner', language)
      : getByLanguage(refData as Cycle, 'pictureUrl', language);
  const prices = {
    price: order.excludingVatAmount / 100,
    originalPrice:
      previewType === 'CYCLE'
        ? getCycleOriginalPrice(refData as Cycle, isMember)
        : null,
  };
  const actionLink =
    previewType === 'CYCLE'
      ? bindCycleLink(URLS.cycle.reception.registration.root, refData.id)
      : bindWebinarLink(
          URLS.webinar.reception.registration.root,
          refData.id,
          selectedDateId ? `dateIndex=${selectedDateId}` : '',
        );
  const eventsCount = (refData as Cycle)?.eventsCount;
  const projectedEventsCount = (refData as Cycle)?.projectedEventsCount;

  return {
    bannerUrl: logoUrl,
    speakersLabel: speakersToString(refData, 2),
    trainingHours: (refData as Event)?.accreditationHours ?? 0,
    trainingsCount: !eventsCount ? projectedEventsCount : eventsCount,
    startDateTime: refData.startDateTime,
    endDateTime: refData.endDateTime,
    dateEndOfReplay: formatDateEndOfReplay(refData.dateEndOfReplay ?? ''),
    actionLink,
    ...prices,
    ...customData,
  };
};
