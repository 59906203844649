import React from 'react';
import s from './EventCarousel.module.scss';
import cn from 'classnames';
import TTPSlider from 'Common/TTPSlider';
import { Settings } from 'react-slick';
import useResponsive from 'hooks/useResponsive';

interface Props {
  title?: string;
  titleClassName?: string;
  cards: React.ReactElement[];
  settings?: Settings;
  explore?: string;
  exploreClassName?: string;
  classNameActions?: string;
  arrowsClassName?: string;
  showArrows?: boolean;
  isMainSlide?: boolean;
  containerClassName?: string;
}

const EventCarousel = ({
  title,
  cards,
  settings = {},
  explore,
  exploreClassName,
  classNameActions,
  titleClassName,
  arrowsClassName,
  showArrows,
  isMainSlide,
  containerClassName,
}: Props) => {
  const { isDesktop, isTablet } = useResponsive();

  return (
    <div className={cn(s.events, containerClassName)}>
      <TTPSlider
        title={title}
        titleClassName={titleClassName ? titleClassName : s.headline}
        settings={{
          cssEase: 'ease',
          dots: true,
          lazyLoad: 'ondemand',
          adaptiveHeight: true,
          variableWidth: true,
          className: s.carousel_list,
          ...settings,
        }}
        cell={isDesktop ? 4 : isTablet ? 2 : 1}
        cellToScroll={isDesktop ? 4 : isTablet ? 2 : 1}
        isLeftAlign={true}
        layout="layout3"
        cards={cards}
        explore={explore}
        exploreClassName={exploreClassName}
        classNameActions={cn(classNameActions, s.dots_container)}
        arrowsClassName={arrowsClassName}
        showArrows={showArrows}
        isMainSlide={isMainSlide}
      />
    </div>
  );
};

export default EventCarousel;
