import { connect } from 'react-redux';
import { RootState } from 'store/types';
import OrganizationForm from './OrganizationForm';
import {
  fetchOrganizationByUen,
  fetchSectorsTypeahead,
} from 'store/Auth/thunks';

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps, {
  fetchOrganizationByUen,
  fetchSectorsTypeahead,
})(OrganizationForm);
