import { connect } from 'react-redux';
import { saveStep1 } from 'store/Guests/thunks';
import { closeModal } from 'store/Modal/actions';
import { RootState } from 'store/types';
import Step1PersonalData from './StepPersonalData';
import { checkCoupon } from 'store/Guests/thunks';

const mapStateToProps = (state: RootState) => ({
  checkedCoupon: state.event.guests.checkedCoupon,
});

export default connect(mapStateToProps, { saveStep1, closeModal, checkCoupon })(
  Step1PersonalData,
);
