import { Organization, AppToken, Language } from 'store/types';
import { PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';

export interface User {
  id: number;
  uid: string;
  avatar?: string;
  avatarUrl?: string;
  roles?: Array<UserRole> | [];
  role?: UserRole;
  // token: string;
  // expiresIn: string;
  // createdAt: string;
  token: {
    access_token: string;
    client_id: string;
    expires: number;
    scope: string;
    user_id: string;
  };
  language: Language;
  memberOfCommunities?: Array<Organization>;
  items: []; // TODO
  firstName?: string;
  lastName?: string;
  mainEmail: string;
  mainPhone: string;
  numeroAgreation: string;
  gender?: string;
  isUaAdmin?: boolean;
}

export interface UserRole {
  type: string;
  typeStatus: string;
  organization: Organization;
}

export interface EventAuthorization {
  id: number;
  client: Organization;
  role: USER_ROLE.ROLE_USER | USER_ROLE.ROLE_ADMIN;
}

export enum USER_ROLE {
  ROLE_ADMIN = 'ADMIN',
  ROLE_USER = 'USER',
  ROLE_GUEST = 'GUEST',
}

export enum ROLE_TYPE {
  ROLE_MANAGER = 'MANAGER',
  ROLE_LEGAL_REP = 'LEGAL_REPRESENTATIVE',
  ROLE_OFFICIAL = 'OFFICIAL',
  ROLE_SHAREHOLDER = 'SHAREHOLDER',
  ROLE_QUALITY_MANAGER = 'QUALITY_MANAGER',
  ROLE_EXTERNAL = 'EXTERNAL',
}

export enum ROLE_TYPE_STATUS {
  ROLE_STATUS_MANAGER = 'MANAGER',
  ROLE_STATUS_ADMIN = 'ADMIN',
  ROLE_STATUS_FOLDER = 'FOLDER',
  ROLE_STATUS_CLIENT = 'CLIENT',
}

export interface AuthState {
  authorizations?: EventAuthorization[];
  authorizationFetched: boolean;
  authorizationFetching: boolean;
  fetching: boolean;
  fetched: boolean;
  token: string;
  expiresIn?: string | null;
  createdAt?: string | null;
  user?: User | null;
  error?: any | null;
  loggedAs: USER_ROLE;
  loggedAsPost?: string | null;
  navCommunity?: Organization | null;
  currentPortal: string;
  activeApps: {
    fetching: boolean;
    fetched: boolean;
    result?: any | null;
    error?: any | null;
  };
  appToken: AppToken;
  appTokenFetching: boolean;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
  userPremiumShared: boolean;
}

/* ACTION TYPES */

export const SET_AUTH = 'SET_AUTH';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const SET_EXPIRES_IN = 'SET_EXPIRES_IN';
export const SET_AUTH_LOGGED_AS_POST = 'SET_AUTH_LOGGED_AS_POST';
export const SET_AUTH_LOGGED_AS = 'SET_AUTH_LOGGED_AS';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const FETCH_AUTH_USER = 'FETCH_AUTH_USER';
export const FETCH_AUTH_USER_PENDING = 'FETCH_AUTH_USER_PENDING';
export const FETCH_AUTH_USER_FULFILLED = 'FETCH_AUTH_USER_FULFILLED';
export const FETCH_AUTH_USER_REJECTED = 'FETCH_AUTH_USER_REJECTED';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_FETCHING = 'SET_AUTH_FETCHING';
export const SET_TOKEN_CREATED_AT = 'SET_TOKEN_CREATED_AT';
export const SET_ACTIVE_APPS = 'SET_ACTIVE_APPS';
export const SET_ACTIVE_APPS_PENDING = 'SET_ACTIVE_APPS_PENDING';
export const SET_ACTIVE_APPS_FULFILLED = 'SET_ACTIVE_APPS_FULFILLED';
export const SET_ACTIVE_APPS_REJECTED = 'SET_ACTIVE_APPS_REJECTED';
export const SET_NAV_COMMUNITY = 'SET_NAV_COMMUNITY';
export const FETCH_APP_TOKEN = 'FETCH_APP_TOKEN';
export const FETCH_APP_TOKEN_PENDING = 'FETCH_APP_TOKEN_PENDING';
export const FETCH_APP_TOKEN_FULFILLED = 'FETCH_APP_TOKEN_FULFILLED';
export const FETCH_APP_TOKEN_REJECTED = 'FETCH_APP_TOKEN_REJECTED';
export const FETCH_EVENT_AUTHORIZATION = 'FETCH_EVENT_AUTHORIZATION';
export const FETCH_EVENT_AUTHORIZATION_PENDING =
  'FETCH_EVENT_AUTHORIZATION_PENDING';
export const FETCH_EVENT_AUTHORIZATION_FULFILLED =
  'FETCH_EVENT_AUTHORIZATION_FULFILLED';
export const FETCH_EVENT_AUTHORIZATION_REJECTED = 'FETCH_APP_TOKEN_REJECTED';
export const SAVE_ORGANIZATION_DATA = 'SAVE_ORGANIZATION_DATA';
export const SAVE_ORGANIZATION_DATA_PENDING = 'SAVE_ORGANIZATION_DATA_PENDING';
export const SAVE_ORGANIZATION_DATA_FULFILLED =
  'SAVE_ORGANIZATION_DATA_FULFILLED';
export const SAVE_ORGANIZATION_DATA_REJECTED =
  'SAVE_ORGANIZATION_DATA_REJECTED';
export const SET_USER_PREMIUM_STATUS = 'SET_USER_PREMIUM_STATUS';
export const SET_USER_PREMIUM_SHARED = 'SET_USER_PREMIUM_SHARED';

export interface SetAuthAction {
  type: typeof SET_AUTH;
  auth: AuthState;
}

export interface InitializeAuthAction {
  type: typeof INITIALIZE_AUTH;
  initialState?: Partial<AuthState>;
}

export interface SetExpiresInAction {
  type: typeof SET_EXPIRES_IN;
  expiresIn: string;
}

export interface SetTokenCreatedAtAction {
  type: typeof SET_TOKEN_CREATED_AT;
  createdAt: string;
}

export interface SetAuthUserAction {
  type: typeof SET_AUTH_USER;
  user: User;
}

export interface SetAuthFetchingAction {
  type: typeof SET_AUTH_FETCHING;
  fetching: boolean;
}

export interface FetchAuthUserAction {
  type:
    | typeof FETCH_AUTH_USER
    | typeof FETCH_AUTH_USER_PENDING
    | typeof FETCH_AUTH_USER_FULFILLED
    | typeof FETCH_AUTH_USER_REJECTED;
  payload: any;
}

export interface SetAuthTokenAction {
  type: typeof SET_AUTH_TOKEN;
  token: string;
}

export interface SetAuthLoggedAsAction {
  type: typeof SET_AUTH_LOGGED_AS;
  loggedAs: USER_ROLE;
}

export interface SetAuthLoggedAsPostAction {
  type: typeof SET_AUTH_LOGGED_AS_POST;
  loggedAsPost: string;
}

export interface SetNavCommunityAction {
  type: typeof SET_NAV_COMMUNITY;
  community: Organization | null;
}

export interface SetActiveAppsAction {
  type:
    | typeof SET_ACTIVE_APPS
    | typeof SET_ACTIVE_APPS_PENDING
    | typeof SET_ACTIVE_APPS_FULFILLED
    | typeof SET_ACTIVE_APPS_REJECTED;
  payload: any;
}

export interface FetchAppTokenAction {
  type:
    | typeof FETCH_APP_TOKEN
    | typeof FETCH_APP_TOKEN_PENDING
    | typeof FETCH_APP_TOKEN_FULFILLED
    | typeof FETCH_APP_TOKEN_REJECTED;
  payload: any;
}

export interface FetchEventAuthorizationAction {
  type:
    | typeof FETCH_EVENT_AUTHORIZATION
    | typeof FETCH_EVENT_AUTHORIZATION_PENDING
    | typeof FETCH_EVENT_AUTHORIZATION_FULFILLED
    | typeof FETCH_EVENT_AUTHORIZATION_REJECTED;
  payload: Promise<AppToken> | EventAuthorization[];
}

export interface SaveOrganizationDataAction {
  type:
    | typeof SAVE_ORGANIZATION_DATA
    | typeof SAVE_ORGANIZATION_DATA_PENDING
    | typeof SAVE_ORGANIZATION_DATA_FULFILLED
    | typeof SAVE_ORGANIZATION_DATA_REJECTED;
  payload: any;
}

export interface SetUserPremiumStatusAction {
  type: typeof SET_USER_PREMIUM_STATUS;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
}

export interface SetUserPremiumSharedAction {
  type: typeof SET_USER_PREMIUM_SHARED;
  userPremiumShared: boolean;
}

export type AuthActionTypes =
  | SetAuthAction
  | InitializeAuthAction
  | SetExpiresInAction
  | SetTokenCreatedAtAction
  | SetAuthUserAction
  | FetchAuthUserAction
  | SetAuthTokenAction
  | SetAuthLoggedAsAction
  | SetAuthLoggedAsPostAction
  | SetNavCommunityAction
  | SetActiveAppsAction
  | FetchEventAuthorizationAction
  | SetAuthFetchingAction
  | FetchAppTokenAction
  | SaveOrganizationDataAction
  | SetUserPremiumStatusAction
  | SetUserPremiumSharedAction;
