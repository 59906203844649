import { Language } from 'store/types';

export type LanguageFilterType = { [key in Language]: boolean };

export interface SubMenuState {
  languageFilter: LanguageFilterType;
}

export const SET_LANGUAGE_FILTER = 'SET_LANGUAGE_FILTER';

export interface SetLanguageFilterAction {
  type: typeof SET_LANGUAGE_FILTER;
  filter: LanguageFilterType;
}

export type SubMenuActionTypes = SetLanguageFilterAction;
