import React, { memo, useEffect, useState } from 'react';
import styles from './ModalPayment.module.scss';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { ModalPaymentType } from 'store/Modal/Payment/types';
import { merge } from 'lodash';
import cn from 'classnames';
import NextStep from 'Registration/NextStep/NextStep';
import t from 'i18n';

interface Props {
  modal: ModalState<ModalPaymentType>;
  onCloseModal: () => void;
}

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: 0,
    top: '50%',
    bottom: 'unset',
    height: '90%',
    maxHeight: '700px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '90%',
    maxWidth: '1296px',
  },
};

export default memo(function ModalPayment({ modal, onCloseModal }: Props) {
  const [showTerminate, setShowTerminate] = useState(false);
  useEffect(() => {
    const handler = (e: MessageEvent) => {
      const eventId = e?.data?.event;

      const paymentIframe = document.getElementById(
        'modal-payment-iframe',
      ) as HTMLIFrameElement;

      if (e?.data?.message === 'CLOSE_PAYMENT_MODAL') {
        modal.onSuccess?.();
        onCloseModal();
      }

      if (e?.data?.event === 'RETRY_PAYMENT') {
        paymentIframe.src += ''; // To refresh the iframe
      }

      switch (eventId) {
        case 'PAYMENT_ONLINE':
          setShowTerminate(false);
          break;
        case 'PAYMENT_TRANSFER':
          setShowTerminate(true);
          break;
        case 'PAYMENT_ORDERS':
          setShowTerminate(false);
          break;
        case 'RETRY_PAYMENT':
          setShowTerminate(false);
          break;
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  const handleCloseModal = () => {
    modal.onCancel?.();
    onCloseModal();
  };

  const { src, styles: modalStyles } = modal.data;

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      style={merge(customModalStyles, modalStyles)}
      contentLabel="Tamtam payment"
    >
      <div className="rmodal">
        <CloseModal onClose={handleCloseModal} />
        <div className={styles.wrapper}>
          <iframe id="modal-payment-iframe" title="payment" src={src}></iframe>
          {showTerminate && (
            <div className={cn('flex-container p-r-s')}>
              <NextStep
                className="m-l-auto"
                onClick={handleCloseModal}
                text={t('Finish')}
                hideIcon={true}
                theme={src.indexOf('isCommunity') != -1 ? 'orangeRoyal' : ''}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
});
