import React from 'react';
import s from './NotificationBar.module.scss';
import cn from 'classnames';
import { NotificationBarData } from 'store/Params/NotificationBar';
import { ReactComponent as IconCross } from 'assets/icons/cross.svg';
import { getIcon } from './services';
import Markdown from 'markdown-to-jsx';

interface Props {
  data: NotificationBarData;
  innerRef?: React.Ref<any>;
}

const NotificationBar = ({
  data: { message, theme, icon, onClose },
  innerRef,
}: Props) => {
  return (
    <div
      className={cn(
        s.wrapper,
        'flex-container align-middle align-justify',
        theme && s[theme],
      )}
      ref={innerRef}
    >
      <div className={cn(s.message, theme && s[theme])}>
        {icon && <div className={cn(s.icon, 'm-r-s')}>{getIcon(icon)}</div>}
        <Markdown>{message}</Markdown>
      </div>
      {onClose && (
        <div className={cn(s.close_icon, 'm-l-s')}>
          <IconCross
            onClick={() => onClose?.()}
            width="11"
            height="11"
            fill="#29394D"
          />
        </div>
      )}
    </div>
  );
};

export default NotificationBar;
