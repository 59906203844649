import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  selectUserPremiumStatus,
  selectLoggedAs,
  selectNavCommunity,
} from 'store/Auth';
import { User, USER_ROLE } from 'store/Auth/types';
import SubMenuComponent from './SubMenu';
import { RootState, Organization, TTPSort } from 'store/types';
import { MenuDirectionType } from 'store/Params/SubMenu';
import { setSubMenuDirection } from 'store/Params/actions';
import { FFF_ID, TTP_API_URL } from 'config';
import { useFetchResource } from 'hooks/useFetchResource';
import { Filter, MultiValueFilter, SingleValueFilter } from 'services/Filter';
import { Cycle, CYCLE_STATUS } from 'store/Cycle/types';
import { isEmpty } from 'lodash';
import { Guest, PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';
import { FetchParams, ResourceState } from 'store/Resource/types';
import { fetchPremiumGuest } from 'store/Guests/thunks';
import { cancelOrder, fetchUserOrders } from 'store/Orders/thunks';
import { OrderState } from 'store/Orders/types';
import { isUserPremium, replaceSpecialChars, TYPE_ESSENTIAL } from 'utils';
import moment from 'moment';
import { fetchPremiumOffers } from 'store/Cycle/thunks';

interface Props {
  loggedAs: USER_ROLE;
  navCommunity?: Organization | null;
  direction: MenuDirectionType;
  innerRef?: React.Ref<any>;
  user?: User | null;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
  premiumOffersResource: ResourceState<Cycle>;
  ordersResource: OrderState;
  isPremiumGuestFetching: boolean;
  isPremiumGuestFetched: boolean;
  premiumGuest: Guest | null;
  setSubMenuDirection: (dir: MenuDirectionType) => void;
  fetchUserOrders: (
    userId: number,
    filters?: Filter[],
    sort?: TTPSort[],
  ) => void;
  cancelOrder: (guestId: number, orderId: number) => any;
  fetchGuest: (userId: string | number, cycleId: number) => void;
  fetchPremiumOffers: (params?: FetchParams) => void;
}

const SubMenu = memo(
  ({
    navCommunity,
    direction,
    innerRef,
    user,
    userPremiumStatus,
    isPremiumGuestFetching,
    isPremiumGuestFetched,
    ordersResource: { fetching: isCartFetching, items: orders },
    premiumGuest,
    premiumOffersResource: { items: premiumOffers },
    fetchUserOrders,
    setSubMenuDirection,
    cancelOrder,
    fetchGuest,
  }: Props) => {
    const isFFF = navCommunity?.id === FFF_ID;
    const premiumId = premiumGuest?.cycleId;
    const isRegisteredToPremium = isUserPremium(userPremiumStatus);
    const isUserNotRegisteredToActivePremium =
      premiumGuest && premiumGuest['isUserNotRegisteredToActivePremium'];

    useEffect(() => {
      const filters = [
        new SingleValueFilter('status', 'eq', 'NOT_PAID'),
        new SingleValueFilter('isCanceled', 'eq', 0),
        new MultiValueFilter('appType', 'in', [
          'registering',
          'cycle_registering',
        ]),
      ];
      fetchUserOrders(user?.id ?? 0, filters);
    }, [fetchUserOrders, user]);

    useEffect(() => {
      if (
        user &&
        isRegisteredToPremium &&
        premiumId &&
        !isPremiumGuestFetched
      ) {
        fetchGuest(user.id, premiumId);
      }
    }, [premiumId, premiumOffers]);

    const [, , essentialsCount] = useFetchResource<Array<Cycle> | null>(
      `${TTP_API_URL}/event/cycle`,
      null,
      {
        filters: [
          new SingleValueFilter('type', 'eq', TYPE_ESSENTIAL),
          new SingleValueFilter('status', 'neq', CYCLE_STATUS.INACTIVE),
          new SingleValueFilter(
            'endDateTime',
            'gt',
            replaceSpecialChars(moment().format("[']YYYY-MM-DD HH:mm:ss[']")),
          ),
          new SingleValueFilter('languages', 'eq', 'fr,nl,en'),
        ],
        sort: [{ property: 'startDateTime', dir: 'ASC' }],
        queryParams: {
          userId: user?.id,
          nolimit: true,
          communityIds:
            navCommunity && navCommunity.id ? navCommunity.id : undefined,
        },
      },
      [navCommunity],
    );

    const changeMenuDirection = () => {
      setSubMenuDirection(
        direction === 'HORIZONTAL' ? 'VERTICAL' : 'HORIZONTAL',
      );
    };

    const tabsConfig = {
      showTrainingsTab: true,
      showSeasonsTab: true,
      showChannelsTab: true,
      showMySpaceTab: !isEmpty(user),
      showCyclesTab: true,
      showEssentialsTab: isFFF || essentialsCount > 0,
      showPlansBtn: isFFF && !isPremiumGuestFetching && !isRegisteredToPremium,
      showPremiumOfferBtn:
        (isFFF || (!navCommunity && !isEmpty(user))) &&
        !isPremiumGuestFetching &&
        isRegisteredToPremium &&
        !isUserNotRegisteredToActivePremium,
      showShoppingCart: !isEmpty(user),
      showPremiumTab: true,
    };

    // if (loggedAs === USER_ROLE.ROLE_GUEST || !navCommunity) {
    //   return null;
    // }

    return (
      <SubMenuComponent
        direction={direction}
        changeMenuDirection={changeMenuDirection}
        innerRef={innerRef}
        navCommunity={navCommunity}
        user={user}
        tabsConfig={tabsConfig}
        premiumOfferId={premiumId}
        orders={orders}
        isCartFetching={isCartFetching}
        cancelOrder={cancelOrder}
        fetchUserOrders={fetchUserOrders}
      />
    );
  },
);

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  loggedAs: selectLoggedAs(state),
  navCommunity: selectNavCommunity(state),
  direction: state.params.subMenuDirection,
  ordersResource: state.orders,
  userPremiumStatus: selectUserPremiumStatus(state),
  premiumGuest: state.event.guests.premiumGuest,
  isPremiumGuestFetching: state.event.guests.premiumGuestFetching,
  isPremiumGuestFetched: state.event.guests.premiumGuestFetched,
  premiumOffersResource: state.cycle.premiumOffers,
});

export default connect(mapStateToProps, {
  setSubMenuDirection,
  fetchUserOrders,
  cancelOrder,
  fetchGuest: fetchPremiumGuest,
  fetchPremiumOffers,
})(SubMenu);
