import React from 'react';
import styles from './SpeakerSlide.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import Slider from 'react-slick';
import { Speaker as SpeakerType } from 'store/Speakers/types';
import Avatar from 'components/ui/Avatar';
import { S3_FOLDER_URL_PROD } from 'config';
import { ReactComponent as ArrowRight } from 'assets/icons/sample-arrow-right.svg';
import { getSpeakerPageUrl, prepareS3ResourceUrl } from 'utils';
import { useLanguage } from 'hooks/useLanguage';

interface Props {
  speakers: any[];
  isRegistered?: boolean;
}

export const SpeakerSlide = ({ speakers, isRegistered }: Props) => {
  const language = useLanguage();

  function SampleArrow(props: any) {
    const { className, isPrev, onClick } = props;
    return (
      <div
        className={classNames(
          className,
          isPrev ? styles.sliderPrev : styles.sliderNext,
          isRegistered && styles.active,
        )}
        onClick={onClick}
      >
        <ArrowRight width="14" height="14" fill="#fffff" className="m-r-xs" />
      </div>
    );
  }

  const settings = {
    className: 'left',
    centerMode: false,
    centerPadding: '22px',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleArrow />,
    prevArrow: <SampleArrow isPrev={true} />,
  };
  return (
    <div
      className={classNames(' filter-wrapper__tabs', styles.speakersNavigation)}
    >
      <div>
        <Slider
          {...settings}
          className={classNames(speakers.length > 1 && styles.slider)}
        >
          {speakers.map(
            (speaker: SpeakerType, index: number) =>
              index <= 5 && (
                <div key={index} className={styles.avatar}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getSpeakerPageUrl(speaker, language)}
                  >
                    <Avatar
                      showInfo
                      width={36}
                      height={36}
                      backgroundWidth={42}
                      backgroundHeight={42}
                      firstName={speaker.firstName}
                      lastName={speaker.lastName}
                      styleInfoName={{
                        //fontFamily: 'Roboto',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#29394D',
                        width: '95%',
                      }}
                      //url={`${S3_FOLDER_URL_PROD}/events-folder${speaker.pictureUrl}`}
                      url={prepareS3ResourceUrl(
                        S3_FOLDER_URL_PROD,
                        speaker.pictureUrl,
                      )}
                      fontSize="1rem"
                      className={styles.hideShadow}
                      infoNameClassName={styles.avatarName}
                    />
                  </a>
                </div>
              ),
          )}
        </Slider>
      </div>
    </div>
  );
};

export default SpeakerSlide;
