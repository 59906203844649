import { Dispatch } from 'redux';
import { RootState } from 'store/types';
import actions from './actions';
import * as api from './api';
import { TagOption } from './types';

export const fetchTags = (inputTag: string, language: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  const filters: any = [];

  return dispatch(
    actions.fetchTags(
      api.getTags({ inputTag, language, token, filters }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

// TODO  modify the fields in the AP that returns the events in all lists in UI to avoid  to refetch the events just to get the tags ....

export const fetchEventWithTags = (eventId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  const filters: any = [];

  return dispatch(
    actions.fetchEventWithTags(
      api.getEventWithTags({ eventId, token, filters }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const updateEventTags = (
  eventId: number,
  updatedEventTags: TagOption[],
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  const filters: any = [];

  return dispatch(
    actions.updateEventTags(
      api
        .updateEventTags({ eventId, updatedEventTags, token, filters })
        .then((res) => {
          const { data, nbResult } = res.data;
          return { data: [data], nbResult };
        }),
    ),
  );
};
