import {
  FETCH_RESOURCE,
  ResourceActionTypes,
  RESET_RESOURCE,
  RESET_RESOURCE_DATA,
  SET_RESOURCE_FETCHING,
  SAVE_RESOURCE,
  ADD_RESOURCE,
  ADD_STRATEGY,
  CHANGE_ADD_RESOURCE_STRATEGY,
} from './types';
import { GenericReducerTypes } from 'store/types';

export const resetResource = <T>(
  reducerName: GenericReducerTypes,
): ResourceActionTypes<T> => ({
  type: RESET_RESOURCE,
  meta: reducerName,
});

export const resetResourceData = <T>(
  reducerName: GenericReducerTypes,
): ResourceActionTypes<T> => ({
  type: RESET_RESOURCE_DATA,
  meta: reducerName,
});

export const fetchResource = <T>(
  reducerName: GenericReducerTypes,
  resp: any,
): ResourceActionTypes<T> => ({
  type: FETCH_RESOURCE,
  payload: resp,
  meta: reducerName,
});

export const setResourceFetching = <T>(
  reducerName: GenericReducerTypes,
): ResourceActionTypes<T> => ({
  type: SET_RESOURCE_FETCHING,
  meta: reducerName,
});

export const saveResource = <T>(
  reducerName: GenericReducerTypes,
  resp: any,
): ResourceActionTypes<T> => ({
  type: SAVE_RESOURCE,
  payload: resp,
  meta: reducerName,
});

export const addResource = <T>(
  reducerName: GenericReducerTypes,
  resource: T,
): ResourceActionTypes<T> => ({
  type: ADD_RESOURCE,
  payload: resource,
  meta: reducerName,
});

export const changeAddResourceStrategy = <T>(
  reducerName: GenericReducerTypes,
  strategy: ADD_STRATEGY,
): ResourceActionTypes<T> => ({
  type: CHANGE_ADD_RESOURCE_STRATEGY,
  payload: strategy,
  meta: reducerName,
});

export default {
  resetResource,
  fetchResource,
  setResourceFetching,
};
