import {
  PromotedPopupActionTypes,
  RESET_PROMOTED_POPUP_TIMEOUT_ID,
  SET_PROMOTED_POPUP_TIMEOUT_ID,
} from './types';

export const resetPromotedPopupTimeoutId = (): PromotedPopupActionTypes => ({
  type: RESET_PROMOTED_POPUP_TIMEOUT_ID,
});

export const setPromotedPopupTimeoutId = (
  timeoutId: NodeJS.Timeout,
): PromotedPopupActionTypes => ({
  type: SET_PROMOTED_POPUP_TIMEOUT_ID,
  timeoutId,
});

export default {
  resetPromotedPopupTimeoutId,
  setPromotedPopupTimeoutId,
};
