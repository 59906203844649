import { RootState, EventReducerNames } from 'store/types';

export const selectEvents = (state: RootState, name: EventReducerNames) => {
  switch (name) {
    case 'LIST':
      return state.events.list;
    case 'SEARCH':
      return state.events.search;
    case 'FAVORITE_LIST':
      return state.events.userFavoriteList;
    default:
      return state.events.list;
  }
};
