import { AnswersActionTypes, FETCH_ANSWERS, SAVE_ANSWER } from './types';

export const fetchAnswers = (resp: any): { payload: any; type: string } => ({
  type: FETCH_ANSWERS,
  payload: resp,
});

export const saveAnswer = (resp: any): { payload: any; type: string } => ({
  type: SAVE_ANSWER,
  payload: resp,
});

export default {
  fetchAnswers,
  saveAnswer,
};
