import { SET_LANGUAGE_FILTER, SubMenuActionTypes, SubMenuState } from './types';

const initialState = {
  languageFilter: {
    fr: false,
    en: false,
    nl: false,
  },
};

export const reducer = (
  state: SubMenuState = initialState,
  action: SubMenuActionTypes,
) => {
  switch (action.type) {
    case SET_LANGUAGE_FILTER: {
      return {
        ...state,
        languageFilter: action.filter,
      };
    }
    default:
      return state;
  }
};

export default reducer;
