import { SetLanguageAction, SET_LANGUAGE, LanguageState } from './types';

const reducer = (state: LanguageState = 'fr', action: SetLanguageAction) => {
  switch (action.type) {
    case SET_LANGUAGE: {
      return action.language;
    }
    default:
      return state;
  }
};

export default reducer;
