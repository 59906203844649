import React from 'react';
import s from './TagList.module.scss';
import { isEmpty } from 'lodash';

interface Props {
  tags: string[];
  color?: string;
}

const TagList = ({ tags, color = '#6d7f921a' }: Props) => {
  return (
    <div className={s.tags_wrapper}>
      {tags.map((tag: string, key) => {
        if (!isEmpty(tag)) {
          return (
            <div key={key} className={s.tag} style={{ backgroundColor: color }}>
              {tag}
            </div>
          );
        }
      })}
    </div>
  );
};

export default TagList;
