import {
  SET_SUB_MENU_DIRECTION,
  SetSubMenuDirectionAction,
  MenuDirectionState,
} from './types';

const reducer = (
  state: MenuDirectionState = 'HORIZONTAL',
  action: SetSubMenuDirectionAction,
) => {
  switch (action.type) {
    case SET_SUB_MENU_DIRECTION: {
      return action.subMenuDirection;
    }
    default:
      return state;
  }
};

export default reducer;
