import { legalFormOptions } from './Steps/StepOrganizationData/OrganizationForm/services';

export enum REGISTRATION_PACK_STEPS {
  PACK,
  ORGANIZATION,
  PAYMENT,
  COLLABORATORS,
  PLACE_ATTRIBUTIONS,
}

export const getLegalFormLabel = (legalForm: string) => {
  const [result] = legalFormOptions.filter(({ value }) => {
    return legalForm === value;
  });

  return result?.label ?? legalForm;
};
