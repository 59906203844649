import {
  EventActionTypes,
  EventState,
  FETCH_EVENT_IDS_FULFILLED,
  FETCH_EVENT_IDS_PENDING,
  FETCH_EVENT_IDS_REJECTED,
  FETCH_EVENTS_FULFILLED,
  FETCH_EVENTS_PENDING,
  FETCH_EVENTS_REJECTED,
  FETCH_EVENTS_STATS_FULFILLED,
  FETCH_EVENTS_STATS_PENDING,
  FETCH_EVENTS_STATS_REJECTED,
  FETCH_USER_REGISTERED_EVENTS_STATS_FULFILLED,
  FETCH_USER_REGISTERED_EVENTS_STATS_PENDING,
  FETCH_USER_REGISTERED_EVENTS_STATS_REJECTED,
  RESET_EVENT_FAVORITE,
  RESET_EVENTS,
  RESET_EVENTS_FAVORITE,
  SET_EVENT_CLASSIFICATION_RANK,
  SET_EVENT_FAVORITE,
} from './types';
import { paginationInitialState } from 'store/Pagination/reducer';
import { filterInitialState } from 'store/Filter/reducer';
import { uniq } from 'lodash';

const initialState = {
  fetching: false,
  fetched: false,
  statsFetching: false,
  statsFetched: false,
  userStatsFetching: false,
  userStatsFetched: false,
  eventIdsFetched: false,
  items: [],
  eventIds: [],
  error: null,
  nbResult: 0,
  stats: null,
  userStats: null,
  isEventFavoriteRemoved: false,
  isEventFavoriteAdded: false,
  eventFavoriteTab: '',
  previousRankOfEvent: undefined,
  updatedRankEventId: undefined,
  updatedRank: undefined,
  ...paginationInitialState,
  ...filterInitialState,
};

export const reducer = (
  state: EventState = initialState,
  action: EventActionTypes,
) => {
  switch (action.type) {
    case RESET_EVENTS || RESET_EVENTS_FAVORITE: {
      return initialState;
    }
    case RESET_EVENT_FAVORITE: {
      return {
        ...state,
        isEventFavoriteRemoved: false,
        isEventFavoriteAdded: false,
      };
    }
    case SET_EVENT_CLASSIFICATION_RANK: {
      return {
        ...state,
        previousRankOfEvent: action.previousRankOfEvent,
        updatedRankEventId: action.updatedRankEventId,
        updatedRank: action.updatedRank,
      };
    }
    case SET_EVENT_FAVORITE: {
      if (action.eventFavoriteTab) {
        return {
          ...state,
          isEventFavoriteRemoved: action.isEventFavoriteRemoved,
          isEventFavoriteAdded: action.isEventFavoriteAdded,
          eventFavoriteTab: action.eventFavoriteTab,
        };
      } else {
        return {
          ...state,
          isEventFavoriteRemoved: action.isEventFavoriteRemoved,
          isEventFavoriteAdded: action.isEventFavoriteAdded,
        };
      }
    }
    case FETCH_EVENT_IDS_PENDING:
    case FETCH_EVENTS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_EVENTS_FULFILLED: {
      const { data, nbResult } = action.payload;
      /*let result = [...data];
      const { eventIds } = state;

      if (eventIds && eventIds.length > 0) {
        result = eventIds.map(id => {
          for (let i = 0; i < data.length; i++) {
            if (parseInt(id, 10) === parseInt(data[i].id, 10)) {
              return { ...data[i] };
            }
          }
        });
      }*/

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items:
          state.page === 1
            ? Array.isArray(data)
              ? [...data]
              : []
            : [...state.items, ...(Array.isArray(data) ? data : [])],
        nbResult: nbResult,
      };
    }
    case FETCH_EVENTS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    case FETCH_EVENT_IDS_FULFILLED: {
      const { data } = action.payload.data;
      const eventsIds = data?.events ?? [];
      const eventsAdmIds = data?.eventsAdm ?? [];

      return {
        ...state,
        eventIds: uniq([...eventsIds, ...eventsAdmIds]),
        eventIdsFetched: true,
      };
    }
    case FETCH_EVENT_IDS_REJECTED: {
      return {
        ...state,
        fetching: false,
        eventIdsFetched: false,
        eventIds: [],
      };
    }
    case FETCH_EVENTS_STATS_PENDING: {
      return {
        ...state,
        statsFetched: false,
        statsFetching: true,
      };
    }
    case FETCH_EVENTS_STATS_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        statsFetching: false,
        statsFetched: true,
        stats: data.data,
      };
    }
    case FETCH_EVENTS_STATS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        statsFetching: false,
        stats: null,
      };
    }
    case FETCH_USER_REGISTERED_EVENTS_STATS_PENDING: {
      return {
        ...state,
        userStatsFetched: false,
        userStatsFetching: true,
      };
    }
    case FETCH_USER_REGISTERED_EVENTS_STATS_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        userStatsFetching: false,
        userStatsFetched: true,
        userStats: data.data,
      };
    }
    case FETCH_USER_REGISTERED_EVENTS_STATS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        userStatsFetching: false,
        userStats: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
