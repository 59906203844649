import React, { ReactNode, memo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'i18n';
import lottie, { AnimationItem } from 'lottie-web';
import styles from './NotFound.module.scss';
import { S3_FOLDER_URL_PROD } from 'config';
import { captureException } from '@sentry/browser';

interface Props {
  errorPageType?: 'ERROR_404' | 'ACCESS_DENIED';
  mainClass?: string;
  children?: React.ReactNode;
  text?: string;
  subText?: string;
  buttonIgnored?: boolean;
}

export default memo(
  ({
    mainClass,
    children,
    text,
    subText,
    errorPageType,
    buttonIgnored,
  }: Props) => {
    let animation: AnimationItem | null;
    const error404Container = React.createRef<HTMLDivElement>();
    const notFoundContainer = React.createRef<HTMLDivElement>();
    const accessDeniedContainer = React.createRef<HTMLDivElement>();

    useEffect(() => {
      /**
       * React may render "NotFound" before fetching the items,
       * we add a timeout so we don't fetch animation from s3, until we really have to
       */
      const timerId = setTimeout(() => {
        intiAnimation();
      }, 100);

      // Submit error to sentry
      const errorMessage = text ?? errorPageType ?? 'Nothing Found';
      captureException(new Error(errorMessage));

      return () => {
        clearTimeout(timerId);
        if (animation) {
          animation.destroy();
        }
      };
    }, []);

    const intiAnimation = () => {
      if (animation) {
        animation.destroy();
      }

      let path;
      let container;

      switch (errorPageType) {
        case 'ERROR_404':
          path = `${S3_FOLDER_URL_PROD}/assets/animations/error-404.json`;
          container = error404Container.current;
          break;
        case 'ACCESS_DENIED':
          path = `${S3_FOLDER_URL_PROD}/assets/animations/access-denied.json`;
          container = accessDeniedContainer.current;
          break;
        default:
          path = `${S3_FOLDER_URL_PROD}/assets/animations/nothing-found.json`;
          container = notFoundContainer.current;
          break;
      }

      if (container && path) {
        animation = lottie.loadAnimation({
          container,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path,
        });
      }
    };

    let renderedTxt: ReactNode;

    switch (errorPageType) {
      case 'ERROR_404':
        renderedTxt = (
          <div className={styles.not_found_v2__main}>
            <div ref={error404Container} style={{ height: '360px' }} />
            <h2>{_('Oops')}</h2>
            <h5>{_("You've moved so fast and got lost...")}</h5>
            <p>{_('pageNotFound')}</p>
            {!buttonIgnored ? (
              <button className="btn secondary">
                <i className="icon-arrow-left" /> &nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/">{_('goBack')}</NavLink>
              </button>
            ) : (
              ''
            )}
          </div>
        );
        break;

      case 'ACCESS_DENIED':
        renderedTxt = (
          <div className={styles.not_found_v2__main}>
            <div ref={accessDeniedContainer} style={{ height: '360px' }} />
            <h2>{_('Oops')}</h2>
            {subText && <h5>{_(subText)}</h5>}
            <p>{_(text)}</p>
            {/*<p>{_("accessDenied")}</p>*/}
            {!buttonIgnored ? (
              <button
                className="btn secondary"
                style={{ marginLeft: 12, marginTop: 10 }}
              >
                <i className="icon-arrow-left" /> &nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/">{_('goBack')}</NavLink>
              </button>
            ) : (
              ''
            )}
          </div>
        );
        break;

      default:
        renderedTxt = (
          <div className={styles.not_found_v2__main}>
            <div ref={notFoundContainer} style={{ height: '360px' }} />
            <h2>{_('Nothing Found')}...</h2>
            <p>{_(text || 'nothingFound')}</p>
          </div>
        );
        break;
    }

    return (
      <div className={`${styles.not_found_v2} ${mainClass}`}>
        {children || renderedTxt}
      </div>
    );
  },
);
