import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as GarbageIcon } from 'assets/icons/garbage.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import _ from 'i18n';
import TTButton from '../OLDButton';

export function TTPConfirmDeleteModal(props) {
  return (
    <TTPActionModal
      labels={{ ok: _('Delete') }}
      classNames={{ ok: 'red' }}
      theme="alert"
      renderIcon={() => <GarbageIcon />}
      {...props}
    />
  );
}

export function TTPConfirmActionModal(props) {
  return <TTPActionModal renderIcon={() => <InfoIcon />} {...props} />;
}

export function TTPConfirmSendModal(props) {
  return (
    <TTPActionModal
      labels={{ ok: _('yes'), cancel: _('no') }}
      classNames={{ ok: 'green2' }}
      theme="success"
      renderIcon={() => <SendIcon />}
      {...props}
    />
  );
}

export default function TTPActionModal({
  title,
  message,
  labels,
  classNames,
  theme,
  icon,
  renderIcon,
  contentLabel,
  onCloseModal,
  onAction,
  onAsyncAction,
  onAbort,
  children,
  ...props
}) {
  const [showLoading, setShowLoading] = useState(false);
  const actionLabel = labels?.ok ?? _('ok');
  const cancelLabel = labels?.cancel ?? _('close');
  const classNameAction = classNames?.ok ?? 'blue3';
  const classNameCancel = classNames?.cancel ?? 'gray';

  const afterAsyncAction = (res) => {
    setShowLoading(false);
    onCloseModal();

    return res;
  };

  const onClick = () => {
    if (onAction) {
      onAction();
      onCloseModal();
    } else if (onAsyncAction) {
      setShowLoading(true);
      onAsyncAction().then(afterAsyncAction).catch(afterAsyncAction);
    }
  };

  const onCancel = () => {
    if (onAbort) {
      onAbort();
    }
    onCloseModal();
  };

  return (
    <ReactModal
      className="ttp-modal"
      contentLabel={contentLabel ?? 'Modal'}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={!showLoading}
      {...props}
    >
      <div className="ttp-modal__wrapper">
        <div className={`ttp-modal__header m-b-xl ${theme}`}>
          <div className="icon-wrapper bottom-icon">
            <div>
              {typeof renderIcon === 'function' ? (
                renderIcon()
              ) : (
                <i className={`icomoon ${icon}`} />
              )}
            </div>
          </div>
          <div className="content center">{title}</div>
        </div>
        <div className="ttp-modal__content m-y-s">
          {message ? <div className="message">{message}</div> : children}
        </div>
        <div className="ttp-modal__footer">
          <TTButton
            text={cancelLabel}
            className={`full p-x-l m-r-s ${classNameCancel}`}
            onClick={onCancel}
            fontSize="16px"
            color=""
            upperCase={true}
            disabled={showLoading}
          />
          <TTButton
            text={showLoading ? `${_('Processing')} ...` : actionLabel}
            className={`full main-action p-x-xxl ${classNameAction}`}
            onClick={!showLoading ? onClick : null}
            fontSize="16px"
            color=""
            upperCase={true}
          />
        </div>
        <button
          className="ttp-modal__close"
          type="button"
          onClick={!showLoading ? onCloseModal : null}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </ReactModal>
  );
}
