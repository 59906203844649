import React, { useCallback, useState } from 'react';
import s from './OptionsConfirmation.module.scss';
import t from 'i18n';
import { Language } from 'store/types';
import { isEmpty } from 'lodash';
import { Guest } from 'store/Guests/types';
import { getByLanguage } from 'utils';
import SmallOption from 'ui/SmallOption';
import { S3_FOLDER_URL } from 'config';
import cn from 'classnames';
import StepsControl from 'Webinar/Reception/Registration/Common/StepsControl/StepsControl';
import NextStep from 'Registration/NextStep/NextStep';
import { EventOption } from '../../../../interfaces/EventOption';

interface Props {
  onCloseModal: () => void;
  eventId: number;
  userId: number;
  language: Language;
  guest: Guest;
  isAutoPopup?: boolean;
  optionsToBuy?: EventOption[];
  confirmGuestOption: (
    guestId: number,
    optionId: number,
    isConfirmed: boolean,
  ) => any;
  fetchGuestData: (eventId: string | number, userId: string | number) => void;
  confirmOptionsRejection: (userId: number, eventId: number) => any;
}

export const OptionsConfirmation = ({
  onCloseModal,
  eventId,
  userId,
  guest,
  language,
  confirmGuestOption,
  isAutoPopup,
  fetchGuestData,
  confirmOptionsRejection,
  optionsToBuy,
}: Props) => {
  const options = guest?.options;
  const [checked, setChecked] = useState<boolean>(false);
  const nonConfirmedOptions = options?.filter((obj) => {
    return obj.hasConfirmed == null && obj.isConfirmable == 1;
  });

  const optionsList = isAutoPopup ? optionsToBuy : nonConfirmedOptions;
  const getGuestData = useCallback(() => {
    onCloseModal();
    if (userId) {
      fetchGuestData(eventId, userId);
    }
  }, [eventId, fetchGuestData, userId]);

  const stopShowingModal = useCallback(() => {
    if (userId && checked) {
      confirmOptionsRejection(userId, eventId);
    }
    onCloseModal();
  }, [eventId, confirmOptionsRejection, userId, checked]);

  const renderOptions = () => {
    return (
      <>
        {optionsList?.map((option) => {
          const name = getByLanguage(option, 'name', language);
          const pictureUrl = getByLanguage(option, 'pictureUrl', language);
          const description = getByLanguage(option, 'description', language);

          return (
            <div className="cell small-6 m-b-xs p-r-xs" key={option.id}>
              <SmallOption
                guestId={guest.id}
                optionId={option.id}
                confirmGuestOption={confirmGuestOption}
                title={name}
                description={description}
                isAutoPopup={isAutoPopup}
                price={isAutoPopup ? parseInt(option.memberPrice) : undefined}
                imgUrl={
                  isEmpty(pictureUrl)
                    ? '/img/option.jpg'
                    : `${S3_FOLDER_URL}/events-folder${pictureUrl}`
                }
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={cn(s.options_confirmation)}>
      <div className={cn(s.header, 'align-justify')}>
        <div className={s.title}>
          {isAutoPopup ? t('options') : t('Option_Confirmations')}
        </div>
      </div>
      <div
        className="align-middle p-x-l m-t-s"
        style={{ height: '60vh', overflowY: 'scroll' }}
      >
        <div className={cn('grid-x')}>
          <div className={cn('cell small-12 m-b-xs p-r-xs', s.subheader)}>
            <h3>{isAutoPopup ? t('additional_options') : t('My options')}</h3>
            <p>
              {isAutoPopup
                ? t('r_you_interested_in_these_options')
                : t('options_description')}
            </p>
          </div>
          {renderOptions()}
        </div>
      </div>
      <StepsControl className={s.actions}>
        <div className={cn('p-s p-x-l flex-container')}>
          <div className="flex-container m-l-auto align-middle">
            {isAutoPopup && (
              <>
                <input
                  className={s.checkboxInput}
                  checked={checked}
                  type="checkbox"
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                />
                <span className={s.textNote}>
                  {t('do_not_show_this_option_tab_again')}
                </span>
              </>
            )}
            <NextStep
              className="m-l-auto"
              text={t('Close')}
              hideIcon
              onClick={isAutoPopup ? stopShowingModal : getGuestData}
            />
          </div>
        </div>
      </StepsControl>
    </div>
  );
};

export default OptionsConfirmation;
