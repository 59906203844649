export interface NameWrappedAction {
  meta: string;
}
export interface QuestionsState {
  fetching: boolean;
  fetched: boolean;
  items: any[];
  error: any;
  nbResult: number;
}
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_QUESTIONS_PENDING = 'FETCH_QUESTIONS_PENDING';
export const FETCH_QUESTIONS_FULFILLED = 'FETCH_QUESTIONS_FULFILLED';
export const FETCH_QUESTIONS_REJECTED = 'FETCH_QUESTIONS_REJECTED';

export interface FetchQuestionsAction extends NameWrappedAction {
  type:
    | typeof FETCH_QUESTIONS
    | typeof FETCH_QUESTIONS_PENDING
    | typeof FETCH_QUESTIONS_FULFILLED
    | typeof FETCH_QUESTIONS_REJECTED;
  payload: any;
}

export type QuestionsActionTypes = FetchQuestionsAction;
