export interface PrivacyState {
  fetching: boolean;
  fetched: boolean;
  items: any;
  error: any;
}

export const FETCH_PRIVACY = 'FETCH_PRIVACY';
export const FETCH_PRIVACY_PENDING = 'FETCH_PRIVACY_PENDING';
export const FETCH_PRIVACY_FULFILLED = 'FETCH_PRIVACY_FULFILLED';
export const FETCH_PRIVACY_REJECTED = 'FETCH_PRIVACY_REJECTED';

export interface FetchPrivacyAction {
  type:
    | typeof FETCH_PRIVACY
    | typeof FETCH_PRIVACY_PENDING
    | typeof FETCH_PRIVACY_FULFILLED
    | typeof FETCH_PRIVACY_REJECTED;
  payload: any;
}

export type PrivacyActionTypes = FetchPrivacyAction;
