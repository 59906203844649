export interface TTPDialogState {
  showDialog: boolean;
  customData: any;
}

export enum DIALOG_TYPES {
  CONFIRMATION = 'confirmation',
  SEND = 'send',
  INFO = 'info',
  DELETE = 'delete',
  DEFAULT = 'default',
}

export const SET_TTP_DIALOG_SHOW = 'SET_TTP_DIALOG_SHOW';
export const SET_TTP_DIALOG_CUSTOM_DATA = 'SET_TTP_DIALOG_CUSTOM_DATA';

export interface SetTTPDialogShowAction {
  type: typeof SET_TTP_DIALOG_SHOW;
  showDialog: boolean;
}

export interface SetTTPDialogCustomDataAction {
  type: typeof SET_TTP_DIALOG_CUSTOM_DATA;
  customData: any;
}

export type TTPDialogActionTypes =
  | SetTTPDialogShowAction
  | SetTTPDialogCustomDataAction;
