import React, { memo, useCallback } from 'react';
import styles from './Session.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import { Slot } from 'store/Slots/types';
import {
  filterSpeakersForSlots,
  getByLanguage,
  getCroppedImageUrl,
  isSessionPast,
  isSessionSoldOut,
  isSlotInProgram,
  isWebinarSlot,
} from 'utils';
import { Language } from 'store/types';
import { AG_ITAA, S3_FOLDER_URL } from 'config';
import moment from 'moment';
import SessionActions from './SessionActions/SessionActions';
import SessionLayout1 from './SessionLayout_1';
import SessionLayout2 from './SessionLayout_2';
import SessionLayout3 from './SessionLayout_3';
import SessionLayout4 from './SessionLayout_4';
import SessionLayout5 from './SessionLayout_5';
import SessionLayout6 from './SessionLayout_6';
import { Guest, PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';
import { GuestProgramState } from 'store/Program/types';
import { Event } from 'store/Events/types';
import { ResourceState } from 'store/Resource/types';
import { EventAccess } from 'store/Event/types';
import { User } from 'store/Auth/types';
import { ModalDataInfoType } from 'store/Modal/Info/types';
import { capitalize, isEmpty } from 'lodash';

interface Props {
  session: Slot;
  event: Event;
  language: Language;
  type?: 'demo' | 'conference' | 'break-out' | 'session';
  layout?:
    | 'layout1'
    | 'layout2'
    | 'layout3'
    | 'layout4'
    | 'layout5'
    | 'layout6';
  blockType?: 'block' | 'list';
  showHeader?: boolean;
  guest?: Guest;
  user?: User | null;
  guestProgram: GuestProgramState;
  eventIdsResource: ResourceState<EventAccess>;
  showProgramActions?: boolean;
  search: string;
  addSlotToProgram: (slotId: number, guestId: number) => any;
  removeSlotFromProgram: (slotId: number, guestId: number) => any;
  openSessionModal: (session: Slot) => void;
  openInfoModal: (data: ModalDataInfoType) => void;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
  registerPremiumToEvent: (
    eventId: number | string,
    userId: string | number,
  ) => any;
}

export default memo(function Session({
  session,
  language,
  type = 'conference',
  layout = 'layout1',
  showHeader,
  guest,
  guestProgram,
  eventIdsResource,
  blockType = 'block',
  event,
  user,
  showProgramActions = true,
  search,
  addSlotToProgram,
  removeSlotFromProgram,
  openSessionModal,
  openInfoModal,
  userPremiumStatus,
  registerPremiumToEvent,
}: Props) {
  const handleOpenDetails = useCallback(() => {
    openSessionModal(session);
  }, [openSessionModal, session]);

  const name = getByLanguage(session, 'name', language) ?? '';
  const isWebinar = isWebinarSlot(session);
  const banner = getByLanguage(session, 'pictureUrl', language) ?? '';
  const eventBanner = getByLanguage(event, 'urlBanner', language) ?? '';
  let desc = name;
  const maxLenght = 140;
  if (desc) {
    const lgt = desc.length;
    if (lgt > maxLenght) {
      desc = desc.substring(0, maxLenght) + '...';
    }
  }

  const isSoldOut = isSessionSoldOut(session) && !isSessionPast(session);
  const isLive = moment().isBetween(
    moment(session.startDateTime),
    moment(session.endDateTime),
  );
  const day = moment(session.startDateTime).locale(language).format('ll');
  const hour = `${moment(session.startDateTime).format('HH:mm')} - ${moment(
    session.endDateTime,
  ).format('HH:mm')}`;
  const croppedBanner = getCroppedImageUrl(
    !isEmpty(banner) ? `/events-folder${banner}` : eventBanner,
    370,
  );
  const backgroundStyle = !isEmpty(croppedBanner)
    ? {
        backgroundImage: `linear-gradient(180deg, rgba(41, 57, 77, 0.27) 0%, rgba(41, 57, 77, 0.81) 100%), url(${S3_FOLDER_URL}${croppedBanner.replace(
          'eventsFolder',
          'events-folder',
        )})`,
      }
    : {};

  const filteredSpeakers = filterSpeakersForSlots(session.speakers);
  const speakersLength = filteredSpeakers.length;
  let speakersList = '';

  if (speakersLength > 0) {
    speakersList =
      filteredSpeakers.reduce((prev, speaker, index) => {
        let value = `${prev} ${speaker.firstName} ${speaker.lastName}`;
        if (index < speakersLength - 1) {
          value += ',';
        }
        return value;
      }, '') ?? '';
  }

  const partner = session.partners?.[0];
  const isSelected = isSlotInProgram(guestProgram.data, session.id, 'any');

  const renderTime = useCallback(() => {
    return isLive ? (
      <>
        <div className={classNames(styles.liveIcon, 'm-r-xs')} />
        <span className={styles.sessionTime} style={{ color: '#FE3745' }}>
          {_('Ongoing')}
        </span>
      </>
    ) : (
      <>
        <CalendarIcon width="12" height="12" fill="#fff" className="m-r-xs" />
        <span className={styles.sessionTime}>
          {day}, {hour}
        </span>
      </>
    );
  }, [day, hour, isLive]);

  const renderShortTime = () => {
    if (isLive) {
      return (
        <>
          <div className={classNames(styles.liveIcon, 'm-r-xs')} />
          <span className={styles.sessionTime} style={{ color: '#FE3745' }}>
            {_('Ongoing')}
          </span>
        </>
      );
    }
    return <span className={styles.sessionTime}>{hour}</span>;
  };

  const renderExtras = () => {
    const { files } = session;
    if (Array.isArray(files) && files.length > 0) {
      return (
        <div className={styles.extrasWrapper}>
          <span className={styles.extras}>
            <div className={styles.extrasItem} key="docs">
              <FolderIcon width="14" fill="#6D7F92" />
              <span style={{ color: '#6D7F92' }}>{files.length ?? '??'}</span>
            </div>
          </span>
        </div>
      );
    }

    return null;
  };

  const renderActions = useCallback(() => {
    if (AG_ITAA.includes(event.id)) {
      return <div></div>;
    }

    return (
      <SessionActions
        session={session}
        showCalendarAction={false}
        showAddText={layout === 'layout1' && blockType === 'block'}
        showRemoveText={
          layout === 'layout1' && blockType === 'block' ? false : undefined
        }
        showJoinText={
          (type === 'conference' && blockType === 'block') ||
          ['layout5', 'layout4'].includes(layout)
        }
        showMainButton={
          !['layout5', 'layout4'].includes(layout) && showProgramActions
        }
        language={language}
        guest={guest}
        event={event}
        user={user}
        isSelected={isSelected}
        guestProgram={guestProgram}
        eventIdsResource={eventIdsResource}
        addSlotToProgram={addSlotToProgram}
        removeSlotFromProgram={removeSlotFromProgram}
        blockType={blockType}
        openInfoModal={openInfoModal}
        userPremiumStatus={userPremiumStatus}
        registerPremiumToEvent={registerPremiumToEvent}
      />
    );
  }, [
    blockType,
    event,
    eventIdsResource,
    guest,
    guestProgram,
    isSelected,
    language,
    session,
    type,
    user,
    layout,
  ]);

  if (layout === 'layout4') {
    return (
      <SessionLayout4
        startAt={session.startDateTime}
        endAt={session.endDateTime}
        renderActions={renderActions}
        title={desc}
        backgroundStyle={backgroundStyle}
        isSelected={isSelected}
        onClick={handleOpenDetails}
        speakers={speakersList}
        renderExtras={renderExtras}
      />
    );
  }

  if (layout === 'layout5') {
    return (
      <SessionLayout5
        startAt={session.startDateTime}
        endAt={session.endDateTime}
        renderActions={renderActions}
        title={desc}
        backgroundStyle={backgroundStyle}
        isSelected={isSelected}
        onClick={handleOpenDetails}
        speakers={speakersList}
      />
    );
  }

  if (layout === 'layout6') {
    return (
      <SessionLayout6
        startAt={session.startDateTime}
        endAt={session.endDateTime}
        renderActions={renderActions}
        title={desc}
        isWebinar={session.isWebinar}
        roomName={session.roomName}
        activityName={session.activityName}
        isSelected={isSelected}
        onClick={handleOpenDetails}
        speakers={speakersList}
        language={language}
        isSoldOut={isSoldOut}
        partner={partner}
        searchKeyword={search}
      />
    );
  }

  if (blockType === 'list') {
    return (
      <SessionLayout3
        language={language}
        renderTime={renderShortTime}
        renderActions={renderActions}
        title={desc}
        type={_(type)}
        isSelected={isSelected}
        onClick={handleOpenDetails}
        isSoldOut={isSoldOut}
        activityName={session.activityName}
        isWebinar={isWebinar}
        roomName={session.roomName}
      />
    );
  }

  if (type === 'conference') {
    return (
      <SessionLayout1
        renderTime={renderShortTime}
        renderActions={renderActions}
        title={desc}
        backgroundStyle={backgroundStyle}
        type={_(type)}
        isSelected={isSelected}
        onClick={handleOpenDetails}
        speakers={speakersList}
        partner={partner}
        language={language}
        showHeader={showHeader}
        isSoldOut={isSoldOut}
        activityName={session.activityName}
        isWebinar={isWebinar}
        roomName={session.roomName}
        searchKeyword={search}
        renderExtras={renderExtras}
      />
    );
  }

  return (
    <SessionLayout2
      renderTime={renderTime}
      renderActions={renderActions}
      title={desc}
      backgroundStyle={backgroundStyle}
      type={_(type)}
      isSelected={isSelected}
      onClick={handleOpenDetails}
      speakers={speakersList}
      language={language}
      partner={partner}
      isSoldOut={isSoldOut}
      renderExtras={renderExtras}
      isWebinar={isWebinar}
      roomName={session.roomName}
      searchKeyword={search}
    />
  );
});
