import { Partner } from 'store/Partners/types';

export interface PartnerType
  extends Pick<
    Partner,
    | 'id'
    | 'pictureUrlFr'
    | 'pictureUrlNl'
    | 'pictureUrlEn'
    | 'urlFr'
    | 'urlNl'
    | 'urlEn'
  > {
  to?: string;
  isLocalData?: boolean; // Is data stored locally (with fake id) or in the partners entity
}

export const PARTNERS: PartnerType[] = [
  {
    id: 1,
    pictureUrlFr: '/img/partners/offfcourse.png',
    pictureUrlNl: '',
    pictureUrlEn: '',
    urlFr: 'https://www.offfcourse.be',
    urlNl: '',
    urlEn: '',
    isLocalData: true,
  },
  {
    id: 892,
    pictureUrlFr:
      '/uploads/partenaires/2020/10/BilltoBox_Fr_2020_10_21_10_39_39-original.jpg',
    pictureUrlNl: '',
    pictureUrlEn: '',
    urlFr: 'https://www.billtobox.be',
    urlNl: '',
    urlEn: '',
    isLocalData: false,
  },
  {
    id: 2,
    pictureUrlFr: '/img/partners/ua.png',
    pictureUrlNl: '',
    pictureUrlEn: '',
    urlFr: 'https://unitedassociates.be/',
    urlNl: '',
    urlEn: '',
    isLocalData: true,
  },
  {
    id: 1332,
    pictureUrlFr:
      '/uploads/partenaires/2022/11/HORUS_Fr_2022_11_22_16_05_06-original.jpg',
    pictureUrlNl: '',
    pictureUrlEn: '',
    urlFr: 'https://www.horussoftware.be/',
    urlNl: '',
    urlEn: '',
    isLocalData: false,
  },
  // {
  //   id: 894,
  //   pictureUrlFr:
  //     '/uploads/partenaires/2020/10/WinBooks_Fr_2020_10_28_10_18_01-original.jpg',
  //   pictureUrlNl: '',
  //   pictureUrlEn: '',
  //   urlFr: 'https://www.winbooks.be/fr/',
  //   urlNl: 'https://www.winbooks.be/nl/',
  //   urlEn: '',
  // },
  // {
  //   id: 895,
  //   pictureUrlFr:
  //     '/uploads/partenaires/2020/10/FID-Manager_Fr_2020_10_21_10_54_57-original.jpg',
  //   pictureUrlNl:
  //     '/uploads/partenaires/2020/11/FID-Manager_Nl_2020_11_25_16_58_50-original.jpg',
  //   pictureUrlEn: '',
  //   urlFr: 'https://www.fid-manager.com',
  //   urlNl: 'https://www.fid-manager.com/nl',
  //   urlEn: '',
  // },
  // {
  //   id: 538,
  //   pictureUrlFr:
  //     '/uploads/partenaires/2015/10/Winauditor_Fr_2015_10_21_11_27_29-original.jpg',
  //   pictureUrlNl:
  //     '/uploads/partenaires/2015/10/Winauditor_Nl_2015_10_21_11_27_03-original.jpg',
  //   pictureUrlEn: '',
  //   urlFr: 'https://www.winauditor.com/',
  //   urlNl: 'https://www.winauditor.com/index-nl.php',
  //   urlEn: '',
  // },
  // {
  //   id: 1118,
  //   pictureUrlFr:
  //     '/uploads/partenaires/2021/10/Sd_Worx__Fr_2021_10_15_13_00_52-original.jpg',
  //   pictureUrlNl: '',
  //   pictureUrlEn: '',
  //   urlFr: 'https://www.sdworx.be',
  //   urlNl: '',
  //   urlEn: '',
  // },
];

export const getWrapperPadding = (size: 'M' | 'L', items: number) => {
  if (items > 4) {
    return size === 'L' ? '2rem 1rem' : '1rem';
  }

  return size === 'L' ? '2rem 3rem' : '1rem 2rem';
};
