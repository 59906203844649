import {
  PartnersActionTypes,
  PartnersReducerNameType,
  Partner,
  UPDATE_PARTNER,
  PartnersState,
} from './types';
import { reducer as resourceReducer } from '../Resource';
import { paginationInitialState } from 'store/Pagination/reducer';
import { createNamedWrapperReducer } from 'store/services';
import {
  ResourceState,
  ResourceActionTypes,
  ADD_STRATEGY,
} from 'store/Resource/types';
import {
  EVENT_PARTNERS_RESOURCE,
  EVENT_PARTNERS_SEARCH_RESOURCE,
  EVENT_STRUCTURAL_PARTNERS_RESOURCE,
} from 'store/types';
import { filterInitialState } from 'store/Filter/reducer';

const initialState = {
  fetching: false,
  fetched: false,
  fetchedAt: 0,
  items: [],
  error: null,
  nbResult: 0,
  saving: false,
  addStrategy: ADD_STRATEGY.TAIL,
  ...paginationInitialState,
  ...filterInitialState,
};

const partners = createNamedWrapperReducer<
  ResourceState<Partner>,
  ResourceActionTypes<Partner>
>(resourceReducer, EVENT_PARTNERS_RESOURCE);

const partnersStructural = createNamedWrapperReducer<
  ResourceState<Partner>,
  ResourceActionTypes<Partner>
>(resourceReducer, EVENT_STRUCTURAL_PARTNERS_RESOURCE);

const partnersSearch = createNamedWrapperReducer<
  ResourceState<Partner>,
  ResourceActionTypes<Partner>
>(resourceReducer, EVENT_PARTNERS_SEARCH_RESOURCE);

export const reducer = (nameReducer: PartnersReducerNameType) => (
  state: ResourceState<Partner> = initialState,
  action: PartnersActionTypes | ResourceActionTypes<Partner>,
) => {
  if (nameReducer !== action.meta) {
    return state;
  }

  switch (action.type) {
    case UPDATE_PARTNER: {
      const { partner } = action;
      return {
        ...state,
        items: state.items.map((oldPartner) => {
          if (oldPartner.id === partner.id) {
            return {
              ...partner,
              extras: oldPartner.extras,
              userPartnerRoles:
                partner?.userPartnerRoles ?? oldPartner.userPartnerRoles,
            };
          }

          return oldPartner;
        }),
      };
    }
    default: {
      if (nameReducer === EVENT_STRUCTURAL_PARTNERS_RESOURCE) {
        return partnersStructural(
          state,
          action as ResourceActionTypes<Partner>,
        );
      }
      if (nameReducer === EVENT_PARTNERS_SEARCH_RESOURCE) {
        return partnersSearch(state, action as ResourceActionTypes<Partner>);
      }
      return partners(state, action as ResourceActionTypes<Partner>);
    }
  }
};

export default reducer;
