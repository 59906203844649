import React from 'react';

import styles from '../StepCollaboratorsData.module.scss';
import { fileHeader, fileType, fileExtension, fileName } from '../services';
import ReactDropzone from 'react-dropzone';
import _ from 'i18n';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { onError, onSuccess } from 'utils';
import t from 'i18n';
import UploadResult from './UploadResult';
import { ReactComponent as SpinIcon } from 'assets/icons/tail-spin.svg';
interface Props {
  organizationId: number;
  saved: boolean;
  saving: boolean;
  uploadCollaborators: (organization: number, importAttachment: any) => any;
  fetchCollaborators: (organizationId: number) => void;
  uploadResult: any;
}

interface IState {
  fileName: string;
  fileSize: number;
}

class UploadCollaboratorsFile extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fileName: '',
      fileSize: 0,
    };
  }
  exportToCSV = (csvData: any[], fileName: string, event: any) => {
    event.stopPropagation();
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'collaborateurs' + fileExtension);
  };
  renderContent() {
    return (
      <React.Fragment>
        <i
          className="icon-cloud-upload"
          style={{ fontSize: '1.5rem', marginTop: '2%' }}
        />
        <p>{_('uploadText')}</p>
        <p className={styles.size}>{_('Format (.xlsx)')}</p>
      </React.Fragment>
    );
  }
  getAcceptedTypes() {
    const allowedPartnerDocTypes = ['EXCEL'];
    const acceptedTypes = [];

    if (allowedPartnerDocTypes.includes('EXCEL')) {
      acceptedTypes.push(
        ...[
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          '.xls',
          'text/csv',
        ],
      );
    }

    return acceptedTypes;
  }

  handleExcelDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) return;

    this.setState({
      fileName: acceptedFiles[0].name,
      fileSize: acceptedFiles[0].size,
    });

    this.props
      .uploadCollaborators(this.props.organizationId, acceptedFiles[0])
      .then((res: any) => {
        onSuccess(res, {
          title: t('saveSuccess'),
          body: t('collaboratorUpdated'),
        });
        this.props.fetchCollaborators(this.props.organizationId);
      })
      .catch((res: any) => {
        onError(res, t('invalidExcelFile'));
      });
  };

  render() {
    const { fileName, fileSize } = this.state;
    return (
      <div className={styles.upload_zone}>
        {this.props.saved && fileName != '' && fileSize > 0 && (
          <UploadResult
            fileName={fileName}
            fileSize={fileSize}
            uploadResult={this.props.uploadResult}
          />
        )}

        <div>
          <ReactDropzone
            onDrop={this.handleExcelDrop}
            accept={this.getAcceptedTypes()}
            maxSize={8e6}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} />
                {this.renderContent()}
              </div>
            )}
          </ReactDropzone>
          {this.props.saving ? (
            <div style={{ marginLeft: '45%', marginTop: '2%' }}>
              <SpinIcon className="tiny-loader" />
            </div>
          ) : (
            <div
              className={styles.btn_download}
              onClick={(e) => this.exportToCSV(fileHeader, fileName, e)}
            >
              {_('download an example')}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default UploadCollaboratorsFile;
