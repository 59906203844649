export interface PartnerLead {
  id: number;
  type: LEAD_TYPE;
  status: LEAD_STATUS;
  title?: string;
  user?: User;
  partner: number;
  targetId: number;
  createdAtTimestamp: number;
}

export enum LEAD_TYPE {
  INTERESTED = 'INTERESTED',
  DEMO_REQUEST = 'DEMO_REQUEST',
  CONTACT_REQUEST = 'CONTACT_REQUEST',
  APPOINTMENT_REQUEST = 'APPOINTMENT_REQUEST',
  GIFT_AD = 'GIFT_AD',
  DISCOUNT_AD = 'DISCOUNT_AD',
  GENERIC_AD = 'GENERIC_AD',
  SESSION = 'SESSION',
  SESSION_PARTICIPANT = 'SESSION_PARTICIPANT',
  SESSION_NON_PARTICIPANT = 'SESSION_NON_PARTICIPANT',
  DOWNLOAD_DOC = 'DOWNLOAD_DOC',
  ADD_DOC = 'ADD_DOC',
}

export enum LEAD_STATUS {
  STATUS_DONE = 1,
  STATUS_PENDING = 0,
}

interface User {
  firstName: string;
  lastName: string;
  avatarUrl: string;
  function: string;
  email: string;
  language: string;
  organization: string;
}

export interface PartnerLeadState {
  fetching: boolean;
  fetched: boolean;
  updating: boolean;
  items: PartnerLead[];
  error: any;
  nbResult: number;
}

// leads type
export const DEMO_REQUEST_LEAD = 'DEMO_REQUEST';
export const INTERESTED_LEAD = 'INTERESTED';
export const CONTACT_REQUEST_LEAD = 'CONTACT_REQUEST';
export const APPOINTMENT_REQUEST_LEAD = 'APPOINTMENT_REQUEST';

export const GIFT_AD_LEAD = 'GIFT_AD';
export const DISCOUNT_AD_LEAD = 'DISCOUNT_AD';
export const GENERIC_AD_LEAD = 'GENERIC_AD';

export const DONE_LEAD = 'DONE_LEAD';

export const FETCH_PARTNER_LEAD = 'FETCH_PARTNER_LEAD';
export const FETCH_PARTNER_LEAD_PENDING = 'FETCH_PARTNER_LEAD_PENDING';
export const FETCH_PARTNER_LEAD_FULFILLED = 'FETCH_PARTNER_LEAD_FULFILLED';
export const FETCH_PARTNER_LEAD_REJECTED = 'FETCH_PARTNER_LEAD_REJECTED';

export const FETCH_USER_PARTNERS_LEAD = 'FETCH_USER_PARTNERS_LEAD';
export const FETCH_USER_PARTNERS_LEAD_PENDING =
  'FETCH_USER_PARTNERS_LEAD_PENDING';
export const FETCH_USER_PARTNERS_LEAD_FULFILLED =
  'FETCH_USER_PARTNERS_LEAD_FULFILLED';
export const FETCH_USER_PARTNERS_LEAD_REJECTED =
  'FETCH_USER_PARTNERS_LEAD_REJECTED';

export const UPDATE_PARTNER_LEAD = 'UPDATE_PARTNER_LEAD';
export const UPDATE_PARTNER_LEAD_PENDING = 'UPDATE_PARTNER_LEAD_PENDING';
export const UPDATE_PARTNER_LEAD_FULFILLED = 'UPDATE_PARTNER_LEAD_FULFILLED';
export const UPDATE_PARTNER_LEAD_REJECTED = 'UPDATE_PARTNER_LEAD_REJECTED';

export const CREATE_PARTNER_LEAD = 'CREATE_PARTNER_LEAD';
export const CREATE_PARTNER_LEAD_PENDING = 'CREATE_PARTNER_LEAD_PENDING';
export const CREATE_PARTNER_LEAD_FULFILLED = 'CREATE_PARTNER_LEAD_FULFILLED';
export const CREATE_PARTNER_LEAD_REJECTED = 'CREATE_PARTNER_LEAD_REJECTED';

export const USER_JOINED = 'USER_JOINED';
export const USER_JOINED_PENDING = 'USER_JOINED_PENDING';
export const USER_JOINED_FULFILLED = 'USER_JOINED_FULFILLED';
export const USER_JOINED_REJECTED = 'USER_JOINED_REJECTED';

export const CLEAR_USER_JOINED = 'CLEAR_USER_JOINED';
export const CLEAR_USER_JOINED_PENDING = 'CLEAR_USER_JOINED_PENDING';
export const CLEAR_USER_JOINED_FULFILLED = 'CLEAR_USER_JOINED_FULFILLED';
export const CLEAR_USER_JOINED_REJECTED = 'CLEAR_USER_JOINED_REJECTED';

export const DELETE_PARTNER_LEAD = 'DELETE_PARTNER_LEAD';
export const DELETE_PARTNER_LEAD_PENDING = 'DELETE_PARTNER_LEAD_PENDING';
export const DELETE_PARTNER_LEAD_FULFILLED = 'DELETE_PARTNER_LEAD_FULFILLED';
export const DELETE_PARTNER_LEAD_REJECTED = 'DELETE_PARTNER_LEAD_REJECTED';

export const PUSH_PARTNER_LEAD = 'PUSH_PARTNER_LEAD';

export const REMOVE_PARTNER_LEAD = 'REMOVE_PARTNER_LEAD';
export interface FetchPartnerLeadsAction {
  type:
    | typeof FETCH_PARTNER_LEAD
    | typeof FETCH_PARTNER_LEAD_PENDING
    | typeof FETCH_PARTNER_LEAD_FULFILLED
    | typeof FETCH_PARTNER_LEAD_REJECTED;
  payload: any;
}

export interface UpdatePartnerLeadAction {
  type:
    | typeof UPDATE_PARTNER_LEAD
    | typeof UPDATE_PARTNER_LEAD_PENDING
    | typeof UPDATE_PARTNER_LEAD_FULFILLED
    | typeof UPDATE_PARTNER_LEAD_REJECTED;
  payload: any;
}

export interface FetchUserPartnersLeadsAction {
  type:
    | typeof FETCH_USER_PARTNERS_LEAD
    | typeof FETCH_USER_PARTNERS_LEAD_PENDING
    | typeof FETCH_USER_PARTNERS_LEAD_FULFILLED
    | typeof FETCH_USER_PARTNERS_LEAD_REJECTED;
  payload: any;
}

export interface CreatePartnerLeadAction {
  type:
    | typeof CREATE_PARTNER_LEAD
    | typeof CREATE_PARTNER_LEAD_PENDING
    | typeof CREATE_PARTNER_LEAD_FULFILLED
    | typeof CREATE_PARTNER_LEAD_REJECTED;
  payload: any;
}

export interface UserJoinedAction {
  type:
    | typeof USER_JOINED
    | typeof USER_JOINED_PENDING
    | typeof USER_JOINED_FULFILLED
    | typeof USER_JOINED_REJECTED;
  payload: any;
}

export interface ClearUserJoinedAction {
  type:
    | typeof CLEAR_USER_JOINED
    | typeof CLEAR_USER_JOINED_PENDING
    | typeof CLEAR_USER_JOINED_FULFILLED
    | typeof CLEAR_USER_JOINED_REJECTED;
}

export interface DeletePartnerLeadAction {
  type:
    | typeof DELETE_PARTNER_LEAD
    | typeof DELETE_PARTNER_LEAD_PENDING
    | typeof DELETE_PARTNER_LEAD_FULFILLED
    | typeof DELETE_PARTNER_LEAD_REJECTED;
  payload: any;
}

export interface PushPartnerLeadAction {
  type: typeof PUSH_PARTNER_LEAD;
  lead: PartnerLead;
}

export interface RemovePartnerLeadAction {
  type: typeof REMOVE_PARTNER_LEAD;
  leadId: number | string;
}

export type PartnerLeadActionTypes =
  | FetchPartnerLeadsAction
  | UpdatePartnerLeadAction
  | CreatePartnerLeadAction
  | PushPartnerLeadAction
  | DeletePartnerLeadAction
  | RemovePartnerLeadAction
  | UserJoinedAction
  | ClearUserJoinedAction
  | FetchUserPartnersLeadsAction;
