import {
  FETCH_USER_PERSONAL_DATA,
  SAVE_USER_PERSONAL_DATA,
  UserActionTypes,
} from './types';

export const saveUserPersonalData = (resp: any): UserActionTypes => ({
  type: SAVE_USER_PERSONAL_DATA,
  payload: resp,
});

export const fetchUserPersonalData = (resp: any): UserActionTypes => ({
  type: FETCH_USER_PERSONAL_DATA,
  payload: resp,
});

export default {
  saveUserPersonalData,
  fetchUserPersonalData,
};
