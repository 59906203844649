import React, { memo } from 'react';
import { ModalState } from 'store/Modal/types';
import { ModalDataSessionType } from 'store/Modal/Session/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import ModalContent from './ModalContent';
import { Language } from 'store/types';

interface Props {
  language: Language;
  modal: ModalState<ModalDataSessionType>;
  onCloseModal: () => void;
}

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: 0,
    top: '50%',
    // left: '5%',
    // right: '5%',
    bottom: 'unset',
    // transform: 'translateY(-50%)',
    maxHeight: '90%',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '90%',
    maxWidth: '1290px',
  },
};

export default memo(function ModalSession({
  language,
  modal,
  onCloseModal,
}: Props) {
  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div className="rmodal">
        <CloseModal onClose={onCloseModal} />
        <ModalContent session={modal.data} language={language} />
      </div>
    </Modal>
  );
});
