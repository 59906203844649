import _ from 'i18n';

export const getTooltipMessage = (key: string) => {
  switch (key) {
    case 'not_allowed':
      return _('events.errornotChoosed');
    case 'not_registered':
      return _('events.errorneedInscription');
    case 'empty_url':
      return _('The session does not have a webinar URL');
    case 'empty_replay_url':
      return _('The training video will soon be available in replay');
    default:
      return _('The session does not have a webinar URL');
  }
};
