import { Partner } from '../types';

export interface CurrentPartnerState {
  fetching: boolean;
  fetched: boolean;
  items: Partner[];
  error: any;
  nbResult: number;
}

export const SET_CURRENT_PARTNER = 'SET_CURRENT_PARTNER';
export const RESET_CURRENT_PARTNER = 'RESET_CURRENT_PARTNER';
export const FETCH_CURRENT_PARTNER = 'FETCH_CURRENT_PARTNER';
export const FETCH_CURRENT_PARTNER_PENDING = 'FETCH_CURRENT_PARTNER_PENDING';
export const FETCH_CURRENT_PARTNER_FULFILLED =
  'FETCH_CURRENT_PARTNER_FULFILLED';
export const FETCH_CURRENT_PARTNER_REJECTED = 'FETCH_CURRENT_PARTNER_REJECTED';
export const SAVE_CURRENT_PARTNER = 'SAVE_CURRENT_PARTNER';
export const SAVE_CURRENT_PARTNER_PENDING = 'SAVE_CURRENT_PARTNER_PENDING';
export const SAVE_CURRENT_PARTNER_FULFILLED = 'SAVE_CURRENT_PARTNER_FULFILLED';
export const SAVE_CURRENT_PARTNER_REJECTED = 'SAVE_CURRENT_PARTNER_REJECTED';

export interface ResetCurrentPartnersAction {
  type: typeof RESET_CURRENT_PARTNER;
}

export interface SaveCurrentPartnersAction {
  type:
    | typeof SAVE_CURRENT_PARTNER
    | typeof SAVE_CURRENT_PARTNER_PENDING
    | typeof SAVE_CURRENT_PARTNER_FULFILLED
    | typeof SAVE_CURRENT_PARTNER_REJECTED;
  payload: any;
}

export interface SetCurrentPartnersAction {
  type: typeof SET_CURRENT_PARTNER;
  partner: Partner;
}

export interface FetchCurrentPartnersAction {
  type:
    | typeof FETCH_CURRENT_PARTNER
    | typeof FETCH_CURRENT_PARTNER_PENDING
    | typeof FETCH_CURRENT_PARTNER_FULFILLED
    | typeof FETCH_CURRENT_PARTNER_REJECTED;
  payload: any;
}

export type CurrentPartnerActionTypes =
  | FetchCurrentPartnersAction
  | ResetCurrentPartnersAction
  | SetCurrentPartnersAction
  | SaveCurrentPartnersAction;
