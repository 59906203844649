import {
  GoBackActionTypes,
  GoBackState,
  SET_GO_BACK_SHOW,
  SET_GO_BACK_CUSTOM_DATA,
  RESET_GO_BACK,
} from './types';

const initialState = {
  showGoBack: false,
  customData: {
    title: '...',
  },
  route: {
    path: '/unkonw-page',
    exact: true,
  },
};

const reducer = (
  state: GoBackState = initialState,
  action: GoBackActionTypes,
) => {
  switch (action.type) {
    case RESET_GO_BACK: {
      return { ...initialState };
    }
    case SET_GO_BACK_SHOW: {
      return { ...state, showGoBack: action.showGoBack };
    }
    case SET_GO_BACK_CUSTOM_DATA: {
      return {
        ...state,
        customData: action.customData,
        route: action.route,
        showGoBack: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
