import React, { useState } from 'react';
import s from './YesNoButtons.module.scss';
import cn from 'classnames';
import t from 'i18n';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import _ from 'i18n';
import { isBoolean } from 'lodash';

interface Props {
  onClick: (yesOrNo: any) => void;
  hasConfirmed?: any;
}

const YesNoButtons = ({ onClick, hasConfirmed }: Props) => {
  const [status, setResponseStatus] = useState<boolean>(hasConfirmed);
  const [responded, setResponded] = useState<boolean>();

  const setResponse = (response: boolean) => {
    setResponseStatus(response);
    onClick(response);
    setResponded(true);
  };

  const fillIcon = status ? '#06D9B1' : '#F9626D';
  const isConfirmedText = status
    ? t('confirmed option')
    : t('unconfirmed option');
  return (
    <>
      {!responded && !isBoolean(hasConfirmed) && (
        <div className="flex-container align-justify align-middle">
          <span className={s.text}>{t('confirm this option')}</span>
          <div className="flex-container align-justify align-middle">
            <div className={cn(s.positive_btn, 'm-t-xs m-b-xs m-x-xs')}>
              <button onClick={() => setResponse(true)}>{_('yes')}</button>
            </div>
            <div className={cn(s.negative_btn, 'm-t-xs m-b-xs m-x-xs')}>
              <button onClick={() => setResponse(false)}>{_('no')}</button>
            </div>
          </div>
        </div>
      )}
      {(responded || isBoolean(hasConfirmed)) && (
        <div
          className={cn(
            status && s.success_action,
            !status && s.fail_action,
            'flex-container align-justify align-middle',
          )}
        >
          <CheckMarkIcon
            fill={fillIcon}
            className="m-r-xs"
            width="12"
            height="12"
          />
          <span>{isConfirmedText}</span>
        </div>
      )}
    </>
  );
};

export default YesNoButtons;
