import { Article } from 'store/Articles/types';
import { Channel } from 'store/Channel/types';
import { Language } from 'store/types';
import { getByLanguage } from 'utils';
import { TTP_FFF_BLOG_URL, TTP_BLOG_URL, TTP_API_URL } from 'config';

export const getAuthors = (author: any, lng: Language = 'fr') => {
  let authors = [];
  const others = [];
  if (author && author.length > 0) {
    authors = author
      .filter((a: any) => a.enableAvatar === true)
      .map((a: any) => {
        return {
          id: a.id,
          name: a.firstName + ' ' + a.lastName, //a.signature.title
          headline: a.signature.head,
          avatar: a.avatar,
          avatarUrl: a.avatarUrl,
          url: `/${lng}/author/${a.url}/${a.id}`,
        };
      });
  }

  return [...authors];
};

export function getArticleFullUrl(article: Article) {
  const { url, id, organization, language, isExternal, externalUrl } = article;

  if (isExternal) {
    return externalUrl;
  }

  const urlLang = language;

  const fullUrl = `/${urlLang}/article/${url}/${id}`;

  return `${TTP_FFF_BLOG_URL}${fullUrl}`;
}

export const getMainMedia = ({ main_media }: any) => {
  if (main_media) {
    if (
      main_media.preview &&
      (main_media.preview.fullMediaUrl || main_media.preview.webPath)
    ) {
      return main_media.preview.fullMediaUrl
        ? main_media.preview.fullMediaUrl
        : TTP_API_URL + '/' + main_media.preview.webPath;
    } else {
      if (main_media.fullCroppedWebPath) {
        return main_media.fullCroppedWebPath;
      } else {
        return main_media.fullMediaUrl
          ? main_media.fullMediaUrl
          : `${TTP_API_URL}/${main_media.webPath}`;
      }
    }
  }
  return '/img/article-cover-small.jpg';
};
export const prepareArticle = (article: Article) => {
  const {
    id,
    title,
    status,
    introduction,
    organization,
    category,
    type,
    isPrivate,
    isExternal,
    externalUrl,
    countLikes,
    countDislikes,
    countComments,
    publishedAt,
    social,
    language,
    readTime,
  } = article;

  let socialData = { countLikes, countDislikes, countComments };

  if (social) {
    socialData = {
      ...socialData,
    };
  }

  return {
    id,
    title,
    status,
    introduction,
    communityName:
      organization.abbreviation ||
      (organization.name.length <= 30
        ? organization.name
        : organization.name.substr(0, 30) + '...'),
    category: {
      name: getByLanguage(category, 'name', language),
      colorCode: category && category.colorCode ? category.colorCode : '',
    },
    articleType: {
      name: getByLanguage(type, 'name', language),
      colorCode: type && type.colorCode ? type.colorCode : '',
    },
    url: getArticleFullUrl(article),
    //shareUrl: getArticleUrl(article, env, host),
    hasRelativePath: false,
    mainMedia: getMainMedia(article),
    //album: getAlbum(article),
    authors: getAuthors(article.author, language),
    isPrivate,
    isExternal,
    externalUrl,
    countLikes,
    countDislikes,
    countComments,
    publishedAt,
    socialData,
    language,
    readTime,
  };
};
