import React from 'react';

// TODO refactoring
export default function OLDButton({
  icon = undefined,
  color = 'blue',
  className = undefined,
  onClick = undefined,
  text = undefined,
  children = undefined,
  disabled = undefined,
  upperCase = undefined,
  fontSize = undefined,
  textStyle = undefined,
  textClassName = '',
  dataTip = undefined,
  dataFor = undefined,
  style = undefined,
}) {
  const data = dataTip ? { 'data-tip': true, 'data-for': dataFor } : {};

  return (
    <button
      type="button"
      {...data}
      className={`tt-button ${color} ${className} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={disabled ? undefined : onClick}
      style={style}
    >
      {icon}
      <span
        style={textStyle ? { ...textStyle, fontSize } : { fontSize }}
        className={`tt-button__text ${
          upperCase ? 'text-uppercase' : ''
        } ${textClassName}`}
      >
        {text ?? children}
      </span>
    </button>
  );
}
