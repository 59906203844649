import { connect } from 'react-redux';
import { saveStep2, checkCoupon } from 'store/Guests/thunks';
import { closeModal } from 'store/Modal/actions';
import { RootState } from 'store/types';
import StepAddress from './StepAddress';

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  language: state.params.lng,
  shippingAddresses: state.guest.shippingAddress.items,
});

export default connect(mapStateToProps, {
  saveStep2,
  checkCoupon,
  closeModal,
})(StepAddress);
