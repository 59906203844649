import * as React from 'react';
import { ReactElement } from 'react';
import s from './StepPack.module.scss';
import cn from 'classnames';
import { ReactComponent as IconPack } from 'assets/icons/pack.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import _ from 'i18n';
import { Language } from 'store/types';
import Markdown from 'markdown-to-jsx';
import { isEmpty } from 'lodash';
import { EventPack } from 'interfaces/EventPack';

export interface Props {
  label: string;
  packs: EventPack[];
  description?: string;
  fetching: boolean;
  fetched: boolean;
  setSelectedPack: any;
  selectedPack: any;
  date?: string;
  language: Language;
  setPacksAvailability: any;
  eventId: number;
}

export default function PackContainer({
  label,
  description,
  fetching,
  packs,
  setSelectedPack,
  date,
  selectedPack,
  fetched,
  language,
  setPacksAvailability,
  eventId,
}: Props): ReactElement {
  const packsIds = packs.map(({ id }) => id);
  const isPackSelected = packsIds.includes(+selectedPack);

  const renderOptions = () => {
    if (fetching) {
      return [1, 2, 3].map((key) => (
        <div key={key} className={cn('grid-x m-b-xs')}>
          <input name="radio" type="radio" />
          <label className={s.place_number__fetching}>
            <span />
          </label>
          <span className={s.per_place_price} />
        </div>
      ));
    } else
      return packs.map(({ id, event, memberPrice, nameFr }) => {
        if (event == eventId) {
          const placesNbr = +nameFr.substr(0, 7).replace(/[^0-9]/g, '');
          return (
            <div
              key={id}
              className={cn('grid-x m-b-xs', selectedPack == id && s.checked)}
            >
              <input
                id={String(id)}
                name="radio"
                value={id}
                type="radio"
                checked={id == selectedPack}
                onChange={() => {
                  setSelectedPack(id);
                }}
              />
              <label className={s.place_number} htmlFor={String(id)}>
                <span />
                {placesNbr} {placesNbr > 1 ? _('seats') : _('seat')}
              </label>
              <section style={{ display: 'inline-flex' }}>
                <span className={s.per_place_price}>
                  {Math.round(+memberPrice / placesNbr)} € / {_('place')}
                </span>
                <span className={s.separator} />
                <span className={s.all_place_price}>{memberPrice} €</span>
              </section>
            </div>
          );
        }
      });
  };

  return (
    <div
      className={s.card}
      style={{ border: isPackSelected ? '1.5px solid #F7953E' : '' }}
    >
      <div className={s.card_header}>
        <div
          className={s.content}
          style={{ background: isPackSelected ? '#FEF0E3' : '' }}
        >
          <div className={cn(s.title, 'grid-x')}>
            <div className="cell small-3">
              <IconPack />
            </div>
            <div className="cell small-9">
              <div className={s.first_part}>{label}</div>
              {/*<div className={s.second_part}>
                <CalendarIcon
                  width="12"
                  height="12"
                  fill="#29394D"
                  className="m-r-xs"
                />
                <span>{date}</span>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
      <div
        className={s.arrow_down}
        style={{ borderTop: isPackSelected ? '20px solid #FEF0E3' : '' }}
      />

      <div className={s.description}>
        <div className={s.first_part}>
          {!isEmpty(description) && <Markdown>{description ?? ''}</Markdown>}
        </div>
        <div className={s.second_part}>{_('Desired number of places')} :</div>
      </div>
      <div className={s.packPlaces_options}>{renderOptions()}</div>
    </div>
  );
}
