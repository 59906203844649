import axios from 'axios';
import { TTP_API_URL } from 'config';
import { Filter } from 'services/Filter';
import { TTPSort } from 'store/types';

export const getRooms = ({
  token,
  filters = [],
  sort = [],
}: {
  token: string;
  sort?: TTPSort[];
  filters?: Filter[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/room`;

  const filter = [...filters].map((filter) => filter.serialize());

  const sortBy = [...sort];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sortBy),
      nolimit: 1,
    },
  });
};
