import React, { ReactElement, useEffect, useRef, useState } from 'react';
import s from '../StepOrganizationData.module.scss';
import cn from 'classnames';
import t from 'i18n';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  PreventSubmitOnKeyDown,
  TTPInputField,
  TTPSelectField,
} from 'components/Common/TTPForm';
import NextStep from 'components/Registration/NextStep/NextStep';
import GoBack from 'components/Registration/GoBack';
import {
  countriesOptions,
  getFormInitialValues,
  getSectorOptions,
  languageOptions,
  legalFormOptions,
  OrganizationFormValues,
  SectorTypeahead,
  vatOptions,
} from './services';
import * as yup from 'yup';
import { Organization } from 'interfaces/Organization';
import { Language, ResourceData } from 'store/types';
import {
  COUNTRIES,
  CURRENT_CONGRESS_ID,
  LANGUAGES,
  LEGAL_FORMS,
  TTP_HOME_URL,
  VAT,
} from 'config';
import { TTPCheckBoxField } from 'components/Common/TTPForm/TTPCheckBox';
import LocalLoader from 'components/Common/LocalLoader';
import { LocalLoaderWrapper } from 'components/Common/LocalLoader/LocalLoader';
import { useLanguage } from 'hooks/useLanguage';
import { debounce, isEmpty, upperFirst } from 'lodash';
import { getByLanguage, getPrivacyTermsUrl } from 'utils';
import { Event } from 'store/Events/types';

interface Props {
  data: Organization | null;
  actionsClassName?: string;
  event: Event;
  fetchOrganizationByUen: (uen: string) => any;
  fetchSectorsTypeahead: (keyword: string, language: Language) => any;
  onSubmit: (data: ResourceData<Organization>) => any;
  onCancel?: () => void;
  onPreviousStep?: () => void;
}

const OrganizationForm = ({
  data,
  actionsClassName,
  event,
  fetchOrganizationByUen,
  fetchSectorsTypeahead,
  onSubmit,
  onCancel,
  onPreviousStep,
}: Props): ReactElement => {
  const language = useLanguage();
  const uenValidationStatus = useRef<boolean>(true);
  const [inputMask, setInputMask] = useState('');
  const [uen, setUen] = useState('');
  const [uenLength, setUenLength] = useState(10);
  const isEdit = data !== null;
  const initialSectorOption = isEdit
    ? {
        value: data?.sector.id,
        label: upperFirst(
          getByLanguage(data?.sector, 'title', language)?.toLowerCase(),
        ),
      }
    : null;
  const showPrivacyTerms = event.id == CURRENT_CONGRESS_ID;
  const privacyTermsLink = getPrivacyTermsUrl(language);

  useEffect(() => {
    if (!isEmpty(data?.uen) && !isEmpty(data?.country)) {
      setUen(`${data?.country}${data?.country == 'LU' ? '' : ' '}${data?.uen}`);
    }
  }, [data]);

  const loadSectorsSuggestions = async (inputValue: string) => {
    try {
      const res: SectorTypeahead[] = await fetchSectorsTypeahead(
        inputValue,
        language,
      );
      return getSectorOptions(res);
    } catch (error) {
      return [];
    }
  };

  // TODO: stuck on the first keystroke
  //const loadSectorsSuggestionsDebounced = debounce(loadSectorsSuggestions, 300);

  const checkUen = async (value: string) => {
    // * NOTE: Validating a single field in Formik also triggers full schema validation, which is inconvenient
    // * with asynchronous validation. Currently, there is no implementation of the issue (at least at the
    // * time of this writing). There is the validation prop of Formik.Field but that does not solve the
    // * problem. The other (naive) solution is to manually take care of onBlur/onChange of each field.
    // * The workaround I used (Formik set up with Yup and validationSchema) is to simply check if the active
    // * DOM element is the desired field, then trigger the async validation.
    // * For more updates on this issue please visit this github issue : https://github.com/formium/formik/issues/512
    if (
      document.activeElement?.id === 'uen' &&
      value?.length === uenLength &&
      value !== data?.uen
    ) {
      try {
        const res = await fetchOrganizationByUen(value ?? '');
        uenValidationStatus.current = res?.uen !== value;
        return uenValidationStatus.current;
      } catch (error) {
        uenValidationStatus.current = true;
        return uenValidationStatus.current;
      }
    }

    return uenValidationStatus.current;
  };

  const validationSchema = yup.object().shape({
    uen: yup
      .string()
      .length(uenLength, t('yup.length'))
      .required(t('yup.required'))
      .test('is-unique', t('yup.unique_uen'), checkUen)
      .label(t('org.inscription.uen')),
    name: yup
      .string()
      .min(2, t('yup.min'))
      .required(t('yup.required'))
      .label(t('org.inscription.name')),
    url: yup.string().url(t('yup.url')).label(t('org.inscription.url')),
    address: yup
      .string()
      .required(t('yup.required'))
      .label(t('org.inscription.address')),
    zipCode: yup
      .string()
      .min(3, t('yup.min'))
      .max(12, t('yup.max'))
      .test(
        'exclude-zero-sequence',
        t('yup.invalid'),
        (value: string) => !/^0+$/.test(value?.replace(/\s/, '')),
      )
      .required(t('yup.required'))
      .label(t('org.inscription.zip')),
    country: yup
      .string()
      .oneOf(COUNTRIES, t('yup.invalid'))
      .required(t('yup.required'))
      .label(t('org.inscription.country')),
    legalForm: yup
      .string()
      .oneOf(LEGAL_FORMS, t('yup.invalid'))
      .required(t('yup.required'))
      .label(t('org.inscription.lf')),
    vat: yup
      .string()
      .oneOf(VAT, t('yup.invalid'))
      .required(t('yup.required'))
      .label(t('org.inscription.vat')),
    languages: yup
      .array()
      .of(yup.string().oneOf(LANGUAGES, t('yup.required')))
      .required(t('yup.required'))
      .label(t('org.inscription.language')),
    ...((!isEdit || !data?.hasAgreedTerms) && {
      terms: yup
        .string()
        .oneOf(['1'], t('You must accept the terms and conditions'))
        .required(t('You must accept the terms and conditions')),
    }),
    ...(!isEdit &&
      showPrivacyTerms && {
        privacyTerms: yup
          .string()
          .oneOf(['1'], t('You must accept the Privacy terms'))
          .required(t('You must accept the Privacy terms')),
      }),
  });

  const handleSubmit = (
    values: OrganizationFormValues,
  ): void | Promise<any> => {
    return onSubmit({
      ...(isEdit && { id: data?.id }),
      uen: values.uen,
      name: values.name,
      abbreviation: values.abbreviation,
      website: values.url,
      address1: values.address,
      zipCode: values.zipCode,
      country: values.country,
      legalForm: values.legalForm,
      sector: values.sector ?? '',
      tva: values.vat,
      languagePreferences: values.languages,
      hasAgreedTerms: values.terms === '1',
    });
  };

  return (
    <Formik
      initialValues={getFormInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValidating, values, setFieldValue }) => (
        <Form onKeyDown={PreventSubmitOnKeyDown}>
          <LocalLoaderWrapper className="m-b-l">
            <div className="grid-x grid-margin-x">
              <div className="cell small-3">
                <TTPInputField
                  theme={'orangeRoyal'}
                  id="uen"
                  name="uen"
                  label={t('org.inscription.uen')}
                  labelClassName={s.weight_400}
                  placeholder={'BE XXXX.XXX.XXX | LUXXXXXXXX'}
                  required={true}
                  tooltip={t('org.inscription.uen.tooltip')}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    const uenValue = value.replace(/\D/g, '');
                    if (value.substr(0, 2) === 'LU') {
                      setInputMask('aa99999999');
                      setUenLength(8);
                    } else {
                      setInputMask('aa 9999.999.999');
                      setUenLength(10);
                    }
                    setUen(value);
                    setFieldValue('uen', uenValue);
                  }}
                  mask={inputMask}
                  isInputMask={true}
                  value={uen}
                />
              </div>
              <div className="cell small-3">
                <TTPInputField
                  theme={'orangeRoyal'}
                  name="name"
                  label={t('org.inscription.name')}
                  labelClassName={s.weight_400}
                  placeholder={t('org.inscription.name')}
                  required={true}
                />
              </div>
              <div className="cell small-3">
                <TTPInputField
                  theme={'orangeRoyal'}
                  name="abbreviation"
                  label={t('org.inscription.abbr')}
                  labelClassName={s.weight_400}
                  placeholder={t('org.inscription.abbr')}
                  required={false}
                />
              </div>
              <div className="cell small-3">
                <TTPInputField
                  theme={'orangeRoyal'}
                  name="url"
                  label={t('org.inscription.url')}
                  labelClassName={s.weight_400}
                  placeholder={t('org.inscription.url')}
                  required={false}
                />
              </div>
            </div>

            <hr className="separator m-x-0 m-y-l" />

            <div className="grid-x grid-margin-x">
              <div className="cell small-6">
                <TTPInputField
                  theme={'orangeRoyal'}
                  name="address"
                  label={t('org.inscription.address')}
                  labelClassName={s.weight_400}
                  placeholder={t('org.inscription.address')}
                  required={true}
                />
              </div>
              <div className="cell small-3">
                <TTPInputField
                  theme={'orangeRoyal'}
                  name="zipCode"
                  label={t('org.inscription.zip')}
                  labelClassName={s.weight_400}
                  placeholder={t('org.inscription.zip')}
                  required={true}
                />
              </div>
              <div className="cell small-3">
                <TTPSelectField
                  theme={'orangeRoyal'}
                  name="country"
                  label={t('org.inscription.country')}
                  labelClassName={s.weight_400}
                  options={countriesOptions}
                  placeholder={t('org.inscription.country')}
                  required={true}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                />
              </div>
            </div>

            <hr className="separator m-x-0 m-y-l" />

            <div className="grid-x grid-margin-x">
              <div className="cell small-3">
                <TTPSelectField
                  theme={'orangeRoyal'}
                  name="legalForm"
                  label={t('org.inscription.lf')}
                  labelClassName={s.weight_400}
                  options={legalFormOptions}
                  placeholder={t('org.inscription.lf')}
                  required={true}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                />
              </div>
              <div className="cell small-3">
                <TTPSelectField
                  theme={'orangeRoyal'}
                  name="sector"
                  isAsync={true}
                  cacheOptions
                  label={t('org.inscription.sector')}
                  labelClassName={s.weight_400}
                  loadOptions={loadSectorsSuggestions}
                  placeholder={t('org.inscription.sector')}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                  defaultOptions={
                    initialSectorOption && values.sector
                      ? [initialSectorOption]
                      : []
                  }
                  isClearable
                />
              </div>
              <div className="cell small-3">
                <TTPSelectField
                  theme={'orangeRoyal'}
                  name="vat"
                  label={t('org.inscription.vat')}
                  labelClassName={s.weight_400}
                  options={vatOptions}
                  placeholder={t('org.inscription.vat')}
                  required={true}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                />
              </div>
              <div className="cell small-3">
                <TTPSelectField
                  theme={'orangeRoyal'}
                  name="languages"
                  isMulti={true}
                  label={t('org.inscription.language')}
                  labelClassName={s.weight_400}
                  options={languageOptions}
                  placeholder={t('org.inscription.language')}
                  required={true}
                  textStyle={{ fontWeight: '400', textTransform: 'none' }}
                />
              </div>
            </div>
            <LocalLoader loading={isSubmitting || isValidating} />
          </LocalLoaderWrapper>
          <div className={cn('p-s p-x-l flex-container', actionsClassName)}>
            {onPreviousStep ? (
              <GoBack
                text={t('Previous step')}
                onClick={onPreviousStep}
                disabled={isSubmitting || isValidating}
                className="m-r-s"
              />
            ) : (
              <GoBack
                disabled={isSubmitting || isValidating}
                onClick={onCancel}
                text={t('Cancel')}
                hideIcon={true}
                className="m-r-s"
              />
            )}
            <div className="flex-container m-l-auto align-middle">
              {!isEdit && showPrivacyTerms && (
                <div>
                  <TTPCheckBoxField
                    theme={'orangeRoyal'}
                    name="privacyTerms"
                    value="1"
                    className={'m-r-l'}
                    label={
                      <span className={s.acceptConditions}>
                        {t('inscription.privacy_terms_p1')}&nbsp;
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={privacyTermsLink}
                        >
                          {t('inscription.privacy_terms_p2')}
                        </a>
                      </span>
                    }
                  />
                  <div className="yup-error">
                    <ErrorMessage
                      name="privacyTerms"
                      component="p"
                      className="m-x-0"
                    />
                  </div>
                </div>
              )}
              {(!isEdit || !data?.hasAgreedTerms) && (
                <div>
                  <TTPCheckBoxField
                    theme={'orangeRoyal'}
                    name="terms"
                    value="1"
                    className={'m-r-l'}
                    label={
                      <span className={s.acceptConditions}>
                        {t('inscription.terms_p1')}&nbsp;
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${TTP_HOME_URL}/privacy-and-terms`}
                        >
                          {t('org.inscription.terms.p2')}
                        </a>
                      </span>
                    }
                  />
                  <div className="yup-error">
                    <ErrorMessage
                      name="terms"
                      component="p"
                      className="m-x-0"
                    />
                  </div>
                </div>
              )}
              <NextStep
                className="m-l-auto"
                theme="orangeRoyal"
                text={t('Next step')}
                disabled={isSubmitting || isValidating}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationForm;
