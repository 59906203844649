import React, { memo } from 'react';
import styles from './Session.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import { activityName } from 'store/Slots/types';
import { isEmpty } from 'lodash';
import { getByLanguage } from 'utils';
import { Language } from 'store/types';

export interface Props {
  title: string;
  isSelected: boolean;
  type: string;
  isSoldOut?: boolean;
  language: Language;
  activityName?: activityName;
  isWebinar?: boolean;
  roomName?: string;
  renderActions: () => JSX.Element;
  renderTime: () => JSX.Element;
  onClick: (event: any) => void;
}

export default memo(function Session({
  title,
  isSelected,
  isSoldOut,
  language,
  activityName,
  isWebinar,
  roomName,
  renderTime,
  renderActions,
  onClick,
}: Props) {
  const activity = getByLanguage(activityName, 'name', language);

  const renderSlotMode = () => {
    if (isWebinar) {
      return (
        <div className={classNames(styles.block)}>
          <span>{_('Webinar')}</span>
        </div>
      );
    }

    if (!isEmpty(roomName)) {
      return (
        <div className={classNames(styles.block)}>
          <span>{roomName}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      onClick={onClick}
      className={classNames(styles.layout3, isSelected && styles.hilighted)}
    >
      <div>
        {isSoldOut && (
          <div className={styles.soldOut}>{_('session_sold_out')}</div>
        )}
        <div className={classNames(styles.sessionHeader, 'm-b-xs')}>
          <div className="flex-container">
            <div className={styles.block}>{renderTime()}</div>
            {!isEmpty(activity) && (
              <>
                <div className={styles.separator}>&#9679;</div>
                <div className={styles.block}>
                  <span>{activity}</span>
                </div>
              </>
            )}
          </div>
          <div>{renderSlotMode()}</div>
        </div>
        <h3>{title}</h3>
      </div>
      <div className={classNames('m-b-s, m-l-xxs')}>{renderActions()}</div>
    </div>
  );
});
