import {
  AUTH_COOKIE_NAME,
  APP_ENV,
  AUTH_LOW_SECURITY_COOKIE_NAME,
  TTP_HOME_URL,
  AUTH_OFFFCOURSE_COOKIE_NAME,
  TOKEN_LS_SCOPE,
  AUTH_LOW_SECURITY_OFFFCOURSE_COOKIE_NAME,
} from 'config';
import { AuthCookie, AppToken, Token } from 'store/types';
import { User, AuthState } from 'store/Auth/types';
import { redirectToHome } from './common';
import { isEmpty } from 'lodash';
import { AppInfoData } from 'store/Params/AppInfo';

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

export function setCookie(
  name: string,
  value: string,
  expires: Date,
  path: string,
  domain: string,
  secure = false,
  escapeValue = true,
) {
  document.cookie =
    name +
    ' = ' +
    (escapeValue ? escape(value) : value) +
    '  ' +
    (!expires ? '' : '; expires = ' + expires.toUTCString()) +
    (!path ? '' : '; path = ' + path) +
    (!domain ? '' : '; domain = ' + domain) +
    (secure === true ? '; secure' : '');
}

export const getDataFromCookie = (cookieName: string): AuthCookie | null => {
  const cookie = getCookie(cookieName);

  if (isEmpty(cookie)) {
    return null;
  }

  return JSON.parse(cookie);
};

/**
 * NOTE:
 * The TTP token contains timestamps in seconds (PHP)
 * JS timestamps are in ms
 */
export const isTTPTokenExpired = (token: Token | null) => {
  if (!token) return true;

  const { createdAt = NaN, expiresIn = NaN, expiry = NaN } = token;

  if (isNaN(+createdAt) || isNaN(+expiresIn)) {
    return true;
  }

  const now = Date.now();

  if (!isNaN(+expiry)) {
    const expiryMs = +expiry * 1000;
    return now >= expiryMs;
  }

  const createdAtMs = +createdAt * 1000;
  const expiresInMs = +expiresIn * 1000;

  return now >= createdAtMs + expiresInMs;
};

export const getAuthInfosFromCookie = (
  cookieName: string = AUTH_COOKIE_NAME,
) => {
  const authInfos = getDataFromCookie(cookieName);

  return { authInfos, expired: isTTPTokenExpired(authInfos) };
};

export const getAuthInfosByPriority = (isOfffcourse: boolean) => {
  const hsCookieData = getAuthInfosFromCookie(
    isOfffcourse ? AUTH_OFFFCOURSE_COOKIE_NAME : AUTH_COOKIE_NAME,
  );
  const lsCookieData = getAuthInfosFromCookie(
    isOfffcourse
      ? AUTH_LOW_SECURITY_OFFFCOURSE_COOKIE_NAME
      : AUTH_LOW_SECURITY_COOKIE_NAME,
  );

  let authInfos = { ...hsCookieData, isLowSecurity: false };
  if (hsCookieData.expired && !lsCookieData.expired) {
    authInfos = { ...lsCookieData, isLowSecurity: true };
  }

  return authInfos;
};

export const getTokenFromAuthApp = (authInfos: any): AppToken => {
  return {
    token: authInfos?.access_token,
    expiresIn: authInfos?.expires_in,
    createdAt: authInfos?.createdAt,
    scope: authInfos?.scope,
    tokenType: authInfos?.token_type,
  };
};

export const getLocalStorageUser = (): User => {
  const stringUser = localStorage.getItem('user');
  let user;

  if (stringUser) {
    try {
      user = JSON.parse(stringUser);
    } catch (e) {}
  }

  return user instanceof Object ? user : {};
};

export const getLocalStorageAuth = (): AuthState => {
  const stringAuth = localStorage.getItem('auth');
  let auth;

  if (stringAuth) {
    try {
      auth = JSON.parse(stringAuth);
    } catch (e) {}
  }

  return typeof auth === 'object' ? auth : {};
};

export const setLocalStorageAuth = (auth: AuthState | null) => {
  if (typeof auth === 'object') {
    localStorage.setItem('auth', JSON.stringify(auth));
    return auth;
  }
  if (auth == null) {
    localStorage.removeItem('auth');
  }
};

export const logout = (
  appInfo: AppInfoData,
  gotToHome: boolean = false,
  path: string = '',
  forceHomeLogout: boolean = false,
) => {
  setLocalStorageAuth(null);

  const isOfffcourse = appInfo.id === 'OFFFCOURSE';
  const dtExpire = new Date();
  dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

  setCookie(
    isOfffcourse ? AUTH_OFFFCOURSE_COOKIE_NAME : AUTH_COOKIE_NAME,
    '',
    dtExpire,
    '/',
    appInfo.domain,
  );
  setCookie(
    isOfffcourse
      ? AUTH_LOW_SECURITY_OFFFCOURSE_COOKIE_NAME
      : AUTH_LOW_SECURITY_COOKIE_NAME,
    '',
    dtExpire,
    '/',
    appInfo.domain,
  );
  setCookie(`ttp_session_${APP_ENV}`, '', dtExpire, '/', appInfo.domain);
  setCookie('XSRF-TOKEN', '', dtExpire, '/', appInfo.domain);
  setCookie('laravel_session', '', dtExpire, '/', appInfo.domain);

  if (forceHomeLogout) {
    window.location.assign(
      encodeURI(`${TTP_HOME_URL}/logout?goto=${appInfo.url}`),
    );
  } else if (gotToHome) {
    setTimeout(() => {
      redirectToHome(appInfo, path);
    }, 200);
  } else if (path.length > 0) {
    window.location.assign(encodeURI(appInfo.url + path));
  }
};

export const setAuthCookie = (
  { id, firstName, lastName, mainEmail, token }: User,
  appInfo: AppInfoData,
  scope?: string,
) => {
  const isOfffcourse = appInfo.id === 'OFFFCOURSE';
  const { expires, access_token } = token;
  const dtExpireCookie = new Date();
  dtExpireCookie.setTime(+expires * 1000);

  const tokenCreatedAt = new Date();
  const tokenExpiresIn = +expires - Math.floor(tokenCreatedAt.getTime() / 1000);

  const authInfos = {
    createdAt: Math.floor(tokenCreatedAt.getTime() / 1000),
    expiresIn: tokenExpiresIn,
    email: mainEmail,
    fullName: `${firstName} ${lastName}`,
    id,
    token: access_token,
  };

  if (scope === TOKEN_LS_SCOPE) {
    setCookie(
      isOfffcourse
        ? AUTH_LOW_SECURITY_OFFFCOURSE_COOKIE_NAME
        : AUTH_LOW_SECURITY_COOKIE_NAME,
      encodeURIComponent(JSON.stringify(authInfos)),
      dtExpireCookie,
      '/',
      appInfo.domain,
      false,
      false,
    );
  } else {
    setCookie(
      isOfffcourse ? AUTH_OFFFCOURSE_COOKIE_NAME : AUTH_COOKIE_NAME,
      encodeURIComponent(JSON.stringify(authInfos)),
      dtExpireCookie,
      '/',
      appInfo.domain,
      false,
      false,
    );
  }
};
