import { EventOption } from 'interfaces/EventOption';
import { useMemo } from 'react';
import { EventPlanAndOptions } from 'store/Events/types';
import { GuestData } from '../services';

export const useSelectedOptions = (
  data: Pick<GuestData, 'options'>,
  plansAndOptions: EventPlanAndOptions | null,
) =>
  useMemo(
    () =>
      data.options?.reduce((acc, option) => {
        const op = plansAndOptions?.options.find((op) => +op.id === +option);
        if (!op) return acc;
        return [...acc, op];
      }, [] as EventOption[]) ?? [],
    [data.options, plansAndOptions?.options],
  );
