import { setShowNotificationBar } from './actions';
import {
  NotificationBarActionTypes,
  NotificationBarState,
  SET_SHOW_NOTIFICATION_BAR,
  SET_NOTIFICATION_BAR_DATA,
  RESET_NOTIFICATION_BAR,
} from './types';

const initialState = {
  isDisplayed: false,
  data: {
    message: '',
  },
  route: {
    path: '/',
    exact: true,
  },
};

const reducer = (
  state: NotificationBarState = initialState,
  action: NotificationBarActionTypes,
) => {
  switch (action.type) {
    case RESET_NOTIFICATION_BAR: {
      return { ...initialState };
    }
    case SET_SHOW_NOTIFICATION_BAR: {
      return { ...state, isDisplayed: action.isDisplayed };
    }
    case SET_NOTIFICATION_BAR_DATA: {
      return {
        ...state,
        data: action.data,
        route: action.route,
        isDisplayed: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
