import { SetShowFaqAction, SET_SHOW_FAQ, FaqState } from './types';

const reducer = (
  state: FaqState = { showFaq: null },
  action: SetShowFaqAction,
) => {
  switch (action.type) {
    case SET_SHOW_FAQ: {
      return { ...state, showFaq: action.showFaq };
    }
    default:
      return state;
  }
};

export default reducer;
