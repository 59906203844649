import {
  EventsCurrentHomeState,
  FETCH_EVENTS_CURRENT_FULFILLED,
  FETCH_EVENTS_CURRENT_PENDING,
  FETCH_EVENTS_CURRENT_REJECTED,
  SET_EVENTS_CURRENT_PAGE_SIZE,
  SET_EVENTS_CURRENT_PAGE,
} from './types';
import { AnyAction } from 'redux';

const initialState = {
  items: [],
  fetching: false,
  fetched: false,
  error: null,
  nbResult: 0,
  page: 1,
  pageSize: 8,
};

export const EventsCurrentReducer = (
  state: EventsCurrentHomeState = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_EVENTS_CURRENT_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case SET_EVENTS_CURRENT_PAGE:
      return { ...state, page: action.payload };
    case FETCH_EVENTS_CURRENT_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_EVENTS_CURRENT_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items:
          state.page === 1
            ? data[0] ?? []
            : [...state.items, ...(data[0] ?? [])],
        nbResult: nbResult,
      };
    }
    case FETCH_EVENTS_CURRENT_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default EventsCurrentReducer;
