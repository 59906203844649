import React, { useEffect, useRef, useState } from 'react';
import s from './StepPayment.module.scss';
import cn from 'classnames';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsContent from '../../../Common/StepsContent';
import StepsControl from '../../../Common/StepsControl';
import { Guest } from 'store/Guests/types';
import { closeModal } from 'store/Modal/actions';
import { REGISTRATION_FULL_STEPS, StepProps } from '../../services';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from 'store/Auth';
import { RootState } from 'store/types';

interface Props extends Omit<StepProps, 'nextStep'> {
  guest: Guest;
  goToStep: (step: REGISTRATION_FULL_STEPS) => void;
  getGuestData: () => void;
}

const StepPayment = ({
  guest,
  previousStep,
  goToStep,
  getGuestData,
}: Props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => selectToken(state));
  const [showTerminate, setShowTerminate] = useState(false);

  useEffect(() => {
    if (isEmpty(guest.paymentUrl)) {
      goToStep(REGISTRATION_FULL_STEPS.ADDRESS);
    }
  }, [goToStep, guest.paymentUrl]);

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      const eventId = e?.data?.event;
      const paymentIframe = document.getElementById(
        'payment-full-iframe',
      ) as HTMLIFrameElement;

      switch (eventId) {
        case 'PAYMENT_ONLINE':
          setShowTerminate(false);
          break;
        case 'PAYMENT_TRANSFER':
          setShowTerminate(true);
          break;
        case 'PAYMENT_ORDERS':
          setShowTerminate(false);
          break;
        case 'RETRY_PAYMENT':
          setShowTerminate(false);
          paymentIframe.src += '';
          break;
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  const handleFinish = () => {
    dispatch(closeModal());
    getGuestData();
  };

  return (
    <StepsContent>
      <div className={s.wrapper}>
        <iframe
          id="payment-full-iframe"
          title="payment"
          src={`${guest.paymentUrl}&token=${token}&embedded=1`}
        ></iframe>
      </div>
      <StepsControl className={s.actions}>
        <div className={cn('p-s p-x-l flex-container')}>
          <GoBack onClick={() => goToStep(previousStep)} className="m-r-s" />
          {showTerminate && (
            <NextStep
              className="m-l-auto"
              onClick={handleFinish}
              text={t('Finish')}
              hideIcon={true}
            />
          )}
        </div>
      </StepsControl>
    </StepsContent>
  );
};

export default StepPayment;
