import {
  LanguageFilterType,
  SET_LANGUAGE_FILTER,
  SubMenuActionTypes,
} from './types';

export const setLanguageFilter = (
  filter: LanguageFilterType,
): SubMenuActionTypes => ({
  type: SET_LANGUAGE_FILTER,
  filter: filter,
});

export default setLanguageFilter;
