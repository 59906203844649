const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  items: [],
  deleting: false,
  deleted: true,
  error: null,
  editMode: false,
  nbResult: 0,
  paginationPage: 1,
  paginationTotalPages: 1,
};
export const mediasReducer = (state = initialState, action) => {
  switch (action.type) {
    case `RESET_MEDIAS`: {
      return initialState;
    }
    case `FETCH_MEDIAS_PENDING`: {
      return { ...state, fetched: false, fetching: true };
    }
    case `FETCH_MEDIAS_FULFILLED`: {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case `FETCH_MEDIAS_REJECTED`: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    case `ADD_MEDIA`: {
      const { media } = action;
      return {
        ...state,
        editMode: true,
        items: [...state.items, media],
      };
    }
    case `DELETE_MEDIA`: {
      const { id } = action;
      return {
        ...state,
        items: state.items.filter((item) => parseInt(item.id) !== parseInt(id)),
      };
    }
    case `SAVE_DOC_FULFILLED`: {
      return {
        ...state,
        editMode: false,
      };
    }
    case `SAVE_MEDIA_FULFILLED`: {
      const { data } = action.payload.data;

      // let isLogo = false;
      const help = { ...data };
      if (data.objectType === 'MASK' || data.objectType === 'LOGO') {
        help.docType = data.objectType;
        // isLogo = true;
      }

      const media = state.items.find((item) => item.id === data.id);
      if (!media) {
        // new uploaded media
        return {
          ...state,
          // items: isLogo ? [...state.items, help] : [help, ...state.items],
          items: [help, ...state.items],
          saved: true,
          saving: false,
          nbResult: state.nbResult + 1,
        };
      }

      const newMedia = { ...media, ...data };
      const newMedias = state.items.map((item) => {
        if (item.id === newMedia.id) {
          return newMedia;
        }

        return item;
      });

      return {
        ...state,
        saved: true,
        saving: false,
        items: newMedias,
      };
    }

    default:
      return state;
  }
};

const uploadingMediasInitialState = {
  // fetching: false,
  // fetched: false,
  items: [],
  // error: null,
  // nbResult: 0,
  // paginationPage: 1,
  // paginationTotalPages: 1
};
export const uploadingMediasReducer = (
  state = uploadingMediasInitialState,
  action,
) => {
  switch (action.type) {
    case 'ADD_UPLOADING_MEDIAS': {
      return { ...state, items: state.items.concat(action.items) };
    }
    case 'SET_UPLOADING_MEDIAS': {
      return { ...state, items: action.items };
    }
    default:
      return state;
  }
};

export const partnerDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case `RESET_DOCS`: {
      return initialState;
    }
    case `FETCH_DOCS_PENDING`: {
      return { ...state, fetched: false, fetching: true };
    }
    case `FETCH_DOCS_FULFILLED`: {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case `FETCH_DOCS_REJECTED`: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        error,
        items: [],
        nbResult: 0,
      };
    }

    case `SAVE_DOC_PENDING`: {
      return { ...state, saving: true };
    }

    case `SAVE_DOC_FULFILLED`: {
      const { data } = action.payload.data;

      const help = { ...data };

      const media = state.items.find((item) => item.id === data.id);
      if (!media) {
        // new uploaded media
        return {
          ...state,
          saving: false,
          saved: true,
          items: [help, ...state.items],
          nbResult: state.nbResult + 1,
        };
      }

      const newMedia = { ...media, ...data };
      const newMedias = state.items.map((item) => {
        if (item.id === newMedia.id) {
          return newMedia;
        }
        return item;
      });

      return {
        ...state,
        saving: false,
        saved: true,
        items: newMedias,
      };
    }
    case `DELETE_DOC_PENDING`: {
      return { ...state, deleting: true };
    }

    case `DELETE_DOC_FULFILLED`: {
      const { deletedId } = action.payload.data;

      return {
        ...state,
        deleting: false,
        deleted: true,
        items: state.items.filter(
          (item) => parseInt(item.id) !== parseInt(deletedId),
        ),
      };
    }

    case `SET_DOCS_SAVED`: {
      return { ...state, saved: action.saved };
    }

    default:
      return state;
  }
};

const initialViewerState = {
  medias: null,
  currentMedia: null,
  isOpen: false,
  area: null,
};

export const mediaViewerReducer = (state = initialViewerState, action) => {
  switch (action.type) {
    case 'CLOSE_MEDIA_VIEWER': {
      return initialViewerState;
    }
    case 'OPEN_MEDIA_VIEWER': {
      return { ...state, ...action.viewer };
    }
    case 'SET_CURRENT_MEDIA_VIEWER': {
      return { ...state, currentMedia: action.media };
    }
    default:
      return state;
  }
};

const initialFavState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  items: [],
  error: null,
  nbResult: 0,
  paginationPage: 1,
  paginationTotalPages: 1,
};
export const favReducer = (state = initialFavState, action) => {
  switch (action.type) {
    case `RESET_FAVS`: {
      return initialFavState;
    }

    case `FETCH_FAV_DOCS_PENDING`: {
      return { ...state, fetched: false, fetching: true };
    }
    case `FETCH_FAV_DOCS_FULFILLED`: {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case `FETCH_FAV_DOCS_REJECTED`: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        error,
      };
    }
    case `ADD_TO_FAV_PENDING`: {
      return { ...state, saved: false, saving: true };
    }
    case `ADD_TO_FAV_FULFILLED`: {
      if (action.payload.data && action.payload.data.deletedId) {
        return state;
      }
      const { data } = action.payload.data;

      const object = state.items.find((item) => item.id === data.id);
      if (!object) {
        const a = [data, ...state.items];
        const o2 = a.sort((a, b) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });
        return {
          ...state,
          saving: false,
          saved: true,
          items: o2,
          nbResult: state.nbResult + 1,
        };
      }

      const newObj = { ...object, ...data };
      const newObjs = state.items.map((item) => {
        return item.id === newObj.id ? newObj : item;
      });

      const o2 = newObjs.sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      });

      return {
        ...state,
        items: o2,
      };
    }
    case `ADD_TO_FAV_REJECTED`: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 500:
          error = {
            title: error.response.data.title,
            code: 500,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        saved: false,
        error,
      };
    }
    case `REDUCE_FAV`: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.id),
        nbResult: state.nbResult - 1,
      };
    }

    default:
      return state;
  }
};

const initialCommentState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  items: [],
  error: null,
  nbResult: 0,
  paginationPage: 1,
  paginationTotalPages: 1,
};
export const commentReducer = (state = initialCommentState, action) => {
  switch (action.type) {
    case `RESET_COMMENTS`: {
      return initialCommentState;
    }

    case `FETCH_COMMENTS_PENDING`: {
      return { ...state, fetched: false, fetching: true };
    }
    case `FETCH_COMMENTS_FULFILLED`: {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case `FETCH_COMMENTS_REJECTED`: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    case `SAVE_COMMENT_PENDING`: {
      return { ...state, saved: false, saving: true };
    }
    case `SAVE_COMMENT_FULFILLED`: {
      const { data } = action.payload.data;

      const object = state.items.find((item) => item.id === data.id);
      if (!object) {
        const a = [data, ...state.items];
        const o2 = a.sort((a, b) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });
        return {
          ...state,
          saving: false,
          saved: true,
          items: o2,
          nbResult: state.nbResult + 1,
        };
      }

      const newObj = { ...object, ...data };
      const newObjs = state.items.map((item) => {
        return item.id === newObj.id ? newObj : item;
      });

      const o2 = newObjs.sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      });

      return {
        ...state,
        items: o2,
      };
    }
    case `SAVE_COMMENT_REJECTED`: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 500:
          error = {
            title: error.response.data.title,
            code: 500,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        saved: false,
        error,
      };
    }
    default:
      return state;
  }
};
