import { AuthState } from './Auth/types';
import { AnyAction } from 'redux';
import { ParamsState } from './Params';
import { ThunkAction } from 'redux-thunk';
import { RouterState } from 'connected-react-router';
import { NotifsState } from './Notifs/types';
import { Event, EventPlanAndOptions, EventState } from './Events/types';
import { PaginationState } from './Pagination/types';
import { FilterState } from './Filter/types';
import { Speaker } from './Speakers/types';
import { SlotState, Slot } from './Slots/types';
import { ResourceState } from './Resource/types';
import { Guest, GuestState } from './Guests/types';
import { Partner } from './Partners/types';
import { PartnerAd, PartnerAdState } from './PartnerAds/types';
import { PartnerLeadState } from './PartnerLeads/types';
import { GuestProgramState } from './Program/types';
import { ModalState } from './Modal/types';
import { EventAccess } from './Event/types';
import { DataResourceState } from './Resource/DataResource/types';
import { Market } from './Market/types';
// import { RegistrationState } from './Events/Registration/types';
import { Cycle } from './Cycle/types';
import { FormState } from './Form';
import { SubscriptionState } from './Subscription/types';
import { SubMenuState } from './SubMenu/types';
import { ICSFileType } from './ICalendar/types';
import { EventHandouts } from 'interfaces/SlotFile';
import { Scan } from 'interfaces/Scan';
import { EventPack } from 'interfaces/EventPack';
import { EventOption } from 'interfaces/EventOption';
import { EventPlan } from 'interfaces/EventPlan';
import { CollaboratorsState } from './Collaborators/types';
import { PacksState } from './Packs/types';
import { Address } from 'interfaces/Address';
import { EventActivity } from 'interfaces/EventActivity';
import { CouponModel } from 'interfaces/CouponModel';
import { PartnerGuest } from 'interfaces/PartnerGuest';
import { QuestionsState } from './Evaluation/Question/types';
import { AnswersState } from './Evaluation/Answer/types';
import { RelatedArticlesState } from './Articles/types';
import { Channel } from './Channel/types';
import { EventsFutureHomeState } from './Home/Future/types';
import { EventsReplayHomeState } from './Home/Replay/types';
import { EventsCurrentHomeState } from './Home/Current/types';
import { EventsTagsState } from './EventsTags/types';
import { OrderState } from './Orders/types';
import { EventsCategoriesState } from './EventsCategories/types';
import { CategoryEvents } from 'interfaces/CategoryEvents';
import { RubricsState } from './Rubrics/types';
import { TagsState } from './EventTags/types';
import { UserPersonalDataState } from './User/types';

export type RootState = Readonly<{
  router: RouterState;
  auth: AuthState;
  params: ParamsState;
  subscriptions: SubscriptionState;
  notifs: NotifsState;
  slots: SlotState;
  event: {
    eventIds: ResourceState<EventAccess>;
    event: ResourceState<Event>;
    slots: ResourceState<Slot>;
    speakers: ResourceState<Speaker>;
    partners: ResourceState<Partner>;
    partnersStructural: ResourceState<Partner>;
    searchPartners: ResourceState<Partner>;
    currentPartner: ResourceState<Partner>;
    guests: GuestState;
    guestProgram: GuestProgramState;
    market: DataResourceState<Market>;
    packs: ResourceState<EventPack>;
    plansAndOptions: DataResourceState<EventPlanAndOptions>;
    activities: ResourceState<EventActivity>;
    couponModels: ResourceState<CouponModel>;
    partnerGuests: ResourceState<PartnerGuest>;
    categoryEvents: ResourceState<CategoryEvents>;
  };
  guest: {
    // TODO: move all related user data to guest
    shippingAddress: ResourceState<Address>;
  };
  cycle: {
    speakers: ResourceState<Speaker>;
    allCycles: ResourceState<Cycle>;
    cycles: ResourceState<Cycle>;
    premiumOffers: ResourceState<Cycle>;
    seasons: ResourceState<Cycle>;
    essentials: ResourceState<Cycle>;
  };
  recommendation: ResourceState<SliderData>;
  events: {
    list: EventState;
    userList: ResourceState<Event>;
    userFavoriteList: EventState;
    search: EventState;
  };
  partnersAds: {
    current: PartnerAdState;
    global: ResourceState<PartnerAd>;
  };
  speakers: ResourceState<Speaker>;
  partnerLeads: {
    daily: PartnerLeadState;
    current: PartnerLeadState;
    list: PartnerLeadState;
  };
  modal: ModalState;
  medias: any; // js reducer
  forms: FormState;
  subMenu: SubMenuState;
  iCalendar: DataResourceState<ICSFileType>;
  handouts: ResourceState<EventHandouts>;
  scans: ResourceState<Scan>;
  collaborators: CollaboratorsState;
  packs: PacksState;
  evaluations: {
    questions: QuestionsState;
    answers: AnswersState;
  };
  channel: {
    channels: ResourceState<Channel>;
  };
  home: {
    future: EventsFutureHomeState;
    replay: EventsReplayHomeState;
    current: EventsCurrentHomeState;
  };
  categories: EventsCategoriesState;
  tags: EventsTagsState;
  orders: OrderState;
  EventArticles: RelatedArticlesState;
  rubrics: RubricsState;
  eventTags: TagsState;
  userData: UserPersonalDataState;
}>;

export interface WithPaginationAndFilterState
  extends PaginationState,
    FilterState {}

export interface NameWrappedAction {
  meta: string;
}

export type EventReducerNames =
  | 'SEARCH'
  | 'LIST'
  | 'FAVORITE_LIST'
  | 'RECOMMENDED'
  | 'POPULAR'
  | 'SPONSORED';

export const EVENT_RESOURCE = 'EVENT';
export const EVENT_STRUCTURAL_PARTNERS_RESOURCE = 'EVENT_STRUCTURAL_PARTNERS';
export const EVENT_PARTNERS_RESOURCE = 'EVENT_PARTNERS';
export const EVENT_PARTNERS_SEARCH_RESOURCE = 'EVENT_SEARCH_PARTNERS';
export const EVENT_CURRENT_PARTNER_RESOURCE = 'EVENT_CURRENT_PARTNER';
export const EVENT_PLANS_RESOURCE = 'EVENT_PLANS';
export const EVENT_OPTIONS_RESOURCE = 'EVENT_OPTIONS';
export const EVENT_PACKS_RESOURCE = 'EVENT_PACKS';
export const EVENT_PLANS_AND_OPTIONS_RESOURCE = 'EVENT_PLANS_AND_OPTIONS';
export const EVENT_IDS_RESOURCE = 'EVENT_IDS';
export const SPEAKER_RESOURCE = 'SPEAKERS';
export const RECOMMENDATION_SLIDER_RESOURCE = 'RECOMMENDATION_SLIDER_RESOURCE';
export const CYCLE_RESOURCE = 'CYCLE';
export const ALL_CYCLES_RESOURCE = 'ALL_CYCLES_RESOURCE';
export const INVOICING_RESOURCE = 'INVOICING';
export const EVENT_SPEAKER_RESOURCE = 'EVENT_SPEAKERS';
export const EVENT_ACTIVITIES_RESOURCE = 'EVENT_ACTIVITIES';
export const EVENT_COUPON_MODELS_RESOURCE = 'EVENT_COUPON_MODELS';
export const CYCLE_SPEAKER_RESOURCE = 'CYCLE_SPEAKER';
export const EVENT_SLOT_RESOURCE = 'EVENT_SLOTS';
export const SLOTS_RESOURCE = 'SLOTS';
export const PARTNERS_ADS_RESOURCE = 'PARTNERS_ADS';
export const PARTNERS_DOCS_RESOURCE = 'PARTNERS_DOCS';
export const PARTNER_GUESTS_RESOURCE = 'PARTNER_GUESTS';
export const MARKET_DATA_RESOURCE = 'MARKET_DATA_RESOURCE';
export const ICS_FILE_RESOURCE = 'ICS_FILE_RESOURCE';
export const EVENT_HANDOUTS_RESOURCE = 'EVENT_HANDOUTS_RESOURCE';
export const SCANS_RESOURCE = 'SCANS_RESOURCE';
export const CHANNEL_RESOURCE = 'CHANNEL_RESOURCE';
export const PREMIUM_OFFER_RESOURCE = 'PREMIUM_OFFER_RESOURCE';
export const SEASON_RESOURCE = 'SEASON_RESOURCE';
export const CATEGORY_EVENTS_RESOURCE = 'CATEGORY_EVENTS_RESOURCE';
export const ESSENTIAL_RESOURCE = 'ESSENTIAL_RESOURCE';

export type PartnersReducerNames =
  | typeof EVENT_STRUCTURAL_PARTNERS_RESOURCE
  | typeof EVENT_PARTNERS_RESOURCE
  | typeof EVENT_CURRENT_PARTNER_RESOURCE
  | typeof EVENT_PARTNERS_SEARCH_RESOURCE;

export type GenericReducerTypes =
  | EventReducerNames
  | PartnersReducerNames
  | typeof EVENT_RESOURCE
  | typeof EVENT_IDS_RESOURCE
  | typeof EVENT_PLANS_RESOURCE
  | typeof EVENT_OPTIONS_RESOURCE
  | typeof EVENT_PACKS_RESOURCE
  | typeof EVENT_PLANS_AND_OPTIONS_RESOURCE
  | typeof SPEAKER_RESOURCE
  | typeof RECOMMENDATION_SLIDER_RESOURCE
  | 'LIST_FOR_USER'
  | 'SHIPPING_ADDRESS_RESOURCE'
  | typeof CYCLE_RESOURCE
  | typeof ALL_CYCLES_RESOURCE
  | typeof INVOICING_RESOURCE
  | typeof EVENT_SPEAKER_RESOURCE
  | typeof EVENT_ACTIVITIES_RESOURCE
  | typeof EVENT_COUPON_MODELS_RESOURCE
  | typeof CYCLE_SPEAKER_RESOURCE
  | typeof EVENT_SLOT_RESOURCE
  | typeof PARTNERS_ADS_RESOURCE
  | typeof PARTNERS_DOCS_RESOURCE
  | typeof PARTNER_GUESTS_RESOURCE
  | typeof MARKET_DATA_RESOURCE
  | typeof SLOTS_RESOURCE
  | typeof ICS_FILE_RESOURCE
  | typeof EVENT_HANDOUTS_RESOURCE
  | typeof SCANS_RESOURCE
  | typeof CHANNEL_RESOURCE
  | typeof PREMIUM_OFFER_RESOURCE
  | typeof SEASON_RESOURCE
  | typeof CATEGORY_EVENTS_RESOURCE
  | typeof ESSENTIAL_RESOURCE;

export interface Organization {
  id: number;
  abbreviation?: string;
  name: string;
  url: string;
  avatarUrl?: string;
  avatarWebPath?: string;
  owner?: Organization;
}

export interface AppToken {
  token: string;
  createdAt?: string;
  expiresIn?: string;
  scope: string;
  tokenType: string;
}

export interface Token {
  token: string;
  expiresIn?: string;
  createdAt?: string;
  expiry?: string;
}

export interface AuthCookie {
  token: string;
  id: number;
  email: string;
  expiresIn: string;
  createdAt: string;
  fullName: string;
}

export interface SliderData {
  id: number; // Fake id: Any Resource must have an id
  requestTime: string;
  cycles: Cycle[];
  events: Event[];
  taxTvShow: Event[];
}

export type TTPSort = Record<string, string | React.ReactText[]>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
  // Action<string>
>;

export type ResourceData<T> = {
  [key in keyof T]?: T[key];
};

export interface ServerData {
  data: Array<any> | any;
}

export type Language = 'fr' | 'en' | 'nl';
export type ApiBoolean = boolean | 1 | 0 | '1' | '0';

export type AppReducer<S, A> = (state: S, action: A) => S;

export type Nullable<T> = T | undefined | null;
