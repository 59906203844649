import React from 'react';
import styles from '../GuestAddress.module.scss';
import s from './AddAddress.module.scss';
import cn from 'classnames';
import { TTPInput } from 'components/Common/TTPForm';
import _ from 'i18n';
import { LayoutTheme } from 'components/Layout/services';
import t from 'i18n';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { useAddAddress } from './hooks/useAddAddress';
import { Address } from 'interfaces/Address';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'components/Common/LocalLoader/LocalLoader';

type AddressData = Partial<Address>;

interface Props {
  theme?: LayoutTheme;
  horizontalInputs?: boolean;
  onSubmit: (data: AddressData) => void;
  onCancel: () => void;
}

export const AddShippingAddress = ({
  theme,
  horizontalInputs = true,
  onSubmit,
  onCancel,
}: Props) => {
  const {
    data,
    errors,
    saving,
    handleSubmit,
    handleChange,
    onFieldBlur,
  } = useAddAddress<AddressData>(
    validationSchema,
    {
      fullName: '',
      // number: '',
      street: '',
      // address2: '',
      // province: '',
      city: '',
      country: 'BE',
      zipCode: '',
    },
    onSubmit,
  );

  const inputFields: {
    name: keyof AddressData;
    label: string;
    required: boolean;
  }[] = [
    {
      name: 'fullName',
      label: _('inscription.shipping.fullname'),
      required: true,
    },
    // {
    //   name: 'number',
    //   label: _('inscription.shipping.number'),
    //   required: false,
    // },
    {
      name: 'street',
      label: _('inscription.shipping.street'),
      required: true,
    },
    // {
    //   name: 'address2',
    //   label: _('inscription.shipping.address2'),
    //   required: false,
    // },
    // {
    //   name: 'province',
    //   label: _('inscription.shipping.province'),
    //   required: false,
    // },
    {
      name: 'city',
      label: _('inscription.shipping.city'),
      required: true,
    },
    // {
    //   name: 'country',
    //   label: _('inscription.shipping.country'),
    //   required: true,
    // },
    {
      name: 'zipCode',
      label: _('inscription.shipping.zipCode'),
      required: true,
    },
  ];

  return (
    <LocalLoaderWrapper className="m-b-s">
      {inputFields.map((field) => (
        <TTPInput
          key={field.name}
          theme={theme}
          wrapperClassName="m-b-xs"
          name={field.name}
          label={field.label}
          required={field.required}
          isHorizontal={horizontalInputs}
          labelClassName={styles.inputLabel}
          onChange={handleChange}
          onBlur={onFieldBlur}
          value={data[field.name]}
        >
          {!isEmpty(errors[field.name]) && <p>{errors[field.name]}</p>}
        </TTPInput>
      ))}
      <div className={cn(s.actions)}>
        <button
          className={s.cancel}
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          {t('Cancel')}
        </button>
        <button className={s.submit} onClick={handleSubmit}>
          {t('Add')}
        </button>
      </div>
      <LocalLoader loading={saving} style={{ zIndex: 100 }} />
    </LocalLoaderWrapper>
  );
};

const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .label(t('inscription.shipping.fullname'))
    .min(3, t('yup.min'))
    .required(t('yup.required')),
  number: yup.string().label(t('inscription.shipping.number')),
  street: yup
    .string()
    .label(t('inscription.shipping.street'))
    .required(t('yup.required')),
  address2: yup.string().label(t('inscription.shipping.address2')),
  province: yup.string().label(t('inscription.shipping.province')),
  city: yup
    .string()
    .label(t('inscription.shipping.city'))
    .required(t('yup.required')),
  country: yup
    .string()
    .label(t('inscription.shipping.country'))
    .required(t('yup.required')),
  zipCode: yup
    .string()
    .label(t('inscription.shipping.zipCode'))
    .min(3, t('yup.min'))
    .required(t('yup.required')),
});

export default AddShippingAddress;
