import { Organization } from 'interfaces/Organization';
import { ROLE_TYPE, ROLE_TYPE_STATUS, UserRole } from 'store/Auth/types';
import { isEmpty, min } from 'lodash';
import { User } from 'store/Auth/types';

export const getUserNameForAvatar = (
  firstName: string | null = '',
  lastName: string | null = '',
) => {
  const fName = firstName?.split(' ') ?? [];

  if (fName.length >= 3) {
    return extractFirstLetter(fName, 3);
  }

  const lName = lastName?.split(' ') ?? [];
  return extractFirstLetter(fName.concat(lName), 3);
};

function extractFirstLetter(arrayStr: string[], length: number) {
  const lettersCount = min([arrayStr.length, length]) ?? 0;
  let result = '';

  for (let i = 0; i < lettersCount; i++) {
    result += arrayStr[i].substring(0, 1);
  }
  return result.toUpperCase();
}

/**
 * Get the user organizations of a specific role type and type status.
 * @param userRoles
 * @param roleType
 * @returns the extracted organizations
 */
export const getOrganizationsOfRoleType = (
  userRoles: UserRole[],
  roleType: ROLE_TYPE,
  roleTypeStatus: ROLE_TYPE_STATUS[],
  isDesc: boolean = true,
): Organization[] => {
  const organizations: Organization[] = [];

  userRoles.map(({ type, typeStatus, organization }) => {
    if (
      type === roleType &&
      roleTypeStatus.includes(typeStatus as ROLE_TYPE_STATUS)
    ) {
      organizations.push(organization as Organization);
    }
  });

  if (isDesc) {
    return organizations.reverse();
  }

  return organizations;
};

export const hasMissingFields = (
  fields: (keyof User)[],
  user?: User | null,
) => {
  if (!user) {
    return true;
  }

  return fields.some((field) => {
    let value = user[field];

    if (typeof value === 'number') {
      return false;
    }

    if (typeof value === 'string') {
      value = value.trim();
    }

    return isEmpty(value);
  });
};
