import { TTP_API_URL } from 'config';
import axios from 'axios';
import { Address } from 'interfaces/Address';
import { hydrateFormData } from 'store/services';

export const saveAddress = ({
  token,
  userId,
  data,
}: {
  token: string;
  userId?: number;
  data: Partial<Address>;
}) => {
  const requestUrl = `${TTP_API_URL}/profile/address`;

  const formData = new FormData();
  formData.append('access_token', token);

  let _data: any = { ...data };
  if (userId) {
    _data = { ..._data, user: userId };
  }

  hydrateFormData(formData, _data);

  return axios.post(requestUrl, formData);
};

export const validateOrganizationNumber = ({
  token,
  uen,
}: {
  token: string;
  uen: string;
}) => {
  const requestUrl = TTP_API_URL + `/billing/document/validate-uen`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      uen,
    },
  });
};
