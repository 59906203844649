import {
  CREATE_PARTNER_LEAD,
  FETCH_PARTNER_LEAD,
  FETCH_USER_PARTNERS_LEAD,
  USER_JOINED,
  PartnerLeadActionTypes,
  UPDATE_PARTNER_LEAD,
  PUSH_PARTNER_LEAD,
  DELETE_PARTNER_LEAD,
  PartnerLead,
  CLEAR_USER_JOINED,
  REMOVE_PARTNER_LEAD,
} from './types';

export const fetchPartnerLeads = (resp: any): PartnerLeadActionTypes => ({
  type: FETCH_PARTNER_LEAD,
  payload: resp,
});

export const fetchUserPartnersLeads = (resp: any): PartnerLeadActionTypes => ({
  type: FETCH_USER_PARTNERS_LEAD,
  payload: resp,
});

export const updatePartnerLead = (resp: any): PartnerLeadActionTypes => ({
  type: UPDATE_PARTNER_LEAD,
  payload: resp,
});

export const createPartnerLead = (resp: any): PartnerLeadActionTypes => ({
  type: CREATE_PARTNER_LEAD,
  payload: resp,
});

export const userJoined = (resp: any): PartnerLeadActionTypes => ({
  type: USER_JOINED,
  payload: resp,
});

export const clearUserJoined = (): PartnerLeadActionTypes => ({
  type: CLEAR_USER_JOINED,
});

export const pushPartnerLead = (lead: PartnerLead): PartnerLeadActionTypes => ({
  type: PUSH_PARTNER_LEAD,
  lead,
});

export const RemovePartnerLead = (
  leadId: number | string,
): PartnerLeadActionTypes => ({
  type: REMOVE_PARTNER_LEAD,
  leadId,
});

export const deletePartnerLead = (resp: any): PartnerLeadActionTypes => ({
  type: DELETE_PARTNER_LEAD,
  payload: resp,
});

export default {
  fetchPartnerLeads,
  updatePartnerLead,
  createPartnerLead,
  deletePartnerLead,
  fetchUserPartnersLeads,
};
