import { connect } from 'react-redux';
import { RootState } from 'store/types';
import EventLayout1 from './EventLayout1';
import { selectUserPremiumStatus } from 'store/Auth';
import { USER_ROLE } from 'store/Auth/types';
import { registerPremiumToEvent } from 'store/Guests/thunks';

const mapStateToProps = (state: RootState) => ({
  userPremiumStatus: selectUserPremiumStatus(state),
  loggedAsAdmin: state.auth.loggedAs === USER_ROLE.ROLE_ADMIN,
  userId: state.auth.user?.id ?? 0,
});

export default connect(mapStateToProps, { registerPremiumToEvent })(
  EventLayout1,
);
