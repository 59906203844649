import { Slot } from 'store/Slots/types';
import {
  GuestProgramState,
  GuestProgramActionTypes,
  RESET_GUEST_PROGRAM,
  FETCH_GUEST_PROGRAM_PENDING,
  FETCH_GUEST_PROGRAM_FULFILLED,
  FETCH_GUEST_PROGRAM_REJECTED,
  ADD_SLOT_TO_PROGRAM_FULFILLED,
  REMOVE_SLOT_FROM_PROGRAM_FULFILLED,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  data: undefined,
  error: null,
  nbResult: 0,
};

const initialData = {
  program: [],
  busy: [],
  replay_program: [],
  allowed_slots: [],
};

export const reducer = (
  state: GuestProgramState = initialState,
  action: GuestProgramActionTypes,
): GuestProgramState => {
  switch (action.type) {
    case RESET_GUEST_PROGRAM: {
      return initialState;
    }
    case FETCH_GUEST_PROGRAM_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_GUEST_PROGRAM_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: data,
        nbResult: nbResult,
      };
    }
    case FETCH_GUEST_PROGRAM_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        data: undefined,
        nbResult: 0,
      };
    }
    case ADD_SLOT_TO_PROGRAM_FULFILLED: {
      const { data } = action.payload;
      const oldData = state.data ?? initialData;

      let newData;

      if (data?.isReplay) {
        newData = {
          ...oldData,
          replay_program: [
            ...oldData?.replay_program,
            String(data?.slot?.id ?? 0),
          ],
        };
      } else {
        newData = {
          ...oldData,
          program: [...oldData?.program, String(data?.slot?.id ?? 0)],
        };
      }

      return {
        ...state,
        data: newData,
      };
    }
    case REMOVE_SLOT_FROM_PROGRAM_FULFILLED: {
      const { data } = action.payload;
      const oldData = state.data ?? initialData;

      return {
        ...state,
        data: {
          ...oldData,
          busy: data?.busy ?? oldData.busy,
          program: oldData.program.filter(
            (slotId) => slotId != data?.deletedSlotId,
          ),
          replay_program: oldData.replay_program.filter(
            (slotId) => slotId != data?.deletedSlotId,
          ),
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
