import React from 'react';
import cn from 'classnames';
import s from './StepsSummary.module.scss';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg';
import { useAdvancedLayoutTheme } from 'components/Layout/AdvancedLayout/ThemeProvider';

interface Props {
  title: string;
  colorTheme?: string;
}

export const StepTitle = ({ title, colorTheme }: Props) => {
  const theme = useAdvancedLayoutTheme();

  return (
    <div
      className={cn(s.title, s.big_text, 'flex-container align-middle m-y-xs')}
    >
      <CheckCircleIcon
        className="m-l-s m-r-xs"
        width="14"
        height="14"
        fill={colorTheme ?? theme.primaryColorDark}
      />
      <div
        className={cn(s.big_text, s.uppercase)}
        style={{ color: colorTheme ?? theme.primaryColor }}
      >
        {title}
      </div>
      <div className={s.line}></div>
    </div>
  );
};
