import { TTP_API_URL, getActivitiesTypesByEvent } from 'config';
import { capitalize, escapeRegExp, isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import { EventAccess } from 'store/Event/types';
import { GuestProgramData } from 'store/Program/types';
import { Slot, SlotStatus } from 'store/Slots/types';
import { Speaker } from 'store/Speakers/types';
import { Language } from 'store/types';
import { appendParamsToLink } from './app';
import {
  getByLanguage,
  getNotNullLanguages,
  hasEventAdminAccess,
  hasPartnerManagerAccess,
  parseStringLanguages,
} from './event';

export const isSessionDemo = (demos: number[], session: Slot) => {
  return demos.indexOf(session.activity) !== -1;
};

export const isSessionBreakOut = (breakOuts: number[], session: Slot) => {
  return breakOuts.indexOf(session.activity) !== -1;
};

export const getSessionType = (
  session: Slot,
): 'demo' | 'break-out' | 'conference' => {
  if (session.type == 'breakout') {
    return 'break-out';
  }

  return session.type ?? 'conference';
};

export interface SlotBlockByTime {
  label: string;
  moment: Moment;
  items: Slot[];
}

export const sortSlotByDays = (
  slots: Slot[],
  lng: Language = 'fr',
  dayLabelFormat = 'DD MMMM',
): SlotBlockByTime[] => {
  if (slots.length > 0) {
    const blocks: SlotBlockByTime[] = [];

    slots.forEach((slot) => {
      const slotStartTime = moment(slot.startDateTime);

      if (blocks.length > 0) {
        const prevBlock = blocks[blocks.length - 1];
        if (prevBlock.moment.isSame(slotStartTime, 'day')) {
          prevBlock.items = [...prevBlock.items, slot];
          return;
        }
      }

      blocks.push({
        label: slotStartTime.locale(lng).format(dayLabelFormat),
        moment: slotStartTime,
        items: [slot],
      });
    });
    return blocks;
  }

  return [];
};

export const getCurrentSlotBlockByDate = (
  blocks: SlotBlockByTime[],
  dateLabel: string,
  strict = false,
) => {
  const label = blocks.find(({ label }) => label === dateLabel)?.label;

  if (strict) {
    return label ?? '';
  }

  return label ?? blocks[0]?.label ?? '';
};

export const isSlotInProgram = (
  programData: GuestProgramData | undefined,
  slotId: number,
  programType: 'main' | 'replay' | 'any' = 'main',
) => {
  if (!programData) {
    return false;
  }

  let program: string[] = [];

  switch (programType) {
    case 'main':
      program = Array.isArray(programData.program) ? programData.program : [];
      break;
    case 'replay':
      program = Array.isArray(programData.replay_program)
        ? programData.replay_program
        : [];
      break;
    default: {
      const mainProgram = Array.isArray(programData.program)
        ? programData.program
        : [];
      const replayProgram = Array.isArray(programData.replay_program)
        ? programData.replay_program
        : [];
      program = [...mainProgram, ...replayProgram];
      break;
    }
  }

  return program.some((stringSlotId) => parseInt(stringSlotId) === slotId);
};

export const isAllowedSlot = (
  programData: GuestProgramData | undefined,
  slotId: number,
) => {
  if (!programData) {
    return false;
  }

  const slotIds = Array.isArray(programData.allowed_slots)
    ? programData.allowed_slots
    : [];

  return slotIds.some((stringSlotId) => parseInt(stringSlotId) === slotId);
};

export const separateSlotsByType = (sessions: Slot[]) => {
  const blocks: {
    conferences: Slot[];
    demos: Slot[];
    breakOuts: Slot[];
  } = { conferences: [], demos: [], breakOuts: [] };

  sessions.forEach((slot) => {
    if (getSessionType(slot) === 'demo') {
      blocks.demos.push(slot);
      return;
    }
    if (getSessionType(slot) === 'break-out') {
      blocks.breakOuts.push(slot);
      return;
    }

    blocks.conferences.push(slot);
  });

  return blocks;
};

export const separateSlotsByAccess = (
  sessions: Slot[],
  programData: GuestProgramData | undefined,
) => {
  const blocks: {
    withAccess: Slot[];
    others: Slot[];
  } = { withAccess: [], others: [] };

  sessions.forEach((slot) => {
    if (isSlotInProgram(programData, slot.id, 'any')) {
      blocks.withAccess.push(slot);
    } else {
      blocks.others.push(slot);
    }
    return;
  });

  return blocks;
};

export const searchSessions = (sessions: Slot[], search: string): Slot[] =>
  sessions.filter(
    (slot) =>
      String(slot.nameFr).search(new RegExp(search, 'i')) !== -1 ||
      String(slot.nameNl).search(new RegExp(search, 'i')) !== -1 ||
      String(slot.nameEn).search(new RegExp(search, 'i')) !== -1,
  );

export const filterSessionsByLanguage = (
  sessions: Slot[],
  languages: Language[],
): Slot[] => {
  if (languages.length === 0 || languages.length === 3) {
    return sessions;
  }

  const search = new RegExp(
    '(' +
      languages.map((lng) => `"${capitalize(lng)}":("1"|1)`).join('|') +
      ')',
    'i',
  );

  return sessions.filter(
    (slot) => String(slot.languages).search(new RegExp(search, 'i')) !== -1,
  );
};

export const filterSessionsByNameAndSpeakers = (
  sessions: Slot[],
  keyword: string,
): Slot[] => {
  const searchKeyword = escapeRegExp(keyword.trim());

  if (isEmpty(searchKeyword)) {
    return sessions;
  }

  return sessions.filter((slot) => {
    return (
      String(slot.nameFr).search(new RegExp(searchKeyword, 'i')) !== -1 ||
      String(slot.nameNl).search(new RegExp(searchKeyword, 'i')) !== -1 ||
      String(slot.nameEn).search(new RegExp(searchKeyword, 'i')) !== -1 ||
      slot.speakers?.filter(
        ({ firstName, lastName }) =>
          String(`${firstName} ${lastName}`).search(
            new RegExp(searchKeyword, 'i'),
          ) !== -1 ||
          String(`${lastName} ${firstName}`).search(
            new RegExp(searchKeyword, 'i'),
          ) !== -1,
      ).length
    );
  });
};

export const filterSpeakersForSlot = (speakers?: Speaker[]): Speaker[] => {
  if (isEmpty(speakers) || !Array.isArray(speakers)) {
    return [];
  }

  return speakers.filter(
    (speaker) =>
      [2, 5, 6, '2', '5', '6'].indexOf(speaker.typeForSlot?.type ?? '') === -1,
  );
};

export const isSessionLive = (session: Slot) =>
  moment().isBetween(
    moment(session?.startDateTime),
    moment(session?.endDateTime),
  );

export const SessionsToLive = (sessions: any): Slot => {
  let liveSlot = {};
  sessions.forEach((slot: Slot) => {
    if (isSessionLive(slot)) {
      liveSlot = slot;
    }
  });
  return <Slot>liveSlot;
};
export const NextSession = (sessions: any): Slot => {
  let nextSlot = {};
  let date: Moment | null = null;
  sessions.forEach((slot: Slot) => {
    if (
      (!date && moment().isBefore(moment(slot.startDateTime))) ||
      moment(slot.startDateTime).isBetween(date, moment())
    ) {
      nextSlot = slot;
      date = moment(slot.startDateTime);
    }
  });
  return <Slot>nextSlot;
};

export const isSessionPast = (session: Slot) =>
  moment().isAfter(moment(session.endDateTime));

export const isSessionSoldOut = (session: Slot) =>
  session.status == SlotStatus.SOLD_OUT ||
  session.placesNumber <= session.subscribersNumber;

export const isPlaySessionInStage = (session: Slot) => {
  const roomsinStage = [193];
  return roomsinStage.indexOf(parseInt(String(session.room))) !== -1;
};

export const isSessionSpeaker = (session: Slot, userId?: number): boolean => {
  if (!userId) {
    return false;
  }

  const speakers = session.speakers;

  if (!speakers || !Array.isArray(speakers)) {
    return false;
  }

  return speakers.some((speaker) => +speaker.user === +userId);
};

export const getLanguageForSession = (session: Slot, appLanguage: Language) => {
  const languages = parseStringLanguages(session?.languages);
  if (languages.indexOf(appLanguage) !== -1) {
    return appLanguage;
  }

  if (!isEmpty(languages[0])) {
    return languages[0];
  }

  return appLanguage;
};

export const isSlotAdmin = (
  slot: Slot,
  eventAccess: EventAccess | null,
  eventId: string | number,
  userId?: number,
) => {
  return (
    hasPartnerManagerAccess(eventAccess, eventId) ||
    hasEventAdminAccess(eventAccess, eventId) ||
    isSessionSpeaker(slot, userId)
  );
};

export const getSlotReplayUrl = (
  data: Pick<
    Slot,
    | 'webinarUrlEn'
    | 'webinarReplayVideoFr'
    | 'webinarReplayVideoNl'
    | 'webinarReplayVideoEn'
  >,
  language: Language,
) => {
  const { webinarUrlEn } = data;

  if (isEmpty(webinarUrlEn)) {
    return null;
  }
  const languages = getNotNullLanguages(data, 'webinarReplayVideo');

  if (languages.length == 0) {
    return null;
  }

  const mainLanguage =
    languages.indexOf(language) !== -1 ? language : languages[0];
  const linkParams: [string, string][] = [['lng', mainLanguage]];
  languages.forEach((lng) => {
    linkParams.push([
      `video${capitalize(lng)}`,
      getByLanguage(data, 'webinarReplayVideo', lng) ?? '',
    ]);
  });

  return appendParamsToLink(webinarUrlEn, linkParams);
};

export const isWebinarSlot = (slot: Slot) => {
  return ['1', '2'].includes(slot.isWebinar);
};

export const getProgramTrainingHours = (
  programData: GuestProgramData | undefined,
) => {
  if (!programData?.trainingHours) {
    return 0;
  }

  const {
    trainingHours: { maxPerDay, ...allowedHours },
  } = programData;
  const sumMinutes = Object.values(allowedHours).reduce((a, b) => a + b, 0);

  return sumMinutes / 60;
};

export const getProgramIcsUrl = (guestId: number, language: Language) => {
  const encodedGuestId = btoa(String(guestId));

  return `${TTP_API_URL}/event/ics-program/${language}/${encodedGuestId}`;
};

export const filterSpeakersForSlots = (speakers?: Speaker[]): Speaker[] => {
  if (isEmpty(speakers) || !Array.isArray(speakers)) {
    return [];
  }

  const excludedTypes = [2, 5, 6, '2', '5', '6'];

  return speakers.filter((speaker) => {
    if (!isEmpty(speaker.typeForSlot)) {
      return !excludedTypes.includes(speaker.typeForSlot?.type ?? '');
    }

    if (!isEmpty(speaker.slots) && Array.isArray(speaker.slots)) {
      return speaker.slots?.some((slot) => !excludedTypes.includes(slot.type));
    }

    return true;
  });
};
