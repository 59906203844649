import React, { memo } from 'react';
import cn from 'classnames';

interface Props {
  theme?: 'DARK' | 'LIGHT';
  className?: string;
  onClose: () => void;
}

export default memo(function CloseModal({ onClose, theme, className }: Props) {
  const themeStyle = {
    backgroundColor: theme === 'DARK' ? '#29394D' : '',
    borderColor: theme === 'DARK' ? '#29394D' : '',
    //boxShadow: theme === 'DARK' ? '0px 4px 4px rgba(255, 255, 255, 0.3)' : '',
  };

  return (
    <div className={cn('rmodal__close', className)} style={themeStyle}>
      <button
        className={cn('close', theme === 'DARK' && 'dark')}
        onClick={onClose}
      />
    </div>
  );
});
