import { FETCH_NOTIFS, RESET_NOTIFS } from './types';

export const fetchNotifs = (resp: any) => ({
  type: FETCH_NOTIFS,
  payload: resp,
});

export const resetNotifs = () => ({
  type: RESET_NOTIFS,
});

export default { fetchNotifs };
