import { FETCH_EVENTS_TAGS } from './types';

export const fetchEventsTags = (resp: any): { payload: any; type: string } => ({
  type: FETCH_EVENTS_TAGS,
  payload: resp,
});

export default {
  fetchEventsTags,
};
