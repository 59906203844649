import { CURRENT_CONGRESS_ID } from 'config';
import { Cycle } from 'store/Cycle/types';
import { Event } from 'store/Events/types';
import { SliderData } from 'store/types';
import { isCycleSeason, isCongress, isEventPast, isPremiumOffer } from 'utils';

export type WatchSlidesData = {
  events: Event[];
};

export const getSliderData = (
  data: SliderData,
  isEventWatch?: boolean,
): WatchSlidesData => {
  const eventsData = data.events?.slice().reverse() ?? [];
  const events: Event[] = [];

  eventsData.forEach((event) => {
    if (event.id === CURRENT_CONGRESS_ID) {
      //congresses.push(event);
    } else if (!isCongress(event) && (!isEventPast(event) || isEventWatch)) {
      events.push(event);
    }
  });

  return {
    events: events.slice(0, 10),
  };
};
