import { selectToken } from 'store/Auth';
import * as actions from './actions';
import * as api from './api';

export const fetchPrivacy = () => {
  return (dispatch, getState) => {
    const state = getState();
    const token = selectToken(state);

    return dispatch(actions.fetchPrivacy(api.getPrivacyRubrics({ token })));
  };
};
