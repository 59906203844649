import { connect } from 'react-redux';
import { RootState } from 'store/types';
import GuestAddress from './GuestAddress';
import { updateAddress } from 'store/Guests/thunks';

const mapStateToProps = (state: RootState) => ({
  guestsResource: state.event.guests,
});

export default connect(mapStateToProps, {
  updateAddress,
})(GuestAddress);
