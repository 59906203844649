import { Operator } from './types';

abstract class Filter<T = any> {
  constructor(
    protected _property: string,
    protected _operator: Operator,
    protected _properties: string[] = [],
  ) {}

  /** Operators with simple value */
  static readonly Eq = 'eq';
  static readonly Like = 'like';

  /** Operators with array value */
  static readonly In = 'in';
  static readonly Between = 'between';

  get property(): string {
    return this._property;
  }

  set property(property: string) {
    this._property = property;
  }

  get properties(): string[] {
    return this._properties;
  }

  set properties(_properties: string[]) {
    this._properties = _properties;
  }

  get operator(): Operator {
    return this._operator;
  }

  set operator(operator: Operator) {
    this._operator = operator;
  }

  isSame = ({ property, operator }: Filter<T>) =>
    this.property === property && this.operator === operator;

  abstract get value(): any;
  abstract set value(v: any);
  abstract serialize(): Record<string, any>;
  abstract clone(): Filter<T>;
  abstract isEqual(filter: Filter<T>): boolean;
}

export default Filter;
