import {
  OrderState,
  OrderActionTypes,
  CANCEL_ORDER_PENDING,
  CANCEL_ORDER_FULFILLED,
  CANCEL_ORDER_REJECTED,
  RESET_ORDERS,
  FETCH_ORDERS_PENDING,
  FETCH_ORDERS_FULFILLED,
  FETCH_ORDERS_REJECTED,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  items: [],
  error: null,
  nbResult: 0,
};

export const reducer = (
  state: OrderState = initialState,
  action: OrderActionTypes,
) => {
  switch (action.type) {
    case RESET_ORDERS: {
      return initialState;
    }
    case CANCEL_ORDER_PENDING: {
      return {
        ...state,
        saved: false,
        saving: true,
      };
    }
    case CANCEL_ORDER_FULFILLED: {
      const { data: canceledOrder } = action.payload;

      return {
        ...state,
        saved: true,
        saving: false,
        items: state.items.filter((order) => order.id !== canceledOrder.id),
      };
    }
    case CANCEL_ORDER_REJECTED: {
      const error = action.payload;
      return {
        ...state,
        saving: false,
        saved: false,
        error,
      };
    }
    case FETCH_ORDERS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_ORDERS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case FETCH_ORDERS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }

    default:
      return state;
  }
};

export default reducer;
