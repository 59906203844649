import { Dispatch } from 'redux';
import { RootState } from 'store/types';
import actions from './actions';
import * as api from './api';

export const fetchEventsCategories = () => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  const filters: any = [];

  return dispatch(
    actions.fetchEventsCategories(
      api.getEventsCategories({ token, filters }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};
