import { WithPaginationAndFilterState } from 'store/types';

export interface NameWrappedAction {
  meta: string;
}

export const FETCH_EVENTS_TAGS = 'FETCH_EVENTS_TAGS';
export const FETCH_EVENTS_TAGS_PENDING = 'FETCH_EVENTS_TAGS_PENDING';
export const FETCH_EVENTS_TAGS_FULFILLED = 'FETCH_EVENTS_TAGS_FULFILLED';
export const FETCH_EVENTS_TAGS_REJECTED = 'FETCH_EVENTS_TAGS_REJECTED';

export interface FetchEventsTagsAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENTS_TAGS
    | typeof FETCH_EVENTS_TAGS_PENDING
    | typeof FETCH_EVENTS_TAGS_FULFILLED
    | typeof FETCH_EVENTS_TAGS_REJECTED;
  payload: any;
}

export interface EventsTagsState extends WithPaginationAndFilterState {
  fetching: boolean;
  fetched: boolean;
  items: any[];
  error: any;
  nbResult: number;
}

export type EventsTagsActionTypes = FetchEventsTagsAction;
