import React, { useEffect, useRef, useState } from 'react';
import s from './DailyIframe.module.scss';
import { APP_ENV } from 'config';
import {
  DAILY_PARTNERS_RC2,
  DAILY_PARTNERS_V2,
} from 'Webinar/Exhibitors/ExhibitorHeader/DailyIframe/config';
import DailyIframe from '@daily-co/daily-js';
import { User } from 'store/Auth/types';
import { connect } from 'react-redux';
import { Language } from 'store/types';

interface Props {
  modal: any;
  user: User;
  lng: Language;
}

const DailyFrame = ({ modal, user, lng }: Props): any => {
  const dailyParams: any =
    APP_ENV === 'v2' ? DAILY_PARTNERS_V2 : DAILY_PARTNERS_RC2;

  const { partner, isManager } = modal;
  const frame = useRef<HTMLDivElement>(null);

  const url = dailyParams[partner].roomUrl;

  const token = isManager ? dailyParams[partner].token : null;

  const [joined, setJoined] = useState(false);

  const initFrame = async (url: string, token: string | null = null) => {
    if (url && !joined) {
      setJoined(true);
      const callFrame = await DailyIframe.createFrame(
        frame.current as HTMLElement,
        {
          iframeStyle: { height: '100%', width: '100%' },
        },
      );
      let elem: any = {
        url,
        lang: lng,
        userName: `${user.firstName} ${user.lastName}`,
        showLeaveButton: false,
        showFullscreenButton: false,
        customLayout: true,
        cssText:
          '.sidebar {order: -1;} .call > div:first-child:not(.topbar) {display: none;}',
        activeSpeakerMode: true,
        layoutConfig: { grid: { minTilesPerPage: 1, maxTilesPerPage: 6 } },
      };
      if (token) elem = { ...elem, token };
      const result = await callFrame.join(elem);
      console.error(result);
    }
  };

  useEffect(() => {
    initFrame(url, token);
  }, [url, token]);

  if (modal)
    return (
      <div className={s.wrapper}>
        <div ref={frame} style={{ width: '100%', height: '100%' }} />
      </div>
    );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  lng: state.params.lng,
});

export default connect(mapStateToProps)(DailyFrame);
