import React from 'react';
import s from './Chip.module.scss';
import cn from 'classnames';
import { ColorType } from './services';

interface Props {
  label: string;
  color?: ColorType;
  isTransparent?: boolean;
  isLowercase?: boolean;
}

const Chip = ({
  label,
  color = 'slateGrey',
  isLowercase,
  isTransparent,
}: Props) => {
  return (
    <div
      className={cn(
        s.wrapper,
        s[color as string],
        isTransparent && s.transparent,
      )}
    >
      {isLowercase ? label.toLowerCase() : label.toUpperCase()}
    </div>
  );
};

export default Chip;
