export const IMAGE_SIZES = {
  partner: {
    banner: {
      structural: { width: 835, height: 186 },
      others: { width: 410, height: 186 },
    },
    ad: { width: 384, height: 192 },
    logo: { width: 368, height: 192 },
  },
};
