import React, { ReactElement, useEffect, useState } from 'react';
import s from './CollaboratorLayout.module.scss';
import { getByLanguage } from 'utils';
import cn from 'classnames';
import t from 'i18n';
import { isEmpty } from 'lodash';
import CollaboratorLayout from './CollaboratorLayout';
import SearchInput from 'Common/SearchInput/SearchInput';
import RoundedButton from 'ui/RoundedButton/RoundedButton';
import { attributionFilter, CollabCount } from './services';
import { Tabs } from 'antd';
import classNames from 'classnames';
import CollaboratorPackFilter from './CollaboratorPackFilter';
const { TabPane } = Tabs;

interface Props {
  goToStep?: () => void;
  organisationId: number;
  collaborators: any;
  pack: any;
  fetching?: boolean;
  partner?: any;
  eventId?: number;
  ownerId?: number;
  fetchPacks: (organizationId: number, eventId: number) => void;
  getEmployeePack: (organizationId: number, eventId: number) => void;
  organizationName: string;
  sendGuestConfirmationSubscribed: (guestsId: number) => any;
  savePlace: (
    event: any,
    user: any,
    couponModel: any,
    owner: any,
    partner: any,
  ) => any;
  cancelPlace: (
    eventId: any,
    partnerId: any,
    guestId: any,
    ownerId: any,
  ) => any;
}

export default function CollaboratorList({
  collaborators,
  goToStep,
  fetching,
  pack,
  organisationId,
  partner,
  eventId,
  ownerId,
  savePlace,
  fetchPacks,
  cancelPlace,
  getEmployeePack,
  organizationName,
  sendGuestConfirmationSubscribed,
}: Props): ReactElement {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [filterPlan, setFilterPlan] = useState('');
  const defaultFilterPlan = pack[Object.keys(pack)[0]]?.nameFr;
  const allCollaborators = collaborators?.filter((collaborator: any) => {
    return String(collaborator.userId).length > 0;
  });
  useEffect(() => {
    setFilter('all');
  }, []);

  const AssignedCollaborators = collaborators?.filter((collaborator: any) => {
    return (
      String(collaborator.guestUsedCoupon).search(new RegExp('null|^$')) &&
      String(collaborator.canceled).search(new RegExp('true', 'i')) &&
      collaborator?.invitedBy?.organizationId == organisationId
    );
  });

  const UnAssignedCollaborators = collaborators?.filter((collaborator: any) => {
    return (
      !String(collaborator.guestUsedCoupon).search(new RegExp('null|^$')) ||
      collaborator?.invitedBy?.organizationId != organisationId
    );
  });

  const Filter = (label: any) => {
    setFilter(label);
    setFilterPlan('null');
  };

  const renderAttributionFilter = () => {
    return attributionFilter(
      CollabCount(collaborators),
      CollabCount(AssignedCollaborators),
      CollabCount(UnAssignedCollaborators),
    ).map((item) => (
      <RoundedButton
        key={item.label}
        value={item.value}
        size={2}
        textStyle={{
          size: '12px',
          weight: 500,
          isUpper: true,
        }}
        count={item.count || 0}
        isActive={filter == item.label}
        bgColors={{ default: '#F1F2F4', active: '#6D7F92' }}
        className={classNames('m-r-xxs', s.filter_btn)}
        onClick={() => Filter(item.label)}
      />
    ));
  };

  const renderFilterTab = () => {
    return (
      <CollaboratorPackFilter
        packs={pack}
        defaultPlan={defaultFilterPlan}
        onChange={setFilterPlan}
      />
    );
  };

  const renderCollaborators = () => {
    if (fetching) {
      return [1, 2, 3, 4, 5, 6].map((key) => (
        <CollaboratorLayout.Fetching key={`collaborator-${key}`} />
      ));
    }

    let filteredCollaborators = collaborators;

    if (!isEmpty(filter)) {
      switch (filter) {
        case 'all':
          filteredCollaborators = allCollaborators;
          break;
        case 'in':
          filteredCollaborators = AssignedCollaborators?.filter(
            (collaborator: any) => {
              return (
                String(collaborator.guestPlanName.NameFr).indexOf(
                  defaultFilterPlan,
                ) !== -1
              );
            },
          );
          break;
        case 'nin':
          filteredCollaborators = UnAssignedCollaborators;
          break;
      }
    }

    if (!isEmpty(filterPlan) && filterPlan != 'null') {
      filteredCollaborators = AssignedCollaborators?.filter(
        (collaborator: any) => {
          return (
            String(collaborator.guestPlanName.NameFr).indexOf(filterPlan) !== -1
          );
        },
      );
    }

    if (!isEmpty(search)) {
      if (filteredCollaborators) {
        filteredCollaborators = filteredCollaborators?.filter(
          (collaborator: any) => {
            return (
              String(collaborator.firstName).search(new RegExp(search, 'i')) !==
                -1 ||
              String(collaborator.lastName).search(new RegExp(search, 'i')) !==
                -1
            );
          },
        );
      }
    }

    return filteredCollaborators?.map((collaborator: any) => (
      <CollaboratorLayout
        key={collaborator.id}
        collaborator={collaborator}
        pack={pack}
        partner={partner ? partner : 0}
        eventId={eventId}
        ownerId={ownerId}
        savePlace={savePlace}
        sendGuestConfirmationSubscribed={sendGuestConfirmationSubscribed}
        cancelPlace={cancelPlace}
        getEmployeePack={getEmployeePack}
        organisationId={organisationId}
        fetchPacks={fetchPacks}
      />
    ));
  };

  return (
    <div>
      <h3 className={cn(s.title, 'p-x-l m-t-l')}>
        {t('AssignPlaceTitle')} : {organizationName}
      </h3>

      <div className={cn(s.subtitle, 'p-x-l m-t-m')}>
        <span>{t('Collaborators list')} :</span>
        <span className={cn(s.addCollab, 'p-x-l m-t-l')} onClick={goToStep}>
          {t('Manage my employees')}
        </span>
      </div>
      <div className={cn(s.filters, 'p-x-l m-t-l')}>
        <div style={{ display: 'flex' }}>{renderAttributionFilter()}</div>
        <SearchInput search={search} handleSearchChange={setSearch} />
      </div>
      <div className="grid-x p-x-l m-t-l">
        {filter == 'in' && renderFilterTab()}
        {renderCollaborators()}
      </div>
    </div>
  );
}
