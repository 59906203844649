import React, { useRef, useState } from 'react';
import s from './SmallOption.module.scss';
import cn from 'classnames';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import t from 'i18n';
import { isEmpty, isBoolean } from 'lodash';
import Markdown from 'markdown-to-jsx';
import YesNoButtons from 'ui/YesNoButton';
import { bindWebinarLink, onError, onSuccess } from 'utils';
import _ from 'i18n';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'Common/LocalLoader/LocalLoader';
import { Price } from 'ui/PriceLabel/PriceLabel';
import TTButton from 'Common/OLDButton/OLDButton';
import { URLS } from 'router';
import { Link, useHistory } from 'react-router-dom';
import { CURRENT_CONGRESS_ID } from 'config';

interface Props {
  title: string;
  description: string;
  imgUrl?: string;
  optionId: number;
  guestId: number;
  price?: number;
  eventId?: number;
  isAutoPopup?: boolean;
  confirmGuestOption?: (
    guestId: number,
    optionId: number,
    hasConfirmed: boolean,
  ) => any;
  isPromoted?: boolean;
}

const SmallOption = ({
  title,
  description,
  imgUrl,
  optionId,
  guestId,
  confirmGuestOption,
  price,
  isPromoted,
  eventId,
  isAutoPopup,
}: Props) => {
  const [isDescExpanded, setIsDescExpanded] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const [yesOrNo, setYesOrNo] = useState<any>();
  const showDescription = !isEmpty(description);
  const history = useHistory();

  const handleConfirmGuestOption = (
    guestId: number,
    optionId: number,
    hasConfirmed: boolean,
  ) => {
    setSaving(true);
    return confirmGuestOption
      ? confirmGuestOption(guestId, optionId, hasConfirmed)
          .then((res: any) => {
            setSaving(false);
            onSuccess(res, { body: _('Option Confirmed') });
          })
          .catch(() => {
            setSaving(false);
            onError();
          })
      : '';
  };

  const onButtonClick = (yesOrNo: any) => {
    setYesOrNo(yesOrNo);
    isBoolean(yesOrNo) && handleConfirmGuestOption(guestId, optionId, yesOrNo);
  };

  const renderYesNo = () => {
    return <YesNoButtons onClick={onButtonClick} />;
  };

  const renderPriceLayout = () => {
    const render = [];

    if (price != 0) {
      render.push(
        <Price
          size={'medium'}
          price={price as number}
          style={{
            padding: '0.25rem 0.75rem',
            marginRight: '1.25rem',
            width: isAutoPopup ? '50px' : 'unset',
            height: isAutoPopup ? '24px' : 'unset',
            marginTop: isAutoPopup ? '6px' : 'unset',
          }}
        />,
      );
    }

    const handleBuyOption = () => {
      window.location.assign(
        bindWebinarLink(
          URLS.webinar.reception.registration.root,
          String(isAutoPopup ? CURRENT_CONGRESS_ID : eventId),
          `&openRegistration=individual&openStep=options&optionId=${optionId}`,
        ),
      );
    };

    if (render.length > 0) {
      return (
        <div className={cn(isAutoPopup ? s.autoPopup : s.promoted)}>
          {render}
          <div>
            <TTButton
              fontSize="14px"
              onClick={() => {
                handleBuyOption();
              }}
              className="green full active"
              style={{
                height: '36px',
                padding: '0.5rem 1.5rem',
                minWidth: '187px',
              }}
            >
              {t('Acheter cette option')}
            </TTButton>
          </div>
        </div>
      );
    }

    return null;
  };
  return (
    <div className={cn(s.option_wrapper, '')}>
      <LocalLoaderWrapper>
        <div
          className={cn(
            s.option_content,
            yesOrNo && s.option_approved,
            yesOrNo !== undefined && !yesOrNo && s.option_declined,
          )}
        >
          <div className="grid-x">
            <div className="cell small-3">
              <div
                className={s.option_image}
                style={{ backgroundImage: `url(${imgUrl})` }}
              />
            </div>
            <div className="cell small-9 p-x-s">
              <h3>{title}</h3>
              {showDescription && (
                <>
                  <p className={cn(!isDescExpanded && s.custom_details)}>
                    {isDescExpanded && <Markdown>{description}</Markdown>}
                  </p>
                  <div
                    className={cn(s.more_details)}
                    onClick={() => setIsDescExpanded(!isDescExpanded)}
                  >
                    <span className="m-r-xs">
                      {isDescExpanded ? t('less_details') : t('more_details')}
                    </span>
                    <ChevronUpIcon
                      className={cn('m-r-s', !isDescExpanded && 'rl180')}
                      width="10px"
                      fill="#6d7f92"
                    />
                  </div>
                </>
              )}
              {isPromoted || isAutoPopup ? (
                <div className={isPromoted ? 'm-t-s' : s.price_layout}>
                  {renderPriceLayout()}
                </div>
              ) : (
                <div>{renderYesNo()}</div>
              )}
            </div>
          </div>
        </div>
        <LocalLoader loading={saving} style={{ zIndex: 100 }} />
      </LocalLoaderWrapper>
    </div>
  );
};

export default SmallOption;
