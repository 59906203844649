import React, { CSSProperties, ReactNode } from 'react';
import { getMainMenuHeight } from 'utils';

interface Props {
  children: ReactNode;
  sidesGap?: 'XS' | 'S' | 'M';
  bgColor?: string;
  className?: string;
  styles?: CSSProperties;
  asFullPage?: boolean;
}

const PageContainer = ({
  children,
  bgColor = '#FAFBFB',
  sidesGap,
  asFullPage = true,
  className,
  styles,
}: Props) => {
  const paddingX =
    sidesGap === 'XS' ? '1.75rem' : sidesGap === 'S' ? '6.75rem' : '10.75rem';
  const defaultStyle = {
    minHeight: asFullPage ? `calc(100vh - ${getMainMenuHeight()}px)` : '',
    background: bgColor,
    padding: `1.5rem ${paddingX}`,
  };

  return (
    <div className={className} style={{ ...defaultStyle, ...styles }}>
      <div>{children}</div>
    </div>
  );
};

export default PageContainer;
