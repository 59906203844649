export const SET_SHOW_FAQ = 'SET_SHOW_FAQ';

export interface FaqState {
  showFaq: any;
}

export interface SetShowFaqAction {
  type: typeof SET_SHOW_FAQ;
  showFaq: any;
}
