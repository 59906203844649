import { useEffect, useRef } from 'react';

// Optimized hook version of the useInterval hook
const useRecursiveTimeout = <T>(
  callback: (() => void) | (() => Promise<T>),
  delay: number | null,
) => {
  const savedCallback = useRef(callback);
  const savedDelay = useRef(delay);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedDelay.current = delay;
  }, [delay]);

  useEffect(() => {
    let id: NodeJS.Timeout;

    const tick = () => {
      const currentCallback = savedCallback.current();
      const currentDelay = savedDelay.current;

      if (currentCallback instanceof Promise) {
        currentCallback.then(() => {
          if (currentDelay !== null) {
            id = setTimeout(tick, currentDelay);
          }
        });
      } else {
        if (currentDelay !== null) {
          id = setTimeout(tick, currentDelay);
        }
      }
    };

    if (savedDelay.current !== null) {
      id = setTimeout(tick, savedDelay.current);
      return () => id && clearTimeout(id);
    }
  }, [delay]);
};

export default useRecursiveTimeout;
