import React, { memo } from 'react';
import styles from './Session.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import { ReactComponent as SessionIcon } from 'assets/icons/session.svg';
import moment from 'moment';
import { isLive, getByLanguage } from 'utils';

import LiveIcon from 'components/ui/LiveIcon';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { isEmpty } from 'lodash';
import { LanguageState } from '../../../../store/Params/Language';
import { S3_FOLDER_URL } from '../../../../config';
import Highlighter from 'react-highlight-words';

interface Props {
  title: string;
  speakers: string;
  isSelected: boolean;
  showHeader?: boolean;
  isWebinar?: string;
  startAt: string;
  endAt: string;
  renderActions: () => JSX.Element;
  onClick: (event: any) => void;
  roomName?: string;
  activityName?: any;
  language: LanguageState;
  isSoldOut?: boolean;
  partner?: any;
  searchKeyword: string;
}

export default memo(function Session({
  title,
  isSelected,
  startAt,
  endAt,
  partner,
  speakers,
  renderActions,
  onClick,
  isWebinar,
  roomName,
  activityName,
  language,
  isSoldOut,
  searchKeyword,
}: Props) {
  const renderTiming = () => {
    const isSessionLive = isLive(startAt, endAt);

    if (isSessionLive) {
      return (
        <div className="flex-container align-middle">
          <LiveIcon className="m-r-xxs" />
          <span style={{ color: '#fe3745' }}>{_('Ongoing')}</span>
        </div>
      );
    }

    const startHour = moment(startAt).format('HH:mm');
    const endHour = moment(endAt).format('HH:mm');

    return (
      <div className={classNames(styles.time, 'm-b-xxs')}>
        {startHour} - {endHour}
      </div>
    );
  };

  const renderPartner = () => {
    if (partner) {
      const logoUrl = getByLanguage(partner, 'pictureUrl', language);
      const name = getByLanguage(partner, 'name', language);
      if (!isEmpty(logoUrl) && !isEmpty(name)) {
        return (
          <>
            <div className="cell small-12 flex-container p-l-s dark">
              <div className={styles.exhibitor}>
                <div className={styles.logoWrapper}>
                  <span
                    style={{
                      backgroundImage: `url(${S3_FOLDER_URL}/${
                        logoUrl?.split('/')[0] !== 'events-folder'
                          ? 'events-folder'
                          : ''
                      }${logoUrl})`,
                    }}
                  />
                </div>
                <div className={styles.title}>{name}</div>
              </div>
            </div>
          </>
        );
      }
    }
    return null;
  };

  const isWebinarEvent = () => {
    switch (isWebinar) {
      case '0':
        return false;
      case '1':
        return true;
      case '2':
        return true;
      default:
        return false;
    }
  };

  if (speakers.length > 75) {
    speakers = speakers.substring(0, 75) + '...';
  }

  activityName = getByLanguage(activityName, 'name', language);
  return (
    <div
      onClick={onClick}
      className={classNames(styles.layout4, isSelected && styles.hilighted)}
    >
      {isSoldOut && (
        <div className={styles.soldOut}>{_('session_sold_out')}</div>
      )}
      <div className="grid-x">
        <div className="cell small-9 flex-container">
          <div className="grid-x">
            {renderPartner()}
            <div className="cell small-4 flex-container vseparator p-s dark">
              <div className={classNames(styles.sessionHeader)}>
                <div className={styles.block}>{renderTiming()}</div>
                <div className={styles.block}>
                  <SessionIcon className="m-r-xs" width="15" stroke="#29394D" />
                  <span>{activityName}</span>
                </div>
                {isWebinarEvent() && (
                  <div className={styles.block}>
                    <EarthIcon
                      width="15"
                      height="15"
                      fill="#29394D"
                      className="m-r-xs"
                    />
                    <span>{_('Webinar')}</span>
                  </div>
                )}
                {!isWebinarEvent() && (
                  <div className={styles.block}>
                    <PinIcon
                      width="15"
                      height="15"
                      fill="#29394D"
                      className="m-r-xs"
                    />
                    <span>{_(roomName)}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="cell small-8 p-s p-l-m flex-container">
              <div className="align-center flex-1 grid-y p-r-s m-l-s">
                <h3>
                  <Highlighter
                    highlightClassName="marker"
                    searchWords={[searchKeyword]}
                    autoEscape={true}
                    textToHighlight={title}
                  />
                </h3>
                <h6 className="greetings">
                  <Highlighter
                    highlightClassName="marker"
                    searchWords={[searchKeyword]}
                    autoEscape={true}
                    textToHighlight={speakers}
                  />
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="cell small-3 p-xxs p-l-xxs flex-container">
          <div className="align-center flex-1 grid-y p-r-s">
            <div
              className={styles.actionWrapper}
              style={{ marginLeft: 'auto', marginRight: '0' }}
            >
              {renderActions()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
