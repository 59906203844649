import { Event } from 'store/Events/types';
import { NameWrappedAction, WithPaginationAndFilterState } from 'store/types';

export interface EventsFutureHomeState {
  items: Event[];
  fetching: boolean;
  fetched: boolean;
  error: any;
  nbResult: number;
  page: number;
  pageSize: number;
}

export const FETCH_EVENTS_FUTURE = 'FETCH_EVENTS_FUTURE';
export const FETCH_EVENTS_FUTURE_PENDING = 'FETCH_EVENTS_FUTURE_PENDING';
export const FETCH_EVENTS_FUTURE_FULFILLED = 'FETCH_EVENTS_FUTURE_FULFILLED';
export const FETCH_EVENTS_FUTURE_REJECTED = 'FETCH_EVENTS_REJECTED';

export const SET_EVENTS_FUTURE_PAGE_SIZE = 'SET_EVENTS_FUTURE_PAGE_SIZE';
export const SET_EVENTS_FUTURE_PAGE = 'SET_EVENTS_FUTURE_PAGE';

export interface FetchEventsFutureAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENTS_FUTURE
    | typeof FETCH_EVENTS_FUTURE_PENDING
    | typeof FETCH_EVENTS_FUTURE_FULFILLED
    | typeof FETCH_EVENTS_FUTURE_REJECTED
    | typeof SET_EVENTS_FUTURE_PAGE_SIZE
    | typeof SET_EVENTS_FUTURE_PAGE;
  payload: any;
}

export type EventActionTypes = FetchEventsFutureAction;
