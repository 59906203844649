import {
  SocialState,
  SocialActionTypes,
  ADD_LIKE_PENDING,
  ADD_LIKE_FULFILLED,
  ADD_LIKE_REJECTED,
  ADD_DISLIKE_PENDING,
  ADD_DISLIKE_FULFILLED,
  ADD_DISLIKE_REJECTED,
  ADD_TO_FAV_PENDING,
  ADD_TO_FAV_FULFILLED,
  ADD_TO_FAV_REJECTED,
} from './types';

const initialState = {
  likeAdding: false,
  likeAdded: false,
  dislikeAdding: false,
  dislikeAdded: false,
  favAdding: false,
  favAdded: false,
  result: null,
  error: null,
};

export const reducer = (
  state: SocialState = initialState,
  action: SocialActionTypes,
) => {
  switch (action.type) {
    case ADD_LIKE_PENDING: {
      return { ...state, likeAdded: false, likeAdding: true };
    }
    case ADD_LIKE_FULFILLED: {
      const { data } = action.payload.data;

      const result = data ? data : [];

      return {
        ...state,
        likeAdding: false,
        likeAdded: true,
        error: null,
        result,
      };
    }
    case ADD_LIKE_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        likeAdding: false,
        likeAdded: false,
        error,
      };
    }
    case ADD_DISLIKE_PENDING: {
      return { ...state, dislikeAdded: false, dislikeAdding: true };
    }
    case ADD_DISLIKE_FULFILLED: {
      const { data } = action.payload.data;

      const result = data ? data : [];

      return {
        ...state,
        dislikeAdding: false,
        dislikeAdded: true,
        error: null,
        result,
      };
    }
    case ADD_DISLIKE_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        dislikeAdding: false,
        dislikeAdded: false,
        error,
      };
    }
    case ADD_TO_FAV_PENDING: {
      return { ...state, favAdded: false, favAdding: true };
    }
    case ADD_TO_FAV_FULFILLED: {
      const { data } = action.payload.data;

      const result = data ? data : [];

      return {
        ...state,
        favAdding: false,
        favAdded: true,
        error: null,
        result,
      };
    }
    case ADD_TO_FAV_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        favAdding: false,
        favAdded: false,
        error,
      };
    }

    default:
      return state;
  }
};

export default reducer;
