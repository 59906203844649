import React, { useEffect, useState } from 'react';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsControl from '../../../Common/StepsControl';
import StepsContent from '../../../Common/StepsContent';
import PlacesLayout from './PlacesLayout/PlacesLayout';
import CollaboratorList from './CollaboratorLayout/CollaboratorList';
import { Collaborators as CollaboratorsType } from 'store/Collaborators/types';
import { REGISTRATION_PACK_STEPS } from '../../services';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useUser } from 'hooks/useUser';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'Common/LocalLoader/LocalLoader';
import { fetchGuestData } from 'store/Guests/thunks';
import { useDispatch } from 'react-redux';

interface Props {
  organisationId: number;
  goToStep: (step: REGISTRATION_PACK_STEPS) => void;
  collaborators: CollaboratorsType;
  packs: any;
  closeModal: () => void;
  fetchPacks: (organizationId: number, eventId: number) => void;
  getEmployeePack: (organizationId: number, eventId: number) => void;
  savePlace: (
    event: any,
    user: any,
    couponModel: any,
    owner: any,
    partner: any,
  ) => any;
  sendGuestConfirmationSubscribed: (guestsId: number) => any;
  cancelPlace: (
    eventId: any,
    partnerId: any,
    guestId: any,
    ownerId: any,
  ) => any;
  collaboratorsFetching: boolean;
  packFetching: boolean;
  saving: boolean;
  canceling: boolean;
  eventId: number;
  fetchPackSummary: (userId: number, eventId: number) => any;
  userId?: number;
  organizationName: string;
}

export const StepPlaceAttribution = ({
  goToStep,
  collaborators,
  fetchPacks,
  closeModal,
  getEmployeePack,
  packs,
  collaboratorsFetching,
  packFetching,
  eventId,
  savePlace,
  cancelPlace,
  saving,
  canceling,
  organisationId,
  fetchPackSummary,
  userId,
  organizationName,
  sendGuestConfirmationSubscribed,
}: Props) => {
  useEffect(() => {
    fetchPacks(organisationId, eventId);
    getEmployeePack(organisationId, eventId);
  }, []);
  const dispatch = useDispatch();
  const Plans = packs ? packs.plans : '';
  const partner = packs ? packs.partnerId : '';
  const user = useUser();
  const handleClose = () => {
    closeModal();
    if (user) {
      dispatch(fetchGuestData(eventId, user.id));
      fetchPackSummary(user.id, eventId);
    }
  };
  return (
    <>
      <StepsContent>
        <LocalLoaderWrapper>
          <div className="grid-x">
            <DndProvider backend={HTML5Backend}>
              <div className="Collaborators cell small-8">
                <CollaboratorList
                  collaborators={collaborators}
                  pack={Plans}
                  goToStep={() =>
                    goToStep(REGISTRATION_PACK_STEPS.COLLABORATORS)
                  }
                  fetching={collaboratorsFetching}
                  partner={partner}
                  eventId={eventId}
                  ownerId={user?.id ?? 0}
                  savePlace={savePlace}
                  sendGuestConfirmationSubscribed={
                    sendGuestConfirmationSubscribed
                  }
                  cancelPlace={cancelPlace}
                  getEmployeePack={getEmployeePack}
                  organisationId={organisationId}
                  fetchPacks={fetchPacks}
                  organizationName={organizationName}
                />
              </div>
              <div className="Places cell small-4">
                <PlacesLayout
                  goToStep={() => goToStep(REGISTRATION_PACK_STEPS.PACK)}
                  fetching={packFetching}
                  pack={Plans}
                  partner={partner}
                  eventId={eventId}
                  ownerId={user?.id ?? 0}
                  savePlace={savePlace}
                  getEmployeePack={getEmployeePack}
                  organisationId={organisationId}
                  fetchPacks={fetchPacks}
                />
              </div>
            </DndProvider>
            <LocalLoader loading={saving || canceling} />
          </div>
        </LocalLoaderWrapper>
      </StepsContent>
      <StepsControl>
        <div className="p-s p-x-l flex-container">
          <GoBack
            text={t('Previous step')}
            onClick={() => goToStep(REGISTRATION_PACK_STEPS.COLLABORATORS)}
            className="m-r-s"
          />

          <NextStep
            text={t('Finish')}
            className="m-l-auto"
            theme="orangeRoyal"
            onClick={() => handleClose()}
            hideIcon={true}
          />
        </div>
      </StepsControl>
    </>
  );
};

export default StepPlaceAttribution;
