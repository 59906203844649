import React, { memo } from 'react';
import styles from './NoData.module.scss';

interface Props {
  icon?: React.ReactNode;
  title?: string;
  message?: string;
}

export default memo(function NoData({ icon, title, message }: Props) {
  return (
    <div className={styles.noData}>
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      {title && <h5>{title}</h5>}
      {message && <h6>{message}</h6>}
    </div>
  );
});
