import { ErrorMessage, useField } from 'formik';
import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import t from 'i18n';
import s from './CouponInput.module.scss';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import PulseLoader from 'react-spinners/PulseLoader';
import { setTimeout } from 'timers';
import { AdvancedLayoutTheme } from 'components/Layout/AdvancedLayout/services';

export interface CouponInputOption {
  label: string;
  value: string;
}

const DEFAULT_WIDTH = '2px';

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
  > {
  isMulti?: boolean;
  errorsClassName?: string;
  name: string;
  value?: CouponInputOption[];
  maxItems?: number;
  children?: React.ReactNode;
  theme?: AdvancedLayoutTheme;
  className?: string;
  validateValue?: (coupon: string, callBack: () => void) => any;
  onChange?: (value: CouponInputOption[]) => void;
}

export const createOption = (label: string) => ({
  label,
  value: label,
});

export function CouponInput({
  isMulti,
  value,
  placeholder,
  maxItems,
  name,
  children,
  theme = 'blue',
  className,
  validateValue,
  onFocus,
  onBlur,
  onKeyDown,
  onChange,
}: Props) {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const widthCalculatorRef = useRef<HTMLDivElement>(null);
  const [focused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const nbItems = (value ?? []).length;
  const canAddMore =
    (!isMulti && nbItems < 1) ||
    (isMulti && (maxItems == null || nbItems < maxItems));
  const isApplyDisabled = isEmpty(inputValue.trim());

  useLayoutEffect(() => {
    const divElement = widthCalculatorRef.current;
    const inputElement = inputRef.current;
    if (divElement && inputElement) {
      const divWidth = divElement.offsetWidth ?? divElement.clientWidth ?? 0;
      inputElement.style.width = `${divWidth + 2}px`;
    }
  }, [inputValue]);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
    // setTimeout(() => setInputValue(''), 150);
  };

  const handleInputChange = ({
    target: { value: input },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!canAddMore || isLoading) {
      return;
    }

    setInputValue(input);
  };

  const addOption = () => {
    onChange?.([...(value ?? []), createOption(inputValue)]);
    setInputValue('');
  };

  const removeOption = (option: CouponInputOption) => {
    onChange?.((value ?? []).filter((op) => op.value !== option.value));
    setInputValue('');
  };

  const onApply = () => {
    if (isApplyDisabled) {
      return;
    }

    if (validateValue) {
      setIsLoading(true);
      const _finally = () => {
        setIsLoading(false);
        setInputValue('');
      };
      validateValue(inputValue, () => addOption())
        .then(_finally)
        .catch(_finally);
    } else {
      addOption();
    }
  };

  const handleKeyDown = (e: any) => {
    if (!inputValue || isApplyDisabled) return;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
        onApply();
        e.preventDefault();
        break;
    }

    onKeyDown?.(e);
  };

  return (
    <div>
      <div
        className={cn(
          s.couponInput,
          s[theme],
          (focused || nbItems > 0) && s.focused,
          className,
        )}
        onClick={handleClick}
      >
        <div className={s.valueContainer}>
          {isEmpty(value) && isEmpty(inputValue) && (
            <div className={s.placeholder}>{placeholder}</div>
          )}
          {!isEmpty(value) &&
            value?.map((option, index) => (
              <div key={`${option.value}-index`} className={s.multiValue}>
                <div className={s.label}>{option.label}</div>
                <div className={s.remove} onClick={() => removeOption(option)}>
                  <CrossIcon fill="currentColor" width="8" height="8" />
                </div>
              </div>
            ))}
          <div className={s.Input}>
            <div style={{ display: 'inline-block' }}>
              <input
                name={name}
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                value={inputValue}
                onChange={handleInputChange}
                ref={inputRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                style={{ width: DEFAULT_WIDTH }}
              />
              <div ref={widthCalculatorRef} className={s.widthCalculator}>
                {inputValue}
              </div>
            </div>
          </div>
        </div>
        <div className={s.actions}>
          <div style={{ height: '100%' }}>
            {isLoading && (
              <div className={s.spinner}>
                <PulseLoader size={8} color="#2495E1" loading={isLoading} />
              </div>
            )}
            {!isLoading && canAddMore && (
              <button
                className={cn(
                  s.apply,
                  isApplyDisabled && s.disabled,
                  !isEmpty(inputValue) && s.show,
                )}
                onClick={(e) => {
                  e.preventDefault();
                  onApply();
                }}
              >
                <div className={s.label}>{t('Apply')}</div>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={s.errorMessage}>{children}</div>
    </div>
  );
}

interface PropsField extends Props {}

export const CouponInputField = ({
  onChange,
  onBlur,
  errorsClassName,
  ...props
}: PropsField) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <CouponInput
      {...field}
      {...props}
      onChange={
        onChange
          ? onChange
          : (value: CouponInputOption[]) => helpers.setValue(value)
      }
      onBlur={(e) => {
        onBlur?.(e);
        !meta.touched && helpers.setTouched(true);
      }}
    >
      <ErrorMessage
        className={errorsClassName}
        name={props.name}
        component="p"
      />
    </CouponInput>
  );
};

export default memo(CouponInput);
