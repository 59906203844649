import React, { Fragment, ReactElement, useState } from 'react';
import styles from './PersonalDataForm.module.scss';
import {
  PreventSubmitOnKeyDown,
  TTPDatePickerField,
  TTPInputField,
  TTPRadioGroupField,
  TTPSelectField,
} from 'components/Common/TTPForm';
import cn from 'classnames';
import NextStep from '../NextStep/NextStep';
import {
  Formik,
  Form,
  FormikHelpers,
  FormikErrors,
  FormikTouched,
  FormikProps,
} from 'formik';
import t from 'i18n';
import * as yup from 'yup';
import { Gender, UserInscriptionState } from 'store/Guests/types';
import {
  accesGratuiteOptions,
  certificationParam1Options,
  getFormInitialValues,
  isRequiredCertificateFields,
  isRequiredStudyFields,
  languagesOptions,
  sexOptions,
  PersonalDataFormValues,
  MemberItaaTitles,
  StudentItaaTitles,
  getSchoolOptions,
  isRequiredSchoolFields,
} from './services';
import { getUserNameForAvatar, parseJson } from 'utils';
import { User } from 'store/Auth/types';
import { LANGUAGES, TTP_API_URL } from 'config';
import { isEmpty, omitBy, pick } from 'lodash';
import { Language, ResourceData } from 'store/types';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'components/Common/LocalLoader/LocalLoader';
import { Guest } from 'store/Guests/types';
import { LayoutTheme } from 'components/Layout/services';
import { Subscription } from 'store/Subscription/types';
import GoBack from '../GoBack';
import moment, { Moment } from 'moment';
import { School } from 'interfaces/School';
import { ReactComponent as InfoIcon } from 'assets/icons/alert-circle.svg';

interface Props {
  theme?: LayoutTheme;
  data: Guest | Subscription;
  user: User;
  hideMembership?: boolean; // HideMemberShip may force 2CELL layouts
  isMembershipRequired?: boolean;
  wrapperClassName?: string;
  formClassName?: string;
  actionsClassName?: string;
  submitText?: string;
  hideSubmitIcon?: boolean;
  layout?: '3CELL' | '2CELL';
  onCancel?: () => void;
  onSubmit: (data: ResourceData<Guest | Subscription>) => any;
  schools?: School[];
  isSchoolsFetching?: boolean;
  organization?: string;
}

export default function PersonalDataForm({
  theme,
  user,
  data,
  hideMembership,
  isMembershipRequired = true,
  wrapperClassName,
  formClassName,
  actionsClassName,
  submitText,
  hideSubmitIcon,
  layout = '2CELL',
  onSubmit,
  onCancel,
  schools,
  isSchoolsFetching,
  organization,
}: Props): ReactElement {
  const [formStatus, setFormStatus] = useState<string>('');

  const titleSelectValues = MemberItaaTitles.concat(StudentItaaTitles).map(
    (item) => item.value,
  );
  const schoolsOptions = getSchoolOptions(schools);

  const handleSubmit = (
    values: PersonalDataFormValues,
    formikHelpers: FormikHelpers<PersonalDataFormValues>,
  ): void | Promise<any> => {
    let _data: ResourceData<Guest> = omitBy(
      pick(data, ['id', 'fullName', 'uid']),
      isEmpty,
    );

    const userInscriptionState: UserInscriptionState = {
      id: user.id,
      userId: user.id,
      uid: user.uid,
      firstName: user.firstName,
      lastName: user.lastName,
      mainEmail: user.mainEmail,
      language: (values.language ?? '').toLowerCase() as Language,
      gender: values.gender as Gender,
      mainPhone: values.mainPhone,
      organization: values.organization,
      function: values.function,
      birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : '',
      membership: hideMembership
        ? undefined
        : {
            type: 'fff', // todo
            data: {
              status: values.status ?? '',
              studyDegree: values.studyDegree,
              school: values.school,
              studyTitle: values.studyTitle,
              dateFromStudy: values.dateFromStudy
                ? values.dateFromStudy.format('YYYY-MM-DD')
                : '',
              certificationTitle: values.certificationTitle,
              certificationNumber: values.certificationNumber
                .replaceAll('.', '')
                .replaceAll(' ', ''),
              certificationParam1: values.certificationParam1 ?? '',
            },
          },
    };
    _data = {
      ..._data,
      member: hideMembership ? data.member : 2,
      userInscriptionState: JSON.stringify(userInscriptionState),
    };

    if (data.cycleId !== 0) {
      _data.cycleTypeRegistration = data.cycleTypeRegistration;
    }

    return onSubmit(_data);
  };

  const resetStatusRelatedFields = (
    values: PersonalDataFormValues,
    errors: FormikErrors<PersonalDataFormValues>,
    touched: FormikTouched<PersonalDataFormValues>,
    resetForm: FormikHelpers<PersonalDataFormValues>['resetForm'],
  ) => {
    resetForm({
      values: {
        ...values,
        studyDegree: '',
        school: '',
        studyTitle: '',
        dateFromStudy: null,
        certificationTitle: '',
        certificationNumber: '',
        certificationParam1: null,
      },
      errors: {
        ...errors,
        studyDegree: undefined,
        school: undefined,
        studyTitle: undefined,
        dateFromStudy: undefined,
        certificationTitle: undefined,
        certificationNumber: undefined,
        certificationParam1: undefined,
      },
      touched: {
        ...touched,
        studyDegree: undefined,
        school: undefined,
        studyTitle: undefined,
        dateFromStudy: undefined,
        certificationTitle: undefined,
        certificationNumber: undefined,
        certificationParam1: undefined,
      },
    });
  };

  const { avatarUrl, avatar } = user;
  const userInscriptionState = parseJson<UserInscriptionState>(
    data.userInscriptionState,
  );
  const { firstName, lastName, mainEmail } = userInscriptionState ?? {};
  const initialValues = getFormInitialValues(
    userInscriptionState,
    organization,
  );
  const certificationValidation = (schema: any, otherwise?: any) => {
    return yup.mixed().when('status', {
      is: (status: any) => {
        return isRequiredCertificateFields(status);
      },
      then: schema,
      otherwise,
    });
  };

  const studyValidation = (schema: any, otherwise?: any) => {
    return yup.mixed().when('status', {
      is: (status: string) => isRequiredStudyFields(status),
      then: schema,
      otherwise,
    });
  };

  const getStatusValidator = () => {
    if (hideMembership) {
      return {};
    }

    const statusValidation = yup
      .string()
      .nullable()
      .label(t('inscription.vous_etes'));

    if (isMembershipRequired) {
      statusValidation.required(t('yup.required'));
    }
    return {
      status: statusValidation,
      studyDegree: studyValidation(
        yup.string().required(t('yup.required')),
      ).label(t('inscription.studyDegree')),
      school: studyValidation(
        yup
          .string()
          .required(t('yup.required'))
          .test('school', t('inscription.school.error'), (value: string) => {
            return value !== 'other';
          }),
      ).label(t('inscription.school')),
      studyTitle: studyValidation(
        yup.string().required(t('yup.required')),
      ).label(t('inscription.title')),
      dateFromStudy: studyValidation(
        yup.string().required(t('yup.required')),
      ).label(t('inscription.dateFromStudy')),
      certificationTitle:
        formStatus === 'mItaa' || formStatus === 'sItaa'
          ? certificationValidation(
              yup
                .string()
                .required(t('yup.required'))
                .test(
                  'certificationTitle',
                  t('yup.required'),
                  (value: string) => {
                    return titleSelectValues.includes(value);
                  },
                ),
            ).label(t('inscription.title'))
          : certificationValidation(
              yup.string().required(t('yup.required')),
            ).label(t('inscription.title')),
      certificationNumber: certificationValidation(
        yup
          .string()
          .required(t('yup.required'))
          .test('certificationNumber', t('yup.invalid'), (value: string) => {
            if (!value) {
              value = '_';
            }
            return !value.includes('_');
          }),
      ).label(t('inscription.certificationNumber')),
      certificationParam1: certificationValidation(
        yup.string().required(t('yup.required')).nullable(),
      ).label(t('inscription.vous_etes')),
    };
  };

  const validationSchema = yup.object().shape({
    mainPhone: yup
      .string()
      .required(t('yup.required'))
      .label(t('inscription.gsm')),
    birthday: yup
      .object()
      .nullable()
      .required(t('yup.required'))
      .test('is-greater', t('yup.min_age_12'), (value: Moment) => {
        if (isEmpty(value) || typeof value !== 'object') {
          return true;
        }
        return moment().diff(value, 'years') >= 12;
      })
      .label(t('inscription.date_naissance')),
    language: yup
      .string()
      .required(t('yup.required'))
      .oneOf(LANGUAGES, t('yup.invalid'))
      .label(t('inscription.langue')),
    gender: yup
      .string()
      .required(t('yup.required'))
      .oneOf(['MALE', 'FEMALE'], t('yup.invalid'))
      .label(t('inscription.je_suis')),
    organization: yup
      .string()
      .required(t('yup.required'))
      .label(t('inscription.organisation')),
    function: yup
      .string()
      .required(t('yup.required'))
      .label(t('inscription.fonction')),
    ...getStatusValidator(),
  });

  // ____________________ RENDER ____________________//

  const currentLayout = hideMembership ? '2CELL' : layout;
  const is3Cells = currentLayout === '3CELL';

  const renderMembershipFields = (
    status: string | null,
    schoolValue: string | null,
  ) => {
    if (isRequiredCertificateFields(status)) {
      return (
        <>
          {status === 'mItaa' || status === 'sItaa' ? (
            <TTPSelectField
              theme={theme}
              name="certificationTitle"
              wrapperClassName={styles.field}
              label={t('inscription.title')}
              options={
                status === 'mItaa'
                  ? MemberItaaTitles.map((item) => ({
                      label: t(item.label),
                      value: item.value,
                    }))
                  : StudentItaaTitles.map((item) => ({
                      label: t(item.label),
                      value: item.value,
                    }))
              }
              placeholder={t('inscription.param1')}
              required={true}
            />
          ) : (
            <TTPInputField
              theme={theme}
              name="certificationTitle"
              wrapperClassName={styles.field}
              label={t('inscription.title')}
              placeholder={t('inscription.title')}
              required={true}
            />
          )}
          <TTPInputField
            theme={theme}
            name="certificationNumber"
            wrapperClassName={styles.field}
            label={t('inscription.certificationNumber')}
            isInputMask={true}
            mask={
              status === 'mItaa' || status === 'sItaa'
                ? '9 9 . 9 9 9 . 9 9 9'
                : 'a  9 9 9 9 9'
            }
            required={true}
          />
          <TTPSelectField
            theme={theme}
            name="certificationParam1"
            wrapperClassName={styles.field}
            label={t('inscription.vous_etes')}
            options={certificationParam1Options}
            placeholder={t('inscription.param1')}
            required={true}
          />
        </>
      );
    } else if (isRequiredStudyFields(status)) {
      const showAdditionalSchoolFields =
        !schools || (schools && isRequiredSchoolFields(schoolValue));

      return (
        <>
          {schools ? (
            <Fragment>
              <div className={styles.info_message}>
                <div className="m-r-xs">
                  <InfoIcon width="14" height="14" fill="#18a0fb" />
                </div>
                <span>{t('inscription.school.alert')}</span>
              </div>
              <TTPSelectField
                theme={theme}
                name="school"
                wrapperClassName={styles.field}
                label={t('inscription.school')}
                options={schoolsOptions}
                placeholder={t('inscription.param1')}
                required={true}
                isLoading={isSchoolsFetching}
                isSearchable
                inputStyle={{
                  color: '#29394D',
                  caretColor: '#29394D',
                }}
              />
            </Fragment>
          ) : (
            <TTPInputField
              theme={theme}
              name="school"
              wrapperClassName={styles.field}
              label={t('inscription.school')}
              placeholder={t('inscription.school')}
              required={true}
            />
          )}
          {showAdditionalSchoolFields && (
            <Fragment>
              <TTPInputField
                theme={theme}
                name="studyTitle"
                wrapperClassName={styles.field}
                label={t('inscription.title')}
                placeholder={t('inscription.title')}
                required={true}
              />
              <TTPInputField
                theme={theme}
                name="studyDegree"
                wrapperClassName={styles.field}
                label={t('inscription.studyDegree')}
                placeholder={t('inscription.studyDegree')}
                required={true}
              />
              <TTPDatePickerField
                theme={theme}
                name="dateFromStudy"
                wrapperClassName={styles.field}
                label={t('inscription.dateFromStudy')}
                placeholder={t('inscription.dateFromStudy')}
                showIcon={true}
                required={true}
              />
            </Fragment>
          )}
        </>
      );
    }
  };

  const renderProfileForm = (
    className: string,
    errors: FormikErrors<PersonalDataFormValues>,
  ) => (
    <div
      className={cn(
        'cell p-b-l p-s',
        className,
        styles.profileWrapper /*styles.section*/,
        styles.rightBorder,
      )}
    >
      <div className={styles.infos}>
        <div
          className={cn('flex-container align-middle', styles.infos_wrapper)}
        >
          <div>
            {!isEmpty(avatar) || !isEmpty(avatarUrl) ? (
              <div
                className={styles.avatar}
                style={{
                  backgroundImage: `url(${
                    avatarUrl ? avatarUrl : TTP_API_URL + '/' + avatar
                  })`,
                }}
              ></div>
            ) : (
              <div className={cn(styles.avatar, 'empty-avatar')}>
                <span>{getUserNameForAvatar(firstName, lastName)}</span>
              </div>
            )}
          </div>
          <div className="m-l-s">
            <h5>{`${firstName} ${lastName}`.trim()}</h5>
            <h6>{mainEmail}</h6>
          </div>
        </div>
      </div>
      <div className="m-t-s">
        <TTPInputField
          theme={theme}
          name="mainPhone"
          wrapperClassName={styles.field}
          label={t('inscription.gsm')}
          placeholder={t('(ex: +32 xxx xx xxxx)')}
          required={true}
        />
        <TTPDatePickerField
          theme={theme}
          name="birthday"
          wrapperClassName={styles.field}
          label={t('inscription.date_naissance')}
          placeholder={t('inscription.date_naissance_ph')}
          showIcon={true}
          required={true}
          hasError={!isEmpty(errors.birthday)}
        />
        <TTPRadioGroupField
          theme={theme}
          name="language"
          wrapperClassName={styles.field}
          options={languagesOptions}
          label={t('inscription.langue')}
          required={true}
        />
        <TTPRadioGroupField
          theme={theme}
          name="gender"
          wrapperClassName={cn(styles.field, 'm-b-0 p-b-s')}
          options={sexOptions.map((sex) => ({
            label: t(sex.label),
            value: sex.value,
          }))}
          label={t('inscription.je_suis')}
          required={true}
        />
      </div>
    </div>
  );

  const renderCompanyForm = () => (
    <div className="p-t-s">
      <h3>{t('My company')} :</h3>
      <TTPInputField
        theme={theme}
        name="organization"
        wrapperClassName={styles.field}
        label={t('inscription.organisation')}
        placeholder={t('inscription.organisation')}
        required={true}
      />
      <TTPInputField
        theme={theme}
        name="function"
        wrapperClassName={styles.field}
        label={t('inscription.fonction')}
        placeholder={t('inscription.fonction')}
        required={true}
      />
    </div>
  );

  const renderAsForm = ({
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  }: Pick<
    FormikProps<PersonalDataFormValues>,
    'values' | 'errors' | 'touched' | 'resetForm' | 'setFieldValue'
  >) =>
    !hideMembership && (
      <div className="p-s m-b-xl">
        <h3>{t('As')} :</h3>
        <TTPSelectField
          theme={theme}
          name="status"
          wrapperClassName={styles.field}
          label={t('inscription.vous_etes')}
          options={accesGratuiteOptions.map((item) => ({
            label: t(item.label),
            options: item.options?.map((option) => ({
              label: t(option.label),
              value: option.value,
            })),
            value: item.value,
          }))}
          placeholder={t('inscription.choisissez')}
          onChange={(option: any) => {
            resetStatusRelatedFields(values, errors, touched, resetForm);
            setFieldValue('status', option?.value ?? null);
            setFormStatus(option?.value ?? '');
          }}
          required={isMembershipRequired}
          isClearable={true}
        />
        {renderMembershipFields(values.status, values.school)}
      </div>
    );

  const lefSectionClassName = is3Cells
    ? 'small-12 medium-6 large-4'
    : 'small-12 medium-6';
  const rightSectionClassName = is3Cells
    ? 'small-12 medium-6 large-8 grid-x'
    : 'small-12 medium-6 p-b-l';
  const subSectionClassName = is3Cells
    ? 'cell small-12 large-6'
    : 'cell small-12';

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValidating,
        setFieldValue,
        resetForm,
      }) => (
        <Form onKeyDown={PreventSubmitOnKeyDown} className={formClassName}>
          <LocalLoaderWrapper className={wrapperClassName}>
            <div className={cn(styles.wrapper, wrapperClassName)}>
              <div className="grid-x flex-1">
                {renderProfileForm(lefSectionClassName, errors)}
                <div className={rightSectionClassName}>
                  <div
                    className={cn(
                      subSectionClassName,
                      styles.section,
                      styles.rightBorder,
                    )}
                  >
                    {renderCompanyForm()}
                  </div>
                  <div className={cn(subSectionClassName, styles.section)}>
                    {renderAsForm({
                      values,
                      errors,
                      touched,
                      resetForm,
                      setFieldValue,
                    })}
                  </div>
                </div>
              </div>
            </div>
            <LocalLoader loading={isSubmitting || isValidating} />
          </LocalLoaderWrapper>
          <div className={cn('p-t-s flex-container', actionsClassName)}>
            {onCancel && (
              <GoBack
                disabled={isSubmitting || isValidating}
                onClick={onCancel}
                text={t('Cancel')}
                hideIcon={true}
                className="m-r-s"
              />
            )}
            <NextStep
              className="m-l-auto"
              theme={theme}
              disabled={isSubmitting || isValidating}
              text={submitText}
              hideIcon={hideSubmitIcon}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
