import { Component } from 'react';
import PropTypes from 'prop-types';
import { URLS } from 'router';
import { matchPath } from 'react-router-dom';

export default class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.any,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (
        prevProps.location.pathname === URLS.planSelector ||
        (this.props.location.pathname !== URLS.search &&
          !matchPath(this.props.location.pathname, [
            URLS.webinar.reception.registration.root,
            URLS.webinar.reception.administration,
            URLS.cycle.reception.registration.root,
            URLS.subscription.reception.registration.root,
          ]))
      ) {
        $('html, body').animate({ scrollTop: 0 });
        // eslint-disable-next-line no-unused-expressions
        document
          .querySelector('#sub-menu')
          ?.classList.remove('visibility-hidden');
      }
    }
  }

  render() {
    return this.props.children;
  }
}
