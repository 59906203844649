import {
  PromotedPopupState,
  PromotedPopupActionTypes,
  RESET_PROMOTED_POPUP_TIMEOUT_ID,
  SET_PROMOTED_POPUP_TIMEOUT_ID,
} from './types';

const initialState = {
  timeoutId: null,
};

const reducer = (
  state: PromotedPopupState = initialState,
  action: PromotedPopupActionTypes,
) => {
  switch (action.type) {
    case RESET_PROMOTED_POPUP_TIMEOUT_ID: {
      return { ...initialState };
    }
    case SET_PROMOTED_POPUP_TIMEOUT_ID: {
      return { ...state, timeoutId: action.timeoutId };
    }
    default:
      return state;
  }
};

export default reducer;
