import {
  FETCH_EVENTS_REPLAY,
  SET_EVENTS_REPLAY_PAGE_SIZE,
  SET_EVENTS_REPLAY_PAGE,
} from './types';

export const fetchEventsReplay = (
  resp: any,
): { payload: any; type: string } => ({
  type: FETCH_EVENTS_REPLAY,
  payload: resp,
});

export const setEventsReplayPageSize = (
  pageSize: number,
): { payload: any; type: string } => ({
  type: SET_EVENTS_REPLAY_PAGE_SIZE,
  payload: pageSize,
});

export const setEventsReplayPage = (
  page: number,
): { payload: any; type: string } => ({
  type: SET_EVENTS_REPLAY_PAGE,
  payload: page,
});
export default {
  fetchEventsReplay,
  setEventsReplayPageSize,
  setEventsReplayPage,
};
