import { useMemo } from 'react';
import { Coupon } from 'store/Guests/types';
import { getSelectedCoupons } from 'utils';
import { GuestData } from '../services';

export const useSelectedCoupons = (
  data: Pick<GuestData, 'coupons'>,
  coupons: Coupon[],
  defaultCoupon?: string,
) =>
  useMemo(
    () => getSelectedCoupons(data.coupons ?? [], coupons, defaultCoupon),
    [coupons, data.coupons, defaultCoupon],
  );
