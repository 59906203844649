import React, { memo } from 'react';
import styles from './SearchInput.module.scss';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import classNames from 'classnames';
import _ from 'i18n';

interface Props {
  search: string;
  className?: string;
  colors?: {
    searchIconColor?: string;
    clearIconColor?: string;
  };
  width?: number | string;
  handleSearchChange: (value: string) => void;
}

export default memo(function SearchInput({
  search,
  className,
  colors,
  width,
  handleSearchChange,
}: Props) {
  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(value);
  };

  return (
    <div className={classNames(styles.search, className)} style={{ width }}>
      <SearchIcon
        fill={colors?.searchIconColor ?? '#B7BDC3'}
        width="16"
        height="16"
      />
      <input
        value={search ?? ''}
        placeholder={`${_('Search')} ...`}
        onChange={handleChange}
      />
      {search && (
        <CrossIcon
          className={classNames('m-r-xxs', styles.clean)}
          fill={colors?.clearIconColor ?? '#B7BDC3'}
          width="12"
          height="12"
          onClick={() => handleSearchChange('')}
        />
      )}
    </div>
  );
});
