import React, { CSSProperties, memo, useState } from 'react';
import styles from './TTPSelect.module.scss';
import formStyles from '../TTPForm.module.scss';
import { Props as ReactSelectProps } from 'react-select';
import { default as Select } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import { TTPFormFieldProps } from '../types';
import {
  findSelectedValue,
  getSelectStyles,
  getAsyncSelectStyles,
  DropdownIndicator,
  NoOptionsMessage,
  LoadingMessage,
  Menu,
} from './services';
import { ErrorMessage, FormikHelpers, FormikValues, useField } from 'formik';

interface Props extends Omit<ReactSelectProps, 'value'>, TTPFormFieldProps {
  isCreatable?: boolean;
  value: string | number | boolean;
  textStyle?: CSSProperties;
  inputStyle?: CSSProperties;
}

const TTPSelect = ({
  theme = '',
  name,
  required,
  labelClassName,
  options,
  value,
  wrapperClassName,
  isHorizontal,
  label,
  children,
  textStyle,
  inputStyle,
  ...props
}: Props) => {
  // TODO: Add conditional props to accept just one of isCreatable and isAsync
  const SelectComponent: typeof React.Component = props.isCreatable
    ? CreatableSelect
    : props.isAsync
    ? AsyncSelect
    : Select;

  const selectStyles = props.isAsync
    ? getAsyncSelectStyles(theme, textStyle, inputStyle)
    : getSelectStyles(theme, textStyle, inputStyle);

  return (
    <div
      className={classNames(
        wrapperClassName,
        formStyles.ttpField,
        formStyles[theme],
      )}
    >
      <div className={classNames(isHorizontal && formStyles.hGroup)}>
        {label && (
          <label
            htmlFor={`input-${name}`}
            className={classNames(formStyles.inputLabel, labelClassName)}
          >
            {label}
            {required && <span className={formStyles.required}>*</span>}
          </label>
        )}
        <div className={formStyles.inputWrapper}>
          <SelectComponent
            className={classNames(styles.ttp_select_container)}
            classNamePrefix="ttp_form_select"
            label="Single select"
            styles={selectStyles}
            components={{
              DropdownIndicator,
              NoOptionsMessage,
              LoadingMessage,
              Menu,
            }}
            options={options}
            value={
              value !== undefined ? findSelectedValue(value, options) : value
            }
            {...props}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

interface PropsField extends Omit<Props, 'value'> {}

export const TTPSelectField = ({
  onChange,
  onBlur,
  options,
  ...props
}: PropsField) => {
  const [field, meta, helpers] = useField(props.name);
  const [asyncOptions, setAsyncOptions] = useState<any>(
    props.isAsync ? props.defaultOptions : [],
  );

  const handleOnChange = (option: any) => {
    const fieldValue =
      option instanceof Array
        ? option.map((item) => item.value)
        : option?.value;
    helpers.setValue(fieldValue);
    setAsyncOptions(option ? [...asyncOptions, option] : []);
  };

  return (
    <TTPSelect
      {...field}
      {...props}
      onChange={onChange ? onChange : handleOnChange}
      onBlur={() => {
        onBlur?.();
        !meta.touched && helpers.setTouched(true);
      }}
      options={props.isAsync ? asyncOptions : options}
    >
      {props.customizeError ? (
        ''
      ) : (
        <ErrorMessage name={props.name} component="p" />
      )}
    </TTPSelect>
  );
};

export default memo(TTPSelect);
