import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import URLS from 'router/urls';
import { isInIframe } from 'utils';
import { NotFoundPage } from 'Pages/NotFound';
import PremiumHero from 'Watch/PremiumHero/PremiumHero.wrap';
import { usePremiumSharedStatus } from 'hooks/usePremiumSharedStatus';
import TTPLoader from 'components/Common/TTPLoader';
import PageContainer from 'components/Layout/PageContainer';

interface Props {
  component?: React.ComponentType<any>;
  [p: string]: any;
}

const WatchRoute = ({ component: ComponentToRender, ...rest }: Props) => {
  const { isShared, isFetching } = usePremiumSharedStatus();

  if (!isInIframe()) {
    return <Route {...rest} component={NotFoundPage} />;
  }

  if (isFetching) {
    return (
      <PageContainer bgColor="#29394d">
        <TTPLoader />
      </PageContainer>
    );
  }

  if (ComponentToRender) {
    return (
      <Route {...rest}>
        {isShared ? <ComponentToRender /> : <PremiumHero />}
      </Route>
    );
  }

  return (
    <Route
      {...rest}
      render={(routerProps) =>
        isShared ? (
          <Redirect
            to={{
              pathname: URLS.watch.trainings,
              state: { from: routerProps.location },
            }}
          />
        ) : (
          <PremiumHero />
        )
      }
    />
  );
};

export default WatchRoute;
