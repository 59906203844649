import React, { createContext, useContext } from 'react';
import { AdvancedLayoutTheme } from '../services';

export interface Theme {
  name: AdvancedLayoutTheme;
  primaryColor: string;
  primaryColorDark: string;
  primaryColorLight: string;
  secondaryColor: string;
  secondaryColorLight: string;
}

export const BlueTheme: Theme = {
  name: 'blue',
  primaryColor: '#18a0fb',
  primaryColorDark: '#2495E1',
  primaryColorLight: '#c8e4f7',
  secondaryColor: '#c7e8fe',
  secondaryColorLight: '#f3faff',
};

export const OrangeTheme: Theme = {
  name: 'orange',
  primaryColor: '#f47f4d',
  primaryColorDark: '#DB6939',
  primaryColorLight: '#f6d9cd',
  secondaryColor: '#ffe1cc',
  secondaryColorLight: '#fef0e3',
};

export const OrangeRoyalTheme: Theme = {
  name: 'orange',
  primaryColor: '#f7953e',
  primaryColorDark: '#f7953e',
  primaryColorLight: '#f6d9cd',
  secondaryColor: '#ffe1cc',
  secondaryColorLight: '#fcfaf3',
};

export const RedOrangeTheme: Theme = {
  name: 'redOrange',
  primaryColor: '#FB1E5B',
  primaryColorDark: '#cc1c4d',
  primaryColorLight: '#ffdce6',
  secondaryColor: '#ffe2dc',
  secondaryColorLight: '#fff1f0',
};

export const PurpleTheme: Theme = {
  name: 'purple',
  primaryColor: '#6F6CEC',
  primaryColorDark: '#5F5DE8',
  primaryColorLight: '#D7CFFF',
  secondaryColor: '#D7CFFF',
  secondaryColorLight: '#F4F2FF',
};

export const GreenTealTheme: Theme = {
  name: 'greenTeal',
  primaryColor: '#14B7AD',
  primaryColorDark: '#14B7AD',
  primaryColorLight: '#6FD3C9',
  secondaryColor: '#6FD3C9',
  secondaryColorLight: '#ECFCFB',
};

export const DefaultTheme: Theme = {
  name: '',
  primaryColor: '#D7DBE0',
  primaryColorDark: '#F1F2F4',
  primaryColorLight: '#F8F9FA',
  secondaryColor: '#FAFBFB',
  secondaryColorLight: '#FFFFFF',
};

export const getTheme = (theme: AdvancedLayoutTheme) => {
  switch (theme) {
    case OrangeTheme.name:
      return OrangeTheme;
    case RedOrangeTheme.name:
      return RedOrangeTheme;
    default:
      return BlueTheme;
  }
};

export const ThemeContext = createContext<Theme>(BlueTheme);

interface Props {
  children?: React.ReactNode;
  value?: Theme;
}

export const useAdvancedLayoutTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children, value = BlueTheme }: Props) => {
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
