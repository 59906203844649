import React, { memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import s from './ModalWatchSearch.module.scss';
import cn from 'classnames';
import t from 'i18n';
import { ModalState } from 'store/Modal/types';
import SearchHeader from './SearchHeader';
import SearchResultPage from 'components/Watch/EventSlide/SearchResultPage';
import { capitalize, isEmpty } from 'lodash';
import { Filter, SingleValueFilter, SingleValueType } from 'services/Filter';
import LanguageCircles from 'components/Common/LanguageCircles';
import { Language } from 'store/types';
import { LanguageOptionsType } from 'store/Params/Language';
import { getLanguagesFilter } from 'utils';
import { EventsCategoriesState } from 'store/EventsCategories/types';
import TagsFilterTablet from 'components/Watch/EventSlide/EventFilters/TagsFilter/Tablet/TagsFilterTablet';
import { EventState } from 'store/Events/types';
import { FFF_ID, LANGUAGES } from 'config';
import { User } from 'store/Auth/types';

interface Props {
  modal: ModalState<any>;
  user?: User | null;
  eventsCategories: EventsCategoriesState;
  eventsListResource: EventState;
  searchFilter: SingleValueFilter<SingleValueType>;
  filters: Filter[];
  language: Language;
  fetchEventsStats: (params?: Record<string, any>) => void;
  addEventFilter: (filter: Filter) => void;
  removeEventFilter: (filter: Filter) => void;
  fetchEventsCategories: () => any;
  onCloseModal: () => void;
}

export default memo(function ModalWatch({
  modal,
  user,
  eventsCategories,
  eventsListResource: { statsFetching: isStatsFetching, stats },
  searchFilter,
  filters,
  language,
  addEventFilter: addEventFilterAction,
  removeEventFilter: removeEventFilterAction,
  fetchEventsCategories,
  fetchEventsStats,
  onCloseModal,
}: Props) {
  const [keyword, setKeyword] = useState<string>('');
  const [showResults, setShowResults] = useState(false);
  const [languageFilter, setLanguageFilter] = useState<Language[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { future_events: fEvents = 0, replay_events: rEvents = 0 } =
    stats?.events ?? {};

  const periods = [
    {
      id: 'future',
      title: t('Live'),
      isWide: false,
      count: fEvents,
    },
    {
      id: 'replay',
      title: t('REPLAY'),
      isWide: false,
      count: rEvents,
    },
  ];

  useEffect(() => {
    fetchEventsCategories();
  }, []);

  useEffect(() => {
    addEventFilter(
      new SingleValueFilter('languages', 'eq', languageFilter.join(',')),
    );
    if (!isStatsFetching) {
      fetchEventsStats({
        languages: languageFilter.length
          ? languageFilter.join(',')
          : LANGUAGES.join(','),
        userId: user?.id,
        communityIds: FFF_ID,
      });
    }
  }, [languageFilter]);

  const addEventFilter = useCallback(
    (filter: Filter) => {
      addEventFilterAction(filter);
    },
    [addEventFilterAction],
  );

  const removeEventFilter = useCallback(
    (filter: Filter) => {
      setKeyword('');
      setSelectedTags([]);
      removeEventFilterAction(filter);
    },
    [removeEventFilterAction],
  );

  const handleDeleteFilters = () => {
    filters.map((filter) => {
      removeEventFilter(filter);
    });
  };

  const handleSearchClick = () => {
    setShowResults(true);
  };

  const handleLanguageChange = (languageOptions: LanguageOptionsType) => {
    setLanguageFilter(getLanguagesFilter(languageOptions));
  };

  const handleSelectCategory = (category: string, categoryLabel: string) => {
    handleDeleteFilters();
    setKeyword(capitalize(categoryLabel));
    setSelectedTags([category]);
    addEventFilter(new SingleValueFilter('category', 'eq', category));
    addEventFilter(
      new SingleValueFilter('languages', 'eq', languageFilter.join(',')),
    );
    handleSearchClick();
  };

  const handleSelectPeriod = (period: string, periodLabel: string) => {
    handleDeleteFilters();
    setKeyword(capitalize(periodLabel));
    setSelectedTags([period]);
    addEventFilter(new SingleValueFilter('period', 'eq', period));
    addEventFilter(
      new SingleValueFilter('languages', 'eq', languageFilter.join(',')),
    );
    handleSearchClick();
  };

  const handleOnCancel = () => {
    if (showResults) {
      handleDeleteFilters();
    } else {
      onCloseModal();
    }
    setShowResults(false);
  };

  const renderTagsFilter = () => {
    if (eventsCategories.fetching) {
      return <TagsFilterTablet.fetching />;
    }

    if (!eventsCategories?.items?.length) {
      return null;
    }

    return (
      <TagsFilterTablet
        periods={periods}
        tags={eventsCategories.items}
        selectedTags={selectedTags}
        setTag={handleSelectCategory}
        selectPeriod={handleSelectPeriod}
      />
    );
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={false}
      overlayClassName="ReactModal__Overlay_DARK"
      className={cn(s.modal, showResults && s.solid_bg)}
      shouldCloseOnEsc={false}
    >
      <SearchHeader
        searchValue={keyword}
        onChange={setKeyword}
        onSearch={handleSearchClick}
        onCancel={handleOnCancel}
        addEventFilter={addEventFilter}
        removeEventFilter={removeEventFilter}
        filter={searchFilter}
      />

      <div className={s.language_filter}>
        <LanguageCircles
          bgColor="#F1F2F4"
          handleLanguageClick={handleLanguageChange}
          listClassName="m-0"
          defaultLng={language}
          asFullText
        />
      </div>
      {showResults ? (
        <SearchResultPage wrapperClassName={s.search_results} />
      ) : (
        <div>{renderTagsFilter()}</div>
      )}
    </Modal>
  );
});
