import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import s from './OrderPreview.module.scss';
import cn from 'classnames';
import t from 'i18n';
import { isEmpty } from 'lodash';
import {
  formatDateFromTo,
  formatDecimalHours,
  getNextYearDate,
  onError,
  onSuccess,
  prepareS3ResourceUrl,
} from 'utils';
import { getPreviewData } from './services';
import { Language, TTPSort } from 'store/types';
import { Order } from 'store/Guests/types';
import { ReactComponent as CircleIcon } from 'assets/icons/Ellipse.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashcan.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import PriceLabel from 'components/ui/PriceLabel';
import { S3_FOLDER_URL } from 'config';
import { Filter, MultiValueFilter, SingleValueFilter } from 'services/Filter';
import { User } from 'store/Auth/types';

interface Props {
  order: Order;
  language: Language;
  setIsCartOpen: (isCartOpen: boolean) => void;
  onDelete: (guestId: number, orderId: number) => any;
  fetchUserOrders: (
    userId: number,
    filters?: Filter[],
    sort?: TTPSort[],
  ) => void;
  user?: User | null;
}

const OrderPreview = ({
  order,
  language,
  setIsCartOpen,
  onDelete,
  fetchUserOrders,
  user,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    themeColor,
    titleLabel,
    title,
    bannerUrl,
    startDateTime,
    endDateTime,
    speakersLabel,
    dateEndOfReplay,
    price,
    originalPrice,
    trainingHours,
    trainingsCount,
    cycleTrainingHours,
    actionLink,
    receptionLink,
    isPremiumOffer,
    isSeason,
    isCycle,
    isEvent,
    isReplayable,
    isExpired,
    isUpcoming,
    eventId,
    isCertificateNotIncluded,
  } = getPreviewData(order, language);

  const dateHelper = formatDateFromTo(startDateTime, endDateTime, language);
  const endDate = formatDateFromTo('', endDateTime, language, 'L');
  const bannerImgUrl = !isEmpty(bannerUrl)
    ? prepareS3ResourceUrl(S3_FOLDER_URL, bannerUrl)
    : '/img/no-preview-image.png';

  const handleOnDelete = () => {
    setIsDeleting(true);
    onDelete(order.appWorkload.guest, order.id)
      .then((res: any) => {
        onSuccess();
        const filters = [
          new SingleValueFilter('status', 'eq', 'NOT_PAID'),
          new SingleValueFilter('isCanceled', 'eq', 0),
          new MultiValueFilter('appType', 'in', [
            'registering',
            'cycle_registering',
          ]),
        ];
        fetchUserOrders(user?.id ?? 0, filters);
      })
      .catch(onError)
      .finally(() => setIsDeleting(false));
  };

  const renderStatusLabel = () => {
    if (isEvent && isReplayable && isExpired) {
      return (
        <strong className="title-case">
          {t('IN REPLAY').toLocaleLowerCase()}
        </strong>
      );
    }

    if (isEvent && isUpcoming) {
      return (
        <Fragment>
          <strong className="title-case">
            {t('LIVE').toLocaleLowerCase()} :&thinsp;
          </strong>
          {dateHelper}
        </Fragment>
      );
    }

    if (isCycle || isSeason) {
      return (
        <div className="flex-container align-middle">
          <strong className="title-case">
            {t('LIVE').toLocaleLowerCase()} :&thinsp;
          </strong>
          {dateHelper.toLocaleLowerCase()}
          <CircleIcon width="4" height="4" fill="#b2bcc6" className="m-x-xs" />
          <span>
            {trainingsCount} {t('Trainings')}
          </span>
        </div>
      );
    }
  };

  const renderReplayLabel = () => {
    if (isSeason || (isReplayable && isCycle)) {
      return t('super_cycle_replay_info').replace('{date}', dateEndOfReplay);
    }

    if (isReplayable && isEvent) {
      return t('training_info_replay').replace(
        '{date}',
        dateEndOfReplay ? dateEndOfReplay : '31/12/2022',
      );
    }
  };

  const renderCycleHours = () => {
    if ((isCycle || isSeason) && !isEmpty(cycleTrainingHours)) {
      return (
        <li>
          <strong>{formatDecimalHours(cycleTrainingHours)}</strong>
          &thinsp;
          {t('of certified attestations')}
          <CircleIcon width="4" height="4" fill="#b2bcc6" className="m-x-xs" />
          <span>
            {t('Approved by')}&thinsp;<strong>ITAA</strong>
          </span>
        </li>
      );
    }
  };

  const renderCertificateLabel = () => {
    if (!isEvent) return null;

    if (isCertificateNotIncluded) {
      return t('Certificate not included');
    }

    return (
      <>
        {t('Certificate included approved by')} <b>&thinsp;ITAA</b>
      </>
    );
  };

  const renderDescriptionList = () => {
    if (isPremiumOffer) {
      return (
        <ul>
          <li>
            <div
              className={cn(s.big_text, s.uppercase)}
              style={{ lineHeight: '16px' }}
            >
              {t('premium_offer_description').replace(
                ':endDate',
                getNextYearDate(),
              )}
            </div>
          </li>
        </ul>
      );
    }

    return (
      <ul>
        {!isEmpty(trainingHours) && (
          <li>
            {formatDecimalHours(trainingHours, false)} {t('of training')}
          </li>
        )}
        <li>{renderStatusLabel()}</li>
        <li>{renderCycleHours()}</li>
        <li>{renderReplayLabel()}</li>
        <li>{renderCertificateLabel()}</li>
      </ul>
    );
  };

  return (
    <div className={cn(s.wrapper)}>
      <div className={cn(s.borderline, s[themeColor])} />
      <div className={cn(s.preview)}>
        <div className={cn(s.preview_header, 'grid-x m-b-xs')}>
          <div
            className={cn(
              s.banner,
              isEmpty(bannerUrl) && s.empty_banner,
              'cell small-3',
            )}
            style={{ backgroundImage: `url(${bannerImgUrl})` }}
          />
          <div className={cn(s.titles, 'cell small-8')}>
            <Link to={receptionLink ?? ''}>
              {titleLabel && <h3 className={s[themeColor]}>{titleLabel}</h3>}
              {title && <h1>{title}</h1>}
            </Link>
            <h2>{speakersLabel}</h2>
          </div>
          <div
            className={cn(
              s.delete_icon,
              isDeleting && s.disabled,
              'cell small-1',
            )}
            onClick={!isDeleting ? handleOnDelete : undefined}
          >
            {isDeleting ? (
              <ClipLoader size={14} color="#6D7F92" loading={true} />
            ) : (
              <TrashIcon color="#6D7F92" height="14" />
            )}
          </div>
        </div>
        <div className={s.preview_description}>
          {renderDescriptionList()}
          <PriceLabel
            className={'flex-dir-column'}
            price={price}
            originalPrice={originalPrice}
            stylePrice={{
              fontSize: 18,
              fontWeight: 700,
              padding: 0,
              margin: 0,
            }}
            styleOriginalPrice={{
              fontSize: 16,
              fontWeight: 700,
              padding: 0,
              margin: 0,
            }}
            background={'transparent'}
            isOriginalPriceLight
          />
        </div>
        <div className={s.preview_footer}>
          <Link
            to={!isDeleting ? actionLink : '#'}
            onClick={() => setIsCartOpen(false)}
            className={cn(isDeleting && s.disabled)}
          >
            {t('Complete my order')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderPreview;
