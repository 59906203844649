import React, { CSSProperties, ReactNode } from 'react';
import s from './RoundedButton.module.scss';
import cn from 'classnames';

interface Props {
  value: string;
  count?: number;
  size: 1 | 2 | 3;
  textStyle: {
    size: string;
    color?: string;
    activeColor?: string;
    weight?: number;
    isUpper?: boolean;
  };
  bgColors?: {
    default: string;
    active?: string;
  };
  outlineStyle?: {
    color: string;
    width?: string;
    style?: string;
  };
  shadow?: {
    level: 1 | 2 | 3;
    color?: string;
  };
  icon?: ReactNode;
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
}

const RoundedButton = ({
  value,
  size,
  icon,
  textStyle,
  bgColors,
  outlineStyle,
  isActive,
  shadow,
  className,
  onClick,
  count,
}: Props) => {
  const buttonStyle: CSSProperties = {
    padding: `${4 * (size + 1) + 1}px ${15 * size}px`,
    backgroundColor: isActive
      ? bgColors?.active ?? '#6D7F92'
      : bgColors?.default ?? '#F1F2F4',
    borderWidth: outlineStyle && (outlineStyle.width ?? '1px'),
    borderColor: outlineStyle?.color,
    borderStyle: outlineStyle && (outlineStyle.style ?? 'solid'),
    boxShadow:
      shadow &&
      `0px ${2 * shadow.level}px ${5 * shadow.level}px 
      ${shadow.color ?? 'rgba(41, 57, 77, 0.1)'}`,
  };

  const valueStyle: CSSProperties = {
    fontSize: textStyle.size,
    color: isActive
      ? textStyle.activeColor ?? '#FFF'
      : textStyle.color ?? '#6D7F92',
    fontWeight: textStyle.weight ?? 400,
    textTransform: textStyle.isUpper ? 'uppercase' : 'none',
  };

  const iconStyle: CSSProperties = {
    marginRight: `${7 * size}px`,
  };

  return (
    <button
      className={cn(s.wrapper, className)}
      style={buttonStyle}
      onClick={onClick}
    >
      {icon && <span style={iconStyle}>{icon}</span>}
      <span style={valueStyle}>{value}</span>
      {count && (
        <span style={{ ...valueStyle, marginLeft: `${4 * size}px` }}>
          {count}
        </span>
      )}
    </button>
  );
};

export default RoundedButton;
