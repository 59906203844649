import React, { memo } from 'react';
import styles from './TTPForm.module.scss';
import classNames from 'classnames';
import { TTPInputProps } from './types';
import { isEmpty } from 'lodash';
import { ErrorMessage, Field, useField } from 'formik';

interface Option {
  value: string | number;
  label?: string | React.ReactNode;
  activeColor?: string;
}

interface Props extends TTPInputProps {
  options: Option[];
  optionsWrapperClassName?: string;
}

const TTPRadioGroup = ({
  theme = '',
  name,
  label,
  options,
  wrapperClassName,
  optionsWrapperClassName,
  value,
  required,
  isHorizontal,
  labelClassName,
  children,
  onChange,
}: Props) => {
  return (
    <div
      className={classNames(styles.ttpField, wrapperClassName, styles[theme])}
    >
      <div className={classNames(isHorizontal && styles.hGroup)}>
        {label && (
          <div className={classNames(styles.inputLabel, labelClassName)}>
            {label}
            {required && !isEmpty(label.trim()) && (
              <span className={styles.required}>*</span>
            )}
          </div>
        )}
        <div>
          <div
            className={classNames(
              styles.optionsWrapper,
              optionsWrapperClassName,
            )}
          >
            {options.map((option) => (
              <label className={styles.option} key={option.value}>
                <input
                  name={name}
                  id={`input-${name}`}
                  type="radio"
                  checked={option.value === value}
                  value={option.value}
                  onChange={onChange}
                />
                <span
                  className={styles.radioButton}
                  style={
                    option.value === value && option.activeColor
                      ? { background: option.activeColor }
                      : undefined
                  }
                />
                {option.label}
              </label>
            ))}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

// export const TTPRadioGroupField = (props: Props) => <Field as={TTPRadioGroup} {...props} />;

export const TTPRadioGroupField = ({ ...props }: Props) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <TTPRadioGroup {...field} {...props}>
      <ErrorMessage
        name={props.name}
        component="p"
        className={props.errorsClassName}
      />
    </TTPRadioGroup>
  );
};

export default memo(TTPRadioGroup);
