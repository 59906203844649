import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './SubMenu.module.scss';
import _ from 'i18n';
import { URLS } from 'router';
import { openModal } from 'store/Modal/actions';
import { TTP_LOGO_OFFFCOURSE_INVERTED_URL } from 'config';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as MySpaceIcon } from 'assets/icons/channels.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/cil_education.svg';
import TTNavComponent from './TTNavComponent';
import { selectModal } from 'store/Modal/selectors';
import { RootState } from 'store/types';

const WatchSubMenuMobile = () => {
  const dispatch = useDispatch();
  const { isOpen: isModalOpen, modalId } = useSelector((state: RootState) =>
    selectModal(state),
  );

  const isSearchModalOpen = modalId === 'WATCH_SEARCH' && isModalOpen;

  const handleOpenModal = () => {
    dispatch(openModal('WATCH_SEARCH', { data: null }));
  };

  return (
    <div id="watch-sub-menu" className={s.watch_submenu}>
      <div className={s.top_nav}>
        <img src={TTP_LOGO_OFFFCOURSE_INVERTED_URL} alt="logo" />
      </div>
      <div className={s.bottom_nav}>
        <ul>
          <li className={s.nav_item}>
            <TTNavComponent
              label={_('Trainings')}
              url={URLS.watch.trainings}
              icon={() => (
                <EducationIcon width="20" height="20" fill="#C7E8FE" />
              )}
              exact={true}
              {...(isSearchModalOpen ? { isActive: () => false } : {})}
            />
          </li>
          <li className={s.nav_item}>
            <TTNavComponent
              label={_('my List')}
              url={URLS.watch.favorites}
              icon={() => <MySpaceIcon width="20" height="20" fill="#C7E8FE" />}
              exact={true}
              {...(isSearchModalOpen ? { isActive: () => false } : {})}
            />
          </li>
          <li className={s.nav_item}>
            <TTNavComponent
              label={_('Search')}
              icon={() => <SearchIcon width="20" height="20" fill="#C7E8FE" />}
              onClick={handleOpenModal}
              isActive={() => isSearchModalOpen}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
export default WatchSubMenuMobile;
