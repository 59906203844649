import React, {
  ReactElement,
  ReactHTMLElement,
  ReactNode,
  SVGProps,
} from 'react';
import { LayoutTheme } from 'Layout/services';

export type NotificationBarIconType =
  | 'checkMark'
  | 'clock'
  | 'loader'
  | 'alert';

export interface NotificationBarData {
  message: string;
  theme?: LayoutTheme;
  style?: React.CSSProperties;
  icon?: NotificationBarIconType;
  onClose?: () => void;
}

export interface NotificationBarRouteType {
  path: string | string[];
  exact?: boolean;
}

export interface NotificationBarState {
  isDisplayed: boolean;
  data: NotificationBarData;
  route?: {
    path: string | string[];
    exact?: boolean;
  };
}

export const RESET_NOTIFICATION_BAR = 'RESET_NOTIFICATION_BAR';
export const SET_SHOW_NOTIFICATION_BAR = 'SET_SHOW_NOTIFICATION_BAR';
export const SET_NOTIFICATION_BAR_DATA = 'SET_NOTIFICATION_BAR_DATA';

export interface ResetNotificationBarAction {
  type: typeof RESET_NOTIFICATION_BAR;
}

export interface SetShowNotificationBarAction {
  type: typeof SET_SHOW_NOTIFICATION_BAR;
  isDisplayed: boolean;
}

export interface SetNotificationBarDataAction {
  type: typeof SET_NOTIFICATION_BAR_DATA;
  data: NotificationBarData;
  route?: NotificationBarRouteType;
}

export type NotificationBarActionTypes =
  | ResetNotificationBarAction
  | SetShowNotificationBarAction
  | SetNotificationBarDataAction;
