import React from 'react';
import s from './TagChip.module.scss';
import cn from 'classnames';

type ColorType = 'slateGrey';

interface Props {
  label: string;
  color?: ColorType;
  isLowercase?: boolean;
  className?: string;
  isTransparent?: boolean;
}

const TagChip = ({
  label,
  color = 'slateGrey',
  isLowercase,
  className,
  isTransparent,
}: Props) => {
  return (
    <div
      className={cn(
        s.wrapper,
        s[color as string],
        isTransparent && s.transparent,
        className,
      )}
    >
      {isLowercase ? label.toLowerCase() : label.toUpperCase()}
    </div>
  );
};

export default TagChip;
