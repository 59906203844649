import React, { memo } from 'react';
import styles from './TTPForm.module.scss';
import classNames from 'classnames';
import { TTPTextareaProps } from './types';
import { isEmpty } from 'lodash';
import { ErrorMessage, useField } from 'formik';

interface Props extends TTPTextareaProps {}

export const TTPTextarea = ({
  customizeError,
  theme = '',
  name,
  label,
  className,
  wrapperClassName,
  required,
  value,
  isHorizontal,
  labelClassName,
  children,
  hasError,
  ...textareaProps
}: Props) => {
  return (
    <div
      className={classNames(styles.ttpField, wrapperClassName, styles[theme])}
    >
      <div className={classNames(isHorizontal && styles.hGroup)}>
        {label && (
          <label
            htmlFor={`textarea-${name}`}
            className={classNames(
              styles.inputLabel,
              labelClassName,
              'flex-container',
            )}
          >
            {label}
            {required && <span className={styles.required}>*</span>}
          </label>
        )}
        <div className={styles.inputWrapper}>
          <textarea
            name={name}
            className={classNames(
              styles.field,
              styles.textarea,
              hasError && styles.hasError,
              className,
              !isEmpty(value) ? styles.notEmpty : '',
            )}
            id={`textarea-${name}`}
            value={value}
            {...textareaProps}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export const TTPTextareaField = ({ ...props }: Props) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <TTPTextarea
      {...field}
      {...props}
      hasError={!isEmpty(meta.error) && meta.touched}
    >
      {props.customizeError ? (
        ''
      ) : (
        <ErrorMessage name={props.name} component="p" />
      )}
    </TTPTextarea>
  );
};

export default memo(TTPTextarea);
