import React from 'react';
import styles from './TTPDatePicker.module.scss';
import formStyles from '../TTPForm.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import _ from 'i18n';
import { TTPFormFieldProps } from '../types';
import { isEmpty } from 'lodash';
import { ErrorMessage, useField } from 'formik';
import { DatePicker, DatePickerProps } from 'antd';

interface TTPDatePickerProps extends TTPFormFieldProps {
  value: moment.Moment | null;
  onChange: (date: moment.Moment | null) => void;
  showIcon?: boolean;
}

type Props = TTPDatePickerProps & DatePickerProps;

const TTPDatePicker = ({
  theme = '',
  name,
  required,
  labelClassName,
  value,
  wrapperClassName,
  isHorizontal,
  label,
  showIcon,
  children,
  hasError,
  onChange,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(
        wrapperClassName,
        formStyles.ttpField,
        formStyles[theme],
      )}
    >
      <div className={classNames(isHorizontal && formStyles.hGroup)}>
        {label && (
          <label
            htmlFor={`input-${name}`}
            className={classNames(formStyles.inputLabel, labelClassName)}
          >
            {label}
            {required && <span className={formStyles.required}>*</span>}
          </label>
        )}
        <div className={formStyles.inputWrapper}>
          <div
            id="ttp-date-picker-field"
            className={classNames(
              formStyles.field,
              hasError && formStyles.hasError,
              !isEmpty(value) ? formStyles.notEmpty : '',
            )}
          >
            {showIcon && (
              <div className={classNames(styles.icon)}>
                <CalendarIcon
                  width="14"
                  height="14"
                  fill={!isEmpty(value) ? '#3c4e64' : '#6d7f92'}
                />
              </div>
            )}
            <DatePicker
              id={name}
              value={value}
              onChange={onChange}
              bordered={false}
              suffixIcon={null}
              className="flex-1"
              format="DD/MM/YYYY"
              {...props}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

interface PropsField extends Omit<Props, 'onChange' | 'value'> {
  onChange?: (date: moment.Moment | null) => void;
}

export const TTPDatePickerField = ({ onChange, ...props }: PropsField) => {
  const [{ onBlur, ...field }, meta, helpers] = useField(props.name);

  return (
    <TTPDatePicker
      {...field}
      {...props}
      onChange={
        onChange
          ? onChange
          : (date: moment.Moment | null) => helpers.setValue(date)
      }
      onBlur={() => {
        !meta.touched && helpers.setTouched(true);
      }}
    >
      <ErrorMessage name={props.name} component="p" />
    </TTPDatePicker>
  );
};

export default TTPDatePicker;
