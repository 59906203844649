export interface GuestProgramData {
  program: string[];
  busy: string[];
  replay_program: string[];
  allowed_slots: string[];
  trainingHours?: {
    freeForEvent: number;
    maxPerDay: number;
    [key: string]: number;
  };
}

export interface GuestProgramState {
  fetching: boolean;
  fetched: boolean;
  data?: GuestProgramData;
  error: any;
  nbResult: number;
}

export const RESET_GUEST_PROGRAM = 'RESET_GUEST_PROGRAM';
export const FETCH_GUEST_PROGRAM = 'FETCH_GUEST_PROGRAM';
export const FETCH_GUEST_PROGRAM_PENDING = 'FETCH_GUEST_PROGRAM_PENDING';
export const FETCH_GUEST_PROGRAM_FULFILLED = 'FETCH_GUEST_PROGRAM_FULFILLED';
export const FETCH_GUEST_PROGRAM_REJECTED = 'FETCH_GUEST_PROGRAM_REJECTED';

export const ADD_SLOT_TO_PROGRAM = 'ADD_SLOT_TO_PROGRAM';
export const ADD_SLOT_TO_PROGRAM_PENDING = 'ADD_SLOT_TO_PROGRAM_PENDING';
export const ADD_SLOT_TO_PROGRAM_FULFILLED = 'ADD_SLOT_TO_PROGRAM_FULFILLED';
export const ADD_SLOT_TO_PROGRAM_REJECTED = 'ADD_SLOT_TO_PROGRAM_REJECTED';

export const REMOVE_SLOT_FROM_PROGRAM = 'REMOVE_SLOT_FROM_PROGRAM';
export const REMOVE_SLOT_FROM_PROGRAM_PENDING =
  'REMOVE_SLOT_FROM_PROGRAM_PENDING';
export const REMOVE_SLOT_FROM_PROGRAM_FULFILLED =
  'REMOVE_SLOT_FROM_PROGRAM_FULFILLED';
export const REMOVE_SLOT_FROM_PROGRAM_REJECTED =
  'REMOVE_SLOT_FROM_PROGRAM_REJECTED';

export interface ResetGuestProgramAction {
  type: typeof RESET_GUEST_PROGRAM;
}

export interface FetchGuestProgramAction {
  type:
    | typeof FETCH_GUEST_PROGRAM
    | typeof FETCH_GUEST_PROGRAM_PENDING
    | typeof FETCH_GUEST_PROGRAM_FULFILLED
    | typeof FETCH_GUEST_PROGRAM_REJECTED;
  payload: any;
}

export interface AddSlotToProgramAction {
  type:
    | typeof ADD_SLOT_TO_PROGRAM
    | typeof ADD_SLOT_TO_PROGRAM_PENDING
    | typeof ADD_SLOT_TO_PROGRAM_FULFILLED
    | typeof ADD_SLOT_TO_PROGRAM_REJECTED;
  payload: any;
}

export interface RemoveSlotFromProgramAction {
  type:
    | typeof REMOVE_SLOT_FROM_PROGRAM
    | typeof REMOVE_SLOT_FROM_PROGRAM_PENDING
    | typeof REMOVE_SLOT_FROM_PROGRAM_FULFILLED
    | typeof REMOVE_SLOT_FROM_PROGRAM_REJECTED;
  payload: any;
}

export type GuestProgramActionTypes =
  | FetchGuestProgramAction
  | AddSlotToProgramAction
  | RemoveSlotFromProgramAction
  | ResetGuestProgramAction;
