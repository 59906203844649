import React, { CSSProperties, useState } from 'react';
import { ModalState } from 'store/Modal/types';
import styles from './ModalFreeEventRegistration.module.scss';
import classnames from 'classnames';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { Event } from 'store/Events/types';
import { parseJson } from 'utils';
import { Question as QuestionType } from 'interfaces/Question';
import { Checkbox, Form, Input, Radio, Space } from 'antd';
import t from 'i18n';
import { Guest } from 'store/Guests/types';
import { useDispatch } from 'react-redux';
import { acceptRegistration } from 'store/Guests/thunks';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import { getInitialAnswer } from './services';

type ModalFreeEventRegistration = {
  event: Event;
};

type QuestionFormType = { answer: string };

interface Props {
  modal: ModalState<ModalFreeEventRegistration>;
  onCloseModal: () => void;
  guest: Guest;
}

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: '15px',
    top: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    width: '566px',
    height: 'fit-content',
  },
};

export default function ModalFreeEventRegistration({
  modal,
  onCloseModal,
  guest,
}: Props) {
  const {
    data: { event },
  } = modal;

  const question = parseJson(event.question) as QuestionType;

  const [answer, setAnswer] = useState(getInitialAnswer(question).toString());
  const [isProcessing, setIsProcessing] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onAnswerChange = ({ answer }: QuestionFormType) => {
    if (Array.isArray(answer)) {
      setAnswer(answer?.toString());
    } else {
      setAnswer(answer ?? '');
    }
  };

  const handleSubmit = async () => {
    if (isProcessing) {
      return;
    }
    if (!(await form.validateFields())) {
      return;
    }

    setIsProcessing(true);

    dispatch(acceptRegistration(event.id, answer) as any)
      .then((data: Guest) => {
        toast.success(<strong>{t('registeredForEventSuccessfully')}</strong>, {
          autoClose: 10000,
        });
      })
      .finally(() => {
        setIsProcessing(false);
        onCloseModal();
      });
  };

  const handleCancel = () => {
    if (isProcessing) {
      return;
    }
    onCloseModal();
  };

  const renderField = () => {
    if (question?.type === 'select') {
      const options = question?.options;
      return (
        <Radio.Group>
          <Space direction="vertical">
            {options.map((option) => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    }

    if (question?.type === 'multiSelect') {
      const options = question?.options;
      return (
        <Checkbox.Group>
          <Space direction="vertical">
            {options.map((option) => (
              <Checkbox key={option} value={option}>
                {option}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      );
    }

    return <Input placeholder={t('Please answer this question')} />;
  };

  const renderForm = () => (
    <Form
      initialValues={{ answer: getInitialAnswer(question) }}
      onFinish={handleSubmit}
      autoComplete="off"
      onValuesChange={onAnswerChange}
      layout="vertical"
      form={form}
      className={styles.form}
      requiredMark={false}
    >
      <Form.Item<QuestionFormType>
        label={question?.questionText}
        name="answer"
        rules={[
          {
            required: true,
            message: t('Please specify your answer'),
          },
        ]}
      >
        {renderField()}
      </Form.Item>
    </Form>
  );

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={false}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div className={classnames(styles.eventFreeRegistrationModal)}>
        <CloseModal onClose={onCloseModal} />
        <div>
          <h3>{t('inscription.further_information')}</h3>
          {renderForm()}
        </div>
        <div className={styles.actions}>
          <button
            onClick={handleCancel}
            className={classnames(styles.action, styles.cancel)}
          >
            {t('Cancel')}
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className={classnames(
              styles.action,
              isProcessing && styles.disabled,
            )}
            disabled={isProcessing}
          >
            {isProcessing && <ClipLoader size={14} color="#fff" loading />}
            {t('Validate and register')}
          </button>
        </div>
      </div>
    </Modal>
  );
}
