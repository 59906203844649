import {
  Operator,
  multiValueOperators,
  MultiValueOperator,
  SingleValueOperator,
  singleValueOperators,
} from './types';
import { Filter, MultiValueFilter } from '.';
import { castArray } from 'lodash';
import SingleValueFilter from './SingleValueFilter';

export default class FilterFactory {
  static createFilter(
    property: string,
    operator: Operator,
    value: any,
  ): Filter | null {
    if (multiValueOperators.includes(operator)) {
      return new MultiValueFilter(
        property,
        operator as MultiValueOperator,
        castArray(value),
      );
    } else if (singleValueOperators.includes(operator)) {
      return new SingleValueFilter(
        property,
        operator as SingleValueOperator,
        value,
      );
    }

    return null;
  }
}
