import {
  FETCH_PARTNER_LEAD_PENDING,
  FETCH_PARTNER_LEAD_FULFILLED,
  FETCH_PARTNER_LEAD_REJECTED,
  PartnerLeadState,
  PartnerLeadActionTypes,
  PartnerLead,
  UPDATE_PARTNER_LEAD_PENDING,
  UPDATE_PARTNER_LEAD_FULFILLED,
  UPDATE_PARTNER_LEAD_REJECTED,
  PUSH_PARTNER_LEAD,
  FETCH_USER_PARTNERS_LEAD_PENDING,
  FETCH_USER_PARTNERS_LEAD_FULFILLED,
  FETCH_USER_PARTNERS_LEAD_REJECTED,
  CREATE_PARTNER_LEAD_FULFILLED,
  DELETE_PARTNER_LEAD_FULFILLED,
  LEAD_TYPE,
  REMOVE_PARTNER_LEAD,
  USER_JOINED,
  CLEAR_USER_JOINED,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  updating: false,
  items: [],
  error: null,
  nbResult: 0,
};

export const partnerLeads = (
  state: PartnerLeadState = initialState,
  action: PartnerLeadActionTypes,
) => {
  switch (action.type) {
    case FETCH_PARTNER_LEAD_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_PARTNER_LEAD_FULFILLED: {
      const { data, nbResult, isSessionLeads } = action.payload;

      let items = data;
      if (isSessionLeads) {
        items = (data || []).map(({ id, participated, user }: any) => ({
          id,
          user: { ...(user || {}), email: user?.mainEmail },
          type: participated
            ? LEAD_TYPE.SESSION_PARTICIPANT
            : LEAD_TYPE.SESSION_NON_PARTICIPANT,
        }));
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items,
        nbResult: nbResult,
      };
    }
    case FETCH_PARTNER_LEAD_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    case UPDATE_PARTNER_LEAD_PENDING: {
      return {
        ...state,
        updating: true,
      };
    }

    case UPDATE_PARTNER_LEAD_FULFILLED: {
      const { id, status, type } = action.payload;

      const newItems: PartnerLead[] = state.items.map((lead) => {
        if (lead.id == id) {
          return { ...lead, status, type };
        }
        return lead;
      });

      return {
        ...state,
        updating: false,
        items: newItems,
      };
    }

    case UPDATE_PARTNER_LEAD_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updating: false,
        error,
      };
    }

    case PUSH_PARTNER_LEAD: {
      const lead: PartnerLead = action.lead;

      let isNew = true;
      const newItems: PartnerLead[] = state.items.map((item) => {
        if (item.id == lead.id) {
          isNew = false;
          return lead;
        }
        return item;
      });

      if (isNew) {
        newItems.push(lead);
      }
      return {
        ...state,
        items: newItems,
      };
    }

    case REMOVE_PARTNER_LEAD: {
      const leadId = action.leadId;

      let isExist = false;
      const newItems: PartnerLead[] = state.items.filter((item) => {
        if (item.id == leadId) {
          isExist = true;
        }
        return item.id !== leadId;
      });

      if (!isExist) {
        return state;
      }

      return {
        ...state,
        items: newItems,
      };
    }

    default:
      return state;
  }
};

export const userLeads = (
  state: PartnerLeadState = initialState,
  action: PartnerLeadActionTypes,
) => {
  switch (action.type) {
    case CREATE_PARTNER_LEAD_FULFILLED: {
      const lead = action.payload;
      const newItems: PartnerLead[] = [...state.items, lead];

      return {
        ...state,
        items: newItems,
      };
    }

    case DELETE_PARTNER_LEAD_FULFILLED: {
      const deletedId = action.payload;
      let isExist = false;

      const newItems: PartnerLead[] = state.items.filter((lead) => {
        if (lead.id == deletedId) {
          isExist = true;
        }
        return lead.id !== deletedId;
      });

      if (!isExist) {
        return state;
      }

      return {
        ...state,
        items: newItems,
      };
    }

    case FETCH_USER_PARTNERS_LEAD_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case FETCH_USER_PARTNERS_LEAD_FULFILLED: {
      const { data, nbResult } = action.payload;

      return {
        ...state,
        fetching: false,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }

    case FETCH_USER_PARTNERS_LEAD_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }

    default:
      return state;
  }
};

export const daily = (
  state: PartnerLeadState = initialState,
  action: PartnerLeadActionTypes,
) => {
  switch (action.type) {
    case USER_JOINED: {
      const data = action.payload;
      return {
        ...state,
        fetching: false,
        error: null,
        items: JSON.parse(data),
      };
    }
    case CLEAR_USER_JOINED: {
      return {
        ...state,
        items: '',
      };
    }

    default:
      return state;
  }
};
