import { connect } from 'react-redux';
import { RootState } from 'store/types';
import StepPlaceAttribution from './StepPlaceAttribution';
import { cancelPlace, fetchPacks, savePlace } from 'store/Packs/thunks';
import { getEmployeePack } from 'store/Collaborators/thunks';
import { closeModal } from 'store/Modal/actions';
import { fetchPackSummary } from 'store/Packs/thunks';
import { sendGuestConfirmationSubscribed } from 'store/Guests/thunks';

const mapStateToProps = (state: RootState) => ({
  collaborators: state.collaborators.items[0],
  packs: state.packs.items[0],
  saving: state.packs.saving,
  canceling: state.packs.canceling,
  collaboratorsFetching: state.collaborators.fetching,
  packFetching: state.packs.fetching,
  eventId: state.event.event.items[0].id,
  userId: state.auth.user?.id,
});

export default connect(mapStateToProps, {
  fetchPacks,
  getEmployeePack,
  savePlace,
  cancelPlace,
  closeModal,
  fetchPackSummary,
  sendGuestConfirmationSubscribed,
})(StepPlaceAttribution);
