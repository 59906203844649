import { connect } from 'react-redux';
import { choosePack, setPackOrderFiduciary } from 'store/Packs/thunks';
import { RootState } from 'store/types';
import StepPack from './StepPack';
import { closeModal } from 'store/Modal/actions';

const mapStateToProps = (state: RootState) => ({
  isSaving: state.packs.saving,
  packs: state.packs.packList[0],
  packFetching: state.packs.fetching,
  nbResult: state.packs.nbResult,
  fetched: state.packs.fetched,
  language: state.params.lng,
});

export default connect(mapStateToProps, {
  choosePack,
  setPackOrderFiduciary,
  closeModal,
})(StepPack);
