import axios from 'axios';
import { TTP_API_URL } from 'config';
import { hydrateFormData } from '../services';
import { Filter, SingleValueFilter } from 'services/Filter';
import { TagOption } from './types';

export const getTags = ({
  inputTag,
  language,
  token,
  filters = [],
}: {
  inputTag: string;
  language: string;
  token: string;
  filters?: Filter[];
}) => {
  const requestUrl = `${TTP_API_URL}/blog/tag`;

  let filter;

  switch (language) {
    case 'fr':
      filter = [
        new SingleValueFilter('nameFr', 'like', inputTag),
        ...filters,
      ].map((filter) => filter.serialize());
      break;
    case 'nl':
      filter = [
        new SingleValueFilter('nameNl', 'like', inputTag),
        ...filters,
      ].map((filter) => filter.serialize());
      break;
    case 'en':
      filter = [
        new SingleValueFilter('nameEn', 'like', inputTag),
        ...filters,
      ].map((filter) => filter.serialize());
      break;
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
    },
  });
};

export const getEventWithTags = ({
  eventId,
  token,
  filters = [],
}: {
  eventId: number;
  token: string;
  filters?: any[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/event`;

  const filter = [{ property: 'id', value: 0, operator: 'eq' }];
  filter[0].value = eventId;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: 'tag,id',
      filter: JSON.stringify(filter),
    },
  });
};

export const updateEventTags = ({
  eventId,
  updatedEventTags,
  token,
  filters = [],
}: {
  eventId: number;
  updatedEventTags: TagOption[];
  token: string;
  filters?: any[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/event`;

  const data = {
    id: eventId,
    tag: JSON.stringify(updatedEventTags),
  };

  const formData = new FormData();
  formData.append('access_token', token);

  hydrateFormData(formData, data);

  return axios.post(requestUrl, formData);
};
