export interface channelInfo {
  isAuthorsSet: boolean;
}

export const SET_CHANNEL_INFO = 'SET_CHANNEL_INFO';
export const RESET_CHANNEL_INFO = 'RESET_CHANNEL_INFO';

export interface SetChannelInfoAction {
  type: typeof SET_CHANNEL_INFO;
  isAuthorsSet: boolean;
}

export interface ResetChannelInfoAction {
  type: typeof RESET_CHANNEL_INFO;
}

export type channelInfoActionTypes =
  | ResetChannelInfoAction
  | SetChannelInfoAction;
