import React, { useEffect, useMemo, useRef, useState } from 'react';
import s from './StepAddress.module.scss';
import cn from 'classnames';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsControl from '../../../Common/StepsControl';
import { Event } from 'store/Events/types';
import { Language } from 'store/types';
import {
  Coupon,
  Guest,
  GuestAddress as GuestAddressType,
} from 'store/Guests/types';
import { useSize } from 'hooks/useSize';
import Summary from '../../Summary';
import { getPrivacyTermsUrl, onError, parseBoolean } from 'utils';
import { GuestData, REGISTRATION_FULL_STEPS, StepProps } from '../../services';
import { EventOption } from 'interfaces/EventOption';
import {
  ErrorMessage,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikTouched,
} from 'formik';
import * as yup from 'yup';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'components/Common/LocalLoader/LocalLoader';
import { Step2Data } from 'store/Guests/api';
import GuestAddress from 'components/Registration/GuestAddress';
import { debounce, isEmpty } from 'lodash';
import { TTPCheckBoxField } from 'components/Common/TTPForm/TTPCheckBox';
import { User } from 'store/Auth/types';
import { URLS } from 'router';
import { EventPlan } from 'interfaces/EventPlan';
import ShippingAddress from 'components/Registration/GuestAddress/ShippingAddress';
import { Address as AddressInterface } from 'interfaces/Address';
import { Address as InvoicingAddress } from 'store/Guests/Invoicing/types';
import { CURRENT_CONGRESS_ID } from 'config';

interface Props extends Omit<StepProps, 'nextStep'> {
  event: Event;
  language: Language;
  guest: Guest;
  selectedCoupons: Coupon[];
  selectedOptions: EventOption[];
  shippingAddresses: AddressInterface[];
  selectedPlan?: EventPlan;
  data: GuestData;
  totalPrice: number;
  user?: User | null;
  invoicingAddresses: InvoicingAddress[];
  saveStep2: (eventId: string, userId: string, data: Partial<Step2Data>) => any;
  handleChangeData: (data: Partial<GuestData>) => void;
  goToStep: (step: REGISTRATION_FULL_STEPS) => void;
  closeModal: () => void;
  getGuestData: () => void;
}

interface InvoicingFormValues extends GuestAddressType {
  termsOfSales: '1' | '0';
  deliveryAddressId?: number;
  privacyTerms: '1' | '0';
}

const StepAddress = ({
  user,
  event,
  language,
  guest,
  selectedCoupons,
  selectedOptions,
  data,
  selectedPlan,
  previousStep,
  totalPrice,
  shippingAddresses,
  invoicingAddresses,
  goToStep,
  saveStep2,
  closeModal,
  handleChangeData,
  getGuestData,
}: Props) => {
  const summaryRef = useRef<Summary>(null);
  const {
    measuredRef,
    size: { height },
  } = useSize<HTMLFormElement>();
  const hideInvoicingData = totalPrice <= 0; // hide when its free !!
  const scrollDownSummary = debounce(_scrollDownSummary, 300);
  const showShippingAddress = selectedOptions.some(
    (op) => +op.isDeliverable === 1,
  ); // show when some options are deliverable !
  const showPrivacyTerms = event.id == CURRENT_CONGRESS_ID;
  const privacyTermsLink = getPrivacyTermsUrl(language);

  const getInitialValues = (
    {
      billingCompanyNumber,
      billingOrganization,
      billingStreet,
      billingPostalCode,
      billingSubjectToVAT,
      billingSignature,
      termsOfSales,
      billingOrderNumber,
      billingAddress2,
      deliveryAddressId,
      billingCountry,
    }: GuestData,
    shippingAddresses: AddressInterface[],
  ): InvoicingFormValues => {
    return {
      billingCompanyNumber,
      billingOrganization,
      billingStreet,
      billingPostalCode,
      billingSubjectToVAT,
      billingSignature,
      billingAddress2,
      billingOrderNumber,
      termsOfSales,
      deliveryAddressId:
        isEmpty(deliveryAddressId) ||
        !shippingAddresses.some(({ id }) => id == deliveryAddressId)
          ? shippingAddresses[0]?.id ?? ''
          : deliveryAddressId,
      billingCountry,
      privacyTerms: '0',
    };
  };

  const [initialValues, setInitialValues] = useState<InvoicingFormValues>(
    getInitialValues(data, shippingAddresses),
  );

  const [addressFormOpened, toggleAddressForm] = useState(
    isEmpty(initialValues.billingSignature),
  );

  const [shippingAddressFormOpened, toggleShippingAddressForm] = useState(
    isEmpty(shippingAddresses),
  );

  // if (!plansAndOptions) {
  //   return <div>TODO !</div>;
  // }

  function _scrollDownSummary() {
    setTimeout(() => summaryRef.current?.scrollDown(), 0);
  }

  // ------------- FORMIK ---------- //

  const isRequiredSchema = (
    schema: any,
    required: boolean,
    message?: string,
    strict?: boolean,
  ): any => {
    if (!required) {
      if (strict) {
        return null;
      }
      return schema;
    }

    return schema.required(message);
  };

  const validationSchema = yup.object().shape({
    termsOfSales: yup
      .string()
      .oneOf(['1'], t('You must accept the terms and conditions'))
      .required(t('You must accept the terms and conditions')),
    billingCompanyNumber: yup
      .string()
      .label(t('inscription.billingCompanyNumber'))
      .when('billingSubjectToVAT', {
        is: (billingSubjectToVAT) => {
          return !hideInvoicingData && parseBoolean(billingSubjectToVAT);
        },
        then: yup.string().required(t('yup.required')),
      }),
    billingOrganization: isRequiredSchema(
      yup.string().label(t('inscription.billingOrganization')),
      !hideInvoicingData,
      t('yup.required'),
    ),
    billingStreet: isRequiredSchema(
      yup.string().label(t('inscription.billingStreet')),
      !hideInvoicingData,
      t('yup.required'),
    ),
    billingPostalCode: isRequiredSchema(
      yup.string().label(t('inscription.billingPostalCode')), // Ex: '1360 Perwez',
      !hideInvoicingData,
      t('yup.required'),
    ),
    billingSubjectToVAT: isRequiredSchema(
      yup
        .string()
        .oneOf(['1', '0'])
        .label(t('inscription.billingSubjectToVAT')),
      !hideInvoicingData,
      t('yup.required'),
    ),
    privacyTerms: isRequiredSchema(
      yup.string().oneOf(['1'], t('You must accept the privacy terms')),
      showPrivacyTerms,
      t('You must accept the privacy terms'),
      true,
    ),
  });

  const handleClickInvoice = (
    address: GuestAddressType | null,
    values: InvoicingFormValues,
    errors: FormikErrors<InvoicingFormValues>,
    touched: FormikTouched<InvoicingFormValues>,
    resetForm: FormikHelpers<InvoicingFormValues>['resetForm'],
    setValues: FormikHelpers<InvoicingFormValues>['setValues'],
    reset?: boolean,
  ) => {
    resetForm({
      errors: {
        ...errors,
        billingCompanyNumber: undefined,
        billingOrganization: undefined,
        billingStreet: undefined,
        billingPostalCode: undefined,
        billingSubjectToVAT: undefined,
        billingSignature: undefined,
      },
      touched: {
        ...touched,
        billingCompanyNumber: undefined,
        billingOrganization: undefined,
        billingStreet: undefined,
        billingPostalCode: undefined,
        billingSubjectToVAT: undefined,
        billingSignature: undefined,
      },
    });

    if (reset) {
      setValues({
        ...values,
        billingCompanyNumber: '',
        billingOrganization: '',
        billingStreet: '',
        billingPostalCode: '',
        billingSubjectToVAT: '0',
        billingSignature: '',
      });
    } else {
      setValues({
        ...values,
        billingCompanyNumber: address
          ? address.billingCompanyNumber
          : initialValues.billingCompanyNumber,
        billingOrganization: address
          ? address.billingOrganization
          : initialValues.billingOrganization,
        billingStreet: address
          ? address.billingStreet
          : initialValues.billingStreet,
        billingPostalCode: address
          ? address.billingPostalCode
          : initialValues.billingPostalCode,
        billingSubjectToVAT: address
          ? address.billingSubjectToVAT == '1'
            ? '1'
            : '0'
          : initialValues.billingSubjectToVAT,
        billingSignature: address
          ? address.billingSignature
          : initialValues.billingSignature,
      });
    }
  };

  const handleSubmit = (
    values: InvoicingFormValues,
    formikHelpers: FormikHelpers<InvoicingFormValues>,
  ): void | Promise<any> => {
    let newData: Partial<Step2Data> = {
      plan: data.plan,
      options: JSON.stringify(data.options ?? []),
      days: selectedPlan?.accessibleDays?.split(','),
      coupon: selectedCoupons[0]?.code ?? '',
      termsOfSales: values.termsOfSales,
      deliveryAddressId: values.deliveryAddressId,
    };

    if (!hideInvoicingData) {
      newData = {
        ...newData,
        billingCompanyNumber: values.billingCompanyNumber,
        billingOrganization: values.billingOrganization,
        billingStreet: values.billingStreet,
        billingPostalCode: values.billingPostalCode,
        billingSubjectToVAT: values.billingSubjectToVAT,
      };
    }

    return saveStep2(String(event.id), String(user?.id ?? 0), {
      ...newData,
      cycle: 0,
    })
      .then((res: any) => {
        const paymentUrl = res?.value?.data?.paymentUrl;
        if (!isEmpty(paymentUrl) && res?.value?.data?.step == 4) {
          goToStep(REGISTRATION_FULL_STEPS.PAYMENT);
        } else {
          const data: Guest | undefined = res.value.data;
          if (!data || +data.step !== 5) {
            goToStep(REGISTRATION_FULL_STEPS.PAYMENT);
            onError();
          } else {
            // Nothing to be paid ...
            closeModal();
          }
        }
      })
      .catch(onError);
  };

  // ------------- RENDER ---------- //

  const renderAddressError = (
    errors: FormikErrors<InvoicingFormValues>,
    touched: FormikTouched<InvoicingFormValues>,
  ) => {
    // if (addressFormOpened) {
    //   return null;
    // }

    const addressTouched =
      touched.billingCompanyNumber ||
      touched.billingOrganization ||
      touched.billingStreet ||
      touched.billingPostalCode;

    const withErrors =
      !isEmpty(errors.billingCompanyNumber) ||
      !isEmpty(errors.billingOrganization) ||
      !isEmpty(errors.billingStreet) ||
      !isEmpty(errors.billingPostalCode);

    if (addressTouched && withErrors) {
      return (
        <div className="yup-error">
          <p>{t('yup.required').replace('${path}', t('Billing data'))}</p>
        </div>
      );
    }
  };

  /**
   * 70: stepControlHeight
   * 32: padding
   * 16: margin
   */
  const summaryHeight = height - 70 - 32 - 16;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValidating,
        resetForm,
        setValues,
        setFieldError,
        setFieldValue,
      }) => {
        scrollDownSummary();

        return (
          <LocalLoaderWrapper className={'flex-container flex-1'}>
            <Form ref={measuredRef} className={s.wrapper}>
              <div className={cn(s.content, 'flex-container')}>
                <div className="flex-1 p-s">
                  <div className="grid-x">
                    {!hideInvoicingData && (
                      <div
                        className={
                          showShippingAddress
                            ? cn('small-6 p-r-m vseparator')
                            : 'small-12'
                        }
                      >
                        <h3 className="m-b-s">{t('Billing address')} :</h3>
                        <div style={{ marginLeft: '-0.5rem' }}>
                          {renderAddressError(errors, touched)}
                        </div>
                        <div className={s.invoicing}>
                          <GuestAddress
                            invoicings={invoicingAddresses}
                            billingSignature={values.billingSignature}
                            defaultOpenForm={addressFormOpened}
                            onOpenForm={toggleAddressForm}
                            getGuestData={getGuestData}
                            onSelectAddress={(
                              address: GuestAddressType | null,
                              reset,
                            ) => {
                              handleClickInvoice(
                                address,
                                values,
                                errors,
                                touched,
                                resetForm,
                                setValues,
                                reset,
                              );
                            }}
                            postDeleteAddress={(invoice: GuestAddressType) => {
                              // For now we dont need this function, since useEffect do all initialization if the guest has changed
                            }}
                            titleClassName={s.invoicingTitle}
                            title={
                              addressFormOpened
                                ? t('New address')
                                : t('Existing addresses')
                            }
                            horizontalInputs={false}
                          />
                          {/* <div>{renderAddressError(errors, touched)}</div> */}
                        </div>
                        {/* <div>
                          <pre>{JSON.stringify(values)}</pre>
                          <pre>{JSON.stringify(errors)}</pre>
                          <pre>{JSON.stringify(touched)}</pre>
                        </div> */}
                      </div>
                    )}
                    {showShippingAddress && (
                      <div className="small-6 p-x-m">
                        <h3 className="m-b-s">{t('Shipping address')} :</h3>
                        <div className={s.invoicing}>
                          <ShippingAddress
                            addresses={shippingAddresses}
                            selectedAddress={shippingAddresses.find(
                              ({ id }) => id == values.deliveryAddressId,
                            )}
                            defaultOpenForm={shippingAddressFormOpened}
                            onOpenForm={toggleShippingAddressForm}
                            onSelectAddress={(addressId: number | null) => {
                              setFieldError('deliveryAddressId', undefined);
                              setFieldValue('deliveryAddressId', addressId);
                            }}
                            titleClassName={s.invoicingTitle}
                            title={
                              shippingAddressFormOpened
                                ? t('New address')
                                : t('Existing addresses')
                            }
                            horizontalInputs={false}
                          />
                          <div>
                            <div className="yup-error">
                              <ErrorMessage
                                name="deliveryAddressId"
                                component="p"
                                className="text-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: '360px',
                    position: 'relative',
                    marginRight: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  <Summary
                    ref={summaryRef}
                    style={{
                      maxHeight: `${summaryHeight}px`,
                      minHeight: summaryHeight > 500 ? 500 : summaryHeight,
                    }}
                    selectedCoupons={selectedCoupons}
                    guest={guest}
                    plan={selectedPlan}
                    options={selectedOptions}
                    language={language}
                    goToStep={goToStep}
                    step={REGISTRATION_FULL_STEPS.ADDRESS}
                    invoiceAddress={{
                      billingCompanyNumber: values.billingCompanyNumber,
                      billingOrganization: values.billingOrganization,
                      billingStreet: values.billingStreet,
                      billingPostalCode: values.billingPostalCode,
                      billingSubjectToVAT: values.billingSubjectToVAT,
                      billingSignature: values.billingSignature,
                    }}
                    shippingAddress={
                      showShippingAddress
                        ? shippingAddresses.find(
                            ({ id }) => id == values.deliveryAddressId,
                          )
                        : undefined
                    }
                  />
                </div>
              </div>
              <StepsControl className={s.actions}>
                <div className={cn('p-s p-x-l flex-container')}>
                  <GoBack
                    onClick={() => goToStep(previousStep)}
                    className="m-r-s"
                  />

                  <div className="flex-container m-l-auto align-middle">
                    {showPrivacyTerms && (
                      <div className="flex-container flex-dir-column">
                        <TTPCheckBoxField
                          className="m-r-s"
                          name="privacyTerms"
                          value="1"
                          label={
                            <span className={s.acceptConditions}>
                              {t('inscription.privacy_terms_p1')}&nbsp;
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={privacyTermsLink}
                                style={{
                                  color: '#18A0FB',
                                  textDecoration: 'underline',
                                }}
                              >
                                {t('inscription.privacy_terms_p2')}
                              </a>
                            </span>
                          }
                        />
                        <div className="yup-error">
                          <ErrorMessage
                            name="privacyTerms"
                            component="p"
                            className="text-center"
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex-container flex-dir-column">
                      <TTPCheckBoxField
                        className="m-r-s"
                        name="termsOfSales"
                        value="1"
                        label={
                          <span className={s.acceptConditions}>
                            {t('inscription.terms_p1')}&nbsp;
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={URLS.terms.event}
                              style={{
                                color: '#18A0FB',
                                textDecoration: 'underline',
                              }}
                            >
                              {t('inscription.terms_p2')}
                            </a>
                          </span>
                        }
                      />
                      <div className="yup-error">
                        <ErrorMessage
                          name="termsOfSales"
                          component="p"
                          className="text-center"
                        />
                      </div>
                    </div>
                    <NextStep
                      className="m-l-auto"
                      text={+guest.step > 2 ? t('Pay') : t('Next step')}
                      // onClick={() => goToStep(REGISTRATION_FULL_STEPS.ADDRESS)}
                    />
                  </div>
                </div>
              </StepsControl>
            </Form>
            <LocalLoader
              loading={isSubmitting || isValidating}
              style={{ zIndex: 100 }}
            />
          </LocalLoaderWrapper>
        );
      }}
    </Formik>
  );
};

export default StepAddress;
