import React from 'react';
import s from './NoIE.module.scss';
import t from 'i18n';

interface Props {
  handleClose: () => void;
  visible: boolean;
  innerRef?: React.Ref<any>;
}

export const NoIE = ({ handleClose, visible, innerRef }: Props) => {
  if (!visible) {
    return null;
  }

  const renderMessage = () => {
    const [firstPart, secondPart] = t('userChromeDetails').split(
      '{{google Chrome}}',
    );

    return (
      <>
        {firstPart}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/chrome"
        >
          google Chrome
        </a>
        {secondPart}
      </>
    );
  };

  return (
    <div className={s.wrapper} role="alert" ref={innerRef}>
      <button
        type="button"
        className={s.close_button}
        data-dismiss="alert"
        aria-label="Close"
        onClick={handleClose}
      >
        <span aria-hidden="true">×</span>
      </button>
      <div>
        <p className="text-center">
          <strong>{t('Warning')} !</strong>{' '}
          {t('This site is not optimized for your browser')}
        </p>
        <p className="text-center">{renderMessage()}</p>
      </div>
    </div>
  );
};
