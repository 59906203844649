import {
  FlashMessageActionTypes,
  FlashMessageState,
  DISPLAY_FLASH_MESSAGE,
  HIDE_FLASH_MESSAGE,
} from './types';

const initialState = {
  openFlash: false,
  message: '',
  status: 'SUCESS',
};

const reducer = (
  state: FlashMessageState = initialState,
  action: FlashMessageActionTypes,
) => {
  switch (action.type) {
    case DISPLAY_FLASH_MESSAGE: {
      const { status, message } = action;
      return { ...state, status, message, openFlash: true };
    }
    case HIDE_FLASH_MESSAGE: {
      return { ...state, openFlash: false };
    }
    default:
      return state;
  }
};

export default reducer;
