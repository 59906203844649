import { connect } from 'react-redux';
import { RootState } from 'store/types';
import StepOrganizationData from './StepOrganizationData';
import {
  saveOrganizationStep,
  fetchCurrentUserRoles,
  acceptOrganizationTerms,
} from 'store/Auth/thunks';
import { setPackOrderFiduciary } from 'store/Packs/thunks';

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps, {
  fetchCurrentUserRoles,
  saveOrganizationStep,
  acceptOrganizationTerms,
  setPackOrderFiduciary,
})(StepOrganizationData);
