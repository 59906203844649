import { FormikValues } from 'formik';

export interface FormState {
  [key: string]: FormValues;
}
type FormValues = FormikValues;

export const SYNC_FORM_VALUES = 'SYNC_FORM_VALUES';
export const RESET_FORM_VALUES = 'RESET_FORM_VALUES';

export interface SyncFormValuesAction {
  type: typeof SYNC_FORM_VALUES;
  name: string;
  values: FormValues;
}

export interface ResetFormValuesAction {
  type: typeof RESET_FORM_VALUES;
  name: string;
}

export type FormActionTypes = SyncFormValuesAction | ResetFormValuesAction;
