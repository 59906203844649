import React, { useEffect, useState } from 'react';
import styles from './LanguageCircles.module.scss';
import t from 'i18n';
import classNames from 'classnames';
import { LanguageOptionsType } from 'store/Params/Language';
import { Language } from 'store/types';

interface Props {
  defaultLng?: Language;
  size?: number | string;
  bgColor?: string;
  theme?: 'blue' | 'orange' | 'purple' | 'darkBlue' | 'greenTeal';
  asFullText?: boolean;
  listClassName?: string;
  handleLanguageClick: (value: LanguageOptionsType) => void;
}

const LanguageCircles = ({
  defaultLng,
  size,
  theme = 'blue',
  bgColor,
  asFullText,
  listClassName,
  handleLanguageClick,
}: Props) => {
  const [languageOptions, setLanguageOptions] = useState<LanguageOptionsType>(
    {} as LanguageOptionsType,
  );

  useEffect(() => {
    if (defaultLng) {
      setLanguageOptions({
        ...languageOptions,
        [defaultLng]: true,
      });
    }
  }, []);

  useEffect(() => {
    handleLanguageClick(languageOptions);
  }, [languageOptions]);

  const onLanguageClick = (lng: Language) => {
    setLanguageOptions({
      ...languageOptions,
      [lng]: !languageOptions[lng],
    });
  };

  const lgItemsClassName = classNames(
    styles[theme],
    asFullText && styles.expanded,
  );
  const lgItemsStyle = {
    width: size,
    height: size,
    backgroundColor: bgColor,
    border: bgColor ? 'unset' : '',
  };

  return (
    <div className={styles.circles_container}>
      <div className={styles.circles}>
        <ul className={listClassName}>
          <li
            className={classNames(
              lgItemsClassName,
              languageOptions.fr ? styles.selected : '',
            )}
            onClick={() => onLanguageClick('fr')}
            style={lgItemsStyle}
          >
            <span>{asFullText ? t('french') : 'FR'}</span>
          </li>
          <li
            className={classNames(
              lgItemsClassName,
              languageOptions.nl ? styles.selected : '',
            )}
            onClick={() => onLanguageClick('nl')}
            style={lgItemsStyle}
          >
            <span>{asFullText ? t('dutch') : 'NL'}</span>
          </li>
          <li
            className={classNames(
              lgItemsClassName,
              languageOptions.en ? styles.selected : '',
            )}
            onClick={() => onLanguageClick('en')}
            style={lgItemsStyle}
          >
            <span>{asFullText ? t('english') : 'EN'}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LanguageCircles;
