import { connect } from 'react-redux';
import { RootState } from 'store/types';
import OptionsConfirmation from './OptionsConfirmation';
import {
  confirmGuestOption,
  confirmOptionsRejection,
  fetchGuestData,
} from 'store/Guests/thunks';

const mapStateToProps = (state: RootState) => ({
  packSummary: state.packs.packSummary,
});

const mapDispatchToProps = {
  confirmGuestOption,
  fetchGuestData,
  confirmOptionsRejection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OptionsConfirmation);
