import {
  ResourceState,
  ResourceActionTypes,
  RESET_RESOURCE,
  FETCH_RESOURCE_PENDING,
  FETCH_RESOURCE_FULFILLED,
  FETCH_RESOURCE_REJECTED,
  RESET_RESOURCE_DATA,
  SET_RESOURCE_FETCHING,
  ADD_STRATEGY,
  SAVE_RESOURCE_PENDING,
  SAVE_RESOURCE_FULFILLED,
  SAVE_RESOURCE_REJECTED,
  ApiResource,
  CHANGE_ADD_RESOURCE_STRATEGY,
  ADD_RESOURCE,
} from './types';
import { paginationInitialState } from 'store/Pagination/reducer';
import { filterInitialState } from 'store/Filter/reducer';

const initialResourceState = {
  fetching: false,
  fetched: false,
  saving: false,
  addStrategy: ADD_STRATEGY.TAIL,
  items: [],
  error: null,
  nbResult: 0,
  fetchedAt: 0,
  ...paginationInitialState,
  ...filterInitialState,
};

export const reducer = <Resource extends ApiResource>(
  state: ResourceState<Resource> = initialResourceState,
  action: ResourceActionTypes<Resource>,
): ResourceState<Resource> => {
  switch (action.type) {
    case RESET_RESOURCE: {
      return initialResourceState;
    }
    case RESET_RESOURCE_DATA: {
      return {
        ...state,
        fetching: false,
        fetched: false,
        items: [],
        error: null,
      };
    }
    case SET_RESOURCE_FETCHING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case CHANGE_ADD_RESOURCE_STRATEGY: {
      return {
        ...state,
        addStrategy: action.payload,
      };
    }
    case ADD_RESOURCE: {
      return {
        ...state,
        items:
          state.addStrategy === ADD_STRATEGY.HEAD
            ? [action.payload, ...state.items]
            : [...state.items, action.payload],
      };
    }
    case FETCH_RESOURCE_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_RESOURCE_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchedAt: new Date().getTime(),
        error: null,
        items:
          state.page === 1 ? data ?? [] : [...state.items, ...(data ?? [])],
        nbResult: nbResult ?? 0,
      };
    }
    case FETCH_RESOURCE_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    case SAVE_RESOURCE_PENDING: {
      return {
        ...state,
        saving: true,
      };
    }
    case SAVE_RESOURCE_FULFILLED: {
      const { data } = action.payload;
      let exist = false;

      let nbResult = state.nbResult;
      let newItems = state.items.map((item) => {
        if (+item.id === +data?.id) {
          exist = true;
          return data;
        }
        return item;
      });

      /**
       * Check if it exists AND if the api returns a correct object
       */
      if (!exist && data.id) {
        if (state.addStrategy === ADD_STRATEGY.HEAD) {
          newItems = [data, ...newItems];
        } else if (state.addStrategy === ADD_STRATEGY.TAIL) {
          newItems = [...newItems, data];
        }
        nbResult += 1;
      }

      return {
        ...state,
        saving: false,
        items: newItems,
        nbResult,
      };
    }
    case SAVE_RESOURCE_REJECTED: {
      return {
        ...state,
        saving: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
