import React from 'react';

import styles from '../StepCollaboratorsData.module.scss';
import { Tooltip, Table, Modal } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import _ from 'i18n';
import classNames from 'classnames';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as GarbageIcon } from 'assets/icons/garbage.svg';
import DeleteCollaboratorForm from './DeleteCollaboratorForm';
interface Props {
  fetched: boolean;
  fetching: boolean;
  error?: any;
  collaborators: any;
  deleteCollaborator: (
    userId: number,
    isSimple: boolean,
    email: string,
    oldEmail: string,
  ) => any;
  onEdit: (collaborator: any) => any;
  deleting: boolean;
  newCollab: any;
}

interface IState {
  count: number;
  dataSource: any;
  nameSearch: string;
  isDelete: boolean;
  userId: number;
  oldMail: string;
}

class CollaboratorsList extends React.Component<Props, IState> {
  data: any = [];
  constructor(props: Props) {
    super(props);
    this.props.collaborators?.length > 0 &&
      this.props.collaborators?.map((item: any, i: number) => {
        const email = item?.email?.find((mail: any) => mail.main == 1);
        const phone = item?.email?.find((mail: any) => mail.main == 1);
        this.data.push({
          id: item?.id,
          key: i,
          name: item?.firstName + ' ' + item?.lastName,
          firstName: item?.firstName,
          lastName: item?.lastName,
          Email: item?.mainEmail,
          EmailId: email && email?.id,
          Tél: item?.mainPhone,
          phoneId: phone && phone?.id,
          role: _(item?.role?.type),
          roleId: item?.role?.id,
          lng: item?.language,
          action: '',
        });
      });

    this.state = {
      count: Array.isArray(this.props.collaborators)
        ? this.props.collaborators.length
        : 0,
      dataSource: this.data,
      nameSearch: '',
      isDelete: false,
      userId: 0,
      oldMail: '',
    };
  }

  handleClear = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('col-search').value = '';

    this.setState({
      dataSource: this.data,
      nameSearch: '',
    });
  };

  handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value != null && value !== '') {
      this.setState({
        dataSource: this.data.filter((person: any) =>
          person.name.toUpperCase().includes(value.toUpperCase()),
        ),
        nameSearch: value,
      });
    } else {
      this.setState({
        dataSource: this.data,
      });
    }
  };

  handleCloseDeleteModal = () => {
    this.setState({ isDelete: false, userId: 0, oldMail: '' });
    const { isDelete } = this.state;
  };

  render() {
    const handleOpenDeleteModal = (userId: number, oldMail: string) => {
      this.setState({ isDelete: true, userId: userId, oldMail: oldMail });
    };

    const {
      dataSource,
      nameSearch,
      count,
      isDelete,
      userId,
      oldMail,
    } = this.state;

    const { newCollab } = this.props;
    const columns: any = [
      {
        title: _('lastName') + '/' + _('firstName'),
        dataIndex: 'name',
        width: 80,
        sorter: (a: any, b: any) => a?.name?.length - b?.name?.length,
        //defaultSortOrder: ['descend'],
        render(text: any, record: any) {
          const color = newCollab.includes(record.id) ? '#FEF0E3' : '#F8F9FA';
          return {
            props: {
              style: {
                background: color,
                paddingLeft: '2%',
              },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        width: 120,
        render(text: any, record: any) {
          const color = newCollab.includes(record.id) ? '#FEF0E3' : '#F8F9FA';
          return {
            props: {
              style: {
                background: color,
                paddingLeft: '2%',
              },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: 'Tél',
        dataIndex: 'Tél',
        width: 60,
        render(text: any, record: any) {
          const color = newCollab.includes(record.id) ? '#FEF0E3' : '#F8F9FA';
          return {
            props: {
              style: {
                background: color,
                paddingLeft: '2%',
              },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: _('Collaborator role'),
        dataIndex: 'role',
        width: 80,
        sorter: (a: any, b: any) => a?.role?.length - b?.role?.length,
        defaultSortOrder: ['descend'],
        render(text: any, record: any) {
          const color = newCollab.includes(record.id) ? '#FEF0E3' : '#F8F9FA';
          return {
            props: {
              style: {
                background: color,
                paddingLeft: '2%',
              },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: _('lng'),
        dataIndex: 'lng',
        width: 60,
        render(text: any, record: any) {
          const color = newCollab.includes(record.id) ? '#FEF0E3' : '#F8F9FA';
          return {
            props: {
              style: {
                background: color,
                paddingLeft: '2%',
              },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: '',
        dataIndex: 'action',
        width: 30,
        render(text: any, record: any) {
          const color = newCollab.includes(record.id) ? '#FEF0E3' : '#F8F9FA';
          return {
            props: {
              style: {
                background: color,
                paddingLeft: '2%',
              },
            },
            children: (
              <div>
                <span className={styles.actions_btn}>
                  <span
                    onClick={() =>
                      handleOpenDeleteModal(record.id, record.Email)
                    }
                  >
                    <Tooltip placement="top" title={_('delete')}>
                      <GarbageIcon fill="#FF0000" width="12" height="12" />
                    </Tooltip>
                  </span>
                </span>
              </div>
            ),
          };
        },
      },
    ];

    return (
      <div className={styles.collaborator_list}>
        <div className="grid-x" style={{ marginBottom: '1%' }}>
          <div
            className="cell small-8 align-stretch p-r-xs"
            style={{ fontSize: '16px', marginTop: '1%' }}
          >
            {_('Collaborators list')} {'('} {count} {') :'}
          </div>
          <div className="cell small-4 align-stretch p-r-xs">
            <div className={styles.search_input}>
              <span className="m-l-xs">
                <SearchIcon fill="#6D7F92" width="18" height="18" />
              </span>
              <input
                id={'col-search'}
                type="text"
                placeholder={`${_('Search')} ...`}
                onChange={this.handleChange}
              />
              <span
                onClick={this.handleClear}
                className={classNames(
                  'm-r-s',
                  styles.clear_input,
                  nameSearch != '' && styles.visible,
                )}
              >
                <CrossIcon fill="#3C4E64" width="12" height="12" />
              </span>
            </div>
          </div>
        </div>
        <Modal
          closable={false}
          maskClosable={true}
          destroyOnClose={true}
          visible={isDelete}
          //onOk={}
          //onCancel={}
          width={632}
          footer={null}
          // mask={''}
        >
          <DeleteCollaboratorForm
            oldMail={oldMail}
            userId={userId}
            onCancel={this.handleCloseDeleteModal}
            deleteCollaborator={this.props.deleteCollaborator}
            deleting={this.props.deleting}
          />
        </Modal>
        <Table
          className={styles.myTable}
          style={{ marginBottom: '-1%' }}
          columns={columns}
          dataSource={dataSource ? dataSource : []}
          pagination={false}
          scroll={{ y: 400 }}
        />
      </div>
    );
  }
}

export default CollaboratorsList;
