import { connect } from 'react-redux';
import { EVENT_SLOT_RESOURCE, RootState } from 'store/types';
import UpComingEventPopup from './UpComingEventPopup';
import { fetchResourceThunk } from 'store/Resource/thunks';
import { fetchSlots, FetchSlotsParamsType } from 'store/Slots/thunks';
import { closeModal } from 'store/Modal/actions';

const mapStateToProps = (state: RootState) => ({
  language: state.params.lng,
  eventIdsResource: state.event.eventIds,
  userId: state.auth.user?.id,
  community: state.auth.navCommunity,
  slots: state.event.slots.items,
});

const fetchEventSlots = (params: FetchSlotsParamsType) =>
  fetchSlots(params, EVENT_SLOT_RESOURCE);

export default connect(mapStateToProps, {
  fetchEvents: fetchResourceThunk,
  fetchSlots: fetchEventSlots,
  closeModal,
})(UpComingEventPopup);
