import { connect } from 'react-redux';
import { addEventFilter, removeEventFilter } from 'store/Events/actions';
import { fetchEventsStats } from 'store/Events/thunks';
import { fetchEventsCategories } from 'store/EventsCategories/thunks';
import { selectSingleValueFilter } from 'store/Filter/selectors';
import { RootState } from 'store/types';
import ModalWatchSearch from './ModalWatchSearch';

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  searchFilter: selectSingleValueFilter(state.events.search, 'search', 'like'),
  filters: state.events.search.filters,
  language: state.params.lng,
  eventsCategories: state.categories,
  eventsListResource: state.events.list,
});

const mapDispatchToProps = {
  addEventFilter,
  removeEventFilter,
  fetchEventsCategories,
  fetchEventsStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWatchSearch);
