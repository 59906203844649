import axios, { AxiosPromise } from 'axios';
import { TTP_API_URL, CLIENT_CREDENTIAL } from 'config';
import { Organization } from 'interfaces/Organization';
import { SingleValueFilter } from 'services/Filter';
import { hydrateFormData } from 'store/services';
import { Language, ResourceData, ServerData } from 'store/types';
import { getFormData } from 'utils';

const USER_FIELDS = [
  '*',
  'uid',
  'email',
  'avatar',
  'description',
  'cover',
  'url',
  'roles',
  'role',
  'birthday',
  'userSettings',
  'memberOfCommunities',
  'contactSocialNetworks',
];

export const getTTPUser = (
  userId: number,
  token: string,
): AxiosPromise<ServerData> => {
  const filter = [
    {
      property: 'id',
      value: userId,
      operator: 'eq',
    },
  ];
  const fields = USER_FIELDS;
  const requestUrl = `${TTP_API_URL}/organization/user`;

  const params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(','),
    workspace: 'offfcourse',
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getClientCredential = (): AxiosPromise => {
  const requestUrl = `${TTP_API_URL}/token`;
  return axios.post(requestUrl, getFormData(CLIENT_CREDENTIAL));
};

export const setSelectedOrganization = ({
  token,
  organizationId,
}: any): AxiosPromise<ServerData> => {
  const requestUrl = `${TTP_API_URL}/organization/user/save-selected-organization`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('organizationId', organizationId);

  return axios.post(requestUrl, formData);
};

export const getSelectedOrganization = (
  token: string,
): AxiosPromise<ServerData> => {
  const requestUrl = `${TTP_API_URL}/organization/user/get-selected-organization`;

  // return $.ajax({
  //   type: "GET",
  //   async: false,
  //   url: requestUrl,
  //   data: `access_token=${token}&fields=${[
  //     "id",
  //     "uen",
  //     "name",
  //     "url",
  //     "abbreviation"
  //   ].join(",")}`
  // });

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: [
        'id',
        'uen',
        'name',
        'url',
        'abbreviation',
        'avatarWebPath',
      ].join(','),
    },
  });
};

export const getActiveApps = (
  token: string,
  organizationId: number,
): AxiosPromise<ServerData> => {
  const requestUrl = `${TTP_API_URL}/organization/organization/apps-activation-status`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
    },
  });
};

export const getEventAuthorization = ({
  token,
  userId,
  communityIds,
}: {
  token: string;
  userId: number;
  communityIds: number[];
}) => {
  const fields = ['*', 'user', 'client'];

  const requestUrl = `${TTP_API_URL}/event/event-authorisation`;

  const filter = [
    {
      property: 'user',
      operator: 'eq',
      value: userId,
    },
    {
      property: 'client',
      operator: 'in',
      value: communityIds,
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(','),
    },
  });
};

export const fetchTTPUserByToken = (token: string) => {
  const fields = USER_FIELDS;

  const requestUrl = `${TTP_API_URL}/organization/user/getCurrentUser`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
    },
  });
};

export const fetchCommunity = (token: string, communityId: string | number) => {
  const requestUrl = `${TTP_API_URL}/event/event/organization/folder`;
  const filter = [
    new SingleValueFilter('id', 'eq', communityId),
  ].map((filter) => filter.serialize());

  const fields = ['id', 'name', 'url', 'avatarWebPath', 'cover'];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter),
    },
  });
};

export const fetchCurrentUserRoles = (token: string) => {
  const requestUrl = `${TTP_API_URL}/organization/user/getCurrentUser`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: 'roles',
    },
  });
};

export const getOrganizationByUen = (
  token: string,
  uen: string,
  discr: string,
) => {
  const requestUrl = `${TTP_API_URL}/organization/organization/get-organization-by-uen`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      uen,
      discr,
    },
  });
};

export const getSectorsTypeahead = (
  token: string,
  keyword: string,
  lng: Language,
) => {
  const requestUrl = `${TTP_API_URL}/organization/sector/typeahead`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      title: keyword,
      language: lng,
    },
  });
};

export const saveOrganizationStep = ({
  token,
  organizationData,
}: {
  token: string;
  organizationData: ResourceData<Organization>;
}) => {
  const requestUrl = `${TTP_API_URL}/organization/organization`;
  const formData = new FormData();

  formData.append('access_token', token);
  hydrateFormData(formData, organizationData);

  return axios.post(requestUrl, formData);
};

export const acceptTerms = ({
  token,
  orgId,
}: {
  token: string;
  orgId: ResourceData<string>;
}) => {
  const requestUrl = `${TTP_API_URL}/organization/organization/terms`;
  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('id', orgId);

  return axios.post(requestUrl, formData);
};

export const subscribeToNewsLetterFFF = ({
  token,
  email,
}: {
  token: string;
  email: string;
}) => {
  const requestUrl = `${TTP_API_URL}/mailing/mailing-list-subscriber/request`;
  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('email', email);
  formData.append('mailingList', '6');

  return axios.post(requestUrl, formData);
};
