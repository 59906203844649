import {
  ADD_PARTNER_AD,
  DELETE_PARTNER_AD,
  FETCH_PARTNER_AD_FULFILLED,
  FETCH_PARTNER_AD_PENDING,
  FETCH_PARTNER_AD_REJECTED,
  PartnerAdActionTypes,
  PartnerAdState,
  RESET_PARTNER_AD,
  EDIT_PARTNER_AD,
  FILTER_PARTNER_AD,
  PartnerAd,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  filteredItems: [],
  error: null,
  nbResult: 0,
};

export const reducer = (
  state: PartnerAdState = initialState,
  action: PartnerAdActionTypes,
) => {
  switch (action.type) {
    case RESET_PARTNER_AD: {
      return initialState;
    }
    case FETCH_PARTNER_AD_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_PARTNER_AD_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        filteredItems: data ? data : [],
        nbResult: nbResult,
      };
    }
    case FETCH_PARTNER_AD_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        filteredItems: [],
        nbResult: 0,
      };
    }
    case ADD_PARTNER_AD: {
      const sortedAds = [action.partnerAd, ...state.items].sort(
        (a: PartnerAd, b: PartnerAd) => a.position - b.position,
      );

      return {
        ...state,
        items: sortedAds,
        filteredItems: sortedAds,
      };
    }

    case EDIT_PARTNER_AD: {
      const newItems = state.items.map((item) => {
        if (item.id === action.partnerAd.id) {
          return { ...item, ...action.partnerAd };
        }
        return item;
      });

      return {
        ...state,
        items: newItems.sort(
          (a: PartnerAd, b: PartnerAd) => a.position - b.position,
        ),
        filteredItems: newItems.sort(
          (a: PartnerAd, b: PartnerAd) => a.position - b.position,
        ),
      };
    }

    case DELETE_PARTNER_AD: {
      return {
        ...state,
        items: state.items.filter((item) => item.id != action.payload),
        filteredItems: state.items.filter((item) => item.id != action.payload),
      };
    }

    case FILTER_PARTNER_AD: {
      return { ...state, filteredItems: action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
