import { EventPlan, PLAN_OPTION_TYPE } from 'interfaces/EventPlan';
import { useMemo } from 'react';
import { EventPlanAndOptions } from 'store/Events/types';
import { Coupon, COUPON_REDUCTION_TYPE } from 'store/Guests/types';

interface Params {
  plan?: EventPlan;
  plansAndOptions: EventPlanAndOptions | null;
  coupons: Coupon[];
}

export const useExtraOptions = ({ plansAndOptions, plan, coupons }: Params) =>
  useMemo(() => {
    if (!plansAndOptions) {
      return [];
    }

    const { extraPlanOptions, additionnalOptions } = plansAndOptions;

    const additionalOptionsIds =
      plan?.options
        .filter(({ type }) => +type === PLAN_OPTION_TYPE.ADDITIONAL)
        .map((op) => +op.option) ?? [];

    const filteredAdditionalOptions = additionnalOptions.filter(
      (op) => additionalOptionsIds.indexOf(+op.id) !== -1,
    );

    return [...filteredAdditionalOptions, ...extraPlanOptions].filter((op) => {
      // The idea is to remove coupon related options
      return (
        +op.byCoupon === 0 ||
        (+op.byCoupon === 1 &&
          coupons.some((cp) =>
            cp.reductions.some(
              (rd) =>
                +rd.id === +op.id && rd.type === COUPON_REDUCTION_TYPE.OPTION,
            ),
          ))
      );
    });
  }, [coupons, plan?.options, plansAndOptions]);
