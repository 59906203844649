import React, { useCallback, useState } from 'react';

/**
 * Get component size
 */
export const useSize = <T extends HTMLElement>() => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const measuredRef = useCallback((node: T) => {
    if (node !== null) {
      const { offsetWidth, offsetHeight } = node;
      setSize({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  return { measuredRef, size };
};
