import { Dispatch } from 'redux';
import { RootState } from 'store/types';
import actions from './actions';
import * as api from './api';

export const fetchPacks = (organizationId: number, eventId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  const filters = [
    {
      property: 'organizationId',
      operator: 'eq',
      value: organizationId,
    },
  ];

  return dispatch(
    actions.fetchPacks(
      api.getPack({ token, filters, organizationId, eventId }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const getAllPacks = (eventId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.getAllPacks(
      api.getAllPacks({ token, eventId }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const savePlace = (
  event: any,
  user: any,
  couponModel: any,
  owner: any,
  partner: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.savePlace(
      api
        .savePlace({
          token,
          event,
          user,
          couponModel,
          owner,
          partner,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const choosePack = (eventId: string, packId: string, userId: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.choosePack(
      api
        .choosePack({
          token,
          eventId,
          packId,
          userId,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const cancelPlace = (
  eventId: any,
  partnerId: any,
  guestId: any,
  ownerId: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.cancelPlace(
      api
        .cancelPlace({
          token,
          eventId,
          partnerId,
          guestId,
          ownerId,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const setPackOrderFiduciary = (
  packOrderId: string,
  organizationId: string,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.setFiduciary(
      api
        .setPackOrderFiduciary({
          token,
          packOrderId,
          organizationId,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const fetchPackSummary = (userId: number, eventId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.fetchPackSummary(
      api.getPackSummary({ token, userId, eventId }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const cancelPackOrder = (packOrderId: string) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api.cancelPackOrder({ token, packOrderId }).then((resp) => {
    return resp.data.data;
  });
};
