import Switcher from 'components/Common/Switcher/Switcher';
import React from 'react';
import styles from './GuestAddress.module.scss';
import classNames from 'classnames';
import { Address as InvoicingAddressType } from 'store/Guests/Invoicing/types';
import { LayoutTheme } from 'components/Layout/services';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import _ from 'i18n';
import { formatUen } from 'utils';
import { GuestAddress } from 'store/Guests/types';
import { Address as AddressInterface } from 'interfaces/Address';
import { ReactComponent as UpdateIcon } from 'assets/icons/edit.svg';

interface Address1 {
  type: 'INVOICING';
  address: InvoicingAddressType;
}
interface Address2 {
  type: 'ADDRESS';
  address: AddressInterface;
}
interface Address3 {
  type: 'GUEST';
  address: GuestAddress;
}
interface Props {
  data: Address1 | Address2 | Address3;
  isSelected?: boolean;
  theme?: LayoutTheme;
  onClick?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
}

export const Address = ({
  data,
  isSelected,
  theme = '',
  onClick,
  onDelete,
  onUpdate,
}: Props) => {
  const renderData = () => {
    switch (data.type) {
      case 'GUEST': {
        const {
          billingCompanyNumber,
          billingOrganization,
          billingSubjectToVAT,
          billingStreet,
          billingPostalCode,
          billingAddress2,
          billingOrderNumber,
        } = data.address;

        return (
          <div className={styles.data}>
            {billingCompanyNumber && (
              <div>{formatUen(billingCompanyNumber)}</div>
            )}
            {billingOrganization && (
              <div>
                <strong>{billingOrganization}</strong>
              </div>
            )}
            <div>
              {billingStreet} - {billingPostalCode}
            </div>
            {billingAddress2 && <div>{billingAddress2}</div>}
            {billingOrderNumber && <div>{billingOrderNumber}</div>}
            <div>
              {billingSubjectToVAT == '1'
                ? _('inscription.soumis_tva')
                : _('inscription.non_soumis_tva')}
            </div>
          </div>
        );
      }
      case 'INVOICING': {
        const {
          uen,
          organization,
          vatApply,
          street,
          zip,
          city,
          address2,
          orderNumber,
        } = data.address;

        return (
          <div className={styles.data}>
            {uen && <div>{formatUen(uen)}</div>}
            {organization && (
              <div>
                <strong>{organization}</strong>
              </div>
            )}
            <div>
              {street} - {zip} {city}
            </div>
            <div>
              {address2 && <div>{address2}</div>}
              {orderNumber && <div>{orderNumber}</div>}
              {vatApply == '1'
                ? _('inscription.soumis_tva')
                : _('inscription.non_soumis_tva')}
            </div>
          </div>
        );
      }
      case 'ADDRESS': {
        const { fullName: fullname, street, zipCode, city } = data.address;

        return (
          <div className={styles.data}>
            {fullname && (
              <div>
                <strong>{fullname}</strong>
              </div>
            )}
            <div>
              {street} - {zipCode} {city}
            </div>
          </div>
        );
      }
    }

    return null;
  };
  return (
    <div
      className={classNames(
        styles.address,
        isSelected && styles.active,
        styles[theme],
      )}
    >
      <div className="grid-x">
        <div className="cell small-10">{renderData()}</div>
        <div className="cell small-2">
          <div
            className={classNames(
              styles.switchWrapper,
              'align-right flex-container',
            )}
          >
            <div className="m-r-xs">{isSelected ? '' : _('Choose')}</div>
            <Switcher
              theme={theme}
              checked={isSelected === true}
              onChange={() => onClick?.()}
            />
          </div>
        </div>
        <div className={classNames('cell small-12', styles.addressActions)}>
          {onUpdate && (
            <div className={styles.updateAction}>
              <UpdateIcon color="#FE3745" onClick={onUpdate} />
            </div>
          )}
          {onDelete && (
            <div
              className={classNames(
                styles.actions,
                'flex-container align-right',
              )}
            >
              <TrashIcon color="#FE3745" height="14" onClick={onDelete} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
