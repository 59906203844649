import { PaginationState } from 'store/Pagination/types';
import { Partner } from 'store/Partners/types';
import { Speaker } from 'store/Speakers/types';
import {
  NameWrappedAction,
  EVENT_SLOT_RESOURCE,
  SLOTS_RESOURCE,
} from 'store/types';

export interface Slot {
  id: number;
  createdAt: string;
  updatedAt: string;
  startDateTime: string;
  endDateTime: string;
  nameFr?: string | null;
  nameNl?: string | null;
  nameEn?: string | null;
  languages?: string;
  descriptionFr?: string | null;
  descriptionNl?: string | null;
  descriptionEn?: string | null;
  pictureUrlFr?: string | null;
  pictureUrlNl?: string | null;
  pictureUrlEn?: string | null;
  webinarUrlFr?: string;
  webinarUrlNl?: string;
  webinarUrlEn?: string;
  partners?: Partner[];
  speakers?: Speaker[];
  room?: number;
  roomData?: Room;
  event?: number;
  activity: number;
  status: SlotStatus;
  placesNumber: number;
  subscribersNumber: number;
  webinarReplayVideoFr?: string;
  webinarReplayVideoNl?: string;
  webinarReplayVideoEn?: string;
  webinarVideoEn?: string;
  webinarVideoFr?: string;
  webinarVideoNl?: string;
  zoomMeetingUrl?: string;
  isPostPlayVideo: boolean;
  files?: SlotFile[];
  isWebinar: SlotType;
  roomName?: string;
  activityName?: activityName;
  type: 'breakout' | 'conference' | 'demo';
  youtubeVideoLink?: string;
}

export enum SlotStatus {
  SOLD_OUT = 4,
}

export enum SlotType {
  IS_NOT_WEBINAR = '0',
  IS_EXTERNAL_WEBINAR = '1',
  IS_TAMTAM_WEBINAR = '2',
}

export interface activityName {
  Fr: string | null;
  Nl: string | null;
  En: string | null;
}

export interface Room {
  id: number;
  name: string;
}

export interface SlotFile {
  id: number;
  fileUrl: string;
  event: number;
  slot: number;
  handoutId: string | null;
}

export interface SlotState extends PaginationState {
  fetching: boolean;
  fetched: boolean;
  items: Slot[];
  search: string;
  error: any;
  nbResult: number;
}

export const SET_ROOMS = 'SET_ROOMS';
export const SET_SEARCH = 'SET_SEARCH';

export type SlotReducerNameType =
  | typeof EVENT_SLOT_RESOURCE
  | typeof SLOTS_RESOURCE;

export interface SetRoomsAction extends NameWrappedAction {
  type: typeof SET_ROOMS;
  rooms: Room[];
}

export interface SetSearchAction extends NameWrappedAction {
  type: typeof SET_SEARCH;
  search: string;
}

export type SlotActionTypes = SetRoomsAction | SetSearchAction;
