import { paginationActionsCreator } from 'store/Pagination/actions';
import { resetResource } from 'store/Resource/actions';
import {
  EVENT_PARTNERS_RESOURCE,
  EVENT_PARTNERS_SEARCH_RESOURCE,
  EVENT_STRUCTURAL_PARTNERS_RESOURCE,
  GenericReducerTypes,
  PartnersReducerNames,
} from 'store/types';
import {
  Partner,
  PartnersActionTypes,
  PartnersReducerNameType,
  UPDATE_PARTNER,
} from './types';
import { Dispatch } from 'redux';

export const resetPartners = (name: PartnersReducerNames) =>
  resetResource(name);

export const updatePartner = (
  partner: Partner,
  reducerName: GenericReducerTypes,
): PartnersActionTypes => ({
  type: UPDATE_PARTNER,
  meta: reducerName,
  partner,
});

export const updateAllPartnerOccurence = (partner: Partner) => (
  dispatch: Dispatch,
) => {
  const partnerReducers: PartnersReducerNameType[] = [
    EVENT_STRUCTURAL_PARTNERS_RESOURCE,
    EVENT_PARTNERS_RESOURCE,
  ];

  partnerReducers.forEach((reducerName) => {
    dispatch(updatePartner(partner, reducerName));
  });
};

export const [setPartnersPage, setPartnersPageSize] = paginationActionsCreator(
  EVENT_PARTNERS_RESOURCE,
);
export const [
  setPartnersSearchPage,
  setPartnersSearchPageSize,
] = paginationActionsCreator(EVENT_PARTNERS_SEARCH_RESOURCE);

export default {
  updatePartner,
  updateAllPartnerOccurence,
  setPartnersPage,
  setPartnersPageSize,
  setPartnersSearchPage,
  setPartnersSearchPageSize,
};
