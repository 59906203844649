import React from 'react';
import s from '../StepsSummary.module.scss';
import cn from 'classnames';
import _ from 'i18n';
import { StepTitle } from 'components/Tools/Summary/StepTitle';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import { Coupon } from 'store/Guests/types';
import { getByLanguage } from 'utils';
import { Language } from 'store/types';
import { isEmpty } from 'lodash';
import { Help } from 'components/ui/Help/Help';
import Markdown from 'markdown-to-jsx';
import { SummaryBlock } from 'components/Tools/SummaryBlocks/SummaryBlock';

interface Props {
  coupons: Coupon[];
  className?: string;
  language: Language;
  onEdit?: () => void;
}

export const DiscountSummary = ({
  coupons,
  className,
  language,
  onEdit,
}: Props) => {
  return (
    <div className={cn('m-b-s', className)}>
      <SummaryBlock title={_('Discount')} />
      {coupons.map((coupon, index) => {
        const modelName = getByLanguage(coupon, 'modelName', language) ?? '';
        const comment = getByLanguage(coupon, 'comment', language);
        const hostName = getByLanguage(coupon, 'hostName', language) ?? '';
        const couponName = !isEmpty(modelName) ? modelName : hostName;

        return (
          <div
            className="flex-container align-justify align-top"
            key={coupon.code}
          >
            <div className="flex-container align-middle">
              <span className={cn(s.big_text, s.no_weight)}>
                {_('Coupon code')} : <strong>{coupon.code}</strong>
              </span>
              {!isEmpty(comment) && (
                <Help
                  className="m-l-xs"
                  title={couponName}
                  contentStyle={{ width: '263px' }}
                  align={{ offset: [0, -32] }}
                  placement="leftTop"
                >
                  <Markdown className="markdown">{comment}</Markdown>
                </Help>
              )}
            </div>

            {index === 0 && onEdit && (
              <button className={cn(s.circle_btn)} onClick={onEdit}>
                <EditIcon fill="#6D7F92" width="12px" />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DiscountSummary;
