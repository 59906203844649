import {
  FETCH_PACKS,
  FETCH_ALL_PACKS,
  SAVE_PLACE,
  CANCEL_PLACE,
  CHOOSE_PACK,
  SET_FIDUCIARY,
  FETCH_SUMMARY,
} from './types';

export const fetchPacks = (resp: any): { payload: any; type: string } => ({
  type: FETCH_PACKS,
  payload: resp,
});

export const getAllPacks = (resp: any): { payload: any; type: string } => ({
  type: FETCH_ALL_PACKS,
  payload: resp,
});

export const savePlace = (resp: any): { payload: any; type: string } => ({
  type: SAVE_PLACE,
  payload: resp,
});

export const cancelPlace = (resp: any): { payload: any; type: string } => ({
  type: CANCEL_PLACE,
  payload: resp,
});

export const choosePack = (resp: any): { payload: any; type: string } => ({
  type: CHOOSE_PACK,
  payload: resp,
});

export const setFiduciary = (resp: any): { payload: any; type: string } => ({
  type: SET_FIDUCIARY,
  payload: resp,
});

export const fetchPackSummary = (
  resp: any,
): { payload: any; type: string } => ({
  type: FETCH_SUMMARY,
  payload: resp,
});

export default {
  fetchPacks,
  getAllPacks,
  savePlace,
  cancelPlace,
  choosePack,
  setFiduciary,
  fetchPackSummary,
};
