import { connect } from 'react-redux';
import { RootState } from 'store/types';
import AddAddress from './AddAddress';
import { validateOrganizationNumber } from 'store/Address/thunks';

const mapStateToProps = (state: RootState) => ({
  guestsResource: state.event.guests,
});

export default connect(mapStateToProps, {
  validateOrganizationNumber,
})(AddAddress);
