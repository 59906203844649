export { default } from './TTPForm';
export { default as TTPInput, TTPInputField } from './TTPInput';
export { default as TTPTextarea, TTPTextareaField } from './TTPTextarea';
export { default as TTPRadioGroup, TTPRadioGroupField } from './TTPRadioGroup';
export { default as TTPDatePicker, TTPDatePickerField } from './TTPDatePicker';
export { TTPSelect, TTPSelectField } from './TTPSelect';
export { TTPFormPersist } from './TTPFormPersist';

/**
 * Stop enter submitting the form.
 * @param keyEvent Event triggered when the user presses a key.
 */
export function PreventSubmitOnKeyDown(
  keyEvent: React.KeyboardEvent<HTMLFormElement>,
) {
  if (
    (keyEvent.charCode || keyEvent.keyCode) === 13 ||
    keyEvent.key === 'Enter'
  ) {
    keyEvent.preventDefault();
  }
}
