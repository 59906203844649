import {
  NameWrappedAction,
  TTPSort,
  WithPaginationAndFilterState,
} from 'store/types';
import { Filter } from 'services/Filter';

export interface ApiResource {
  id: number;
}

export interface ResourceState<Resource> extends WithPaginationAndFilterState {
  fetching: boolean;
  fetched: boolean;
  saving: boolean;
  addStrategy: ADD_STRATEGY;
  fetchedAt: number; // timestamp
  items: Resource[];
  error: any;
  nbResult: number;
}

export enum ADD_STRATEGY {
  HEAD,
  TAIL,
}

export const RESET_RESOURCE = 'RESET_RESOURCE';
export const RESET_RESOURCE_DATA = 'RESET_RESOURCE_DATA';
export const FETCH_RESOURCE = 'FETCH_RESOURCE';
export const FETCH_RESOURCE_PENDING = 'FETCH_RESOURCE_PENDING';
export const FETCH_RESOURCE_FULFILLED = 'FETCH_RESOURCE_FULFILLED';
export const FETCH_RESOURCE_REJECTED = 'FETCH_RESOURCE_REJECTED';
export const SET_RESOURCE_FETCHING = 'SET_RESOURCE_FETCHING';
export const SAVE_RESOURCE = 'SAVE_RESOURCE';
export const SAVE_RESOURCE_PENDING = 'SAVE_RESOURCE_PENDING';
export const SAVE_RESOURCE_FULFILLED = 'SAVE_RESOURCE_FULFILLED';
export const SAVE_RESOURCE_REJECTED = 'SAVE_RESOURCE_REJECTED';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const CHANGE_ADD_RESOURCE_STRATEGY = 'CHANGE_ADD_RESOURCE_STRATEGY';

export interface ResetResourceAction extends NameWrappedAction {
  type: typeof RESET_RESOURCE;
}

export interface ResetResourceDataAction extends NameWrappedAction {
  type: typeof RESET_RESOURCE_DATA;
}

export interface FetchResourceAction extends NameWrappedAction {
  type:
    | typeof FETCH_RESOURCE
    | typeof FETCH_RESOURCE_PENDING
    | typeof FETCH_RESOURCE_FULFILLED
    | typeof FETCH_RESOURCE_REJECTED;
  payload: any;
}

export interface SaveResourceAction extends NameWrappedAction {
  type:
    | typeof SAVE_RESOURCE
    | typeof SAVE_RESOURCE_PENDING
    | typeof SAVE_RESOURCE_FULFILLED
    | typeof SAVE_RESOURCE_REJECTED;
  payload: any;
}

export interface SetResourceFetchingAction extends NameWrappedAction {
  type: typeof SET_RESOURCE_FETCHING;
}

export interface AddResourceAction<T> extends NameWrappedAction {
  type: typeof ADD_RESOURCE;
  payload: T;
}

export interface changeAddResourceStrategyAction extends NameWrappedAction {
  type: typeof CHANGE_ADD_RESOURCE_STRATEGY;
  payload: ADD_STRATEGY;
}

export type ResourceActionTypes<T> =
  | FetchResourceAction
  | ResetResourceAction
  | ResetResourceDataAction
  | AddResourceAction<T>
  | changeAddResourceStrategyAction
  | SaveResourceAction
  | SetResourceFetchingAction;

export interface FetchParams {
  filters?: Filter[];
  page?: number;
  pageSize?: number;
  nolimit?: boolean;
  sort?: TTPSort[];
  fields?: string[];
  queryParams?: Record<string, any>;
  communityId?: number;
  isCommunityPartner?: boolean;
  isHome?: boolean;
}
