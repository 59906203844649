export const DAILY_PARTNERS_RC2 = {
  '1189': {
    roomUrl: 'https://tamtam.daily.co/room-partner-2023',
    id: 1189,
    name: 'Anlisa BV',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzIjoiNThiZDYxNTQtMjBhNi00YjIzLWExNDctMjY1ODk3NDRhNmI2IiwibSI6InRhbXRhbS9yb29tLXBhcnRuZXItMjAyMyIsImlhdCI6MTY5MzQ5NjA0MCwiZXhwIjoxNjkzNDk2MzQwfQ.5VKDn5AqH7q6UufGSKDCkwd7Tq7RLvzU_HruzcLTm54      ',
    fullUrl:
      'https://tamtam.daily.co/room-partner-2023?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzIjoiNThiZDYxNTQtMjBhNi00YjIzLWExNDctMjY1ODk3NDRhNmI2IiwibSI6InRhbXRhbS9yb29tLXBhcnRuZXItMjAyMyIsImlhdCI6MTY5MzQ5NjA0MCwiZXhwIjoxNjkzNDk2MzQwfQ.5VKDn5AqH7q6UufGSKDCkwd7Tq7RLvzU_HruzcLTm54',
  },
  '1323': {
    roomUrl: 'https://tamtam.daily.co/room-partner-2023',
    id: 1323,
    name: 'BBB',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMDk4IiwidSI6IkJCQiIsImlhdCI6MTYzODA5NDY3My40MjEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTA5OCJ9.Nlu9tIAR00iNPaSoB9Y9fh-JGLXRjDaOmUq9CqyGm_Y',
    fullUrl: 'https://tamtam.daily.co/room-partner-2023',
  },
  '1099': {
    roomUrl: 'https://tamtam.daily.co/room-partner-rc2-1099',
    id: 1099,
    name: 'BEPAY SRL',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMDk5IiwidSI6IkJFUEFZIFNSTCIsImlhdCI6MTYzODA5NDY3NS44MTEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTA5OSJ9.AVzg6zK8p4eaJpaB-g35nroD6Rk0bvtxpglVIMGFbhA',
    fullUrl:
      'https://tamtam.daily.co/room-partner-rc2-1099?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMDk5IiwidSI6IkJFUEFZIFNSTCIsImlhdCI6MTYzODA5NDY3NS44MTEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTA5OSJ9.AVzg6zK8p4eaJpaB-g35nroD6Rk0bvtxpglVIMGFbhA',
  },
  '1100': {
    roomUrl: 'https://tamtam.daily.co/room-partner-rc2-1100',
    id: 1100,
    name: 'Billit',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMTAwIiwidSI6IkJpbGxpdCIsImlhdCI6MTYzODA5NDY3My40MjQsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwMCJ9.udJzta5f5cvDWQLrGLvTnpvXJb7zUzQm4BYqym0ANgM',
    fullUrl:
      'https://tamtam.daily.co/room-partner-rc2-1100?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMTAwIiwidSI6IkJpbGxpdCIsImlhdCI6MTYzODA5NDY3My40MjQsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwMCJ9.udJzta5f5cvDWQLrGLvTnpvXJb7zUzQm4BYqym0ANgM',
  },
  '1101': {
    roomUrl: 'https://tamtam.daily.co/room-partner-rc2-1101',
    id: 1101,
    name: 'Billtobox',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMTAxIiwidSI6IkJpbGx0b2JveCIsImlhdCI6MTYzODA5NDY3NS44MTIsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwMSJ9.09a2o7js2a2H0roRtva-Bv9VA9tU6d7m7jUvQFEmcHI',
    fullUrl: 'https://tamtam.daily.co/room-partner-2023',
  },
  '1102': {
    roomUrl: 'https://tamtam.daily.co/room-partner-rc2-1102',
    id: 1102,
    name: 'Nicholas de Nil',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMTAyIiwidSI6Ik5pY2hvbGFzIGRlIE5pbCIsImlhdCI6MTYzODA5NDY3NS44MSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAyIn0.w96z_iQXYGN3hHCT3tXM6LV8VkY8XjjFRDAfrZ-v6Z0',
    fullUrl:
      'https://tamtam.daily.co/room-partner-rc2-1102?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXJjMi0xMTAyIiwidSI6Ik5pY2hvbGFzIGRlIE5pbCIsImlhdCI6MTYzODA5NDY3NS44MSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAyIn0.w96z_iQXYGN3hHCT3tXM6LV8VkY8XjjFRDAfrZ-v6Z0',
  },
};
export const DAILY_PARTNERS_V2 = {
  '1097': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1097',
    id: 1097,
    name: 'Anlisa BV',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTEwOTciLCJ1IjoiQW5saXNhIEJWIiwiaWF0IjoxNjM4NDA1NDQ2LjY3MSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMDk3In0.DpdpkmB9AD7mXQiFQ4JCND2mUlPhODnrqM8ZxuDIOHY',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1097?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTEwOTciLCJ1IjoiQW5saXNhIEJWIiwiaWF0IjoxNjM4NDA1NDQ2LjY3MSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMDk3In0.DpdpkmB9AD7mXQiFQ4JCND2mUlPhODnrqM8ZxuDIOHY',
  },
  '1098': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1098',
    id: 1098,
    name: 'BBB',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTEwOTgiLCJ1IjoiQkJCIiwiaWF0IjoxNjM4NDA1NDQ2LjUyNCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMDk4In0.5MKUD8_2a03iJI8XuE0hcBimiSOvK9UtKbjGG9Ln3iw',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1098?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTEwOTgiLCJ1IjoiQkJCIiwiaWF0IjoxNjM4NDA1NDQ2LjUyNCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMDk4In0.5MKUD8_2a03iJI8XuE0hcBimiSOvK9UtKbjGG9Ln3iw',
  },
  '1099': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1099',
    id: 1099,
    name: 'BEPAY SRL',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTEwOTkiLCJ1IjoiQkVQQVkgU1JMIiwiaWF0IjoxNjM4NDA1NDQ2LjczMiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMDk5In0.XWjRES88rMJcaRkqWvzted848NRUwuGlG1W0K-Js1G4',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1099?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTEwOTkiLCJ1IjoiQkVQQVkgU1JMIiwiaWF0IjoxNjM4NDA1NDQ2LjczMiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMDk5In0.XWjRES88rMJcaRkqWvzted848NRUwuGlG1W0K-Js1G4',
  },
  '1100': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1100',
    id: 1100,
    name: 'Billit',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDAiLCJ1IjoiQmlsbGl0IiwiaWF0IjoxNjM4NDA1NDQ2Ljc4OCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAwIn0.SuU7TEZL6A4UXQQ9sRvxTqGGqIw8-4g1hZdbq265qok',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1100?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDAiLCJ1IjoiQmlsbGl0IiwiaWF0IjoxNjM4NDA1NDQ2Ljc4OCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAwIn0.SuU7TEZL6A4UXQQ9sRvxTqGGqIw8-4g1hZdbq265qok',
  },
  '1101': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1101',
    id: 1101,
    name: 'Billtobox',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDEiLCJ1IjoiQmlsbHRvYm94IiwiaWF0IjoxNjM4NDA1NDQ3LjkzNCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAxIn0.D3k0iHZVf_YhYFWyGDQqIQKWQhGSkG-_Ema41hZ9T_4',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1101?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDEiLCJ1IjoiQmlsbHRvYm94IiwiaWF0IjoxNjM4NDA1NDQ3LjkzNCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAxIn0.D3k0iHZVf_YhYFWyGDQqIQKWQhGSkG-_Ema41hZ9T_4',
  },
  '1102': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1102',
    id: 1102,
    name: 'Bizz Control',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDIiLCJ1IjoiQml6eiBDb250cm9sIiwiaWF0IjoxNjM4NDA1NDQ3LjkxMSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAyIn0.nm5PwXaM2Q-rlHxTL7oIE705YMS-B_AUlJoJLQjevzM',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1102?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDIiLCJ1IjoiQml6eiBDb250cm9sIiwiaWF0IjoxNjM4NDA1NDQ3LjkxMSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTAyIn0.nm5PwXaM2Q-rlHxTL7oIE705YMS-B_AUlJoJLQjevzM',
  },
  '1103': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1103',
    id: 1103,
    name: 'BNP Paribas Fortis Factor',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDMiLCJ1IjoiQk5QIFBhcmliYXMgRm9ydGlzIEZhY3RvciIsImlhdCI6MTYzODQwNTQ0Ni43MjQsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwMyJ9.2GyoKiDRv7LveAE1cFe-he2STFdU94j-ribyxjrZRcU',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1103?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDMiLCJ1IjoiQk5QIFBhcmliYXMgRm9ydGlzIEZhY3RvciIsImlhdCI6MTYzODQwNTQ0Ni43MjQsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwMyJ9.2GyoKiDRv7LveAE1cFe-he2STFdU94j-ribyxjrZRcU',
  },
  '1104': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1104',
    id: 1104,
    name: 'Koalaboox a Cegid Company',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDQiLCJ1IjoiS29hbGFib294IGEgQ2VnaWQgQ29tcGFueSIsImlhdCI6MTYzODQwNTQ0OS4xNzYsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwNCJ9.h9V1i7eCGHi_vLQMxIEBkaN6wINyGkB-wOTaleN5_bQ',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1104?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDQiLCJ1IjoiS29hbGFib294IGEgQ2VnaWQgQ29tcGFueSIsImlhdCI6MTYzODQwNTQ0OS4xNzYsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwNCJ9.h9V1i7eCGHi_vLQMxIEBkaN6wINyGkB-wOTaleN5_bQ',
  },
  '1105': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1105',
    id: 1105,
    name: 'Exact Belgium',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDUiLCJ1IjoiRXhhY3QgQmVsZ2l1bSIsImlhdCI6MTYzODQwNTQ0OS4wODEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwNSJ9.HpMK-bk5ygJjhPniaAASsvclQGWRJRUMvNGHMXyyF6g',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1105?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDUiLCJ1IjoiRXhhY3QgQmVsZ2l1bSIsImlhdCI6MTYzODQwNTQ0OS4wODEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwNSJ9.HpMK-bk5ygJjhPniaAASsvclQGWRJRUMvNGHMXyyF6g',
  },
  '1106': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1106',
    id: 1106,
    name: 'Federatie Vrije Beroepen',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDYiLCJ1IjoiRmVkZXJhdGllIFZyaWplIEJlcm9lcGVuIiwiaWF0IjoxNjM4NDA1NDQ5LjUzNSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTA2In0.QbilR_qTSMxl7BkLRfeqxv4SpmdNHidF6BaVwDVJBNg',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1106?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDYiLCJ1IjoiRmVkZXJhdGllIFZyaWplIEJlcm9lcGVuIiwiaWF0IjoxNjM4NDA1NDQ5LjUzNSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTA2In0.QbilR_qTSMxl7BkLRfeqxv4SpmdNHidF6BaVwDVJBNg',
  },
  '1107': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1107',
    id: 1107,
    name: 'FID-Manager',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDciLCJ1IjoiRklELU1hbmFnZXIiLCJpYXQiOjE2Mzg0MDU0NDkuNTM4LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMDcifQ.f8ZfwYJpYUTmVOkAXCV8UVLKPOVJKnvo7s1k5kYxBVU',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1107?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDciLCJ1IjoiRklELU1hbmFnZXIiLCJpYXQiOjE2Mzg0MDU0NDkuNTM4LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMDcifQ.f8ZfwYJpYUTmVOkAXCV8UVLKPOVJKnvo7s1k5kYxBVU',
  },
  '1108': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1108',
    id: 1108,
    name: 'Hanna-Solutions',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDgiLCJ1IjoiSGFubmEtU29sdXRpb25zIiwiaWF0IjoxNjM4NDA1NDQ4LjE3LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMDgifQ.S3j8_jsRC-sFeqNPVgZbr0V8hqm0S-xFEsSmJUvF_1U',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1108?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDgiLCJ1IjoiSGFubmEtU29sdXRpb25zIiwiaWF0IjoxNjM4NDA1NDQ4LjE3LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMDgifQ.S3j8_jsRC-sFeqNPVgZbr0V8hqm0S-xFEsSmJUvF_1U',
  },
  '1109': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1109',
    id: 1109,
    name: 'Octopus Accountancy Software',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDkiLCJ1IjoiT2N0b3B1cyBBY2NvdW50YW5jeSBTb2Z0d2FyZSIsImlhdCI6MTYzODQwNTQ0OC40ODcsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwOSJ9.0qcs6k7RezqIHd6S1gGHg5zn7FakqnJKQbm1Hf86bos',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1109?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMDkiLCJ1IjoiT2N0b3B1cyBBY2NvdW50YW5jeSBTb2Z0d2FyZSIsImlhdCI6MTYzODQwNTQ0OC40ODcsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEwOSJ9.0qcs6k7RezqIHd6S1gGHg5zn7FakqnJKQbm1Hf86bos',
  },
  '1110': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1110',
    id: 1110,
    name: 'Intellifin',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTAiLCJ1IjoiSW50ZWxsaWZpbiIsImlhdCI6MTYzODQwNTQ0OS42NzksImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExMCJ9.S0hMFIRw9mG-czqb_iqUQWCFZrLkfJ9Qgl8tTykz7mY',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1110?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTAiLCJ1IjoiSW50ZWxsaWZpbiIsImlhdCI6MTYzODQwNTQ0OS42NzksImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExMCJ9.S0hMFIRw9mG-czqb_iqUQWCFZrLkfJ9Qgl8tTykz7mY',
  },
  '1111': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1111',
    id: 1111,
    name: 'Isabel',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTEiLCJ1IjoiSXNhYmVsIiwiaWF0IjoxNjM4NDA1NDUwLjAxNywiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTExIn0.OgGSSVngFmS-iwJtCRpXQeVQKaMO6AqZKHOrjKe_0UQ',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1111?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTEiLCJ1IjoiSXNhYmVsIiwiaWF0IjoxNjM4NDA1NDUwLjAxNywiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTExIn0.OgGSSVngFmS-iwJtCRpXQeVQKaMO6AqZKHOrjKe_0UQ',
  },
  '1112': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1112',
    id: 1112,
    name: 'Belgian Institute for Tax Advisors and Accountants',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTIiLCJ1IjoiQmVsZ2lhbiBJbnN0aXR1dGUgZm9yIFRheCBBZHZpc29ycyBhbmQgQWNjb3VudGFudHMiLCJpYXQiOjE2Mzg0MDU0NTAuMDIzLCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMTIifQ.r1o1E-dEuwMAJezAeWar6sQHGHVbqlmBc5Fp9LTgjO8',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1112?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTIiLCJ1IjoiQmVsZ2lhbiBJbnN0aXR1dGUgZm9yIFRheCBBZHZpc29ycyBhbmQgQWNjb3VudGFudHMiLCJpYXQiOjE2Mzg0MDU0NTAuMDIzLCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMTIifQ.r1o1E-dEuwMAJezAeWar6sQHGHVbqlmBc5Fp9LTgjO8',
  },
  '1113': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1113',
    id: 1113,
    name: 'Larcier Intersentia',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTMiLCJ1IjoiTGFyY2llciBJbnRlcnNlbnRpYSIsImlhdCI6MTYzODQwNTQ0Ny40NTEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExMyJ9.oR2MfO39Lhy9ZQU05kRBSG_B-1PDlRGzrh3DGGRxetQ',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1113?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTMiLCJ1IjoiTGFyY2llciBJbnRlcnNlbnRpYSIsImlhdCI6MTYzODQwNTQ0Ny40NTEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExMyJ9.oR2MfO39Lhy9ZQU05kRBSG_B-1PDlRGzrh3DGGRxetQ',
  },
  '1114': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1114',
    id: 1114,
    name: 'Liantis',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTQiLCJ1IjoiTGlhbnRpcyIsImlhdCI6MTYzODQwNTQ1MS4zMDEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExNCJ9.k1r59iDjbjVkn-pPViMBuqB36HgCqr0AqKnVyq-GOHk',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1114?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTQiLCJ1IjoiTGlhbnRpcyIsImlhdCI6MTYzODQwNTQ1MS4zMDEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExNCJ9.k1r59iDjbjVkn-pPViMBuqB36HgCqr0AqKnVyq-GOHk',
  },
  '1116': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1116',
    id: 1116,
    name: 'Partena Professional',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTYiLCJ1IjoiUGFydGVuYSBQcm9mZXNzaW9uYWwiLCJpYXQiOjE2Mzg0MDU0NDcuNTk0LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMTYifQ.AMeMukuZp5BogY9Qv-y8HjGfIy2fN4JVHFc2SDsj7zY',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1116?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTYiLCJ1IjoiUGFydGVuYSBQcm9mZXNzaW9uYWwiLCJpYXQiOjE2Mzg0MDU0NDcuNTk0LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMTYifQ.AMeMukuZp5BogY9Qv-y8HjGfIy2fN4JVHFc2SDsj7zY',
  },
  '1117': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1117',
    id: 1117,
    name: 'sage',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTciLCJ1Ijoic2FnZSIsImlhdCI6MTYzODQwNTQ0Ny4zNjksImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExNyJ9.PcYHcnUMexwVcmGrJyBEGWa-KFotF4BPe2Rmu1MSX54',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1117?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTciLCJ1Ijoic2FnZSIsImlhdCI6MTYzODQwNTQ0Ny4zNjksImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExNyJ9.PcYHcnUMexwVcmGrJyBEGWa-KFotF4BPe2Rmu1MSX54',
  },
  '1118': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1118',
    id: 1118,
    name: 'Sd Worx',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTgiLCJ1IjoiU2QgV29yeCIsImlhdCI6MTYzODQwNTQ0Ny40MjYsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExOCJ9.uJB8JbD-wIQXYNmwbYHq0qAGxA8WcsJOiKnZA4Euq5I',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1118?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTgiLCJ1IjoiU2QgV29yeCIsImlhdCI6MTYzODQwNTQ0Ny40MjYsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTExOCJ9.uJB8JbD-wIQXYNmwbYHq0qAGxA8WcsJOiKnZA4Euq5I',
  },
  '1119': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1119',
    id: 1119,
    name: 'SPF Finances',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTkiLCJ1IjoiU1BGIEZpbmFuY2VzIiwiaWF0IjoxNjM4NDA1NDQ3LjQ1OSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTE5In0.bTeFEQKTbn_5MTuGvtgn0AVIaaIDh5Eb_MzKa3UD9Zo',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1119?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMTkiLCJ1IjoiU1BGIEZpbmFuY2VzIiwiaWF0IjoxNjM4NDA1NDQ3LjQ1OSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTE5In0.bTeFEQKTbn_5MTuGvtgn0AVIaaIDh5Eb_MzKa3UD9Zo',
  },
  '1120': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1120',
    id: 1120,
    name: 'Silverfin',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjAiLCJ1IjoiU2lsdmVyZmluIiwiaWF0IjoxNjM4NDA1NDQ3LjM5NCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTIwIn0.zCC6MqTmTqpSCo3YhSF3zG-6R1TYPI9Gm7sDBYzkC6M',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1120?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjAiLCJ1IjoiU2lsdmVyZmluIiwiaWF0IjoxNjM4NDA1NDQ3LjM5NCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTIwIn0.zCC6MqTmTqpSCo3YhSF3zG-6R1TYPI9Gm7sDBYzkC6M',
  },
  '1121': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1121',
    id: 1121,
    name: 'Skwarel',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjEiLCJ1IjoiU2t3YXJlbCIsImlhdCI6MTYzODQwNTQ0Ny4zODMsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyMSJ9.T6IbQ1Ofzr9yba4A7JpcDqD0nK-liahTsHzLt-Cee7w',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1121?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjEiLCJ1IjoiU2t3YXJlbCIsImlhdCI6MTYzODQwNTQ0Ny4zODMsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyMSJ9.T6IbQ1Ofzr9yba4A7JpcDqD0nK-liahTsHzLt-Cee7w',
  },
  '1122': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1122',
    id: 1122,
    name: 'Sodalis',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjIiLCJ1IjoiU29kYWxpcyIsImlhdCI6MTYzODQwNTQ0OS41OSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTIyIn0.tMer93L44lfbGgooqw5a4XStdrv24M7HW83gshjQDl4',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1122?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjIiLCJ1IjoiU29kYWxpcyIsImlhdCI6MTYzODQwNTQ0OS41OSwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTIyIn0.tMer93L44lfbGgooqw5a4XStdrv24M7HW83gshjQDl4',
  },
  '1123': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1123',
    id: 1123,
    name: 'AdminPulse by Syneton',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjMiLCJ1IjoiQWRtaW5QdWxzZSBieSBTeW5ldG9uIiwiaWF0IjoxNjM4NDA1NDQ3LjQ2NiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTIzIn0.I_ufxw7KcBTeTVh91suROF1j2k-a0N38BYssD-04JEI',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1123?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjMiLCJ1IjoiQWRtaW5QdWxzZSBieSBTeW5ldG9uIiwiaWF0IjoxNjM4NDA1NDQ3LjQ2NiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTIzIn0.I_ufxw7KcBTeTVh91suROF1j2k-a0N38BYssD-04JEI',
  },
  '1124': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1124',
    id: 1124,
    name: 'TwinnTech SRL',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjQiLCJ1IjoiVHdpbm5UZWNoIFNSTCIsImlhdCI6MTYzODQwNTQ0OC41OTcsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyNCJ9.565cljkcSHgC-m6W4rkuAuzkSBKMHlz-MuQ0GMENHN8',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1124?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjQiLCJ1IjoiVHdpbm5UZWNoIFNSTCIsImlhdCI6MTYzODQwNTQ0OC41OTcsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyNCJ9.565cljkcSHgC-m6W4rkuAuzkSBKMHlz-MuQ0GMENHN8',
  },
  '1125': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1125',
    id: 1125,
    name: 'WinAuditor',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjUiLCJ1IjoiV2luQXVkaXRvciIsImlhdCI6MTYzODQwNTQ0Ny40NzgsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyNSJ9.l5j2y5JkyDnXY_lcRzWF0X2XncgiwySyrIBfcjG6CSk',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1125?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjUiLCJ1IjoiV2luQXVkaXRvciIsImlhdCI6MTYzODQwNTQ0Ny40NzgsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyNSJ9.l5j2y5JkyDnXY_lcRzWF0X2XncgiwySyrIBfcjG6CSk',
  },
  '1126': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1126',
    id: 1126,
    name: 'Winbooks',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjYiLCJ1IjoiV2luYm9va3MiLCJpYXQiOjE2Mzg0MDU0NDcuNDczLCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMjYifQ.29caCNW_BBs2Mxtoj6UkeaZSlbx3xB1Q_jFieJf2e2s',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1126?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjYiLCJ1IjoiV2luYm9va3MiLCJpYXQiOjE2Mzg0MDU0NDcuNDczLCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMjYifQ.29caCNW_BBs2Mxtoj6UkeaZSlbx3xB1Q_jFieJf2e2s',
  },
  '1127': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1127',
    id: 1127,
    name: 'Yuki',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjciLCJ1IjoiWXVraSIsImlhdCI6MTYzODQwNTQ0Ny40MjEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyNyJ9.F-Yc_3jrCbfPad_FUsoFfp_qgZ7UH3KVi-QlbIo6bA0',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1127?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjciLCJ1IjoiWXVraSIsImlhdCI6MTYzODQwNTQ0Ny40MjEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyNyJ9.F-Yc_3jrCbfPad_FUsoFfp_qgZ7UH3KVi-QlbIo6bA0',
  },
  '1128': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1128',
    id: 1128,
    name: 'YOOZ',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjgiLCJ1IjoiWU9PWiIsImlhdCI6MTYzODQwNTQ0Ny41MzEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyOCJ9.6L9eMMU26dl5xN4XB-l_ypDVKbpg_4abu35vKu430UA',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1128?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjgiLCJ1IjoiWU9PWiIsImlhdCI6MTYzODQwNTQ0Ny41MzEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyOCJ9.6L9eMMU26dl5xN4XB-l_ypDVKbpg_4abu35vKu430UA',
  },
  '1129': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1129',
    id: 1129,
    name: 'Visionplanner',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjkiLCJ1IjoiVmlzaW9ucGxhbm5lciIsImlhdCI6MTYzODQwNTQ0Ny40OTEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyOSJ9.VhL2SphezhkYF2ZHojZfPAp6zQrzllXgmaoJ9uw5298',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1129?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMjkiLCJ1IjoiVmlzaW9ucGxhbm5lciIsImlhdCI6MTYzODQwNTQ0Ny40OTEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEyOSJ9.VhL2SphezhkYF2ZHojZfPAp6zQrzllXgmaoJ9uw5298',
  },
  '1131': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1131',
    id: 1131,
    name: 'Forum for the future',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMzEiLCJ1IjoiRm9ydW0gZm9yIHRoZSBmdXR1cmUiLCJpYXQiOjE2Mzg0MDU0NDguNTExLCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMzEifQ.SEAfpCuuEwyBzUgksFSf5ixu_I8qUKyYeVuJaera7UA',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1131?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMzEiLCJ1IjoiRm9ydW0gZm9yIHRoZSBmdXR1cmUiLCJpYXQiOjE2Mzg0MDU0NDguNTExLCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExMzEifQ.SEAfpCuuEwyBzUgksFSf5ixu_I8qUKyYeVuJaera7UA',
  },
  '1133': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1133',
    id: 1133,
    name: 'Accounton',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMzMiLCJ1IjoiQWNjb3VudG9uIiwiaWF0IjoxNjM4NDA1NDQ5LjUsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEzMyJ9.3jOjOERCbEpPCZktWgUmHRf6ikb3m1cg6JFB87Yeo9E',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1133?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMzMiLCJ1IjoiQWNjb3VudG9uIiwiaWF0IjoxNjM4NDA1NDQ5LjUsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEzMyJ9.3jOjOERCbEpPCZktWgUmHRf6ikb3m1cg6JFB87Yeo9E',
  },
  '1139': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1139',
    id: 1139,
    name: 'Bothive',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMzkiLCJ1IjoiQm90aGl2ZSIsImlhdCI6MTYzODQwNTQ0Ny41NTgsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEzOSJ9.Ws5ipsT0B19lhb6uyESFn4MaEFdC8EXZ3Ev3bgV_BH8',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1139?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExMzkiLCJ1IjoiQm90aGl2ZSIsImlhdCI6MTYzODQwNTQ0Ny41NTgsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTEzOSJ9.Ws5ipsT0B19lhb6uyESFn4MaEFdC8EXZ3Ev3bgV_BH8',
  },
  '1140': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1140',
    id: 1140,
    name: 'Group S',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDAiLCJ1IjoiR3JvdXAgUyIsImlhdCI6MTYzODQwNTQ1MC4zNzMsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE0MCJ9.6wwPCukJf9o6ZyjqKhjzQm1qEUJ_ohucbbuKk4hUzy0',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1140?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDAiLCJ1IjoiR3JvdXAgUyIsImlhdCI6MTYzODQwNTQ1MC4zNzMsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE0MCJ9.6wwPCukJf9o6ZyjqKhjzQm1qEUJ_ohucbbuKk4hUzy0',
  },
  '1141': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1141',
    id: 1141,
    name: 'Penneo A/S',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDEiLCJ1IjoiUGVubmVvIEEvUyIsImlhdCI6MTYzODQwNTQ0OS43MTksImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE0MSJ9.DGwT8roCR2mHMWUV_i6U86yRo_8RHvgpPRg9uYpma6Y',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1141?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDEiLCJ1IjoiUGVubmVvIEEvUyIsImlhdCI6MTYzODQwNTQ0OS43MTksImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE0MSJ9.DGwT8roCR2mHMWUV_i6U86yRo_8RHvgpPRg9uYpma6Y',
  },
  '1142': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1142',
    id: 1142,
    name: 'Cloudbizz',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDIiLCJ1IjoiQ2xvdWRiaXp6IiwiaWF0IjoxNjM4NDA1NDQ4Ljg3MiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTQyIn0.h-voI3xohW1-AfwN8ZS_moPx-bdkXfODR4Bx6rxoc3g',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1142?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDIiLCJ1IjoiQ2xvdWRiaXp6IiwiaWF0IjoxNjM4NDA1NDQ4Ljg3MiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTQyIn0.h-voI3xohW1-AfwN8ZS_moPx-bdkXfODR4Bx6rxoc3g',
  },
  '1143': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1143',
    id: 1143,
    name: 'Allegro',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDMiLCJ1IjoiQWxsZWdybyIsImlhdCI6MTYzODQwNTQ1MC43ODUsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE0MyJ9.jiP08LGp_WsJx_cuNxObBQPv4kriZNgtFZbO5q6fzm0',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1143?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDMiLCJ1IjoiQWxsZWdybyIsImlhdCI6MTYzODQwNTQ1MC43ODUsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE0MyJ9.jiP08LGp_WsJx_cuNxObBQPv4kriZNgtFZbO5q6fzm0',
  },
  '1145': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1145',
    id: 1145,
    name: 'Acerta',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDUiLCJ1IjoiQWNlcnRhIiwiaWF0IjoxNjM4NDA1NDUwLjcyOCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTQ1In0.RkGXr-2zFwddA6gq0nxUcLxsJ6Ua2ZjNaoIwyZY49zM',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1145?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNDUiLCJ1IjoiQWNlcnRhIiwiaWF0IjoxNjM4NDA1NDUwLjcyOCwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTQ1In0.RkGXr-2zFwddA6gq0nxUcLxsJ6Ua2ZjNaoIwyZY49zM',
  },
  '1152': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1152',
    id: 1152,
    name: 'East Accountancy',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTIiLCJ1IjoiRWFzdCBBY2NvdW50YW5jeSIsImlhdCI6MTYzODQwNTQ0OS43MDYsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE1MiJ9.Bldj8wfYQFySXVbt_D5Pj_fxa2q8rumPONdEzbt2gtE',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1152?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTIiLCJ1IjoiRWFzdCBBY2NvdW50YW5jeSIsImlhdCI6MTYzODQwNTQ0OS43MDYsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE1MiJ9.Bldj8wfYQFySXVbt_D5Pj_fxa2q8rumPONdEzbt2gtE',
  },
  '1156': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1156',
    id: 1156,
    name: 'LVAB',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTYiLCJ1IjoiTFZBQiIsImlhdCI6MTYzODQwNTQ0OS45NjEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE1NiJ9.KNwhmilqtpIxXWKLNigW6XmWZQZjlMvo2S7KnCEmHhI',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1156?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTYiLCJ1IjoiTFZBQiIsImlhdCI6MTYzODQwNTQ0OS45NjEsImQiOiIzZTc1N2Q2MC04YjkyLTQzNTAtOGI1NS03Y2JkYWZjMzc3NzQiLCJvIjp0cnVlLCJ1ZCI6InBhcnRuZXItYWRtaW4tMTE1NiJ9.KNwhmilqtpIxXWKLNigW6XmWZQZjlMvo2S7KnCEmHhI',
  },
  '1157': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1157',
    id: 1157,
    name: 'East Accountancy ASBL',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTciLCJ1IjoiRWFzdCBBY2NvdW50YW5jeSBBU0JMIiwiaWF0IjoxNjM4NDA1NDQ5Ljk3LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExNTcifQ.mOR0C1LdGA80nwtXug_u49yeRibXqf59vNKpVIig3Ws',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1157?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTciLCJ1IjoiRWFzdCBBY2NvdW50YW5jeSBBU0JMIiwiaWF0IjoxNjM4NDA1NDQ5Ljk3LCJkIjoiM2U3NTdkNjAtOGI5Mi00MzUwLThiNTUtN2NiZGFmYzM3Nzc0IiwibyI6dHJ1ZSwidWQiOiJwYXJ0bmVyLWFkbWluLTExNTcifQ.mOR0C1LdGA80nwtXug_u49yeRibXqf59vNKpVIig3Ws',
  },
  '1158': {
    roomUrl: 'https://tamtam.daily.co/room-partner-v2-1158',
    id: 1158,
    name: 'OkiOki',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTgiLCJ1IjoiT2tpT2tpIiwiaWF0IjoxNjM4NDA1NDUzLjc4MiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTU4In0.kXAcSwk-SoJ_lGb8cslG35hpzv-P89pt4CgdyC0SksM',
    fullUrl:
      'https://tamtam.daily.co/room-partner-v2-1158?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoicm9vbS1wYXJ0bmVyLXYyLTExNTgiLCJ1IjoiT2tpT2tpIiwiaWF0IjoxNjM4NDA1NDUzLjc4MiwiZCI6IjNlNzU3ZDYwLThiOTItNDM1MC04YjU1LTdjYmRhZmMzNzc3NCIsIm8iOnRydWUsInVkIjoicGFydG5lci1hZG1pbi0xMTU4In0.kXAcSwk-SoJ_lGb8cslG35hpzv-P89pt4CgdyC0SksM',
  },
};
