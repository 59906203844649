import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './CollaboratorLayout.module.scss';

interface Props {
  packs: any;
  defaultPlan: any;
  onChange: (packName: any) => void;
}

export const CollaboratorPackFilter = ({
  packs,
  onChange,
  defaultPlan,
}: Props) => {
  const [filter, setFilter] = useState(defaultPlan);
  const handleChangeTabFilter = (packName: any) => {
    onChange(packName);
    setFilter(packName);
  };
  const FilterTabs = () => {
    return Object.keys(packs).map((key) => (
      <div className={styles.customFilter_element} key={packs[key].nameFr}>
        <div
          key={packs[key].nameFr}
          style={{
            color: filter == packs[key].nameFr ? '#F7953E' : '#6D7F92',
          }}
          onClick={() => handleChangeTabFilter(packs[key].nameFr)}
        >
          <div className={styles.customFilter_tab_name}>
            {packs[key].nameFr}
          </div>
          <div
            className={styles.customFilter_border}
            style={{
              background: filter == packs[key].nameFr ? '#F7953E' : '#B2BCC6',
            }}
          />
        </div>
      </div>
    ));
  };
  return <div className={styles.customFilter}>{FilterTabs()}</div>;
};

export default CollaboratorPackFilter;
