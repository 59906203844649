import { appMetadata } from 'config';
import { useAppInfo } from 'hooks/useAppInfo';
import { useLanguage } from 'hooks/useLanguage';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { getDefaultMataData } from './services';

type MetaType = JSX.IntrinsicElements['meta'];

interface Props {
  title?: string;
  titleSeparator?: string;
  description?: string;
  meta?: MetaType[];
}

export const SEO = ({
  title,
  description = '',
  meta = [],
  titleSeparator = '|',
}: Props) => {
  const lang = useLanguage();
  const { id: appId } = useAppInfo();
  const defaultMetaData = useMemo(() => getDefaultMataData(appId), [appId]);

  const { title: appTitle, description: appDescription, iconsUrl } =
    defaultMetaData || {};

  return (
    <Helmet
      title={title}
      titleTemplate={`${appTitle} ${titleSeparator} %s`}
      defaultTitle={appTitle}
      htmlAttributes={{ lang }}
      meta={[
        {
          name: `description`,
          content: isEmpty(description) ? appDescription : description,
        },
        ...meta,
      ]}
      link={[
        {
          rel: 'icon',
          href: iconsUrl?.favicon,
        },
        {
          rel: 'apple-touch-icon',
          href: iconsUrl?.logo192,
        },
      ]}
    />
  );
};

export default SEO;
