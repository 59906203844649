import { map } from 'lodash';
import {
  ACCEPT_REGISTRATION_GUESTS_FULFILLED,
  ACCEPT_REGISTRATION_GUESTS_PENDING,
  ACCEPT_REGISTRATION_GUESTS_REJECTED,
  DECLINE_REGISTRATION_GUESTS_FULFILLED,
  DECLINE_REGISTRATION_GUESTS_PENDING,
  DECLINE_REGISTRATION_GUESTS_REJECTED,
  FETCH_GUEST_DATA_FULFILLED,
  FETCH_GUEST_DATA_PENDING,
  FETCH_GUEST_DATA_REJECTED,
  FETCH_GUESTS_FULFILLED,
  FETCH_GUESTS_PENDING,
  FETCH_GUESTS_REJECTED,
  GuestActionTypes,
  GuestState,
  RESET_GUESTS,
  SAVE_GUEST_DATA_FULFILLED,
  SAVE_GUEST_DATA_PENDING,
  SAVE_GUEST_DATA_REJECTED,
  CHECK_COUPON_FULFILLED,
  DELETE_ADDRESS_FULFILLED,
  GENERATE_BADGE_PENDING,
  GENERATE_BADGE_FULFILLED,
  SAVE_GUEST_FULFILLED,
  SAVE_GUEST_PENDING,
  SAVE_GUEST_REJECTED,
  IMPORT_GUESTS_FULFILLED,
  IMPORT_GUESTS_PENDING,
  IMPORT_GUESTS_REJECTED,
  FETCH_PREMIUM_GUEST_PENDING,
  FETCH_PREMIUM_GUEST_FULFILLED,
  FETCH_PREMIUM_GUEST_REJECTED,
  FETCH_USER_PREMIUM_STATUS_FULFILLED,
  REGISTER_PREMIUM_TO_EVENT_FULFILLED,
  UPDATE_ADDRESS_FULFILLED,
  UPDATE_ADDRESS_PENDING,
  UPDATE_ADDRESS_REJECTED,
} from './types';

const initialState = {
  fetching: false,
  processing: false,
  fetched: false,
  items: [],
  coupons: [],
  checkedCoupon: null,
  premiumGuestFetching: false,
  premiumGuestFetched: false,
  premiumGuest: null,
  premiumGuestStatus: 0,
  addressUpdating: false,
  addressUpdated: false,
  error: null,
  nbResult: 0,
};

export const reducer = (
  state: GuestState = initialState,
  action: GuestActionTypes,
): GuestState => {
  switch (action.type) {
    case RESET_GUESTS: {
      return initialState;
    }
    case FETCH_GUEST_DATA_PENDING:
    case FETCH_GUESTS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_PREMIUM_GUEST_PENDING: {
      return {
        ...state,
        premiumGuestFetched: false,
        premiumGuestFetching: true,
      };
    }
    case FETCH_GUEST_DATA_FULFILLED:
    case FETCH_GUESTS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        coupons: data?.[0]?.couponsDetails
          ? map(data?.[0]?.couponsDetails, (coupon) => coupon)
          : [],
        nbResult: nbResult,
      };
    }
    case FETCH_PREMIUM_GUEST_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        premiumGuestFetched: true,
        premiumGuestFetching: false,
        premiumGuest: data,
      };
    }
    case FETCH_PREMIUM_GUEST_REJECTED: {
      return {
        ...state,
        premiumGuestFetched: false,
        premiumGuestFetching: false,
        premiumGuest: null,
      };
    }
    case SAVE_GUEST_DATA_PENDING:
    case ACCEPT_REGISTRATION_GUESTS_PENDING:
    case DECLINE_REGISTRATION_GUESTS_PENDING:
    case SAVE_GUEST_PENDING:
    case IMPORT_GUESTS_PENDING: {
      return {
        ...state,
        processing: true,
      };
    }
    case SAVE_GUEST_DATA_REJECTED:
    case ACCEPT_REGISTRATION_GUESTS_REJECTED:
    case DECLINE_REGISTRATION_GUESTS_REJECTED:
    case SAVE_GUEST_REJECTED:
    case IMPORT_GUESTS_REJECTED: {
      return {
        ...state,
        processing: false,
      };
    }
    case SAVE_GUEST_DATA_FULFILLED:
    case ACCEPT_REGISTRATION_GUESTS_FULFILLED:
    case DECLINE_REGISTRATION_GUESTS_FULFILLED:
    case SAVE_GUEST_FULFILLED:
    case IMPORT_GUESTS_FULFILLED:
    case REGISTER_PREMIUM_TO_EVENT_FULFILLED: {
      const { data } = action.payload;

      if (data) {
        return {
          ...state,
          processing: false,
          error: null,
          items: [data],
          coupons: data?.couponsDetails
            ? map(data?.couponsDetails, (coupon) => coupon)
            : [],
          nbResult: 1,
        };
      }

      return {
        ...state,
        processing: false,
      };
    }
    case CHECK_COUPON_FULFILLED: {
      const { data } = action.payload;

      if (data) {
        const coupons = [...state.coupons].filter(
          (item) => item.code != data.code,
        );

        return {
          ...state,
          coupons: [...coupons, data],
          checkedCoupon: data,
        };
      }

      return state;
    }
    case DELETE_ADDRESS_FULFILLED: {
      const { data } = action.payload;
      const guest = state.items[0];

      if (guest) {
        return {
          ...state,
          items: [{ ...guest, invoicingData: data ?? [] }],
        };
      }

      return state;
    }

    case GENERATE_BADGE_PENDING: {
      return {
        ...state,
        processing: true,
      };
    }

    case GENERATE_BADGE_FULFILLED: {
      const { data } = action.payload;
      const guest = state.items[0];

      return {
        ...state,
        processing: false,
        fetched: true,
        error: null,
        items: [
          {
            ...guest,
            badgeUrl: data.fileName,
            badgePrintingDate: data.printingDate,
          },
        ],
      };
    }
    case FETCH_GUEST_DATA_REJECTED:
    case FETCH_GUESTS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        coupons: [],
        items: [],
        nbResult: 0,
      };
    }
    case FETCH_USER_PREMIUM_STATUS_FULFILLED: {
      const { data: status } = action.payload;
      return {
        ...state,
        premiumGuestStatus: status,
      };
    }
    case UPDATE_ADDRESS_PENDING: {
      return {
        ...state,
        addressUpdated: false,
        addressUpdating: true,
      };
    }
    case UPDATE_ADDRESS_FULFILLED: {
      const { data } = action.payload;
      const guest = state.items[0];

      if (guest) {
        return {
          ...state,
          items: [{ ...guest, invoicingData: data ?? [] }],
          addressUpdated: true,
          addressUpdating: false,
        };
      }

      return state;
    }
    case UPDATE_ADDRESS_REJECTED: {
      return {
        ...state,
        addressUpdating: false,
        addressUpdated: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
