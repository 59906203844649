import React, { useRef, useState } from 'react';
import s from './PlanOption.module.scss';
import cn from 'classnames';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { Price } from '../PriceLabel/PriceLabel';
import t from 'i18n';
import { isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';

interface Props {
  title: string;
  description: string;
  price: number;
  originalPrice?: number;
  imgUrl?: string;
  isChecked: boolean;
  isSoldOut?: boolean;
  onCheck: (checked: boolean) => void;
}

const PlanOption = ({
  title,
  description,
  price,
  originalPrice,
  imgUrl,
  isChecked,
  isSoldOut,
  onCheck,
}: Props) => {
  const [isDescExpanded, setIsDescExpanded] = useState<boolean>(false);
  const showDescription = !isEmpty(description);
  const descRef = useRef<HTMLDivElement>(null);
  const descCharLength =
    descRef.current?.textContent?.replace(/ +/g, ' ')?.length ?? 0;

  const handleCheckOption = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (isSoldOut) return;
    onCheck(checked);
  };

  const renderPriceLayout = () => {
    const render = [];

    if (originalPrice && price !== originalPrice) {
      render.push(
        <Price
          size={'medium'}
          price={originalPrice}
          background={'#FFDAE0'}
          className={cn(s.priceOff, 'm-r-xs')}
          style={{ padding: '0.25rem 0.75rem' }}
        />,
      );
    }

    if (price != 0) {
      render.push(
        <Price
          size={'medium'}
          price={price}
          background={isChecked ? '#C7E8FE' : ''}
          style={{ padding: '0.25rem 0.75rem' }}
        />,
      );
    }

    if (render.length > 0) {
      return <div className="m-b-s">{render}</div>;
    }

    return null;
  };

  const renderCheckBox = () => {
    return (
      <label className={cn(s.checkbox, isChecked && s.checked)}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckOption}
        />
        {isChecked && <CheckMarkIcon fill={'#fff'} width={12} />}
      </label>
    );
  };

  // const backgroundImage = imgUrl
  //   ? getCroppedImageUrl(imgUrl, undefined, 500 * 2, false)
  //   : imgUrl;

  return (
    <div className={s.option_wrapper}>
      <div
        className={cn(
          s.option_content,
          isChecked && s.option_active,
          isSoldOut && s.option_sold_out,
        )}
      >
        <div className="grid-x">
          <div className="cell small-3">
            {/* <img
              className={s.option_image}
              src={imgUrl}
              alt="Plan option image"
            /> */}
            <div
              className={s.option_image}
              style={{ backgroundImage: `url(${imgUrl ?? '/img/option.jpg'})` }}
            />
          </div>
          <div className="cell small-8 p-x-s">
            {renderPriceLayout()}
            <h3>{title}</h3>
            {showDescription && (
              <>
                <div
                  ref={descRef}
                  className={cn(!isDescExpanded && s.custom_details)}
                >
                  <Markdown>
                    {description.length > 90 && !isDescExpanded
                      ? description.substr(0, 89).concat('...')
                      : description}
                  </Markdown>
                </div>
                {descCharLength > 90 && (
                  <div
                    className={cn(s.more_details, 'm-t-s')}
                    onClick={() => setIsDescExpanded(!isDescExpanded)}
                  >
                    <span className="m-r-xs">
                      {isDescExpanded ? t('less_details') : t('more_details')}
                    </span>
                    <ChevronUpIcon
                      className={cn('m-r-s', !isDescExpanded && 'rl180')}
                      width="10px"
                      fill="#6d7f92"
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="cell small-1 align-self-middle">
            {renderCheckBox()}
          </div>
        </div>
      </div>
      {isSoldOut && <div className={cn(s.sold_out)}>{t('sold out')}</div>}
    </div>
  );
};

export default PlanOption;
