import {
  FETCH_TAGS,
  FETCH_EVENT_WITH_TAGS,
  UPDATE_EVENT_TAGS,
  TagsActionTypes,
} from './types';

export const fetchTags = (resp: any): { payload: any; type: string } => ({
  type: FETCH_TAGS,
  payload: resp,
});

export const fetchEventWithTags = (
  resp: any,
): { payload: any; type: string } => ({
  type: FETCH_EVENT_WITH_TAGS,
  payload: resp,
});

export const updateEventTags = (resp: any): { payload: any; type: string } => ({
  type: UPDATE_EVENT_TAGS,
  payload: resp,
});

export default {
  fetchTags,
  fetchEventWithTags,
  updateEventTags,
};
