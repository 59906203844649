import React, { memo } from 'react';
import styles from './GoBack.module.scss';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left-gray.svg';
import classNames from 'classnames';
import { LayoutTheme } from 'components/Layout/services';
import _ from 'i18n';
import cn from 'classnames';
interface Props {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  theme?: LayoutTheme;
  hideIcon?: boolean;
  text?: string;
}

export default memo(function GoBack({
  disabled,
  className,
  hideIcon,
  text,
  theme = '',
  onClick,
}: Props) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClick?.();
  };
  return (
    <button
      className={classNames(className, styles.goBack)}
      onClick={handleClick}
      disabled={disabled}
    >
      {!hideIcon && <ChevronLeftIcon width="18" height="15" />}
      <span className={cn(!hideIcon && 'm-l-xs')}>{text ?? _('Back')}</span>
    </button>
  );
});
