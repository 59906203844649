import React, { memo } from 'react';
import s from './ModalConfirmation.module.scss';
import cn from 'classnames';
import _ from 'i18n';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import Markdown from 'markdown-to-jsx';

// TODO : This component needs more functionalities to be more generic.
/* List of improvements :
  - customization of the actions 'cancel' and 'confirm' (text, links ...) 
*/

interface Props {
  modal: ModalState<{
    message: string;
    subtext?: string;
    childComp?: React.ReactNode;
    successBtn?: string;
  }>;
  onCloseModal: () => void;
}

const customModalStyles = {
  content: {
    backgroundColor: '#F8F9FA',
    border: 'none',
    overflow: 'none',
    padding: '2rem',
    top: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    width: '80%',
    height: 'fit-content',
  },
};

export default memo(function ModalConfirmation({ modal, onCloseModal }: Props) {
  const {
    data: { message, subtext, childComp, successBtn },
    onSuccess,
  } = modal;

  const handleConfirmation = () => {
    onSuccess && onSuccess();
    onCloseModal();
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div>
        <CloseModal onClose={onCloseModal} />
        <div className={s.message}>
          <Markdown>{message}</Markdown>
          <br />
          <Markdown>{subtext || ''}</Markdown>
        </div>
        {childComp}
        <div className="flex-container align-justify">
          <button className={cn(s.action_btn, s.cancel)} onClick={onCloseModal}>
            {_('Cancel')}
          </button>
          <button
            className={cn(s.action_btn, s.confirm)}
            onClick={handleConfirmation}
          >
            {successBtn ? successBtn : _('reConfirmer')}
          </button>
        </div>
      </div>
    </Modal>
  );
});
