import React, { useState } from 'react';
import s from '../StepsSummary.module.scss';
import cn from 'classnames';
import _ from 'i18n';
import { useUser } from 'hooks/useUser';
import { useLanguage } from 'hooks/useLanguage';
import Avatar, { getUserAvatarUrl } from 'components/ui/Avatar';
import { StepTitle } from 'components/Tools/Summary/StepTitle';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-up.svg';
import { formatDateFromTo } from 'utils';
import EditButton from 'components/ui/EditButton';
import { Language } from 'store/types';
import { MAP_LANGUAGES } from 'config';
import { capitalize, isEmpty, lowerCase } from 'lodash';
import { SummaryBlock } from 'components/Tools/SummaryBlocks/SummaryBlock';
import { Theme } from 'components/Layout/AdvancedLayout/ThemeProvider';

interface Props {
  userData: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    birthday?: string;
    organization?: string;
    profession?: string;
    language?: Language;
    role?: string;
  };
  hasTitle?: boolean;
  className?: string;
  fields?: string[];
  onEdit?: () => void;
  theme?: Theme;
}

export const UserSummary = ({
  userData: {
    firstName,
    lastName,
    email,
    phone,
    birthday,
    organization,
    profession,
    language: userLanguage,
  },
  hasTitle = true,
  fields = ['PHONE', 'BIRTHDAY', 'ORGANIZATION', 'PROFESSION', 'LANGUAGE'],
  className,
  theme,
  onEdit,
}: Props) => {
  const user = useUser();
  const language = useLanguage();
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);

  if (!user) {
    return null;
  }

  const renderUserInfos = (field: string) => {
    switch (field) {
      case 'PHONE':
        return (
          <div key="PHONE">
            {_('Phone.')} : <strong>{phone}</strong>
          </div>
        );
      case 'BIRTHDAY':
        if (isEmpty(birthday)) {
          return null;
        }
        return (
          <div key="BIRTHDAY">
            {_('Date of birth')} :{' '}
            <strong>
              {birthday && formatDateFromTo(birthday, '', language, 'l')}
            </strong>
          </div>
        );
      case 'ORGANIZATION':
        return (
          <div key="ORGANIZATION" className="greetings">
            {_('Organization')} : <strong>{organization}</strong>
          </div>
        );
      case 'PROFESSION':
        return (
          <div key="PROFESSION" className="greetings">
            {_('Profession')} : <strong>{profession}</strong>
          </div>
        );
      case 'LANGUAGE':
        if (!userLanguage) {
          return null;
        }
        return (
          <div key="LANGUAGE" className="greetings">
            {_('Language')} :{' '}
            <strong>
              {capitalize(MAP_LANGUAGES[lowerCase(userLanguage)])}
            </strong>
          </div>
        );
    }

    return null;
  };

  return (
    <div className={cn('m-b-s', className)}>
      {hasTitle && <SummaryBlock theme={theme} title={_('Personal data')} />}
      <div
        className="flex-container align-justify"
        style={{ paddingLeft: '1.1rem' }}
      >
        <div className="flex-container" style={{ overflow: 'hidden' }}>
          <Avatar
            url={getUserAvatarUrl(user)}
            firstName={user.firstName}
            lastName={user.lastName}
            width="38px"
            height="38px"
            fontSize="1.25rem"
          />
          <div className="m-x-s">
            <h3 className={cn(s.big_text, 'm-b-0')}>
              {`${firstName ?? ''} ${lastName ?? ''}`.trim()}
            </h3>
            <div className={s.text}>{email}</div>
            {isInfoExpanded && (
              <div className={cn(s.text, 'm-t-s')}>
                {fields.map((field) => renderUserInfos(field))}
              </div>
            )}
          </div>
        </div>
        <div>
          <span
            className={cn(
              s.circle_btn,
              s.arrow,
              isInfoExpanded && s.arrow_active,
            )}
            onClick={() => setIsInfoExpanded(!isInfoExpanded)}
          >
            <ChevronIcon fill="#6D7F92" width="10px" className={'rl180'} />
          </span>
          {isInfoExpanded && onEdit && (
            <EditButton className="m-t-xs" onClick={onEdit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSummary;
