import React, { Fragment, useEffect, useState } from 'react';
import t from 'i18n';
import { CompactEventCard } from 'components/EventCard';
import { TTP_API_URL } from 'config';
import { Event } from 'store/Events/types';
import { FetchResourceThunkParams } from 'store/Resource/thunks';
import { ResourceState } from 'store/Resource/types';
import { Language } from 'store/types';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down.svg';
import Button from 'components/ui/Button';
import { SingleValueFilter } from 'services/Filter';
import { isEmpty } from 'lodash';

interface Props {
  userId?: number;
  currentEvent: Event;
  language: Language;
  eventsResource: ResourceState<Event>;
  fetchEvents: (params: FetchResourceThunkParams) => void;
  resetEvents: () => void;
}

// TODO : Add mobile case using react-responsive

const SimilarEvents = ({
  userId,
  currentEvent,
  language,
  eventsResource: { fetching, items: events, nbResult: totalEvents },
  fetchEvents,
  resetEvents,
}: Props) => {
  const [page, setPage] = useState(1);
  const [filteredEvents, setFilteredEvents] = useState(events);
  const hasMore = filteredEvents.length < totalEvents;
  const { tag: tags, id: currentEventId } = currentEvent;

  useEffect(() => {
    if (!isEmpty(tags)) {
      fetchEvents({
        name: 'LIST_FOR_USER',
        requestUrl: `${TTP_API_URL}/event/event/get-list-for-user`,
        sort: [{ property: 'startDateTime', dir: 'ASC' }],
        fields: [
          '*',
          'speakers-abstract',
          'user-registered',
          'client',
          'tag',
          'slotReplayUrls',
        ],
        filters: [new SingleValueFilter('id', 'neq', currentEventId)],
        page,
        pageSize: 9,
        queryParams: {
          userId: userId,
          communityIds: 9,
          language,
          tags: tags.map(({ id }) => id).join(','),
        },
      });
    }

    return resetEvents;
  }, [language, page, userId, tags, currentEventId, fetchEvents, resetEvents]);

  useEffect(() => {
    setPage(1);
    setFilteredEvents([]);
    resetEvents();
  }, [currentEvent, resetEvents]);

  useEffect(() => {
    setFilteredEvents([...filteredEvents, ...events]);
  }, [events]);

  const loadMore = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };

  if (!fetching && !filteredEvents.length) {
    return (
      <div
        className="align-center-middle flex-container small-12"
        style={{ height: '5rem', fontSize: '1rem', color: '#b2bcc6' }}
      >
        {t('No result found')}
      </div>
    );
  }

  return (
    <div className="m-t-xl">
      <div className="grid-x">
        {filteredEvents.map((event, index) => (
          <div className="cell small-12 medium-6 large-4 m-b-xxl" key={index}>
            <CompactEventCard event={event} language={language} />
          </div>
        ))}
        {fetching &&
          [1, 2, 3].map((index) => (
            <div className="cell small-4" key={index}>
              <CompactEventCard.Fetching key={index} />
            </div>
          ))}
      </div>
      {hasMore && (
        <div className="flex-container align-center m-t-xl">
          <Button
            title="See more"
            variant="outlined"
            color="slateGrey"
            onClick={loadMore}
            endIcon={<ChevronIcon />}
          />
        </div>
      )}
    </div>
  );
};

export default SimilarEvents;
