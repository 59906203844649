import React, { memo } from 'react';
import s from './SubMenu.module.scss';
import _ from 'i18n';
import cn from 'classnames';
import TTNavComponent from './TTNavComponent';
import { URLS } from 'router';
import { ReactComponent as MySpaceIcon } from 'assets/icons/channels.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/cil_education.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import { TTP_EMBLEM_OFFFCOURSE_URL } from 'config';

interface Props {
  innerRef?: React.Ref<any>;
}

export default memo(function WatchSubMenu({ innerRef }: Props) {
  return (
    <div id="watch-sub-menu" className={s.watch_submenu} ref={innerRef}>
      <ul>
        <div className={s.left_side}>
          <li className={s.emblem}>
            <img src={TTP_EMBLEM_OFFFCOURSE_URL} alt="logo-emblem" />
          </li>
          <li className={s.nav}>
            <TTNavComponent
              label={_('Trainings')}
              url={URLS.watch.trainings}
              icon={() => (
                <EducationIcon width="20" height="20" fill="#C7E8FE" />
              )}
              exact={true}
            />
          </li>
          <li className={s.nav}>
            <TTNavComponent
              label={_('my List')}
              url={URLS.watch.favorites}
              icon={() => <MySpaceIcon width="20" height="20" fill="#C7E8FE" />}
              exact={true}
            />
          </li>
        </div>
        <div className={s.right_side}>
          <li className={s.nav_btn}>
            <div className={cn(s.label_btn, s.redOrange)}>
              <CheckMarkIcon width={10} height={10} fill="#fff" />
              <span className="m-l-xs">{_('Premium shared')}</span>
            </div>
          </li>
        </div>
      </ul>
    </div>
  );
});
