import React, { useState } from 'react';
import s from './OrganizationOption.module.scss';
import cn from 'classnames';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import _ from 'i18n';
import RoundedButton from 'components/ui/RoundedButton/RoundedButton';
import { Organization } from 'interfaces/Organization';
import { getByLanguage, getCommunityLogoUrl, getLanguagesLabel } from 'utils';
import { Language, Organization as OrganizationType } from 'store/types';
import { upperFirst } from 'lodash';
import { getLegalFormLabel } from '../../../services';

interface Props {
  organization: Organization;
  hasRadioBtn?: boolean;
  isSelected: boolean;
  language: Language;
  onSelect: (organization: Organization | null) => void;
  onEdit: (organization: Organization) => void;
}

const OrganizationOption = ({
  organization,
  hasRadioBtn = true,
  isSelected,
  language,
  onSelect,
  onEdit,
}: Props) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(false);
  const {
    name,
    uen,
    abbreviation,
    website,
    tva,
    billingAddress,
    billingZipCode,
    billingCity,
  } = organization;
  const address = `${organization.address1} - ${organization.zipCode} - ${organization.country}`;
  const logoUrl = getCommunityLogoUrl(organization as OrganizationType);
  const languages = getLanguagesLabel(organization.languagePreferences);
  const legalForm = getLegalFormLabel(organization.legalForm);
  const sector = upperFirst(
    getByLanguage(organization.sector, 'title', language)?.toLowerCase(),
  );

  const renderInfo = (data: Record<string, string | number>) => {
    return (
      <div className={s.info}>
        {Object.entries(data).map(([key, value], index) => (
          <div key={index}>
            {_(key)}: <strong>{value}</strong>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={cn(
        s.option_wrapper,
        (isSelected || isDetailsExpanded) && s.active,
      )}
    >
      <div className="grid-x">
        <div className="cell small-3">
          <img
            className={s.option_image}
            src={logoUrl}
            alt="Organization Logo"
          />
        </div>
        <div className={cn('cell auto p-t-s', s.option_right)}>
          <h3>{name}</h3>
          <div className="m-y-s">
            {renderInfo({
              'Billing address': !!billingAddress ? billingAddress : '-',
              'org.inscription.zip': billingZipCode ?? '-',
              City: !!billingCity ? billingCity : '-',
            })}
          </div>
        </div>
        {hasRadioBtn && (
          <div
            className="cell small-2 p-t-s flex-container align-right"
            onClick={() => onSelect(organization)}
          >
            <div>
              <div className="flex-container align-middle">
                <span className={cn(s.radio_label, isSelected && s.active)}>
                  {_('Choose')}
                </span>
                <span className={cn(s.radio_button, isSelected && s.active)} />
              </div>
            </div>
          </div>
        )}
      </div>
      {isDetailsExpanded && (
        <div>
          <div className="grid-x m-y-s">
            <div className={cn('cell small-6 p-x-xl', s.right_divider)}>
              {renderInfo({
                'org.inscription.uen': uen,
                'org.inscription.abbr': !!abbreviation ? abbreviation : '-',
                'org.inscription.url': !!website ? website : '-',
                'org.inscription.address': address,
              })}
            </div>
            <div className="cell small-6 p-x-xl">
              {renderInfo({
                'org.inscription.lf': legalForm,
                'org.inscription.sector': !!sector ? sector : '-',
                'org.inscription.vat': _(tva),
                'org.inscription.language': languages ?? '-',
              })}
            </div>
          </div>
          <RoundedButton
            value={_('Edit organization data')}
            size={1}
            textStyle={{
              size: '12px',
            }}
            bgColors={{ default: 'transparent' }}
            //outlineStyle={{ color: '#D8DDE2' }}
            icon={<EditIcon fill="#6D7F92" width="11px" height="11px" />}
            className={cn('m-l-s m-b-xs', s.edit_btn)}
            onClick={() => onEdit(organization)}
          />
        </div>
      )}

      <div
        className={s.more_details}
        onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}
      >
        <span className={cn('m-r-xs', isDetailsExpanded && s.text_orange)}>
          {isDetailsExpanded ? _('less_details') : _('more_details')}
        </span>
        <ChevronUpIcon
          className={cn(!isDetailsExpanded && 'rl180')}
          width="10px"
          fill={isDetailsExpanded ? '#f7953e' : '#6d7f92'}
        />
      </div>
    </div>
  );
};

OrganizationOption.Fetching = () => (
  <div className={cn(s.option_wrapper, s.fetching)}>
    <div className="grid-x">
      <div className="cell small-3">
        <div className={cn(s.option_image, s.fetching)} />
      </div>
      <div className={cn('cell small-9 p-t-s', s.option_right)}>
        <h3 className={s.fetching}></h3>
        <div className={cn(s.info, 'm-y-s')}>
          <div className={s.fetching} />
          <div className={s.fetching} />
          <div className={s.fetching} />
        </div>
      </div>
    </div>
  </div>
);

export default OrganizationOption;
