import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import styles from './SubMenu.module.scss';

interface Row {
  label: string;
  url: string;
  icon?: () => React.ReactNode | string;
  show: boolean;
}

interface Props {
  label: string;
  url: string;
  isClickable?: boolean;
  exact?: boolean;
  icon: () => React.ReactNode | string;
  rows: Row[];
}

export default memo(function TTSelectComponent({
  label,
  url,
  icon,
  exact = false,
  rows,
  isClickable = true,
}: Props) {
  const handleClick = (e: any) => {
    if (!isClickable) e.preventDefault();
  };

  return (
    <div className={styles.select}>
      <NavLink
        exact={exact}
        to={url}
        onClick={(event) => handleClick(event)}
        activeClassName={styles.active}
      >
        {typeof icon == 'function' ? (
          icon()
        ) : icon ? (
          <i className={`icon icon-${icon}`} />
        ) : null}
        {label}
        <span className={classnames(styles.arrow, 'icon icon-arrow-down')} />
      </NavLink>
      <ul>
        {rows.map((row) => (
          <li key={row.label}>
            {row.show && (
              <NavLink activeClassName={styles.active} exact to={row.url}>
                {typeof row.icon == 'function' ? (
                  row.icon()
                ) : row.icon ? (
                  <i className={`icon icon-${row.icon}`} />
                ) : null}
                {row.label}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
});
