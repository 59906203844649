import { matchPath } from 'react-router-dom';
import { Guest, SUBSCRIPTION_FREQUENCY } from 'store/Guests/types';
import { GoBackState } from 'store/Params/GoBack';
import { MenuDirectionType } from 'store/Params/SubMenu';
import {
  CYCLE_PREMIUM_ID,
  bindCycleLink,
  formatDateEndOfReplay,
  isPremiumUserInGracePeriod,
  isUserPremium,
  isUserPremiumPending,
} from 'utils';
import _ from 'i18n';
import { URLS } from 'router';

export const NOTIFS_LIMIT = 5;

export const getSpaceMaintainerClass = (
  subMenuDirection: MenuDirectionType,
  hideSubMenu: boolean,
  showNoIE: boolean,
  goBack: GoBackState,
  currentPathName: string,
) => {
  let className = '';
  const isSubMenuActive = !hideSubMenu && subMenuDirection === 'HORIZONTAL';
  const isGoBackActive =
    goBack.showGoBack && !!matchPath(currentPathName, goBack.route);

  if (isSubMenuActive) {
    className += ' wsm';
  }

  if (isGoBackActive) {
    className += ' wgb';
  }

  if (showNoIE) {
    className += ' wnoIE';
  }

  return className;
};

export const getNotifData = (
  premiumGuest: Guest,
  setShowNotificationBar: (isDisplayed: boolean) => void,
) => {
  if (!premiumGuest) {
    return null;
  }

  const isRegisteredToPremium = isUserPremium(
    premiumGuest.premiumRegistrationStatus ?? 0,
  );
  const dateEndOfSubscription = premiumGuest?.subscriptionEndDate ?? '';

  const isPremiumSubscriptionPending = isUserPremiumPending(
    premiumGuest.premiumRegistrationStatus ?? 0,
  );
  const isPremiumInGracePeriod = isPremiumUserInGracePeriod(
    premiumGuest.premiumRegistrationStatus ?? 0,
  );
  const dateEndOfReplay = formatDateEndOfReplay(
    premiumGuest?.dateEndOfReplay ?? '',
  );
  const premiumLink = bindCycleLink(
    URLS.cycle.reception.registration.root,
    CYCLE_PREMIUM_ID,
  );
  const period =
    premiumGuest?.subscriptionFrequency == SUBSCRIPTION_FREQUENCY.MONTHLY
      ? _('monthlyFeminine')
      : _('annualFeminine');

  if (isRegisteredToPremium) {
    if (isPremiumSubscriptionPending) {
      return {
        theme: 'greenTeal',
        message: _('premium_offer_notif_bar_message_pending'),
        icon: 'loader',
      };
    } else if (isPremiumInGracePeriod) {
      return {
        theme: 'red',
        message: _('premium_offer_notif_bar_message_grace_period')
          .replace('{date}', dateEndOfReplay)
          .replace('{link}', premiumLink)
          .replace('{period}', period),
        icon: 'alert',
      };
    } else if (dateEndOfSubscription) {
      return {
        theme: 'greenTeal',
        message: _('premium_offer_notif_bar_message').replace(
          '{date}',
          dateEndOfSubscription,
        ),
        icon: 'checkMark',
        onClose: () => setShowNotificationBar(false),
      };
    }
  }
  return null;
};
