import React from 'react';

import * as Yup from 'yup';
import { Dropdown, Menu } from 'antd';
import styles from './StepCollaboratorsData.module.scss';
import * as yup from 'yup';
import t from 'i18n';

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer">
        Modifier
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer">
        Supprimer
      </a>
    </Menu.Item>
  </Menu>
);

export const columns: any = [
  {
    title: 'Nom / Prénom',
    dataIndex: 'name',
    width: 150,
    sorter: (a: any, b: any) => a.name.length - b.name.length,
    defaultSortOrder: ['descend'],
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    width: 220,
  },
  {
    title: 'Tél',
    dataIndex: 'Tél',
    width: 100,
  },
  {
    title: 'Role utilisateur',
    dataIndex: 'role',
    width: 150,
    sorter: (a: any, b: any) => a.role.length - b.role.length,
    defaultSortOrder: ['descend'],
  },
  {
    title: 'Langue',
    dataIndex: 'lng',
    width: 100,
  },
  {
    title: '',
    dataIndex: 'action',
    width: 50,
    render: (text: any, record: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return (
        <Dropdown overlay={menu} placement="bottomLeft">
          <span className={styles.actions_btn}>
            <img alt="" src="/img/icons/group.svg" className="NOTIFS" />
          </span>
        </Dropdown>
      );
    },
  },
];

export const userRoles = [
  {
    label: t('LEGAL_REPRESENTATIVE'),
    value: 'LEGAL_REPRESENTATIVE',
  },
  {
    label: t('MANAGER'),
    value: 'MANAGER',
  },
  {
    label: t('EXTERNAL'),
    value: 'EXTERNAL',
  },
  {
    label: t('OFFICIAL'),
    value: 'OFFICIAL',
  },
];

export const Lng = ['fr', 'en', 'nl'];

export const roles = [
  'LEGAL_REPRESENTATIVE',
  'MANAGER',
  'EXTERNAL',
  'OFFICIAL',
];

export const languages = [
  {
    label: 'Français',
    value: 'fr',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'néerlandais',
    value: 'nl',
  },
];

export const areaCodes = [
  {
    label: 'Belgique (+32)',
    value: '+32',
  },
  {
    label: 'France (+33)',
    value: '+33',
  },
  {
    label: 'Pays-Bas (+31)',
    value: '+31',
  },
  {
    label: 'Allemagne (+49)',
    value: '+49',
  },
  {
    label: 'Suisse (+41)',
    value: '+41',
  },
];

export const fileHeader = [
  {
    prénom: 'John',
    nom: 'John',
    email: 'john74963@gmail.com',
    téléphone: '321765567890',
    langue: 'Fr',
  },
  {
    prénom: 'John',
    nom: 'Test',
    email: 'john654987@gmail.com',
    téléphone: '321765568855',
    langue: 'En',
  },
];

export const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';
export const fileName = 'collaborators';

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const getCollabFormInitialValues = (
  collaboratorData: any | null,
  selectedRole: string | null,
) => {
  return {
    id: collaboratorData?.id ?? '',
    firstName: collaboratorData?.firstName ?? '',
    lastName: collaboratorData?.lastName ?? '',
    email: collaboratorData?.email ?? '',
    emailId: collaboratorData?.emailId ?? '',
    phoneNumber: collaboratorData?.phoneNumber ?? '',
    phoneId: collaboratorData?.phoneId ?? '',
    role: selectedRole ? selectedRole : collaboratorData?.role ?? '',
    roleId: collaboratorData?.roleId ?? '',
    lng: collaboratorData?.lng ?? '',
    areaCode: '',
  };
};

/*export const CollaboratorForm = Yup.object().shape({
  firstName: Yup.string().min(2).required(t('firstNameRequired')),
  lastName: Yup.string().min(2).required(t('lastNameRequired')),
  email: Yup.string().email(t('invalidEmail')).required(t('emailRequired')),
  phoneNumber: Yup.string().min(8).max(20).required(t('phoneRequired')),
  lng: yup
    .string()
    .oneOf(Lng, t('yup.invalid'))
    .required(t('yup.required'))
    .label(t('languageRequired')),
  role: yup
    .string()
    .oneOf(roles, t('yup.invalid'))
    .required(t('yup.required'))
    .label(t('roleRequired')),
  areaCode: yup.string(),
});*/

export interface Collaborator {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  emailId: number;
  phoneNumber: string;
  phoneId: number;
  role: string;
  roleId: number;
  lng: string;
  cachedNumber?: string;
}
