import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/Modal/actions';
import { ModalState } from 'store/Modal/types';
import ModalSession from '../ModalSession';
import Modal from 'react-modal';
import ModalAd from '../ModalAd/ModalAd';
import ModalInfo from '../ModalInfo';
import ModalEventQuick from '../ModalEventQuick';
import ModalPayment from '../ModalPayment/ModalPayment';
import ModalCycle from '../ModalCycle';
import ModalSpeaker from '../ModalSpeaker';
import ModalConfirmation from '../ModalConfirmation';
import { useLocation } from 'react-router-dom';
import ModalEventFullRegistration from '../ModalEventFullRegistration';
import ModalPackRegistration from '../ModalPackRegistration';
import ModalOptionsConfirmation from '../ModalOptionsConfirmation';
import ModalUpComingEventPopup from '../ModalUpComingEventPopup';
import ModalWatch from '../ModalWatch';
import ModalWatchSearch from '../ModalWatchSearch';
import ModalFreeEventRegistration from '../ModalFreeEventRegistration';
import ModalDaily from '../ModalDaily';
import ModalCancelRegistration from '../ModalCancelRegistration/ModalCancelRegistration';

Modal.setAppElement('#root');

interface Props {
  modal: ModalState;
}

export default memo(function ModalManager({ modal }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleCloseModal = useCallback(() => {
    modal.onClose?.();
    dispatch(closeModal());
  }, [dispatch, modal]);

  useEffect(() => {
    if (modal.isOpen) {
      dispatch(closeModal());
    }
  }, [dispatch, location.pathname]);

  if (!modal.isOpen) {
    return null;
  }

  switch (modal.modalId) {
    case 'SESSION':
      return <ModalSession modal={modal} onCloseModal={handleCloseModal} />;
    case 'AD_CONFIRM':
      return <ModalAd modal={modal} onCloseModal={handleCloseModal} />;
    case 'INFO':
      return <ModalInfo modal={modal} onCloseModal={handleCloseModal} />;
    case 'EventQuick':
      return <ModalEventQuick modal={modal} onCloseModal={handleCloseModal} />;
    case 'PAYMENT':
      return <ModalPayment modal={modal} onCloseModal={handleCloseModal} />;
    case 'CYCLE_DETAILS':
      return <ModalCycle modal={modal} onCloseModal={handleCloseModal} />;
    case 'SPEAKER':
      return <ModalSpeaker modal={modal} onCloseModal={handleCloseModal} />;
    case 'EVENT_POPUP':
      return (
        <ModalUpComingEventPopup
          modal={modal}
          onCloseModal={handleCloseModal}
        />
      );
    case 'EVENT_FULL_REGISTRATION':
      return (
        <ModalEventFullRegistration
          modal={modal}
          onCloseModal={handleCloseModal}
        />
      );
    case 'PACK_REGISTRATION':
      return (
        <ModalPackRegistration modal={modal} onCloseModal={handleCloseModal} />
      );
    case 'CONFIRMATION':
      return (
        <ModalConfirmation modal={modal} onCloseModal={handleCloseModal} />
      );
    case 'OPTIONS_CONFIRMATION':
      return (
        <ModalOptionsConfirmation
          modal={modal}
          onCloseModal={handleCloseModal}
        />
      );
    case 'WATCH':
      return <ModalWatch modal={modal} onCloseModal={handleCloseModal} />;
    case 'WATCH_SEARCH':
      return <ModalWatchSearch modal={modal} onCloseModal={handleCloseModal} />;
    case 'EVENT_FREE_REGISTRATION':
      return (
        <ModalFreeEventRegistration
          modal={modal}
          onCloseModal={handleCloseModal}
        />
      );
    case 'DAILY_IFRAME':
      return <ModalDaily modal={modal} onCloseModal={handleCloseModal} />;
    case 'EVENT_CANCEL_REGISTRATION':
      return (
        <ModalCancelRegistration
          modal={modal}
          onCloseModal={handleCloseModal}
        />
      );
    default:
      return null;
  }
});
