import React, { memo } from 'react';
import s from './ModalDaily.module.scss';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import DailyFrame from 'components/Webinar/Exhibitors/ExhibitorHeader/DailyIframe/DailyFrame';

interface Props {
  modal: any;
  onCloseModal: () => void;
}

export default memo(function ModalDaily({ modal, onCloseModal }: Props) {
  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      className={s.modal_content}
    >
      <CloseModal onClose={onCloseModal} />
      <DailyFrame modal={modal.data} />
    </Modal>
  );
});
