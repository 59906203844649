import { applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { APP_ENV } from 'config';
import { History } from 'history';

export const createEnhancer = (history: History) => {
  // PROD Middlewares
  const prodMiddlewares = [
    routerMiddleware(history),
    thunk,
    promise,
    loadingBarMiddleware(),
  ];

  // DEV Middlewares
  const devMiddlewares = [...prodMiddlewares, createLogger()];

  if (['production', 'v2'].indexOf(APP_ENV as string) !== -1) {
    return compose(applyMiddleware(...prodMiddlewares));
  }

  return composeWithDevTools(applyMiddleware(...devMiddlewares));
};
