import { ModalState, ModalActionTypes, CLOSE_MODAL, OPEN_MODAL } from './types';

const initialState = {
  isOpen: false,
  modalId: '' as const,
  data: {},
  onClose: undefined,
  onSuccess: undefined,
  onCancel: undefined,
};

export const reducer = (
  state: ModalState = initialState,
  action: ModalActionTypes,
) => {
  switch (action.type) {
    case CLOSE_MODAL: {
      return initialState;
    }
    case OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
        modalId: action.modalId,
        data: action.data,
        onClose: action.onClose,
        onSuccess: action.onSuccess,
        onCancel: action.onCancel,
      };
    }
    default:
      return state;
  }
};

export default reducer;
