import React from 'react';

interface Props {}
import styles from '../StepCollaboratorsData.module.scss';
import classnames from 'classnames';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import _ from 'i18n';
import classNames from 'classnames';

class CollaboratorsFetching extends React.Component<Props> {
  render() {
    return (
      <div className={styles.fetching_table}>
        <div className="grid-x">
          <div className="cell small-8 align-stretch p-r-xs">
            <div className={classnames(styles.collab_count, styles.gradiant)} />
          </div>
          <div className="cell small-4 align-stretch p-r-xs" />
        </div>

        <div>
          <div className={styles.table}>
            <div className={styles.table_header}>
              <div
                className={classnames(styles.table_frame, styles.gradiant)}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CollaboratorsFetching;
