import {
  TagsActionTypes,
  TagsState,
  TagType,
  EventWithTags,
  FETCH_TAGS,
  FETCH_TAGS_PENDING,
  FETCH_TAGS_FULFILLED,
  FETCH_TAGS_REJECTED,
  FETCH_EVENT_WITH_TAGS,
  FETCH_EVENT_WITH_TAGS_PENDING,
  FETCH_EVENT_WITH_TAGS_FULFILLED,
  FETCH_EVENT_WITH_TAGS_REJECTED,
  UPDATE_EVENT_TAGS,
  UPDATE_EVENT_TAGS_PENDING,
  UPDATE_EVENT_TAGS_FULFILLED,
  UPDATE_EVENT_TAGS_REJECTED,
} from './types';
import { paginationInitialState } from 'store/Pagination/reducer';
import { filterInitialState } from 'store/Filter/reducer';

const initialState = {
  fetching: false,
  fetched: false,
  eventFetching: false,
  eventFetched: false,
  items: [],
  error: null,
  eventWithTags: [],
  nbResult: 0,
  ...paginationInitialState,
  ...filterInitialState,
};

export const reducer = (
  state: TagsState = initialState,
  action: TagsActionTypes,
) => {
  switch (action.type) {
    case FETCH_TAGS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_TAGS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: state.page === 1 ? [...data[0]] : [...state.items, ...data[0]],
        nbResult: nbResult,
      };
    }
    case FETCH_TAGS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: error,
        items: [],
        nbResult: 0,
      };
    }

    case FETCH_EVENT_WITH_TAGS_PENDING: {
      return {
        ...state,
        eventFetched: false,
        eventFetching: true,
        eventWithTags: state.page === 1 ? [] : state.eventWithTags,
      };
    }
    case FETCH_EVENT_WITH_TAGS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        eventFetching: false,
        eventFetched: true,
        error: null,
        eventWithTags:
          state.page === 1
            ? [...data[0]]
            : [...state.eventWithTags, ...data[0]],
        nbResult: nbResult,
      };
    }
    case FETCH_EVENT_WITH_TAGS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        eventFetching: false,
        eventFetched: false,
        error: error,
        eventWithTags: null,
        items: [],
        nbResult: 0,
      };
    }

    case UPDATE_EVENT_TAGS_PENDING: {
      return {
        ...state,
        eventFetched: false,
        eventFetching: true,
        eventWithTags: state.page === 1 ? [] : state.eventWithTags,
      };
    }
    case UPDATE_EVENT_TAGS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        eventFetching: false,
        eventFetched: true,
        error: null,
        eventWithTags:
          state.page === 1 ? [...data] : [...state.eventWithTags, ...data],
        nbResult: nbResult,
      };
    }
    case UPDATE_EVENT_TAGS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        eventFetching: false,
        eventFetched: false,
        error: error,
        eventWithTags: null,
        items: [],
        nbResult: 0,
      };
    }

    default:
      return state;
  }
};

export default reducer;
