import { connect } from 'react-redux';
import { selectUser } from 'store/Auth';
import { fetchResourceThunk } from 'store/Resource/thunks';
import { RootState } from 'store/types';
import SimilarEvents from './SimilarEvents';
import { resetResource } from 'store/Resource/actions';

const mapStateToProps = (state: RootState) => ({
  userId: selectUser(state)?.id,
  eventsResource: state.events.userList,
});

const resetEvents = () => resetResource('LIST_FOR_USER');

export default connect(mapStateToProps, {
  fetchEvents: fetchResourceThunk,
  resetEvents,
})(SimilarEvents);
