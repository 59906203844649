import React from 'react';
import s from './StepsContent.module.scss';
import cn from 'classnames';
interface Props {
  children?: React.ReactNode;
  className?: string;
  innerRef?: React.Ref<any>;
}

const StepsContent = ({ children, className, innerRef }: Props) => {
  return (
    <div className={cn(className, s.steps_content)} ref={innerRef}>
      {children}
    </div>
  );
};

export default StepsContent;
