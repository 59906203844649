const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};
export const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `RESET_ARTICLES`: {
      return initialState;
    }
    case `FETCH_ARTICLES_PENDING`: {
      return { ...state, fetched: false, fetching: true };
    }
    case `FETCH_ARTICLES_FULFILLED`: {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case `FETCH_ARTICLES_REJECTED`: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};
