import {
  FETCH_PARTNER_AD,
  PartnerAd,
  PartnerAdActionTypes,
  RESET_PARTNER_AD,
  ADD_PARTNER_AD,
  DELETE_PARTNER_AD,
  EDIT_PARTNER_AD,
  FILTER_PARTNER_AD,
} from './types';
import { paginationActionsCreator } from '../Pagination/actions';
import { PARTNERS_ADS_RESOURCE } from 'store/types';

export const resetPartnerAds = (): PartnerAdActionTypes => ({
  type: RESET_PARTNER_AD,
});

export const fetchPartnerAds = (resp: any): PartnerAdActionTypes => ({
  type: FETCH_PARTNER_AD,
  payload: resp,
});

export const removePartnerAdsFromItems = (
  resp: number,
): PartnerAdActionTypes => ({
  type: DELETE_PARTNER_AD,
  payload: resp,
});

export const AddPartnerAds = (partnerAd: PartnerAd): PartnerAdActionTypes => ({
  type: ADD_PARTNER_AD,
  partnerAd,
});

export const EditPartnerAds = (partnerAd: PartnerAd): PartnerAdActionTypes => ({
  type: EDIT_PARTNER_AD,
  partnerAd,
});

export const filterPartnerAds = (resp: any): PartnerAdActionTypes => ({
  type: FILTER_PARTNER_AD,
  payload: resp,
});

/** PARTNERS GENERIQUE RESOURCE ACTIONS */
export const [
  sePartnersAdsPage,
  setPartnersAdsPageSize,
] = paginationActionsCreator(PARTNERS_ADS_RESOURCE);
/** END PARTNERS RESOURCE ACTIONS */

export default {
  resetPartnerAds,
  fetchPartnerAds,
};
