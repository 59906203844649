import React, { memo } from 'react';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { Speaker as SpeakerType } from 'store/Speakers/types';
import { useLanguage } from 'hooks/useLanguage';
import Speaker from 'components/SpeakersList/Speaker';

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: 0,
    top: '50%',
    bottom: 'unset',
    maxHeight: '90%',
    borderRadius: '20px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '90%',
    maxWidth: '981px',
  },
};

interface Props {
  modal: ModalState<SpeakerType>;
  onCloseModal: () => void;
}

export default memo(function ModalSpeaker({ modal, onCloseModal }: Props) {
  const language = useLanguage();

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
    >
      <div
        className="rmodal"
        style={{
          overflowY: 'auto',
          maxHeight: '90vh',
        }}
      >
        <CloseModal onClose={onCloseModal} />
        <div>
          <Speaker
            speaker={modal.data}
            language={language}
            isSingleLayout={true}
          />
        </div>
      </div>
    </Modal>
  );
});
