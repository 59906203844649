import {
  CollaboratorsActionTypes,
  CollaboratorsState,
  RESET_COLLABORATORS,
  FETCH_COLLABORATORS_PENDING,
  FETCH_COLLABORATORS_FULFILLED,
  FETCH_COLLABORATORS_REJECTED,
  SAVE_COLLABORATORS_PENDING,
  SAVE_COLLABORATORS_FULFILLED,
  SAVE_COLLABORATORS_REJECTED,
  DELETE_COLLABORATOR_PENDING,
  DELETE_COLLABORATOR_FULFILLED,
  DELETE_COLLABORATOR_REJECTED,
  ADD_COLLABORATOR,
  FETCH_USER,
  FETCH_USER_PENDING,
  FETCH_USER_FULFILLED,
  FETCH_USER_REJECTED,
  FETCH_USERS_PENDING,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  UPLOAD_COLLABORATORS_PENDING,
  UPLOAD_COLLABORATORS_FULFILLED,
  UPLOAD_COLLABORATORS_REJECTED,
} from './types';
import { paginationInitialState } from 'store/Pagination/reducer';
import { filterInitialState } from 'store/Filter/reducer';

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  userFetching: false,
  userFetched: false,
  items: [],
  collaborators: [],
  newCollab: [],
  error: null,
  nbResult: 0,
  ...paginationInitialState,
  ...filterInitialState,
  data: [],
};

export const reducer = (
  state: CollaboratorsState = initialState,
  action: CollaboratorsActionTypes,
) => {
  switch (action.type) {
    case RESET_COLLABORATORS: {
      return initialState;
    }
    case FETCH_COLLABORATORS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_COLLABORATORS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: state.page === 1 ? [...data] : [...state.items, ...data],
        nbResult: nbResult,
      };
    }
    case FETCH_COLLABORATORS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: error,
        items: [],
        nbResult: 0,
      };
    }
    case FETCH_USERS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        collaborators: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_USERS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        collaborators: state.page === 1 ? [...data] : [...state.items, ...data],
        nbResult: nbResult,
      };
    }
    case FETCH_USERS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: error,
        collaborators: [],
        nbResult: 0,
      };
    }
    case FETCH_USER_PENDING: {
      return {
        ...state,
        userFetched: false,
        userFetching: true,
      };
    }
    case FETCH_USER_FULFILLED: {
      return {
        ...state,
        userFetching: false,
        userFetched: true,
        error: null,
      };
    }
    case FETCH_USER_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        userFetching: false,
        userFetched: false,
        error: error,
      };
    }

    case SAVE_COLLABORATORS_PENDING: {
      return {
        ...state,
        saved: false,
        saving: true,
      };
    }
    case SAVE_COLLABORATORS_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        newCollab: [data?.id],
        saving: false,
        saved: true,
        error: null,
        data: data,
      };
    }
    case SAVE_COLLABORATORS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        error: error?.request?.response,
        saving: false,
      };
    }
    case UPLOAD_COLLABORATORS_PENDING: {
      return {
        ...state,
        saved: false,
        saving: true,
      };
    }
    case UPLOAD_COLLABORATORS_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        newCollab: data?.newCollab,
        saving: false,
        saved: true,
        error: null,
        data: data,
      };
    }
    case UPLOAD_COLLABORATORS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        saving: false,
        error: error,
        items: [],
        nbResult: 0,
      };
    }
    case DELETE_COLLABORATOR_PENDING: {
      return {
        ...state,
        deleted: false,
        deleting: true,
      };
    }
    case DELETE_COLLABORATOR_FULFILLED: {
      return {
        ...state,
        deleting: false,
        deleted: true,
        error: null,
      };
    }
    case DELETE_COLLABORATOR_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: error,
        items: [],
        nbResult: 0,
      };
    }
    case ADD_COLLABORATOR: {
      return {
        ...state,
        items: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
