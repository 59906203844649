import React, { Component } from 'react';
import _ from 'i18n';
import { ReactComponent as GarbageIcon } from 'assets/icons/garbage.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import TTPActionModal from '../../Common/reveal/TTPActionModal';

export default class TTPDialog extends Component {
  cancel = () => {
    this.props.setTTPDialogShow(false);
  };

  prepareModalePropsFromType = (type) => {
    let props = {};

    switch (type) {
      case 'send':
        props = {
          classNames: { ok: 'green2', cancel: 'gray' },
          theme: 'success',
          renderIcon: () => <SendIcon />,
        };
        break;
      case 'delete':
        props = {
          classNames: { ok: 'red', cancel: 'gray' },
          theme: 'alert',
          renderIcon: () => <GarbageIcon />,
        };
        break;
      case 'confirmation':
        props = {
          classNames: { ok: 'blue3', cancel: 'gray' },
          renderIcon: () => <InfoIcon />,
        };
        break;
      default:
        break;
    }

    return props;
  };

  render() {
    const {
      dialog: { showDialog, customData },
    } = this.props;

    if (!showDialog) {
      return null;
    }
    const modalMessage = customData.message || _('Do you want to proceed ?');
    const modalProceedBtnLabel = customData.proceedBtnLabel || _('yes');
    const modalAbortBtnLabel = customData.abortBtnLabel || _('cancel');
    const modalProps = this.prepareModalePropsFromType(customData.type);

    if (!modalProps.icon && typeof modalProps.renderIcon !== 'function') {
      modalProps.renderIcon = () => <InfoIcon />;
    }

    const title = customData.title || 'Confirmation';

    return (
      <TTPActionModal
        isOpen={showDialog}
        title={title}
        message={modalMessage}
        labels={{ ok: modalProceedBtnLabel, cancel: modalAbortBtnLabel }}
        renderIcon={() => <SendIcon />}
        contentLabel={customData?.contentLabel}
        onCloseModal={this.cancel}
        onAction={customData?.approvedAction}
        onAsyncAction={customData?.approvedAsyncAction}
        onAbort={customData?.abortAction}
        {...modalProps}
      />
    );
  }
}
