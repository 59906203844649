import React from 'react';
import { NotificationBarIconType } from 'store/Params/NotificationBar';
import { ReactComponent as CheckMarkCircleIcon } from 'assets/icons/check-circle-centered.svg';
import { ReactComponent as ClockPieIcon } from 'assets/icons/clock-pie.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-circle-filled.svg';

const ICON_SIZE = 24;

export const getIcon = (icon: NotificationBarIconType) => {
  switch (icon) {
    case 'checkMark':
      return <CheckMarkCircleIcon width={ICON_SIZE} height={ICON_SIZE} />;
    case 'clock':
      return <ClockPieIcon width={ICON_SIZE} height={ICON_SIZE} />;
    case 'loader':
      return <ClipLoader size={ICON_SIZE} color="#FFFFFF" loading={true} />;
    case 'alert':
      return <AlertIcon width={ICON_SIZE} height={ICON_SIZE} />;
  }
};
