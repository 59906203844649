import {
  SlotActionTypes,
  SET_ROOMS,
  Slot,
  SlotReducerNameType,
  SET_SEARCH,
} from './types';
import { reducer as resourceReducer } from '../Resource';
import { paginationInitialState } from 'store/Pagination/reducer';
import { createNamedWrapperReducer } from 'store/services';
import {
  ResourceState,
  ResourceActionTypes,
  ADD_STRATEGY,
} from 'store/Resource/types';
import { EVENT_SLOT_RESOURCE, SLOTS_RESOURCE } from 'store/types';
import { filterInitialState } from 'store/Filter/reducer';

const initialState = {
  fetching: false,
  fetched: false,
  fetchedAt: 0,
  items: [],
  error: null,
  nbResult: 0,
  saving: false,
  search: '',
  addStrategy: ADD_STRATEGY.TAIL,
  ...paginationInitialState,
  ...filterInitialState,
};

const eventSlotReducer = createNamedWrapperReducer<
  ResourceState<Slot>,
  ResourceActionTypes<Slot>
>(resourceReducer, EVENT_SLOT_RESOURCE);

const slotReducer = createNamedWrapperReducer<
  ResourceState<Slot>,
  ResourceActionTypes<Slot>
>(resourceReducer, SLOTS_RESOURCE);

export const reducer = (nameReducer: SlotReducerNameType) => (
  state: ResourceState<Slot> = initialState,
  action: SlotActionTypes | ResourceActionTypes<Slot>,
) => {
  if (nameReducer !== action.meta) {
    return state;
  }

  switch (action.type) {
    case SET_ROOMS: {
      const { rooms } = action;
      const itemsWithRooms = state.items.map((slot) => ({
        ...slot,
        roomData: rooms.find(({ id }) => id === slot.room),
      }));

      return { ...state, items: itemsWithRooms };
    }
    case SET_SEARCH: {
      return { ...state, search: action.search };
    }
    default: {
      const _reducer =
        nameReducer === EVENT_SLOT_RESOURCE ? eventSlotReducer : slotReducer;
      return _reducer(state, action as ResourceActionTypes<Slot>);
    }
  }
};

export default reducer;
