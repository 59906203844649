import {
  GoBackActionTypes,
  SET_GO_BACK_SHOW,
  SET_GO_BACK_CUSTOM_DATA,
  RESET_GO_BACK,
  GoBackData,
  GoBackRouteType,
} from './types';

export const setGoBackShow = (showGoBack: boolean): GoBackActionTypes => ({
  type: SET_GO_BACK_SHOW,
  showGoBack,
});

export const resetGoBack = (): GoBackActionTypes => ({
  type: RESET_GO_BACK,
});

export const setGoBackCustomData = (
  route: GoBackRouteType,
  customData: GoBackData,
): GoBackActionTypes => ({
  type: SET_GO_BACK_CUSTOM_DATA,
  route,
  customData,
});

export default { setGoBackShow, setGoBackCustomData };
