import {
  FETCH_EVENTS_FUTURE,
  SET_EVENTS_FUTURE_PAGE_SIZE,
  SET_EVENTS_FUTURE_PAGE,
} from './types';

export const fetchEventsFuture = (
  resp: any,
): { payload: any; type: string } => ({
  type: FETCH_EVENTS_FUTURE,
  payload: resp,
});

export const setEventsFuturePageSize = (
  pageSize: number,
): { payload: any; type: string } => ({
  type: SET_EVENTS_FUTURE_PAGE_SIZE,
  payload: pageSize,
});

export const setEventsFuturePage = (
  page: number,
): { payload: any; type: string } => ({
  type: SET_EVENTS_FUTURE_PAGE,
  payload: page,
});

export default {
  fetchEventsFuture,
  setEventsFuturePageSize,
  setEventsFuturePage,
};
