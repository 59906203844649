import languageActions from './Language/actions';
import sourceTokenActions from './SourceToken/actions';
import subMenuActions from './SubMenu/actions';
import ttpDialogAction from './TTPDialog/actions';
import faqActions from './Faq/actions';
import AppInfoActions from './AppInfo/actions';
import AutoPromotedOptionsModalActions from './AutoPromotedOptionsModal/actions';

export * from './Language/actions';
export * from './SourceToken/actions';
export * from './SubMenu/actions';
export * from './TTPDialog/actions';
export * from './Faq/actions';
export * from './AppInfo/actions';
export * from './AutoPromotedOptionsModal/actions';

export default {
  ...languageActions,
  ...sourceTokenActions,
  ...subMenuActions,
  ...ttpDialogAction,
  ...faqActions,
  ...AppInfoActions,
  ...AutoPromotedOptionsModalActions,
};
