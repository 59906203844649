import React, { memo, useCallback } from 'react';
import { Language, RootState } from 'store/types';
import styles from './ModalContent.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import Markdown from 'markdown-to-jsx';
import { Slot } from 'store/Slots/types';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as SessionIcon } from 'assets/icons/session.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as PaperClipIcon } from 'assets/icons/paperclip.svg';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';

import moment from 'moment';
import { S3_FOLDER_AWS_URL, S3_FOLDER_URL, S3_FOLDER_URL_PROD } from 'config';
import SessionActions from 'components/Webinar/Sessions/Session/SessionActions';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Event } from 'store/Events/types';
import {
  filterSpeakersForSlots,
  getByLanguage,
  getCroppedImageUrl,
  getSessionType,
  getUserNameForAvatar,
  hasEventAdminAccess,
  hasPartnerManagerAccess,
  isProgramActionsAllowed,
  isSessionPast,
  isSessionSoldOut,
  isWebinarSlot,
  parseJson,
  prepareS3ResourceUrl,
} from 'utils';

interface Props {
  session: Slot;
  language: Language;
}

export default memo(function ModalContent({ session, language }: Props) {
  const event: Event | undefined = useSelector(
    (state: RootState) => state.event.event.items[0],
  );
  const eventIdsResource = useSelector(
    (state: RootState) => state.event.eventIds,
  );
  const isManager = hasPartnerManagerAccess(
    eventIdsResource.items[0],
    event?.id ?? 0,
  );
  const isEventAdmin = hasEventAdminAccess(
    eventIdsResource.items[0],
    event?.id ?? 0,
  );
  const eventBanner = getByLanguage(event, 'urlBanner', language) ?? '';
  const name = getByLanguage(session, 'name', language) ?? '';
  const description = getByLanguage(session, 'description', language) ?? '';
  const pictureUrl = getByLanguage(session, 'pictureUrl', language) ?? '';
  const activity = getByLanguage(session.activityName, 'name', language);
  const isWebinar = isWebinarSlot(session);
  const banner = getCroppedImageUrl(
    !isEmpty(pictureUrl) ? `/events-folder${pictureUrl}` : eventBanner,
    undefined,
    364,
  );

  const day = moment(session.startDateTime).locale(language).format('ll');
  const hour = `${moment(session.startDateTime).format('HH:mm')} - ${moment(
    session.endDateTime,
  ).format('HH:mm')}`;
  const backgroundStyle = {
    backgroundImage: `linear-gradient(180deg, rgba(41, 57, 77, 0.27) 0%, rgba(41, 57, 77, 0.81) 100%), url(${S3_FOLDER_URL}${banner.replace(
      'eventsFolder',
      'events-folder',
    )})`,
  };

  const renderSpeakers = useCallback(() => {
    const speakers = filterSpeakersForSlots(session.speakers);

    return speakers.map((speaker) => {
      let headline = getByLanguage(speaker, 'headline', language);
      if (typeof headline === 'object') {
        headline = headline.title;
      } else if (typeof parseJson(headline) === 'object') {
        headline = parseJson(headline).title;
      }
      const avatarUrl = getCroppedImageUrl(speaker.pictureUrl, 134);

      return (
        <div className="medium-4 large-3" key={`speaker-${speaker.id}`}>
          <div className={classNames(styles.userWrapper, 'p-x-xxs')}>
            {!isEmpty(avatarUrl) ? (
              <div
                className={styles.avatar}
                style={{
                  backgroundImage: `url(${prepareS3ResourceUrl(
                    S3_FOLDER_URL_PROD,
                    pictureUrl,
                  )})`,
                }}
              />
            ) : (
              <div className={classNames(styles.avatar, 'empty-avatar')}>
                <span>
                  {getUserNameForAvatar(speaker.firstName, speaker.lastName)}
                </span>
              </div>
            )}
            <div className={styles.avatarName}>
              {`${speaker.firstName} ${speaker.lastName}`}
            </div>
            <div className={styles.profession}>{headline}</div>
          </div>
        </div>
      );
    });
  }, [language, session.speakers]);

  const renderSlotMode = () => {
    if (isWebinar) {
      return (
        <>
          <div className={styles.separator}>&#9679;</div>
          <div className={classNames(styles.block)}>
            <EarthIcon
              width="14"
              height="14"
              fill="#29394d"
              className="m-r-xs"
            />
            <span>{_('Webinar')}</span>
          </div>
        </>
      );
    }

    if (!isEmpty(session.roomName)) {
      return (
        <>
          <div className={styles.separator}>&#9679;</div>
          <div className={classNames(styles.block)}>
            <MapPinIcon
              className="m-r-xs"
              width="14"
              height="14"
              fill="#29394d"
            />
            <span>{session.roomName}</span>
          </div>
        </>
      );
    }

    return null;
  };
  const renderDocs = () => {
    if (!session.files || session.files.length < 1) {
      return null;
    }

    return (
      <div className={classNames(styles.docs)}>
        <div className={classNames(styles.docsList)}>
          {session.files.map((file, index) => {
            const fileName = file.fileUrl.split('/').pop();
            const fileNameArray = fileName?.split('.') ?? [];

            const ext = fileNameArray.length > 1 ? fileNameArray.pop() : '';
            // const helpName = fileNameArray
            //   .join('.')
            //   .replace(/-\d{4}_\d{2}_\d{2}_\d{2}_\d{2}_\d{2}/g, '');
            const helpName = `${_('attachment')} (${index + 1}) - ${name}`;
            const croppedName =
              helpName.length > 62 ? helpName.slice(0, 56).trim() : helpName;

            const label = `${croppedName}${
              helpName.length > 62 ? '[...]' : ''
            }.${ext}`;

            const prefix = file.handoutId ? '' : '/events-folder';
            const path = `${S3_FOLDER_AWS_URL}${prefix}${file.fileUrl}`;

            return (
              <a
                key={`file-${file.id}`}
                href={path}
                download
                target="_blank"
                rel="noopener noreferrer"
                className={classNames(styles.dowWrapper, 'cell small-3')}
              >
                <div className={styles.icon}>
                  <PaperClipIcon width="22" height="22" />
                </div>
                <div className={styles.title}>{label}</div>
                <DownloadIcon className={styles.downloadIcon} />
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  const isSoldOut = isSessionSoldOut(session) && !isSessionPast(session);
  const partner = session.partners?.[0];
  const logoUrl = getByLanguage(partner, 'pictureUrl', language) ?? '';

  return (
    <>
      <div className={classNames(styles.sessionModal, 'grid-x h100')}>
        <div className="small-6">
          <div className={styles.pictureBox}>
            {!isEmpty(backgroundStyle) && (
              <div
                className={styles.logo}
                style={{
                  ...backgroundStyle,
                }}
              >
                {isSoldOut && (
                  <div className={styles.soldOut}>{_('session_sold_out')}</div>
                )}
                {!isEmpty(logoUrl) && (
                  <div className={styles.logoPartner}>
                    <span
                      style={{
                        backgroundImage: `url(${S3_FOLDER_URL}/${
                          logoUrl.split('/')[0] !== 'events-folder'
                            ? 'events-folder'
                            : ''
                        }${logoUrl})`,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={classNames(styles.speakers, 'p-y-s')}>
              <div className="grid-x">{renderSpeakers()}</div>
            </div>
          </div>
        </div>
        <div className="small-6 h100">
          <span className={styles.sessionTitle}>{name}</span>
          <div className={styles.sessionHeader}>
            <div className={styles.block}>
              <CalendarIcon
                width="12"
                height="12"
                fill="#29394d"
                className="m-r-xs"
              />
              <span className={styles.sessionTime}>
                {day}, {hour}
              </span>
            </div>
            <div className={styles.separator}>&#9679;</div>
            {!isEmpty(activity) && (
              <>
                <div className={styles.block}>
                  <SessionIcon className="m-r-xs" width="16" stroke="#29394d" />
                  <span>{activity}</span>
                </div>
              </>
            )}
            {renderSlotMode()}
          </div>

          <SessionActions
            session={session}
            showMainButton={isProgramActionsAllowed(event, {
              hadPartnerManager: isManager,
              isEventAdmin,
            })}
            actionsStyle={{ float: 'unset' }}
            showCalendarAction={true}
            showAddText={true}
            showJoinText={true}
          />
          <div className={styles.description}>
            <div className="markdown">
              <Markdown>{description}</Markdown>
            </div>
          </div>
        </div>
      </div>
      {renderDocs()}
    </>
  );
});
