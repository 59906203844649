import React, { memo } from 'react';
import s from './ModalOptionsConfirmation.module.scss';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import OptionsConfirmation from 'Webinar/Reception/OptionsConfirmation';
import { optionsData } from 'store/Modal/OptionsConfirmation/types';
interface Props {
  modal: ModalState;
  onCloseModal: () => void;
}

export default memo(function ModalOptionsConfirmation({
  modal,
  onCloseModal,
}: Props) {
  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={false}
      className={s.modal_content}
    >
      <OptionsConfirmation {...modal.data} onCloseModal={onCloseModal} />
    </Modal>
  );
});
