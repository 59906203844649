import { NameWrappedAction } from 'store/types';

export interface EventsReplayHomeState {
  items: Event[];
  fetching: boolean;
  fetched: boolean;
  error: any;
  nbResult: number;
  page: number;
  pageSize: number;
}

export const FETCH_EVENTS_REPLAY = 'FETCH_EVENTS_REPLAY';
export const FETCH_EVENTS_REPLAY_PENDING = 'FETCH_EVENTS_REPLAY_PENDING';
export const FETCH_EVENTS_REPLAY_FULFILLED = 'FETCH_EVENTS_REPLAY_FULFILLED';
export const FETCH_EVENTS_REPLAY_REJECTED = 'FETCH_EVENTS_REPLAY_REJECTED';

export const SET_EVENTS_REPLAY_PAGE_SIZE = 'SET_EVENTS_REPLAY_PAGE_SIZE';
export const SET_EVENTS_REPLAY_PAGE = 'SET_EVENTS_REPLAY_PAGE';
export interface FetchEventsReplayAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENTS_REPLAY
    | typeof FETCH_EVENTS_REPLAY_PENDING
    | typeof FETCH_EVENTS_REPLAY_FULFILLED
    | typeof FETCH_EVENTS_REPLAY_REJECTED
    | typeof SET_EVENTS_REPLAY_PAGE_SIZE
    | typeof SET_EVENTS_REPLAY_PAGE;
  payload: any;
}

export type EventActionTypes = FetchEventsReplayAction;
