import React, { memo } from 'react';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { Cycle } from 'store/Cycle/types';
import { useLanguage } from 'hooks/useLanguage';
import EventsList from 'components/Cycle/Events/EventsList';
import t from 'i18n';
import { getByLanguage } from 'utils';

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: 0,
    top: '50%',
    bottom: 'unset',
    maxHeight: '90%',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '90%',
    maxWidth: '981px',
  },
};

interface Props {
  modal: ModalState<Cycle>;
  onCloseModal: () => void;
}

export default memo(function EventQuickModal({ modal, onCloseModal }: Props) {
  const language = useLanguage();
  const { eventCycles } = modal.data;
  const name = getByLanguage(modal.data, 'name', language);

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div
        className="rmodal"
        style={{
          overflowY: 'auto',
          maxHeight: '90vh',
          minHeight: '50vh',
        }}
      >
        <CloseModal onClose={onCloseModal} />
        <div className="m-t-s">
          <EventsList
            events={eventCycles.map(({ eventsAbstract }) => eventsAbstract)}
            language={language}
            isRegistredToCycle={false /*TODO*/}
            renderHeader={() => (
              <h3
                className="p-s"
                style={{
                  fontSize: '18px',
                  color: '#29394D',
                  fontWeight: 'normal',
                  margin: 0,
                }}
              >
                {t('Cycle trainings')} : <strong>{name}</strong>
              </h3>
            )}
            renderEventProps={() => ({
              options: {
                showBackOffice: false,
                showMainAction: false,
                showDetails: true,
                openOnNewPage: true,
              },
            })}
          />
        </div>
      </div>
    </Modal>
  );
});
