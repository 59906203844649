import { FormikValues } from 'formik';
import { FormActionTypes, RESET_FORM_VALUES, SYNC_FORM_VALUES } from './types';

export const syncFormValues = (
  name: string,
  values: FormikValues,
): FormActionTypes => ({
  type: SYNC_FORM_VALUES,
  name,
  values,
});

export const resetFormValues = (name: string): FormActionTypes => ({
  type: RESET_FORM_VALUES,
  name,
});

export default { syncFormValues };
