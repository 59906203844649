import React, { useState } from 'react';
import s from './Coupon.module.scss';
import cn from 'classnames';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import { Help } from 'components/ui/Help/Help';
import _ from 'i18n';
import { LayoutTheme } from 'components/Layout/services';
import {
  Coupon as CouponType,
  COUPON_REDUCTION_TYPE,
  Guest,
} from 'store/Guests/types';
import { getByLanguage } from 'utils';
import { Language } from 'store/types';
import { isEmpty } from 'lodash';
import { getTheme } from 'components/Layout/AdvancedLayout/ThemeProvider';
import {
  CouponInput,
  createOption,
  CouponInputOption,
} from 'components/Registration/Coupon/CouponInput/CouponInput';
import { Event } from 'store/Events/types';
import { checkCoupon } from 'store/Guests/thunks';
import { useDispatch } from 'react-redux';
import Markdown from 'markdown-to-jsx';

interface Props {
  event: Event;
  guest: Guest;
  theme?: LayoutTheme;
  coupons?: CouponType[];
  selected?: string[];
  language: Language;
  isMember: boolean;
  allowedCoupons: COUPON_REDUCTION_TYPE[];
  selectedPlanId: number;
  onChange: (value: CouponInputOption[]) => void;
  setError?: (message: string) => any;
  afterApply?: (coupon: CouponType) => void;
}

export const Coupon = ({
  theme = '',
  language,
  isMember,
  coupons = [],
  selected = [],
  allowedCoupons,
  event,
  guest,
  selectedPlanId,
  onChange,
  afterApply,
}: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    coupon: CouponType,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    onChange([createOption(coupon.code)]);
    afterApply?.(coupon);
  };

  const validateCoupon = (coupon: string): any => {
    return dispatch(
      checkCoupon({
        code: coupon,
        eventId: event.id,
        guestId: guest.id,
      }),
    );
  };

  const handleValidateCoupon = (coupon: string, callback: () => void) => {
    setError(null);

    return validateCoupon(coupon)
      .then((res: any) => {
        callback();
        afterApply?.(res.value.data as CouponType);
      })
      .catch((err: any) => {
        if (setError) {
          let msg = `${_('yup.coupon_invalid')} (${coupon})`;

          if (err?.response?.data?.errors?.[0] === 'Expired coupon') {
            msg = `${_('yup.coupon_expired')} (${coupon})`;
          } else if (
            err?.response?.data?.errors?.[0] === 'Usage limit reached'
          ) {
            msg = `${_('yup.coupon_usage_limit')} (${coupon})`;
          }
          setError(msg);
        }
        return err;
      });
  };

  return (
    <div className={cn('grid-x', s.wrapper)}>
      <div className="cell small-12 medium-6 p-r-s">
        <div className="flex-container align-middle">
          <div className={cn(s.title, 'm-r-s')}>{_('Reductions')}:</div>
          <div className={cn(s.helperText, 'm-r-s')}>
            {_('I have a coupon')}
          </div>
          <div className="flex-1">
            <CouponInput
              className={s.input}
              theme={theme}
              name="coupons"
              placeholder={_('Enter a coupon and press Enter ...')}
              required={true}
              isMulti
              maxItems={1}
              validateValue={handleValidateCoupon}
              errorsClassName="m-y-xxs"
              value={selected.map((i) => ({ value: i, label: i }))}
              onChange={onChange}
            >
              {error && <p>{error}</p>}
            </CouponInput>
          </div>
        </div>
      </div>
      <div className="cell small-12 medium-6 p-l-s">
        <div className={s.coupons_list}>
          {coupons.map((coupon) => {
            const isSelected = selected.find((item) => item === coupon.code);
            const modelName =
              getByLanguage(coupon, 'modelName', language) ?? '';
            const comment = getByLanguage(coupon, 'comment', language);
            const hostName = getByLanguage(coupon, 'hostName', language) ?? '';
            const price = coupon.reductions.find(
              (item) =>
                allowedCoupons.includes(item.type) &&
                +item.id === selectedPlanId,
            );

            let couponName = !isEmpty(modelName) ? modelName : hostName;
            if (price) {
              const reductionMembre = Math.abs(
                parseInt(price.reductionMembre.toString()),
              );
              const reductionNonMembre = Math.abs(
                parseInt(price.reductionNonMembre.toString()),
              );

              if (isMember) {
                couponName +=
                  parseInt(price.reductionMembre.toString()) < 0 ? ': ' : ': -';
              } else {
                couponName +=
                  parseInt(price.reductionNonMembre.toString()) < 0
                    ? ': '
                    : ': -';
              }
              couponName += `${
                isMember ? reductionMembre : reductionNonMembre
              } €`;
            } else if (!isEmpty(comment)) {
              couponName += ` : ${comment}`;
            }

            return (
              <div
                className={cn('flex-container align-middle', s.coupon_wrapper)}
                key={coupon.code}
              >
                <div className="m-r-xs align-center-middle flex-container">
                  {!isEmpty(comment) && (
                    <Help
                      className="m-l-xs"
                      title={couponName}
                      contentStyle={{ width: '263px' }}
                      align={{ offset: [0, -32] }}
                      placement="leftTop"
                    >
                      <Markdown className="markdown">{comment}</Markdown>
                    </Help>
                  )}
                </div>
                <div
                  className={cn(
                    'flex-1',
                    s.coupon,
                    s[theme],
                    isSelected && s.active,
                  )}
                >
                  <div className="p-l-s">{coupon.code}</div>
                  <div className="flex-1 p-l-xs p-r-s greetings">
                    {couponName}
                  </div>
                  <button onClick={(e) => onClick(e, coupon)}>
                    {isSelected ? (
                      <CheckMarkIcon
                        width="10px"
                        height="10px"
                        fill={getTheme(theme).primaryColor}
                      />
                    ) : (
                      <span>{_('Apply')}</span>
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Coupon;
