import { Question } from 'interfaces/Question';

export const getInitialAnswer = (question: Question) => {
  if (question?.type === 'multiSelect') {
    if (!question?.defaultOptions?.length) {
      return [];
    }
    return question?.defaultOptions;
  }

  if (!question?.defaultOptions?.length) {
    return '';
  }

  return question?.defaultOptions[0];
};
