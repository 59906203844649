import React, { useState, useEffect, useRef } from 'react';
import s from './AddToCalendarBtn.module.scss';
import t from 'i18n';
import { ReactComponent as DropdownIcon } from 'assets/icons/polygon.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';
import { ReactComponent as YahooIcon } from 'assets/icons/yahoo.svg';
import { ReactComponent as AgendaIcon } from 'assets/icons/agenda.svg';
import { ReactComponent as OutlookIcon } from 'assets/icons/outlook.svg';
import { ReactComponent as Office365Icon } from 'assets/icons/office365.svg';
import { ReactComponent as CalendarPlusIcon } from 'assets/icons/calendar-plus.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  buildCalendarUrl,
  CalendarEventType,
  CalendarSizes,
  CalendarType,
  DEFAULT_BUTTON_NAME,
  DEFAULT_CALENDAR_NAME,
  DEFAULT_FILE_NAME,
  getCalendarButtonStyles,
  getDefaultCalendars,
  validateFileName,
} from './services';
import cn from 'classnames';

interface CommonProps {
  calendars?: CalendarType[];
  buttonName?: string;
  calendarName?: string;
  hasButtonIcon?: boolean;
  hasServiceIcons?: boolean;
  isProcessing?: boolean;
  fileName?: string;
  size?: CalendarSizes;
  isWatch?: boolean;
  isDropdownVisible?: boolean;
  optionsPosition?: 'topLeft' | 'topRight';
}
interface SingleEventProps extends CommonProps {
  isMulti?: false;
  event: CalendarEventType;
}
interface MultiEventsProps extends CommonProps {
  isMulti: true;
  icsFileUrl: string;
}

type Props = SingleEventProps | MultiEventsProps;

const AddToCalendarBtn = (props: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const {
    isMulti,
    calendars = getDefaultCalendars(isMulti),
    buttonName = t(DEFAULT_BUTTON_NAME),
    calendarName = DEFAULT_CALENDAR_NAME,
    isProcessing = false,
    hasServiceIcons = true,
    hasButtonIcon = false,
    fileName,
    size = CalendarSizes.S,
    isWatch,
    isDropdownVisible,
    optionsPosition,
  } = props;

  const calendarData = props.isMulti ? props.icsFileUrl : props.event;
  const defaultCalIcons = {
    Google: <GoogleIcon width="15px" />,
    Outlook: <OutlookIcon width="15px" />,
    Office365: <Office365Icon width="15px" height="15px" />,
    Yahoo: <YahooIcon width="15px" />,
    iCal: <AgendaIcon width="15px" height="15px" />,
  };
  const icsFileName = fileName
    ? validateFileName(fileName, 'ics')
    : DEFAULT_FILE_NAME;
  const {
    fontSize,
    loaderSize,
    dropdownSize,
    btnHeight,
  } = getCalendarButtonStyles(size);

  useEffect(() => {
    if (isDropdownVisible === false) {
      setIsDropdownOpen(false);
    }
  }, [isDropdownVisible]);

  const mainRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (mainRef.current && !mainRef.current.contains(target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderCalendarOptions = () => {
    const optionItems: React.ReactNode[] = calendars.map((calendar, key) => {
      return (
        <li key={key}>
          <a
            href={buildCalendarUrl(
              calendar,
              isMulti,
              calendarData,
              calendarName,
            )}
            target="_blank"
            rel="noopener noreferrer"
            {...(calendar.service === 'iCal' && {
              download: icsFileName,
            })}
          >
            {hasServiceIcons && defaultCalIcons[calendar.service]}
            <span className="m-l-xs">
              {calendar.label ? calendar.label : calendar.service}
            </span>
          </a>
        </li>
      );
    });

    return optionItems;
  };

  const onButtonClick = (event: any) => {
    event.stopPropagation();
    !isProcessing && setIsDropdownOpen(!isDropdownOpen);
  };

  const watchCalendarOptionsStyle = {
    top:
      optionsPosition === 'topLeft' || optionsPosition === 'topRight'
        ? '-175px'
        : undefined,
    left:
      optionsPosition === 'topLeft'
        ? size === 2
          ? '-134px'
          : '-139.9px'
        : undefined,
  };

  return (
    <div
      className={cn(s.calendar_wrapper, { [s.watch]: isWatch })}
      ref={mainRef}
    >
      <div
        className={s.calendar_btn}
        onClick={onButtonClick}
        style={{
          height: !isWatch ? btnHeight : size === 1 ? 36 : 44,
          padding: size === 1 ? 10 : 13,
        }}
      >
        {!isWatch ? (
          <>
            {hasButtonIcon && (
              <CalendarIcon width={fontSize} height={fontSize} />
            )}
            <span className={s.calendar_label} style={{ fontSize }}>
              {buttonName}
            </span>
            {isProcessing ? (
              <ClipLoader size={loaderSize} color={'#6d7f92'} />
            ) : (
              <DropdownIcon width={dropdownSize} />
            )}
          </>
        ) : (
          <CalendarPlusIcon width={16} height={16} />
        )}
      </div>
      {isDropdownOpen && (
        <div
          className={s.calendar_options}
          onMouseLeave={() => setIsDropdownOpen(false)}
          style={isWatch ? watchCalendarOptionsStyle : undefined}
        >
          <ul>{renderCalendarOptions()}</ul>
        </div>
      )}
    </div>
  );
};

export default AddToCalendarBtn;
