import axios from 'axios';
import { TTP_API_URL } from 'config';
import { Filter, SingleValueFilter } from 'services/Filter';
import { TTPSort } from 'store/types';

export const getUserOrders = ({
  token,
  userId,
  filters = [],
  sort = [],
}: {
  token: string;
  userId: number;
  filters?: Filter[];
  sort?: TTPSort[];
}) => {
  const requestUrl = `${TTP_API_URL}/billing/order`;
  const fields = ['id', 'appWorkload', 'excludingVatAmount', 'events'];
  const sortBy = [...sort, { property: 'documentDate', dir: 'DESC' }];
  const filter = [
    ...filters,
    new SingleValueFilter('app', 'eq', 'EVENT'),
    new SingleValueFilter('user', 'eq', userId),
  ].map((filter) => filter.serialize());

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sortBy),
      nolimit: 1,
    },
  });
};

export const cancelOrder = ({
  token,
  guestId,
  orderId,
}: {
  token: string;
  guestId: number;
  orderId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/register/cancel-guest-order`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('guestId', String(guestId));
  formData.append('orderId', String(orderId));

  return axios.post(requestUrl, formData);
};
