import {
  AuthState,
  User,
  AuthActionTypes,
  SET_AUTH,
  INITIALIZE_AUTH,
  SET_EXPIRES_IN,
  SET_AUTH_LOGGED_AS_POST,
  SET_AUTH_LOGGED_AS,
  SET_AUTH_TOKEN,
  FETCH_AUTH_USER,
  SET_AUTH_USER,
  SET_TOKEN_CREATED_AT,
  SET_ACTIVE_APPS,
  SET_NAV_COMMUNITY,
  FETCH_APP_TOKEN,
  USER_ROLE,
  FETCH_EVENT_AUTHORIZATION,
  EventAuthorization,
  SET_AUTH_FETCHING,
  SAVE_ORGANIZATION_DATA,
  SET_USER_PREMIUM_STATUS,
  SET_USER_PREMIUM_SHARED,
} from './types';
import { Organization, AppToken } from 'store/types';
import { PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';

export const initializeAuth = (
  initialState?: Partial<AuthState>,
): AuthActionTypes => ({
  type: INITIALIZE_AUTH,
  initialState,
});

export const setAuth = (auth: AuthState): AuthActionTypes => ({
  type: SET_AUTH,
  auth,
});

export const setExpiresIn = (expiresIn: string): AuthActionTypes => ({
  type: SET_EXPIRES_IN,
  expiresIn,
});

export const setTokenCreatedAt = (createdAt: string): AuthActionTypes => ({
  type: SET_TOKEN_CREATED_AT,
  createdAt,
});

export const setAuthUser = (user: User): AuthActionTypes => ({
  type: SET_AUTH_USER,
  user,
});

export const setAuthFetching = (fetching: boolean): AuthActionTypes => ({
  type: SET_AUTH_FETCHING,
  fetching,
});

export const fetchAuthUser = (user: Promise<User> | User): AuthActionTypes => ({
  type: FETCH_AUTH_USER,
  payload: user,
});

export const setAuthToken = (token: string): AuthActionTypes => ({
  type: SET_AUTH_TOKEN,
  token,
});

export const setAuthLoggedAs = (loggedAs: USER_ROLE): AuthActionTypes => ({
  type: SET_AUTH_LOGGED_AS,
  loggedAs,
});

export const setAuthLoggedAsPost = (loggedAsPost: string): AuthActionTypes => ({
  type: SET_AUTH_LOGGED_AS_POST,
  loggedAsPost,
});

export const setNavCommunity = (
  community: Organization | null,
): AuthActionTypes => ({
  type: SET_NAV_COMMUNITY,
  community,
});

export const setActiveApps = (resp: any): AuthActionTypes => ({
  type: SET_ACTIVE_APPS,
  payload: resp,
});

export const fetchAppToken = (
  appToken: Promise<AppToken> | AppToken,
): AuthActionTypes => ({
  type: FETCH_APP_TOKEN,
  payload: appToken,
});

export const fetchEventAuthorization = (
  authorizations: Promise<AppToken> | EventAuthorization[],
): AuthActionTypes => ({
  type: FETCH_EVENT_AUTHORIZATION,
  payload: authorizations,
});

export const saveOrganizationData = (resp: any): AuthActionTypes => ({
  type: SAVE_ORGANIZATION_DATA,
  payload: resp,
});

export const setUserPremiumStatus = (
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS,
): AuthActionTypes => ({
  type: SET_USER_PREMIUM_STATUS,
  userPremiumStatus,
});

export const setUserPremiumShared = (
  userPremiumShared: boolean,
): AuthActionTypes => ({
  type: SET_USER_PREMIUM_SHARED,
  userPremiumShared,
});

export default {
  setAuth,
  setExpiresIn,
  setTokenCreatedAt,
  setAuthUser,
  fetchAuthUser,
  setAuthToken,
  setAuthLoggedAs,
  setAuthLoggedAsPost,
  setNavCommunity,
  setActiveApps,
  saveOrganizationData,
  setUserPremiumStatus,
  setUserPremiumShared,
};
