import { AnyAction } from 'redux';
import { PaginationState } from './types';
import { AppReducer, GenericReducerTypes } from 'store/types';

export const paginationInitialState = {
  page: 1,
  pageSize: 8,
};

export const withPagination = <S extends PaginationState, A extends AnyAction>(
  reducer: AppReducer<S, A>,
  reducerName: GenericReducerTypes,
) => (state: S, action: A) => {
  switch (action.type) {
    case `SET_${reducerName}_PAGE`:
      return { ...state, page: action.page };
    case `SET_${reducerName}_PAGE_SIZE`:
      return { ...state, pageSize: action.pageSize };
    default:
      return reducer(state, action);
  }
};

export default withPagination;
