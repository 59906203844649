import React from 'react';
import s from '../StepsSummary.module.scss';
import cn from 'classnames';
import _ from 'i18n';
import { StepTitle } from 'components/Tools/Summary/StepTitle';
import { Price } from 'components/ui/PriceLabel/PriceLabel';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import { isEmpty } from 'lodash';
import Help from 'components/ui/Help';
import Markdown from 'markdown-to-jsx';

interface Props {
  optionsData: {
    optionName: string;
    description?: string;
    price: number;
    originalPrice?: number;
  }[];
  hideEdit?: boolean;
  className?: string;
  onEdit?: () => void;
}

export const OptionsSummary = ({
  optionsData,
  className,
  hideEdit,
  onEdit,
}: Props) => {
  return (
    <div className={cn('m-b-s', className)}>
      <StepTitle title={_('Options choice')} />
      {optionsData.map(
        ({ optionName, description, price, originalPrice }, index) => {
          const _originalPrice = originalPrice ?? 0;

          return (
            <div key={index} className="flex-container align-justify m-b-xs">
              <div className="flex-container align-middle">
                <span className={s.big_text}>{optionName}</span>
                {!isEmpty(description) && description && (
                  <Help
                    className="m-l-xs"
                    title={optionName}
                    contentStyle={{ width: '263px' }}
                    align={{ offset: [0, -32] }}
                    placement="leftTop"
                  >
                    <Markdown className="markdown">{description}</Markdown>
                  </Help>
                )}
              </div>
              <div className="text-right">
                {_originalPrice > 0 && price !== originalPrice && (
                  <Price
                    size={'medium'}
                    price={_originalPrice}
                    background={'#FFDAE0'}
                    className={'m-r-xs strike-price small'}
                    style={{ padding: '0.25rem 0.75rem' }}
                  />
                )}
                {price > 0 && (
                  <Price
                    size={'medium'}
                    price={price}
                    background={'#F1F2F4'}
                    style={{ padding: '0.25rem 0.75rem' }}
                  />
                )}
              </div>
            </div>
          );
        },
      )}
      <div className="flex-container align-right">
        {!hideEdit && (
          <button className={cn(s.circle_btn)} onClick={onEdit}>
            <EditIcon fill="#6D7F92" width="12px" />
          </button>
        )}
      </div>
    </div>
  );
};

export default OptionsSummary;
