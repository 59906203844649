import {
  FETCH_EVENTS_CURRENT,
  SET_EVENTS_CURRENT_PAGE_SIZE,
  SET_EVENTS_CURRENT_PAGE,
} from './types';

export const fetchEventsCurrent = (
  resp: any,
): { payload: any; type: string } => ({
  type: FETCH_EVENTS_CURRENT,
  payload: resp,
});

export const setEventsCurrentPageSize = (
  pageSize: number,
): { payload: any; type: string } => ({
  type: SET_EVENTS_CURRENT_PAGE_SIZE,
  payload: pageSize,
});

export const setEventsCurrentPage = (
  page: number,
): { payload: any; type: string } => ({
  type: SET_EVENTS_CURRENT_PAGE,
  payload: page,
});

export default {
  fetchEventsCurrent,
  setEventsCurrentPageSize,
  setEventsCurrentPage,
};
