import React, { memo } from 'react';
import styles from './NextStep.module.scss';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import classNames from 'classnames';
import { LayoutTheme } from 'components/Layout/services';
import _ from 'i18n';
import cn from 'classnames';
interface Props {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  theme?: LayoutTheme;
  text?: string;
  hideIcon?: boolean;
}

export default memo(function NextStep({
  disabled,
  className,
  theme = '',
  text,
  hideIcon,
  onClick,
}: Props) {
  return (
    <button
      className={classNames(className, styles.submit, styles[theme])}
      type="submit"
      onClick={onClick}
      disabled={disabled}
    >
      <span className={cn(!hideIcon && 'm-r-xs')}>{text ?? _('Continue')}</span>{' '}
      {!hideIcon && <ChevronRightIcon width="18" height="15" />}
    </button>
  );
});
