import React, { useEffect } from 'react';
import styles from './UpComingEventPopup.module.scss';
import {
  bindWebinarLink,
  formatDateFromTo,
  getByLanguage,
  hasEventAdminAccess,
  hasPartnerManagerAccess,
  isEventRegistrationOpen,
  isEventReplayable,
  isEventStageOpen,
  isFreeEvent,
  capFirstLetterInSentence,
  isEventIncludedInPremium,
  formatDateEndOfReplay,
  isEventLive,
  getCroppedImageUrl,
  capitalizeFirstLetter,
  parseJson,
  isWebinarEvent,
  prepareS3ResourceUrl,
  getEventRegisterLink,
} from 'utils';
import Avatar from 'ui/Avatar';
import { usePremiumOffer } from 'hooks/usePremiumOffer';
import { Link } from 'react-router-dom';
import t from 'i18n';
import { Language, RootState, Organization } from 'store/types';
import { Event, EVENT_STAGES, EVENT_TYPES } from 'store/Events/types';
import { URLS } from 'router';
import cn from 'classnames';
import {
  CONGRESS_IDS,
  S3_FOLDER_URL,
  LIVE_COMING_EVENT_POPUP,
  S3_FOLDER_URL_PROD,
} from 'config';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { ReactComponent as AwardIcon } from 'assets/icons/award.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as HybridIcon } from 'assets/icons/hybrid.svg';
import LiveIcon from 'ui/LiveIcon';
import { useCountdown } from 'hooks/useCountDown';
import PremiumFlag from 'ui/PremiumFlag';
import { FetchSlotsParamsType } from 'store/Slots/thunks';
import { Slot } from 'store/Slots/types';
import moment from 'moment/moment';
import { REGISTRATION_TAB } from 'Webinar/Tools/services';

interface Options {
  showBackOffice: boolean;
  showReplayInfo?: boolean;
  showPrice?: boolean;
}

interface Props {
  slots: Slot[];
  language: Language;
  event: Event;
  options?: Options;
  userId?: number;
  isMember?: boolean;
  price?: number;
  isRegisteredToPremium?: boolean;
  community?: Organization | null;
  fetchSlots: (params: FetchSlotsParamsType) => void;
  closeModal: () => void;
}

export const UpComingEventPopup = ({
  event,
  slots,
  options,
  isMember,
  price,
  community,
  language,
  fetchSlots,
  closeModal,
}: Props) => {
  const { startDateTime, endDateTime, memberPrice, nonMemberPrice } = event;
  const session = slots[0];
  const speakersData = event['speakers-abstract']?.speakers;
  const {
    firstName: firstName = undefined,
    lastName: lastName = undefined,
    pictureUrl: pictureUrl = undefined,
  } = (speakersData && speakersData[0]) || {};
  const SpeakerFullname = `${firstName} ${lastName}`.trim();
  let headline = getByLanguage(
    speakersData && speakersData[0],
    'headline',
    language,
  );
  if (typeof headline === 'object') {
    headline = headline.title;
  } else if (typeof parseJson(headline) === 'object') {
    headline = parseJson(headline).title;
  }
  const isLive = isEventLive(event);
  const headlineCustom =
    !isEmpty(headline) && headline.length > 120
      ? `${headline.slice(0, 120)}...`
      : headline;
  const dateHelper = formatDateFromTo(
    startDateTime ?? '',
    endDateTime ?? '',
    language,
  );
  const name = getByLanguage(event, 'name', language, true);
  const title =
    !isEmpty(name) && name.length > 37 ? `${name.slice(0, 37)}...` : name;
  const urlBanner = getByLanguage(event, 'urlBanner', language) ?? '';
  const banner = getCroppedImageUrl(urlBanner, undefined, 280);
  const bannerImgUrl = !isEmpty(banner)
    ? prepareS3ResourceUrl(S3_FOLDER_URL, banner)
    : '/img/event-banner-fallback-light.png';
  const isCurrentCongress = CONGRESS_IDS.includes(event.id);
  const eventIdsResource = useSelector(
    (state: RootState) => state.event.eventIds,
  );
  const isActive = event['user-registered'];
  const isReplayable = isEventReplayable(event as Event);
  const isFree = isFreeEvent(event as Event);
  const isEventFull = +(event.type ?? 0) == EVENT_TYPES.EVENT_FULL;
  const configBag = parseJson(event.configBag);
  const linkWebinarlive = getByLanguage(
    configBag,
    'linkWebinar',
    language,
    true,
  );
  const multiDateEventParam =
    event.isMultiDate && event.selectedDate
      ? `?selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
      : '';
  const linkWebinar = linkWebinarlive
    ? linkWebinarlive + multiDateEventParam
    : isCurrentCongress
    ? `/event/${event.id}/session?tool=program`
    : session
    ? session.webinarUrlEn + multiDateEventParam
    : event.slotReplayUrls?.webinarUrlEn + multiDateEventParam ?? '';
  const {
    hours: diffHoursWithNow,
    minutes: diffMinutesWithNow,
    seconds: diffsecondsWithNow,
    counter: defaultPopupTimeout,
  } = useCountdown(event.startDateTime, LIVE_COMING_EVENT_POPUP.popupTimeout);
  const belgiumCurrentdate = new Date().toLocaleString('EN', {
    timeZone: 'Europe/Brussels',
  });
  const congressPopupLaunchTime =
    moment(belgiumCurrentdate).isBetween(
      moment(event.startDateTime),
      moment(event.startDateTime.replace(/ .*/, ' 18:30:00')),
    ) ||
    moment(belgiumCurrentdate).isBetween(
      moment(event.startDateTime).add(1, 'days'),
      moment(event.endDateTime),
    );
  const openPopup =
    (diffHoursWithNow == 0 &&
      (diffMinutesWithNow || diffMinutesWithNow == 0) &&
      diffMinutesWithNow <= LIVE_COMING_EVENT_POPUP.popupLaunchTime) ||
    (isCurrentCongress && congressPopupLaunchTime);
  const eventdateEndOfReplay = formatDateEndOfReplay(
    event.dateEndOfReplay
      ? event.dateEndOfReplay
      : new Date().getFullYear() + '-12-31 23:59:59',
  );
  const isManager = hasPartnerManagerAccess(
    eventIdsResource.items[0],
    event.id,
  );
  const isEventAdmin = hasEventAdminAccess(eventIdsResource.items[0], event.id);
  const premiumOffer = usePremiumOffer();
  const isSessionsOpen = isEventStageOpen(
    event,
    EVENT_STAGES.SHOW_SESSIONS_PAGE,
    {
      isEventAdmin,
      hadPartnerManager: isManager,
    },
  );
  const isWebinar = isWebinarEvent(event);
  const place = getByLanguage(event, 'place', language);

  const closePopupModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (!isEventFull) {
      const sort = [{ property: 'startDateTime', dir: 'ASC' }];
      fetchSlots({ sort, ids: [event.id], nolimit: true, withRooms: false });
    }
  }, [event.id, fetchSlots, isEventFull]);

  useEffect(() => {
    if (
      defaultPopupTimeout == LIVE_COMING_EVENT_POPUP.popupTimeout ||
      !openPopup
    ) {
      closeModal();
    }
  }, [defaultPopupTimeout, openPopup]);

  const renderReplayInfo = () => {
    if (!isReplayable || (options && !options.showReplayInfo)) {
      return null;
    }
    return (
      <li style={{ lineHeight: '13px' }}>
        <PlayIcon width="14" height="14" fill="#29394D" className="m-r-xs" />
        <span>
          {t('training_info_replay').replace('{date}', eventdateEndOfReplay)}
        </span>
      </li>
    );
  };

  const renderEventPrice = () => {
    if ((options && !options.showPrice) || isCurrentCongress) {
      return null;
    }

    if (isFree) {
      return (
        <div className={styles.price}>{capitalizeFirstLetter(t('Free'))} </div>
      );
    }

    const eventPrice =
      (isMember ? memberPrice ?? nonMemberPrice : nonMemberPrice) ?? 0;

    if (!price || +price > +eventPrice) {
      return <div className={styles.price}>{eventPrice} €</div>;
    }

    return (
      <div className={styles.price}>
        <span className="m-r-xs">{price} €</span>
        {/*<span className={styles.strike}>{eventPrice} €</span>*/}
      </div>
    );
  };
  const renderActions = () => {
    return (
      <div className={cn(styles.actions, styles.fullWidth)}>
        {renderEventPrice()}
        {isEventRegistrationOpen(event) && (
          <>
            {!isActive ? (
              <Link
                className={styles.green}
                to={getEventRegisterLink(event, false, event.multiDateIndex)}
              >
                {t(isFree ? 'register' : 'Buy training')}
              </Link>
            ) : (
              <a
                href={linkWebinar}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  styles.actions,
                  'greetings',
                  isLive ? styles.red : styles.blue,
                )}
                style={{ color: '#FFF' }}
                onClick={closePopupModal}
              >
                <ExternalLinkIcon width="10" height="10" />
                {isLive ? t('Join the live') : t('Rejoin')}
              </a>
            )}
          </>
        )}
        <Link
          to={bindWebinarLink(
            URLS.webinar.reception.home,
            String(event.id ?? 0),
            `tool=${REGISTRATION_TAB}${
              event.multiDateIndex ? `&dateIndex=${event.multiDateIndex}` : ''
            }`,
          )}
        >
          {t('more_details')}
        </Link>
        {isSessionsOpen && (
          <Link
            to={bindWebinarLink(URLS.webinar.webinarSessions.all, event.id)}
          >
            {t('Browse the program')}
          </Link>
        )}
      </div>
    );
  };

  const renderDates = () => {
    if (isEmpty(dateHelper)) {
      return null;
    }
    return (
      <>
        {!isCurrentCongress && !isLive && (
          <li>
            <CalendarIcon
              width="14"
              height="14"
              fill="#29394D"
              className="m-r-xs"
            />
            <span>{dateHelper}</span>
          </li>
        )}
      </>
    );
  };

  const RenderIncludedAttestation = () => {
    const label = event.isCertificateNotIncluded ? (
      <span>{t('Certificate not included')}</span>
    ) : (
      <span>
        {t('Certificate included approved by')} <b>&thinsp;ITAA</b>
      </span>
    );

    return (
      <li>
        <AwardIcon width="14" height="14" fill="#29394D" className="m-r-xs" />
        {label}
      </li>
    );
  };

  const renderSpeakers = () => {
    const speakersLenght = speakersData ? speakersData?.length : 0;
    if (!speakersLenght) {
      return null;
    }
    if (speakersLenght > 1)
      return (
        <div
          className={cn(styles.infos, 'm-b-xs')}
          style={{
            color: '#29394D',
          }}
        >
          {speakersLenght} {t('Speakers').toLowerCase()}
        </div>
      );
    return (
      <div className={cn(styles.speaker, 'm-b-xs')}>
        <div className={styles.speaker_wrapper}>
          <div
            className={styles.infos}
            style={{
              display: 'flex',
              color: '#29394D',
              paddingBottom: '0.65rem',
            }}
          >
            <Avatar
              className={styles.avatarWrapper}
              url={prepareS3ResourceUrl(S3_FOLDER_URL_PROD, pictureUrl ?? '')}
              firstName={firstName}
              lastName={lastName}
              width="40px"
              height="40px"
              fontSize="1rem"
            />
            <div className={'m-t-xs'}>{SpeakerFullname}</div>
          </div>
          <div
            style={{
              fontSize: '0.7rem',
              marginLeft: '3.39rem',
              color: '#6D7F92',
              position: 'relative',
              marginTop: '-2rem',
            }}
          >
            {headlineCustom}
          </div>
        </div>
      </div>
    );
  };

  const renderTrainingMode = () => {
    if (!isWebinar && place) {
      return (
        <li>
          <MapPinIcon
            width="14"
            height="14"
            fill="#29394D"
            className="m-r-xs"
          />
          <span>{place.split('contact')[0]}</span>
        </li>
      );
    }

    if (isWebinar && place) {
      return (
        <li>
          <HybridIcon
            width="14"
            height="14"
            fill="#29394D"
            className="m-r-xs"
          />
          <span>{t('Virtual and Presential')}</span>
        </li>
      );
    }

    return (
      <li>
        <EarthIcon width="14" height="14" fill="#29394D" className="m-r-xs" />
        <span>{t('Webinar')}</span>
      </li>
    );
  };

  return (
    <div>
      {openPopup ? (
        <div
          className={cn(styles.event_banner, isActive && styles.active)}
          style={isCurrentCongress ? { height: 'unset' } : {}}
        >
          <div className={'h100'}>
            <div className={cn('grid-x')}>
              <div
                className={cn(styles.banner, 'small-4 medium-4 large-4 p-l-l')}
                style={{ backgroundImage: `url(${bannerImgUrl})` }}
              >
                {!isEmpty(event.clientData?.avatarUrl) && (
                  <div className={styles.logoWrapper}>
                    <span
                      style={{
                        backgroundImage: `url(${event.clientData?.avatarUrl})`,
                      }}
                    />
                  </div>
                )}
                {event.isIncludedPremium && (
                  <PremiumFlag className={styles.premiumFlag} />
                )}
              </div>

              <div
                className={cn(
                  styles.details,
                  'small-8 medium-8 large-8 p-l-l p-r-xxl',
                )}
              >
                {!isCurrentCongress && (
                  <div className={cn(styles.live)}>
                    {isLive
                      ? t('ongoing_since').toUpperCase()
                      : t('ongoing_in').toUpperCase()}
                    <span className={styles.timeWrapper}>
                      {' '}
                      <span
                        className={cn(styles.text, 'm-r-xs')}
                        style={{ fontSize: '22px' }}
                      >
                        {diffMinutesWithNow} min
                      </span>
                    </span>
                    <span style={{ marginLeft: '1rem' }}>:</span>
                    <span className={cn(styles.timeWrapper)}>
                      {' '}
                      <span
                        className={cn(styles.text, 'm-r-xs')}
                        style={{ fontSize: '22px' }}
                      >
                        {diffsecondsWithNow} sec
                      </span>
                    </span>
                  </div>
                )}
                <div className={styles.title}>
                  {capitalizeFirstLetter(title)}
                </div>
                {renderSpeakers()}
                <ul>
                  <li>
                    {isLive && (
                      <>
                        <LiveIcon className="m-r-xs" size="14px" />
                        <span style={{ color: '#fe3745' }}>
                          <strong>
                            {capFirstLetterInSentence(t('ongoing_now'))}
                          </strong>
                        </span>
                      </>
                    )}
                  </li>
                  {renderDates()}
                  {renderTrainingMode()}
                  {renderReplayInfo()}
                  {RenderIncludedAttestation()}
                </ul>
                <div className="m-t-m">{renderActions()}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UpComingEventPopup;
