import { LANGUAGES } from 'config';
import _ from 'i18n';
import { isEmpty, upperCase } from 'lodash';
import { UserInscriptionState } from 'store/Guests/types';
import moment from 'moment';
import { School } from 'interfaces/School';

export type PersonalDataFormValues = ReturnType<typeof getFormInitialValues>;

export const languagesOptions = LANGUAGES.map((lng) => ({
  value: lng,
  label: upperCase(lng),
}));

export const sexOptions = [
  {
    value: 'MALE',
    label: 'A man',
  },
  {
    value: 'FEMALE',
    label: 'A woman',
  },
];

export const accesGratuiteOptions = [
  {
    label: 'inscription.itaa',
    options: [
      { label: 'inscription.membre_itaa', value: 'mItaa' },
      { label: 'inscription.stagiaire_itaa', value: 'sItaa' },
      { label: 'inscription.collaborateur_itaa', value: 'cItaa' },
    ],
  },
  {
    label: 'inscription.ire',
    options: [
      { label: 'inscription.membre_ire', value: 'mIre' },
      { label: 'inscription.stagiaire_ire', value: 'sIre' },
      { label: 'inscription.collaborateur_ire', value: 'cIre' },
    ],
  },
  {
    label: 'inscription.entreprise',
    options: [
      { label: 'inscription.salarie', value: 'salarie' },
      { label: 'inscription.independant', value: 'independant' },
    ],
  },
  {
    label: 'inscription.enseignement',
    options: [
      { label: 'inscription.etudiant', value: 'student' },
      { label: 'inscription.enseignant', value: 'teacher' },
    ],
  },
  { label: 'inscription.autre', value: 'autre' },
];

export const certificationParam1Options = [
  { label: _('inscription.interne'), value: 'interne' },
  { label: _('inscription.externe'), value: 'externe' },
];

export const MemberItaaTitles = [
  { label: 'inscription.title.accountant', value: 'accountant' },
  { label: 'inscription.title.taxAccountant', value: 'tax Accountant' },
  {
    label: 'inscription.title.certifiedAccountant',
    value: 'certified Accountant',
  },
  {
    label: 'inscription.title.certifiedTaxAdvisor',
    value: 'certified Tax Advisor',
  },
  {
    label: 'inscription.title.certifiedTaxAccountant',
    value: 'certified Tax Accountant',
  },
];

export const StudentItaaTitles = [
  {
    label: 'inscription.title.stagiaireCertifiedAccountant',
    value: 'stagiaire Certified Accountant',
  },
  {
    label: 'inscription.title.stagiaireCertifiedTaxAdvisor',
    value: 'stagiaire Certified Tax Advisor',
  },
];

export const getFormInitialValues = (
  userInscriptionState?: UserInscriptionState,
  defaultOrg?: string,
) => {
  const {
    mainPhone,
    birthday,
    language,
    gender,
    organization,
    function: _function,
    membership,
  } = userInscriptionState ?? {};

  const {
    status,
    studyDegree,
    school,
    studyTitle,
    dateFromStudy,
    certificationTitle,
    certificationNumber,
    certificationParam1,
  } = membership?.data ?? {};

  return {
    mainPhone: mainPhone ?? '',
    birthday: !isEmpty(birthday) ? moment(birthday) : null,
    language: language ?? '',
    gender: gender ?? '',
    organization: defaultOrg?.length ? defaultOrg : organization ?? '',
    function: _function ?? '',
    status: status ?? null,
    studyDegree: studyDegree ?? '',
    school: school ?? '',
    studyTitle: studyTitle ?? '',
    dateFromStudy: !isEmpty(dateFromStudy) ? moment(dateFromStudy) : null,
    certificationTitle: certificationTitle ?? '',
    certificationNumber: certificationNumber ?? '',
    certificationParam1: !isEmpty(certificationParam1)
      ? certificationParam1
      : null,
  };
};

export const isRequiredCertificateFields = (
  membershipStatus: string | null,
) => {
  if (!membershipStatus) {
    return false;
  }

  return ['mItaa', 'sItaa', 'cItaa', 'mIre', 'sIre', 'cIre'].includes(
    membershipStatus,
  );
};

export const isRequiredStudyFields = (membershipStatus: string | null) => {
  if (!membershipStatus) {
    return false;
  }

  return ['teacher', 'student'].includes(membershipStatus);
};

export const isRequiredSchoolFields = (schoolValue: string | null) =>
  schoolValue !== 'other';

export const getSchoolOptions = (schools?: School[]) => {
  const schoolOptions = schools?.map(({ name }) => ({
    value: name,
    label: name,
  }));

  schoolOptions?.push({ value: 'other', label: _('inscription.autre') });

  return schoolOptions;
};
