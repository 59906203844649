import { isEmpty, isNil } from 'lodash';
import { Moment } from 'moment';
import { Cycle, CYCLE_STATUS, EventCycle } from 'store/Cycle/types';
import moment from 'moment';
import { Guest, RegistredChoice } from 'store/Guests/types';
import { bindLinkData, parseBoolean } from './app';
import { URLS } from 'router';
import { parseJson } from './common';
import { Language } from '../store/types';
import { FFF_ID, TTP_API_URL } from 'config';
import t from 'i18n';

export const CYCLE_PREMIUM_ID = 56;
export const TYPE_CYCLE = 1;
export const TYPE_SEASON = 2;
export const TYPE_ESSENTIAL = 3;
export const TYPE_PREMIUM = 4;

export const getCycleNbHours = (cycle: Cycle): number => {
  const { eventCycles } = cycle;

  if (isEmpty(eventCycles)) {
    return 0;
  }

  return eventCycles.reduce((acc, ec) => {
    if (isEmpty(ec) || isNaN(+ec.eventsAbstract?.accreditationHours)) {
      return acc;
    }
    return acc + +ec.eventsAbstract.accreditationHours;
  }, 0);
};

export const getCycleDateTime = (
  cycle: Cycle,
): { startDateTime: Moment | null; endDateTime: Moment | null } => {
  const { eventCycles } = cycle;

  if (isEmpty(eventCycles)) {
    return {
      startDateTime: null,
      endDateTime: null,
    };
  }

  const firstEventStartAt = eventCycles[0]?.eventsAbstract?.startDateTime;
  const lastEventEndAt =
    eventCycles[eventCycles.length - 1]?.eventsAbstract?.startDateTime;

  return {
    startDateTime: firstEventStartAt ? moment(firstEventStartAt) : null,
    endDateTime: lastEventEndAt ? moment(lastEventEndAt) : null,
  };
};

export const getCyclePrice = (cycle: Cycle) => {
  const {
    memberPrice: cycleMemberPrice,
    nonMemberPrice: cycleNonMemberPrice,
  } = cycle;

  const member = cycle?.allEventsPrices?.member;
  const nonMember = cycle?.allEventsPrices?.nonMember;

  return {
    memberPrice: {
      price: cycleMemberPrice,
      originalPrice: member && cycleMemberPrice < member ? member : null,
    },
    nonMemberPrice: {
      price: cycleNonMemberPrice,
      originalPrice:
        nonMember && cycleNonMemberPrice < nonMember ? nonMember : null,
    },
  };
};

export const getPremiumCyclePrice = (cycle: Cycle) => {
  const {
    memberPrice,
    nonMemberPrice,
    monthlyMemberPrice,
    monthlyNonMemberPrice,
  } = cycle;

  return {
    memberPrice: {
      yearlyPrice: memberPrice,
      monthlyPrice: monthlyMemberPrice,
    },
    nonMemberPrice: {
      yearlyPrice: nonMemberPrice,
      monthlyPrice: monthlyNonMemberPrice,
    },
  };
};

export const getRegistredPlan = (guest: Guest): 'EVENT' | 'CYCLE' => {
  const registeredChoice: RegistredChoice | undefined = parseJson(
    guest?.registeredChoice,
  );

  if (isEmpty(registeredChoice) || typeof registeredChoice !== 'object') {
    return 'EVENT';
  }

  return +registeredChoice.cycle > 0 ? 'CYCLE' : 'EVENT';
};

export const bindCycleLink = (
  link: string,
  cycleId: string | number,
  params?: string,
) => {
  const linkData = [
    {
      key: ':cycleId',
      value: cycleId,
    },
  ];

  return bindLinkData(link, linkData, params);
};

export const bindPremiumOfferLink = (
  link: string,
  premiumOfferId: string | number,
  params?: string,
) => {
  const linkData = [
    {
      key: ':premiumOfferId',
      value: premiumOfferId,
    },
  ];

  return bindLinkData(link, linkData, params);
};

export const isWebinarCycle = (cycle: Cycle) => true;

export const hasEventCycles = (cycle: Cycle) => {
  const { eventCycles } = cycle;
  return !isEmpty(eventCycles);
};

export const isCycleExpired = (cycle: Cycle) =>
  moment().isAfter(moment(cycle.endDateTime));

export const isCycleRegistrationOpen = (cycle: Cycle) =>
  +cycle.status === CYCLE_STATUS.ACTIVE; /*&& !isCycleExpired(cycle)*/

export const getRegisterCycleLink = (cycle: Cycle) => {
  if (!cycle) {
    return '';
  }

  const isSeason = isCycleSeason(cycle);

  if (isSeason || +cycle.client !== 9) {
    return bindCycleLink(URLS.cycle.reception.registration.root, cycle.id);
  }

  return `${URLS.planSelector}?cycleId=${cycle.id}`;
};

export const isPremiumOffer = (cycle?: Cycle) =>
  parseBoolean(cycle?.type == TYPE_PREMIUM);

export const isActivePremiumOffer = (cycle: Cycle) =>
  cycle?.id == CYCLE_PREMIUM_ID;

export const isCycleSeason = (cycle?: Cycle) =>
  parseBoolean(cycle?.type == TYPE_SEASON);

export const isCycleEssential = (cycle?: Cycle) =>
  parseBoolean(cycle?.type == TYPE_ESSENTIAL);

export const isCycle = (cycle?: Cycle) =>
  parseBoolean(cycle?.type == TYPE_CYCLE);

export const filterCycleByTitle = (cycles: Cycle[], search: string): Cycle[] =>
  cycles.filter((cycle) => String(cycle.id) == search);

export const filterCycleEvents = (
  cycle: Cycle,
  search: string,
  languages: Language[],
): Cycle => {
  if (!cycle || (!search.length && !languages.length)) return cycle;

  let filteredEvents: EventCycle[] = cycle.eventCycles;

  if (search.length) {
    filteredEvents = filteredEvents.filter(
      (event) =>
        // By Name
        String(event.eventsAbstract.nameFr).search(new RegExp(search, 'i')) !==
          -1 ||
        String(event.eventsAbstract.nameNl).search(new RegExp(search, 'i')) !==
          -1 ||
        String(event.eventsAbstract.nameEn).search(new RegExp(search, 'i')) !==
          -1 ||
        // By speakers
        event?.eventsAbstract.speakers?.filter(
          ({ firstName, lastName }) =>
            String(`${firstName} ${lastName}`).search(
              new RegExp(search, 'i'),
            ) !== -1 ||
            String(`${lastName} ${firstName}`).search(
              new RegExp(search, 'i'),
            ) !== -1,
        ).length,
    );
  }

  return { ...cycle, eventCycles: filteredEvents };
};

export const isCycleIncludedInPremium = (
  cycle: Cycle,
  communityId?: number,
) => {
  return cycle.client == FFF_ID && (communityId == FFF_ID || !communityId);
};

export const cycleAgreedBy = (cycle: Cycle) => {
  if (cycle.isAgreedByItaa && cycle.isAgreedByOBFG) {
    return t('certificate_agreedby_itaa_and_obfg');
  }

  if (cycle.isAgreedByItaa) {
    return t('certificate_agreedby_itaa');
  }

  if (cycle.isAgreedByOBFG) {
    return t('certificate_agreedby_obfg');
  }

  return t('certificate_agreedby_itaa');
};

export const infoCycleAgreedBy = (cycle: Cycle) => {
  if (cycle.isAgreedByItaa && cycle.isAgreedByOBFG) {
    return t('approved_by_itaa_and_obfg');
  }

  if (cycle.isAgreedByItaa) {
    return t('approved_by_itaa');
  }

  if (cycle.isAgreedByOBFG) {
    return t('approved_by_obfg');
  }

  return t('approved_by_itaa');
};

export const getCycleEventsCount = (cycle: Cycle) => {
  const { eventsCount, eventCycles, projectedEventsCount } = cycle;

  return eventsCount > 0
    ? eventsCount
    : projectedEventsCount > 0
    ? projectedEventsCount
    : eventCycles?.length;
};

export const getCycleIcsUrl = (
  cycleId: number,
  userId: number,
  language: Language,
) => {
  const encodedUserId = btoa(String(userId)).replace(/=/g, '');

  return `${TTP_API_URL}/event/ics-cycle/${language}/${cycleId}/${encodedUserId}`;
};
