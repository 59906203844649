import React, { memo, useState, useEffect, useCallback, Fragment } from 'react';
import { Organization, EventReducerNames, Language } from 'store/types';
import { EventState } from 'store/Events/types';
import InfiniteScroll from 'react-infinite-scroller';
import { Filter } from 'services/Filter';
import styles from './SearchResultPage.module.scss';
import lodash from 'lodash';
import _ from 'i18n';
import cn from 'classnames';
import { GoBackData, GoBackRouteType } from 'store/Params/GoBack';
import urls from 'router/urls';
import { isUserPremium, sortEventByStartDateTime } from 'utils';
import { ResourceState } from 'store/Resource/types';
import { eventFields } from 'store/Events/api';
import { prepareFilter } from 'components/EventFilters/services';
import { useCommunitiesFilter } from 'context/communitiesContext';
import { Cycle } from 'store/Cycle/types';
import { User } from 'store/Auth/types';
import { PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';
import { CompactEventCard } from 'components/EventCard';
import useResponsive from 'hooks/useResponsive';
import Container from 'components/Layout/Container';
import { FFF_ID } from '../../../../config';
export interface SearchResultPageProps {
  user?: User | null;
  community?: Organization | null;
  filters: Filter[];
  events: EventState;
  language: Language;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
  setGoBackCustomData: (route: GoBackRouteType, data: GoBackData) => void;
  resetGoBack: () => void;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
  premiumOffersResource: ResourceState<Cycle>;
  fetchEvents: (
    type: EventReducerNames,
    filters: Filter[],
    fields: string[],
    queryParams: any,
  ) => void;
  initializeEventFilter: () => void;
  wrapperClassName?: string;
}

export default memo(function SearchResultPage({
  user,
  community,
  events,
  filters,
  language,
  userPremiumStatus,
  setPage,
  setPageSize,
  fetchEvents,
  initializeEventFilter,
  setGoBackCustomData,
  resetGoBack,
  premiumOffersResource: { items: premiumOffers },
  wrapperClassName,
}: SearchResultPageProps) {
  const idCommunities = useCommunitiesFilter();
  const debouncedFetchEvents = useCallback(lodash.debounce(fetchEvents, 800), [
    fetchEvents,
  ]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const { fetching, items, page, pageSize, nbResult: total } = events;
  const premiumOffer = premiumOffers[0];
  const isRegisteredToPremium = isUserPremium(userPremiumStatus);
  const { isMobile, isDesktop } = useResponsive();

  useEffect(() => {
    setPageSize(16);
  }, []);

  useEffect(() => {
    setHasMoreItems(!fetching);
  }, [fetching]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    debouncedFetchEvents(
      'SEARCH',
      prepareFilter(filters, idCommunities),
      [...eventFields, 'speakers-abstract', 'user-registered'],
      {
        userId: user?.id,
        communityIds: FFF_ID,
        isWatchQuery: true,
      },
    );
  }, [debouncedFetchEvents, filters, page, pageSize]);

  useEffect(() => {
    const route = {
      path: urls.search,
      exact: false,
    };

    const data = {
      callBack: onPageExit,
      title: _('Advanced search'),
      goBackPath: { pathname: '/' },
    };

    setGoBackCustomData(route, data);

    return onPageExit;
  }, []);

  const onPageExit = () => {
    initializeEventFilter();
    resetGoBack();
  };

  const loadItems = () => {
    if (!fetching && items.length < total) {
      setHasMoreItems(false);
      setPage(page + 1);
    }
  };

  const renderEventItems = () => {
    let blocks: React.ReactNode[] = [];
    const eventsList = sortEventByStartDateTime(items);
    eventsList.map((event, index) => {
      blocks.push(
        <div
          className={cn(
            'cell small-12 medium-6 large-3 flex-container align-center',
            styles.event_card,
          )}
          key={index}
        >
          <CompactEventCard
            event={event}
            language={language}
            isFullWidth={isMobile}
          />
        </div>,
      );
    });

    return blocks;
  };

  const renderEvents = () => {
    return (
      <Fragment>
        <div className={styles.list}>
          <Container bgColor="#29394d" className={isDesktop ? 'm-t-xl' : ''}>
            <div className="grid-x">
              {renderEventItems()}

              {fetching &&
                [1, 2, 3, 4].map((el) => (
                  <li
                    className={cn(
                      'cell small-12 medium-6 large-3 flex-container align-center',
                      styles.event_card,
                    )}
                    key={`event-fetching-${el}`}
                  >
                    <CompactEventCard.Fetching isFullWidth={isMobile} />
                  </li>
                ))}
            </div>
          </Container>
        </div>
      </Fragment>
    );
  };

  return (
    <div className={cn(styles.search, wrapperClassName)}>
      <div className="section-body">
        {!fetching && (!items || items.length < 1) ? (
          <div className={styles.not_found}>
            {/* <div style={{ height: '160px' }} /> */}
            <h2>{_('Nothing Found')}...</h2>
            <p>{_('nothingFound')}</p>
          </div>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadItems}
            hasMore={hasMoreItems}
            initialLoad={false}
          >
            {renderEvents()}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
});
