import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { reducer as auth } from './Auth';
import { reducer as params } from './Params';
import { reducer as notifs } from './Notifs';
import { reducer as events } from './Events';
import { reducer as social } from './Social';
import { reducer as slotsCreator } from './Slots';
import { reducer as resource } from './Resource';
import { reducer as event } from './Event';
import { reducer as cycle } from './Cycle';
import { reducer as partnerAds } from './PartnerAds';
import { userLeads, partnerLeads, daily } from './PartnerLeads';
import { reducer as modal } from './Modal';
import { reducer as forms } from './Form';
import { reducer as subscriptions } from 'store/Subscription';
import { reducer as dataResource } from 'store/Resource/DataResource';
import { reducer as collaborators } from './Collaborators';
import { reducer as packs } from './Packs';
import { reducer as questions } from './Evaluation/Question';
import { reducer as answers } from './Evaluation/Answer';
import { reducer as eventsFuture } from './Home/Future';
import { reducer as eventsReplay } from './Home/Replay';
import { reducer as eventsCurrent } from './Home/Current';
import { reducer as tags } from './EventsTags';
import { reducer as orders } from './Orders';
import { reducer as privacy } from './Privacy';
import { reducer as categories } from './EventsCategories';
import { reducer as EventArticles } from './Articles';
import { reducer as rubrics } from './Rubrics';
import { reducer as eventTags } from './EventTags';
import { reducer as userData } from './User';

import {
  mediasReducer,
  partnerDocReducer,
  uploadingMediasReducer,
  mediaViewerReducer,
  commentReducer,
  favReducer,
} from './Media';
import { articlesReducer } from './Article';
import { reducer as subMenu } from './SubMenu';
import { reducer as channel } from './Channel';

import { EventState, EventActionTypes, Event } from './Events/types';
import {
  composeWithPaginationAndFilter,
  createNamedWrapperReducer,
} from './services';
import withPagination from './Pagination';
import { createEventReducer } from './Events/services';
import { ResourceState, ResourceActionTypes } from './Resource/types';
import {
  SPEAKER_RESOURCE,
  SLOTS_RESOURCE,
  PARTNERS_ADS_RESOURCE,
  RECOMMENDATION_SLIDER_RESOURCE,
  ICS_FILE_RESOURCE,
  EVENT_HANDOUTS_RESOURCE,
  SCANS_RESOURCE,
  SliderData,
} from './types';
import { Speaker } from './Speakers/types';
import { PartnerAd } from './PartnerAds/types';
import { DataResourceState } from './Resource/DataResource/types';
import { ICSFileType } from './ICalendar/types';
import { EventHandouts } from 'interfaces/SlotFile';
import { Scan } from 'interfaces/Scan';
import { Address } from 'interfaces/Address';

export function createReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    auth,
    params,
    subscriptions,
    notifs,
    slots: withPagination(slotsCreator(SLOTS_RESOURCE), SLOTS_RESOURCE),
    event,
    guest: combineReducers({
      shippingAddress: createNamedWrapperReducer<
        ResourceState<Address>,
        ResourceActionTypes<Address>
      >(resource, 'SHIPPING_ADDRESS_RESOURCE'),
    }),
    cycle,
    collaborators,
    packs,
    recommendation: composeWithPaginationAndFilter<
      ResourceState<SliderData>,
      ResourceActionTypes<SliderData>
    >(
      createNamedWrapperReducer<
        ResourceState<SliderData>,
        ResourceActionTypes<SliderData>
      >(resource, RECOMMENDATION_SLIDER_RESOURCE),
      RECOMMENDATION_SLIDER_RESOURCE,
    ),
    events: combineReducers({
      search: composeWithPaginationAndFilter<EventState, EventActionTypes>(
        createEventReducer<EventState, EventActionTypes>(events, 'SEARCH'),
        'SEARCH',
      ),
      list: composeWithPaginationAndFilter<EventState, EventActionTypes>(
        createEventReducer<EventState, EventActionTypes>(events, 'LIST'),
        'LIST',
      ),
      userFavoriteList:
        composeWithPaginationAndFilter<
          ResourceState<Event>,
          ResourceActionTypes<Event>
        >(
          createNamedWrapperReducer<
            ResourceState<Event>,
            ResourceActionTypes<Event>
          >(resource, 'FAVORITE_LIST'),
          'FAVORITE_LIST',
        ) ||
        composeWithPaginationAndFilter<EventState, EventActionTypes>(
          createEventReducer<EventState, EventActionTypes>(
            events,
            'FAVORITE_LIST',
          ),
          'FAVORITE_LIST',
        ),
      userList: composeWithPaginationAndFilter<
        ResourceState<Event>,
        ResourceActionTypes<Event>
      >(
        createNamedWrapperReducer<
          ResourceState<Event>,
          ResourceActionTypes<Event>
        >(resource, 'LIST_FOR_USER'),
        'LIST_FOR_USER',
      ),
    }),
    partnersAds: combineReducers({
      global: withPagination(
        createNamedWrapperReducer<
          ResourceState<PartnerAd>,
          ResourceActionTypes<PartnerAd>
        >(resource, PARTNERS_ADS_RESOURCE),
        PARTNERS_ADS_RESOURCE,
      ),
      current: partnerAds,
    }),
    speakers: createNamedWrapperReducer<
      ResourceState<Speaker>,
      ResourceActionTypes<Speaker>
    >(resource, SPEAKER_RESOURCE),
    social,
    evaluations: combineReducers({
      questions: questions,
      answers: answers,
    }),
    medias: combineReducers({
      listing: mediasReducer,
      uploading: uploadingMediasReducer,
      docs: partnerDocReducer,
      viewer: mediaViewerReducer,
      comments: commentReducer,
      favorites: favReducer,
    }),
    articles: articlesReducer,
    partnerLeads: combineReducers({
      current: userLeads,
      list: partnerLeads,
      daily: daily,
    }),
    iCalendar: createNamedWrapperReducer<
      DataResourceState<ICSFileType>,
      ResourceActionTypes<ICSFileType>
    >(dataResource, ICS_FILE_RESOURCE),
    handouts: composeWithPaginationAndFilter<
      ResourceState<EventHandouts>,
      ResourceActionTypes<EventHandouts>
    >(
      createNamedWrapperReducer<
        ResourceState<EventHandouts>,
        ResourceActionTypes<EventHandouts>
      >(resource, EVENT_HANDOUTS_RESOURCE),
      EVENT_HANDOUTS_RESOURCE,
    ),
    scans: composeWithPaginationAndFilter<
      ResourceState<Scan>,
      ResourceActionTypes<Scan>
    >(
      createNamedWrapperReducer<ResourceState<Scan>, ResourceActionTypes<Scan>>(
        resource,
        SCANS_RESOURCE,
      ),
      SCANS_RESOURCE,
    ),
    modal,
    forms,
    subMenu,
    EventArticles,
    channel,
    home: combineReducers({
      replay: eventsReplay,
      future: eventsFuture,
      current: eventsCurrent,
    }),
    tags,
    categories,
    orders,
    privacy,
    rubrics,
    eventTags,
    userData,
  });
}
