import {
  PrivacyState,
  PrivacyActionTypes,
  FETCH_PRIVACY_PENDING,
  FETCH_PRIVACY_FULFILLED,
  FETCH_PRIVACY_REJECTED,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  items: null,
  error: null,
};

export const reducer = (
  state: PrivacyState = initialState,
  action: PrivacyActionTypes,
) => {
  switch (action.type) {
    case FETCH_PRIVACY_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_PRIVACY_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data.data,
      };
    }
    case FETCH_PRIVACY_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
