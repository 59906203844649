import { User } from 'store/Auth/types';
import { PaginationState } from 'store/Pagination/types';
import {
  NameWrappedAction,
  EVENT_PARTNERS_RESOURCE,
  EVENT_STRUCTURAL_PARTNERS_RESOURCE,
  EVENT_PARTNERS_SEARCH_RESOURCE,
  Nullable,
} from 'store/types';

export interface Partner {
  id: number;
  nameFr: string;
  nameNl: string;
  nameEn: string;
  descriptionFr: string;
  descriptionNl: string;
  descriptionEn: string;
  pictureUrlFr: string;
  pictureUrlNl: string;
  pictureUrlEn: string;
  urlFr: string;
  urlNl: string;
  urlEn: string;
  event: number;
  emailFr: string;
  emailEN: string;
  emailNl: string;
  phoneNumberFr: string;
  phoneNumberEn: string;
  phoneNumberNl: string;
  address: string;
  url: string;
  userPartnerRoleEmails: string;
  dailyOnDemand: number;
  canDownloadLeadXls: boolean;
  bannerUrl: string;
  videoUrlFr: string;
  videoUrlNl: string;
  videoUrlEn: string;

  chatAutoRespondStatus: boolean | number;
  chatAutoRespondTextFr: string;
  chatAutoRespondTextNl: string;
  chatAutoRespondTextEn: string;

  extras?: {
    ads: {
      gifts: number;
      offers: number;
      generic: number;
    };
    documents: number;
    sessions: { conferences: number; demos: number; breakouts: number };
    documentsCountByLanguage: {
      en: string;
      fr: string;
      nl: string;
    };
  };

  userPartnerRoles?: UserPartnerRole[];
  type: 1 | 0 | 5;
  chain: number;
  isLight?: boolean;
}

export interface PartnerProfile extends Partner {
  pictureFileFr: Nullable<File>;
  pictureFileNl: Nullable<File>;
  pictureFileEn: Nullable<File>;
}

export interface UserPartnerRole {
  id: number;
  uid: string;
  event: number;
  partner: number;
  role: USER_PARTNER_ROLE;
  createdAt: string;
  updatedAt: string;
  user: User;
}

export enum USER_PARTNER_ROLE {
  ADMIN = 'ADMIN',
}

export interface PartnersState extends PaginationState {
  fetching: boolean;
  fetched: boolean;
  items: Partner[];
  error: any;
  nbResult: number;
}

export const UPDATE_PARTNER = 'UPDATE_PARTNER';

export type PartnersReducerNameType =
  | typeof EVENT_PARTNERS_RESOURCE
  | typeof EVENT_PARTNERS_SEARCH_RESOURCE
  | typeof EVENT_STRUCTURAL_PARTNERS_RESOURCE;

export interface UpdatePartnerAction extends NameWrappedAction {
  type: typeof UPDATE_PARTNER;
  partner: Partner;
}
export type PartnersActionTypes = UpdatePartnerAction;
