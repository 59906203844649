import { Breakpoints } from 'config';
import { useState, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

function useResponsive() {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.MEDIUM,
  });

  const isTablet = useMediaQuery({
    minWidth: Breakpoints.MEDIUM,
    maxWidth: Breakpoints.LARGE,
  });

  const isDesktop = useMediaQuery({
    minWidth: Breakpoints.LARGE,
  });

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isDesktop: isClient ? isDesktop : true,
    isTablet: isClient ? isTablet : false,
    isMobile: isClient ? isMobile : false,
  };
}

export default useResponsive;
