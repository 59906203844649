import React, { memo } from 'react';
import s from './ModalPackRegistration.module.scss';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import PackRegistration from 'components/Webinar/Reception/Registration/PackRegistration';
import { PackRegistrationData } from 'store/Modal/PackRegistration/types';

interface Props {
  modal: ModalState<PackRegistrationData>;
  onCloseModal: () => void;
}

export default memo(function ModalPackRegistration({
  modal,
  onCloseModal,
}: Props) {
  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      className={s.modal_content}
    >
      <CloseModal onClose={onCloseModal} />
      <PackRegistration {...modal.data} />
    </Modal>
  );
});
