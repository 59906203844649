import React, { useEffect } from 'react';
import { CompactEventCard } from 'components/EventCard';
import { isEmpty } from 'lodash';
import t from 'i18n';
import { Event } from 'store/Events/types';
import { FetchResourceThunkParams } from 'store/Resource/thunks';
import { ResourceState } from 'store/Resource/types';
import { Language } from 'store/types';
import { TTP_API_URL } from 'config';
import { SingleValueFilter } from 'services/Filter';
import EventsCarousel from 'components/Watch/EventsCarousel/EventsCarousel';

interface Props {
  userId?: number;
  currentEvent: Event;
  language: Language;
  eventsResource: ResourceState<Event>;
  fetchEvents: (params: FetchResourceThunkParams) => void;
  resetEvents: () => void;
}

const SimilarEventsCarousel = ({
  userId,
  currentEvent,
  language,
  eventsResource: { fetching, items: events, nbResult: totalEvents },
  fetchEvents,
  resetEvents,
}: Props) => {
  const { tag: tags, id: currentEventId } = currentEvent;

  useEffect(() => {
    if (!isEmpty(tags)) {
      fetchEvents({
        name: 'LIST_FOR_USER',
        requestUrl: `${TTP_API_URL}/event/event/get-list-for-user`,
        sort: [{ property: 'startDateTime', dir: 'ASC' }],
        fields: [
          '*',
          'speakers-abstract',
          'user-registered',
          'client',
          'tag',
          'slotReplayUrls',
        ],
        filters: [new SingleValueFilter('id', 'neq', currentEventId)],
        queryParams: {
          userId: userId,
          communityIds: 9,
          language,
          tags: tags.map(({ id }) => id).join(','),
        },
      });
    }

    return resetEvents;
  }, [language, userId, tags, currentEventId, fetchEvents, resetEvents]);

  if (!fetching && !events.length) {
    return (
      <div
        className="align-center-middle flex-container small-12"
        style={{ height: '5rem', fontSize: '10px', color: '#b2bcc6' }}
      >
        {t('No result found')}
      </div>
    );
  }

  if (fetching) {
    return (
      <div className="m-t-l">
        <EventsCarousel
          cards={[1, 2, 3, 4].map((index) => {
            return (
              <div style={{ padding: '0 5px' }} key={`event-fetching-${index}`}>
                <CompactEventCard.Fetching />
              </div>
            );
          })}
        />
      </div>
    );
  }

  return (
    <div className="m-t-l">
      <EventsCarousel
        settings={{
          infinite: events.length > 3,
        }}
        cards={events.map((event) => {
          return (
            <div style={{ padding: '0 5px' }} key={`event-${event.id}`}>
              <CompactEventCard event={event} language={language} />
            </div>
          );
        })}
      />
    </div>
  );
};

export default SimilarEventsCarousel;
