import { Partner } from 'store/Partners/types';
import { Organization } from 'store/types';

export interface PartnerAd {
  id: number;
  event: number;
  partner: Partner;
  viewCounter: number;
  clickCounter: number;

  titleFr: string;
  titleNl: string;
  titleEn: string;

  descriptionFr: string;
  descriptionNl: string;
  descriptionEn: string;

  buttonLabelEn: string;
  buttonLabelFr: string;
  buttonLabelNl: string;

  buttonUrlEn: string;
  buttonUrlFr: string;
  buttonUrlNl: string;

  expiryDate: string;

  medias: {
    mediaFr: AdMediaType;
    mediaNl: AdMediaType;
    mediaEn: AdMediaType;
  };

  languages: any;

  type: AD_TYPE;
  status: AD_STATUS;
  position: number;
  organization?: Organization;
}

export enum AD_TYPE {
  AD_TYPE_GIFT = 1,
  AD_TYPE_GENERIC = 2,
  AD_TYPE_OFFER = 3,
}

export enum AD_STATUS {
  STATUS_PUBLISHED = 1,
  STATUS_UNPUBLISHED = 2,
  STATUS_UNDEFINED = 0,
}

export interface AdMediaType {
  id: number;
  titleEn: string;
  titleFr: string;
  titleNl: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionNl: string;
  altEn: string;
  altFr: string;
  altNl: string;
  copyrightEn: string;
  copyrightFr: string;
  copyrightNl: string;
  docType: string;

  fullMediaUrl: string;
  webPath: string;

  meta: {
    width: number;
    height: number;
    mimeType: string;
    size: string;
    color: string;
  };
}

export enum DOC_STATUS {
  STATUS_NOT_PUBLISHED = 'NOT_PUBLISHED',
  STATUS_PUBLISHED = 'PUBLISHED',
  STATUS_EXPIRED = 'EXPIRED',
}

export interface PartnerDoc {
  id: number;
  status: DOC_STATUS;
  event: number;
  partner: Partner;
  updatedAt: string;
  createdAt: string;
  media: AdMediaType;
}

export interface PartnerAdState {
  fetching: boolean;
  fetched: boolean;
  items: PartnerAd[];
  error: any;
  nbResult: number;
}

export const RESET_PARTNER_AD = 'RESET_PARTNER_AD';
export const ADD_PARTNER_AD = 'ADD_PARTNER_AD';
export const EDIT_PARTNER_AD = 'EDIT_PARTNER_AD';
export const FILTER_PARTNER_AD = 'FILTER_PARTNER_AD';
export const DELETE_PARTNER_AD = 'DELETE_PARTNER_AD';
export const FETCH_PARTNER_AD = 'FETCH_PARTNER_AD';
export const FETCH_PARTNER_AD_PENDING = 'FETCH_PARTNER_AD_PENDING';
export const FETCH_PARTNER_AD_FULFILLED = 'FETCH_PARTNER_AD_FULFILLED';
export const FETCH_PARTNER_AD_REJECTED = 'FETCH_PARTNER_AD_REJECTED';

export interface ResetPartnerAdsAction {
  type: typeof RESET_PARTNER_AD;
}

export interface AddPartnerAdsAction {
  type: typeof ADD_PARTNER_AD;
  partnerAd: PartnerAd;
}

export interface EditPartnerAds {
  type: typeof EDIT_PARTNER_AD;
  partnerAd: PartnerAd;
}

export interface DeletePartnerAdsAction {
  type: typeof DELETE_PARTNER_AD;
  payload: number;
}

export interface FilterPartnerAdsAction {
  type: typeof FILTER_PARTNER_AD;
  payload: any;
}

export interface FetchPartnerAdsAction {
  type:
    | typeof FETCH_PARTNER_AD
    | typeof FETCH_PARTNER_AD_PENDING
    | typeof FETCH_PARTNER_AD_FULFILLED
    | typeof FETCH_PARTNER_AD_REJECTED;
  payload: any;
}

export type PartnerAdActionTypes =
  | FetchPartnerAdsAction
  | ResetPartnerAdsAction
  | AddPartnerAdsAction
  | EditPartnerAds
  | FilterPartnerAdsAction
  | DeletePartnerAdsAction;
