import { Address } from 'store/Guests/Invoicing/types';
import {
  Assignment,
  Coupon,
  CouponReduction,
  COUPON_REDUCTION_TYPE,
  Guest,
  GuestAddress,
  Order,
  ORDER_OPERATION_TYPE,
  ORDER_STATUS,
  PREMIUM_REGISTRATION_STATUS,
} from 'store/Guests/types';
import { isEmpty, isNil, map } from 'lodash';
import moment from 'moment';
import { addMonths, format, parse } from 'date-fns';

export const isGuestMemeber = (guest?: Guest | null) => {
  if (!guest) {
    return false;
  }
  return (guest.member ?? 0) > 0;
};

export const isEmptyAddress = (guest: Guest) => {
  return (
    isEmpty(guest.billingCompanyNumber) ||
    isEmpty(guest.billingOrganization) ||
    isEmpty(guest.billingStreet) ||
    isEmpty(guest.billingPostalCode)
  );
};

export const prepareGuestAddress = (
  guest: Guest,
  addresses: Address[],
  uen?: string,
) => {
  let addressData: GuestAddress;

  /**
   * When the uen is provided (via query params)
   */
  if (uen?.length) {
    const address = addresses.filter(
      (a) => a.uen.toLocaleLowerCase() === uen.toLocaleLowerCase(),
    );

    addressData = address.length
      ? {
          billingCompanyNumber: address[0].uen,
          billingOrganization: address[0].organization,
          billingStreet: address[0].street,
          billingPostalCode: `${address[0].zip} ${address[0].city}`.trim(),
          billingSubjectToVAT: address[0].vatApply == '1' ? '1' : '0',
          billingSignature: address[0].signature,
          billingAddress2: address[0].address2 ?? '',
          billingOrderNumber: address[0].orderNumber ?? '',
          billingCountry: address[0].country ?? '',
        }
      : {
          billingCompanyNumber: uen,
          billingOrganization: '',
          billingStreet: '',
          billingPostalCode: '',
          billingSubjectToVAT: '0',
          billingSignature: '',
          billingAddress2: '',
          billingOrderNumber: '',
          billingCountry: '',
        };

    return addressData;
  }

  const isAddressAvailable = addresses.some(
    (ad) => ad.signature === guest.billingSignature && !isEmpty(ad.signature),
  );

  const isEmptyGuestAddress = isEmptyAddress(guest);

  if (
    isEmpty(guest.billingSignature) &&
    isEmptyGuestAddress &&
    addresses.length > 0
  ) {
    /**
     * Apply the first adress by default when guest adress is empty
     */
    const address = addresses[0];

    addressData = {
      billingCompanyNumber: address.uen,
      billingOrganization: address.organization,
      billingStreet: address.street,
      billingPostalCode: `${address.zip} ${address.city}`.trim(),
      billingSubjectToVAT: address.vatApply == '1' ? '1' : '0',
      billingSignature: address.signature,
      billingAddress2: address.address2 ?? '',
      billingOrderNumber: address.orderNumber ?? '',
      billingCountry: address.country ?? '',
    };
  } else {
    // if (!isEmptyGuestAddress && !isAddressAvailable) {
    //   /**
    //    * If If the selected address is not found && the guest address is not empty
    //    *  */
    //   addressData = {
    //     billingCompanyNumber: guest.billingCompanyNumber ?? '',
    //     billingOrganization: guest.billingOrganization ?? '',
    //     billingStreet: guest.billingStreet ?? '',
    //     billingPostalCode: guest.billingPostalCode ?? '',
    //     billingSubjectToVAT: guest.billingSubjectToVAT == 1 ? '1' : '0',
    //     billingSignature: '', // let the signature empty, in order to open the form address
    //   };
    // }

    if (addresses.length > 0 && !isAddressAvailable) {
      /**
       * If the selected address is not found, we clear data inorder to chose a new adress
       */
      addressData = {
        billingCompanyNumber: '',
        billingOrganization: '',
        billingStreet: '',
        billingPostalCode: '',
        billingSubjectToVAT: guest.billingSubjectToVAT == 1 ? '1' : '0',
        billingSignature: guest.billingSignature ?? '',
        billingAddress2: '',
        billingOrderNumber: '',
        billingCountry: '',
      };
    } else {
      /**
       * If adress is Available OR If adressess[] is empty
       */
      addressData = {
        billingCompanyNumber: guest.billingCompanyNumber ?? '',
        billingOrganization: guest.billingOrganization ?? '',
        billingStreet: guest.billingStreet ?? '',
        billingPostalCode: guest.billingPostalCode ?? '',
        billingSubjectToVAT: guest.billingSubjectToVAT == 1 ? '1' : '0',
        billingSignature: guest.billingSignature ?? '',
        billingAddress2: guest.billingAddress2 ?? '',
        billingOrderNumber: guest.billingOrderNumber ?? '',
        billingCountry: guest.billingCountry ?? '',
      };
    }
  }

  return addressData;
};

export const filterDuplicateAndEmptyInvoicing = (addresses: Address[]) => {
  const filteredAddresses = addresses.filter(
    (invoice) =>
      !isEmpty(invoice.uen) &&
      !isEmpty(invoice.organization) &&
      !isEmpty(invoice.street) &&
      (!isEmpty(invoice.zip) || !isEmpty(invoice.city)) &&
      !isNil(invoice.vatApply),
  );

  const mappedAddresses = filteredAddresses.reduce((acc, address) => {
    const oldAdd = acc[address.signature];
    if (
      oldAdd &&
      ((!isEmpty(oldAdd.city) && !isEmpty(oldAdd.zip)) || // city and zip of the old address are not empty
        (isEmpty(address.city) && isEmpty(address.zip))) // city and zip of the new address are empty
    ) {
      return acc;
    }
    return { ...acc, [address.signature]: address };
  }, {} as { [key: string]: Address });

  return map(mappedAddresses, (add) => add);
};

export const mergeWithGuestAddress = (addresses: Address[], guest?: Guest) => {
  if (!guest || isEmptyAddress(guest)) {
    return addresses;
  }

  const [zip, ...city] = (guest.billingPostalCode ?? '').split(' ');

  /**
   * Transform guest address to invoice address
   */
  const guestAddress: Address = {
    uen: guest.billingCompanyNumber ?? '',
    organization: guest.billingOrganization ?? '',
    street: guest.billingStreet ?? '',
    vatApply: guest.billingSubjectToVAT == 1 ? '1' : '0',
    signature: guest.billingSignature ?? '',
    zip,
    city: (city ?? []).join(' '),
    isGuestAddress: true,
    guestId: guest.id,
    address2: guest.billingAddress2 ?? '',
    orderNumber: guest.billingOrderNumber ?? '',
    source: 'guest',
  };

  return filterDuplicateAndEmptyInvoicing([guestAddress, ...addresses]);
};

export const getSelectedCoupons = (
  coupons: string[],
  couponsList: Coupon[],
  defaultCoupon?: string,
) => {
  if (!isEmpty(defaultCoupon)) {
    return couponsList.filter(({ code }) => code === defaultCoupon);
  }

  return couponsList.filter(({ code }) => coupons.indexOf(code) !== -1);
};

interface GuestPrice {
  memberLevel: string | number;
  memberPrice: string | number;
  nonMemberPrice: string | number;
}

export const getGuestPrice = <T extends GuestPrice>(
  resource: T,
  guest?: Guest,
  reduction?: CouponReduction,
) => {
  let originalPrice = +resource.nonMemberPrice;

  if (!guest) {
    return { price: originalPrice, originalPrice };
  }

  const guestMemberLevel = +(guest.member ?? -1);
  const isMember =
    guestMemberLevel > 0 && guestMemberLevel >= +resource.memberLevel;
  const reductionAmount = reduction
    ? isMember
      ? +reduction.reductionMembre
      : +reduction.reductionNonMembre
    : 0;

  originalPrice = isMember ? +resource.memberPrice : +resource.nonMemberPrice;

  return {
    price: originalPrice - reductionAmount,
    originalPrice,
  };
};

export const getCouponReduction = (
  coupons: Coupon[],
  reductionType: COUPON_REDUCTION_TYPE,
  resourceId?: number,
): CouponReduction | undefined => {
  const findReduction = (reduction: CouponReduction) => {
    if (resourceId) {
      return (
        reduction.type === reductionType &&
        (+reduction.id === resourceId || [0, NaN].indexOf(+reduction.id) !== -1)
      );
    }

    return reduction.type === reductionType;
  };

  const filteredCoupons = coupons
    .filter(({ reductions }) => reductions.some((item) => findReduction(item)))
    .sort((a, b) => {
      const reductionA = a.reductions.find(
        (item) => item.type === reductionType,
      );
      const reductionB = a.reductions.find(
        (item) => item.type === reductionType,
      );

      return (
        +(reductionB as CouponReduction).reductionMembre -
        +(reductionA as CouponReduction).reductionMembre
      ); // desc sort
    });

  return filteredCoupons[0]?.reductions.find((item) => findReduction(item));
};

export const getOrderPaymentMethod = (payment: string) => {
  if (['OGONE', 'PAYPAL', 'MANGO_PAY'].includes(payment)) {
    return 'Online payment';
  }

  if (['BANK_TRANSFER'].includes(payment)) {
    return 'Bank transfer';
  }

  return payment;
};

export const getPaymentMethod = (assignement: Assignment) => {
  const { OGONE, PAYPAL, MANGO_PAY, BANK_TRANSFER } = ORDER_OPERATION_TYPE;

  if ([OGONE, PAYPAL, MANGO_PAY].includes(assignement.operationType)) {
    return 'Online payment';
  }

  if ([BANK_TRANSFER].includes(assignement.operationType)) {
    return 'Bank transfer';
  }

  return assignement.operationType;
};

export const getNextPaymentDate = (dateTimeAssignment: string) => {
  const parsedDate = parse(
    dateTimeAssignment,
    'yyyy-MM-dd HH:mm:ss',
    new Date(),
  );

  const resultDate = addMonths(parsedDate, 1);
  const formattedDate = format(resultDate, 'dd/MM/yyyy');

  return formattedDate;
};

export const getGuestAddressFromInvoiceAddress = (
  invoiceAddress: Address,
): GuestAddress => {
  return {
    billingCompanyNumber: invoiceAddress.uen,
    billingOrganization: invoiceAddress.organization,
    billingStreet: invoiceAddress.street,
    billingPostalCode: `${invoiceAddress.zip} ${invoiceAddress.city}`.trim(),
    billingSubjectToVAT: invoiceAddress.vatApply == '1' ? '1' : '0',
    billingSignature: invoiceAddress.signature,
    billingAddress2: invoiceAddress.address2 ?? '',
    billingOrderNumber: invoiceAddress.orderNumber ?? '',
    billingCountry: invoiceAddress.country ?? '',
  };
};

export const getGuestFinancial = (
  guest: Guest,
  vatIncluded: boolean = true,
) => {
  const financial = {
    paidInvoices: 0,
    unpaidInvoices: 0,
    toRefund: 0,
    refunded: 0,
  };

  guest.orders?.forEach((order) => {
    const { invoice, creditNotes } = order;
    if (invoice) {
      const price = vatIncluded
        ? invoice.includingVatAmount / 100
        : invoice.excludingVatAmount / 100;
      if (
        [
          ORDER_STATUS.OVER_PAID,
          ORDER_STATUS.UNDER_PAID,
          ORDER_STATUS.PAID,
        ].includes(invoice.status)
      ) {
        financial.paidInvoices += price;
      } else if (invoice.status === ORDER_STATUS.NOT_PAID) {
        financial.unpaidInvoices += price;
      }
    }

    if (creditNotes?.length) {
      creditNotes.forEach((cn) => {
        const price = vatIncluded
          ? cn.includingVatAmount / 100
          : cn.excludingVatAmount / 100;
        if (cn.status === 'NOT_REFUNDED') {
          financial.toRefund += price;
        } else if (status === 'REFUNDED') {
          financial.refunded += price;
        }
      });
    }
  });

  return financial;
};

export const checkIfUserAlreadyRegistered = (initialValues: any) => {
  if (
    !isEmpty(initialValues.status) &&
    (initialValues.status === 'mItaa' || initialValues.status === 'sItaa') &&
    isEmpty(initialValues.certificationNumber)
  ) {
    return false;
  }

  return (
    !isEmpty(initialValues.mainPhone) &&
    !isEmpty(initialValues.birthday) &&
    !isEmpty(initialValues.status) &&
    !isEmpty(initialValues.gender) &&
    !isEmpty(initialValues.function) &&
    !isEmpty(initialValues.organization) &&
    !isEmpty(initialValues.language)
  );
};

interface BillingDocument {
  documentDate: Order['documentDate'];
}

export const sortBillingDocument = <T extends BillingDocument>(
  documents: T[],
  dir: 'ASC' | 'DESC' = 'ASC',
) => {
  return [...documents].sort((docA: T, docB: T) => {
    return dir === 'ASC'
      ? moment(docA.documentDate).diff(moment(docB.documentDate))
      : moment(docB.documentDate).diff(moment(docA.documentDate));
  });
};

export const isGuestRegisteredToPremium = (guest: Guest) =>
  [
    PREMIUM_REGISTRATION_STATUS.DONE,
    PREMIUM_REGISTRATION_STATUS.GRACE_PERIOD,
  ].includes(guest?.premiumRegistrationStatus ?? 0);

export const isPremiumRegistrationPending = (guest: Guest) =>
  guest?.premiumRegistrationStatus === PREMIUM_REGISTRATION_STATUS.PENDING;

export const isUserPremium = (userPremiumStatus: PREMIUM_REGISTRATION_STATUS) =>
  [
    PREMIUM_REGISTRATION_STATUS.DONE,
    PREMIUM_REGISTRATION_STATUS.GRACE_PERIOD,
  ].includes(userPremiumStatus);

export const isUserPremiumPending = (userPremiumStatus: number) =>
  userPremiumStatus === PREMIUM_REGISTRATION_STATUS.PENDING;

export const isUserPremiumShared = (guest: Guest) =>
  guest?.isPremiumShared == true;

export const isPremiumUserInGracePeriod = (
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS,
) => userPremiumStatus === PREMIUM_REGISTRATION_STATUS.GRACE_PERIOD;
