import {
  EventActionTypes,
  EventsReplayHomeState,
  FETCH_EVENTS_REPLAY_FULFILLED,
  FETCH_EVENTS_REPLAY_PENDING,
  FETCH_EVENTS_REPLAY_REJECTED,
  SET_EVENTS_REPLAY_PAGE,
  SET_EVENTS_REPLAY_PAGE_SIZE,
} from './types';

const initialState = {
  items: [],
  fetching: false,
  fetched: false,
  error: null,
  nbResult: 0,
  page: 1,
  pageSize: 8,
};

export const reducer = (
  state: EventsReplayHomeState = initialState,
  action: EventActionTypes,
) => {
  switch (action.type) {
    case SET_EVENTS_REPLAY_PAGE:
      return { ...state, page: action.payload };
    case SET_EVENTS_REPLAY_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case FETCH_EVENTS_REPLAY_PENDING: {
      return {
        ...state,

        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_EVENTS_REPLAY_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items:
          state.page === 1
            ? data[0] ?? []
            : [...state.items, ...(data[0] ?? [])],
        nbResult: nbResult,
      };
    }
    case FETCH_EVENTS_REPLAY_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
