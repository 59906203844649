import React, { useEffect, useState } from 'react';
import styles from './TagsForm.module.scss';
import _ from 'i18n';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import TagsSelect from 'react-select/async';
import Tag from './Tag';
import { TagsState, TagType, EventWithTags } from 'store/EventTags/types';
import { useLanguage } from 'hooks/useLanguage';
import { getByLanguage } from 'utils';
import ClipLoader from 'react-spinners/ClipLoader';
import { ValueType } from 'react-select';
import cn from 'classnames';

interface OptionType {
  value: string;
  label: string;
}

interface Props {
  setShowAddTags: (showAddTags: boolean) => void;
  eventId: number;
  fetchTags: (inputTag: string, lng: string) => any;
  tags: TagsState;
  fetchEventWithTags: (id: number) => void;
  updateEventTags: (id: number, updatedTags: TagType[]) => void;
}

const TagsForm = ({
  setShowAddTags,
  eventId,
  fetchTags,
  tags,
  fetchEventWithTags,
  updateEventTags,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [eventTags, setEventTags] = useState<any[]>([]);
  const [fetching, setFetching] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deletedTagId, setDeletedTagId] = useState<number>(0);
  const [inputTag, setInputTag] = useState<string>('');
  const language = useLanguage();

  useEffect(() => {
    fetchEventWithTags(eventId);
  }, [fetchEventWithTags]);

  useEffect(() => {
    if (
      tags.eventFetched &&
      !tags.eventFetching &&
      tags.eventWithTags[0].id == eventId
    ) {
      setEventTags(tags.eventWithTags[0].tag ?? []);
      setFetching(false);
      setSaving(false);
    }
  }, [tags.eventFetching, tags.eventFetched, tags.eventWithTags]);

  const loadOptions = async (inputValue: string) => {
    const searchTags = await fetchTags(inputValue.trim(), language);
    const filtredTags = searchTags.value.data[0].filter(
      (item1: TagType) =>
        !eventTags.some((item2: TagType) => item1.id === item2.id),
    );
    return mapTags(filtredTags);
  };

  const handleTagSelectionChange = (value: ValueType<OptionType>) => {
    if (value) {
      setSelectedOptions(value as OptionType[]);
    } else {
      setSelectedOptions([]);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && inputTag.trim() === '') {
      saveTags();
    }
  };

  useEffect(() => {
    if (deletedTagId) {
      setSaving(true);
      const updatedEventTags = eventTags.filter(
        (item) => item.id !== deletedTagId,
      );
      updateEventTags(eventId, updatedEventTags);
    }
    setDeletedTagId(0);
  }, [deletedTagId]);

  const saveTags = () => {
    if (selectedOptions.length) {
      setSaving(true);
      const filtredTags = mapOptions(selectedOptions).filter(
        (item1: any) => !eventTags.some((item2: any) => item1.id === item2.id),
      );
      updateEventTags(eventId, [...filtredTags, ...eventTags]);
      setSelectedOptions([]);
    }
  };

  const mapTags = (tags: any[]) => {
    const mappedData = tags.map((item) => {
      const nameLlabel = getByLanguage(item, 'name', language);
      return {
        value: item.id,
        label: nameLlabel,
      };
    });
    return mappedData;
  };

  const mapOptions = (selectOptions: any[]) => {
    const mappedOptions = selectOptions.map((item) => {
      return {
        id: item.value,
      };
    });
    return mappedOptions;
  };

  const selectStyle = {
    placeholder: (base: any) => ({
      ...base,
      fontSize: '13.8px',
      fontWeight: 400,
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#F8F9FA',
    }),
  };

  return (
    <div className={styles.TagsForm}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>{_('Key words')}</div>
        <div className={styles.i}>
          <div className={cn(styles.close_icon, 'col small-1')}>
            <CrossIcon
              onClick={() => setShowAddTags(false)}
              width="12"
              height="12"
              fill="#6D7F92"
            />
          </div>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.tagAndSelectContainer}>
          <div className={styles.selectContainer}>
            <TagsSelect
              isMulti
              className={styles.tagsSelectContainer}
              classNamePrefix={styles.tagsSelect}
              loadOptions={loadOptions}
              onChange={handleTagSelectionChange}
              onInputChange={(inputValue) => setInputTag(inputValue)}
              onKeyDown={handleKeyDown}
              value={selectedOptions}
              defaultOptions
              loadingMessage={() => _('Loading ...')}
              noOptionsMessage={() => _('No options')}
              placeholder={_('Select keywords ...')}
              styles={selectStyle}
              maxMenuHeight={187.5}
            />
          </div>
          {selectedOptions.length > 0 && (
            <div className={cn(styles.action)}>
              <button
                type="submit"
                className={cn(styles.submitBtn)}
                onClick={saveTags}
              >
                {_('Save')}
              </button>
            </div>
          )}

          {!fetching && (
            <div className={styles.tagsContainer}>
              {eventTags.map((item) => (
                <Tag
                  key={item.id}
                  tag={item}
                  setDeletedTagId={setDeletedTagId}
                />
              ))}
            </div>
          )}
          {fetching && (
            <div className={styles.loader}>
              <ClipLoader size="30px" color={'#18a0fb'} />
            </div>
          )}
        </div>
        {saving && (
          <div className={styles.saving}>
            <div className={styles.loader}>
              <ClipLoader size="30px" color={'#18a0fb'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TagsForm);
