import React, { memo } from 'react';
import styles from './EventQuickModal.module.scss';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { Language } from 'store/types';
import { useFetchResource } from 'hooks/useFetchResource';
import { TTP_API_URL } from 'config';
import { SingleValueFilter } from 'services/Filter';
import { SLOT_FIELDS } from 'store/Slots/api';
import { Guest } from 'store/Guests/types';
import {
  bindWebinarLink,
  formatDecimalHours,
  getByLanguage,
  getEventIcsUrl,
  getEventNbHours,
  getEventRegisterLink,
  getRegisterButtonTitle,
  isFreeEvent,
  isGuestMemeber,
  isGuestRegistered,
} from 'utils';
import { ModalDataEventQuickType } from 'store/Modal/EventQuick/types';
import ReceptionBanner from 'components/ui/ReceptionBanner';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import PriceLabel, { Price } from 'components/ui/PriceLabel/PriceLabel';
import SessionActions from 'components/Webinar/Sessions/Session/SessionActions';
import { EVENT_TYPES } from 'store/Events/types';
import TTButton from 'components/Common/OLDButton';
import t from 'i18n';
import { Link, useHistory } from 'react-router-dom';
import { URLS } from 'router';
import cn from 'classnames';
import EventQuickDescription from 'components/Webinar/Reception/Description/EventQuickDescription/EventQuickDescription';

interface Props {
  userId: number;
  language: Language;
  modal: ModalState<ModalDataEventQuickType>;
  guest?: Guest;
  onCloseModal: () => void;
}

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: 0,
    top: '50%',
    bottom: 'unset',
    maxHeight: '90%',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '90%',
    maxWidth: '981px',
  },
};

export default memo(function EventQuickModal({
  userId,
  language,
  modal,
  guest,
  onCloseModal,
}: Props) {
  const { event, cycleId } = modal.data;
  const eventId = event.id;
  const isMemeber = isGuestMemeber(guest);
  const history = useHistory();
  const isFree = isFreeEvent(event);
  const icsFileUrl = getEventIcsUrl(eventId, userId, language);

  const [speakersFetching, speakers] = useFetchResource(
    `${TTP_API_URL}/event/speaker`,
    [],
    {
      filters: [
        new SingleValueFilter('event.id', 'eq', eventId),
        new SingleValueFilter('user', 'nNull', ''),
      ],
      sort: [{ property: 'firstName', dir: 'ASC' }],
      nolimit: true,
    },
    [eventId],
  );

  const [slotsFetching, slots] = useFetchResource(
    `${TTP_API_URL}/event/slot`,
    [],
    {
      filters: [new SingleValueFilter('event.id', 'eq', eventId)],
      fields: SLOT_FIELDS,
      nolimit: true,
    },
    [eventId],
  );

  const getEventInfos = () => {
    const nbHours = getEventNbHours(event);
    const { memberPrice, nonMemberPrice } = event;
    const isReplayable = event.isReplayable == 1;

    return [
      isFree
        ? {
            text: t('Free'),
          }
        : {
            renderLabel: () => (
              <div className="m-r-xs">
                <div className="text-center">
                  {!isMemeber || memberPrice == nonMemberPrice ? (
                    <Price
                      price={nonMemberPrice}
                      background="#C7E8FE"
                      size="xlarge"
                    />
                  ) : (
                    <PriceLabel
                      price={memberPrice}
                      originalPrice={nonMemberPrice}
                      background="#C7E8FE"
                      size="xlarge"
                      isOriginalPriceLight={true}
                    />
                  )}
                </div>
              </div>
            ),
          },
      {
        icon: (
          <ClockIcon className="m-r-xs" width="12" height="12" fill="#2495E1" />
        ),
        text: formatDecimalHours(nbHours),
      },
      isReplayable && {
        icon: (
          <PlayIcon className="m-r-xxs" width="12" height="12" fill="#2495E1" />
        ),
        text: (
          <>
            {t('Includes the option')}: <strong>{t('Replay')}</strong>
          </>
        ),
      },
    ].filter((i) => i);
  };

  const renderBannerActions = (isRegistered: boolean) => {
    if (isRegistered) {
      const session = slots[0];
      const showJoinButton =
        session && slots.length === 1 && event.type == EVENT_TYPES.EVENT_QUICK;

      return (
        <>
          {showJoinButton && (
            <div className="m-r-xs">
              <SessionActions
                session={session}
                showCalendarAction={false}
                showMainButton={false}
                showJoinText={true}
                actionStyle={{ height: '36px' }}
              />
            </div>
          )}
          <TTButton
            fontSize="14px"
            className="green2 full active"
            icon={
              <CheckMarkIcon
                width="10"
                height="10"
                fill="#FFF"
                className="m-r-xs"
              />
            }
            style={{
              height: '36px',
              padding: '0.5rem 1.5rem',
              minWidth: '187px',
              cursor: 'default',
            }}
          >
            {t('You are registered')}
          </TTButton>
        </>
      );
    }

    return (
      <TTButton
        fontSize="14px"
        className="green2 full"
        onClick={() => {
          history.push(getEventRegisterLink(event, !isNaN(+(cycleId ?? NaN))));
        }}
        style={{
          height: '36px',
          padding: '0.5rem 1.5rem',
          minWidth: '187px',
        }}
      >
        {t(getRegisterButtonTitle(event))}
      </TTButton>
    );
  };

  const { isRegistered, isRegistredToCycle } = isGuestRegistered(
    guest,
    +(cycleId ?? -1),
  );

  const isRegistredToCurrentEvent =
    isRegistredToCycle ||
    (isRegistered && guest?.event == eventId) ||
    guest?.registeredForEvents?.[eventId] != null;
  const name = getByLanguage(event, 'name', language);
  const urlBanner = getByLanguage(event, 'urlBanner', language) ?? '';

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div
        className="rmodal"
        style={{
          overflowY: 'auto',
          maxHeight: '90vh',
        }}
      >
        <CloseModal onClose={onCloseModal} />
        <div className={styles.wrapper}>
          <ReceptionBanner
            title={name}
            urlBanner={urlBanner}
            language={language}
            startDate={event.startDateTime}
            endDate={event.endDateTime}
            labels={getEventInfos() as any}
            isCycle={true}
            icsFileUrl={icsFileUrl}
            replayText={
              event.isReplayable == 1 ? t('training_replay_info') : undefined
            }
            renderActions={() => (
              <div className="flex-container">
                {renderBannerActions(isRegistredToCurrentEvent)}
                <Link
                  className={cn(styles.details_action, 'm-l-xs')}
                  to={bindWebinarLink(
                    URLS.webinar.reception.home,
                    String(event.id),
                  )}
                >
                  {t('Training details')}
                </Link>
              </div>
            )}
          />
          <div className="m-s">
            <EventQuickDescription
              showSubscribe={false}
              event={event}
              language={language}
              speakersFetching={speakersFetching}
              speakers={speakers}
              slotsFetching={slotsFetching}
              slots={slots}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});
