import { EventOption } from 'interfaces/EventOption';
import { EventPlan } from 'interfaces/EventPlan';
import { useMemo } from 'react';
import { Coupon, Guest } from 'store/Guests/types';
import {
  getEventOptionPrice,
  getEventPlanPrice,
  getGuestFinancial,
} from 'utils';

interface Params {
  guest: Guest;
  coupons: Coupon[];
  options: EventOption[];
  plan?: EventPlan;
}
export const useTotalPrice = ({ plan, options, guest, coupons }: Params) =>
  useMemo(() => {
    if (!plan) {
      return { totalPrice: 0, alreadyPaid: 0 };
    }

    const { price } = getEventPlanPrice(plan, coupons, guest);

    const total = options.reduce((acc, option: EventOption) => {
      const { price } = getEventOptionPrice(option, coupons, guest);
      return acc + price;
    }, price);

    const {
      paidInvoices,
      unpaidInvoices,
      toRefund,
      refunded,
    } = getGuestFinancial(guest, false);

    const alreadyPaid = paidInvoices + unpaidInvoices - toRefund - refunded;
    const totalHelp = alreadyPaid ? total - alreadyPaid : total;

    return { totalPrice: totalHelp, alreadyPaid };
  }, [coupons, guest, options, plan]);
