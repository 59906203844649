import TagsForm from './TagsForm';
import { connect } from 'react-redux';
import {
  fetchTags,
  fetchEventWithTags,
  updateEventTags,
} from 'store/EventTags/thunks';
import { RootState } from 'store/types';

const mapStateToProps = (state: RootState) => ({
  tags: state.eventTags,
});

export default connect(mapStateToProps, {
  fetchTags,
  fetchEventWithTags,
  updateEventTags,
})(TagsForm);
