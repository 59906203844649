import React from 'react';
import s from './Help.module.scss';
import cn from 'classnames';
import { ReactComponent as HelpIcon } from 'assets/icons/alert-circle.svg';
import { Popover } from 'antd';
import { AbstractTooltipProps } from 'antd/lib/tooltip';

interface Props extends AbstractTooltipProps {
  className?: string;
  title?: string;
  style?: React.CSSProperties;
  width?: React.ReactText;
  contentStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  iconProps?: React.SVGProps<SVGSVGElement>;
  children?: React.ReactNode;
}

export const Help = ({
  className,
  style,
  contentStyle,
  titleStyle,
  iconProps,
  width = 50,
  title,
  children,
  ...tooltipProps
}: Props) => {
  const renderContent = () => {
    if (!children) {
      return (
        <HelpIcon height="14px" width="14px" fill="#B2BCC6" {...iconProps} />
      );
    }

    return (
      <Popover
        overlayClassName="help_toolkit"
        content={
          <div
            className="help_toolkit__container"
            style={{ width, ...contentStyle }}
          >
            {title && (
              <div className="help_toolkit__title" style={titleStyle}>
                {title}
              </div>
            )}
            <div className="help_toolkit__content">{children}</div>
          </div>
        }
        {...tooltipProps}
      >
        <HelpIcon height="14px" width="14px" fill="#B2BCC6" {...iconProps} />
      </Popover>
    );
  };

  return (
    <div className={cn(s.help, className)} style={style}>
      {renderContent()}
    </div>
  );
};

export default Help;
