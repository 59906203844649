import { connect } from 'react-redux';
import { RootState } from 'store/types';
import TTPDialog from './TTPDialog';
import { reducer as dialog } from '../../../store/Params/TTPDialog';
import { setTTPDialogShow } from 'store/Params/TTPDialog/actions';

const mapStateToProps = (state: RootState) => ({
  dialog: state.params.dialog,
});

export default connect(mapStateToProps, {
  setTTPDialogShow,
})(TTPDialog);
