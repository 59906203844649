import {
  UpComingEventPopupActionTypes,
  popupCounterState,
  RESET_POPUP_COUNTER,
  SET_POPUP_COUNTER,
} from './types';

const initialState = {
  popupShowCounter: 0,
};

const reducer = (
  state: popupCounterState = initialState,
  action: UpComingEventPopupActionTypes,
) => {
  switch (action.type) {
    case RESET_POPUP_COUNTER: {
      return { ...initialState };
    }
    case SET_POPUP_COUNTER: {
      return { ...state, popupShowCounter: action.popupShowCounter };
    }
    default:
      return state;
  }
};

export default reducer;
