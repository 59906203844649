import { TTP_API_URL } from 'config';
import { isEmpty } from 'lodash';
import { User } from 'store/Auth/types';

export const getUserAvatarUrl = ({
  avatar,
  avatarUrl,
}: User): string | undefined => {
  if (isEmpty(avatar) && isEmpty(avatarUrl)) {
    return undefined;
  }

  return avatarUrl ? avatarUrl : TTP_API_URL + '/' + avatar;
};
