import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './StepPayment.module.scss';
import cn from 'classnames';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsContent from '../../../Common/StepsContent';
import StepsControl from '../../../Common/StepsControl';
import { REGISTRATION_PACK_STEPS } from '../../services';
import { isEmpty } from 'lodash';
import { PackOrder } from 'interfaces/PackOrder';
import { onError, onSuccess } from 'utils';
import { closeModal } from 'store/Modal/actions';
import { User } from 'store/Auth/types';
import { Event } from 'store/Events/types';
import { fetchPackSummary } from 'store/Packs/thunks';
import { RootState } from 'store/types';
import { selectToken } from 'store/Auth';

interface Props {
  user: User;
  event: Event;
  packOrder: PackOrder | null;
  previousStep: REGISTRATION_PACK_STEPS;
  goToStep: (step: REGISTRATION_PACK_STEPS) => void;
}

const StepPayment = ({
  user,
  event,
  packOrder,
  previousStep,
  goToStep,
}: Props) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => selectToken(state));
  const [showTerminate, setShowTerminate] = useState(false);

  useEffect(() => {
    if (isEmpty(packOrder?.paymentUrl)) {
      goToStep(REGISTRATION_PACK_STEPS.ORGANIZATION);
      onError();
    }
  }, [goToStep, packOrder?.paymentUrl]);

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      const eventId = e?.data?.event;
      const messageId = e?.data?.message;

      if (eventId === 'PAYMENT_TRANSFER') {
        setShowTerminate(true);
      } else if (eventId === 'PAYMENT_ONLINE' || eventId === 'PAYMENT_ORDERS') {
        setShowTerminate(false);
      } else if (
        eventId === 'PAYMENT_OK' ||
        messageId === 'CLOSE_PAYMENT_MODAL'
      ) {
        setShowTerminate(false);
        handleNextStep();
      } else if (eventId === 'RETRY_PAYMENT') {
        setShowTerminate(false);
        const paymentIframe = document.getElementById(
          'payment-iframe',
        ) as HTMLIFrameElement;
        paymentIframe.src += '';
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  const handleNextStep = () => {
    goToStep(REGISTRATION_PACK_STEPS.COLLABORATORS);
    onSuccess();
  };

  const handleClose = () => {
    dispatch(closeModal());
    dispatch(fetchPackSummary(user.id, event.id));
  };

  return (
    <StepsContent>
      <div className={s.wrapper}>
        <iframe
          title="payment"
          id="payment-iframe"
          src={`${packOrder?.paymentUrl}&token=${token}&embedded=1&isCommunity=1`}
        />
      </div>
      <StepsControl className={s.actions}>
        <div className={cn('p-s p-x-l flex-container')}>
          <GoBack
            text={t('Previous step')}
            className="m-r-s"
            onClick={() => goToStep(previousStep)}
          />
          {showTerminate && (
            <NextStep
              text={t('Finish')}
              className="m-l-auto"
              theme="orangeRoyal"
              onClick={handleClose}
              hideIcon={true}
            />
          )}
        </div>
      </StepsControl>
    </StepsContent>
  );
};

export default StepPayment;
