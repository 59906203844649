import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { isUserPremium } from 'utils';

export const usePremiumSharedStatus = () => {
  const userPremiumShared = useSelector(
    (state: RootState) => state.auth.userPremiumShared,
  );
  const userPremiumStatus = useSelector(
    (state: RootState) => state.auth.userPremiumStatus,
  );
  // const isPremiumFetching = useSelector(
  //   (state: RootState) => state.cycle.premiumOffers.fetching,
  // );
  const isPremiumGuestFetching = useSelector(
    (state: RootState) => state.event.guests.premiumGuestFetching,
  );
  const isUserRegisteredPremium = isUserPremium(userPremiumStatus);

  return {
    isShared: userPremiumShared || isUserRegisteredPremium,
    isFetching: isPremiumGuestFetching,
  };
};
