import React, { CSSProperties } from 'react';
import styles from './Avatar.module.scss';
import classNames from 'classnames';
import { getUserNameForAvatar, maxChar } from 'utils';

interface Props {
  url?: string;
  firstName?: string;
  lastName?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  backgroundWidth?: number | string;
  backgroundHeight?: number | string;
  fontSize?: number | string;
  styleInfoName?: CSSProperties;
  infoNameClassName?: string;
  styleInfoSignature?: CSSProperties;
  showInfo?: boolean;
  avatarSignature?: {
    signature: string;
    maxChar?: number;
  };
}

export const Avatar = ({
  url,
  firstName,
  lastName,
  showInfo,
  avatarSignature,
  className,
  width = '62px',
  height = '62px',
  backgroundWidth,
  backgroundHeight,
  fontSize,
  styleInfoName,
  infoNameClassName,
  styleInfoSignature,
}: Props) => {
  const avatarName = `${firstName ?? ''} ${lastName ?? ''}`.trim();

  /*<div className={classNames(styles.avatar, className)}>
            <img src={url} height={height} width={width} />
          </div>*/
  return (
    <span className={classNames(showInfo && styles.avatarContainer)}>
      {url ? (
        <div
          className={classNames(styles.avatar, className)}
          style={{
            width: backgroundWidth,
            height: backgroundHeight,
          }}
        >
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${url})`,
              width,
              height,
            }}
          />
        </div>
      ) : (
        <div
          className={classNames(styles.avatar, 'empty-avatar', className)}
          style={{
            width: backgroundWidth ?? width,
            height: backgroundHeight ?? height,
          }}
        >
          <span style={{ fontSize }}>
            {getUserNameForAvatar(firstName, lastName)}
          </span>
        </div>
      )}
      {showInfo && (
        <div className={styles.avatarInfo}>
          <div
            className={classNames(styles.avatarName, infoNameClassName)}
            style={styleInfoName}
          >
            {avatarName}
          </div>
          {avatarSignature && (
            <div
              title={avatarSignature.signature}
              className={styles.avatarSignature}
              style={styleInfoSignature}
            >
              {avatarSignature.maxChar
                ? maxChar(avatarSignature.signature, avatarSignature.maxChar)
                : avatarSignature.signature}
            </div>
          )}
        </div>
      )}
    </span>
  );
};

export default Avatar;
