import { Dispatch } from 'redux';
import { RootState } from 'store/types';
import * as api from './api';
import actions from './actions';

export const fetchGuestProgram = (guestId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.fetchGuestProgram(
      api.getGuestProgram({ token, guest: guestId }).then((res) => {
        const { data, nbResult } = res.data;
        return { data, nbResult };
      }),
    ),
  );
};

export const addSlotToProgram = (slotId: number, guestId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.addSlotToProgram(
      api.addSlotToProgram({ token, slotId, guestId }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

export const removeSlotFromProgram = (slotId: number, guestId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.removeSlotFromProgram(
      api.removeSlotFromProgram({ token, slotId, guestId }),
    ),
  );
};
