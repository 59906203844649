import React, { useEffect, useRef, useState } from 'react';
import s from './StepOptions.module.scss';
import cn from 'classnames';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsContent from '../../../Common/StepsContent';
import StepsControl from '../../../Common/StepsControl';
import { Event, EventPlanAndOptions } from 'store/Events/types';
import { Language } from 'store/types';
import { Coupon, COUPON_REDUCTION_TYPE, Guest } from 'store/Guests/types';
import PlanOption from 'components/ui/PlanOption';
import { useSize } from 'hooks/useSize';
import Summary from '../../Summary';
import {
  getByLanguage,
  getEventOptionPrice,
  getPrivacyTermsUrl,
  onError,
} from 'utils';
import { GuestData, REGISTRATION_FULL_STEPS, StepProps } from '../../services';
import { EventOption } from 'interfaces/EventOption';
import { EventPlan, PLAN_OPTION_TYPE } from 'interfaces/EventPlan';
import { CURRENT_CONGRESS_ID, S3_FOLDER_URL } from 'config';
import { Step2Data } from 'store/Guests/api';
import { isEmpty, uniqBy } from 'lodash';
import { User } from 'store/Auth/types';
import TTPCheckBox from 'components/Common/TTPForm/TTPCheckBox';
import { URLS } from 'router';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'components/Common/LocalLoader/LocalLoader';
import { useComponentDidMount } from 'hooks/useComponentDidMount';

interface Props extends StepProps {
  event: Event;
  guest: Guest;
  user: User;
  language: Language;
  selectedCoupons: Coupon[];
  plansAndOptions: EventPlanAndOptions | null;
  selectedPlan?: EventPlan;
  selectedOptionId?: number;
  data: GuestData;
  handleChangeData: (data: Partial<GuestData>) => void;
  goToStep: (step: REGISTRATION_FULL_STEPS) => void;
  saveStep2: (eventId: string, userId: string, data: Partial<Step2Data>) => any;
  closeModal: () => void;
}

const StepOptions = ({
  event,
  guest,
  user,
  language,
  selectedCoupons,
  selectedPlan,
  data,
  plansAndOptions,
  nextStep,
  previousStep,
  goToStep,
  handleChangeData,
  saveStep2,
  closeModal,
  selectedOptionId,
}: Props) => {
  const summaryRef = useRef<Summary>(null);
  const {
    measuredRef,
    size: { height },
  } = useSize();
  const [termsOfSales, setTermsOfSales] = useState(+data.termsOfSales === 1);
  const [termsError, setTermsError] = useState(false);
  const [privacyTerms, setPrivacyTerms] = useState(false);
  const [privacyTermsError, setPrivacyTermsError] = useState(false);
  const showPrivacyTerms = event?.id == CURRENT_CONGRESS_ID;
  const [saving, setSaving] = useState(false);
  const [promoted, applyPromoted] = useState(false);
  const options = data.options?.map((op) => +op) ?? [];

  const isMounted = useComponentDidMount();
  useEffect(() => {
    if (!isMounted || !selectedOptionId) return;
    if (!promoted) {
      handleAddOption(selectedOptionId as number);
      applyPromoted(true);
    }
  }, [isMounted, promoted, selectedOptionId]);

  if (!plansAndOptions) {
    return <div>TODO !</div>;
  }

  const scrollDownSummary = () =>
    setTimeout(() => summaryRef.current?.scrollDown(), 0);

  const handleAddOption = (optionId: number) => {
    let newOptions = [...options];

    if (options.indexOf(optionId) !== -1) {
      newOptions = newOptions.filter((op) => op !== optionId);
    } else {
      newOptions = [...newOptions, optionId];
    }

    handleChangeData({ options: newOptions });
    scrollDownSummary();
  };

  const handleSubmit = (): void | Promise<any> => {
    if (!termsOfSales || (!privacyTerms && showPrivacyTerms)) {
      setTermsError(!termsOfSales);
      setPrivacyTermsError(!privacyTerms && showPrivacyTerms);
      return;
    }

    const newData: Partial<Step2Data> = {
      plan: data.plan,
      options: JSON.stringify(options),
      days: selectedPlan?.accessibleDays?.split(','),
      coupon: selectedCoupons[0]?.code ?? '',
      termsOfSales: termsOfSales ? 1 : 0,
    };

    setSaving(true);
    return saveStep2(String(event.id), String(user.id), {
      ...newData,
      cycle: 0,
    })
      .then((res: any) => {
        const paymentUrl = res?.value?.data?.paymentUrl;
        setSaving(false);
        if (!isEmpty(paymentUrl) && res?.value?.data?.step == 4) {
          goToStep(REGISTRATION_FULL_STEPS.PAYMENT);
        } else {
          const data: Guest | undefined = res.value.data;
          if (!data || +data.step !== 5) {
            onError();
          } else {
            // Nothing to be paid ...
            closeModal();
          }
        }
      })
      .catch(() => {
        setSaving(false);
        onError();
      });
  };

  // -------------- RENDER -------------- //

  const renderOptions = () => {
    const { extraPlanOptions, additionnalOptions } = plansAndOptions;

    const additionalOptionsIds =
      selectedPlan?.options
        .filter(({ type }) => +type === PLAN_OPTION_TYPE.ADDITIONAL)
        .map((op) => +op.option) ?? [];

    const filteredAdditionalOptions = additionnalOptions.filter(
      (op) => additionalOptionsIds.indexOf(+op.id) !== -1,
    );

    const filteredExtraPlanOptions = extraPlanOptions.filter(
      (op) =>
        !selectedPlan?.options?.some((planOp) => +planOp.option == +op.id),
    );

    const extra = uniqBy(
      [...filteredAdditionalOptions, ...filteredExtraPlanOptions],
      'id',
    )
      .sort((a, b) => +a?.position - +b?.position)
      .filter((op) => {
        // The idea is to remove coupon related options
        return (
          +op.byCoupon === 0 ||
          (+op.byCoupon === 1 &&
            selectedCoupons.some((cp) =>
              cp.reductions.some(
                (rd) =>
                  +rd.id === +op.id && rd.type === COUPON_REDUCTION_TYPE.OPTION,
              ),
            ))
        );
      });

    return (
      <>
        {extra.map((option) => {
          const name = getByLanguage(option, 'name', language);
          const pictureUrl = getByLanguage(option, 'pictureUrl', language);
          const description = getByLanguage(option, 'description', language);
          const { price, originalPrice } = getEventOptionPrice(
            option,
            selectedCoupons,
            guest,
          );

          return (
            <div className="m-b-xs" key={option.id}>
              <PlanOption
                title={name}
                description={description}
                price={+price}
                originalPrice={originalPrice}
                imgUrl={
                  isEmpty(pictureUrl)
                    ? undefined
                    : `${S3_FOLDER_URL}/events-folder${pictureUrl}`
                }
                isChecked={options.indexOf(+option.id) !== -1}
                isSoldOut={+option.status === 3}
                onCheck={() => handleAddOption(+option.id)}
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderNextStepAction = () => {
    if (nextStep !== REGISTRATION_FULL_STEPS.END) {
      return (
        <NextStep
          className="m-l-auto"
          text={t('Next step')}
          onClick={() => goToStep(nextStep)}
        />
      );
    }

    const privacyTermsLink = getPrivacyTermsUrl(language);

    return (
      <div className="flex-container m-l-auto align-middle">
        {showPrivacyTerms && (
          <div className="flex-container flex-dir-column">
            <TTPCheckBox
              className="m-r-s"
              label={
                <span className={s.acceptConditions}>
                  {t('inscription.privacy_terms_p1')}&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={privacyTermsLink}
                    style={{
                      color: '#18A0FB',
                      textDecoration: 'underline',
                    }}
                  >
                    {t('inscription.privacy_terms_p2')}
                  </a>
                </span>
              }
              checked={privacyTerms}
              onChange={() => {
                setPrivacyTermsError(privacyTerms);
                setPrivacyTerms(!privacyTerms);
              }}
            />
            {privacyTermsError && (
              <div className="yup-error">
                <p className="text-center">
                  {t('You must accept the privacy terms')}
                </p>
              </div>
            )}
          </div>
        )}
        <div className="flex-container flex-dir-column">
          <TTPCheckBox
            className="m-r-s"
            label={
              <span className={s.acceptConditions}>
                {t('inscription.terms_p1')}&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={URLS.terms.event}
                  style={{
                    color: '#18A0FB',
                    textDecoration: 'underline',
                  }}
                >
                  {t('inscription.terms_p2')}
                </a>
              </span>
            }
            checked={termsOfSales}
            onChange={() => {
              setTermsError(termsOfSales);
              setTermsOfSales(!termsOfSales);
            }}
          />
          {termsError && (
            <div className="yup-error">
              <p className="text-center">
                {t('You must accept the terms and conditions')}
              </p>
            </div>
          )}
        </div>
        <NextStep
          className="m-l-auto"
          text={t('Finish')}
          onClick={handleSubmit}
        />
      </div>
    );
  };

  /**
   * 70: stepControlHeight
   * 32: padding
   * 16: margin
   */
  const summaryHeight = height - 70 - 32 - 16;

  return (
    <LocalLoaderWrapper className={'flex-container flex-1'}>
      <StepsContent innerRef={measuredRef}>
        <div className={cn(s.content, 'flex-container')}>
          <div className="flex-1 p-s">
            <h3 className="m-b-s">{t('Select additional options')} :</h3>
            <div>{renderOptions()}</div>
          </div>
          <div
            style={{
              width: '360px',
              position: 'relative',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
          >
            <Summary
              ref={summaryRef}
              style={{
                maxHeight: `${summaryHeight}px`,
                minHeight: summaryHeight > 500 ? 500 : summaryHeight,
              }}
              selectedCoupons={selectedCoupons}
              guest={guest}
              plan={selectedPlan}
              options={data.options?.reduce((acc, option) => {
                const op = plansAndOptions.options.find(
                  (op) => +op.id === +option,
                );
                if (!op) return acc;
                return [...acc, op];
              }, [] as EventOption[])}
              language={language}
              goToStep={goToStep}
              step={REGISTRATION_FULL_STEPS.OPTIONS}
            />
          </div>
        </div>
        <StepsControl className={s.actions}>
          <div className={cn('p-s p-x-l flex-container')}>
            {!selectedOptionId && (
              <GoBack
                onClick={() => goToStep(previousStep)}
                className="m-r-s"
              />
            )}
            {renderNextStepAction()}
          </div>
        </StepsControl>
      </StepsContent>
      <LocalLoader loading={saving} style={{ zIndex: 100 }} />
    </LocalLoaderWrapper>
  );
};

export default StepOptions;
