import { LocationDescriptorObject } from 'history';
import { ReactNode } from 'react';

export interface GoBackData {
  title?: string;
  goBackPath?: string | LocationDescriptorObject;
  callBack?: () => void;
  goBackText?: string;
  isFixed?: boolean;
  childrens?: ReactNode;
  icon?: string;
  hideBackButton?: boolean;
  renderContent?: () => React.ReactNode;
  renderActions?: () => React.ReactNode | JSX.Element;
  theme?: 'orange' | 'redOrange' | '';
  style?: React.CSSProperties;
}

export interface GoBackRouteType {
  path: string | string[];
  exact?: boolean;
}

export interface GoBackState {
  showGoBack: boolean;
  customData: GoBackData;
  route: {
    path: string | string[];
    exact?: boolean;
  };
}

export const RESET_GO_BACK = 'RESET_GO_BACK';
export const SET_GO_BACK_SHOW = 'SET_GO_BACK_SHOW';
export const SET_GO_BACK_CUSTOM_DATA = 'SET_GO_BACK_CUSTOM_DATA';

export interface SetGoBackShowAction {
  type: typeof SET_GO_BACK_SHOW;
  showGoBack: boolean;
}

export interface ResetGoBackAction {
  type: typeof RESET_GO_BACK;
}

export interface SetGoBackCustomDataAction {
  type: typeof SET_GO_BACK_CUSTOM_DATA;
  customData: GoBackData;
  route: GoBackRouteType;
}

export type GoBackActionTypes =
  | ResetGoBackAction
  | SetGoBackShowAction
  | SetGoBackCustomDataAction;
