export interface AppInfoData {
  id: 'EVENT' | 'OFFFCOURSE' | null;
  name: string;
  url: string;
  logo: {
    url: string;
    hasName?: boolean;
  };
  domain: string;
  withoutHeader: boolean;
  params: string;
  isNextApp?: boolean;
}

export const SET_APP_INFO = 'SET_APP_INFO';

export interface SetAppInfoAction {
  type: typeof SET_APP_INFO;
  appInfo: AppInfoData;
}
