import React, { CSSProperties, Fragment, useState } from 'react';
import s from './CompactEventCard.module.scss';
import t from 'i18n';
import {
  calculateTimeDifference,
  formatDateFromTo,
  getByLanguage,
  getCroppedImageUrl,
  getEventNbMinutes,
  isEventLive,
  isEventPast,
  prepareS3Url,
  speakersEventToString,
  calculatePlayProgressTime,
} from 'utils';
import { Event } from 'store/Events/types';
import { Language } from 'store/types';
import Shave from 'components/Common/Shave';
import Chip from 'components/ui/Chip';
import EventCardActions from 'components/EventCardActions';
import { openModal } from 'store/Modal/actions';
import { useDispatch } from 'react-redux';
import ProgressBar from 'components/ui/ProgressBar';
import { isEmpty } from 'lodash';
import { ReactComponent as TrendingIcon } from 'assets/icons/trending.svg';
import cn from 'classnames';

interface Props {
  event: Event;
  language: Language;
  isFullWidth?: boolean;
}

export const CompactEventCard = ({ event, language, isFullWidth }: Props) => {
  const dispatch = useDispatch();
  const { startDateTime, endDateTime } = event;

  const name = getByLanguage(event, 'name', language) ?? '';
  const urlBanner = getByLanguage(event, 'urlBanner', language) ?? '';
  const banner = getCroppedImageUrl(urlBanner, undefined, 280);
  const bannerUrl = isEmpty(banner)
    ? '/img/event-banner-fallback.png'
    : prepareS3Url(banner);
  const speakersLabel = speakersEventToString(event);
  const isLive = isEventLive(event);
  const isExpired = isEventPast(event);
  const dateHelper = formatDateFromTo(
    startDateTime ?? '',
    endDateTime ?? '',
    language,
  );
  const eventTime = getEventNbMinutes(event);
  const playProgress = event?.playProgress ?? 0;
  const EventPlayProgress = calculatePlayProgressTime(
    eventTime,
    event?.playProgress,
  );
  const [isCalendarDropdownVisible, setCalendarDropdownVisible] = useState(
    false,
  );

  const handleOpenModal = () => {
    dispatch(openModal('WATCH', { data: event }));
  };

  const renderPlayProgress = () => {
    if (isExpired && EventPlayProgress > 0) {
      return (
        <div>
          <ProgressBar
            progress={EventPlayProgress}
            showProgressNumber={false}
            color={'linear-gradient(90.02deg, #06D9B1 0%, #18A0FB 99.98%)'}
            width={'280px'}
            height={'4px'}
            style={{ background: '#B2BCC6' }}
            className={s.playProgress}
            isEventWatch={true}
          />
        </div>
      );
    }

    return null;
  };

  const renderChips = () => {
    if (isLive) {
      const { minutes: inLiveSince } = calculateTimeDifference(startDateTime);
      return (
        <Fragment>
          <Chip label={t('ongoing_now')} color="coralRed" />
          <Chip
            label={t('in_live_since').replace(':minutes', inLiveSince)}
            isTransparent
            isLowercase
          />
        </Fragment>
      );
    }

    if (isExpired) {
      const label =
        EventPlayProgress > 0
          ? `${playProgress} sur ${eventTime} min`
          : `${eventTime} min`;
      return (
        <Fragment>
          <Chip label={t('Replay')} isTransparent />
          <Chip label={label} isLowercase isTransparent />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="flex-container align-middle">
          <Chip label={t('live')} isTransparent />
          <time dateTime={startDateTime}>{dateHelper}</time>
        </div>
      </Fragment>
    );
  };

  const bgStyle: CSSProperties = {
    background: `
    linear-gradient(180.87deg, rgba(9, 22, 38, 0) 0.75%, rgba(9, 22, 38, 0.9) 76.68%),
    url(${bannerUrl}) center/cover
    `,
  };

  return (
    <div
      className={cn(s.card_wrapper, isFullWidth && s.full_width)}
      onClick={handleOpenModal}
      onMouseEnter={() => setCalendarDropdownVisible(true)}
      onMouseLeave={() => setCalendarDropdownVisible(false)}
    >
      <div className={s.card}>
        <div className={s.card_summary} style={bgStyle}>
          {!isEmpty(event.clientData?.avatarUrl) && (
            <div className={s.logoWrapper}>
              <span
                style={{
                  backgroundImage: `url(${event.clientData?.avatarUrl})`,
                }}
              />
            </div>
          )}
          {event.eventClassificationRank && (
            <div className={s.trendBadge}>
              <TrendingIcon
                width="34"
                height="46"
                fill="#29394D"
                className={cn('m-r-xs')}
              />
              <div className={s.trending}>
                <h3>TOP</h3>
                <h2>{event.eventClassificationRank}</h2>
              </div>
            </div>
          )}
          {/* <div className={s.card_header}>Header</div> */}
          <div className={s.card_content}>
            <Shave as={'h3'} className={s.title} maxHeight={45}>
              {name}
            </Shave>
            <h5 className={s.speakers}>{speakersLabel}</h5>
            <div className={s.live_info}>{renderChips()}</div>
            <div>{renderPlayProgress()}</div>
          </div>
        </div>
        <div className={s.card_footer}>
          <EventCardActions
            event={event}
            language={language}
            isCalendarDropdownVisible={isCalendarDropdownVisible}
          />
        </div>
      </div>
    </div>
  );
};

CompactEventCard.Fetching = ({ isFullWidth }: { isFullWidth?: boolean }) => (
  <div className={cn(s.card_fetching_wrapper, isFullWidth && s.full_width)}>
    <div className={s.card_summary}>
      <div className={s.card_content}>
        <div className={s.title} />
        <div className={s.speakers} />
      </div>
    </div>
  </div>
);

export default CompactEventCard;
