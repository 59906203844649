import axios from 'axios';
import { TTP_API_URL } from 'config';
import { Filter } from 'services/Filter';

export const getGuestProgram = ({
  token,
  guest,
}: {
  token: string;
  guest: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest-slot/get-busy-light`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      guest,
    },
  });
};

export const addSlotToProgram = ({ token, slotId, guestId }: any) => {
  const requestUrl = `${TTP_API_URL}/event/guest-slot/add-to-program`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('slot', slotId);
  formData.append('guest', guestId);

  return axios.post(requestUrl, formData);
};

export const removeSlotFromProgram = ({ token, slotId, guestId }: any) => {
  const requestUrl = `${TTP_API_URL}/event/guest-slot/delete-from-program`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      guest: guestId,
      slot: slotId,
    },
  });
};

export const getSlotGuests = ({
  token,
  filters = [],
}: {
  token: string;
  filters?: Filter[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest-slot`;

  const filter = [...filters].map((filter) => filter.serialize());

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
    },
  });
};
