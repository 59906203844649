import { combineReducers } from 'redux';

import { reducer as slotsCreator } from 'store/Slots';
import { reducer as partnersReducerCreator } from 'store/Partners';
import { reducer as resource } from 'store/Resource';
import { reducer as dataResource } from 'store/Resource/DataResource';
import { reducer as guests } from 'store/Guests';
import { reducer as currentPartner } from 'store/Partners/CurrentPartner';
import { reducer as guestProgram } from 'store/Program';
// import { reducer as registration } from 'store/Events/Registration';

import { Event, EventPlanAndOptions } from 'store/Events/types';
import { createNamedWrapperReducer } from 'store/services';
import withPagination from 'store/Pagination';
import { ResourceState, ResourceActionTypes } from 'store/Resource/types';
import { Speaker } from 'store/Speakers/types';
import { EventAccess } from 'store/Event/types';

import {
  EVENT_RESOURCE,
  EVENT_SLOT_RESOURCE,
  EVENT_SPEAKER_RESOURCE,
  EVENT_IDS_RESOURCE,
  EVENT_PARTNERS_RESOURCE,
  EVENT_STRUCTURAL_PARTNERS_RESOURCE,
  EVENT_PARTNERS_SEARCH_RESOURCE,
  MARKET_DATA_RESOURCE,
  CYCLE_RESOURCE,
  INVOICING_RESOURCE,
  EVENT_OPTIONS_RESOURCE,
  EVENT_PLANS_RESOURCE,
  EVENT_PACKS_RESOURCE,
  EVENT_PLANS_AND_OPTIONS_RESOURCE,
  EVENT_ACTIVITIES_RESOURCE,
  EVENT_COUPON_MODELS_RESOURCE,
  PARTNER_GUESTS_RESOURCE,
  CATEGORY_EVENTS_RESOURCE,
} from 'store/types';
import { DataResourceState } from 'store/Resource/DataResource/types';
import { Market } from 'store/Market/types';
import { Cycle } from 'store/Cycle/types';
import { EventOption } from 'interfaces/EventOption';
import { EventPlan } from 'interfaces/EventPlan';
import { EventPack } from 'interfaces/EventPack';
import { EventActivity } from 'interfaces/EventActivity';
import { CouponModel } from 'interfaces/CouponModel';
import { PartnerGuest } from 'interfaces/PartnerGuest';
import { CategoryEvents } from 'interfaces/CategoryEvents';

const reducer = combineReducers({
  eventIds: createNamedWrapperReducer<
    ResourceState<EventAccess>,
    ResourceActionTypes<EventAccess>
  >(resource, EVENT_IDS_RESOURCE),
  event: createNamedWrapperReducer<
    ResourceState<Event>,
    ResourceActionTypes<Event>
  >(resource, EVENT_RESOURCE),
  partners: withPagination(
    partnersReducerCreator(EVENT_PARTNERS_RESOURCE),
    EVENT_PARTNERS_RESOURCE,
  ),
  searchPartners: withPagination(
    partnersReducerCreator(EVENT_PARTNERS_SEARCH_RESOURCE),
    EVENT_PARTNERS_SEARCH_RESOURCE,
  ),
  partnersStructural: partnersReducerCreator(
    EVENT_STRUCTURAL_PARTNERS_RESOURCE,
  ),
  currentPartner,
  slots: slotsCreator(EVENT_SLOT_RESOURCE),
  speakers: createNamedWrapperReducer<
    ResourceState<Speaker>,
    ResourceActionTypes<Speaker>
  >(resource, EVENT_SPEAKER_RESOURCE),
  market: createNamedWrapperReducer<
    DataResourceState<Market>,
    ResourceActionTypes<Market>
  >(dataResource, MARKET_DATA_RESOURCE),
  guests,
  guestProgram,
  packs: createNamedWrapperReducer<
    ResourceState<EventPack>,
    ResourceActionTypes<EventPack>
  >(resource, EVENT_PACKS_RESOURCE),
  plansAndOptions: createNamedWrapperReducer<
    DataResourceState<EventPlanAndOptions>,
    ResourceActionTypes<EventPlanAndOptions>
  >(dataResource, EVENT_PLANS_AND_OPTIONS_RESOURCE),
  activities: createNamedWrapperReducer<
    ResourceState<EventActivity>,
    ResourceActionTypes<EventActivity>
  >(resource, EVENT_ACTIVITIES_RESOURCE),
  couponModels: createNamedWrapperReducer<
    ResourceState<CouponModel>,
    ResourceActionTypes<CouponModel>
  >(resource, EVENT_COUPON_MODELS_RESOURCE),
  partnerGuests: createNamedWrapperReducer<
    ResourceState<PartnerGuest>,
    ResourceActionTypes<PartnerGuest>
  >(resource, PARTNER_GUESTS_RESOURCE),
  categoryEvents: createNamedWrapperReducer<
    ResourceState<CategoryEvents>,
    ResourceActionTypes<CategoryEvents>
  >(resource, CATEGORY_EVENTS_RESOURCE),
  // guestInvoicings: createNamedWrapperReducer<
  //   ResourceState<Cycle>,
  //   ResourceActionTypes
  // >(resource, INVOICING_RESOURCE),
  // registration,
});

export default reducer;
