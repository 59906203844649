import React, { ReactElement, useEffect, useState } from 'react';
import styles from './PlacesLayout.module.scss';
import { getByLanguage } from 'utils';
import cn from 'classnames';
import t from 'i18n';
import Pack from './Pack';
import { ReactComponent as IconAdd } from 'assets/icons/plus.svg';

interface Props {
  organisationId: number;
  goToStep?: () => void;
  pack: any;
  fetching: boolean;
  partner?: any;
  eventId?: number;
  ownerId?: number;
  fetchPacks: (organizationId: number, eventId: number) => void;
  savePlace: (
    event: any,
    user: any,
    couponModel: any,
    owner: any,
    partner: any,
  ) => any;
  getEmployeePack: (organizationId: number, eventId: number) => void;
}

export default function PlacesLayout({
  goToStep,
  pack,
  fetching,
  partner,
  eventId,
  ownerId,
  savePlace,
  fetchPacks,
  getEmployeePack,
  organisationId,
}: Props): ReactElement {
  const renderPacks = () => {
    if (fetching) {
      return [1, 2, 3, 4].map((key) => <Pack.Fetching key={key} />);
    }
    const count = Object.keys(pack).length;
    return Object.keys(pack).map((key) => (
      <Pack
        pack={pack[key]}
        partner={partner ? partner : 0}
        eventId={eventId}
        ownerId={ownerId}
        key={pack[key].modelId}
        savePlace={savePlace}
        getEmployeePack={getEmployeePack}
        organisationId={organisationId}
        fetchPacks={fetchPacks}
        count={count}
      />
    ));
  };

  const AvailablePlaceCounter = () => {
    let total = 0;
    for (const property in pack) {
      total += pack[property].availablePlaces;
    }
    return total;
  };

  const registeredPlaceCounter = () => {
    let total = 0;
    for (const property in pack) {
      total += pack[property].usedPlaces;
    }
    return total;
  };

  const totalPlaceCounter = () => {
    let total = 0;
    for (const property in pack) {
      total += pack[property].totalPlaces;
    }
    return total;
  };

  return (
    <div className={styles.packsList}>
      <span className={cn(styles.title, 'm-t-s')}>{t('My places')} :</span>
      <div className="grid-x">{renderPacks()}</div>
      {!fetching && (
        <div className={styles.packDescription}>
          {AvailablePlaceCounter() < 1 ? (
            <span>{t('packFilled')}</span>
          ) : (
            <span>
              <span className={styles.counter}>{registeredPlaceCounter()}</span>
              <span>{t('Registered')}</span>
              <span>{t('sur')}</span>
              <span className={styles.counter}>{totalPlaceCounter()}</span>
              <span>{t('seats')}</span>
            </span>
          )}
        </div>
      )}
      <div className={styles.packAdd}>
        <button onClick={goToStep}>
          <a>
            <IconAdd width="14" height="14" fill="#29394D" />
            <span className="m-l-xs">{t('Buy a new Pack')}</span>
          </a>
        </button>
      </div>
    </div>
  );
}
