export interface NameWrappedAction {
  meta: string;
}
export interface AnswersState {
  fetching: boolean;
  fetched: boolean;
  items: any[];
  error: any;
  nbResult: number;
  saving: boolean;
  saved: boolean;
}
export const FETCH_ANSWERS = 'FETCH_ANSWERS';
export const FETCH_ANSWERS_PENDING = 'FETCH_ANSWERS_PENDING';
export const FETCH_ANSWERS_FULFILLED = 'FETCH_ANSWERS_FULFILLED';
export const FETCH_ANSWERS_REJECTED = 'FETCH_ANSWERS_REJECTED';

export const SAVE_ANSWER = 'SAVE_ANSWER';
export const SAVE_ANSWER_PENDING = 'SAVE_ANSWER_PENDING';
export const SAVE_ANSWER_FULFILLED = 'SAVE_ANSWER_FULFILLED';
export const SAVE_ANSWER_REJECTED = 'SAVE_ANSWER_REJECTED';

export interface FetchAnswersAction extends NameWrappedAction {
  type:
    | typeof FETCH_ANSWERS
    | typeof FETCH_ANSWERS_PENDING
    | typeof FETCH_ANSWERS_FULFILLED
    | typeof FETCH_ANSWERS_REJECTED;
  payload: any;
}

export interface SaveAnswerAction extends NameWrappedAction {
  type:
    | typeof SAVE_ANSWER
    | typeof SAVE_ANSWER_PENDING
    | typeof SAVE_ANSWER_FULFILLED
    | typeof SAVE_ANSWER_REJECTED;
  payload: any;
}
export type AnswersActionTypes = FetchAnswersAction | SaveAnswerAction;
