import actions from './actions';
import * as api from './api';
import { Dispatch } from 'redux';
import { GenericReducerTypes, ResourceData, RootState } from 'store/types';
import { Filter } from 'services/Filter';
import { SingleValueFilter } from 'services/Filter';
import { Guest } from './types';
import { PartnerGuestMailType } from 'interfaces/PartnerGuest';
import { selectToken } from 'store/Auth';
import { Certificate } from 'interfaces/Certificate';
import { isEmpty } from 'lodash';
import { getPremiumOffers } from 'store/Cycle/api';
import { CYCLE_PREMIUM_ID } from 'utils';

export const fetchGuests = (filters: Filter[] = []) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.fetchGuests(
      api.getGuests({ token, filters }).then((res) => {
        const { data, nbResult } = res.data;
        return { data, nbResult };
      }),
    ),
  );
};

export const fetchGuestData = (
  eventId: string | number,
  userId: string | number,
  codeCoupon?: string | number,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;
  const filters = [
    new SingleValueFilter('event.id', 'eq', eventId),
    new SingleValueFilter('user', 'eq', userId),
  ];

  if (!isEmpty(String(codeCoupon))) {
    filters.push(
      new SingleValueFilter<string | number>(
        'couponCode',
        'eq',
        String(codeCoupon),
      ),
    );
  }

  return dispatch(
    actions.fetchGuestData(
      api.getGuestData({ token, filters }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const fetchGuestByCycleAndUser = (
  cycleId: string | number,
  userId: string | number,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.fetchGuestData(
      api.getGuestByCycleAndUser({ token, cycleId, userId }).then((res) => {
        const { data, nbResult } = res.data;
        return { data: [data], nbResult };
      }),
    ),
  );
};

export const fetchPremiumGuest = (userId: string | number, cycleId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const token = selectToken(state);

  const cycleId = CYCLE_PREMIUM_ID;
  return dispatch(
    actions.fetchPremiumGuest(
      api.getGuestByCycleAndUser({ token, cycleId, userId }).then((res) => {
        const { data, nbResult } = res?.data;
        return { data: data, nbResult };
      }),
    ),
  );
};

export const fetchUserPremiumStatus = (
  cycleId: string | number,
  userId: string | number,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.fetchUserPremiumStatus(
      api.getGuestByCycleAndUser({ token, cycleId, userId }).then((res) => {
        const { data } = res.data;
        return { data: data.premiumRegistrationStatus };
      }),
    ),
  );
};

/// ------------------ EVENT LIGHT REGISTRATION ------------------ ///

export const acceptRegistration = (eventId: number, answer?: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.acceptRegistrationGuests(
      api
        .acceptRegistration({
          token,
          userId: user?.id ?? 0,
          eventId: eventId,
          answer,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const declineRegistration = (eventId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.declineRegistrationGuests(
      api
        .declineRegistration({
          token,
          userId: user?.id ?? 0,
          eventId: eventId,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

/// ------------------ EVENT REGISTRATION ------------------ ///

export const saveStep1 = (
  eventId: string,
  userId: string,
  guestData: ResourceData<Guest>,
) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.saveGuestData(
      api.saveStep1({ token, eventId, userId, guestData }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

export const saveStep2 = (
  eventId: string,
  userId: string,
  data: ResourceData<api.Step2Data>,
) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.saveGuestData(
      api.saveStep2({ token, eventId, userId, data }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

export const registerPremiumToEvent = (
  eventId: number | string,
  userId: number | string,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;
  const cycleId = CYCLE_PREMIUM_ID;

  return dispatch(
    actions.registerPremiumToEvent(
      api
        .registerPremiumToEvent({
          token,
          eventId,
          userId,
          cycleId,
        })
        .then((res: any) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

/// ------------------ COUPON ------------------ ///

export const checkCoupon = ({
  code,
  guestId,
  eventId,
  cycleId,
}: {
  code: string;
  guestId: number;
  eventId?: number;
  cycleId?: number;
}) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.checkCoupon(
      api
        .checkCoupon({
          token,
          guestId,
          eventId,
          cycleId,
          code,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

/// ------------------ ADDRESS ------------------ ///

export const deleteAddress = (
  signature: string,
  isGuestAddress?: boolean,
  guestId?: number,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.deleteAddress(
      api
        .deleteAddress({
          token,
          userId: user?.id ?? 0,
          signature,
          isGuestAddress,
          guestId,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

/// ------------------ PARTNER GUESTS ------------------ ///

interface sendPartnerGuestsEmailParams {
  eventId: string;
  partnerId: string;
  ids?: number[];
  level?: PartnerGuestMailType;
  // resourceName: GenericReducerTypes;
}

export const sendPartnerGuestsEmail = ({
  eventId,
  partnerId,
  ids,
  level, // resourceName = 'PARTNER_GUESTS',
}: sendPartnerGuestsEmailParams) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .sendPartnerGuestsEmail({ token, eventId, partnerId, ids, level })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

export const invitePartnerSpeaker = ({
  eventId,
  partnerId,
  couponModelId,
}: {
  eventId: string;
  partnerId: string;
  couponModelId: string | number;
}) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .invitePartnerSpeaker({ token, eventId, partnerId, couponModelId })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

export const getPartnerGuestBadges = ({
  eventId,
  ids,
  send,
  download,
}: {
  eventId: string;
  send?: boolean;
  download?: boolean;
  ids: number[];
}) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .getPartnerGuestBadges({ token, eventId, ids, send, download })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

export const savePartnerGuest = (
  guestId: string | null,
  eventId: string,
  partnerId: string,
  data: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .savePartnerGuest({
      token,
      guestId,
      eventId,
      partnerId,
      data,
    })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

export const importPartnerGuests = (
  eventId: string,
  partnerId: string,
  data: string,
  file: any,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .importPartnerGuests({
      token,
      eventId,
      partnerId,
      data,
      file,
    })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

export const checkImportGuestsCommandStatus = (commandId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .checkImportGuestsCommandStatus({
      token,
      commandId,
    })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

export const getInvitationPreview = ({
  eventId,
  partnerGuestId,
}: {
  eventId: string;
  partnerGuestId: string;
}) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .getInvitationPreview({ token, eventId, partnerGuestId })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

export const deletePartnerGuest = ({ partnerGuestId }: any) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api.deletePartnerGuest({ token, partnerGuestId }).then((res) => {
    const { data } = res.data;
    return { data };
  });
};

export const forceRegistration = (
  eventId: string,
  partnerId: string,
  partnerGuestIds: number[],
) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return api
    .forceRegistration({ token, eventId, partnerId, partnerGuestIds })
    .then((res) => {
      const { data } = res.data;
      return { data };
    });
};

/// ------------------ GENERATE BADGE ------------------ ///

export const generateBadge = (guestId: any, eventId: any) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.generateBadge(
      api.generateBadge({ token, guestId, eventId }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

/// ------------------ SEND BADGE TO EMAIL ------------------ ///

export const sendBadge = (guestId: any, eventId: any) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.sendBadge(
      api.sendBadge({ token, guestId, eventId }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

/// ------------------ SEND CONFIRMATION TO SUBSCRIBED GUESTS ------------------ ///
export const sendGuestConfirmationSubscribed = (guestsId: any) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.sendGuestConfirmationSubscribed(
      api.sendGuestConfirmationSubscribed({ token, guestsId }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

export default {
  fetchGuests,
  fetchGuestData,
  acceptRegistration,
  declineRegistration,
  saveStep1,
  saveStep2,
  deleteAddress,
  checkCoupon,
  importPartnerGuests,
  savePartnerGuest,
  checkImportGuestsCommandStatus,
  getInvitationPreview,
  forceRegistration,
};

/// ------------------ CERTIFICATE ------------------ ///

export const getCertificateForEventAndGuest = (
  guestId: number,
  eventId: number,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const token = selectToken(state);

  return dispatch(
    actions.fetchCertificates(
      api
        .getCertificateForEventAndGuest({ token, guestId, eventId })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const generateCertificate = (certificate: Certificate) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const token = selectToken(state);

  return dispatch(
    actions.generateCertificate(
      api.generateCertificate({ token, certificate }).then((res) => {
        const { data } = res.data;
        return { data };
      }),
    ),
  );
};

export const sendCertificate = (certificateId: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const token = selectToken(state);

  return api.sendCertificate({ token, certificateId }).then((res) => {
    const { data } = res.data;
    return { data };
  });
};

export const editCertificate = (certificateData: ResourceData<Certificate>) => (
  dispatch: Dispatch<any>,
  getState: () => RootState,
) => {
  const state = getState();
  const token = selectToken(state);

  return api.editCertificate({ token, certificateData }).then((res) => {
    const { data } = res.data;
    return { data };
  });
};

/// ------------------ CONFIRM GUEST OPTION ------------------ ///

export const confirmGuestOption = (
  guestId: number,
  optionId: number,
  hasConfirmed: boolean,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.confirmGuestOption(
      api
        .confirmGuestOption({
          token,
          guest: guestId,
          option: optionId,
          hasConfirmed: hasConfirmed,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

export const updateAddress = (signature: string, data: any) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken, user },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.updateAddress(
      api
        .updateAddress({ token, userId: user?.id ?? 0, signature, data })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};

/// ------------------ CONFIRM OPTIONS REJECTION ------------------ ///

export const confirmOptionsRejection = (userId: number, eventId: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const token = userToken !== '' ? userToken : appToken.token;

  return dispatch(
    actions.confirmOptionsRejection(
      api
        .confirmOptionsRejection({
          token,
          userId: userId,
          eventId: eventId,
        })
        .then((res) => {
          const { data } = res.data;
          return { data };
        }),
    ),
  );
};
