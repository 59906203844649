import { SocialActionTypes, ADD_LIKE, ADD_DISLIKE, ADD_TO_FAV } from './types';

export const addLike = (resp: any): SocialActionTypes => {
  return {
    type: ADD_LIKE,
    payload: resp,
  };
};
export const addDisLike = (resp: any): SocialActionTypes => {
  return {
    type: ADD_DISLIKE,
    payload: resp,
  };
};
export const addToFav = (resp: any): SocialActionTypes => {
  return {
    type: ADD_TO_FAV,
    payload: resp,
  };
};

export default {
  addLike,
  addDisLike,
  addToFav,
};
