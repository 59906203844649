import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectShowGoBack,
  selectShowNotificationBar,
} from 'store/Params/selectors';
import { RootState } from 'store/types';
import { getMainMenuHeight } from 'utils';

export const useMainMenuHeight = () => {
  const [mainMenuHeight, setMainMenuHeight] = useState<number>(0);

  const isNotifBarShown = useSelector((state: RootState) =>
    selectShowNotificationBar(state),
  );
  const isGoBackShown = useSelector((state: RootState) =>
    selectShowGoBack(state),
  );

  useEffect(() => {
    setMainMenuHeight(getMainMenuHeight());
  }, [isNotifBarShown, isGoBackShown]);

  return mainMenuHeight;
};
