import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = (isCaseInsensitive?: boolean) => {
  const location = useLocation();

  const getNewUrlSearchParams = (searchParams: string) => {
    const params = new URLSearchParams(searchParams);

    return isCaseInsensitive
      ? new URLSearchParams(
          [...params].map(([key, value]) => [key.toLowerCase(), value]),
        )
      : params;
  };

  return useMemo(() => getNewUrlSearchParams(location.search), [
    location.search,
  ]);
};
