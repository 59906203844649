import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import styles from './SpeakersList.module.scss';
import classNames from 'classnames';
import { Speaker as SpeakerType } from 'store/Speakers/types';
import {
  capitalizeFirstLetter,
  getByLanguage,
  getSpeakerPageUrl,
  parseJson,
  prepareS3ResourceUrl,
} from 'utils';
import { Language } from 'store/types';
import Markdown from 'markdown-to-jsx';
import { S3_FOLDER_URL_PROD } from 'config';
import { isEmpty } from 'lodash';
import { openModal } from 'store/Modal/actions';
import _ from 'i18n';
import Avatar from 'components/ui/Avatar';

interface Props {
  speaker: SpeakerType;
  language: Language;
  isSingleLayout?: boolean;
}

export default function Speaker({
  speaker,
  language,
  isSingleLayout,
}: Props): ReactElement {
  const dispatch = useDispatch();
  let headline = getByLanguage(speaker, 'headline', language);
  if (typeof headline === 'object') {
    headline = headline.title;
  } else if (typeof parseJson(headline) === 'object') {
    headline = parseJson(headline).title;
  }
  const cv = getByLanguage(speaker, 'cv', language) ?? '';
  const { pictureUrl, firstName, lastName } = speaker;
  const name = `${firstName} ${lastName}`.trim();
  const headlineCustom =
    !isEmpty(headline) && headline.length > 120
      ? `${headline.slice(0, 120)}...`
      : headline;

  const handleOpenModal = (speaker: SpeakerType) => {
    dispatch(openModal('SPEAKER', { data: speaker }));
  };

  return (
    <div
      className={classNames(
        styles.speaker,
        isSingleLayout && styles.single_layout,
      )}
    >
      <div className={styles.wrapper}>
        <div className={styles.infos}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={getSpeakerPageUrl(speaker, language)}
          >
            <Avatar
              className={styles.avatarWrapper}
              url={prepareS3ResourceUrl(S3_FOLDER_URL_PROD, pictureUrl)}
              firstName={firstName}
              lastName={lastName}
              width="120px"
              height="120px"
              fontSize="3rem"
            />
            {/* <div
            className={styles.avatarWrapper}
            style={{
              backgroundImage: `url(${S3_FOLDER_URL}/events-folder${pictureUrl})`,
            }}
          /> */}
            <h5>{name}</h5>
          </a>
          <h6>{isSingleLayout ? headline : headlineCustom}</h6>
        </div>
        {isSingleLayout && (
          <React.Fragment>
            <div className={styles.separator} />
            <div className={styles.description}>
              <Markdown className={classNames('markdown', styles.cv)}>
                {cv}
              </Markdown>
            </div>
          </React.Fragment>
        )}
        {!isSingleLayout && (
          <button
            type="button"
            className={styles.detailsButton}
            onClick={() => handleOpenModal(speaker)}
            disabled={cv === ''}
          >
            {_('more_details')}
          </button>
        )}
      </div>
    </div>
  );
}

Speaker.Fetching = () => (
  <div className={styles.speaker}>
    <div
      className={styles.avatarWrapper}
      style={{
        backgroundColor: '#e8eaed',
      }}
    ></div>
    <div className={classNames(styles.wrapper, styles.fetching)}>
      <div className={styles.infos}>
        <h5 className={styles.fetching} />
        <h6 className={styles.fetching} />
      </div>
    </div>
  </div>
);
