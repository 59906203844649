import {
  ADD_SLOT_TO_PROGRAM,
  FETCH_GUEST_PROGRAM,
  GuestProgramActionTypes,
  REMOVE_SLOT_FROM_PROGRAM,
  RESET_GUEST_PROGRAM,
} from './types';

export const resetGuestProgram = (): GuestProgramActionTypes => ({
  type: RESET_GUEST_PROGRAM,
});

export const fetchGuestProgram = (resp: any): GuestProgramActionTypes => ({
  type: FETCH_GUEST_PROGRAM,
  payload: resp,
});

export const addSlotToProgram = (resp: any): GuestProgramActionTypes => ({
  type: ADD_SLOT_TO_PROGRAM,
  payload: resp,
});

export const removeSlotFromProgram = (resp: any): GuestProgramActionTypes => ({
  type: REMOVE_SLOT_FROM_PROGRAM,
  payload: resp,
});

export default {
  resetGuestProgram,
  fetchGuestProgram,
  addSlotToProgram,
  removeSlotFromProgram,
};
