export type SingleValueOperator =
  | 'eq'
  | 'neq'
  | 'like'
  | 'gt'
  | 'lt'
  | 'gte'
  | 'lte'
  | 'nNull'
  | 'eqOrNull';
export const singleValueOperators = [
  'eq',
  'neq',
  'like',
  'gt',
  'lt',
  'gte',
  'lte',
  'nNull',
  'eqOrNull',
];

export type MultiValueOperator = 'in' | 'nin' | 'between';
export const multiValueOperators = ['in', 'nin', 'between'];

export type Operator = SingleValueOperator | MultiValueOperator | 'anyPLike';
export const operators = [...singleValueOperators, multiValueOperators];
