import React from 'react';
import s from './SearchHeader.module.scss';
import t from 'i18n';
import cn from 'classnames';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { isEmpty, lowerCase } from 'lodash';
import { Filter, SingleValueFilter, SingleValueType } from 'services/Filter';

interface Props {
  searchValue: string;
  filter: SingleValueFilter<SingleValueType>;
  onCancel: () => void;
  onSearch: () => void;
  onChange: (value: string) => void;
  addEventFilter: (filter: Filter) => void;
  removeEventFilter: (filter: Filter) => void;
}

const SearchHeader = ({
  searchValue,
  filter,
  onCancel,
  onSearch,
  onChange,
  addEventFilter,
  removeEventFilter,
}: Props) => {
  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      onSearch();
    }
  };

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value != null && value !== '') {
      const newFilter = filter.clone();
      newFilter.value = value;
      addEventFilter(newFilter);
    } else {
      handleRemoveFilter();
    }
  };

  const handleRemoveFilter = () => {
    removeEventFilter(filter);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.search_input}>
        <span className="m-l-xs">
          <SearchIcon fill="#B2BCC6" width="20" height="20" stroke="#B2BCC6" />
        </span>
        <input
          type="text"
          placeholder={`${t('Training, speaker, keywords ...')}`}
          value={filter.value || searchValue}
          onChange={handleChange}
          onKeyDown={handleOnKeyDown}
        />
        <span
          onClick={() => onChange('')}
          className={cn(
            'm-r-s',
            s.clear_input,
            !isEmpty(searchValue) && s.visible,
          )}
        >
          <CrossIcon fill="#fff" width="20" height="20" />
        </span>
      </div>
      <div className={s.cancel_btn} onClick={onCancel}>
        {lowerCase(t('cancel'))}
      </div>
    </div>
  );
};

export default SearchHeader;
