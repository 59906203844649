import { WithPaginationAndFilterState } from 'store/types';
export interface Collaborators {
  id: number;
  firstName: string;
  lastName: string;
  usedCouponName: any;
  guestId: number;
}

export interface NameWrappedAction {
  meta: string;
}

export const ADD_COLLABORATOR = 'ADD_COLLABORATOR';
export const RESET_COLLABORATORS = 'RESET_COLLABORATORS';
export const FETCH_COLLABORATORS = 'FETCH_COLLABORATORS';
export const FETCH_COLLABORATORS_PENDING = 'FETCH_COLLABORATORS_PENDING';
export const FETCH_COLLABORATORS_FULFILLED = 'FETCH_COLLABORATORS_FULFILLED';
export const FETCH_COLLABORATORS_REJECTED = 'FETCH_COLLABORATORS_REJECTED';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
export const FETCH_USER_REJECTED = 'FETCH_USER_REJECTED';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED';

export const SAVE_COLLABORATORS = 'SAVE_COLLABORATORS';
export const SAVE_COLLABORATORS_PENDING = 'SAVE_COLLABORATORS_PENDING';
export const SAVE_COLLABORATORS_FULFILLED = 'SAVE_COLLABORATORS_FULFILLED';
export const SAVE_COLLABORATORS_REJECTED = 'SAVE_COLLABORATORS_REJECTED';

export const UPLOAD_COLLABORATORS = 'UPLOAD_COLLABORATORS';
export const UPLOAD_COLLABORATORS_PENDING = 'UPLOAD_COLLABORATORS_PENDING';
export const UPLOAD_COLLABORATORS_FULFILLED = 'UPLOAD_COLLABORATORS_FULFILLED';
export const UPLOAD_COLLABORATORS_REJECTED = 'UPLOAD_COLLABORATORS_REJECTED';

export const DELETE_COLLABORATOR = 'DELETE_COLLABORATOR';
export const DELETE_COLLABORATOR_PENDING = 'DELETE_COLLABORATOR_PENDING';
export const DELETE_COLLABORATOR_FULFILLED = 'DELETE_COLLABORATOR_FULFILLED';
export const DELETE_COLLABORATOR_REJECTED = 'DELETE_COLLABORATOR_REJECTED';

export interface ResetCollaboratorsAction extends NameWrappedAction {
  type: typeof RESET_COLLABORATORS;
}
export interface FetchCollaboratorsAction extends NameWrappedAction {
  type:
    | typeof FETCH_COLLABORATORS
    | typeof FETCH_COLLABORATORS_PENDING
    | typeof FETCH_COLLABORATORS_FULFILLED
    | typeof FETCH_COLLABORATORS_REJECTED;
  payload: any;
}

export interface FetchUserAction extends NameWrappedAction {
  type:
    | typeof FETCH_USER
    | typeof FETCH_USER_PENDING
    | typeof FETCH_USER_FULFILLED
    | typeof FETCH_USER_REJECTED;
  payload: any;
}

export interface FetchUsersAction extends NameWrappedAction {
  type:
    | typeof FETCH_USERS
    | typeof FETCH_USERS_PENDING
    | typeof FETCH_USERS_FULFILLED
    | typeof FETCH_USERS_REJECTED;
  payload: any;
}

export interface SaveCollaboratorsAction extends NameWrappedAction {
  type:
    | typeof SAVE_COLLABORATORS
    | typeof SAVE_COLLABORATORS_PENDING
    | typeof SAVE_COLLABORATORS_FULFILLED
    | typeof SAVE_COLLABORATORS_REJECTED;
  payload: any;
}

export interface UploadCollaboratorsAction extends NameWrappedAction {
  type:
    | typeof UPLOAD_COLLABORATORS
    | typeof UPLOAD_COLLABORATORS_PENDING
    | typeof UPLOAD_COLLABORATORS_FULFILLED
    | typeof UPLOAD_COLLABORATORS_REJECTED;
  payload: any;
}

export interface DeleteCollaboratorAction extends NameWrappedAction {
  type:
    | typeof DELETE_COLLABORATOR
    | typeof DELETE_COLLABORATOR_PENDING
    | typeof DELETE_COLLABORATOR_FULFILLED
    | typeof DELETE_COLLABORATOR_REJECTED;
  payload: any;
}

export interface CollaboratorsState extends WithPaginationAndFilterState {
  fetching: boolean;
  fetched: boolean;
  saving: boolean;
  saved: boolean;
  deleting: boolean;
  deleted: boolean;
  userFetching: boolean;
  userFetched: boolean;
  items: any[];
  collaborators: any[];
  error: any;
  nbResult: number;
  data: any;
  newCollab: any;
}

export interface AddCollaboratorAction {
  type: typeof ADD_COLLABORATOR;
  payload: any;
}

export type CollaboratorsActionTypes =
  | FetchCollaboratorsAction
  | ResetCollaboratorsAction
  | SaveCollaboratorsAction
  | DeleteCollaboratorAction
  | AddCollaboratorAction
  | FetchUserAction
  | FetchUsersAction
  | UploadCollaboratorsAction;
