import React, { ReactElement, useState } from 'react';
import styles from './CollaboratorLayout.module.scss';
import { getByLanguage, onError, onSuccess } from 'utils';
import { ReactComponent as ExpanderIcon } from 'assets/icons/expander.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import _ from 'i18n';
import { truncText } from './services';
import classNames from 'classnames';
import { Popover } from 'antd';

interface Props {
  collaborator: any;
  organisationId: number;
  pack: any;
  partner?: number;
  eventId?: number;
  ownerId?: number;
  fetchPacks: (organizationId: number, eventId: number) => void;
  getEmployeePack: (organizationId: number, eventId: number) => void;
  savePlace: (
    event: any,
    user: any,
    couponModel: any,
    owner: any,
    partner: any,
  ) => any;
  cancelPlace: (
    eventId: any,
    partnerId: any,
    guestId: any,
    ownerId: any,
  ) => any;
  sendGuestConfirmationSubscribed: (guestsId: number) => any;
}

export default function CollaboratorLayout({
  collaborator,
  pack,
  partner,
  organisationId,
  eventId,
  ownerId,
  fetchPacks,
  savePlace,
  cancelPlace,
  getEmployeePack,
  sendGuestConfirmationSubscribed,
}: Props): ReactElement {
  const [popOver, togglePopOver] = useState(false);
  const isRegistered =
    String(collaborator.guestUsedCoupon).search(new RegExp('null|^$')) ||
    collaborator?.invitedBy?.organizationId == organisationId;

  const {
    userId,
    firstName,
    lastName,
    guestId,
    guestUsedCoupon,
    guestPlanName,
    registeredChoice,
    invitedBy,
  } = collaborator;

  const name = `${firstName} ${lastName}`.trim();
  const attributedByOther = invitedBy?.organizationId !== organisationId;
  const hasIndividualInscription = registeredChoice && attributedByOther;
  const img = document.createElement('img');
  img.src = '/img/drag-collaborator.png';
  const dragStartHandler = (
    event: React.DragEvent<HTMLDivElement>,
    collaborator: any,
  ) => {
    if (guestUsedCoupon || hasIndividualInscription) {
      event.preventDefault();
    } else {
      event.dataTransfer.setDragImage(img, 0, 0);
      event.dataTransfer.setData('text', collaborator);
      (event.target as Element).classList.add('dragging');
    }
  };

  const handleUnAssign = (
    eventId: any,
    partnerId: any,
    guestId: any,
    ownerId: any,
  ) => {
    return cancelPlace(eventId, partnerId, guestId, ownerId)
      .then((res: any) => {
        onSuccess(res, {
          title: _('saveSuccess'),
          body: _('placeCanceled'),
        });
        getEmployeePack(organisationId, eventId);
        fetchPacks(organisationId, eventId);
      })
      .catch((res: any) => {
        onError(res);
      });
  };

  const handleSendConfirmation = (guestsId: any) => {
    return sendGuestConfirmationSubscribed(guestsId)
      .then(() => {
        onSuccess();
      })
      .catch((res: any) => {
        onError(res);
      });
  };

  const handleAttribution = (
    event: any,
    user: any,
    couponModel: any,
    owner: any,
    partner: any,
  ) => {
    return savePlace(event, user, couponModel, owner, partner)
      .then((res: any) => {
        onSuccess(res, {
          title: _('saveSuccess'),
          body: _('placeAssigned'),
        });
        getEmployeePack(organisationId, event);
        fetchPacks(organisationId, event);
      })
      .catch((res: any) => {
        onError(res);
      });
  };

  const handleVisibleChange = (visible: any) => {
    togglePopOver(visible);
  };

  //List of packs shown on PopOver
  const renderPacks = () => {
    return Object.keys(pack).map((key) => (
      <div
        className="grid-x"
        key={pack[key].couponModelId}
        style={
          pack[key].availablePlaces < 1
            ? { pointerEvents: 'none', opacity: '0.5', cursor: 'not-allowed' }
            : {}
        }
      >
        <input
          id={pack[key].couponModelId}
          name="radio"
          value={pack[key].couponModelId}
          type="radio"
          onChange={() => {
            togglePopOver(false);
            handleAttribution(
              eventId,
              userId,
              pack[key].couponModelId,
              ownerId,
              partner,
            );
          }}
        />
        <label className="cell small-10" htmlFor={pack[key].couponModelId}>
          <span /> {pack[key].nameFr}
        </label>
        <span className="cell small-2">
          {pack[key].usedPlaces}/{pack[key].totalPlaces}
        </span>
      </div>
    ));
  };

  return (
    <div
      className={classNames(`cell small-4 Collab`, styles.colElement)}
      onDragStart={(event: any) => dragStartHandler(event, collaborator.userId)}
      onDragEnd={(event: any) =>
        event.currentTarget.classList.remove('dragging')
      }
      draggable={true}
    >
      <div
        className={classNames(`AddButton`, styles.feed)}
        style={{ backgroundColor: hasIndividualInscription ? '#6868681a' : '' }}
      >
        {!guestUsedCoupon && !hasIndividualInscription && (
          <ExpanderIcon className={styles.feed__expander} />
        )}
        <div className={styles.feed__Button}>
          {guestUsedCoupon ? (
            attributedByOther ? (
              ''
            ) : (
              <>
                <span
                  className="m-r-xs"
                  style={{
                    borderWidth: '1px',
                    borderColor: '#DCE0E3',
                    borderStyle: 'solid',
                  }}
                  onClick={() => handleSendConfirmation(guestId)}
                >
                  <SendIcon width="18" height="18" fill="#6D7F92" />
                </span>
                <span
                  className="m-r-xs"
                  style={{
                    borderWidth: '1px',
                    borderColor: 'rgba(254, 55, 69, 0.3)',
                    borderStyle: 'solid',
                  }}
                  onClick={() =>
                    handleUnAssign(eventId, partner, guestId, ownerId)
                  }
                >
                  <CloseIcon width="18" height="18" />
                </span>
              </>
            )
          ) : hasIndividualInscription ? (
            ''
          ) : (
            <Popover
              overlayClassName="help_toolkit"
              content={
                <div className={styles.attribution__container}>
                  <div className={styles.attribution__title}>
                    {_('AssignPlace')} :
                  </div>
                  <div className={styles.pack_options}>{renderPacks()}</div>
                </div>
              }
              trigger="click"
              placement="bottomRight"
              visible={popOver}
              onVisibleChange={handleVisibleChange}
            >
              <span
                className="m-r-xs"
                style={{ background: popOver ? '#F7953E' : '' }}
                onClick={() => togglePopOver(!popOver)}
              >
                <AddIcon
                  fill={popOver ? '#FFF' : '#6D7F92'}
                  width="12"
                  height="12"
                />
              </span>
            </Popover>
          )}
        </div>
        <div className="flex-container">
          <div
            className={styles.feed_content}
            style={guestUsedCoupon ? { marginLeft: '24px' } : {}}
          >
            <p>{truncText(name)}</p>
            <p>
              {hasIndividualInscription
                ? _('Individually subscribed')
                : guestPlanName?.NameFr}
            </p>
            {!isRegistered && (
              <span className={styles.not_registered}>
                {_('Not_registered')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

CollaboratorLayout.Fetching = () => (
  <div className={`cell small-4`}>
    <div className={styles.feed}>
      <ExpanderIcon className={styles.feed__expander} />
      <div className={styles.feed__Button} />
      <div className="flex-container">
        <div className={styles.feed_fetching} />
      </div>
    </div>
  </div>
);
