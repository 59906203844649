import { connect } from 'react-redux';
import { saveStep2, checkCoupon } from 'store/Guests/thunks';
import { RootState } from 'store/types';
import StepPlan from './StepPlan';
import { closeModal } from 'store/Modal/actions';

const mapStateToProps = (state: RootState) => ({
  language: state.params.lng,
  plansAndOptions: state.event.plansAndOptions.data,
});

export default connect(mapStateToProps, {
  saveStep2,
  checkCoupon,
  closeModal,
})(StepPlan);
