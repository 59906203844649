import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from 'store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { APP_ENV } from 'config';
import App from './App';
import { initSentry } from 'sentry';
import ModalCloser from 'Pages/ModalCloser';
import { URLS } from 'router';
import packageJson from '../package.json';
import { withAutologin } from 'hoc/withAutologin';
import Logout from 'components/Logout';

const appVersion = packageJson.version;
window.__APP_VERSION__ = appVersion;

// Load App styles
import 'applicationStyles';

// React dates
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Load jquery
import 'script-loader!jquery/dist/jquery.min.js'; // eslint-disable-line

// Load foundation
// import 'foundation-sites/dist/js/plugins/foundation.core';
// import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.js';
// import 'foundation-sites/dist/js/plugins/foundation.util.triggers.js';
// import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery.js';
// import 'foundation-sites/dist/js/plugins/foundation.util.motion.js';
// import 'foundation-sites/dist/js/plugins/foundation.util.box.js';
// import 'foundation-sites/dist/js/plugins/foundation.reveal.js';
// import 'foundation-sites/dist/js/plugins/foundation.offcanvas.js';
// import 'foundation-sites/dist/js/plugins/foundation.tooltip.js';

// Create browser history
const history = createBrowserHistory();

// Create Redux store
const { store, persistor } = configureStore(history);

// Init Sentry

initSentry(store, {
  APP_ENV,
  APP_NAME: 'event-portal',
  APP_RELEASE: '1.0.0',
  APP_SENTRY_KEY: '6740f1342de6467290e53b733d6dfc56',
  APP_SENTRY_ID: 8,
});

// Render App
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={URLS.healthCheck} render={() => <h1>OK</h1>} />
          <Route path={URLS.modalCloser} component={ModalCloser} />
          <Route path={URLS.logout} component={Logout} />
          <Route component={withAutologin(App)} />
        </Switch>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
