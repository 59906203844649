import axios from 'axios';
import { TTP_API_URL } from 'config';
import { escapeSpecialChars } from '../../utils';

export const packsFields = ['*'];

export const getPack = ({
  token,
  filters = [],
  organizationId,
  eventId,
}: {
  token: string;
  filters?: any[];
  organizationId: number;
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack/organization-packs`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      eventId: eventId,
      filter: escapeSpecialChars(JSON.stringify(filters)),
    },
  });
};

export const getAllPacks = ({
  token,
  filters = [],
  eventId,
}: {
  token: string;
  filters?: any[];
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack/get-all-packs`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      eventId: eventId,
      filter: escapeSpecialChars(JSON.stringify(filters)),
    },
  });
};

export const savePlace = ({
  token,
  event,
  user,
  couponModel,
  owner,
  partner,
}: {
  token: string;
  event: number;
  user: number;
  couponModel: number;
  owner: number;
  partner: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack/subscribe-user`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('event', String(event));
  formData.append('user', String(user));
  formData.append('couponModel', String(couponModel));
  formData.append('owner', String(owner));
  formData.append('partner', String(partner));

  return axios.post(requestUrl, formData);
};

export const choosePack = ({
  token,
  eventId,
  packId,
  userId,
}: {
  token: string;
  eventId: string;
  packId: string;
  userId: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack-order/choose-pack`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', eventId);
  formData.append('packId', packId);
  formData.append('userId', userId);

  return axios.post(requestUrl, formData);
};

export const cancelPlace = ({
  token,
  eventId,
  partnerId,
  guestId,
  ownerId,
}: {
  token: string;
  eventId: any;
  partnerId: any;
  guestId: any;
  ownerId: any;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack/unsubscribe-user`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', eventId);
  formData.append('partnerId', partnerId);
  formData.append('guestId', guestId);
  formData.append('owner', ownerId);

  return axios.post(requestUrl, formData);
};

export const setPackOrderFiduciary = ({
  token,
  packOrderId,
  organizationId,
}: {
  token: string;
  packOrderId: string;
  organizationId: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack-order/set-fiduciary`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('packOrderId', packOrderId);
  formData.append('organizationId', organizationId);

  return axios.post(requestUrl, formData);
};

export const getPackSummary = ({
  token,
  userId,
  eventId,
}: {
  token: string;
  userId: number;
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack/registration-summary`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      userId: userId,
      eventId: eventId,
    },
  });
};

export const cancelPackOrder = ({
  token,
  packOrderId,
}: {
  token: string;
  packOrderId: string;
}) => {
  const requestUrl = `${TTP_API_URL}/event/pack-order/cancel`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('packOrderId', packOrderId);

  return axios.post(requestUrl, formData);
};
