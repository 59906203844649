import { AnyAction } from 'redux';
import { FilterState } from './types';
import { Filter } from 'services/Filter';
import { AppReducer, GenericReducerTypes } from 'store/types';

export const filterInitialState = {
  filters: [],
};

const addFilter = <S extends FilterState>(state: S, newFilter: Filter): S => {
  const oldFilter = state.filters.find((filter) => filter.isSame(newFilter));

  // Add filter
  if (!oldFilter) {
    return { ...state, filters: [...state.filters, newFilter] };
  }

  // Replace filter
  if (!oldFilter.isEqual(newFilter)) {
    return {
      ...state,
      filters: [
        ...state.filters.filter((filter) => filter !== oldFilter),
        newFilter,
      ],
    };
  }

  return state;
};

export const withFilter = <S extends FilterState, A extends AnyAction>(
  reducer: AppReducer<S, A>,
  reducerName: GenericReducerTypes,
) => (state: S, action: A): S => {
  switch (action.type) {
    case `INITIALIZE_${reducerName}_FILTER`:
      return { ...state, filters: [] };
    case `ADD_${reducerName}_FILTER`: {
      const newFilter: Filter = action.filter;
      return addFilter(state, newFilter);
    }
    case `ADD_${reducerName}_FILTERS`: {
      const filters: Filter[] = action.filters;

      return filters.reduce(
        (currentState, filter) => addFilter(currentState, filter),
        state,
      );
    }
    case `REMOVE_${reducerName}_FILTER`:
      return {
        ...state,
        filters: [
          ...state.filters.filter((filter) => filter !== action.filter),
        ],
      };
    default:
      return reducer(state, action);
  }
};

export default withFilter;
