import React, { memo } from 'react';
import styles from './Session.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import { ReactComponent as SessionIcon } from 'assets/icons/session.svg';
import moment from 'moment';
import { isLive } from 'utils';
import LiveIcon from 'components/ui/LiveIcon';
import { isEmpty } from 'lodash';

interface Props {
  title: string;
  backgroundStyle: React.CSSProperties;
  speakers: string;
  isSelected: boolean;
  showHeader?: boolean;
  startAt: string;
  endAt: string;
  renderActions: () => JSX.Element;
  onClick: (event: any) => void;
  renderExtras: () => JSX.Element | null;
}

export default memo(function Session({
  title,
  backgroundStyle,
  isSelected,
  startAt,
  endAt,
  speakers,
  renderExtras,
  renderActions,
  onClick,
}: Props) {
  const renderTiming = () => {
    const isSessionLive = isLive(startAt, endAt);

    if (isSessionLive) {
      return (
        <div className="flex-container align-middle">
          <LiveIcon className="m-r-xxs" />
          <span style={{ color: '#fe3745' }}>{_('Ongoing')}</span>
        </div>
      );
    }

    const startHour = moment(startAt).format('HH:mm');
    const endHour = moment(endAt).format('HH:mm');

    return (
      <div className={classNames(styles.time, 'm-b-xxs')}>
        {startHour} - {endHour}
      </div>
    );
  };

  return (
    <div
      onClick={onClick}
      className={classNames(styles.layout4, isSelected && styles.hilighted)}
    >
      <div className="grid-x">
        <div className="cell small-3 flex-container align-middle align-center vseparator p-s dark">
          <div className={classNames(styles.sessionHeader)}>
            <div className={styles.block}>{renderTiming()}</div>
            <div className={styles.block}>
              <SessionIcon className="m-r-xs" width="16" stroke="#6D7F92" />
              <span>{_('Session')}</span>
            </div>
          </div>
        </div>
        <div className="cell small-9 p-s p-l-m flex-container">
          <div className="align-center flex-1 grid-y p-r-s">
            <h3>{title}</h3>
            <h6 className="greetings">{speakers}</h6>
            <div className="m-t-xs">
              <div className={styles.actionWrapper}>{renderActions()}</div>
            </div>
          </div>
          {!isEmpty(backgroundStyle) && (
            <div
              className={styles.logo}
              style={{
                ...backgroundStyle,
              }}
            >
              {renderExtras()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
