export interface popupCounterState {
  popupShowCounter: number;
}

export interface ResetPopupCounterAction {
  type: typeof RESET_POPUP_COUNTER;
}

export interface SetPopupCounterAction {
  type: typeof SET_POPUP_COUNTER;
  popupShowCounter: number;
}

export const RESET_POPUP_COUNTER = 'RESET_POPUP_COUNTER';
export const SET_POPUP_COUNTER = 'SET_POPUP_COUNTER';

export type UpComingEventPopupActionTypes =
  | ResetPopupCounterAction
  | SetPopupCounterAction;
