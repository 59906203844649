import { FETCH_EVENTS_CATEGORIES } from './types';

export const fetchEventsCategories = (
  resp: any,
): { payload: any; type: string } => ({
  type: FETCH_EVENTS_CATEGORIES,
  payload: resp,
});

export default {
  fetchEventsCategories,
};
