import React, { memo } from 'react';
import styles from './Session.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import { ReactComponent as SessionIcon } from 'assets/icons/session.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { SessionLayoutProps } from './types';
import { getByLanguage } from 'utils';
import { S3_FOLDER_URL } from 'config';
import { capitalize, isEmpty } from 'lodash';
import Highlighter from 'react-highlight-words';

interface Props extends SessionLayoutProps {
  renderExtras: () => JSX.Element | null;
}

export default memo(function Session({
  title,
  backgroundStyle,
  isSelected,
  type,
  speakers,
  partner,
  language,
  isSoldOut,
  isWebinar,
  roomName,
  searchKeyword,
  renderExtras,
  renderActions,
  renderTime,
  onClick,
}: Props) {
  const renderPartner = () => {
    if (partner) {
      const logoUrl = getByLanguage(partner, 'pictureUrl', language);
      if (!isEmpty(logoUrl)) {
        return (
          <div className={styles.logoWrapper}>
            <span
              style={{
                backgroundImage: `url(${S3_FOLDER_URL}/${
                  logoUrl?.split('/')[0] !== 'events-folder'
                    ? 'events-folder'
                    : ''
                }${logoUrl})`,
              }}
            />
          </div>
        );
      }
    }
    return <div />;
  };

  const renderSlotMode = () => {
    if (isWebinar) {
      return (
        <div className={classNames(styles.slot_mode, 'm-t-xxs')}>
          <EarthIcon width="14" height="14" fill="#fff" className="m-r-xs" />
          <span>{_('Webinar')}</span>
        </div>
      );
    }

    if (!isEmpty(roomName)) {
      return (
        <div className={classNames(styles.slot_mode, 'm-t-xxs')}>
          <MapPinIcon className="m-r-xs" width="14" height="14" fill="#fff" />
          <span>{roomName}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.session,
        styles.secondary,
        isSelected && styles.hilighted,
      )}
      style={{ ...backgroundStyle }}
    >
      {isSoldOut && (
        <div className={styles.soldOut}>{_('session_sold_out')}</div>
      )}
      <div
        className={classNames(
          styles.secondaryHeader,
          'm-b-m',
          isSoldOut && 'm-r-xl',
        )}
      >
        {renderPartner()}
        {renderActions()}
      </div>
      <div style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}>
        {renderExtras()}
      </div>
      <h3>
        <Highlighter
          highlightClassName="marker"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={title}
        />
      </h3>
      <h6 className="greetings">
        <Highlighter
          highlightClassName="marker"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={speakers}
        />
      </h6>
      <div className={styles.spacer} />

      <div className={classNames('flex-container', styles.sessionHeader)}>
        <div className={styles.block}>{renderTime()}</div>
        <div className={styles.separator}>&#9679;</div>
        <div className={styles.block}>
          <SessionIcon className="m-r-xs" width="16" stroke="#FFF" />
          <span>{capitalize(type)}</span>
        </div>
      </div>

      <div>{renderSlotMode()}</div>
    </div>
  );
});
