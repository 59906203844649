import { connect } from 'react-redux';
import ModalEventQuick from './ModalEventQuick';
import { RootState } from 'store/types';

const mapStateToProps = (state: RootState) => ({
  userId: state.auth.user?.id ?? 0,
  language: state.params.lng,
  guest: state.event.guests.items[0],
});

export default connect(mapStateToProps, {})(ModalEventQuick);
