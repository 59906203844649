import React, { useEffect, useState } from 'react';
import t from 'i18n';
import {
  getByLanguage,
  getSlotReplayUrl,
  isEventLive,
  isEventPast,
  isEventUpcoming,
  onError,
} from 'utils';
import { Event, EventState } from 'store/Events/types';
import Button, { IconButton } from 'ui/Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CalendarPlusIcon } from 'assets/icons/calendar-plus.svg';
import { ReactComponent as OngoingIcon } from 'assets/icons/ongoing-white.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play-filled.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg';
import { FetchResourceThunkParams } from 'store/Resource/thunks';
import { TTPSelectField } from 'Common/TTPForm';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import s from './HeaderActions.module.scss';
import * as yup from 'yup';
import { ResourceState } from 'store/Resource/types';
import { Language, SliderData } from 'store/types';
import { getSliderData } from 'Watch/EventSlide/services';
import { getRankedEventsOptions } from 'Modal/ModalWatch/HeaderActions/services';
import { User } from 'store/Auth/types';
import { ReactComponent as ResumeIcon } from 'assets/icons/resume.svg';
import { AppInfoData } from 'store/Params/AppInfo';
import AddToCalendarBtn from 'components/Common/AddToCalendarBtn';
import { getEventIcsUrl } from 'utils';
import useResponsive from 'hooks/useResponsive';

interface Props {
  event: Event;
  userId?: number;
  saveEventFavorite: (eventId: number, userId: number) => any;
  saveEventClassificationRank: (eventId: number, rank: number) => any;
  deleteEventFavorite: (eventId: number, userId: number) => any;
  eventsFavoriteResource: EventState;
  eventsFavoriteStatsResource: EventState;
  fetchEvents: (params: FetchResourceThunkParams) => void;
  recommendation: ResourceState<SliderData>;
  isTrainingPage?: boolean;
  user?: User | null;
  language: Language;
  setEventFavorite: (
    isEventFavoriteRemoved: boolean,
    isEventFavoriteAdded: boolean,
    eventFavoriteTab: string,
  ) => void;
  setEventClassificationRank: (
    previousRankOfEvent: number,
    updatedRankEventId: number,
    updatedRank: number,
  ) => void;
  resetEventFavorite: () => void;
  appInfo: AppInfoData;
}

const HeaderActions = ({
  eventsFavoriteResource: {
    items: favoriteEventsList,
    fetched: isFetched,
    fetching: isFetching,
  },
  eventsFavoriteStatsResource: { eventFavoriteTab },
  setEventClassificationRank,
  event,
  userId,
  saveEventClassificationRank,
  saveEventFavorite,
  deleteEventFavorite,
  setEventFavorite,
  resetEventFavorite,
  user,
  isTrainingPage,
  recommendation: {
    items: [data],
  },
  language,
  appInfo: { withoutHeader, params },
}: Props) => {
  const sliderData = getSliderData(data ?? [], true);
  const { events } = sliderData;
  const isExpired = isEventPast(event);
  const isLive = isEventLive(event);
  const [addedToMyList, setAddedToMyList] = useState(false);
  const [isEventIncludedInFavorites, setEventIncludedInFavorites] = useState(
    favoriteEventsList.filter((e) => e.id === event.id).length > 0,
  );
  const isShown = addedToMyList || isEventIncludedInFavorites;
  const isAdmin = user?.isUaAdmin;
  const currentLink = window.location.href;
  const isUpcomming = isEventUpcoming(event);
  const indexOfWatch = currentLink.indexOf('watch');
  const isWatchForTest = !withoutHeader;
  const uaRedirectLink =
    currentLink.substring(0, indexOfWatch + 5) +
    '?params=' +
    encodeURIComponent(params);
  const liveLink = event.slotReplayUrls?.webinarUrlEn;
  const icsFileUrl = getEventIcsUrl(event.id, userId ?? 0, language);
  const { isMobile } = useResponsive();

  const addToMyListAction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (userId) {
      const isSavedSuccessfully = await saveEventFavorite(event.id, userId);
      if (isSavedSuccessfully.value) {
        setTimeout(() => setEventFavorite(false, true, eventFavoriteTab), 200);
        setAddedToMyList(true);
      }
    } else {
      onError('', 'add_to_list_when_offline');
    }
  };

  const removeFromMyListAction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (userId) {
      const isDeletedSuccessfully = await deleteEventFavorite(event.id, userId);
      if (isDeletedSuccessfully.value) {
        setTimeout(() => setEventFavorite(true, false, eventFavoriteTab), 200);
        setAddedToMyList(false);
        setEventIncludedInFavorites(false);
      }
    }
  };

  const handleWatchOnClick = (e: React.MouseEvent, link?: string | null) => {
    e.stopPropagation();

    if (link) {
      window.parent.postMessage(
        {
          event: 'OPEN_WEBINAR',
          message: link,
        },
        '*',
      );
    }
  };

  useEffect(() => {
    return () => {
      resetEventFavorite();
    };
  }, []);

  useEffect(() => {
    if (
      isFetched &&
      favoriteEventsList.filter((e) => e.id === event.id).length
    ) {
      setAddedToMyList(true);
    } else if (!isFetching) {
      setEventIncludedInFavorites(false);
      setAddedToMyList(false);
    }
  }, [isFetched, event]);

  const renderMainAction = () => {
    if (
      event.playProgress &&
      event.playProgress > 0 &&
      event.slotReplayUrls &&
      !isLive &&
      !isUpcomming
    ) {
      const replayLink = getSlotReplayUrl(event.slotReplayUrls, language);
      return (
        <Button
          className="m-r-xs"
          title={t('Resume')}
          startIcon={<ResumeIcon />}
          style={{ padding: '7.8px' }}
          onClick={(e) =>
            handleWatchOnClick(
              e,
              replayLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
          //to={replayLink}
          size={isMobile ? 'small' : 'medium'}
        />
      );
    }
    if (isLive) {
      return (
        <Button
          className="m-r-xs"
          title={t('Rejoin')}
          color="coralRed"
          startIcon={<OngoingIcon />}
          style={{ padding: '7.8px' }}
          disableIconStyle
          onClick={(e) =>
            handleWatchOnClick(
              e,
              liveLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
          //to={liveLink}
          size={isMobile ? 'small' : 'medium'}
        />
      );
    }

    if (isExpired && event.slotReplayUrls) {
      const replayLink = getSlotReplayUrl(event.slotReplayUrls, language);

      return (
        <Button
          className="m-r-xs"
          title={t('Play')}
          startIcon={<PlayIcon />}
          style={{ padding: '7.8px' }}
          //to={replayLink}
          size={isMobile ? 'small' : 'medium'}
          onClick={(e) =>
            handleWatchOnClick(
              e,
              replayLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
        />
      );
    }

    if (isUpcomming) {
      return (
        <Button
          className="m-r-xs"
          title={t('Rejoin')}
          color="blue"
          startIcon={<PlayIcon />}
          style={{ padding: '7.8px' }}
          disableIconStyle
          onClick={(e) =>
            handleWatchOnClick(
              e,
              liveLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
          size={isMobile ? 'small' : 'medium'}
        />
      );
    }

    return null;
  };

  const handleSaveRank = (event: Event, chosenRank: number) => {
    saveEventClassificationRank(event.id, chosenRank);
    setEventClassificationRank(
      event.eventClassificationRank,
      event.id,
      chosenRank,
    );
    // reset ranked elements from store
    resetEventFavorite();
    window.location.replace(isWatchForTest ? currentLink : uaRedirectLink);
  };
  return (
    <div className="flex-container align-middle ">
      {renderMainAction()}
      <Button
        className="m-r-xs"
        title={t('my List')}
        variant={isShown ? 'contained' : 'WatchGrey'}
        color={isShown ? 'blue' : 'slateGrey'}
        startIcon={
          isShown ? <CheckCircleIcon width={17} height={20} /> : <PlusIcon />
        }
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          isShown ? removeFromMyListAction(e) : addToMyListAction(e);
        }}
        size={isMobile ? 'small' : 'medium'}
      />
      {/*
      {!isUpcoming && (
        <IconButton icon={<PlusIcon />} variant="outlined" color="slateGrey" />
      )}
*/}
      {!isExpired && (
        <AddToCalendarBtn
          icsFileUrl={icsFileUrl}
          isMulti={true}
          isWatch={true}
          size={isMobile ? 1 : 2}
          optionsPosition={isTrainingPage ? 'topRight' : undefined}
        />
      )}

      {isAdmin && !isMobile && (
        <div>
          <Formik
            initialValues={{
              eventRank: undefined,
            }}
            validationSchema={Yup.object().shape({
              eventRank: yup
                .string()
                .label('position')
                .required(t('yup.required')),
            })}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              handleSaveRank(event, values.eventRank ?? 0);
            }}
          >
            {({ handleSubmit, values }: any) => (
              <Form onSubmit={handleSubmit}>
                <div className={s.form}>
                  <div>
                    <label>
                      <span style={{ color: '#FFFFFF' }}>
                        {t('positioning')}
                      </span>
                    </label>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <TTPSelectField
                        name="eventRank"
                        placeholder={event.eventClassificationRank ?? undefined}
                        options={getRankedEventsOptions(events, event, 10)}
                        menuPortalTarget={
                          isTrainingPage ? document.querySelector('body') : ''
                        }
                      />
                    </div>
                  </div>
                  <div className={s.button}>
                    <Button
                      className="m-r-xs"
                      title={t('Save')}
                      variant={'contained'}
                      color={'blue'}
                      type={'submit'}
                      disabled={values.eventRank == undefined}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default HeaderActions;
