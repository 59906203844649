export interface EventPlan {
  accessibleDays: string; // Ex: "24/11/2020,25/11/2020,26/11/2020,27/11/2020"
  descriptionEn: string;
  descriptionFr: string;
  descriptionNl: string;
  event: string;
  id: number;
  maxNumber: string;
  memberLevel: string;
  memberPrice: string;
  nameEn: string;
  nameFr: string;
  nameNl: string;
  nonMemberPrice: string;
  notForProfessionnals_21: null;
  notForStudents: null;
  notForTrainees: null;
  number: string;
  options: {
    event: string;
    id: string;
    number: string;
    option: string;
    plan: string;
    type: PLAN_OPTION_TYPE;
  }[];
  position: string;
  price_list: [];
  recommended: string;
  status: PLAN_STATUS;
  trainingHours: string;
  displayedTrainingMinutes: string;
  uid: string;
  updatedAt: string;
  isBadgeAllowed: boolean;
  textDescription: string;
  buttonDescription: string;
}

export enum PLAN_STATUS {
  DEFAULT = 0,
  INACTIVE = 1,
  ACTIVE = 2,
  SOLD_OUT = 3,
}

export enum PLAN_OPTION_TYPE {
  INCLUDED = 1,
  ADDITIONAL = 2,
}
