import React from 'react';
import s from '../StepsSummary.module.scss';
import cn from 'classnames';
import t from 'i18n';
import { formatAccessibleDays, formatDecimalHours } from 'utils';
import { StepTitle } from 'components/Tools/Summary/StepTitle';
import { Price } from 'components/ui/PriceLabel/PriceLabel';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import { isEmpty } from 'lodash';
import { Help } from 'components/ui/Help/Help';
import Markdown from 'markdown-to-jsx';
import { SummaryBlock } from 'components/Tools/SummaryBlocks/SummaryBlock';

interface Props {
  planData: {
    name: string;
    description?: string;
    accessibleDays: string;
    trainingHours?: number | string | null;
    displayedTrainingMinutes?: number | string | null;
    price: number;
    originalPrice?: number;
  };
  hideEdit?: boolean;
  className?: string;
  onEdit?: () => void;
}

export const PlansSummary = ({
  planData: {
    name: planName,
    price,
    description,
    originalPrice,
    accessibleDays,
    trainingHours,
    displayedTrainingMinutes,
  },
  className,
  hideEdit,
  onEdit,
}: Props) => {
  const renderTrainingHours = () => {
    if (
      displayedTrainingMinutes &&
      displayedTrainingMinutes >= 0 &&
      displayedTrainingMinutes != ''
    ) {
      const displayedTrainingHours = +displayedTrainingMinutes / 60;
      return `${formatDecimalHours(displayedTrainingHours)} ${t(
        'of training',
      )}`;
    }

    if (trainingHours && trainingHours >= 0 && trainingHours != '') {
      return `${trainingHours}${t('hour_abr')} ${t('of training')}`;
    }

    return ' ';
  };

  const renderPriceLayout = () => {
    return (
      <div>
        {!!originalPrice && price !== originalPrice && (
          <Price
            size={'medium'}
            price={originalPrice}
            background={'#FFDAE0'}
            className={cn(s.price_off, 'm-r-xs')}
            style={{ padding: '0.25rem 0.75rem' }}
          />
        )}
        <Price
          size={'medium'}
          price={price}
          background={'#F1F2F4'}
          style={{ padding: '0.25rem 0.75rem' }}
        />
      </div>
    );
  };

  return (
    <div className={cn('m-b-s', className)}>
      <SummaryBlock title={t('Plans choice')} />
      <div className="flex-container align-justify align-top m-b-xs">
        <div className="flex-container align-middle">
          <span className={cn(s.big_text, s.uppercase)}>{planName}</span>
          {!isEmpty(description) && description && (
            <Help
              className="m-l-xs"
              title={planName}
              contentStyle={{ width: '263px' }}
              align={{ offset: [0, -32] }}
              placement="leftTop"
            >
              <Markdown className="markdown">{description}</Markdown>
            </Help>
          )}
        </div>
        <div className="text-right">{renderPriceLayout()}</div>
      </div>
      <div className="flex-container align-justify align-top">
        <span className={cn(s.text)}>
          {formatAccessibleDays(accessibleDays)}
          <br />
          {renderTrainingHours()}
        </span>
        {!hideEdit && (
          <button className={cn(s.circle_btn)} onClick={onEdit}>
            <EditIcon fill="#6D7F92" width="12px" />
          </button>
        )}
      </div>
    </div>
  );
};

export default PlansSummary;
