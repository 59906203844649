import React, { memo } from 'react';
import { History } from 'history';
import _ from 'i18n';
import classNames from 'classnames';
import styles from './GoBackHeader.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { renderGoBackIcon } from './services';
import { GoBackData } from 'store/Params/GoBack';

interface Props extends RouteComponentProps {
  history: History;
  innerRef?: React.Ref<any>;
  data: GoBackData;
}

const GoBackHeader = memo(
  ({
    history,
    innerRef,
    data: {
      title,
      goBackText,
      isFixed,
      goBackPath,
      callBack,
      icon = 'arrow-left',
      hideBackButton = false,
      renderContent,
      renderActions,
      theme = '',
      style,
    },
  }: Props) => {
    const goBack = goBackPath ? () => history.push(goBackPath) : history.goBack;

    const handleClick = () => {
      if (callBack) {
        callBack();
      }
      /** Show sub menu, when it's hidden */
      document
        .querySelector('#sub-menu')
        ?.classList.remove('visibility-hidden');
      goBack();
    };

    return (
      <div
        id="goBackHeader"
        ref={innerRef}
        className={classNames(
          styles.goBackHeader,
          isFixed && styles.isFixed,
          styles[theme],
        )}
        style={{ ...style, zIndex: 222 }}
      >
        {!hideBackButton && (
          <button className={styles.goBackButton} onClick={handleClick}>
            {renderGoBackIcon(icon)}
            <span className="m-l-xs">{goBackText ?? _('Go back')}</span>
          </button>
        )}
        <div className={styles.title}>
          {renderContent ? renderContent() : title}
        </div>
        {renderActions?.()}
      </div>
    );
  },
);

export default withRouter(GoBackHeader);
