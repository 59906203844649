import React, { memo } from 'react';
import styles from './Switcher.module.scss';
import classNames from 'classnames';
import { LayoutTheme } from 'components/Layout/services';

interface Props {
  checked: boolean;
  disabled?: boolean;
  label?: string;
  theme?: LayoutTheme;
  onChange: (checked: boolean) => void;
}

export default memo(function Switcher({
  checked,
  label,
  disabled,
  theme = '',
  onChange,
}: Props) {
  return (
    <label
      className={classNames(
        styles.switcher,
        disabled && styles.disabled,
        styles[theme],
      )}
    >
      <input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      {label && <span>{label}</span>}
      <span className={styles.checkMark} />
    </label>
  );
});
