import React, { memo } from 'react';
import styles from './EditButton.module.scss';
import cn from 'classnames';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';

interface Props {
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default memo(function EditButton({ className, onClick }: Props) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick?.();
  };

  return (
    <button className={cn(styles.editButton, className)} onClick={handleClick}>
      <PenIcon width="11" height="11" fill="#6D7F92" />
    </button>
  );
});
