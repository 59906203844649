import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './EventQuickDescription.module.scss';
import {
  Book,
  CONFIG_BAG_BLOCK_TYPE,
  ConfigBag,
  Event,
} from 'store/Events/types';
import { Language } from 'store/types';
import {
  bindWebinarLink,
  filterSpeakersForSlots,
  getByLanguage,
  parseJson,
  prepareS3ResourceUrl,
  prepareS3Url,
  sortSlotByDays,
} from 'utils';
import Markdown from 'markdown-to-jsx';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { URLS } from 'router';
import { Speaker } from 'store/Speakers/types';
import { OECCBB_ORGANIZATION_ID, S3_FOLDER_URL_PROD } from 'config';
import { isEmpty } from 'lodash';
import { Slot } from 'store/Slots/types';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import _ from 'i18n';
import Session from 'components/Webinar/Sessions/Session';
import { Session as SessionLayout5 } from 'components/Webinar/Sessions/Session/SessionLayout_5';
import Avatar from 'components/ui/Avatar';
import PartnersBanner from 'components/ui/PartnersBanner';
import { useOrganization } from 'hooks/useOrganization';
import { openModal } from 'store/Modal/actions';

export interface Props {
  isEventPreview?: boolean;
  event: Event;
  language: Language;
  speakers: Speaker[];
  speakersFetching?: boolean;
  slots: Slot[];
  slotsFetching?: boolean;
  showSubscribe?: boolean;
  onSubscribe?: () => void;
}

interface BookState {
  [key: number]: boolean;
}

export default memo(function EventQuickDescription({
  isEventPreview,
  event,
  language,
  speakers,
  slots,
  speakersFetching,
  slotsFetching,
  showSubscribe = true,
  onSubscribe,
}: Props) {
  const dispatch = useDispatch();
  const [bookBackView, setBookBackView] = useState<BookState>({});
  const history = useHistory();
  const community = useOrganization();
  const description = getByLanguage(event, 'description', language) ?? '';

  const handleSubscribe = () => {
    onSubscribe?.();

    history.push(
      bindWebinarLink(
        URLS.webinar.reception.registration.root,
        String(event.id),
      ),
    );
  };

  const handleSpeakerModal = (speaker: Speaker, hasCV: boolean) => {
    if (hasCV) {
      dispatch(openModal('SPEAKER', { data: speaker }));
    }
  };

  const renderSpeakers = () => {
    if (speakersFetching) {
      return [1, 2, 3].map((key) => (
        <div
          key={key}
          className={classNames(
            styles.speaker,
            styles.fetching,
            'cell small-4',
          )}
        >
          <div
            className={classNames(
              styles.avatar,
              'bg-image avatar empty-avatar',
            )}
          />
          <div className={styles.infos}>
            <h3 />
            <h5 />
          </div>
        </div>
      ));
    }

    const filteredSpeakers = filterSpeakersForSlots(speakers);
    return filteredSpeakers.map((speaker) => {
      const { firstName, lastName, pictureUrl } = speaker;
      let headline = getByLanguage(speaker, 'headline', language);
      if (typeof headline === 'object') {
        headline = headline.title;
      } else if (typeof parseJson(headline) === 'object') {
        headline = parseJson(headline).title;
      }
      const cv = getByLanguage(speaker, 'cv', language);
      const helpHeadLine =
        !isEmpty(headline) && headline.length > 54
          ? `${headline.slice(0, 50)}...`
          : headline;

      return (
        <div
          key={`speaker-${speaker.id}`}
          className={classNames(
            styles.speaker,
            isEventPreview ? 'cell small-6 medium-2' : 'cell small-3',
          )}
          onClick={() => handleSpeakerModal(speaker, !isEmpty(cv))}
          style={{ cursor: isEmpty(cv) ? 'default' : 'pointer' }}
        >
          <Avatar
            className={classNames(styles.avatar, 'm-r-xs')}
            url={prepareS3ResourceUrl(S3_FOLDER_URL_PROD, pictureUrl)}
            firstName={firstName}
            lastName={lastName}
            width="50px"
            height="50px"
            fontSize="1.25rem"
          />
          <div className={styles.infos}>
            <h3>{`${firstName} ${lastName}`.trim()}</h3>
            <h5>{helpHeadLine}</h5>
          </div>
        </div>
      );
    });
  };

  const renderSlots = () => {
    if (slotsFetching) {
      return (
        <div>
          <hr className="separator m-x-0" />
          <div className={styles.block}>
            <h3>{_('Program')}:</h3>
            <div className={classNames(styles.blockSlots)}>
              <div className={styles.header}>
                <CalendarIcon
                  className="m-r-xs"
                  width="14"
                  height="14"
                  fill={'#29394d'}
                />
                <span>--</span>
              </div>
              {[1, 2, 3].map((key) => {
                return (
                  <div key={key} className="m-b-s">
                    <SessionLayout5.Fetching />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    if (slots.length < 2) {
      return null;
    }

    const slotBlocks = sortSlotByDays(slots, language, 'LL');

    if (slotBlocks.length === 0) {
      return null;
    }

    return (
      <div>
        <hr className="separator m-x-0" />
        <div className={styles.block}>
          <h3>{_('Program')}:</h3>
          {slotBlocks.map((slotBlock) => (
            <div className={styles.blockSlots} key={slotBlock.label}>
              <div className={styles.header}>
                <CalendarIcon
                  className="m-r-xs"
                  width="14"
                  height="14"
                  fill={'#6D7F92'}
                />
                <span>{slotBlock.label}</span>
              </div>
              {slotBlock.items.map((slot) => {
                return (
                  <div key={slot.id} className="m-b-s">
                    <Session session={slot} layout="layout5" event={event} />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderBookImage = (book: Book) => {
    const pictureUrl = getByLanguage(book, 'pictureUrl', language);
    const backPictureUrl = getByLanguage(book, 'picture2Url', language);
    const isBackView = bookBackView[book.id];

    return (
      <div className="book-images">
        <div className={classNames('book', isBackView && 'back-view')}>
          <img
            width="324"
            height="458"
            src={prepareS3Url(pictureUrl)}
            className="attachment-shop_single size-shop_single wp-post-image"
            alt=""
            style={{ visibility: 'hidden' }}
          />
          <div className="book__page book__page--front">
            <img
              width="324"
              height="458"
              src={prepareS3Url(pictureUrl)}
              className="attachment-shop_single size-shop_single wp-post-image"
              alt=""
            />
          </div>
          <div className="book__page book__page--back">
            <img
              width="324"
              height="458"
              src={prepareS3Url(backPictureUrl)}
              alt="Couverture arrière"
            />
          </div>
          <div className="book__page book__page--first-page" />
          <div className="book__page book__page--second-page" />
          <div className="book__page book__page--side" />
          <div className="book__page book__page--side-paper" />
        </div>
        <div className="book__action">
          <button
            className="see-back"
            onClick={() =>
              setBookBackView({ ...bookBackView, [book.id]: !isBackView })
            }
          >
            <i className="icon icon-reload m-r-xs" />
            <span>{_('Flip')}</span>
          </button>
        </div>
      </div>
    );
  };

  const renderBlocks = () => {
    const configBag: ConfigBag | undefined = parseJson(event.configBag);
    const books = configBag?.blocks?.filter?.(
      (block) => block.type === CONFIG_BAG_BLOCK_TYPE.BOOK,
    );

    if (books) {
      return (
        <div>
          {books.map((book) => {
            const content = getByLanguage(book, 'content', language);
            const pictureUrl = getByLanguage(book, 'pictureUrl', language);
            const ctaUrl = getByLanguage(book, 'ctaUrl', language);
            const ctaTitle = getByLanguage(book, 'ctaTitle', language);
            const title = getByLanguage(book, 'title', language);
            const showCTA = !isEmpty(ctaUrl);
            const mainBlockSize = !isEmpty(pictureUrl) ? 8 : 12;
            const rightBlockSize = 12 - mainBlockSize;

            return (
              <div key={book.id}>
                <hr className="separator m-x-0" />
                <div className={classNames('grid-x', styles.book)}>
                  <div className={`p-xs cell small-${mainBlockSize}`}>
                    <h3>{title}</h3>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{ __html: content ?? '' }}
                      />
                    </div>
                    {showCTA && (
                      <div className="m-t-s">
                        <a
                          className={styles.action}
                          href={ctaUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {ctaTitle ?? _('Learn more')}
                        </a>
                      </div>
                    )}
                  </div>
                  {rightBlockSize !== 0 && (
                    <div className={`p-xs cell small-${rightBlockSize}`}>
                      {renderBookImage(book)}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const showSlotDescription = slots.length === 1;
  const session = slots[0];
  const isFFF = community && community.id === 9;

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={classNames(
            'markdown',
            styles.description,
            // +event.id === 138 && styles.blueH,
          )}
        >
          {showSubscribe && (
            <button onClick={handleSubscribe} className={styles.register}>
              {_('Register for training')}
            </button>
          )}
          <Markdown>{description}</Markdown>
          {showSlotDescription && session && (
            <Markdown>
              {getByLanguage(session, 'description', language) ?? ''}
            </Markdown>
          )}
        </div>
        <div className={classNames(styles.speakers, 'grid-x grid-padding-x')}>
          {renderSpeakers()}
        </div>
        {renderSlots()}
        {renderBlocks()}
        {isFFF && event.client !== OECCBB_ORGANIZATION_ID && (
          <div>
            <hr className="separator m-x-0" />
            <PartnersBanner layoutSize="M" className="p-y-m" />
          </div>
        )}
      </div>
    </>
  );
});
