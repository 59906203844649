import React, {
  Fragment,
  memo,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Routes } from 'router';
import {
  login,
  loginApp,
  loginWithToken,
  LoginParams,
  fetchCommunity,
  UaParams,
} from 'store/Auth/thunks';
import {
  selectNavCommunity,
  selectRouting,
  selectUserCommunities,
  selectUser,
  selectUserPremiumStatus,
  selectUserPremiumShared,
} from 'store/Auth';
import ScrollToTop from 'Common/ScrollToTop';
import Layout from 'components/Base/Layout';
import SelectCommunity from 'components/Base/SelectCommunity';
import { Language, Organization, RootState } from 'store/types';
import { AuthState } from 'store/Auth/types';
import { RouterState } from 'connected-react-router';
import TTPLoader from 'Common/TTPLoader';
import { useQuery } from 'hooks/useQuery';
import { isEmpty } from 'lodash';
import setLanguageFilter from 'store/SubMenu/actions';
import { LanguageFilterType } from 'store/SubMenu/types';
import { ConfigProvider } from 'antd';
import localeEn from 'antd/lib/locale/en_US';
import localeFr from 'antd/lib/locale/fr_BE';
import localeNl from 'antd/lib/locale/nl_BE';
import {
  getLanguage,
  prepareAppInfo,
  isUserPremium,
  isUserPremiumPending,
  decodeQueryParams,
  filterAutologinQuery,
  CYCLE_PREMIUM_ID,
} from 'utils';
import { useHistory } from 'react-router-dom';
import { Guest, PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';
import { setUserPremiumStatus, setUserPremiumShared } from 'store/Auth/actions';
import { fetchPremiumGuest, fetchUserPremiumStatus } from 'store/Guests/thunks';
import { setNavCommunity } from './store/Auth/actions';
import { changeNavCommunity } from './store/Auth/thunks';
import { FFF_ID } from './config';
import { setAppInfo } from 'store/Params/actions';
import { AppInfoData } from 'store/Params/AppInfo';
import { selectAppInfo } from 'store/Params/selectors';
import { useInterval } from 'hooks/useInterval';
import useRecursiveTimeout from 'hooks/useRecursiveTimeout';
import { PREMIUM_STATUS_FETCH_DELAY } from 'config';
import { SEO } from 'Base/SEO/SEO';
import { URL_HASH_KEY } from 'config';
import { ResourceState } from 'store/Resource/types';
import { Cycle } from 'store/Cycle/types';
import { setLanguage } from 'store/Params/Language/actions';

const ANTD_LOCALES: { [key in Language]: any } = {
  fr: localeFr,
  en: localeEn,
  nl: localeNl,
};

export interface Props {
  communities: Array<Organization>;
  navCommunity?: Organization | null;
  auth: AuthState;
  userId?: number;
  routing: RouterState;
  language: Language;
  premiumGuest: Guest | null;
  appInfo: AppInfoData;
  premiumGuestStatus: number;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
  premiumOffersResource: ResourceState<Cycle>;
  isPremiumGuestFetched: boolean;
  isPremiumGuestFetching: boolean;
  login: (params?: LoginParams, uaParams?: UaParams) => void;
  loginWithToken: (token: string, params?: LoginParams) => any;
  loginApp: () => void;
  setLanguageFilter: (filter: LanguageFilterType) => void;
  fetchPremiumGuest: (userId: string | number, cycleId: number) => any;
  fetchUserPremiumStatus: (
    cycleId: string | number,
    userId: string | number,
  ) => any;
  setUserPremiumStatus: (
    userPremiumStatus: PREMIUM_REGISTRATION_STATUS,
  ) => void;
  setUserPremiumShared: (userPremiumShared: boolean) => void;
  setAppInfo: (appInfo: AppInfoData) => void;
  setNavCommunity: (community: Organization) => void;
  changeNavCommunity: (communityId: number) => void;
  fetchCommunity: (communityId: string | number) => any;
  setLanguage: (language: Language) => void;
}

export const App = memo(
  ({
    routing,
    auth,
    userId,
    premiumGuest,
    language,
    communities,
    navCommunity,
    appInfo,
    premiumGuestStatus,
    userPremiumStatus,
    premiumOffersResource: { items: premiumOffers },
    isPremiumGuestFetched,
    isPremiumGuestFetching,
    loginWithToken,
    login,
    loginApp,
    setLanguageFilter,
    fetchPremiumGuest,
    setUserPremiumStatus,
    setUserPremiumShared,
    setAppInfo,
    setNavCommunity,
    changeNavCommunity,
    fetchCommunity,
    fetchUserPremiumStatus,
    setLanguage,
  }: Props) => {
    const [delay, setDelay] = useState<number | null>(null);
    const [isFFFOrgFetched, setIsFFFOrgFetched] = useState<boolean>(false);
    const query = useQuery();
    const history = useHistory();
    const params = query.get('params');
    const keyValuePairs = params && params.split(',');
    const tokenPair = keyValuePairs
      ? keyValuePairs.find((pair) => pair.includes('token='))
      : null;
    const isNextApp = keyValuePairs
      ? keyValuePairs.find((pair) => pair.includes('isNextApp='))
      : null;
    const languageFromNextApp = keyValuePairs
      ? keyValuePairs.find((pair) => pair.includes('language='))?.split('=')[1]
      : null;
    const tokenFromNextApp = tokenPair ? tokenPair.split('=')[1] : null;
    const token = query.get('token') || tokenFromNextApp;
    const coupon = query.get('coupon') ?? '';
    const eventId = query.get('eventId');
    const oneCoupon = filterAutologinQuery(useQuery(true)).get('onecoupon');
    const tool = query.get('tool');
    const communityId = query.get('communityId');
    const refreshUser = query.get('refreshUser');
    const lng = query.get('lng');
    const scope = query.get('scope');
    const isOfffcourse = appInfo.id === 'OFFFCOURSE';
    const isFFF = navCommunity?.id === FFF_ID;
    const gotoUrlParams = filterAutologinQuery(query).toString();
    const premiumOfferId = premiumOffers[0]?.id ?? 0;

    useLayoutEffect(() => {
      const currentAppInfo = prepareAppInfo(window.location.hostname);
      if (currentAppInfo) {
        setAppInfo(currentAppInfo);
      }
    }, []);

    useRecursiveTimeout(async () => {
      if (userId && premiumGuest?.cycleId) {
        await fetchUserPremiumStatus(premiumGuest?.cycleId, userId);
      }
    }, delay);

    useEffect(() => {
      if (languageFromNextApp) {
        setLanguage(getLanguage(languageFromNextApp) ?? 'fr');
      }
    }, [languageFromNextApp]);

    useEffect(() => {
      const params: LoginParams = {
        communityId,
        language: getLanguage(lng),
        refreshUser: refreshUser == '1',
        scope: scope ?? '',
      };

      if (token && !isEmpty(token)) {
        loginWithToken(token, params);
        query.delete('token');

        if (coupon && !isEmpty(coupon) && tool && !isEmpty(tool)) {
          history.replace({
            search: `?tool=${tool}&coupon=${coupon}`,
          });
        } else if (coupon && !isEmpty(coupon)) {
          history.replace({
            search: `?coupon=${coupon}`,
          });
        } else if (oneCoupon && !isEmpty(oneCoupon)) {
          history.replace({
            search: `?oneCoupon=${oneCoupon}`,
          });
        } else if (eventId && !isEmpty(eventId)) {
          history.replace({
            search: `?eventId=${eventId}`,
          });
        } else {
          history.replace({
            search: gotoUrlParams,
          });
        }
      } else {
        if (query.get('params') && !isNextApp) {
          const uaParams = decodeQueryParams(
            query.get('params') as string,
            URL_HASH_KEY,
          );

          if (uaParams.withoutHeader) {
            setAppInfo({
              ...appInfo,
              withoutHeader: true,
              params: query.get('params') as string,
            });
          }
          login(params, uaParams);
        } else {
          login(params);
        }
      }

      if (isNextApp) {
        setAppInfo({
          ...appInfo,
          isNextApp: true,
        });
      }
      loginApp();
    }, []);

    useEffect(() => {
      setLanguageFilter({
        fr: false,
        nl: false,
        en: false,
        [language]: true,
      });
    }, [language]);

    useEffect(() => {
      if (userId && !isPremiumGuestFetched && !isPremiumGuestFetching) {
        fetchPremiumGuest(userId, CYCLE_PREMIUM_ID);
      }
    }, [userId, isPremiumGuestFetched, isPremiumGuestFetching]);

    useEffect(() => {
      if (userId && premiumGuest?.cycleId) {
        fetchUserPremiumStatus(premiumGuest?.cycleId, userId);
      }
    }, [userId, premiumGuest, fetchUserPremiumStatus]);

    useEffect(() => {
      if (premiumGuest) {
        const { premiumRegistrationStatus, isPremiumShared } = premiumGuest;
        if (premiumRegistrationStatus !== userPremiumStatus) {
          setUserPremiumStatus(
            premiumRegistrationStatus ?? PREMIUM_REGISTRATION_STATUS.NONE,
          );
        }
        if (isPremiumShared) {
          setUserPremiumShared(isPremiumShared);
        } else {
          setUserPremiumShared(false);
        }
      }
    }, [premiumGuest, setUserPremiumStatus]);

    useEffect(() => {
      if (auth && isOfffcourse && !isFFF && !isFFFOrgFetched) {
        fetchCommunity(FFF_ID).then((orgs: Organization[]) => {
          const fffOrganization = orgs[0];
          if (fffOrganization) {
            setIsFFFOrgFetched(true);
            setNavCommunity(fffOrganization);
            changeNavCommunity(fffOrganization.id);
          }
        });
      }
    }, [auth.user, isFFF, isOfffcourse, setNavCommunity, changeNavCommunity]);

    useEffect(() => {
      const premiumStatus = premiumGuestStatus ?? userPremiumStatus;
      setDelay(
        isUserPremiumPending(premiumStatus) ? PREMIUM_STATUS_FETCH_DELAY : null,
      );
      if (isUserPremium(premiumStatus)) {
        setUserPremiumStatus(premiumStatus);
      }
    }, [userPremiumStatus, premiumGuestStatus, setUserPremiumStatus]);

    if (auth.fetching || auth.appTokenFetching) {
      return <TTPLoader cssClass={'fixed-loader'} />;
    }

    // if (!navCommunity && auth && auth.user && communities?.length > 0) {
    //   return <SelectCommunity />;
    // }

    return (
      <Fragment>
        <SEO />
        <ScrollToTop location={routing.location}>
          <ConfigProvider locale={ANTD_LOCALES[language]}>
            <Layout>
              <Routes />
            </Layout>
          </ConfigProvider>
        </ScrollToTop>
      </Fragment>
    );
  },
);

const mapStateToProps = (state: RootState) => ({
  navCommunity: selectNavCommunity(state),
  communities: selectUserCommunities(state),
  auth: state.auth,
  userId: selectUser(state)?.id,
  routing: selectRouting(state),
  language: state.params.lng,
  premiumGuest: state.event.guests.premiumGuest,
  appInfo: selectAppInfo(state),
  premiumGuestStatus: state.event.guests.premiumGuestStatus,
  userPremiumStatus: selectUserPremiumStatus(state),
  userPremiumShared: selectUserPremiumShared(state),
  premiumOffersResource: state.cycle.premiumOffers,
  isPremiumGuestFetched: state.event.guests.premiumGuestFetched,
  isPremiumGuestFetching: state.event.guests.premiumGuestFetching,
});

const mapDispatchToProps = {
  login,
  loginApp,
  loginWithToken,
  setLanguageFilter,
  fetchPremiumGuest,
  setUserPremiumStatus,
  setUserPremiumShared,
  setAppInfo,
  setNavCommunity,
  changeNavCommunity,
  fetchCommunity,
  fetchUserPremiumStatus,
  setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
