import React from 'react';

import styles from '../StepCollaboratorsData.module.scss';
import { Tabs } from 'antd';
import _ from 'i18n';
import { ReactComponent as FileText } from 'assets/icons/file-text.svg';

const { TabPane } = Tabs;

interface Props {
  uploadResult: any;
  fileName: string;
  fileSize: number;
}

interface IState {}

class UploadResult extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const data = this.props.uploadResult;
    return (
      <div className={styles.upload_result}>
        <div className={styles.upload_result_content}>
          <FileText width="12" height="12" />
          <span className={styles.file_name}>{this.props.fileName}</span>
          <span style={{ color: '#6D7F92' }}>({this.props.fileSize} Ko)</span>
          <div className={styles.numbers}>
            <span>{data ? data?.new : 0} nouveaux</span>
            <span>{data ? data?.updated : 0} modifications</span>
            <span>{data ? data?.rejected : 0} contacts non importable</span>
          </div>
        </div>
      </div>
    );
  }
}
export default UploadResult;
