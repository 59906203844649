import React, { useEffect, useState } from 'react';
import s from './PackRegistration.module.scss';
import t from 'i18n';
import Onboarding from 'components/Common/Onboarding';
import OnboardingPage from 'components/Common/Onboarding/OnboardingPage';
import { REGISTRATION_PACK_STEPS } from './services';
import StepOrganizationData from './Steps/StepOrganizationData';
import StepCollaboratorsData from './Steps/StepCollaboratorsData';
import StepPlaceAttribution from './Steps/StepPlaceAttribution';
import StepPack from './Steps/StepPack';
import StepPayment from './Steps/StepPayment';
import { Organization } from 'interfaces/Organization';
import { Language } from 'store/types';
import { PackOrder } from 'interfaces/PackOrder';
import { User } from 'store/Auth/types';
import { Event } from 'store/Events/types';
import { useQuery } from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

interface Props {
  event: Event;
  user: User;
  language: Language;
  packSummary: any[];
}

export const PackRegistration = ({ user, event, packSummary }: Props) => {
  const [tab, setTab] = useState<number>(0);
  const [organizationParamId, setOrganizationParamId] = useState<string | null>(
    null,
  );
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const [packOrder, setPackOrder] = useState<PackOrder>();
  const queryParams = useQuery();
  const history = useHistory();
  const withStepOrganizationData = isEmpty(packSummary[0]?.payment?.invoices);

  useEffect(() => {
    if (!withStepOrganizationData) {
      setSelectedOrg(packSummary[0]?.organizationInfos?.folder);
    }
  }, []);

  useEffect(() => {
    if (queryParams.has('organization')) {
      const organizationId = queryParams.get('organization');
      setOrganizationParamId(organizationId);
    }
    if (queryParams.has('openStep')) {
      const openStep = queryParams.get('openStep');
      switch (openStep) {
        case 'attributionStep':
          setTab(4);
          break;
        case 'packsStep':
          setTab(0);
          break;
        default:
          setTab(0);
          break;
      }
    }

    queryParams.delete('organization');
    queryParams.delete('openStep');
    history.replace({
      search: queryParams.toString(),
    });
  }, [queryParams]);

  return (
    <Onboarding
      selectedTab={tab}
      title={t('Organization registration')}
      className="flex-1"
      theme="orange"
      allowDisable={true}
    >
      <OnboardingPage
        tab={{
          id: REGISTRATION_PACK_STEPS.PACK,
          title: t('PACK CHOICE'),
        }}
      >
        <StepPack
          user={user}
          event={event}
          selectedOrg={selectedOrg}
          goToStep={setTab}
          setOutputData={setPackOrder}
        />
      </OnboardingPage>
      {withStepOrganizationData && (
        <OnboardingPage
          tab={{
            id: REGISTRATION_PACK_STEPS.ORGANIZATION,
            title: t('Organization data'),
          }}
          style={{ overflowY: 'auto' }}
        >
          <StepOrganizationData
            packOrder={packOrder ?? null} // TODO
            goToStep={setTab}
            setOutputData={(org: Organization, packOrder: PackOrder) => {
              setSelectedOrg(org);
              setPackOrder(packOrder);
            }}
            event={event}
          />
        </OnboardingPage>
      )}
      <OnboardingPage
        className={s.page}
        tab={{
          id: REGISTRATION_PACK_STEPS.PAYMENT,
          title: t('PAIEMENT'),
        }}
      >
        <StepPayment
          user={user}
          event={event}
          packOrder={packOrder ?? null}
          goToStep={setTab}
          previousStep={
            withStepOrganizationData
              ? REGISTRATION_PACK_STEPS.ORGANIZATION
              : REGISTRATION_PACK_STEPS.PACK
          }
        />
      </OnboardingPage>
      <OnboardingPage
        tab={{
          id: REGISTRATION_PACK_STEPS.COLLABORATORS,
          title: t('collaborators'),
        }}
      >
        <StepCollaboratorsData
          goToStep={setTab}
          organizationName={selectedOrg ? selectedOrg.name : ''}
          organizationId={
            organizationParamId
              ? parseInt(organizationParamId)
              : selectedOrg
              ? selectedOrg.id
              : 0
          }
        />
      </OnboardingPage>

      <OnboardingPage
        tab={{
          id: REGISTRATION_PACK_STEPS.PLACE_ATTRIBUTIONS,
          title: t('PlacesAttribution'),
        }}
        className={s.page}
      >
        <StepPlaceAttribution
          organizationName={selectedOrg ? selectedOrg.name : ''}
          organisationId={
            organizationParamId
              ? parseInt(organizationParamId)
              : selectedOrg
              ? selectedOrg.id
              : 0
          }
          goToStep={setTab}
        />
      </OnboardingPage>
    </Onboarding>
  );
};

export default PackRegistration;
