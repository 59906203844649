import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { createReducer } from './createReducer';
import { createEnhancer } from './createEnhancer';
import { History } from 'history';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  whitelist: ['auth', 'params'],
  storage,
};

const configureStore = (history: History, initialState = {}) => {
  const enhancer = createEnhancer(history);

  const rootReducer = createReducer(history);

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(persistedReducer, initialState, enhancer);

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};

export default configureStore;
