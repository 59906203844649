import React, { memo } from 'react';
import styles from './LocalLoader.module.scss';
import ClipLoader from 'react-spinners/ClipLoader';
import cn from 'classnames';

export const LocalLoaderWrapper = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div className={cn(styles.wrapper, className)} style={style}>
    {children}
  </div>
);

interface Props {
  loading: boolean;
  className?: string;
  style?: React.CSSProperties;
  counter?: number;
}
export default memo(function LocalLoader({
  loading,
  className,
  style,
  counter,
}: Props) {
  return loading ? (
    <div className={cn(styles.loading, className)} style={style}>
      <ClipLoader size={30} color="#2495E1" loading={loading} />
      {counter != undefined ? (
        <div className={styles.counter}>{counter} %</div>
      ) : null}
    </div>
  ) : null;
});
