import React from 'react';
import s from './StepsControl.module.scss';
import cn from 'classnames';
interface Props {
  children: React.ReactNode;
  className?: string;
}

const StepsControlWrapper = ({ children, className }: Props) => {
  return <div className={cn(s.actions, className)}>{children}</div>;
};

export default StepsControlWrapper;
