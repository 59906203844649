import { RootState } from 'store/types';
import { connect } from 'react-redux';
import { fetchPremiumOffers } from 'store/Cycle/thunks';
import PremiumHero from './PremiumHero';
import { selectLanguage } from 'store/Auth';

const mapStateToProps = (state: RootState) => ({
  premiumOffersResource: state.cycle.premiumOffers,
  language: selectLanguage(state),
});

export default connect(mapStateToProps, {
  fetchPremiumOffers,
})(PremiumHero);
