import {
  channelInfoActionTypes,
  RESET_CHANNEL_INFO,
  SET_CHANNEL_INFO,
} from './types';

export const resetChannelInfo = (): channelInfoActionTypes => ({
  type: RESET_CHANNEL_INFO,
});

export const setChannelInfo = (
  isAuthorsSet: boolean,
): channelInfoActionTypes => ({
  type: SET_CHANNEL_INFO,
  isAuthorsSet,
});

export default {
  resetChannelInfo,
  setChannelInfo,
};
