import { SetAppInfoAction, SET_APP_INFO, AppInfoData } from './types';

const initialState: AppInfoData = {
  id: null,
  name: '',
  url: '',
  logo: { url: '' },
  domain: '',
  withoutHeader: false,
  params: '',
};

const reducer = (
  state: AppInfoData = initialState,
  action: SetAppInfoAction,
) => {
  switch (action.type) {
    case SET_APP_INFO: {
      return action.appInfo;
    }
    default:
      return state;
  }
};

export default reducer;
