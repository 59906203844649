import {
  OPEN_MODAL,
  ModalActionTypes,
  CLOSE_MODAL,
  ModalIdType,
} from './types';

export const closeModal = (): ModalActionTypes => ({
  type: CLOSE_MODAL,
});

export interface OpenModalParams<T> {
  data: T;
  onClose?: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const openModal = <T = any>(
  modalId: ModalIdType,
  params: OpenModalParams<T>,
): ModalActionTypes => ({
  type: OPEN_MODAL,
  modalId,
  ...params,
});

export default {
  closeModal,
  openModal,
};
