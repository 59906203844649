import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRecourceList } from 'store/Resource/api';
import { FetchParams } from 'store/Resource/types';
import { RootState } from 'store/types';

interface Params extends FetchParams {
  isInfinite?: boolean;
}

export const useFetchResource = <T = any>(
  url: string,
  initialValue: T,
  { isInfinite, ...params }: Params = {},
  dependencies?: ReadonlyArray<any>,
  isFetchingSuspended?: boolean,
): [boolean, T, number, any, () => void] => {
  const [data, setData] = useState<T>(initialValue);
  const [nbResult, setNbResult] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const appToken = useSelector((state: RootState) => state.auth.appToken.token);

  const reset = useCallback(() => {
    setData(initialValue);
    setNbResult(0);
    setError(null);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isFetchingSuspended) {
      setLoading(true);
      getRecourceList({
        requestUrl: url,
        token: !isEmpty(token) ? token : appToken,
        ...params,
      })
        .then((res) => {
          const { data: fetchedData, nbResult } = res.data;
          const newData = fetchedData ?? initialValue;

          if (Array.isArray(data)) {
            setData(isInfinite ? [...data, ...newData] : newData);
          } else {
            setData(newData);
          }

          setNbResult(nbResult);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, dependencies);

  return [loading, data, nbResult, error, reset];
};
