import React, { useEffect, useMemo, useState } from 'react';
import s from './StepPlan.module.scss';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as RectangleIcon } from 'assets/icons/rectangle.svg';
import cn from 'classnames';
import { Event, EventPlanAndOptions } from 'store/Events/types';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsControl from '../../../Common/StepsControl';
import StepsContent from '../../../Common/StepsContent';
import Coupon from './Coupon';
import {
  Coupon as CouponType,
  COUPON_REDUCTION_TYPE,
  Guest,
} from 'store/Guests/types';
import { Language } from 'store/types';
import {
  formatAccessibleDays,
  formatDecimalHours,
  getByLanguage,
  getEventPlanPrice,
  getPrivacyTermsUrl,
  getSelectedCoupons,
  onError,
  parseJson,
} from 'utils';
import { GuestData, REGISTRATION_FULL_STEPS, StepProps } from '../../services';
import { CouponInputOption } from 'components/Registration/Coupon/CouponInput/CouponInput';
import { EventPlan, PLAN_OPTION_TYPE, PLAN_STATUS } from 'interfaces/EventPlan';
import { Help } from 'components/ui/Help/Help';
import { isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';
import TTPCheckBox from 'components/Common/TTPForm/TTPCheckBox';
import { Step2Data } from 'store/Guests/api';
import { User } from 'store/Auth/types';
import { URLS } from 'router';
import LocalLoader, {
  LocalLoaderWrapper,
} from 'components/Common/LocalLoader/LocalLoader';
import { CURRENT_CONGRESS_ID } from 'config';
import { useDispatch } from 'react-redux';
import { setTTPDialogCustomData } from 'store/Params/actions';
import { DIALOG_TYPES } from 'store/Params/TTPDialog';

interface Props extends StepProps {
  event: Event;
  language: Language;
  guest: Guest;
  coupons: CouponType[];
  plansAndOptions: EventPlanAndOptions | null;
  allowedCoupons: COUPON_REDUCTION_TYPE[];
  data: GuestData;
  user: User;
  couponToSelect?: string | null;
  handleChangeData: (data: Partial<GuestData>) => void;
  goToStep: (step: REGISTRATION_FULL_STEPS) => void;
  saveStep2: (eventId: string, userId: string, data: Partial<Step2Data>) => any;
  closeModal: () => void;
}

export const StepPlan = ({
  event,
  language,
  guest,
  coupons,
  plansAndOptions,
  data,
  allowedCoupons,
  nextStep,
  previousStep,
  user,
  couponToSelect,
  handleChangeData,
  goToStep,
  saveStep2,
  closeModal,
}: Props) => {
  const [selectedCouponCodes, setSelectedCouponCodes] = useState<string[]>([]);
  const {
    plan: selectedPlanId,
    coupons: couponCodes = [],
    options = [],
  } = data;

  useEffect(() => {
    const defaultCoupons = coupons.filter(
      ({ code }) => code === couponToSelect,
    );

    setSelectedCouponCodes(
      !isEmpty(defaultCoupons)
        ? defaultCoupons.map(({ code }) => code)
        : couponCodes,
    );
  }, [coupons]);

  const { plans = [], includedOptions = [], options: allOptions } =
    plansAndOptions ?? {};

  const dispatch = useDispatch();

  const selectedCoupons = useMemo(() => {
    return getSelectedCoupons(selectedCouponCodes, coupons);
  }, [coupons, selectedCouponCodes]);

  const [termsOfSales, setTermsOfSales] = useState(+data.termsOfSales === 1);
  const [termsError, setTermsError] = useState(false);
  const [privacyTerms, setPrivacyTerms] = useState(false);
  const [privacyTermsError, setPrivacyTermsError] = useState(false);
  const showPrivacyTerms = event?.id == CURRENT_CONGRESS_ID;
  const [saving, setSaving] = useState(false);

  const handleChosePlan = (e: React.MouseEvent<HTMLElement>) => {
    const planId = e.currentTarget.getAttribute('data-plan');

    if (planId && +planId > 0) {
      handleChangeData({ plan: +planId });
    }
  };

  const handleChangeCoupons = (coupons: CouponInputOption[]) => {
    const newCouponCodes = coupons.map(({ value }) => value);
    setSelectedCouponCodes(newCouponCodes);
    handleChangeData({ coupons: newCouponCodes });
  };

  const afterApplyCoupon = (coupon: CouponType) => {
    const autoSelectedPlans = coupon.reductions
      .filter(
        (rd) => rd.type === COUPON_REDUCTION_TYPE.PLAN && +rd.autoselect === 1,
      )
      .map((rd) => +rd.id);

    const autoSelectedOptions = coupon.reductions
      .filter(
        (rd) =>
          rd.type === COUPON_REDUCTION_TYPE.OPTION &&
          +rd.autoselect === 1 &&
          allOptions?.some((op) => +op.id === +rd.id && +op.status === 2), // check option status ??
      )
      .map((rd) => +rd.id);

    const couponSelectedPlan = autoSelectedPlans.filter((planId) =>
      plans.find(
        ({ id, status }) =>
          planId !== selectedPlanId &&
          planId === +id &&
          +status === PLAN_STATUS.ACTIVE,
      ),
    );

    let newData: any = undefined;

    if (couponSelectedPlan[0]) {
      newData = { ...newData, plan: couponSelectedPlan[0] };
    }

    if (autoSelectedOptions.length > 0) {
      const newOptions = autoSelectedOptions.filter(
        (op) => options.indexOf(op) === -1,
      );

      if (newOptions.length > 0) {
        newData = { ...newData, options: [...options, ...newOptions] };
      }
    }

    if (newData) {
      handleChangeData(newData);
    }
  };

  const handleSubmit = (): void | Promise<any> => {
    const plan = plans.find(({ id }) => id == selectedPlanId);

    if (!termsOfSales || (!privacyTerms && showPrivacyTerms) || !plan) {
      setTermsError(!termsOfSales);
      setPrivacyTermsError(!privacyTerms && showPrivacyTerms);
      return;
    }

    const newData: Partial<Step2Data> = {
      plan: selectedPlanId,
      options: '',
      days: plan.accessibleDays?.split(','),
      coupon: selectedCoupons[0]?.code ?? '',
      termsOfSales: termsOfSales ? 1 : 0,
    };

    setSaving(true);
    return saveStep2(String(event.id), String(user.id), {
      ...newData,
      cycle: 0,
    })
      .then((res: any) => {
        const paymentUrl = res?.value?.data?.paymentUrl;
        setSaving(false);
        if (!isEmpty(paymentUrl) && res?.value?.data?.step == 4) {
          goToStep(REGISTRATION_FULL_STEPS.PAYMENT);
        } else {
          const data: Guest | undefined = res.value.data;
          if (!data || +data.step !== 5) {
            onError();
          } else {
            // Nothing to be paid ...
            closeModal();
          }
        }
      })
      .catch(() => {
        setSaving(false);
        onError();
      });
  };

  // --------- RENDER -------- //
  const renderTrainingHours = (plan: EventPlan) => {
    if ([undefined, null, ''].indexOf(plan.displayedTrainingMinutes) !== -1) {
      return ' ';
    }
    const displayedTrainingHours = +plan.displayedTrainingMinutes / 60;
    return `${formatDecimalHours(displayedTrainingHours)} ${t('of training')}`;
  };

  const renderPlans = () => {
    return plans.map((plan) => {
      const isSelected = selectedPlanId === +plan.id;
      const name = getByLanguage(plan, 'name', language);
      const description = getByLanguage(plan, 'description', language);
      const { price, originalPrice } = getEventPlanPrice(
        plan,
        selectedCoupons,
        guest,
      );
      const priceHelper =
        price > 0 || originalPrice > 0 ? `${price} €` : t('Free');
      const isPlanActive = +plan.status === PLAN_STATUS.ACTIVE;
      const isPlanSoldOut = +plan.status === PLAN_STATUS.SOLD_OUT;

      return (
        <th
          key={plan.id}
          className={s.cell}
          data-plan={plan.id}
          onClick={isPlanActive ? handleChosePlan : undefined}
          style={{ position: 'relative' }}
        >
          <div
            className={cn(s.cwrapper, s.header, isSelected && s.selected)}
            style={{ opacity: !isPlanActive ? 0.5 : 1 }}
          >
            <div
              className={s.date}
              style={{ opacity: !isPlanActive ? 0.5 : 1 }}
            >
              {formatAccessibleDays(plan.accessibleDays)}
            </div>
            <div className={s.infos}>
              <h3 className={cn('m-b-xxs', s.plan_name)}>{name}</h3>
              <h5 style={{ minHeight: '19px' }}>
                {renderTrainingHours(plan)}
                {!isEmpty(description) && (
                  <Help
                    className="m-l-xs"
                    title={name}
                    contentStyle={{ width: '263px' }}
                    align={{ offset: [0, -32] }}
                    placement="leftTop"
                  >
                    <Markdown className="markdown">{description}</Markdown>
                  </Help>
                )}
              </h5>
              <div className="text-center p-y-xs">
                {price !== originalPrice && (
                  <span className={cn(s.price, s.red, s.barred)}>
                    {originalPrice} €
                  </span>
                )}
                <span className={s.price}>{priceHelper}</span>
              </div>
              <div className="text-center">
                <span className={cn(s.radioButton, isSelected && s.active)} />
              </div>
            </div>
            <div className={s.ctriangle}></div>
          </div>
          {isPlanSoldOut && (
            <div className={s.sold_out_wrapper}>
              <div className={cn(s.sold_out)}>{t('sold out')}</div>
            </div>
          )}
        </th>
      );
    });
  };

  const renderPlanOptions = () => {
    const rows: any = [];

    includedOptions.forEach((option) => {
      const name = getByLanguage(option, 'name', language);
      const description = getByLanguage(option, 'description', language);

      rows.push(
        <tr key={option.id}>
          <td className="text-right">
            <div className="flex-container align-middle align-right">
              <div className="greetings">{name}</div>
              {!isEmpty(description) && (
                <Help
                  className="m-l-xs"
                  title={name}
                  contentStyle={{ width: '263px' }}
                  align={{ offset: [0, -32] }}
                  placement="leftTop"
                >
                  <Markdown className="markdown">{description}</Markdown>
                </Help>
              )}
            </div>
          </td>
          {plans.map((plan) => {
            const planOption = plan.options.find(
              (o) => +o.option === +option.id,
            );
            const number = +(planOption?.number ?? -1);
            const isPlanActive = +plan.status === PLAN_STATUS.ACTIVE;
            const isAdditional =
              planOption?.type == PLAN_OPTION_TYPE.ADDITIONAL;

            const renderOptionIcon = () => {
              if (isAdditional) {
                return (
                  <RectangleIcon width="11px" height="11px" fill="#FFB340" />
                );
              }

              if (number > 0) {
                return number;
              }

              if (number === 0) {
                return (
                  <CheckMarkIcon width="10px" height="10px" fill="#02AF8E" />
                );
              }

              return <CrossIcon width="10px" height="10px" fill="#FE3745" />;
            };

            return (
              <td
                key={plan.id}
                className={cn(s.cell, s.extraOptionsWrapper, 'text-center')}
                style={{
                  opacity: !isPlanActive ? 0.5 : 1,
                }}
                data-plan={plan.id}
                onClick={isPlanActive ? handleChosePlan : undefined}
              >
                <div
                  className={cn(
                    s.cwrapper,
                    'align-center-middle flex-container',
                    selectedPlanId === +plan.id && s.selected,
                  )}
                >
                  {renderOptionIcon()}
                </div>
              </td>
            );
          })}
        </tr>,
      );
    });

    return rows;
  };

  const handleNextStepAction = () => {
    const plan = plans.find(({ id }) => id == selectedPlanId);
    const textDescription = parseJson(plan?.textDescription);
    const buttonDescription = parseJson(plan?.buttonDescription);

    if (
      plan?.textDescription &&
      plan.buttonDescription &&
      nextStep === REGISTRATION_FULL_STEPS.OPTIONS
    ) {
      dispatch(
        setTTPDialogCustomData({
          approvedAction: () => goToStep(nextStep),
          message:
            language == 'fr'
              ? textDescription?.Fr
              : language == 'nl'
              ? textDescription?.Nl
              : 'Attention, seuls les étudiants réels sont autorisés à prendre cette option',
          title: t('confirmation'),
          type: DIALOG_TYPES.CONFIRMATION,
          proceedBtnLabel:
            language == 'fr'
              ? buttonDescription?.Fr
              : language == 'nl'
              ? buttonDescription?.Nl
              : 'Je confrime être étudiant',
        }),
      );
    } else {
      goToStep(nextStep);
    }
  };

  const renderNextStepAction = () => {
    if (nextStep !== REGISTRATION_FULL_STEPS.END) {
      return (
        <NextStep
          className="m-l-auto"
          text={t('Next step')}
          disabled={+selectedPlanId <= 0}
          onClick={handleNextStepAction}
        />
      );
    }

    const privacyTermsLink = getPrivacyTermsUrl(language);

    return (
      <div
        className="flex-container m-l-auto align-middle"
        style={{ fontWeight: 'normal' }}
      >
        {showPrivacyTerms && (
          <div className="flex-container flex-dir-column">
            <TTPCheckBox
              className="m-r-s"
              label={
                <span className={s.acceptConditions}>
                  {t('inscription.privacy_terms_p1')}&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={privacyTermsLink}
                    style={{
                      color: '#18A0FB',
                      textDecoration: 'underline',
                    }}
                  >
                    {t('inscription.privacy_terms_p2')}
                  </a>
                </span>
              }
              checked={privacyTerms}
              onChange={() => {
                setPrivacyTermsError(privacyTerms);
                setPrivacyTerms(!privacyTerms);
              }}
            />
            {privacyTermsError && (
              <div className="yup-error">
                <p className="text-center">
                  {t('You must accept the privacy terms')}
                </p>
              </div>
            )}
          </div>
        )}
        <div className="flex-container flex-dir-column">
          <TTPCheckBox
            className="m-r-s"
            label={
              <span className={s.acceptConditions}>
                {t('inscription.terms_p1')}&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={URLS.terms.event}
                  style={{
                    color: '#18A0FB',
                    textDecoration: 'underline',
                  }}
                >
                  {t('inscription.terms_p2')}
                </a>
              </span>
            }
            checked={termsOfSales}
            onChange={() => {
              setTermsError(termsOfSales);
              setTermsOfSales(!termsOfSales);
            }}
          />
          {termsError && (
            <div className="yup-error">
              <p className="text-center">
                {t('You must accept the terms and conditions')}
              </p>
            </div>
          )}
        </div>
        <NextStep
          className="m-l-auto"
          text={t('Finish')}
          disabled={+selectedPlanId <= 0}
          onClick={handleSubmit}
        />
      </div>
    );
  };

  return (
    <LocalLoaderWrapper className={'flex-container flex-1'}>
      <StepsContent>
        <Coupon
          event={event}
          guest={guest}
          isMember={+(guest.member ?? -1) > 0}
          coupons={coupons}
          allowedCoupons={allowedCoupons}
          selected={selectedCouponCodes}
          language={language}
          onChange={handleChangeCoupons}
          afterApply={afterApplyCoupon}
          selectedPlanId={selectedPlanId}
        />
        <div className={cn(s.content, 'm-b-xl')}>
          <h3 className="m-l-s m-b-m">
            {t('Select the formula that suits you')} :
          </h3>
          <div className={s.planList}>
            <table>
              <thead>
                <tr>
                  <th className={s.cellTitle} />
                  {renderPlans()}
                </tr>
              </thead>
              <tbody>{renderPlanOptions()}</tbody>
            </table>
          </div>
        </div>
        <StepsControl className={s.actions}>
          <div className={cn('p-s p-x-l flex-container')}>
            <GoBack onClick={() => goToStep(previousStep)} className="m-r-s" />
            {renderNextStepAction()}
          </div>
        </StepsControl>
      </StepsContent>
      <LocalLoader loading={saving} style={{ zIndex: 100 }} />
    </LocalLoaderWrapper>
  );
};

export default StepPlan;
