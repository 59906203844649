import { Order } from 'store/Guests/types';

export interface OrderState {
  fetching: boolean;
  fetched: boolean;
  saving: boolean;
  saved: boolean;
  items: Order[];
  error: any;
  nbResult: number;
}

export const RESET_ORDERS = 'RESET_ORDERS';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_PENDING = 'CANCEL_ORDER_PENDING';
export const CANCEL_ORDER_FULFILLED = 'CANCEL_ORDER_FULFILLED';
export const CANCEL_ORDER_REJECTED = 'CANCEL_ORDER_REJECTED';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_PENDING = 'FETCH_ORDERS_PENDING';
export const FETCH_ORDERS_FULFILLED = 'FETCH_ORDERS_FULFILLED';
export const FETCH_ORDERS_REJECTED = 'FETCH_ORDERS_REJECTED';

export interface ResetOrdersAction {
  type: typeof RESET_ORDERS;
}

export interface CancelOrderAction {
  type:
    | typeof CANCEL_ORDER
    | typeof CANCEL_ORDER_PENDING
    | typeof CANCEL_ORDER_FULFILLED
    | typeof CANCEL_ORDER_REJECTED;
  payload: any;
}

export interface FetchOrdersAction {
  type:
    | typeof FETCH_ORDERS
    | typeof FETCH_ORDERS_PENDING
    | typeof FETCH_ORDERS_FULFILLED
    | typeof FETCH_ORDERS_REJECTED;
  payload: any;
}

export type OrderActionTypes =
  | ResetOrdersAction
  | FetchOrdersAction
  | CancelOrderAction;
