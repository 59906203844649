import React from 'react';
import { connect } from 'react-redux';
import { User } from 'store/Auth/types';
import { Event } from 'store/Events/types';
import { fetchGuestData } from 'store/Guests/thunks';
import { Guest } from 'store/Guests/types';
import { RootState } from 'store/types';
import RegistrationStepsHandler, {
  RegistrationStepsHandlerProps,
} from './RegistrationStepsHandler';

interface Props
  extends Omit<RegistrationStepsHandlerProps, 'guest' | 'user' | 'event'> {
  guest?: Guest;
  user?: User | null;
  event?: Event;
}

export const RegistrationStepsHandlerWrap = (props: Props) => {
  if (!props.guest || !props.user || !props.event) {
    return null;
  }

  return (
    <RegistrationStepsHandler
      {...props}
      guest={props.guest}
      user={props.user}
      event={props.event}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  language: state.params.lng,
  coupons: state.event.guests.coupons,
  checkedCoupon: state.event.guests.checkedCoupon,
  plansAndOptions: state.event.plansAndOptions.data,
  user: state.auth.user,
  guest: state.event.guests.items[0],
  event: state.event.event.items[0],
});

export default connect(mapStateToProps, { fetchGuestData })(
  RegistrationStepsHandlerWrap,
);
