import React from 'react';
import cn from 'classnames';
import s from './PartnersBanner.module.scss';
import t from 'i18n';
import { getWrapperPadding, PARTNERS, PartnerType } from './services';
import {
  appendUrlScheme,
  getByLanguage,
  getCroppedImageUrl,
  prepareS3ResourceUrl,
} from 'utils';
import { useLanguage } from 'hooks/useLanguage';
import { S3_FOLDER_URL } from 'config';
import { Link } from 'react-router-dom';

interface Props {
  layoutSize: 'M' | 'L';
  showTitle?: boolean;
  className?: string;
  partners?: PartnerType[];
}

const PartnersBanner = ({
  layoutSize,
  showTitle = true,
  className,
  partners = PARTNERS,
}: Props) => {
  const language = useLanguage();
  const styleRatio = layoutSize === 'L' ? 1 : 0.65;
  const isTitleUpper = layoutSize === 'L';
  const { ceil } = Math;

  const renderPartnerLogo = (partner: PartnerType) => {
    const pictureUrl = getByLanguage(partner, 'pictureUrl', language);
    const imageUrl = partner.isLocalData
      ? pictureUrl
      : prepareS3ResourceUrl(
          S3_FOLDER_URL,
          getCroppedImageUrl(pictureUrl, undefined, 200),
        );
    const url = getByLanguage(partner, 'url', language);
    const padding = getWrapperPadding(layoutSize, partners.length);

    const content = (
      <div
        className={s.logoWrapper}
        style={{
          height: ceil(140 * styleRatio),
          padding,
        }}
      >
        <span
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      </div>
    );

    if (partner.to) {
      return <Link to={partner.to}>{content}</Link>;
    }

    return (
      <a href={appendUrlScheme(url)} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    );
  };

  return (
    <div className={cn('grid-container p-t-xl', className)}>
      {showTitle && (
        <div
          className={cn(s.title, layoutSize === 'L' ? 'm-b-l' : 'm-b-m')}
          style={{
            fontSize: ceil(24 * styleRatio),
            textTransform: isTitleUpper ? 'uppercase' : 'none',
          }}
        >
          <h1>
            {t('partnersBannerTitle')} {!isTitleUpper && ':'}
          </h1>
        </div>
      )}
      <div className={s.partnersWrapper}>
        {partners.map((partner) => (
          <div key={partner.id}>{renderPartnerLogo(partner)}</div>
        ))}
      </div>
    </div>
  );
};

export default PartnersBanner;
