import React from 'react';
import s from './Onboarding.module.scss';
import toArray from 'rc-util/lib/Children/toArray';
import TabePane, { Tab, TabPaneProps } from './OnboardingPage';
import { LayoutTheme } from 'components/Layout/services';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import cn from 'classnames';

function parseTabList(children: React.ReactNode): Tab[] {
  const result = toArray(children)
    .map((node: React.ReactElement<TabPaneProps>) => {
      if (React.isValidElement(node)) {
        const tab = node.props.tab;
        return tab;
      }

      return null;
    })
    .filter((tab) => tab);

  return result as Tab[];
}

interface Props {
  theme?: LayoutTheme;
  title?: string;
  className?: string;
  classNameTabs?: string;
  allowDisable?: boolean;
  children: React.ReactNode;
  selectedTab: string | number;
}

export default function Onboarding({
  children,
  title,
  theme = '',
  className,
  classNameTabs,
  selectedTab,
  allowDisable,
}: Props) {
  const tabs = parseTabList(children);

  const renderTabs = toArray(children)
    .map((node: React.ReactElement<TabPaneProps>) => {
      if (node.type !== TabePane) {
        return null;
      }

      if (selectedTab !== node.props?.tab?.id) {
        return null;
      }

      return node;
    })
    .filter((node) => node);

  const selectedTabIndex = tabs.findIndex((tab) => tab.id === selectedTab);

  return (
    <div className={cn(s.onboarding, className)}>
      <div className={cn(s.header, 'align-justify')}>
        {title && <div className={s.title}>{title}</div>}
        <ul className={cn(s.navs, s[theme], classNameTabs, 'm-r-xl')}>
          {tabs.map((tab, index) => {
            const isActive = index === selectedTabIndex;
            const isDone = selectedTabIndex > index;
            const isDisabled =
              tabs.length == 5
                ? selectedTabIndex > 2 &&
                  (index === 0 || index === 1 || index === 2)
                : selectedTabIndex > 1 && (index === 0 || index === 1);
            return (
              <li
                key={`${tab.title}-${index}`}
                onClick={() => tab.onClick?.(tab.id)}
                className={cn(
                  isActive && s.activeNav,
                  isDone && s.done,
                  allowDisable && isDisabled && s.disabled,
                )}
                style={!tab.onClick ? { cursor: 'default' } : undefined}
              >
                {tab.title}
                <span className={s.circle}>
                  {isDone && (
                    <CheckMarkIcon width="10" height="10" fill="#FFF" />
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      {renderTabs}
    </div>
  );
}
