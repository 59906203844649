import React from 'react';
import s from './ProgressBar.module.scss';
import cn from 'classnames';

interface Props {
  progress: number;
  renderInfos?: () => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  width?: React.ReactText;
  height?: React.ReactText;
  color?: string;
  showProgressNumber?: boolean;
  isEventWatch?: boolean;
}

export const ProgressBar = ({
  progress,
  style,
  className,
  width = '190px',
  height = '6px',
  color = '#06d9b1',
  renderInfos,
  showProgressNumber = true,
  isEventWatch,
}: Props) => {
  const _progress = progress < 0 || progress > 100 ? 0 : progress;

  return (
    <div
      className={cn(s.progressWrapper, className)}
      style={{ ...style, width, height }}
    >
      <div
        className={isEventWatch ? s.watchProgressBar : s.progressBar}
        style={{ width: `${_progress}%`, color, background: color, height }}
      />
      {showProgressNumber && (
        <div className={s.label} style={{ left: `${_progress}%`, color }}>
          {`${progress}%`}
        </div>
      )}
      {renderInfos && (
        <div className={s.infos} style={{ left: `${_progress}%` }}>
          {renderInfos()}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
