import {
  OptionTypeBase,
  Props as ReactSelectProps,
  StylesConfig,
  ValueType,
} from 'react-select';
import React, { CSSProperties } from 'react';
import { components } from 'react-select';
import { ReactComponent as PolygonIcon } from 'assets/icons/polygon.svg';
import _ from 'i18n';
import styles from './TTPSelect.module.scss';
import { LayoutTheme } from 'components/Layout/services';

interface OptionType extends OptionTypeBase {
  label: string;
  value: string;
}

export const findSelectedValue = (
  value: any,
  options?: ReactSelectProps['options'],
): ValueType<OptionType> => {
  if (!options || !value || value === '' || value === []) {
    return null;
  }

  if (Array.isArray(value)) {
    const selectedOptions: any = [];
    for (const v of value) {
      const selectedValue = findSelectedValue(v, options);
      if (selectedValue) {
        selectedOptions.push(selectedValue);
      }
    }

    return selectedOptions;
  }

  for (const op of options) {
    const option: any = op;

    if (!option.options && option.value && option.value === value) {
      return option;
    }

    if (option?.options && Array.isArray(option?.options)) {
      const find = findSelectedValue(value, option.options);
      if (find) {
        return find;
      }
    }
  }

  return null;
};

/*
 * React select styles
 */

const textStyle = (): CSSProperties => ({
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  textTransform: 'uppercase',
});

export const getSelectStyles = (
  theme: LayoutTheme,
  customTextStyle?: CSSProperties,
  customInputStyle?: CSSProperties,
): StylesConfig => {
  const themeColor =
    theme === 'orange'
      ? '#f47f4d'
      : theme === 'redOrange'
      ? '#29394d'
      : theme === 'orangeRoyal'
      ? '#f7953e'
      : theme === 'purple'
      ? '#6F6CEC'
      : theme === 'greenTeal'
      ? '#14B7AD'
      : '#18A0FB';

  return {
    control: (styles: CSSProperties, { isFocused, hasValue }) => ({
      ...styles,
      minHeight: '34px',
      cursor: 'pointer',
      backgroundColor: '#F8F9FA',
      borderRadius: '4px',
      borderColor: hasValue || isFocused ? themeColor : '#B2BCC6',
      boxShadow: 'none',
      ...textStyle(),
      ...customTextStyle,
      ':hover': {
        ...(styles as any)?.[':hover'],
        borderColor: themeColor,
      },
    }),
    valueContainer: (styles: CSSProperties, { hasValue }: any) => ({
      ...styles,
    }),
    singleValue: (styles: CSSProperties, { hasValue }: any) => ({
      ...styles,
      color: '#29394D',
    }),
    clearIndicator: (styles: CSSProperties, { hasValue }: any) => ({
      ...styles,
      color: '#29394D',
      ':hover': {
        color: '#29394D',
      },
    }),
    indicatorSeparator: (styles: CSSProperties, { hasValue }: any) => ({
      ...styles,
      backgroundColor: '#B2BCC6',
      ':hover': {
        backgroundColor: '#B2BCC6',
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      transition: 'transform .3s ease-in-out',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      zIndex: 200,
      backgroundColor: '#FFFFFF',
      border: '1px solid #C7E8FE',
      boxShadow: '0px 2px 8px rgba(41, 57, 77, 0.3)',
      borderRadius: '4px',
    }),
    option: (styles: CSSProperties, { isDisabled, isSelected, isFocused }) => ({
      ...styles,
      ...textStyle(),
      ...customTextStyle,
      cursor: 'pointer',
      color: isDisabled ? undefined : '#29394D',
      backgroundColor: isDisabled
        ? undefined
        : isSelected || isFocused
        ? '#F1F2F4'
        : '#FFFFFF',
      ':hover': {
        ...(styles as any)?.[':hover'],
        backgroundColor: isDisabled ? undefined : '#F1F2F4',
      },
    }),
    placeholder: (styles: CSSProperties) => ({
      ...styles,
      color: '#6D7F92',
    }),
    input: (styles: CSSProperties) => ({
      ...styles,
      caretColor: '#FFF',
      ...textStyle(),
      ...customTextStyle,
      color: '#FFF',
      ...customInputStyle,
    }),
    noOptionsMessage: (styles: CSSProperties) => ({
      ...styles,
      ...textStyle(),
      ...customTextStyle,
    }),
    loadingMessage: (styles: CSSProperties) => ({
      ...styles,
      ...textStyle(),
      ...customTextStyle,
    }),
  };
};

export const getAsyncSelectStyles = (
  theme: LayoutTheme,
  customTextStyle?: CSSProperties,
  customInputStyle?: CSSProperties,
): StylesConfig => {
  const { input, ...selectStyles } = getSelectStyles(
    theme,
    customTextStyle,
    customInputStyle,
  );

  return {
    ...selectStyles,
    dropdownIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' }),
    input: () => ({
      ...input,
      caretColor: '#29394D',
      color: '#29394D',
    }),
  };
};

/*
 * React Select components
 */

export const Menu = (props: any) => {
  const { title } = props.selectProps;

  return (
    <components.Menu {...props}>
      {title && <div className={styles.menuHeader}>{title}</div>}
      {props.children}
    </components.Menu>
  );
};

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <PolygonIcon fill="#6D7F92" width="11" height="7" />
    </components.DropdownIndicator>
  );
};

export const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">{_('Nothing Found')}</span>
    </components.NoOptionsMessage>
  );
};

export const LoadingMessage = (props: any) => {
  return (
    <components.LoadingMessage {...props}>
      <span className="custom-css-class">{_('Loading ...')}</span>
    </components.LoadingMessage>
  );
};
