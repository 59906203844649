import React from 'react';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross.svg';

export const renderGoBackIcon = (icon: string) => {
  switch (icon) {
    case 'cross':
      return <IconCross width="11" height="11" fill="#29394D" />;
    default:
      return <IconArrowLeft />;
  }
};
