import React, { PureComponent } from 'react';

import styles from './FlashMessage.module.scss';
import classnames from 'classnames';
import _ from 'i18n';

export default class FlashMessageComponent extends PureComponent {
  render() {
    const { openFlash, message, status } = this.props;

    let statusIcon = 'icon-check';
    switch (status) {
      case 'ERROR':
        statusIcon = 'icon-close';
        break;
      case 'WARNING':
        statusIcon = 'icon-power';
        break;
      default:
    }

    return (
      <div
        className={classnames(
          styles.flashMessage,
          styles[status.toLowerCase()],
          openFlash ? styles.opened : '',
        )}
      >
        <i className={classnames('icon', statusIcon)} />
        <div className={styles.body}>
          <h5>{_('Success actions')}</h5>
          <p>{message}</p>
        </div>
      </div>
    );
  }
}

// Conn
