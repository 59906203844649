import {
  TTPDialogActionTypes,
  SET_TTP_DIALOG_SHOW,
  SET_TTP_DIALOG_CUSTOM_DATA,
} from './types';

export const setTTPDialogShow = (
  showDialog: boolean,
): TTPDialogActionTypes => ({
  type: SET_TTP_DIALOG_SHOW,
  showDialog,
});

export const setTTPDialogCustomData = (
  customData: any,
): TTPDialogActionTypes => ({
  type: SET_TTP_DIALOG_CUSTOM_DATA,
  customData,
});

export default { setTTPDialogShow, setTTPDialogCustomData };
