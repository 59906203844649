import { Dispatch } from 'redux';
import {
  RootState,
  CYCLE_RESOURCE,
  PREMIUM_OFFER_RESOURCE,
  SEASON_RESOURCE,
  ALL_CYCLES_RESOURCE,
  ESSENTIAL_RESOURCE,
} from 'store/types';
import { fetchResource } from 'store/Resource/thunks';
import { TTP_API_URL } from 'config';
import { SingleValueFilter } from 'services/Filter';
import { Cycle } from './types';
import { FetchParams } from 'store/Resource/types';
import {
  CYCLE_PREMIUM_ID,
  TYPE_CYCLE,
  TYPE_ESSENTIAL,
  TYPE_SEASON,
} from 'utils';

export const fetchAllCycles = (params: FetchParams) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const requestUrl = `${TTP_API_URL}/event/cycle`;

  return fetchResource<Cycle>({
    name: ALL_CYCLES_RESOURCE,
    requestUrl,
    nolimit: true,
    ...params,
    dispatch,
    getState,
  });
};

export const fetchCycles = (params: FetchParams) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const requestUrl = `${TTP_API_URL}/event/cycle`;
  const { filters = [], ...restParams } = params || {};

  return fetchResource<Cycle>({
    name: CYCLE_RESOURCE,
    requestUrl,
    nolimit: true,
    filters: [...filters, new SingleValueFilter('type', 'eq', TYPE_CYCLE)],
    ...restParams,
    dispatch,
    getState,
  });
};

export const fetchPremiumOffers = (params?: FetchParams) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const requestUrl = `${TTP_API_URL}/event/cycle`;
  const { filters = [], ...restParams } = params || {};

  return fetchResource<Cycle>({
    name: PREMIUM_OFFER_RESOURCE,
    requestUrl,
    nolimit: true,
    filters: [...filters, new SingleValueFilter('id', 'eq', CYCLE_PREMIUM_ID)],
    ...restParams,
    dispatch,
    getState,
    //fields: ['*', 'eventsAbstract', 'allEventsPrices', 'user-registered'],
  });
};

export const fetchSeasons = (params: FetchParams) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const requestUrl = `${TTP_API_URL}/event/cycle`;
  const { filters = [], ...restParams } = params || {};

  return fetchResource<Cycle>({
    name: SEASON_RESOURCE,
    requestUrl,
    nolimit: true,
    filters: [...filters, new SingleValueFilter('type', 'eq', TYPE_SEASON)],
    ...restParams,
    dispatch,
    getState,
  });
};

export const fetchEssentials = (params: FetchParams) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const requestUrl = `${TTP_API_URL}/event/cycle`;
  const { filters = [], ...restParams } = params || {};

  return fetchResource<Cycle>({
    name: ESSENTIAL_RESOURCE,
    requestUrl,
    nolimit: true,
    filters: [...filters, new SingleValueFilter('type', 'eq', TYPE_ESSENTIAL)],
    ...restParams,
    dispatch,
    getState,
  });
};

export default {
  fetchAllCycles,
  fetchCycles,
  fetchSeasons,
  fetchEssentials,
  fetchPremiumOffers,
};
