import {
  FETCH_RELATED_ARTICLES_FULFILLED,
  FETCH_RELATED_ARTICLES_PENDING,
  FETCH_RELATED_ARTICLES_REJECTED,
  RelatedArticlesTypes,
  RelatedArticlesState,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};

export const reducer = (
  state: RelatedArticlesState = initialState,
  action: RelatedArticlesTypes,
) => {
  switch (action.type) {
    case FETCH_RELATED_ARTICLES_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case FETCH_RELATED_ARTICLES_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: [...data[0]],
        nbResult: nbResult,
      };
    }
    case FETCH_RELATED_ARTICLES_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};
export default reducer;
