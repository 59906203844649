import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';

export const messages: any = { en, fr, nl };

const supportedLngs = ['fr', 'en', 'nl'];
export default function (id: any) {
  let lng = localStorage.getItem('lng');
  lng = lng == null || supportedLngs.indexOf(lng) === -1 ? 'fr' : lng;

  return messages[lng][id] && messages[lng][id].length !== 0
    ? messages[lng][id]
    : id;
}
