import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { URLS } from 'router';
import { TTP_HOME_URL, TTP_PORTAL_OFFF_COURSE_URL } from 'config';
import { bindCycleLink, bindWebinarLink, getAndPopQueryParam } from 'utils';
import { useQuery } from 'hooks/useQuery';
import { isEmpty } from 'lodash';

export const withAutologin = (WrappedAppComponent: React.ComponentType) => {
  return (appProps: any) => {
    const location = useLocation();
    const query = useQuery();

    const getGotoUrl = () => {
      const eventId = getAndPopQueryParam(query, 'eventId');
      const cycleId = getAndPopQueryParam(query, 'cycleId');
      const toRegistration = query.has('registration');
      query.delete('registration');
      const queryParams = encodeURIComponent(query.toString());

      if (!isEmpty(eventId)) {
        const webinarLink = toRegistration
          ? bindWebinarLink(
              URLS.webinar.reception.registration.root,
              eventId ?? 0,
            )
          : bindWebinarLink(URLS.webinar.home, eventId ?? 0);
        const baseLink = encodeURI(
          `${TTP_PORTAL_OFFF_COURSE_URL}${webinarLink}`,
        );

        return `${baseLink}?${queryParams}`;
      }

      if (!isEmpty(cycleId)) {
        const cycleLink = toRegistration
          ? bindCycleLink(URLS.cycle.reception.registration.root, cycleId ?? 0)
          : bindCycleLink(URLS.cycle.home, cycleId ?? 0);
        const baseLink = encodeURI(`${TTP_PORTAL_OFFF_COURSE_URL}${cycleLink}`);

        return `${baseLink}?${queryParams}`;
      }

      return `${encodeURI(TTP_PORTAL_OFFF_COURSE_URL)}?${queryParams}`;
    };

    const prepareHomeAutologUrl = () => {
      const idApi2 = getAndPopQueryParam(query, 'idApi2');
      const check = getAndPopQueryParam(query, 'check');
      const gotoUrl = getGotoUrl();

      const baseUrl = encodeURI(
        `${TTP_HOME_URL}/autoLog?idApi2=${idApi2}&check=${check}`,
      );

      return `${baseUrl}&gotoUrl=${gotoUrl}`;
    };

    return (
      <Switch>
        <Route path={URLS.autoLogin}>
          <Redirect
            to={
              location.pathname.slice(0, location.pathname.lastIndexOf('/')) +
              location.search
            }
          />
        </Route>
        <Route
          path={URLS.autoLog}
          render={() => {
            window.location.href = prepareHomeAutologUrl();
            return null;
          }}
        />
        <Route>
          <WrappedAppComponent {...appProps} />
        </Route>
      </Switch>
    );
  };
};
