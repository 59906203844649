import React, { useState } from 'react';
import styles from './TagsFilterTablet.module.scss';
import classNames from 'classnames';
import { getByLanguage } from 'utils';
import { useLanguage } from 'hooks/useLanguage';
import _ from 'i18n';
import cn from 'classnames';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-up.svg';
import useResponsive from 'hooks/useResponsive';
export interface Tab {
  id: string;
  title: string;
  isWide?: boolean;
  count?: string | number;
}

interface Props {
  periods: Tab[];
  tags: any[];
  selectedTags: string[];
  backgroundColor?: string;
  setTag: (tag: string, tagLabel: string) => void;
  selectPeriod: (period: string, periodLabel: string) => void;
  selectedPeriods?: string[];
}

export const TagsFilterTablet = ({
  tags,
  selectedTags,
  setTag,
  periods,
  selectPeriod,
  selectedPeriods,
}: Props) => {
  const [allTags, setAllTags] = useState<any>(tags);
  const [tagsCounter, setTagsCounter] = useState(4);
  const { isMobile } = useResponsive();
  const selectedPeriodsTags = selectedPeriods ?? selectedTags;

  const language = useLanguage();

  const showMoreTags = () => {
    setTagsCounter(tagsCounter + 10);
  };

  const getPeriodTags = () =>
    periods.map((period) => (
      <span
        key={period.id}
        className={classNames(
          styles.tag,
          selectedPeriodsTags.includes(period.id) && styles.activePeriod,
        )}
        onClick={() => selectPeriod(period.id, period.title)}
      >
        {period.title} ({period.count})
      </span>
    ));

  const getOtherTags = () =>
    allTags.map((tag: any, index: number) => {
      if (index < tagsCounter && index < 25) {
        return (
          <span
            key={tag.id}
            className={classNames(
              styles.tag,
              selectedTags.includes(tag.id) && styles.active,
            )}
            onClick={() => setTag(tag.id, getByLanguage(tag, 'name', language))}
          >
            {getByLanguage(tag, 'name', language)}
          </span>
        );
      }
    });

  return (
    <div>
      <div
        className={classNames(' filter-wrapper__tabs', styles.tagsNavigation)}
      >
        <div className={styles.period_tags}>{getPeriodTags()}</div>
        {getOtherTags()}
      </div>
      {allTags && tagsCounter < allTags.length && (
        <div className={styles.btn_container} style={{ marginLeft: '-0.5rem' }}>
          <div
            className={classNames(styles.btn_plus)}
            onClick={() => showMoreTags()}
          >
            <div>
              <ChevronIcon className={'rl180'} fill="#FFFFFF" width="10px" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TagsFilterTablet.fetching = () => (
  <div
    className={classNames(styles.tagsNavigation)}
    //style={{ width: `${165 * tabs.length}px`, height: '48px ' }}
  >
    {[1, 2, 3, 4, 5, 6, 7, 8].map((tag: any) => (
      <span className={styles.fetching} key={tag.id}></span>
    ))}
  </div>
);
export default TagsFilterTablet;
