import { connect } from 'react-redux';

import ModalSession from './ModalSession';
import { RootState } from 'store/types';

const mapStateToProps = (state: RootState) => ({
  language: state.params.lng,
});

export default connect(mapStateToProps)(ModalSession);
