export interface PromotedPopupState {
  timeoutId: NodeJS.Timeout | null;
}

export interface ResetPromotedPopupTimeoutIdAction {
  type: typeof RESET_PROMOTED_POPUP_TIMEOUT_ID;
}

export interface setPromotedPopupTimeoutIdAction {
  type: typeof SET_PROMOTED_POPUP_TIMEOUT_ID;
  timeoutId: NodeJS.Timeout;
}

export const RESET_PROMOTED_POPUP_TIMEOUT_ID =
  'RESET_PROMOTED_POPUP_TIMEOUT_ID';
export const SET_PROMOTED_POPUP_TIMEOUT_ID = 'SET_PROMOTED_POPUP_TIMEOUT_ID';

export type PromotedPopupActionTypes =
  | ResetPromotedPopupTimeoutIdAction
  | setPromotedPopupTimeoutIdAction;
