import { Partner } from '../types';
import {
  FETCH_CURRENT_PARTNER,
  CurrentPartnerActionTypes,
  RESET_CURRENT_PARTNER,
  SAVE_CURRENT_PARTNER,
  SET_CURRENT_PARTNER,
} from './types';

export const resetCurrentPartners = (): CurrentPartnerActionTypes => ({
  type: RESET_CURRENT_PARTNER,
});

export const setCurrentPartner = (
  partner: Partner,
): CurrentPartnerActionTypes => ({
  type: SET_CURRENT_PARTNER,
  partner,
});

export const saveCurrentPartner = (resp: any): CurrentPartnerActionTypes => ({
  type: SAVE_CURRENT_PARTNER,
  payload: resp,
});

export const fetchCurrentPartner = (resp: any): CurrentPartnerActionTypes => ({
  type: FETCH_CURRENT_PARTNER,
  payload: resp,
});

export default {
  resetCurrentPartners,
  fetchCurrentPartner,
  saveCurrentPartner,
};
