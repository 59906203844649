import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SubMenu.module.scss';
import cn from 'classnames';

interface Props {
  label: string;
  url?: string;
  exact?: boolean;
  icon: () => React.ReactNode | string;
  isActive?: () => boolean;
  onClick?: () => void;
}

export default memo(function TTNavComponent({
  label,
  url,
  exact = false,
  icon,
  isActive,
  onClick,
}: Props) {
  const renderIcon = () =>
    typeof icon == 'function' ? (
      icon()
    ) : icon ? (
      <i className={`icon icon-${icon}`} />
    ) : null;

  return url ? (
    <NavLink
      exact={exact}
      to={url}
      activeClassName={styles.active}
      isActive={isActive}
    >
      {renderIcon()}
      {label}
    </NavLink>
  ) : (
    <div
      className={cn(styles.nav_btn, isActive?.() && styles.active)}
      onClick={onClick}
    >
      {renderIcon()}
      {label}
    </div>
  );
});
