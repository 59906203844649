import React, { useState } from 'react';
import styles from './EventsList.module.scss';
import { Language } from 'store/types';
import SearchInput from 'components/Common/SearchInput';
import { Event } from 'store/Events/types';
import { EventAbstract } from 'store/Cycle/types';
import { EventLayout1 } from 'components/ui/Event';
import { findWord } from 'utils';
import { Guest } from 'store/Guests/types';
import cn from 'classnames';
import t from 'i18n';
import { EventProps } from 'components/ui/Event/EventLayout1/EventLayout1';
import NoData from 'components/ui/NoData';
import { ReactComponent as EducationIcon } from 'assets/icons/education.svg';

interface Props {
  language: Language;
  events: (EventAbstract | Event)[];
  guest?: Guest;
  isRegistredToCycle: boolean;
  showFilters?: boolean;
  renderEventProps?: (event: EventAbstract | Event) => Partial<EventProps>;
  renderHeader?: () => JSX.Element;
  isSeason?: boolean;
  dateEndOfReplay?: string;
  isCurrentEvents?: boolean;
  isUpcommingEvents?: boolean;
  isRegisteredToPremium?: boolean;
}

const EventsList = ({
  events,
  language,
  guest,
  isRegistredToCycle,
  showFilters = true,
  renderEventProps,
  renderHeader,
  isSeason,
  dateEndOfReplay,
  isCurrentEvents,
  isUpcommingEvents,
  isRegisteredToPremium,
}: Props) => {
  const [search, setSearch] = useState('');
  const renderEvents = (list: (EventAbstract | Event)[]) => {
    if (events.length === 0) {
      return (
        <div
          className="align-center-middle flex-container small-12"
          style={{ height: '10rem' }}
        >
          <NoData
            icon={<EducationIcon width="34" height="34" fill="#B2BCC6" />}
            title={
              isCurrentEvents
                ? t('empty_training_list_message_for_current')
                : isUpcommingEvents
                ? t('empty_training_list_message_for_upcomming')
                : t('empty_training_list_message')
            }
          />{' '}
        </div>
      );
    }

    if (!list.length) {
      return (
        <div
          className="align-center-middle flex-container small-12"
          style={{ height: '10rem' }}
        >
          {t('No result found')}
        </div>
      );
    }

    return list.map((event) => {
      const isRegistered =
        isRegistredToCycle ||
        guest?.registeredForEvents?.[event.id] != null ||
        isRegisteredToPremium ||
        event['user-registered'];

      return (
        <div
          className="cell m-b-m p-x-xs small-12 medium-6 large-4"
          key={`session-${event.id}`}
        >
          <EventLayout1
            isActive={isRegistered}
            event={event as Event}
            language={language}
            {...renderEventProps?.(event)}
            isCycle={true}
            dateEndOfReplay={dateEndOfReplay}
            multiDateIndex={event.multiDateIndex}
          />
        </div>
      );
    });
  };

  const filteredEvents = findWord(events, search, [
    'nameFr',
    'nameNl',
    'nameEn',
  ]);

  return (
    <div>
      {renderHeader?.()}
      {showFilters && (
        <div className={styles.searchWrapper}>
          {events.length > 10 && (
            <div className="m-l-auto">
              <SearchInput
                width="200"
                search={search}
                handleSearchChange={setSearch}
              />
            </div>
          )}
        </div>
      )}
      <div>
        <div className={cn('grid-x m-x-xs', !showFilters && 'm-t-l')}>
          {renderEvents(filteredEvents)}
        </div>
      </div>
    </div>
  );
};

export default EventsList;
