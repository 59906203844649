import React, { memo, useCallback, useState } from 'react';
import styles from './SessionActions.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import { Slot } from 'store/Slots/types';
import {
  getByLanguage,
  isSessionLive,
  isFullProgram,
  isSessionPast,
  appendParamsToLink,
  hasPartnerManagerAccess,
  isSessionSoldOut,
  bindWebinarLink,
  isPlaySessionInStage,
  isSessionSpeaker,
  getLanguageForSession,
  getNotNullLanguages,
  parseBoolean,
  hasEventAdminAccess,
  isAllowedSlot,
  isReplayExpiredForUser,
  isEndOfReplayExpired,
  parseJson,
  isPastByXTime,
  isUserPremium,
  isEventDatePast,
  isEventDateLive,
} from 'utils';
import { Language } from 'store/types';
import { Guest, PREMIUM_REGISTRATION_STATUS } from 'store/Guests/types';
import { isGuestRegistered, isSlotInProgram } from 'utils';
import { GuestProgramState } from 'store/Program/types';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactTooltip from 'react-tooltip';
import { capitalize, isEmpty } from 'lodash';
import { Event, EVENT_TYPES } from 'store/Events/types';
import { ResourceState } from 'store/Resource/types';
import { EventAccess } from 'store/Event/types';
import { Link } from 'react-router-dom';
import urls from 'router/urls';
import { User } from 'store/Auth/types';
import { getTooltipMessage } from './services';
import { ModalDataInfoType } from 'store/Modal/Info/types';
import { CONGRESS_IDS } from 'config';
import { openOptionsConfirmationModal } from 'store/Modal/OptionsConfirmation/actions';
import { useDispatch } from 'react-redux';
import { optionsData } from 'store/Modal/OptionsConfirmation/types';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as ExpiredIcon } from 'assets/icons/alert-circle.svg';
import { ReactComponent as ClipboardIcon } from 'assets/icons/clipboard.svg';
import s from 'Webinar/Reception/Reception.module.scss';
import t from '../../../../../i18n';

/*import AddToCalendar from 'components/Common/AddToCalendar';
import moment from 'moment';*/

interface Props {
  showJoinText?: boolean;
  showAddText?: boolean;
  showMainButton?: boolean;
  showCalendarAction?: boolean;
  session: Slot;
  event: Event;
  language: Language;
  guest?: Guest;
  user?: User | null;
  guestProgram: GuestProgramState;
  eventIdsResource: ResourceState<EventAccess>;
  isSelected?: boolean;
  blockType?: 'block' | 'list';
  actionStyle?: React.CSSProperties;
  actionsStyle?: React.CSSProperties;
  showRemoveText?: boolean;
  addSlotToProgram: (slotId: number, guestId: number) => any;
  removeSlotFromProgram: (slotId: number, guestId: number) => any;
  openInfoModal: (data: ModalDataInfoType) => void;
  onClick?: () => void;
  isSlotsFetched?: boolean;
  userPremiumStatus: PREMIUM_REGISTRATION_STATUS;
  registerPremiumToEvent: (
    eventId: number | string,
    userId: number | string,
  ) => any;
}

export default memo(function SessionActions({
  showJoinText = false,
  showAddText = false,
  showRemoveText = showAddText,
  showMainButton = true,
  showCalendarAction = false,
  session,
  language,
  guest,
  user,
  isSelected,
  guestProgram,
  eventIdsResource,
  blockType,
  event,
  actionStyle,
  addSlotToProgram,
  removeSlotFromProgram,
  openInfoModal,
  actionsStyle,
  onClick,
  isSlotsFetched,
  userPremiumStatus,
  registerPremiumToEvent,
}: Props) {
  const dispatch = useDispatch<any>();
  const [saving, setSaving] = useState(false);
  const webinarUrl = session?.webinarUrlEn;
  const urlParams = new URLSearchParams(location.search);
  const dateIndexParam = urlParams.get('dateIndex');
  const eventDate = event?.eventDates?.find(
    (evtDate) => evtDate.id == parseInt(dateIndexParam ?? ''),
  );
  const isLive = eventDate
    ? isEventDateLive(eventDate.startDateTime, eventDate.endDateTime)
    : isSessionLive(session);
  const isPast = eventDate
    ? isEventDatePast(eventDate.endDateTime)
    : isSessionPast(session);
  const isPremiumRegistered = isUserPremium(userPremiumStatus);
  const isRegistered =
    isGuestRegistered(guest).isRegistered ||
    (isPremiumRegistered && event.isIncludedPremium == 1);
  const isSlotAdded =
    isSelected ?? isSlotInProgram(guestProgram.data, session.id, 'any');
  const isAllowed = isAllowedSlot(guestProgram.data, session.id);
  const isPartnerManager = hasPartnerManagerAccess(
    eventIdsResource.items[0],
    String(event?.id),
  );
  const isEventAdmin = hasEventAdminAccess(
    eventIdsResource.items[0],
    String(event?.id),
  );
  const isSpeaker = isSessionSpeaker(session, user?.id);
  const isSoldOut = isSessionSoldOut(session);
  const inStage = isPlaySessionInStage(session);
  const hasYoutubeLink =
    (isSlotsFetched && !isEmpty(session.youtubeVideoLink)) ?? false;
  const youtubeLinkCreationTime = (isSlotsFetched && session.createdAt) ?? '';
  const isYoutubeVideoReady = youtubeLinkCreationTime
    ? isPastByXTime(youtubeLinkCreationTime, 10, 'minutes')
    : false;
  const isProcessing = isSlotsFetched && hasYoutubeLink && !isYoutubeVideoReady;
  const forcedDateEndOfReplay = guest?.forcedDateEndOfReplay;
  const [registeringPremium, setRegisteringPremium] = useState(false);
  const isEventFull = +(event.type ?? 0) == EVENT_TYPES.EVENT_FULL;

  const onSuccess = useCallback(() => {
    toast.success(_('operationCompleted'));
  }, []);

  const onError = useCallback((resp: any) => {
    const error = resp?.response?.data?.errors?.error;

    toast.error(_(error ? `events.error${error}` : 'errorOccured'), {
      autoClose: 10000,
    });
  }, []);

  const handleOpenInfos = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      openInfoModal({
        title: _('Session inaccessible!'),
        description: isRegistered
          ? CONGRESS_IDS.includes(event.id)
            ? _('To upgrade your plan contact the helpdesk') +
              ' : info@forumforthefuture.be'
            : _('To access this session, modify your registration')
          : _('To access this session, you need to register'),
        mainAction: {
          text: isRegistered ? _('Modify my registration') : _('Register now'),
          to: bindWebinarLink(
            urls.webinar.reception.registration.root,
            event.id,
          ),
          type: 'green',
        },
      });
    },
    [event.id, language, openInfoModal],
  );

  const handleOpenReplayInfo = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      openInfoModal({
        title: _('This session is not part of your program !'),
        description: _(
          'To access the replay, please add this session to your program.',
        ),
        mainAction: {
          text: _('Add to my program'),
          type: 'green',
          icon: <PlusIcon />,
          onClick: handleAddSlot,
        },
        icon: <ClipboardIcon fill="#29394D" />,
      });
    },
    [event.id, language, openInfoModal],
  );
  const handleOpenOptionsConfirmation = () => {
    const options = guest?.options;
    const nonConfirmedOptions = options?.filter((obj) => {
      return obj.hasConfirmed == null && obj.isConfirmable == 1;
    });
    if (nonConfirmedOptions?.length != 0) {
      if (event && user) {
        const data: optionsData = {
          eventId: event.id,
          userId: user.id,
          guest: guest,
          language: language,
        };
        dispatch(openOptionsConfirmationModal(data));
      }
    }
  };
  const handleAddSlot = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      handleOpenOptionsConfirmation();
      if (saving) {
        return;
      }

      setSaving(true);
      addSlotToProgram(session.id, guest?.id ?? 0)
        .finally(() => {
          setSaving(false);
        })
        .then(() => {
          toast.success(
            <strong>{_('session added successfully to program')}</strong>,
            {
              autoClose: 10000,
            },
          );
        })
        .catch(onError);
    },
    [addSlotToProgram, guest, onError, onSuccess, saving, session.id],
  );

  const handleRemove = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      if (saving) {
        return;
      }

      setSaving(true);
      removeSlotFromProgram(session.id, guest?.id ?? 0)
        .finally(() => {
          setSaving(false);
        })
        .then(() => {
          toast.success(
            <strong>{_('session successfully detached from program')}</strong>,
            {
              autoClose: 10000,
            },
          );
        })
        .catch(onError);
    },
    [guest, onError, onSuccess, removeSlotFromProgram, saving, session.id],
  );

  const handleJoinClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation();
      onClick?.();
      // if (!isEmpty(webinarUrl)) {
      //   window.open(webinarUrl, '_blank');
      // }
      if (!isPlaySessionInStage(session) && isEmpty(webinarUrl)) {
        event.preventDefault();
      }
    },
    [session, webinarUrl],
  );

  const handleRegisterPremium = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      // e.stopPropagation();

      setRegisteringPremium(true);
      registerPremiumToEvent(event.id, user?.id ?? 0)
        .finally(() => {
          setRegisteringPremium(false);
        })
        .then(() => {
          window.open(webinarUrl, '_blank', 'noreferrer');
        })
        .catch(onError);
    },
    [
      registeringPremium,
      user,
      event,
      registerPremiumToEvent,
      onError,
      webinarUrl,
    ],
  );

  /*  const renderAddToCalendar = () => {
    const data = {
      title: getByLanguage(session, 'name', language) ?? '...',
      description: getByLanguage(session, 'description', language) ?? '...',
      location: '...',
      startTime: moment(session.startDateTime).format(),
      endTime: moment(session.endDateTime).format(),
    };

    return (
      <div className="m-l-xs" style={actionStyle}>
        <AddToCalendar
          data={data}
          dropDownClass={styles.calendarDropDown}
          classNameWrapper={styles.calendarWrapper}
        />
      </div>
    );
  };*/

  const renderMainButton = () => {
    const isLight = isFullProgram(event);

    if (isLight) {
      return null;
    }

    if (!isSlotAdded) {
      if (isSoldOut && !isSpeaker && !isPast) {
        return null;
      }

      let props: any = {
        style: actionStyle,
        className: classNames(
          styles.actionButton,
          !showAddText && styles.compact,
        ),
      };

      if (isAllowed) {
        props = {
          ...props,
          onClick: handleAddSlot,
          className: classNames(props.className, styles.blue),
        };
      } else {
        if (isRegistered) {
          props = {
            ...props,
            onClick: handleOpenInfos,
            className: classNames(props.className, isPast && styles.blue),
            'data-tip': 'not_allowed',
            'data-for': `tooltip-sa-${blockType}-${session.id}`,
          };
        } else {
          props = {
            ...props,
            onClick: handleOpenInfos,
            className: classNames(props.className, isPast && styles.blue),
            'data-tip': 'not_registered',
            'data-for': `tooltip-sa-${blockType}-${session.id}`,
          };
        }
        showTooltip = true;
      }

      if (isEventFull) {
        return (
          <button {...props}>
            {saving ? (
              <ClipLoader size={14} color="#fff" loading={saving} />
            ) : (
              <>
                <PlusIcon />
              </>
            )}
            {showAddText ? <span>{_('Add to my program')}</span> : ''}
            {!showAddText && isAllowed && (
              <span className={styles.tooltip}>{_('Add to my program')}</span>
            )}
          </button>
        );
      }
    }

    if (isLive && blockType === 'list') {
      return null;
    }

    if (isEventFull) {
      return (
        <button
          style={actionStyle}
          onClick={handleRemove}
          className={classNames(
            styles.actionButton,
            styles.removeButton,
            !showAddText && styles.compact,
          )}
        >
          {saving ? (
            <ClipLoader size={14} color="#18a0fb" loading={saving} />
          ) : (
            <>
              <CrossIcon fill="#F9626D" width="10px" />
            </>
          )}
          {showRemoveText ? <span>{_('Remove')}</span> : ''}
          {!showAddText && (
            <span className={styles.tooltip}>
              {_('Remove from my program')}
            </span>
          )}
        </button>
      );
    }
  };

  const renderJoinContentButton = (
    type: 'past' | 'stage' | 'expired' | 'default',
  ) => {
    switch (type) {
      case 'past':
        return (
          <>
            {registeringPremium ? (
              <ClipLoader size={14} color={'#fffff'} />
            ) : (
              <PlayIcon stroke="#FFF" width="12px" />
            )}
            {showJoinText ? <span>{_('Replay')}</span> : ''}
          </>
        );
      case 'expired':
        return (
          <>
            <ExpiredIcon stroke="#FFF" width="12px" />
            {showJoinText ? (
              <span style={{ fontSize: '12px' }}>{_('Replay Expired')}</span>
            ) : (
              ''
            )}
          </>
        );
      case 'stage':
        return (
          <>
            <VideoIcon stroke="#FFF" width="12px" />
            {showJoinText || isLive ? <span>{_('Stage')}</span> : ''}
          </>
        );
      default:
        return (
          <>
            {isProcessing ? (
              <>
                <ClipLoader size={14} color={'#fffff'} />
                <span>{_('link_processing')}</span>
              </>
            ) : (
              <>
                {registeringPremium ? (
                  <ClipLoader size={14} color={'#fffff'} />
                ) : (
                  <ExternalLinkIcon />
                )}

                {showJoinText || isLive ? <span>{_('Rejoin')}</span> : ''}
              </>
            )}
          </>
        );
    }
  };

  const renderJoinButton = () => {
    let linkProps: any = {
      onClick: handleJoinClick,
      className: classNames(
        styles.joinButton,
        !isLive && !showJoinText && styles.compact,
        !inStage && !isPast && isEmpty(webinarUrl) && styles.disabled,
        !isLive && !isPast && styles.blue,
        registeringPremium && styles.disabled,
      ),
      style: actionStyle,
    };
    let planDateEndOfReplay = null;
    const withFullAccess = isPartnerManager || isSpeaker || isEventAdmin;
    const canJoinSession =
      (isRegistered &&
        (event.type == EVENT_TYPES.EVENT_QUICK || isSlotAdded)) ||
      withFullAccess;
    const { isReplayable } = event;
    let isMatchEndOfReplayExpired = false;
    if (!isEmpty(guest)) {
      if (!isEmpty(guest?.registeredChoice)) {
        const guestPlan = JSON.parse(guest?.registeredChoice as string)?.plan;
        const configBag = parseJson(event.configBag);
        const endOfReplays = configBag.endOfReplays;
        const matchedEndOfReplay =
          endOfReplays && guestPlan in endOfReplays
            ? endOfReplays[guestPlan]
            : event.dateEndOfReplay;
        planDateEndOfReplay = matchedEndOfReplay;
        if (
          ((endOfReplays && !(guestPlan in endOfReplays)) || !endOfReplays) &&
          forcedDateEndOfReplay
        ) {
          isMatchEndOfReplayExpired = isEndOfReplayExpired(
            forcedDateEndOfReplay,
          );
        } else if (
          forcedDateEndOfReplay &&
          forcedDateEndOfReplay > matchedEndOfReplay
        ) {
          isMatchEndOfReplayExpired = isEndOfReplayExpired(
            forcedDateEndOfReplay,
          );
        } else {
          isMatchEndOfReplayExpired = isEndOfReplayExpired(matchedEndOfReplay);
        }
      }
    }
    /** Session is past */
    if (isPast && parseBoolean(session.isPostPlayVideo) && isReplayable == 1) {
      const isReplayExpired = guest && isReplayExpiredForUser(guest);
      const isPlanReplayExpired = isEndOfReplayExpired(planDateEndOfReplay);

      if (!withFullAccess && isReplayExpired && isPlanReplayExpired) {
        return null;
      }

      if (isMatchEndOfReplayExpired) {
        return (
          <a
            id="session-action-expired"
            target="_blank"
            rel="noopener noreferrer"
            {...linkProps}
          >
            {renderJoinContentButton('expired')}
          </a>
        );
      }

      const languages = getNotNullLanguages(session, 'webinarReplayVideo');

      const webinarLink = webinarUrl ?? '';

      if (languages.length > 0) {
        const mainLanguage =
          languages.indexOf(language) !== -1 ? language : languages[0];
        //const canReplayPastSession = canJoinSession || isAllowed;

        const linkParams: [string, string][] = [['lng', mainLanguage]];
        languages.forEach((lng) => {
          linkParams.push([
            `video${capitalize(lng)}`,
            getByLanguage(session, 'webinarReplayVideo', lng) ?? '',
          ]);
        });
        if (event.isMultiDate) {
          linkParams.push(['selectedDate', guest?.selectedDate ?? '']);
          linkParams.push([
            'eventDate',
            eventDate ? eventDate.startDateTime : event.startDateTime,
          ]);
        }

        if (canJoinSession && !isEmpty(webinarLink)) {
          linkProps = {
            ...linkProps,
            href: appendParamsToLink(webinarLink, linkParams),
          };
          if (
            isPremiumRegistered &&
            (!guest?.id || !isGuestRegistered(guest).isRegistered)
          ) {
            linkProps = { ...linkProps, onClick: handleRegisterPremium };
          }
        } else if (isAllowed && !isSlotAdded && !isEmpty(webinarLink)) {
          linkProps = {
            ...linkProps,
            href: '',
            onClick: handleOpenReplayInfo,
          };
        } else if (!isEmpty(webinarLink)) {
          linkProps = {
            ...linkProps,
            href: '',
            onClick: handleOpenInfos,
            className: classNames(linkProps.className, isPast && styles.blue),
            'data-tip': 'not_allowed',
            'data-for': `tooltip-sa-${blockType}-${session.id}`,
          };
          showTooltip = true;
        } else {
          linkProps = {
            ...linkProps,
            href: '',
            onClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();
            },
            className: classNames(linkProps.className, styles.blue),
            'data-tip': 'empty_url',
            'data-for': `tooltip-sa-${blockType}-${session.id}`,
          };
          showTooltip = true;
        }

        return (
          <a
            id="session-action-join"
            href={appendParamsToLink(webinarLink, linkParams)}
            target="_blank"
            rel="noopener noreferrer"
            {...linkProps}
          >
            {renderJoinContentButton('past')}
          </a>
        );
      } else {
        const lng = getLanguageForSession(session, language);
        const linkParams: [string, string][] = [['lng', lng]];
        if (event.isMultiDate) {
          linkParams.push(['selectedDate', guest?.selectedDate ?? '']);
          linkParams.push([
            'eventDate',
            eventDate ? eventDate.startDateTime : event.startDateTime,
          ]);
        }

        linkProps = {
          ...linkProps,
          className: classNames(linkProps.className, styles.blue),
          'data-tip': 'empty_replay_url',
          'data-for': `tooltip-sa-${blockType}-${session.id}`,
        };
        showTooltip = true;

        return (
          <a
            id="session-action-join"
            href={appendParamsToLink(webinarLink, linkParams)}
            target="_blank"
            rel="noopener noreferrer"
            {...linkProps}
          >
            {renderJoinContentButton('past')}
          </a>
        );
      }
    }

    /** Play session in the Stage when it's not Past */
    if (inStage && !isPast) {
      return (
        <Link
          to={bindWebinarLink(urls.webinar.webinarStage, event.id)}
          {...linkProps}
        >
          {renderJoinContentButton('stage')}
        </Link>
      );
    }

    /**
     * Add past style when
     * (1): the session is Past but the webinarReplayVideo is not configured
     */
    linkProps = {
      ...linkProps,
      className: classNames(linkProps.className, isPast && styles.blue),
    };

    /**
     * The session is not in the Stage (webinar session) and not Past OR When (1)
     **/
    if (!inStage && canJoinSession) {
      const lng = getLanguageForSession(session, language);
      const linkParams: [string, string][] = [['lng', lng]];
      if (event.isMultiDate) {
        linkParams.push(['selectedDate', guest?.selectedDate ?? '']);
        linkParams.push([
          'eventDate',
          eventDate ? eventDate.startDateTime : event.startDateTime,
        ]);
      }

      /** Zoom session, we must add the query param zoomUrl only when no webinarVideo{lng} configured */
      if (!isPast) {
        const languages = getNotNullLanguages(session, 'webinarVideo');

        if (
          languages.length === 0 &&
          session.zoomMeetingUrl &&
          !isEmpty(session.zoomMeetingUrl)
        ) {
          linkParams.push(['zoomUrl', session.zoomMeetingUrl]);
        }
      }

      if (isEmpty(webinarUrl)) {
        linkProps = {
          ...linkProps,
          'data-tip': 'empty_url',
          'data-for': `tooltip-sa-${blockType}-${session.id}`,
        };
        showTooltip = true;

        // TODO Check this condition
        return null;
      }

      if (
        isPremiumRegistered &&
        (!guest?.id || !isGuestRegistered(guest).isRegistered)
      ) {
        linkProps = { ...linkProps, onClick: handleRegisterPremium };
      }

      return (
        <a
          id="session-action-join"
          href={!isProcessing && appendParamsToLink(webinarUrl, linkParams)}
          target="_blank"
          rel="noopener noreferrer"
          {...linkProps}
        >
          {renderJoinContentButton('default')}
        </a>
      );
    }

    return null;
  };

  let showTooltip = false;
  return (
    <>
      <div className={styles.actions} style={actionsStyle}>
        {renderJoinButton()}
        {showMainButton && renderMainButton()}
        {/* {!isPast && isRegistered && showCalendarAction && renderAddToCalendar()} */}
      </div>
      {showTooltip && (
        <ReactTooltip
          id={`tooltip-sa-${blockType}-${session.id}`}
          effect="float"
          type="light"
          className="portal-tooltip"
          getContent={(dataTip: string) => (
            <span>{getTooltipMessage(dataTip)}</span>
          )}
        />
      )}
    </>
  );
});
