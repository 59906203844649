import {
  PUSH_SOURCE_TOKEN,
  CLEAR_SOURCES_TOKENS,
  SourceTokenState,
  SourceTokenActionTypes,
} from './types';

const reducer = (
  state: SourceTokenState = [],
  action: SourceTokenActionTypes,
) => {
  switch (action.type) {
    case PUSH_SOURCE_TOKEN: {
      const newItem = {
        sourceName: action.sourceName,
        sourceToken: action.sourceToken,
      };
      const filteredItem = state.filter(
        (item) => item.sourceName === action.sourceName,
      );
      const mappedItems = state.map((item) =>
        item.sourceName === action.sourceName ? newItem : item,
      );
      const tab = [...state, newItem];
      return filteredItem.length > 0 ? mappedItems : tab;
    }
    case CLEAR_SOURCES_TOKENS: {
      return [];
    }
    default:
      return state;
  }
};

export default reducer;
