import {
  FETCH_USER_PERSONAL_DATA_FULFILLED,
  FETCH_USER_PERSONAL_DATA_PENDING,
  FETCH_USER_PERSONAL_DATA_REJECTED,
  SAVE_USER_PERSONAL_DATA_FULFILLED,
  SAVE_USER_PERSONAL_DATA_PENDING,
  SAVE_USER_PERSONAL_DATA_REJECTED,
  UserActionTypes,
  UserPersonalDataState,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};

export const reducer = (
  state: UserPersonalDataState = initialState,
  action: UserActionTypes,
): UserPersonalDataState => {
  switch (action.type) {
    case FETCH_USER_PERSONAL_DATA_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }

    case FETCH_USER_PERSONAL_DATA_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: [...data[0]],
        nbResult: nbResult,
      };
    }

    case FETCH_USER_PERSONAL_DATA_REJECTED: {
      return {
        ...state,
      };
    }
    case SAVE_USER_PERSONAL_DATA_PENDING: {
      return {
        ...state,
      };
    }
    case SAVE_USER_PERSONAL_DATA_REJECTED: {
      return {
        ...state,
      };
    }
    case SAVE_USER_PERSONAL_DATA_FULFILLED: {
      const { data } = action.payload;

      if (data) {
        return {
          ...state,
          error: null,
          items: [data],
          nbResult: 1,
        };
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default reducer;
