import { Language } from 'store/types';
import { SetLanguageAction, SET_LANGUAGE } from './types';

export const setLanguage = (language: Language): SetLanguageAction => {
  const lng = (language ?? 'fr').toLowerCase();
  localStorage.setItem('lng', lng);

  return {
    type: SET_LANGUAGE,
    language: lng,
  };
};

export default { setLanguage };
