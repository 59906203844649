import { connect } from 'react-redux';
import { RootState } from 'store/types';
import SessionActions from './SessionActions';
import { addSlotToProgram, removeSlotFromProgram } from 'store/Program/thunks';
import { openInfoModal } from 'store/Modal/Info/actions';
import { registerPremiumToEvent } from 'store/Guests/thunks';
import { selectUserPremiumStatus } from 'store/Auth';

const mapStateToProps = (state: RootState) => ({
  guestProgram: state.event.guestProgram,
  eventIdsResource: state.event.eventIds,
  language: state.params.lng,
  guest: state.event.guests.items[0],
  user: state.auth.user,
  event: state.event.event.items[0],
  userPremiumStatus: selectUserPremiumStatus(state),
});

export default connect(mapStateToProps, {
  addSlotToProgram,
  removeSlotFromProgram,
  openInfoModal,
  registerPremiumToEvent,
})(SessionActions);
