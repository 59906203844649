import React, { memo, useEffect } from 'react';
import s from './ModalEventFullRegistration.module.scss';
import { ModalState } from 'store/Modal/types';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { useLanguage } from 'hooks/useLanguage';
import RegistrationStepsHandler from 'components/Webinar/Reception/Registration/FullRegistration/RegistrationStepsHandler';
import { FullEventRegistrationData } from 'store/Modal/EventFullRegistration/types';
interface Props {
  modal: ModalState<FullEventRegistrationData>;
  onCloseModal: () => void;
}

export default memo(function ModalEventFullRegistration({
  modal,
  onCloseModal,
}: Props) {
  const language = useLanguage();

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      if (e?.data?.message === 'CLOSE_REGISTRATION_MODAL') {
        modal.onSuccess?.();
        onCloseModal();
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [modal, onCloseModal]);

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      className={s.modal_content}
    >
      <CloseModal onClose={onCloseModal} />
      <RegistrationStepsHandler /> {/*props: {...modal.data} */}
    </Modal>
  );
});
