import React, { CSSProperties, ReactNode } from 'react';
import s from './Container.module.scss';
import cn from 'classnames';
import { useMainMenuHeight } from 'hooks/useMainMenuHeight';

interface Props {
  children: ReactNode;
  bgColor?: string;
  className?: string;
  styles?: CSSProperties;
  asFullPage?: boolean;
}

const Container = ({
  children,
  bgColor = '#FAFBFB',
  asFullPage = false,
  className,
  styles,
}: Props) => {
  const mainMenuHeight = useMainMenuHeight();

  const defaultStyle = {
    minHeight: asFullPage ? `calc(100vh - ${mainMenuHeight}px)` : '',
    background: bgColor,
  };

  return (
    <div
      className={cn(s.wrapper, className)}
      style={{ ...defaultStyle, ...styles }}
    >
      {children}
    </div>
  );
};

export default Container;
