import { combineReducers } from 'redux';
import { reducer as resource } from 'store/Resource';
import { createNamedWrapperReducer } from 'store/services';
import { ResourceState, ResourceActionTypes } from 'store/Resource/types';
import { Speaker } from 'store/Speakers/types';
import {
  CYCLE_SPEAKER_RESOURCE,
  CYCLE_RESOURCE,
  PREMIUM_OFFER_RESOURCE,
  SEASON_RESOURCE,
  ALL_CYCLES_RESOURCE,
  ESSENTIAL_RESOURCE,
} from 'store/types';
import { Cycle } from 'store/Cycle/types';

const reducer = combineReducers({
  speakers: createNamedWrapperReducer<
    ResourceState<Speaker>,
    ResourceActionTypes<Speaker>
  >(resource, CYCLE_SPEAKER_RESOURCE),
  allCycles: createNamedWrapperReducer<
    ResourceState<Cycle>,
    ResourceActionTypes<Cycle>
  >(resource, ALL_CYCLES_RESOURCE),
  cycles: createNamedWrapperReducer<
    ResourceState<Cycle>,
    ResourceActionTypes<Cycle>
  >(resource, CYCLE_RESOURCE),
  premiumOffers: createNamedWrapperReducer<
    ResourceState<Cycle>,
    ResourceActionTypes<Cycle>
  >(resource, PREMIUM_OFFER_RESOURCE),
  seasons: createNamedWrapperReducer<
    ResourceState<Cycle>,
    ResourceActionTypes<Cycle>
  >(resource, SEASON_RESOURCE),
  essentials: createNamedWrapperReducer<
    ResourceState<Cycle>,
    ResourceActionTypes<Cycle>
  >(resource, ESSENTIAL_RESOURCE),
});

export default reducer;
