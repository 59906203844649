import { RootState } from 'store/types';

export const selectUserCommunities = (state: RootState) => {
  return state.auth?.user?.memberOfCommunities ?? [];
};

export const selectNavCommunity = (state: RootState) => {
  return state.auth && state.auth.navCommunity;
};

export const selectLoggedAs = (state: RootState) => state.auth?.loggedAs;
export const selectUser = (state: RootState) => state.auth?.user;
export const selectRouting = (state: RootState) => state.router;

export const selectLanguage = (state: RootState) => state.params.lng;
export const selectAuthorization = (state: RootState, communityId: number) =>
  state.auth.authorizations?.find(({ client }) => client.id === communityId);

export const selectToken = (state: RootState) => {
  const {
    auth: { token: userToken, appToken },
  } = state;
  return userToken !== '' ? userToken : appToken.token;
};

export const selectUserPremiumStatus = (state: RootState) => {
  return state.auth && state.auth.userPremiumStatus;
};

export const selectUserPremiumShared = (state: RootState) => {
  return state.auth && state.auth.userPremiumShared;
};
