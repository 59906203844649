import {
  FETCH_ORDERS,
  OrderActionTypes,
  RESET_ORDERS,
  CANCEL_ORDER,
} from './types';

export const resetOrders = (): OrderActionTypes => ({
  type: RESET_ORDERS,
});

export const fetchOrders = (resp: any): OrderActionTypes => ({
  type: FETCH_ORDERS,
  payload: resp,
});

export const cancelOrder = (resp: any): OrderActionTypes => ({
  type: CANCEL_ORDER,
  payload: resp,
});

export default {
  resetOrders,
  fetchOrders,
  cancelOrder,
};
