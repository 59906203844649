import React from 'react';
import styles from './Tag.module.scss';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { getByLanguage } from 'utils';
import { useLanguage } from 'hooks/useLanguage';
import { TagType } from 'store/EventTags/types';

interface Props {
  tag: TagType;
  setDeletedTagId: (deletedTagId: number) => void;
}

export default function Tag({ tag, setDeletedTagId }: Props) {
  const language = useLanguage();
  const tagName = getByLanguage(tag, 'name', language);

  return (
    <div className={styles.tag}>
      <p className={styles.tagName}>{tagName}</p>
      <div
        onClick={() => setDeletedTagId(tag.id)}
        className={styles.dismissContainer}
      >
        <CrossIcon className={styles.tagDismiss} />
      </div>
    </div>
  );
}
