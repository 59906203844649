import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import styles from './PlacesLayout.module.scss';
import { getByLanguage, onError, onSuccess } from 'utils';
import cn from 'classnames';
import { ReactComponent as PlanIcon } from 'assets/icons/alert-circle.svg';
import _ from 'i18n';
import Markdown from 'markdown-to-jsx';
import Help from 'ui/Help/Help';

interface Props {
  organisationId: number;
  partner?: any;
  eventId?: number;
  ownerId?: number;
  count?: number;
  pack: any;
  fetchPacks: (organizationId: number, eventId: number) => void;
  savePlace: (
    event: any,
    user: any,
    couponModel: any,
    owner: any,
    partner: any,
  ) => any;
  getEmployeePack: (organizationId: number, eventId: number) => void;
}

export default function Pack({
  organisationId,
  partner,
  eventId,
  ownerId,
  count,
  pack,
  fetchPacks,
  getEmployeePack,
  savePlace,
}: Props): ReactElement {
  const {
    availablePlaces,
    couponModelId,
    totalPlaces,
    usedPlaces,
    nameFr,
    descriptionFr,
    descriptionEn,
    descriptionNl,
  } = pack;
  const [content, setContent] = useState<string>('Drop Something Here');
  const placeAttribution = (
    event: any,
    user: any,
    couponModel: any,
    owner: any,
    partner: any,
  ) => {
    return savePlace(event, user, couponModel, owner, partner)
      .then((res: any) => {
        onSuccess(res, {
          title: _('saveSuccess'),
          body: _('placeAssigned'),
        });
        getEmployeePack(organisationId, event);
        fetchPacks(organisationId, event);
      })
      .catch((res: any) => {
        onError(res);
      });
  };
  // This function will be triggered when dropping
  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text');
    setContent(data);
    placeAttribution(eventId, data, couponModelId, ownerId, partner);
  };

  const allowDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dragOver = (event: any) => {
    event.currentTarget.classList.add('drop');
  };

  const dragLeave = (event: any) => {
    event.currentTarget.classList.remove('drop');
  };

  document.querySelectorAll('#simple_pack').forEach((column) => {
    column.addEventListener('dragover', dragOver);
    column.addEventListener('dragleave', dragLeave);
    column.addEventListener('drop', dragLeave);
  });

  const unaryStyle: CSSProperties = {
    width: count == 1 ? '357px' : '181px',
  };

  return (
    <div
      id="simple_pack"
      className={styles.pack}
      onDragOver={allowDrop}
      onDrop={dropHandler}
      style={
        availablePlaces < 1
          ? { ...unaryStyle, pointerEvents: 'none' }
          : { ...unaryStyle }
      }
    >
      <div className={cn(styles.pack___header, 'grid-x')}>
        <span className="cell auto">{nameFr}</span>
        <Help
          className={styles.pack___help}
          title={nameFr}
          contentStyle={{ width: '263px' }}
          align={{ offset: [10, -20] }}
          placement="leftTop"
        >
          <Markdown className="markdown">
            {descriptionFr || descriptionEn || descriptionNl || ''}
          </Markdown>
        </Help>
      </div>

      <div className={styles.pack___content}>
        <div
          className={cn(styles.pack___content_dropzone, 'onDrop')}
          style={
            availablePlaces < 1
              ? { pointerEvents: 'none', opacity: '0.5', cursor: 'not-allowed' }
              : {}
          }
        >
          <p>{_('DragDrop')}</p>
        </div>
        <div className={styles.pack___content_footer}>
          <span className={styles.x_text}>{usedPlaces}</span>
          <span className={styles.s_text}>{_('Registered')}</span>
          <span className={styles.s_text}>{_('sur')}</span>
          <span className={styles.x_text}>{totalPlaces}</span>
          <span className={styles.s_text}>{_('seats')}</span>
        </div>
      </div>
    </div>
  );
}

Pack.Fetching = () => (
  <div className={styles.pack} style={{ borderColor: '#e8eaed' }}>
    <div
      className={cn(styles.pack___header, 'grid-x')}
      style={{ background: '#e8eaed' }}
    >
      <span className="cell auto" />
      <div className={cn(styles.pack___header_place, 'p-x-s')}>
        <span />
        <span />
      </div>
    </div>

    <div className={styles.pack___content}>
      <div className={styles.pack___content_dropzone}>
        <p />
      </div>
      <div className={styles.pack___content_footer}>
        <p />
      </div>
    </div>
  </div>
);
