import { TTP_API_URL } from 'config';
import axios from 'axios';

export const getPrivacyRubrics = ({
  token,
  communityId = null,
}: {
  token: string;
  communityId: number | null;
}) => {
  const requestUrl = `${TTP_API_URL}/media/privacy-rubric`;

  const filters = [];

  filters.push({
    property: 'organization',
    value: 9,
    operator: 'eq',
  });

  const sort = [
    {
      property: 'order',
      dir: 'asc',
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      sort: JSON.stringify(sort),
      fields: '*,versions',
    },
  });
};
