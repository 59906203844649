import {
  PacksActionTypes,
  PacksState,
  RESET_PACKS,
  FETCH_PACKS_PENDING,
  FETCH_PACKS_FULFILLED,
  FETCH_PACKS_REJECTED,
  FETCH_ALL_PACKS_PENDING,
  FETCH_ALL_PACKS_FULFILLED,
  FETCH_ALL_PACKS_REJECTED,
  SAVE_PLACE_PENDING,
  SAVE_PLACE_FULFILLED,
  SAVE_PLACE_REJECTED,
  CANCEL_PLACE_PENDING,
  CANCEL_PLACE_FULFILLED,
  CANCEL_PLACE_REJECTED,
  CHOOSE_PACK_PENDING,
  CHOOSE_PACK_FULFILLED,
  CHOOSE_PACK_REJECTED,
  FETCH_SUMMARY_PENDING,
  FETCH_SUMMARY_FULFILLED,
  FETCH_SUMMARY_REJECTED,
  SET_FIDUCIARY_PENDING,
  SET_FIDUCIARY_REJECTED,
  SET_FIDUCIARY_FULFILLED,
} from './types';
import { paginationInitialState } from 'store/Pagination/reducer';
import { filterInitialState } from 'store/Filter/reducer';

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  items: [],
  packList: [],
  error: null,
  canceling: false,
  nbResult: 0,
  ...paginationInitialState,
  ...filterInitialState,
  packSummary: [],
  fetchingSummary: false,
  fetchedSummary: false,
};

export const reducer = (
  state: PacksState = initialState,
  action: PacksActionTypes,
) => {
  switch (action.type) {
    case RESET_PACKS: {
      return initialState;
    }
    case FETCH_PACKS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        items: state.page === 1 ? [] : state.items,
      };
    }
    case FETCH_PACKS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: state.page === 1 ? [...data] : [...state.items, ...data],
        nbResult: nbResult,
      };
    }
    case FETCH_PACKS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    case FETCH_ALL_PACKS_PENDING: {
      return {
        ...state,
        fetched: false,
        fetching: true,
        packList: state.page === 1 ? [] : state.packList,
      };
    }
    case FETCH_ALL_PACKS_FULFILLED: {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        packList:
          state.page === 1 ? { ...data } : { ...state.packList, ...data },
        nbResult: nbResult,
      };
    }
    case FETCH_ALL_PACKS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        packList: [],
        nbResult: 0,
      };
    }
    case FETCH_SUMMARY_PENDING: {
      return {
        ...state,
        fetchedSummary: false,
        fetchingSummary: true,
        packSummary: state.page === 1 ? [] : state.packSummary,
      };
    }
    case FETCH_SUMMARY_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        fetchingSummary: false,
        fetchedSummary: true,
        error: null,
        packSummary: data,
      };
    }
    case FETCH_SUMMARY_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        packSummary: [],
      };
    }
    case SAVE_PLACE_PENDING:
    case CHOOSE_PACK_PENDING:
    case SET_FIDUCIARY_PENDING: {
      return {
        ...state,
        saved: false,
        saving: true,
      };
    }
    case SAVE_PLACE_FULFILLED:
    case CHOOSE_PACK_FULFILLED:
    case SET_FIDUCIARY_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        saving: false,
        saved: true,
        error: null,
      };
    }
    case SAVE_PLACE_REJECTED:
    case CHOOSE_PACK_REJECTED:
    case SET_FIDUCIARY_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        saving: false,
        error,
        nbResult: 0,
      };
    }
    case CANCEL_PLACE_PENDING: {
      return {
        ...state,
        canceled: false,
        canceling: true,
      };
    }
    case CANCEL_PLACE_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        canceling: false,
        canceled: true,
        error: null,
      };
    }
    case CANCEL_PLACE_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        canceling: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
