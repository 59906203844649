import React from 'react';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg';
import cn from 'classnames';
import s from './SummaryBlock.module.scss';
import {
  Theme,
  useAdvancedLayoutTheme,
} from 'components/Layout/AdvancedLayout/ThemeProvider';

interface Props {
  title: string;
  theme?: Theme;
  children?: React.ReactNode;
}

export const SummaryBlock = ({ title, theme, children }: Props) => {
  const defaultTheme = useAdvancedLayoutTheme().primaryColor;
  const propsTheme = theme?.primaryColor;
  return (
    <section>
      <div className={cn(s.title, 'flex-container align-middle m-y-xs')}>
        <CheckCircleIcon
          className="m-l-s m-r-xs"
          width="14"
          height="14"
          fill={propsTheme ?? defaultTheme}
        />
        <div
          className={s.uppercase}
          style={{ color: propsTheme ?? defaultTheme }}
        >
          {title}
        </div>
        <div className={s.line}></div>
      </div>
      <div>{children}</div>
    </section>
  );
};
