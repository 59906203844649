import {
  NotificationBarActionTypes,
  SET_SHOW_NOTIFICATION_BAR,
  SET_NOTIFICATION_BAR_DATA,
  RESET_NOTIFICATION_BAR,
  NotificationBarData,
  NotificationBarRouteType,
} from './types';

export const resetNotificationBar = (): NotificationBarActionTypes => ({
  type: RESET_NOTIFICATION_BAR,
});

export const setShowNotificationBar = (
  isDisplayed: boolean,
): NotificationBarActionTypes => ({
  type: SET_SHOW_NOTIFICATION_BAR,
  isDisplayed,
});

export const setNotificationBarData = (
  data: NotificationBarData,
  route?: NotificationBarRouteType,
): NotificationBarActionTypes => ({
  type: SET_NOTIFICATION_BAR_DATA,
  data,
  route,
});

export default {
  resetNotificationBar,
  setShowNotificationBar,
  setNotificationBarData,
};
