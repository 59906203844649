import axios from 'axios';
import { TTP_API_URL } from 'config';
import { escapeSpecialChars, getApiPagination } from 'utils';
import { Filter } from 'services/Filter';
import { ResourceData, TTPSort } from 'store/types';
import { hydrateFormData } from '../services';
import { Step2Data } from '../Subscription/api';

export const eventFields = [
  'id',
  'type',
  'nameFr',
  'nameNl',
  'nameEn',
  'placeFr',
  'placeNl',
  'placeEn',
  'descriptionFr',
  'descriptionNl',
  'descriptionEn',
  'urlBannerFr',
  'urlBannerNl',
  'urlBannerEn',
  'eventDate',
  'slotsCount',
  'client',
  'memberPrice',
  'nonMemberPrice',
  'languages',
  'isReplayable',
  'accreditationHours',
  'status',
  'replayStatus',
  'labelFr',
  'labelNl',
  'labelEn',
  'tag',
  'playProgress',
  'fullWatch',
  'eventClassificationRank',
  'question',
  'isMultiDate',
  'isVirtual',
  'playProgress',
  'isCertificateNotIncluded',
  'certificateStatus',
  'slotReplayUrls',
  'eventDates',
];

export const getEventIds = ({
  token,
  userId,
}: {
  token: string;
  userId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/get-events-access-for-contact`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      contact: userId,
      nolimit: true,
    },
  });
};

export const getPopularEventIds = ({
  token,
  userId,
}: {
  token: string;
  userId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/get-popular-events-access-for-contact`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      contact: userId,
    },
  });
};

export const getEvents = ({
  token,
  ids,
  page,
  pageSize,
  filters = [],
  sort = [],
  fields,
  queryParams,
}: {
  token: string;
  ids: number[];
  page: number;
  pageSize: number;
  sort?: TTPSort[];
  filters?: Filter[];
  fields?: string[];
  queryParams?: any;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event`;
  const filter = [
    // {
    //   property: 'id',
    //   operator: 'in',
    //   value: ids,
    // },
    ...filters.map((filter) => filter.serialize()),
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: escapeSpecialChars(JSON.stringify(filter)),
      sort: JSON.stringify(sort),
      fields: fields ? fields.join(',') : eventFields.join(','),
      ...getApiPagination(page, pageSize),
      ...queryParams,
    },
  });
};

export const getEventsStats = ({
  token,
  queryParams,
}: {
  token: string;
  queryParams?: any;
}) => {
  const requestUrl = `${TTP_API_URL}/event/get-event-cycles-statistics`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      ...queryParams,
    },
  });
};

export const getUserRegisteredEventsStats = ({
  token,
  queryParams,
}: {
  token: string;
  queryParams?: any;
}) => {
  const requestUrl = `${TTP_API_URL}/event/get-user-registered-events-statistics`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      ...queryParams,
    },
  });
};

export const saveEventClassificationRank = ({
  token,
  updatedEventId,
  updatedRank,
}: {
  token: string;
  updatedEventId: number;
  updatedRank: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/save-event-classification-rank`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('eventId', String(updatedEventId));
  formData.append('rank', String(updatedRank));

  return axios.post(requestUrl, formData);
};

export const saveEventFavorite = ({
  token,
  userId,
  eventId,
}: {
  token: string;
  eventId: number;
  userId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event-favorites`;

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('event', String(eventId));
  formData.append('user', String(userId));

  return axios.post(requestUrl, formData);
};

export const deleteEventFavorite = ({
  token,
  userId,
  eventId,
}: {
  token: string;
  userId: number;
  eventId: number;
}) => {
  const requestUrl = `${TTP_API_URL}/event/event-favorites/delete/event-id/${eventId}/user-id/${userId}`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};
