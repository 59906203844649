import actions from './actions';
import * as api from './api';
import { Dispatch } from 'redux';
import { RootState, SLOTS_RESOURCE, TTPSort } from 'store/types';
import { Filter, MultiValueFilter, SingleValueFilter } from 'services/Filter';
import { getEventIdsPromise } from 'store/Events/thunks';
import { selectEvents } from 'store/Events/selectors';
import { fetchEventIds } from 'store/Events/actions';
import { getRooms } from 'store/Rooms/api';
import { Slot, SlotReducerNameType } from './types';
import { fetchResource } from 'store/Resource/actions';

export interface FetchSlotsParamsType {
  filters?: Filter[];
  sort?: TTPSort[];
  ids?: number[];
  nolimit?: boolean;
  withRooms?: boolean;
}

export const fetchSlots = (
  {
    filters = [],
    sort = [],
    ids = [],
    nolimit,
    withRooms = true,
  }: FetchSlotsParamsType,
  nameReducer: SlotReducerNameType = SLOTS_RESOURCE,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const {
    auth: { token: userToken, appToken },
  } = state;
  const { eventIds, eventIdsFetched } = selectEvents(state, 'LIST');
  const { page, pageSize } = state.slots;
  const token = userToken !== '' ? userToken : appToken.token;

  const fetchSlotsAction = (ids: number[]) =>
    dispatch(
      fetchResource(
        nameReducer,
        api
          .getSlots({
            token,
            ids,
            page,
            pageSize,
            nolimit,
            filters: [...filters, new SingleValueFilter('status', 'gte', 2)],
            sort,
          })
          .then((res) => {
            const { data, nbResult } = res.data;

            if (withRooms) {
              if (data?.length > 0) {
                getRooms({
                  token,
                  filters: [
                    new MultiValueFilter(
                      'event.id',
                      'in',
                      data.map((slot: Slot) => slot.event),
                    ),
                  ],
                }).then((res) => {
                  const { data } = res.data;
                  dispatch(actions.setRooms(data, nameReducer));
                });
              }
            }

            return { data, nbResult };
          }),
      ),
    );

  if (ids.length > 0) {
    return fetchSlotsAction(ids);
  }

  if (eventIdsFetched && eventIds.length > 0) {
    return fetchSlotsAction(eventIds);
  }

  return dispatch(
    fetchEventIds(
      'LIST',
      getEventIdsPromise(state, 'LIST').then((resp) => {
        if (resp && resp.data && resp.data.data && resp.data.data.events) {
          fetchSlotsAction(resp.data.data.events);
        }
        return resp;
      }),
    ),
  );
};

export default {
  fetchSlots,
};
