import React, { CSSProperties, Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import s from './Button.module.scss';
import cn from 'classnames';
import { ColorType, SizeType, VariantType } from './services';

interface Props {
  title: string;
  variant?: VariantType;
  size?: SizeType;
  color?: ColorType;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disableIconStyle?: boolean;
  to?: string | null;
  style?: CSSProperties;
  isFullWidth?: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  withoutPathname?: boolean;
}

const Button = ({
  title,
  variant = 'contained',
  size = 'small',
  color = 'blue',
  to,
  startIcon,
  endIcon,
  disableIconStyle,
  isFullWidth,
  className,
  onClick,
  type,
  disabled,
  style,
  withoutPathname,
}: Props) => {
  const wrapperClassNames = cn(
    className,
    s.wrapper,
    isFullWidth && s.full_width,
    s[size],
    s[color as string],
    s[variant],
  );

  const renderButtonContent = () => (
    <Fragment>
      {startIcon && (
        <div className={cn(s.icon, !disableIconStyle && s.customized)}>
          {startIcon}
        </div>
      )}
      <span className={s.title}>{title}</span>
      {endIcon && (
        <div className={cn(s.icon, !disableIconStyle && s.customized)}>
          {endIcon}
        </div>
      )}
    </Fragment>
  );

  return to ? (
    <Link
      className={wrapperClassNames}
      to={withoutPathname ? to : { pathname: to }}
      target={withoutPathname ? '' : '_parent'}
      //target="_blank"
      rel={withoutPathname ? '' : 'noopener noreferrer'}
      style={style}
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
      }}
    >
      {renderButtonContent()}
    </Link>
  ) : (
    <button
      type={type}
      disabled={disabled}
      className={wrapperClassNames}
      onClick={onClick}
    >
      {renderButtonContent()}
    </button>
  );
};

export default Button;
