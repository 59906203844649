import React from 'react';

export interface Tab<T = string | number> {
  id: T;
  title: string;
  onClick?: (id: T) => void;
}

export interface TabPaneProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  tab: Tab;
}

export default function OnboardingPage({
  children,
  className,
  style,
}: TabPaneProps) {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}
