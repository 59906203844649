import React, { memo, useEffect, useState } from 'react';
import t from 'i18n';
import GoBack from 'components/Registration/GoBack';
import NextStep from 'components/Registration/NextStep/NextStep';
import StepsContent from '../../../Common/StepsContent';
import StepsControl from '../../../Common/StepsControl';
import styles from './StepCollaboratorsData.module.scss';
import AddCollaboratorsBloc from './AddCollaborators/AddCollaboratorsBloc';
import CollaboratorsList from './CollaboratorList/CollaboratorsList';
import { Collaborator } from './services';
import { useDispatch } from 'react-redux';
import { onError, onSuccess } from 'utils';
import CollaboratorsFetching from './CollaboratorList/CollaboratorsFetching';
import { REGISTRATION_PACK_STEPS } from 'Webinar/Reception/Registration/PackRegistration/services';
import NotFound from 'Common/NotFound/NotFound';
export interface CollaboratorsListProps {
  organizationId: number;
  goToStep: (step: REGISTRATION_PACK_STEPS) => void;
  fetchCollaborators: (organizationId: number) => void;
  getCollaboratorByEmail: (email: string) => any;
  fetched: boolean;
  fetching: boolean;
  error: string;
  collaborators: any;
  saveCollaborators: (
    organization: number,
    email: string,
    firstName: string,
    lastName: string,
    role: any,
    phone: string,
    lng: any,
  ) => any;
  saved: boolean;
  saving: boolean;
  deleted: boolean;
  deleting: boolean;
  userFetching: boolean;
  userFetched: boolean;
  updateCollaborator: (
    user: number,
    organization: number,
    email: string,
    emailId: number,
    firstName: string,
    lastName: string,
    role: any,
    roleId: number,
    phone: string,
    phoneId: number,
    lng: any,
  ) => any;
  addCollaborator: (collaborators: any) => any;
  uploadCollaborators: (organization: number, importAttachment: any) => any;
  data: any;
  deleteCollaborator: (
    userId: number,
    organization: number,
    isSimple: boolean,
    email: string,
    oldEmail: string,
  ) => any;
  newCollab: any;
  organizationName: string;
}

export default memo(function StepCollaboratorsData({
  organizationName,
  newCollab,
  organizationId,
  goToStep,
  fetchCollaborators,
  getCollaboratorByEmail,
  fetched,
  fetching,
  error,
  collaborators,
  saveCollaborators,
  saved,
  saving,
  deleting,
  addCollaborator,
  updateCollaborator,
  uploadCollaborators,
  userFetching,
  userFetched,
  data,
  deleteCollaborator,
}: CollaboratorsListProps) {
  const [selectedCollab, setSelectedCollab] = useState<Collaborator | null>(
    null,
  );
  const [isEdit, setIsEdit] = useState<boolean | null>(false);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [newCollaborators, setNewCollaborators] = useState<any[]>([]);
  const dispatch = useDispatch();
  const descriptionText = t('collaborators_step_description');
  useEffect(() => {
    fetchCollaborators(organizationId);
  }, []);

  const handleNewCollaborators = (collaborators: any[]) => {
    setNewCollaborators(collaborators);
  };
  const handleResetCollaborator = () => {
    setIsEdit(false);
    setNewCollaborators([]);
    setSelectedCollab({
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      emailId: 0,
      phoneNumber: '',
      phoneId: 0,
      role: '',
      roleId: 0,
      lng: '',
    });
  };
  const handleEdit = (collaborator: any) => {
    setIsEdit(true);
    setSelectedCollab({
      id: collaborator.id,
      firstName: collaborator.firstName,
      lastName: collaborator.lastName,
      email: collaborator.Email,
      emailId: collaborator.EmailId,
      phoneNumber: collaborator.Tél,
      phoneId: collaborator.phoneId,
      role: collaborator.role,
      roleId: collaborator.roleId,
      lng: collaborator.lng,
    });
  };

  const findUserByEmail = (email: string) => {
    getCollaboratorByEmail(email)
      .then((res: any) => {
        res.value.data.map((item: any) => {
          const exist = collaborators.find(
            (collaborator: any) => collaborator.id == item.id,
          );
          if (exist != undefined || exist != null) {
            onSuccess(res, {
              title: t('alreadyExist'),
              body: t('userAlreadyExist'),
            });

            setIsEdit(false);

            setSelectedCollab({
              id: 0,
              firstName: '',
              lastName: '',
              email: email,
              emailId: 0,
              phoneNumber: '',
              phoneId: 0,
              role: '',
              roleId: 0,
              lng: '',
              cachedNumber: '',
            });
          } else {
            if (item?.phone) {
              setIsEdit(null);
            } else {
              setIsEdit(true);
            }

            setSelectedCollab({
              id: item.id,
              firstName: item?.firstName ?? '',
              lastName: item?.lastName ?? '',
              email: item?.mainEmail ?? '',
              emailId: item?.emailId ?? '',
              phoneNumber: '',
              phoneId: item?.numId ?? '',
              role: item?.role?.type ?? '',
              roleId: item?.role?.id ?? '',
              lng: item?.language ?? '',
              cachedNumber: item?.phone,
            });
          }
        });
      })
      .catch((res: any) => {
        setIsEdit(false);

        setSelectedCollab({
          id: 0,
          firstName: '',
          lastName: '',
          email: email,
          emailId: 0,
          phoneNumber: '',
          phoneId: 0,
          role: '',
          roleId: 0,
          lng: '',
          cachedNumber: '',
        });
      });
  };

  const handleDelete = (
    userId: number,
    isSimple: boolean,
    email: string,
    oldEmail: string,
  ) => {
    deleteCollaborator(userId, organizationId, isSimple, email, oldEmail)
      .then((res: any) => {
        fetchCollaborators(organizationId);
        onSuccess(res, {
          title: t(''),
          body: t('collaboratorDeleted'),
        });
      })
      .catch((res: any) => {
        onError(res, t('mailAlreadyExist'));
        fetchCollaborators(organizationId);
      });
  };

  return (
    <StepsContent>
      {organizationId != 0 ? (
        <div>
          <div className={styles.collaborators_step}>
            <div className={styles.text_title}>
              {t('Add company employees')} {':'} {organizationName}
            </div>
            <div className={styles.description_text}>
              {!seeMore
                ? descriptionText.substring(0, descriptionText.indexOf('.')) +
                  ' ... '
                : descriptionText}
              {!seeMore && (
                <a onClick={() => setSeeMore(true)}>{t('See more')}</a>
              )}
            </div>

            <div className="grid-x flex-1">
              <div className="cell p-b-l p-s small-12 medium-4 large-4">
                <AddCollaboratorsBloc
                  organizationId={organizationId}
                  userFetching={userFetching}
                  userFetched={userFetched}
                  error={error}
                  findUserByEmail={findUserByEmail}
                  uploadCollaborators={uploadCollaborators}
                  isEdit={isEdit}
                  data={selectedCollab}
                  addCollaborator={addCollaborator}
                  saveCollaborators={saveCollaborators}
                  updateCollaborator={updateCollaborator}
                  saved={saved}
                  saving={saving}
                  collaborators={collaborators}
                  fetchCollaborators={fetchCollaborators}
                  resetForm={handleResetCollaborator}
                  uploadResult={data}
                />
              </div>
              {fetched ? (
                <div className="cell p-b-l p-s small-12 medium-8 large-8">
                  <CollaboratorsList
                    newCollab={newCollab}
                    collaborators={collaborators}
                    fetched={fetched}
                    fetching={fetching}
                    error={error}
                    deleteCollaborator={handleDelete}
                    onEdit={handleEdit}
                    deleting={deleting}
                  />
                </div>
              ) : (
                <div className="cell small-8 align-stretch p-r-xs">
                  <CollaboratorsFetching />
                </div>
              )}
            </div>
          </div>

          <StepsControl>
            <div className="p-s p-x-l flex-container">
              <NextStep
                text={t('Next step')}
                className="m-l-auto"
                theme="orangeRoyal"
                onClick={() =>
                  goToStep(REGISTRATION_PACK_STEPS.PLACE_ATTRIBUTIONS)
                }
              />
            </div>
          </StepsControl>
        </div>
      ) : (
        <div className="cell small-12">
          <NotFound text={'Select organization'} />
        </div>
      )}
    </StepsContent>
  );
});
