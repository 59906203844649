import React from 'react';
import s from '../StepsSummary.module.scss';
import { StepTitle } from 'components/Tools/Summary/StepTitle';
import { formatUen } from 'utils';
import _ from 'i18n';
import { GuestAddress } from 'store/Guests/types';
import { Address as AddressInterface } from 'interfaces/Address';
import { Address as InvoicingAddressType } from 'store/Guests/Invoicing/types';
import { SummaryBlock } from 'components/Tools/SummaryBlocks/SummaryBlock';

interface Address1 {
  type: 'INVOICING';
  address: Partial<InvoicingAddressType>;
}

interface Address2 {
  type: 'ADDRESS';
  address: Partial<AddressInterface>;
}
interface Address3 {
  type: 'GUEST';
  address: Partial<GuestAddress>;
}

interface Props {
  data: Address1 | Address2 | Address3;
  title?: string;
  className?: string;
}

export const AddressSummary = ({ title, data, className }: Props) => {
  const renderData = () => {
    switch (data.type) {
      case 'GUEST': {
        const {
          billingCompanyNumber,
          billingOrganization,
          billingSubjectToVAT,
          billingStreet,
          billingPostalCode,
        } = data.address;

        return (
          <div className={s.text}>
            {billingCompanyNumber && (
              <div>{formatUen(billingCompanyNumber)}</div>
            )}
            {billingOrganization && (
              <div>
                <strong>{billingOrganization}</strong>
              </div>
            )}
            <div>
              {billingStreet} - {billingPostalCode}
            </div>
            <div>
              {billingSubjectToVAT == '1'
                ? _('inscription.soumis_tva')
                : _('inscription.non_soumis_tva')}
            </div>
          </div>
        );
      }
      case 'INVOICING': {
        const { uen, organization, vatApply, street, zip, city } = data.address;

        return (
          <div className={s.text}>
            {uen && <div>{formatUen(uen)}</div>}
            {organization && (
              <div>
                <strong>{organization}</strong>
              </div>
            )}
            <div>
              {street} - {zip} {city}
            </div>
            <div>
              {vatApply == '1'
                ? _('inscription.soumis_tva')
                : _('inscription.non_soumis_tva')}
            </div>
          </div>
        );
      }
      case 'ADDRESS': {
        const { fullName: fullname, street, zipCode, city } = data.address;

        return (
          <div className={s.text}>
            {fullname && (
              <div>
                <strong>{fullname}</strong>
              </div>
            )}
            <div>
              {street} - {zipCode} {city}
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div className={className}>
      <SummaryBlock title={title ?? _('Billing address')} />
      <div>{renderData()}</div>
    </div>
  );
};

export default AddressSummary;
