import {
  ACCEPT_REGISTRATION_GUESTS,
  CHECK_COUPON,
  DECLINE_REGISTRATION_GUESTS,
  DELETE_ADDRESS,
  FETCH_GUEST_DATA,
  FETCH_GUESTS,
  GENERATE_BADGE,
  GuestActionTypes,
  RESET_GUESTS,
  SAVE_GUEST_DATA,
  SEND_BADGE,
  SEND_GUEST_CONFIRMATION,
  FETCH_CERTIFICATES,
  GENERATE_CERTIFICATE,
  CONFIRM_GUEST_OPTION,
  FETCH_PREMIUM_GUEST,
  FETCH_USER_PREMIUM_STATUS,
  REGISTER_PREMIUM_TO_EVENT,
  UPDATE_ADDRESS,
  CONFIRM_OPTIONS_REJECTION,
} from './types';

export const resetGuests = (): GuestActionTypes => ({
  type: RESET_GUESTS,
});

export const fetchGuests = (resp: any): GuestActionTypes => ({
  type: FETCH_GUESTS,
  payload: resp,
});

export const acceptRegistrationGuests = (resp: any): GuestActionTypes => ({
  type: ACCEPT_REGISTRATION_GUESTS,
  payload: resp,
});

export const declineRegistrationGuests = (resp: any): GuestActionTypes => ({
  type: DECLINE_REGISTRATION_GUESTS,
  payload: resp,
});

export const fetchGuestData = (resp: any): GuestActionTypes => ({
  type: FETCH_GUEST_DATA,
  payload: resp,
});

export const fetchPremiumGuest = (resp: any): GuestActionTypes => ({
  type: FETCH_PREMIUM_GUEST,
  payload: resp,
});

export const fetchUserPremiumStatus = (resp: any): GuestActionTypes => ({
  type: FETCH_USER_PREMIUM_STATUS,
  payload: resp,
});

export const saveGuestData = (resp: any): GuestActionTypes => ({
  type: SAVE_GUEST_DATA,
  payload: resp,
});

export const registerPremiumToEvent = (resp: any): GuestActionTypes => ({
  type: REGISTER_PREMIUM_TO_EVENT,
  payload: resp,
});

export const checkCoupon = (resp: any): GuestActionTypes => ({
  type: CHECK_COUPON,
  payload: resp,
});

export const deleteAddress = (resp: any): GuestActionTypes => ({
  type: DELETE_ADDRESS,
  payload: resp,
});

export const generateBadge = (resp: any): GuestActionTypes => ({
  type: GENERATE_BADGE,
  payload: resp,
});

export const sendBadge = (resp: any): GuestActionTypes => ({
  type: SEND_BADGE,
  payload: resp,
});

export const sendGuestConfirmationSubscribed = (
  resp: any,
): GuestActionTypes => ({
  type: SEND_GUEST_CONFIRMATION,
  payload: resp,
});

export const fetchCertificates = (resp: any): GuestActionTypes => ({
  type: FETCH_CERTIFICATES,
  payload: resp,
});

export const generateCertificate = (resp: any): GuestActionTypes => ({
  type: GENERATE_CERTIFICATE,
  payload: resp,
});

export const confirmGuestOption = (resp: any): GuestActionTypes => ({
  type: CONFIRM_GUEST_OPTION,
  payload: resp,
});

export const updateAddress = (resp: any): GuestActionTypes => ({
  type: UPDATE_ADDRESS,
  payload: resp,
});

export const confirmOptionsRejection = (resp: any): GuestActionTypes => ({
  type: CONFIRM_OPTIONS_REJECTION,
  payload: resp,
});

export default {
  resetGuests,
  fetchGuests,
  acceptRegistrationGuests,
  declineRegistrationGuests,
  fetchGuestData,
  fetchPremiumGuest,
  saveGuestData,
  checkCoupon,
  deleteAddress,
  generateBadge,
  sendBadge,
  sendGuestConfirmationSubscribed,
  fetchCertificates,
  generateCertificate,
  confirmGuestOption,
  fetchUserPremiumStatus,
  registerPremiumToEvent,
  updateAddress,
  confirmOptionsRejection,
};
