export interface IPersonalData {
  userId: number;
  id: number;
  agreationType: string;
  agreationTitle: string;
  numeroAgreation: string;
  agreationParam: string;
}

export interface ISavePersonalData {
  user: number;
  userAgreationData: string;
}

export interface UserPersonalDataState {
  fetching: boolean;
  fetched: boolean;
  items: IPersonalData[];
  error: any;
  nbResult: number;
}

export const FETCH_USER_PERSONAL_DATA = 'FETCH_USER_PERSONAL_DATA';
export const FETCH_USER_PERSONAL_DATA_PENDING =
  'FETCH_USER_PERSONAL_DATA_PENDING';
export const FETCH_USER_PERSONAL_DATA_FULFILLED =
  'FETCH_USER_PERSONAL_DATA_FULFILLED';
export const FETCH_USER_PERSONAL_DATA_REJECTED =
  'FETCH_USER_PERSONAL_DATA_REJECTED';

export const SAVE_USER_PERSONAL_DATA = 'SAVE_USER_PERSONAL_DATA';
export const SAVE_USER_PERSONAL_DATA_PENDING =
  'SAVE_USER_PERSONAL_DATA_PENDING';
export const SAVE_USER_PERSONAL_DATA_FULFILLED =
  'SAVE_USER_PERSONAL_DATA_FULFILLED';
export const SAVE_USER_PERSONAL_DATA_REJECTED =
  'SAVE_USER_PERSONAL_DATA_REJECTED';

export interface FetchUserPersonalDataAction {
  type:
    | typeof FETCH_USER_PERSONAL_DATA
    | typeof FETCH_USER_PERSONAL_DATA_PENDING
    | typeof FETCH_USER_PERSONAL_DATA_FULFILLED
    | typeof FETCH_USER_PERSONAL_DATA_REJECTED;
  payload: any;
}

export interface SaveUserPersonalDataAction {
  type:
    | typeof SAVE_USER_PERSONAL_DATA
    | typeof SAVE_USER_PERSONAL_DATA_PENDING
    | typeof SAVE_USER_PERSONAL_DATA_FULFILLED
    | typeof SAVE_USER_PERSONAL_DATA_REJECTED;
  payload: any;
}

export type UserActionTypes =
  | SaveUserPersonalDataAction
  | FetchUserPersonalDataAction;
