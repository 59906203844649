import axios from 'axios';
import { TTP_API_URL } from 'config';

export const getEventsCategories = ({
  token,
  filters = [],
}: {
  token: string;
  filters?: any[];
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/get-tags-category`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};
