import { Language } from 'store/types';

export interface NameWrappedAction {
  meta: string;
}
export interface RelatedArticlesState {
  fetching: boolean;
  fetched: boolean;
  items: any[];
  error: any;
  nbResult: number;
}
export const FETCH_RELATED_ARTICLES_PENDING = 'FETCH_RELATED_ARTICLES_PENDING';
export const FETCH_RELATED_ARTICLES_FULFILLED =
  'FETCH_RELATED_ARTICLES_FULFILLED';
export const FETCH_RELATED_ARTICLES_REJECTED =
  'FETCH_RELATED_ARTICLES_REJECTED';

export interface FetchRelatedArticlesAction {
  type:
    | typeof FETCH_RELATED_ARTICLES_PENDING
    | typeof FETCH_RELATED_ARTICLES_FULFILLED
    | typeof FETCH_RELATED_ARTICLES_REJECTED;
  payload: any;
}

export type RelatedArticlesTypes = FetchRelatedArticlesAction;

export interface Article {
  id: number;
  author: Author[];
  category: any;
  countComments: number;
  countDislikes: number;
  countLikes: number;
  csScope: string[];
  externalUrl: string;
  featured: number;
  fluxType: string;
  type: any;
  introduction: string;
  isExternal: boolean;
  isPrivate: boolean;
  language: Language;
  main_media: any;
  organization: any;
  pages: any;
  publishOnTalk: boolean;
  relevance: number;
  social: any;
  status: string;
  tags: any;
  theme: any;
  title: string;
  updatedAt: string;
  createdAt: string;
  publishedAt: string;
  readTime: string;
  url: string;
  avatars: any;
  medias: any[];
  shareUrl: string;
  chains: any;
}

export interface Author {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  signature: Signature;
}

export interface Signature {
  head: string | any;
  title: string;
}
