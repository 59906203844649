import {
  channelInfo,
  channelInfoActionTypes,
  RESET_CHANNEL_INFO,
  SET_CHANNEL_INFO,
} from './types';

const initialState = {
  isAuthorsSet: false,
};

const reducer = (
  state: channelInfo = initialState,
  action: channelInfoActionTypes,
) => {
  switch (action.type) {
    case RESET_CHANNEL_INFO: {
      return { ...initialState };
    }

    case SET_CHANNEL_INFO: {
      return {
        ...state,
        isAuthorsSet: action.isAuthorsSet,
      };
    }

    default:
      return state;
  }
};

export default reducer;
