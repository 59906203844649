import React, { memo, useEffect, useRef, useState, Fragment } from 'react';
import Modal from 'react-modal';
import s from './ModalWatch.module.scss';
import cn from 'classnames';
import t from 'i18n';
import CloseModal from '../CloseModal';
import { isEmpty } from 'lodash';
import { ModalState } from 'store/Modal/types';
import { useLanguage } from 'hooks/useLanguage';
import {
  calculatePlayProgressTime,
  formatDecimalHours,
  getByLanguage,
  getCroppedImageUrl,
  getEventNbHours,
  getEventNbMinutes,
  getLanguagesLabel,
  isEventLive,
  isEventPast,
  parseStringLanguages,
  prepareS3Url,
  speakersEventToString,
  formatDateFromTo,
  prepareS3ResourceUrl,
  isEventUpcoming,
  parseJson,
} from 'utils';
import { S3_FOLDER_URL_PROD } from 'config';
import { Event } from 'store/Events/types';
import Markdown from 'markdown-to-jsx';
import Avatar from 'components/ui/Avatar';
import HeaderActions from './HeaderActions/HeaderActions.wrap';
import Shave from 'components/Common/Shave';
import SimilarEvents from './SimilarEvents';
import { EventTag } from 'interfaces/EventTag';
import TagChip from 'components/Common/TagChip';
import useResponsive from 'hooks/useResponsive';
import SimilarEventsCarousel from './SimilarEventsCarousel';
import { useCountdown } from 'hooks/useCountDown';
import Chip from 'components/ui/Chip';
import ProgressBar from 'components/ui/ProgressBar';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

interface Props {
  modal: ModalState<Event>;
  onCloseModal: () => void;
}

// const clientAvatarUrl =
//   'https://s3.tamtam.pro/prod/storage/media/IMAGE/2174/AVATAR_54cbd68e1ba9284a1e486bd5d8551259cd51d96e.png';

export default memo(function ModalWatch({ modal, onCloseModal }: Props) {
  const { isMobile } = useResponsive();
  const language = useLanguage();
  const [isShaved, setIsShaved] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const event = modal.data;
  const modalWrapperRef = useRef<HTMLDivElement | null>(null);

  const { 'speakers-abstract': speakersData, clientData, languages } = event;
  const urlBanner = getByLanguage(event, 'urlBanner', language) ?? '';
  const title = getByLanguage(event, 'name', language);
  const description = getByLanguage(event, 'description', language) ?? '';
  const nbHours = getEventNbHours(event);
  const languagesLabel = getLanguagesLabel(parseStringLanguages(languages));

  const banner = getCroppedImageUrl(urlBanner, undefined, 400);
  const backgroundStyle = {
    backgroundImage: `linear-gradient(
      179.9deg, 
      rgba(41, 57, 77, 0) 0.09%, 
      rgba(41, 57, 77, 0.7) 73.92%, 
      #29394D 99.91%
      ), url(${prepareS3Url(banner)})`,
  };

  const eventTime = getEventNbMinutes(event);
  const playProgress = event?.playProgress ?? 0;
  const EventPlayProgress = calculatePlayProgressTime(
    eventTime,
    event?.playProgress,
  );

  const isLive = isEventLive(event as Event);
  const isExpired = isEventPast(event);
  const isUpcomming = isEventUpcoming(event);
  const { startDateTime, endDateTime } = event;
  const { minutes: diffMinutesWithNow } = useCountdown(startDateTime, 0);
  const dateHelper = formatDateFromTo(
    startDateTime ?? '',
    endDateTime ?? '',
    language,
  );

  useEffect(() => {
    modalWrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [modal.data]);

  const renderSpeakers = () => {
    if (!speakersData) return null;

    const { count, speakers } = speakersData;

    if (count > 2) {
      return (
        <span className={s.speakers_label}>
          {speakersEventToString(event, 4)}
        </span>
      );
    }

    return speakers.map(
      ({ firstName, lastName, pictureUrl, ...speaker }, index) => {
        let headline = getByLanguage(speaker, 'headline', language);
        if (typeof headline === 'object') {
          headline = headline.title;
        } else if (typeof parseJson(headline) === 'object') {
          headline = parseJson(headline).title;
        }
        <div key={index} className="m-r-l">
          <Avatar
            showInfo
            width={isMobile ? 32 : 50}
            height={isMobile ? 32 : 50}
            firstName={firstName}
            lastName={lastName}
            styleInfoName={{
              fontWeight: 500,
              color: '#FFF',
              fontSize: isMobile ? 12 : 14,
            }}
            styleInfoSignature={{
              color: '#B2BCC6',
              maxHeight: '2.2rem',
              overflow: 'hidden',
              fontSize: isMobile ? 10 : 12,
              margin: 0,
            }}
            url={prepareS3ResourceUrl(S3_FOLDER_URL_PROD, pictureUrl)}
            avatarSignature={{
              signature: headline,
              maxChar: 50,
            }}
          />
        </div>;
      },
    );
  };

  const renderTags = (tags: EventTag[]) => {
    return tags.map((tag) => {
      return (
        <TagChip
          key={tag.id}
          label={getByLanguage(tag, 'name', language)}
          className="m-b-xxs m-r-xxs"
          isTransparent
        />
      );
    });
  };

  const renderChips = () => {
    const label =
      EventPlayProgress > 0 && !isLive && !isUpcomming
        ? `${playProgress} sur ${eventTime} min`
        : `${eventTime} min`;

    if (isLive) {
      return (
        <Fragment>
          <Chip label={t('ongoing_now')} color="coralRed" />
          <Chip
            label={t('in_live_since').replace(':minutes', diffMinutesWithNow)}
            isLowercase
            isTransparent
          />
        </Fragment>
      );
    }

    if (isExpired) {
      return (
        <Fragment>
          <Chip label={t('Replay')} isTransparent />
          <Chip label={label} isLowercase isTransparent />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Chip label={t('LIVE')} isTransparent />
        <Chip label={label} isLowercase isTransparent />
      </Fragment>
    );
  };

  const renderPlayProgress = () => {
    const label =
      EventPlayProgress > 0
        ? `${playProgress} sur ${eventTime} min`
        : `${eventTime} min`;
    if (
      event.playProgress &&
      event.playProgress > 0 &&
      !isLive &&
      !isUpcomming
    ) {
      return (
        <div className={s.playProgress}>
          <ProgressBar
            progress={EventPlayProgress}
            showProgressNumber={false}
            color={'linear-gradient(90.02deg, #06D9B1 0%, #18A0FB 99.98%)'}
            width={'280px'}
            height={'4px'}
            style={{ background: '#B2BCC6' }}
            isEventWatch={true}
          />
          <span className={s.playInfo}>
            <Fragment>
              <Chip label={label} isLowercase isTransparent />
            </Fragment>
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={false}
      overlayClassName={cn(s.modal_overlay, 'ReactModal__Overlay_DARK')}
      className={s.modal}
    >
      <div ref={modalWrapperRef} className={s.modal_wrapper}>
        <div className={s.modal_header} style={backgroundStyle}>
          <CloseModal onClose={onCloseModal} className="m-l" theme="DARK" />
          <div className={s.chips}>{renderChips()}</div>
          <h1>{title}</h1>
          <div className={s.live_info}>
            <CalendarIcon
              width="14"
              height="14"
              fill="#fff"
              className="m-r-xs"
            />
            <span>
              <time dateTime={startDateTime}>{dateHelper}</time>
            </span>
          </div>
          <div className={s.speakers}>{renderSpeakers()}</div>
          <div className={s.playProgress}>{renderPlayProgress()}</div>
          <div className={s.actions}>
            <HeaderActions event={event} language={language} />
          </div>
        </div>

        <div className={cn(s.modal_details, 'grid-x')}>
          <div className={cn(s.description, 'small-12 large-8')}>
            <h2>{'Details'}</h2>
            {!isEmpty(description) && (
              <div className={cn(s.description_content, 'markdown m-t-s')}>
                <Shave
                  maxHeight={200}
                  isEnabled={isShaved}
                  setHasMore={setHasMore}
                >
                  <Markdown>{description}</Markdown>
                </Shave>
                {hasMore && (
                  <button
                    className="m-y-s"
                    onClick={() => setIsShaved(!isShaved)}
                  >
                    {isShaved ? t('See more') : t('See less')}
                  </button>
                )}
              </div>
            )}
          </div>

          <div className={cn(s.info, 'small-12 large-4')}>
            {!isEmpty(clientData?.avatarUrl) && (
              <div className={cn(s.client_logo, 'm-b-s')}>
                <div
                  style={{ backgroundImage: `url(${clientData?.avatarUrl})` }}
                />
              </div>
            )}
            {clientData?.name && (
              <div className="m-b-xs">
                <span>{t('Training of')}</span> : {clientData.name}
              </div>
            )}
            <div className="m-b-xs">
              <span>{t('Speakers')} : </span> {speakersEventToString(event, 1)}
            </div>
            {!isEmpty(nbHours) && (
              <div className="m-b-xs">
                <span>{t('Duration')} : </span> {formatDecimalHours(nbHours)}
              </div>
            )}
            <div className="m-b-xs">
              <span>{t('Language')} : </span> {languagesLabel}
            </div>
            {event?.tag && (
              <div className={cn(s.info_tags, 'm-t-l')}>
                {renderTags(event?.tag)}
              </div>
            )}
          </div>
        </div>

        <div className={cn(s.divider, 'm-y-s')} />

        <div className={cn(s.events_list, 'p-b-xl')}>
          <h2>{t('Similar trainings')}</h2>
          {isMobile ? (
            <SimilarEventsCarousel language={language} currentEvent={event} />
          ) : (
            <SimilarEvents language={language} currentEvent={event} />
          )}
        </div>
      </div>
    </Modal>
  );
});
