import React from 'react';

import styles from '../StepCollaboratorsData.module.scss';
import NewCollaboratorForm from './NewCollaboratorForm';
import UploadCollaboratorsFile from './UploadCollaboratorsFile';
import { Collaborator } from '../services';
import _ from 'i18n';
import classnames from 'classnames';
import { onError, onSuccess } from 'utils';

interface Props {
  organizationId: number;
  resetForm: () => void;
  isEdit: boolean | null;
  data: Collaborator | null;
  saved: boolean;
  saving: boolean;
  saveCollaborators: (
    organization: number,
    email: string,
    firstName: string,
    lastName: string,
    role: string,
    phone: string,
    lng: string,
  ) => any;
  error: any;
  collaborators: any;
  addCollaborator: (collaborators: any) => any;
  fetchCollaborators: (organizationId: number) => void;
  updateCollaborator: (
    user: number,
    organization: number,
    email: string,
    emailId: number,
    firstName: string,
    lastName: string,
    role: string,
    roleId: number,
    phone: string,
    phoneId: number,
    lng: string,
    isEdit: any,
  ) => any;
  uploadCollaborators: (organization: number, importAttachment: any) => any;
  findUserByEmail: (email: string) => any;
  userFetching: boolean;
  userFetched: boolean;
  uploadResult: any;
}

interface IState {
  isUpload: boolean;
}

class AddCollaboratorsBloc extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isUpload: false,
    };
  }

  handleChangeTabFilter = (isUpload: boolean) => {
    this.setState({
      isUpload,
    });
  };

  handleErrors = (error: string) => {
    switch (error) {
      case 'email.not.unique':
        onError(null, _('mailAlreadyExist'));
        break;
      case 'invalid.phone.number':
        onError(null, _('invalidPhoneNumber'));
        break;
      default:
        onError(null, error);
        break;
    }
  };
  HandleSaveCollaborators = (
    organization: number,
    email: string,
    firstName: string,
    lastName: string,
    role: string,
    phone: string,
    lng: string,
  ) => {
    this.props
      .saveCollaborators(
        organization,
        email,
        firstName,
        lastName,
        role,
        phone,
        lng,
      )
      .then((res: any) => {
        onSuccess(res, {
          title: _('saveSuccess'),
          body: _('collaboratorSaved'),
        });
        this.props.fetchCollaborators(this.props.organizationId);
        this.props.resetForm();
      })
      .catch((err: any) => {
        const error = JSON.parse(this.props.error);
        this.handleErrors(error?.errors[0]?.message);
      });
  };
  render() {
    const { isUpload } = this.state;

    return (
      <div
        className={classnames(
          styles.add_collaborators,
          this.props.userFetching ? styles.disabledDiv : '',
        )}
      >
        <div className={classnames('grid-x', styles.cumulate_filter)}>
          <div className={'small-6 align-stretch p-r-xs'}>
            <div
              key={1}
              style={{
                color: isUpload ? '#6D7F92' : '#F7953E',
              }}
              onClick={() => this.handleChangeTabFilter(false)}
            >
              <div style={{ paddingLeft: '20%' }}>
                {this.props.isEdit ? _('updateCollaborator') : _('addNew')}
              </div>
              <div style={{ marginTop: '2%', marginLeft: '4%' }}>
                <div
                  className={styles.cumulate_filter_div}
                  style={{
                    background: isUpload ? '#B2BCC6' : '#F7953E',
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'small-6 align-stretch p-r-xs'}>
            <div
              key={2}
              style={{
                color: isUpload ? '#F7953E' : '#6D7F92',
              }}
              onClick={() => this.handleChangeTabFilter(true)}
            >
              <div style={{ paddingLeft: '9%' }}>{_('importExcelFile')}</div>
              <div style={{ marginTop: '2%', marginLeft: '3%' }}>
                <div
                  className={styles.cumulate_filter_div}
                  style={{
                    background: isUpload ? '#F7953E' : '#B2BCC6',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {isUpload ? (
          <UploadCollaboratorsFile
            organizationId={this.props.organizationId}
            uploadResult={this.props.uploadResult}
            saved={this.props.saved}
            saving={this.props.saving}
            uploadCollaborators={this.props.uploadCollaborators}
            fetchCollaborators={this.props.fetchCollaborators}
          />
        ) : (
          <NewCollaboratorForm
            organizationId={this.props.organizationId}
            userFetching={this.props.userFetching}
            userFetched={this.props.userFetched}
            findUserByEmail={this.props.findUserByEmail}
            onResetForm={this.props.resetForm}
            updateCollaborator={this.props.updateCollaborator}
            isEdit={this.props.isEdit}
            data={this.props.data}
            saveCollaborators={this.HandleSaveCollaborators}
            saved={this.props.saved}
            saving={this.props.saving}
            error={this.props.error}
            //collaborators={this.props.collaborators}
            fetchCollaborators={this.props.fetchCollaborators}
          />
        )}
      </div>
    );
  }
}
export default AddCollaboratorsBloc;
