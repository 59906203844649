import { connect } from 'react-redux';
import { selectUser } from 'store/Auth';
import { fetchResourceThunk } from 'store/Resource/thunks';
import { RootState } from 'store/types';
import HeaderActions from 'Modal/ModalWatch/HeaderActions/HeaderActions';
import {
  deleteEventFavorite,
  saveEventFavorite,
  saveEventClassificationRank,
} from 'store/Events/thunks';
import {
  resetEventFavorite,
  setEventClassificationRank,
  setEventFavorite,
} from 'store/Events/actions';
import { selectAppInfo } from 'store/Params/selectors';

const mapStateToProps = (state: RootState) => ({
  userId: selectUser(state)?.id,
  user: state.auth.user,
  eventsFavoriteResource: state.events.userFavoriteList,
  eventsFavoriteStatsResource: state.events.list,
  recommendation: state.recommendation,
  appInfo: selectAppInfo(state),
});

export default connect(mapStateToProps, {
  fetchEvents: fetchResourceThunk,
  saveEventFavorite,
  setEventClassificationRank,
  saveEventClassificationRank,
  deleteEventFavorite,
  setEventFavorite,
  resetEventFavorite,
})(HeaderActions);
