import { Event } from 'store/Events/types';
import { NameWrappedAction, WithPaginationAndFilterState } from 'store/types';

export interface EventsCurrentHomeState {
  items: Event[];
  fetching: boolean;
  fetched: boolean;
  error: any;
  nbResult: number;
  page: number;
  pageSize: number;
}

export const FETCH_EVENTS_CURRENT = 'FETCH_EVENTS_CURRENT';
export const FETCH_EVENTS_CURRENT_PENDING = 'FETCH_EVENTS_CURRENT_PENDING';
export const FETCH_EVENTS_CURRENT_FULFILLED = 'FETCH_EVENTS_CURRENT_FULFILLED';
export const FETCH_EVENTS_CURRENT_REJECTED = 'FETCH_CURRENT_REJECTED';

export const SET_EVENTS_CURRENT_PAGE_SIZE = 'SET_EVENTS_CURRENT_PAGE_SIZE';
export const SET_EVENTS_CURRENT_PAGE = 'SET_EVENTS_CURRENT_PAGE';

export interface FetchEventsCurrentAction extends NameWrappedAction {
  type:
    | typeof FETCH_EVENTS_CURRENT
    | typeof FETCH_EVENTS_CURRENT_PENDING
    | typeof FETCH_EVENTS_CURRENT_FULFILLED
    | typeof FETCH_EVENTS_CURRENT_REJECTED
    | typeof SET_EVENTS_CURRENT_PAGE_SIZE
    | typeof SET_EVENTS_CURRENT_PAGE;
  payload: any;
}

export type EventActionTypes = FetchEventsCurrentAction;
