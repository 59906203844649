import Filter from './Filter';
import { MultiValueOperator } from './types';
import __ from 'lodash';

export type MultiValueType = string | number;

class MultiValueFilter<T extends MultiValueType> extends Filter<T> {
  private _value: Set<T>;

  constructor(
    protected _property: string,
    protected _operator: MultiValueOperator,
    private v: T[],
  ) {
    super(_property, _operator);
    this._value = new Set<T>(v);
  }

  get value(): Set<T> {
    return this._value;
  }

  set value(value: Set<T>) {
    this._value = value;
  }

  get values(): T[] {
    return Array.from(this._value);
  }

  addValue = (value: T) => {
    if (!this.value.has(value)) {
      this.value.add(value);
    }
  };

  serialize = () => ({
    property: this.property,
    operator: this.operator,
    value: this.operator === 'between' ? this.values.join(',') : this.values,
  });

  isEqual = ({ property, operator, value }: MultiValueFilter<T>) =>
    this.property === property &&
    this.operator === operator &&
    __.isEqual(this.value, value);

  clone = (): MultiValueFilter<T> => {
    return new MultiValueFilter<T>(
      this.property,
      this.operator as MultiValueOperator,
      [...this.value],
    );
  };
}

export default MultiValueFilter;
