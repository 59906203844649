import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotFoundPage } from 'Pages/NotFound';
import _ from 'i18n';
import TTPLoader from 'Common/TTPLoader';
import { URLS } from 'router';
import { bindWebinarLink } from 'utils';
import { CURRENT_CONGRESS_ID } from 'config';
import WatchRoute from './WatchRoute';
import { isInIframe } from 'utils';

/** lazy doesn't work on SSR TODO: replace with the alternative mentioned in the doc */
const Home = lazy(() => import('components/Pages/Home'));
const HomeFooter = lazy(() => import('components/Home/Footer'));
const Webinar = lazy(() => import('components/Webinar'));
const PlanSelector = lazy(() => import('components/PlanSelector'));
const Plans = lazy(() => import('Pages/Plans'));
const Cycle = lazy(() => import('components/Cycle'));
const PremiumOffer = lazy(() => import('PremiumOffer'));
const Seasons = lazy(() => import('Pages/Seasons'));
const Cycles = lazy(() => import('Pages/Cycles'));
const Essentials = lazy(() => import('Pages/Essentials'));
const Formations = lazy(() => import('Pages/Formations'));
const WebinarRegister = lazy(
  () => import('components/Webinar/WebinarRegister'),
);
const WibinarRedirect = lazy(() => import('Webinar/WebinarRedirect'));
const EventConditions = lazy(
  () => import('components/TermsAndConditions/EventConditions'),
);
const MySpace = lazy(() => import('MySpace'));
const Footer = lazy(() => import('Base/Layout/Footer'));
const MyReplays = lazy(() => import('MyReplays'));
const Faq = lazy(() => import('Pages/Faq'));
const ChannelsPage = lazy(() => import('components/Channels/NewChannelsPage'));
const NewChannelPage = lazy(() => import('components/Channels/NewChannelPage'));
const Privacy = lazy(() => import('components/Pages/Privacy'));
const WatchTrainings = lazy(() => import('components/Watch/Trainings'));
const WatchFavorites = lazy(() => import('components/Watch/Favorites'));
const Light = lazy(() => import('components/EventLight'));
//const UAPage = lazy(() => import('components/Pages/UA'));
const EventPreview = lazy(() => import('components/Pages/EventPreview'));

const Routes = () => (
  <Suspense fallback={<TTPLoader cssClass={'fixed-loader'} />}>
    <Switch>
      <Route path={URLS.light} component={Light} />
      <Route exact path={URLS.homePage} component={Home} />
      <Route
        path={URLS.webinar.webinarRegister}
        component={WebinarRegister}
        exact
      />
      <Route path="/webinar" component={WibinarRedirect} />
      <Route path={URLS.webinar.root} component={Webinar} />
      <Route path={URLS.cycle.root} component={Cycle} />
      <Route path={URLS.premiumOffer.root} component={PremiumOffer} />
      <Route path={URLS.planSelector} component={PlanSelector} />
      <Route path={URLS.plans} component={Plans} />
      <Route path={URLS.library.seasons} component={Seasons} />
      <Route path={URLS.library.cycles} component={Cycles} />
      <Route path={URLS.library.essentials} component={Essentials} />
      <Route path={URLS.library.trainings} component={Formations} />
      <Route path={URLS.terms.event} component={EventConditions} />
      <Route path={URLS.mySpace.root} component={MySpace} />
      <Route path={URLS.myReplays} component={MyReplays} />
      <Route path={URLS.faq} component={Faq} />
      <Route path={URLS.channels} component={ChannelsPage} />
      <Route path={URLS.channel} component={NewChannelPage} />
      <Route path={URLS.privacy} component={Privacy} />
      <Route
        path={URLS.fff}
        render={() => (
          <Redirect
            to={bindWebinarLink(URLS.webinar.landingPage, CURRENT_CONGRESS_ID)}
          />
        )}
      />
      <Route path={URLS.channel} component={NewChannelPage} />
      {/* <Route path={URLS.ua} component={UAPage} /> */}
      <WatchRoute path={URLS.watch.root} exact />
      <WatchRoute path={URLS.watch.search} component={WatchTrainings} />
      <WatchRoute path={URLS.watch.trainings} component={WatchTrainings} />
      <WatchRoute path={URLS.watch.favorites} component={WatchFavorites} />
      <Route path={URLS.channel} component={NewChannelPage} />
      <Route path={URLS.eventPreview} component={EventPreview} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
    {/* <Route exact path={['/', URLS.event, URLS.search]} component={HomeFooter} /> */}
    <Route exact path="*" render={() => !isInIframe() && <Footer />} />
  </Suspense>
);

export default Routes;
