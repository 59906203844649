import { connect } from 'react-redux';
import EventCardActions from 'EventCardActions/EventCardActions';
import { selectUser } from 'store/Auth';
import { fetchResourceThunk } from 'store/Resource/thunks';
import { RootState } from 'store/types';
import { deleteEventFavorite, saveEventFavorite } from 'store/Events/thunks';
import { resetEventFavorite, setEventFavorite } from 'store/Events/actions';

const mapStateToProps = (state: RootState) => ({
  userId: selectUser(state)?.id,
  eventsFavoriteResource: state.events.userFavoriteList,
  eventsStatsResource: state.events.list,
});

export default connect(mapStateToProps, {
  fetchEvents: fetchResourceThunk,
  saveEventFavorite,
  deleteEventFavorite,
  setEventFavorite,
  resetEventFavorite,
})(EventCardActions);
