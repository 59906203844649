import {
  NotifsActionTypes,
  RESET_NOTIFS,
  FETCH_NOTIFS_PENDING,
  FETCH_NOTIFS_FULFILLED,
  FETCH_NOTIFS_REJECTED,
  NotifsState,
} from './types';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};

const reducer = (
  state: NotifsState = initialState,
  action: NotifsActionTypes,
) => {
  switch (action.type) {
    case RESET_NOTIFS: {
      return initialState;
    }
    case FETCH_NOTIFS_PENDING: {
      return { ...state, fetched: false, fetching: true };
    }
    case FETCH_NOTIFS_FULFILLED: {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case FETCH_NOTIFS_REJECTED: {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error?.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
