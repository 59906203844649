import { compose } from 'redux';
import {
  WithPaginationAndFilterState,
  GenericReducerTypes,
  NameWrappedAction,
  ResourceData,
  AppReducer,
} from './types';
import withFilter from './Filter';
import withPagination from './Pagination';
import { AnyAction } from 'redux';

export function createNamedWrapperReducer<S, A extends NameWrappedAction>(
  reducerFunction: AppReducer<S, A>,
  reducerName: GenericReducerTypes,
) {
  return (state: S, action: A) => {
    const { meta: name } = action;
    const isInitializationCall = state === undefined;
    if (name !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
}

const composeWithPagination = <
  S extends WithPaginationAndFilterState,
  A extends AnyAction
>(
  reducerName: GenericReducerTypes,
) => (reducer: AppReducer<S, A>) => withPagination<S, A>(reducer, reducerName);

const composeWithFilter = <
  S extends WithPaginationAndFilterState,
  A extends AnyAction
>(
  reducerName: GenericReducerTypes,
) => (reducer: AppReducer<S, A>) => withFilter<S, A>(reducer, reducerName);

export const composeWithPaginationAndFilter = <
  S extends WithPaginationAndFilterState,
  A extends AnyAction
>(
  reducer: AppReducer<S, A>,
  reducerName: GenericReducerTypes,
) =>
  compose(
    composeWithPagination<S, A>(reducerName),
    composeWithFilter<S, A>(reducerName),
  )(reducer);

export const hydrateFormData = (
  formData: FormData,
  data: ResourceData<any>,
  namespace?: string,
): FormData => {
  data &&
    Object.keys(data).forEach((key) => {
      let propertyName = key;
      const propertyValue = data[key];

      if (namespace) {
        propertyName = `${namespace}[${key}]`;
      }

      if (Array.isArray(propertyValue)) {
        propertyValue.forEach((value: any) => {
          formData.append(`${propertyName}[]`, value);
        });

        return;
      } else if (
        typeof propertyValue === 'object' &&
        !(propertyValue instanceof File)
      ) {
        hydrateFormData(formData, propertyValue, propertyName);
        return;
      }

      formData.append(propertyName, propertyValue);
    });

  return formData;
};

/**
 * Gets the value of an object's property when the index is variable.
 * This is a solution to the typescript error : object has no index signature.
 */
export function getPropertyValue<T, K extends keyof T>(
  obj: T,
  property: K,
): T[K] {
  return obj[property];
}
