import React, { memo, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import t from 'i18n';
import styles from '../StepCollaboratorsData.module.scss';
import { TTPInputField } from 'Common/TTPForm';
import classNames from 'classnames';
import { ReactComponent as SpinIcon } from 'assets/icons/tail-spin.svg';
import { ReactComponent as AlertTriangle } from 'assets/icons/alert-triangle.svg';

export interface Props {
  userId: number;
  onCancel: () => any;
  deleteCollaborator: (
    userId: number,
    isSimple: boolean,
    email: string,
    oldEmail: string,
  ) => any;
  deleting: boolean;
  oldMail: string;
}
export default memo(function DeleteCollaboratorForm({
  userId,
  onCancel,
  deleteCollaborator,
  deleting,
  oldMail,
}: Props) {
  const [simpleDelete, setSimpleDelete] = useState<boolean>(true);

  const handleDeleteCollaborator = (isSimple: boolean, email: string = '') => {
    deleteCollaborator(userId, isSimple, email, oldMail);
  };
  const handleChangeInputRadio = (deleteType: string) => {
    if (deleteType == 'simpleDelete') {
      setSimpleDelete(true);
    } else {
      setSimpleDelete(false);
    }
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        deleteType: '',
        email: '',
      }}
      validationSchema={Yup.object().shape({
        deleteType: Yup.string().label(t('firstNameRequired')),
        email: simpleDelete
          ? Yup.string()
          : Yup.string().email(t('invalidEmail')).required(t('emailRequired')),
      })}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        handleDeleteCollaborator(simpleDelete, values.email);
      }}
    >
      {({
        isSubmitting,
        isValidating,
        handleSubmit,
        values,
        errors,
        touched,
      }: any) => (
        <Form onSubmit={handleSubmit} className={styles.delete_form}>
          <div style={{ marginLeft: '4%' }}>
            <div className={styles.modal_title}>
              {t('Do you really want')}
              <span style={{ fontWeight: 'bold' }}> {t('delete')} </span>{' '}
              {t('this person on your list')}
            </div>
            <br />
            <div className="grid-x">
              <div className="small-12 align-stretch p-r-xs">
                <label>
                  <Field
                    type="radio"
                    name="deleteType"
                    value="simpleDelete"
                    onClick={() => handleChangeInputRadio('simpleDelete')}
                  />
                  <span
                    style={{
                      marginLeft: '4%',
                      fontSize: '16px',
                      marginBottom: '-5%',
                    }}
                    className={styles.modal_title}
                  >
                    {t('No longer part of the company')}
                  </span>
                </label>
              </div>
            </div>
            <div className="grid-x" style={{ marginTop: '2%' }}>
              <div className="small-12 align-stretch p-r-xs">
                <label>
                  <Field
                    type="radio"
                    name="deleteType"
                    value="deleteAndUpdate"
                    onClick={() => handleChangeInputRadio('deleteAndUpdate')}
                  />
                  <span
                    style={{
                      marginLeft: '4%',
                      fontSize: '16px',
                      marginBottom: '-5%',
                    }}
                    className={styles.modal_title}
                  >
                    {t('Part of another company with a new address mail:')}
                  </span>
                </label>
              </div>
            </div>
            <div className="grid-x">
              <div className="small-12 align-stretch p-r-xs">
                <TTPInputField
                  customizeError={true}
                  autoComplete="new-password"
                  disabled={simpleDelete}
                  theme={'redOrange'}
                  name="email"
                  className={
                    simpleDelete
                      ? styles.input_email_disabled
                      : styles.input_email
                  }
                  style={{
                    border:
                      !simpleDelete &&
                      errors.email &&
                      touched.email &&
                      '1px solid #FE3745',
                  }}
                  placeholder={t('Type the new email address here ...')}
                />
                {!simpleDelete && errors.email && touched.email ? (
                  <span className={styles.error_bloc}>
                    <AlertTriangle />
                    <span className={styles.error_message}>{errors.email}</span>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="grid-x"
            style={{ paddingTop: '13%', paddingBottom: '9%' }}
          >
            <div className="small-6 align-stretch p-r-xs">
              <button
                type={'reset'}
                className={classNames(styles.cancel_btn)}
                onClick={() => onCancel()}
              >
                <span>{t('CANCEL')}</span>
              </button>
            </div>

            <div className="small-6 align-right p-r-xs">
              <button
                className={classNames(styles.delete_btn)}
                type="submit"
                disabled={values.deleteType == ''}
              >
                {deleting && (
                  <div>
                    <SpinIcon className="tiny-loader" />
                  </div>
                )}
                <span>{t('yes, delete')}</span>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
});
