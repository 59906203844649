import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth } from 'store/Auth/actions';
import { useAppInfo } from 'hooks/useAppInfo';
import { useQuery } from 'hooks/useQuery';
import { logout } from 'utils';

const Logout = () => {
  const dispatch = useDispatch();
  const appInfo = useAppInfo();
  const query = useQuery();
  const gotoUrl = query.get('goto');

  useEffect(() => {
    dispatch(setAuth(null as any));
    logout(appInfo);
  }, []);

  if (gotoUrl?.length) {
    window.location.assign(encodeURI(gotoUrl));
    return null;
  }

  return <Redirect to="/" />;
};

export default Logout;
