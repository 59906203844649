import React, { memo } from 'react';
import { ModalState } from 'store/Modal/types';
import styles from './ModalAd.module.scss';
import classnames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import _ from 'i18n';
import { ModalDataAdType } from 'store/Modal/Ad/types';
import { bindExhibitorLink } from 'utils';
import urls from 'router/urls';
import { LEAD_TYPE } from 'store/PartnerLeads/types';

interface Props {
  modal: ModalState<ModalDataAdType>;
  onCloseModal: () => void;
}

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: '15px',
    top: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    width: '566px',
    height: '286px',
  },
};

export default memo(function ModalAd({ modal, onCloseModal }: Props) {
  const {
    data: { partnerId, eventId, type },
  } = modal;

  const history = useHistory();
  const location = useLocation();

  const exhibitorPage = bindExhibitorLink(
    urls.webinar.webinarExhibitors.current,
    eventId,
    String(partnerId),
    'tool=sessions',
  );

  const currentPage = `${location.pathname}${location.search}`;
  const showJoinBtn = currentPage != exhibitorPage;
  const joinExhibitor = () => {
    history.push(exhibitorPage);

    onCloseModal();
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div className={classnames('rmodal grid-y', styles.adModal)}>
        <CloseModal onClose={onCloseModal} />
        <div className={classnames(styles.circle, styles[LEAD_TYPE[type]])}>
          <CheckMarkIcon fill="#E39F00" />
        </div>
        <h3 className="m-b-m m-t-m">{_('Your request has been sent')}</h3>
        <h4 className="m-b-l">{_('Nous prenons contact avec vous')}</h4>
        {showJoinBtn && (
          <button onClick={joinExhibitor} className={styles.join}>
            <span>{_("Rejoindre l'espace exposant")}</span>
          </button>
        )}
      </div>
    </Modal>
  );
});
