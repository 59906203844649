import React, { CSSProperties, memo, useRef, useState } from 'react';
import styles from './TTPSlider.module.scss';
import classNames from 'classnames';
import Slider, { Settings } from 'react-slick';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { Link } from 'react-router-dom';
import t from 'i18n';
import { AdvancedLayoutTheme } from 'components/Layout/AdvancedLayout/services';

interface Props {
  title?: string;
  titleClassName?: string;
  cards: React.ReactElement[];
  cell: number;
  cellToScroll: number;
  numberInTitle?: number;
  settings?: Settings;
  isLeftAlign?: boolean;
  layout?: 'layout1' | 'layout2' | 'layout3' | 'layout4';
  theme?: AdvancedLayoutTheme;
  explore?: string;
  exploreClassName?: string;
  classNameActions?: string;
  arrowsClassName?: string;
  showArrows?: boolean;
  isMainSlide?: boolean;
  arrowsStyle?: CSSProperties;
}

export default memo(
  ({
    title,
    cards,
    settings = {},
    cell,
    cellToScroll,
    isLeftAlign,
    layout,
    explore,
    exploreClassName,
    classNameActions,
    titleClassName,
    arrowsClassName,
    theme = 'blue',
    numberInTitle,
    showArrows,
    isMainSlide,
    arrowsStyle,
  }: Props) => {
    const slider = useRef<Slider>(null);
    const [slideIndex, setSlideIndex] = useState(0);

    const total = cards.length;
    const totalSlides = settings.infinite
      ? Math.ceil(total / cellToScroll)
      : Math.ceil(Math.max(total - cell, 0) / cellToScroll) + 1;

    const handleGoBack = () => {
      if (slider.current) {
        slider.current.slickPrev();
      }
    };

    const handleGoNext = () => {
      if (slider.current) {
        slider.current.slickNext();
      }
    };

    const handleGoTo = (index: number) => {
      if (slider.current) {
        slider.current.slickGoTo(index);
      }
    };

    const renderDots = () => {
      if (total <= cell) {
        return null;
      }

      return [...Array(totalSlides).keys()].map((value, index) => (
        <span
          onClick={() => handleGoTo(index * cellToScroll)}
          className={classNames(
            index * cellToScroll === slideIndex && styles.active,
          )}
          key={`dot-${index}`}
        />
      ));
    };

    const renderBackButton = (className?: string) => {
      return (
        <span
          onClick={handleGoBack}
          className={classNames(
            styles.arrow_button,
            arrowsClassName,
            className,
          )}
          style={arrowsStyle}
        >
          <ArrowRightIcon
            className="rl180"
            fill="#29394D"
            width="16"
            height="16"
          />
        </span>
      );
    };

    const renderNextButton = () => (
      <span
        className={classNames(styles.arrow_button, arrowsClassName)}
        style={arrowsStyle}
        onClick={handleGoNext}
      >
        <ArrowRightIcon fill="#29394D" width="16" height="16" />
      </span>
    );

    const renderNavButtons = () => {
      if (total <= cell) {
        return null;
      }

      return (
        <div className={styles.navButtons}>
          {renderBackButton('m-r-xs')}
          {renderNextButton()}
        </div>
      );
    };

    const renderTopActions = () => {
      if (!layout) {
        return;
      }

      if (layout == 'layout4') {
        return null;
      }

      if (layout === 'layout1') {
        return (
          <>
            <hr className={styles.separator} />
            <div className="flex-container align-justify align-middle p-x-s">
              {renderNavButtons()}
              {explore && (
                <Link
                  to={explore}
                  className={classNames(styles.mainAction, exploreClassName)}
                >
                  {t('Explore')}
                </Link>
              )}
            </div>
            <hr className={styles.separator} />
            <div
              className={classNames(
                styles.dots,
                styles.dots_padding,
                styles[theme],
              )}
            >
              {renderDots()}
            </div>
          </>
        );
      }

      if (layout === 'layout2') {
        return (
          <>
            <div className="flex-container align-justify align-middle">
              <div className={classNames(styles.dots, styles[theme])}>
                {renderDots()}
              </div>
              {renderNavButtons()}
            </div>
            <hr className={classNames(styles.separator, 'no-margin--right')} />
          </>
        );
      }

      if (layout === 'layout3') {
        return (
          <div className="flex-container align-justify align-middle">
            {title && (
              <div className={classNames(styles.title, titleClassName)}>
                {numberInTitle && numberInTitle + ' '}
                {title}
              </div>
            )}
            <div
              className={classNames(
                'flex-container align-right align-middle m-b-s m-l-auto',
                classNameActions,
              )}
            >
              <div className={classNames(styles.dots, styles[theme], 'm-r-s')}>
                {renderDots()}
              </div>
              {renderNavButtons()}
              {explore && (
                <Link
                  to={explore}
                  className={classNames(
                    styles.mainAction,
                    exploreClassName,
                    'm-l-s',
                  )}
                >
                  {t('Explore')}
                </Link>
              )}
            </div>
          </div>
        );
      }
    };

    const renderBottomActions = () => {
      if (layout !== 'layout4') {
        return null;
      }

      if (total <= cell) {
        return null;
      }

      if (layout === 'layout4') {
        return (
          <div
            className={classNames(
              'flex-container align-center align-middle m-t-s',
              classNameActions,
            )}
          >
            {renderBackButton()}
            <div className={classNames(styles.dots, styles[theme], 'm-x-m')}>
              {renderDots()}
            </div>
            {renderNextButton()}
          </div>
        );
      }

      return null;
    };

    /*const handleClickInsideSlider = (event: any) => {
      // event = Mouse click event.
      let rect = event.target.getBoundingClientRect();
      let x = event.clientX - rect.left;
      let y = event.clientY - rect.top;
      if (x > 200) {
        handleGoNext();
      } else {
        handleGoBack();
      }
    };*/
    const showDots = layout ? false : settings.dots ?? true;
    const defaultSettings = {
      infinite: false,
      arrows: false,
      ...settings,
      dots: showDots,
      dotsClass: classNames(
        styles.slick_dots,
        'slick-dots',
        settings.dotsClass,
      ),
      slidesToShow: cell,
      slidesToScroll: cellToScroll,
      className: classNames(
        settings.className,
        showDots && 'm-b-s',
        isLeftAlign && styles.leftAlignSlick,
      ),
      beforeChange: (current: number, next: number) => setSlideIndex(next),
    };

    return (
      <div
        style={{
          minHeight: '0px', // Hack to fix react-slick issue with flex-box
          minWidth: '0px',
        }}
      >
        {renderTopActions()}
        <Slider {...defaultSettings} ref={slider}>
          {cards.map((card) => {
            return (
              <div
                key={card.key}
                onClick={isMainSlide ? handleGoNext : undefined}
              >
                {card}
              </div>
            );
          })}
        </Slider>
        {renderBottomActions()}
      </div>
    );
  },
);
