import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import s from './Button.module.scss';
import cn from 'classnames';
import { ColorType, SizeType, VariantType } from './services';

interface Props {
  icon: ReactNode;
  variant?: VariantType;
  size?: SizeType;
  color?: ColorType;
  disableIconStyle?: boolean;
  to?: string;
  className?: string;
  onClick?: (e?: any) => void;
}

const IconButton = ({
  variant = 'contained',
  size = 'small',
  color = 'blue',
  to,
  icon,
  disableIconStyle,
  className,
  onClick,
}: Props) => {
  const wrapperClassNames = cn(
    className,
    s.wrapper,
    s.icon_only,
    s[size],
    s[color as string],
    s[variant],
  );

  const renderButtonContent = () => (
    <div className={cn(s.icon, !disableIconStyle && s.customized)}>{icon}</div>
  );

  return to ? (
    <Link className={wrapperClassNames} to={to}>
      {renderButtonContent()}
    </Link>
  ) : (
    <button className={wrapperClassNames} onClick={onClick}>
      {renderButtonContent()}
    </button>
  );
};

export default IconButton;
