import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import s from './EventCard.module.scss';
import t from 'i18n';
import {
  formatDateFromTo,
  getByLanguage,
  getCroppedImageUrl,
  isEventLive,
  prepareS3Url,
  speakersEventToString,
  isEventPast,
  getEventNbMinutes,
  calculatePlayProgressTime,
  playProgressTime,
  isEventUpcoming,
} from 'utils';
import { openModal } from 'store/Modal/actions';
import { Event } from 'store/Events/types';
import { Language } from 'store/types';
import Shave from 'components/Common/Shave';
import Chip from 'components/ui/Chip';
import EventCardActions from 'components/EventCardActions';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { useCountdown } from '../../hooks/useCountDown';
import ProgressBar from 'components/ui/ProgressBar';
import { isEmpty } from 'lodash';
import { ReactComponent as TrendingIcon } from 'assets/icons/trending.svg';
import cn from 'classnames';

interface Props {
  event: Event;
  language: Language;
  isFullWidth?: boolean;
}

export const EventCard = ({ event, language, isFullWidth }: Props) => {
  const dispatch = useDispatch();
  const { startDateTime, endDateTime } = event;
  const { minutes: diffMinutesWithNow } = useCountdown(startDateTime, 0);

  const name = getByLanguage(event, 'name', language) ?? '';
  const urlBanner = getByLanguage(event, 'urlBanner', language) ?? '';
  const banner = getCroppedImageUrl(urlBanner, undefined, 280);
  const bannerUrl = isEmpty(banner)
    ? '/img/event-banner-fallback.png'
    : prepareS3Url(banner);
  const speakersLabel = speakersEventToString(event);
  const isLive = isEventLive(event as Event);
  const isExpired = isEventPast(event);
  const dateHelper = formatDateFromTo(
    startDateTime ?? '',
    endDateTime ?? '',
    language,
  );
  const isUpcomming = isEventUpcoming(event);

  const watchedTime = event?.playProgress ?? 0;
  const eventTime = getEventNbMinutes(event);
  const isFullWatch = event?.fullWatch ?? 0;
  const playProgress = playProgressTime(
    event?.playProgress,
    eventTime,
    isFullWatch,
  );
  const EventPlayProgress = calculatePlayProgressTime(
    eventTime,
    event?.playProgress,
    isFullWatch,
  );
  const [isCalendarDropdownVisible, setCalendarDropdownVisible] = useState(
    false,
  );

  const renderPlayProgress = () => {
    if (EventPlayProgress > 0 && !isLive && !isUpcomming) {
      return (
        <ProgressBar
          progress={EventPlayProgress}
          showProgressNumber={false}
          color={'linear-gradient(90.02deg, #06D9B1 0%, #18A0FB 99.98%)'}
          width={'100%'}
          height={'4px'}
          style={{ background: '#B2BCC6' }}
          className={s.playProgress}
          isEventWatch={true}
        />
      );
    }

    return null;
  };

  const handleOpenModal = () => {
    dispatch(openModal('WATCH', { data: event }));
  };

  const renderChips = () => {
    const label =
      EventPlayProgress > 0 && !isLive && !isUpcomming
        ? `${playProgress} sur ${eventTime} min`
        : `${eventTime} min`;

    if (isLive) {
      return (
        <Fragment>
          <Chip label={t('ongoing_now')} color="coralRed" />
          <Chip
            label={t('in_live_since').replace(':minutes', diffMinutesWithNow)}
            isLowercase
            isTransparent
          />
        </Fragment>
      );
    }

    if (isExpired) {
      return (
        <Fragment>
          <Chip label={t('Replay')} isTransparent />
          <Chip label={label} isLowercase isTransparent />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Chip label={t('LIVE')} isTransparent />
        <Chip label={label} isLowercase isTransparent />
      </Fragment>
    );
  };

  return (
    <div
      className={cn(s.card_wrapper, isFullWidth && s.full_width)}
      onClick={handleOpenModal}
      onMouseEnter={() => setCalendarDropdownVisible(true)}
      onMouseLeave={() => setCalendarDropdownVisible(false)}
    >
      <div className={s.card}>
        <div
          className={s.card_header}
          style={{
            backgroundImage: `url(${bannerUrl})`,
          }}
        >
          {!isEmpty(event.clientData?.avatarUrl) && (
            <div className={s.logoWrapper}>
              <span
                style={{
                  backgroundImage: `url(${event.clientData?.avatarUrl})`,
                }}
              />
            </div>
          )}
          {event.eventClassificationRank && (
            <div className={s.trendBadge}>
              <TrendingIcon
                width="34"
                height="46"
                fill="#29394D"
                className={cn('m-r-xs')}
              />
              <div className={s.trending}>
                <h3>TOP</h3>
                <h2>{event.eventClassificationRank}</h2>
              </div>
            </div>
          )}

          {/* <div>
            <span>Left</span>
            <span>Right</span>
          </div> */}
          <div>{renderChips()}</div>
          <div>{renderPlayProgress()}</div>
        </div>

        <div className={s.card_content}>
          <Shave as={'h3'} className={s.title} maxHeight={45}>
            {name}
          </Shave>
          <div className={s.info}>
            <h5 className={s.speakers}>{speakersLabel}</h5>
            <div className={s.live_info}>
              <CalendarIcon
                width="14"
                height="14"
                fill="#fff"
                className="m-r-xs"
              />
              <span>
                {`${t('In live')} : `}
                <time dateTime={startDateTime}>{dateHelper}</time>
              </span>
            </div>
          </div>
        </div>

        <div className={s.card_footer}>
          <EventCardActions
            event={event}
            language={language}
            isCalendarDropdownVisible={isCalendarDropdownVisible}
          />
        </div>
      </div>
    </div>
  );
};

EventCard.Fetching = ({ isFullWidth }: { isFullWidth?: boolean }) => (
  <div className={cn(s.card_fetching_wrapper, isFullWidth && s.full_width)}>
    <div className={s.card_header} />
    <div className={s.card_content}>
      <div className={s.title} />
      <div className={s.speakers} />
      <div className={s.live_info} />
    </div>
  </div>
);

export default EventCard;
