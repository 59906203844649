import { SetAppInfoAction, SET_APP_INFO, AppInfoData } from './types';

export const setAppInfo = (appInfo: AppInfoData): SetAppInfoAction => {
  localStorage.setItem('appInfo', JSON.stringify(appInfo));

  return {
    type: SET_APP_INFO,
    appInfo,
  };
};

export default { setAppInfo };
