import React, { CSSProperties } from 'react';
import styles from './PriceLabel.module.scss';
import classNames from 'classnames';
import t from 'i18n';

type Size = 'large' | 'xlarge' | 'medium' | 'small';

interface Props {
  price: React.ReactText;
  originalPrice?: React.ReactText | null;
  background?: string;
  className?: string;
  stylePrice?: CSSProperties;
  styleOriginalPrice?: CSSProperties;
  styleSeparator?: CSSProperties;
  isOriginalPriceLight?: boolean;
  size?: Size;
  separator?: string;
  showZeroAsFree?: boolean;
  isPremiumTicket?: boolean;
  hidePriceGap?: boolean;
}

export const PriceLabel = ({
  price,
  originalPrice,
  background,
  className,
  stylePrice,
  styleOriginalPrice,
  isOriginalPriceLight,
  size = 'large',
  separator,
  styleSeparator,
  showZeroAsFree,
  isPremiumTicket,
  hidePriceGap,
}: Props) => {
  return (
    <span className={classNames(styles.priceWrapper, className)}>
      <Price
        size={size}
        price={price}
        background={background}
        style={stylePrice}
        showZeroAsFree={showZeroAsFree}
        hidePriceGap={hidePriceGap}
      />
      {separator && (
        <span
          className={classNames(styles.separator, styles[size])}
          style={styleSeparator}
        >
          {separator}
        </span>
      )}
      {originalPrice && (
        <>
          <span
            className={
              isOriginalPriceLight
                ? isPremiumTicket
                  ? classNames(styles.priceOffPremium, styles[size])
                  : classNames(styles.priceOff, styles[size])
                : classNames(styles.price, styles.originalPrice, styles[size])
            }
            style={styleOriginalPrice}
          >
            {originalPrice}
            {!hidePriceGap ? <>&nbsp;</> : ''}€
          </span>
        </>
      )}
    </span>
  );
};

interface PriceProps {
  price: React.ReactText;
  showZeroAsFree?: boolean;
  background?: string;
  className?: string;
  style?: CSSProperties;
  size?: Size;
  hidePriceGap?: boolean;
}
export const Price = ({
  className,
  price,
  background,
  style,
  size = 'large',
  showZeroAsFree,
  hidePriceGap,
}: PriceProps) => (
  <span
    className={classNames(styles.price, styles[size], className)}
    style={{ background, ...style }}
  >
    {price == 0 && showZeroAsFree ? (
      <span>{t('Free')}</span>
    ) : (
      <span>
        {price}
        {!hidePriceGap ? <>&nbsp;</> : ''}€
      </span>
    )}
  </span>
);

export default PriceLabel;
