import {
  TTPDialogActionTypes,
  TTPDialogState,
  SET_TTP_DIALOG_SHOW,
  SET_TTP_DIALOG_CUSTOM_DATA,
} from './types';

const initialState = {
  showDialog: false,
  customData: [],
};

const reducer = (
  state: TTPDialogState = initialState,
  action: TTPDialogActionTypes,
) => {
  switch (action.type) {
    case SET_TTP_DIALOG_SHOW: {
      return { ...state, showDialog: action.showDialog };
    }
    case SET_TTP_DIALOG_CUSTOM_DATA: {
      return { ...state, customData: action.customData, showDialog: true };
    }
    default:
      return state;
  }
};

export default reducer;
