import React from 'react';
import styles from './PremiumFlag.module.scss';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import classNames from 'classnames';
import _ from 'i18n';

interface Props {
  className?: string;
}

export const PremiumFlag = ({ className }: Props) => {
  return (
    <span className={classNames(className, styles.premium)}>
      {_('included premium')}
    </span>
  );
};
export default PremiumFlag;
