import React, { Fragment, useEffect, useState } from 'react';
import t from 'i18n';
import {
  getByLanguage,
  getSlotReplayUrl,
  isEventLive,
  isEventPast,
  isEventUpcoming,
  onError,
} from 'utils';
import { Event, EventState } from 'store/Events/types';
import Button, { IconButton } from 'components/ui/Button';
import { ReactComponent as InfoIcon } from 'assets/icons/alert-circle-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as CalendarPlusIcon } from 'assets/icons/calendar-plus.svg';
import { ReactComponent as OngoingIcon } from 'assets/icons/ongoing-white.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play-filled.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg';
import { ReactComponent as ResumeIcon } from 'assets/icons/resume.svg';
import { Language } from 'store/types';
import { Tooltip } from 'antd';
import AddToCalendarBtn from 'components/Common/AddToCalendarBtn';
import { getEventIcsUrl } from 'utils';

interface Props {
  event: Event;
  userId?: number;
  saveEventFavorite: (eventId: number, userId: number) => any;
  deleteEventFavorite: (eventId: number, userId: number) => any;
  eventsFavoriteResource: EventState;
  eventsStatsResource: EventState;
  language: Language;
  isCalendarDropdownVisible?: boolean;
  setEventFavorite: (
    isEventFavoriteRemoved: boolean,
    isEventFavoriteAdded: boolean,
    eventFavoriteTab: string,
  ) => void;
}

const EventCardActions = ({
  event,
  eventsFavoriteResource: {
    items: favoriteEventsList,
    fetched: isFetched,
    fetching: isFetching,
  },
  eventsStatsResource: { eventFavoriteTab },
  userId,
  saveEventFavorite,
  deleteEventFavorite,
  setEventFavorite,
  language,
  isCalendarDropdownVisible,
}: Props) => {
  const isExpired = isEventPast(event);
  const isLive = isEventLive(event);
  const isUpcoming = isEventUpcoming(event);
  const [addedToMyList, setAddedToMyList] = useState(false);
  const [isEventIncludedInFavorites, setEventIncludedInFavorites] = useState(
    favoriteEventsList.filter((e) => e.id === event.id).length > 0,
  );
  const isShown = addedToMyList || isEventIncludedInFavorites;
  const liveLink =
    event.slotReplayUrls?.webinarUrlEn +
    (event.selectedDate
      ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
      : '');
  const icsFileUrl = getEventIcsUrl(event.id, userId ?? 0, language);

  const handleWatchOnClick = (e: React.MouseEvent, link?: string | null) => {
    e.stopPropagation();

    if (link) {
      window.parent.postMessage(
        {
          event: 'OPEN_WEBINAR',
          message: link,
        },
        '*',
      );
    }
  };

  const addToMyListAction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (userId) {
      const isSavedSuccessfully = await saveEventFavorite(event.id, userId);
      if (isSavedSuccessfully.value) {
        setTimeout(() => setEventFavorite(false, true, eventFavoriteTab), 200);
        setAddedToMyList(true);
      }
    } else {
      onError('', 'add_to_list_when_offline');
    }
  };

  const removeFromMyListAction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (userId) {
      const isDeletedSuccessfully = await deleteEventFavorite(event.id, userId);
      if (isDeletedSuccessfully.value) {
        setTimeout(() => setEventFavorite(true, false, eventFavoriteTab), 200);
        setAddedToMyList(false);
        setEventIncludedInFavorites(false);
      }
    }
  };

  useEffect(() => {
    if (
      isFetched &&
      favoriteEventsList.filter((e) => e.id === event.id).length
    ) {
      setAddedToMyList(true);
    } else if (!isFetching) {
      setEventIncludedInFavorites(false);
      setAddedToMyList(false);
    }
  }, [isFetched, event]);

  const renderMainAction = () => {
    if (
      event.playProgress &&
      event.playProgress > 0 &&
      event.slotReplayUrls &&
      !isLive &&
      !isUpcoming
    ) {
      const replayLink = getSlotReplayUrl(event.slotReplayUrls, language);
      return (
        <Button
          className="m-r-xs"
          title={t('Resume')}
          startIcon={<ResumeIcon />}
          isFullWidth
          style={{ minWidth: 'unset', padding: '7.4px' }}
          onClick={(e) =>
            handleWatchOnClick(
              e,
              replayLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
        />
      );
    }
    if (isExpired && event.slotReplayUrls) {
      const replayLink = getSlotReplayUrl(event.slotReplayUrls, language);

      return (
        <Button
          className="m-r-xs"
          title={t('Play')}
          startIcon={<PlayIcon />}
          isFullWidth
          style={{ minWidth: 'unset', padding: '7.3px' }}
          onClick={(e) =>
            handleWatchOnClick(
              e,
              replayLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
        />
      );
    }

    if (isLive) {
      return (
        <Button
          className="m-r-xs"
          title={t('Rejoin')}
          color="coralRed"
          startIcon={<OngoingIcon />}
          disableIconStyle
          isFullWidth
          style={{ minWidth: 'unset', padding: '7.4px' }}
          onClick={(e) =>
            handleWatchOnClick(
              e,
              liveLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
        />
      );
    }

    if (isUpcoming) {
      return (
        <Button
          className="m-r-xs"
          title={t('Rejoin')}
          color="blue"
          startIcon={<PlayIcon />}
          disableIconStyle
          isFullWidth
          style={{ minWidth: 'unset', padding: '7.4px' }}
          onClick={(e) =>
            handleWatchOnClick(
              e,
              liveLink +
                (event.selectedDate
                  ? `&selectedDate=${event.selectedDate}&eventDate=${event.startDateTime}`
                  : ''),
            )
          }
        />
      );
    }

    return (
      <Button
        className="m-r-xs"
        title={t('Details')}
        variant="WatchGrey"
        color="slateGrey"
        startIcon={<InfoIcon />}
        isFullWidth
      />
    );
  };

  return (
    <div className="flex-container align-middle ">
      {renderMainAction()}
      {isUpcoming ? (
        <Fragment>
          <Tooltip
            placement="top"
            title={isShown ? t('Delete from my list') : t('Add to my list')}
          >
            <span>
              <IconButton
                className="m-r-xs"
                variant={isShown ? 'contained' : 'WatchGrey'}
                color={isShown ? 'blue' : 'slateGrey'}
                icon={isShown ? <CheckCircleIcon /> : <PlusIcon />}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => {
                  isShown ? removeFromMyListAction(e) : addToMyListAction(e);
                }}
              />
            </span>
          </Tooltip>

          {!isExpired && (
            <AddToCalendarBtn
              icsFileUrl={icsFileUrl}
              isMulti={true}
              isWatch={true}
              size={1}
              isDropdownVisible={isCalendarDropdownVisible ?? false}
              optionsPosition={'topLeft'}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Button
            className="m-r-xs"
            title={t('Details')}
            variant="WatchGrey"
            color="slateGrey"
            startIcon={<InfoIcon />}
            isFullWidth
          />
          <Tooltip
            placement="top"
            title={isShown ? t('Delete from my list') : t('Add to my list')}
          >
            <span>
              <IconButton
                className="m-r-xs"
                variant={isShown ? 'contained' : 'WatchGrey'}
                color={isShown ? 'blue' : 'slateGrey'}
                icon={isShown ? <CheckCircleIcon /> : <PlusIcon />}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => {
                  isShown ? removeFromMyListAction(e) : addToMyListAction(e);
                }}
              />
            </span>
          </Tooltip>
        </Fragment>
      )}
    </div>
  );
};

export default EventCardActions;
