import { connect } from 'react-redux';

import SearchResultPage from './SearchResultPage';
import { fetchEvents } from 'store/Events/thunks';
import { RootState } from 'store/types';
import {
  setSearchEventPage,
  setSearchEventPageSize,
  initializeEventFilter,
} from 'store/Events/actions';
import { setGoBackCustomData, resetGoBack } from 'store/Params/GoBack/actions';
import { selectEvents } from 'store/Events/selectors';
import { selectUserPremiumStatus } from 'store/Auth';

const mapStateToProps = (state: RootState) => ({
  community: state.auth.navCommunity,
  filters: state.events.search.filters,
  events: selectEvents(state, 'SEARCH'),
  language: state.params.lng,
  eventIdsResource: state.event.eventIds,
  premiumOffersResource: state.cycle.premiumOffers,
  user: state.auth.user,
  userPremiumStatus: selectUserPremiumStatus(state),
});

const mapDispatchToProps = {
  fetchEvents: fetchEvents,
  setPage: setSearchEventPage,
  setPageSize: setSearchEventPageSize,
  initializeEventFilter,
  setGoBackCustomData,
  resetGoBack,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultPage);
