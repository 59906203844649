import React, { useEffect } from 'react';
import t from 'i18n';

interface Props {}

export const ModalCloser = (props: Props) => {
  useEffect(() => {
    const interval = setInterval(() => {
      window.parent.postMessage(
        {
          message: 'CLOSE_PAYMENT_MODAL',
        },
        '*',
      );
      window.parent.postMessage(
        {
          message: 'CLOSE_REGISTRATION_MODAL',
        },
        '*',
      );
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="align-center-middle flex-container"
      style={{ height: '100vh', color: '#29394d' }}
    >
      <h1>{t('Processing')}...</h1>
    </div>
  );
};

export default ModalCloser;
