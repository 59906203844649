import { Language } from 'store/types';
import { Address } from 'store/Guests/Invoicing/types';
import { EventOption } from 'interfaces/EventOption';
import { Cycle } from 'store/Cycle/types';
import { Event } from 'store/Events/types';

export interface Guest {
  id: number;
  startDateTime: string; // TODO refactoring (remove the startDateTime and endDateTime)
  endDateTime: string;
  step: number;
  user: number;
  registeredChoice: string;
  userInscriptionState: string;
  member: number | null;
  printed: boolean | null;
  fullName: string;
  cycleId: number | null;
  cycleTypeRegistration: number | null;
  forceParticipation: number | null;
  isBadgeForced: null;
  canModify?: boolean | 1 | 0 | '1' | '0';
  amountPaid: number | null;
  guestStatus: number;
  freeStatus: string | null;
  billingOrganization: string | null;
  billingWebOrganization: string | null;
  billingStreet: string | null;
  billingPostalCode: string | null;
  billingCountry: string | null;
  billingCompanyNumber: string | null;
  billingSubjectToVAT: boolean | 1 | 0 | '1' | '0';
  billingSignature: string | null;
  billingOrderNumber?: string | null;
  billingAddress2?: string | null;
  deliveryAddressId?: number;
  category: string | null;
  choice: number | null;
  coupons: string[] | null | '';
  coupon: string | null;
  barcode: string | null;
  registeringChoice: string | null;
  termsOfSales: boolean | 1 | 0 | '1' | '0';
  badgeUrl: string | null;
  badgePrintingDate?: string;
  internalComment: null;
  stepConfirmedByEmail: null;
  event: number;
  paymentUrl: string | null;
  dateEndOfReplay?: string | null;
  forcedDateEndOfReplay?: string | null;
  couponsDetails?: {
    [key: string]: Coupon;
  };
  orders?: Order[];
  invoicingData?: Address[];
  reductions?: Reduction[];
  registeredForEvents?: {
    [key: number]: {
      eventId: string | number;
      guestId: string | number;
      registered_choice: RegistredChoice;
    };
  };
  invitationData?: {
    invitedBy?: {
      nameFr: string;
      nameEn: string;
      nameNl: string;
    };
  };
  options?: EventOption[];
  premiumRegistrationStatus?: PREMIUM_REGISTRATION_STATUS;
  isUserNotRegisteredToActivePremium?: boolean;
  premiumRegisteredId?: number;
  isPremiumShared?: boolean;
  selectedDate?: string;
  response?: string;
  subscriptionFrequency?: string;
  subscriptionEndDate?: string;
  forcedSubscriptionEndDate?: string;
}

export interface UserInscriptionState {
  id: number;
  userId: number;
  uid: string;
  firstName?: string;
  lastName?: string;
  mainEmail: string;
  language: Language;
  gender: Gender;
  mainPhone: string;
  organization: string;
  function?: string;
  birthday?: string;
  membership?: {
    type: string;
    data: {
      status: string;
      studyDegree: string;
      school: string;
      studyTitle: string;
      dateFromStudy: string;
      certificationTitle: string;
      certificationNumber: string;
      certificationParam1: string;
    };
  };
}

export interface GuestAddress {
  billingCompanyNumber: string;
  billingOrganization: string;
  billingStreet: string;
  billingPostalCode: string; // '1360 Perwez';
  billingSubjectToVAT: '1' | '0';
  billingSignature: string;
  billingAddress2: string;
  billingOrderNumber: string;
  billingCountry: string;
}

export interface ShippingAddress {
  fullName: string;
  street: string;
  postalCode: string;
}

export interface RegistredChoice {
  coupon: string;
  cycle: number;
  member: number;
  plan: number;
  days?: [];
  includedOptions?: string[];
  options?: string[];
  rejectedOptionsModal?: boolean;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum REGISTRATION_STEPS {
  PERSONAL_DATA = 1,
  PLAN = 2,
  PAYMENT = 4,
  END = 5,
}

export enum PREMIUM_REGISTRATION_STATUS {
  NONE = 0,
  PENDING = 1,
  DONE = 2,
  GRACE_PERIOD = 3,
  CANCELED = 4,
}

export enum SUBSCRIPTION_FREQUENCY {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface Coupon {
  autoSlots: [];
  code: string;
  commentEn: string;
  commentFr: string;
  commentNl: string;
  cycleId: number;
  event: number;
  fiduciaryId: number;
  guestId: number;
  hostNameEn: string;
  hostNameFr: string;
  hostNameNl: string;
  id: number;
  level: number;
  modelDescriptionEn: string;
  modelDescriptionFr: string;
  modelDescriptionNl: string;
  modelNameEn: string;
  modelNameFr: string;
  modelNameNl: string;
  ownerId: number;
  packOrderId: number;
  reductions: CouponReduction[];
  usageCount: number;
  usageLimit: number;
}

export interface CouponReduction {
  id: number;
  type: COUPON_REDUCTION_TYPE;
  reductionMembre: number | string;
  reductionNonMembre: number | string;
  autoselect: '1' | '0';
}

export enum COUPON_REDUCTION_TYPE {
  EVENT = 'event',
  PLAN = 'plan',
  OPTION = 'option',
  CYCLE = 'cycle',
}

interface AppWorkloadType {
  type: string;
  guest: number;
  guestChoice: {
    cycle: number;
    plan: number;
    options: number[];
    includedOptions: number[];
    coupon: string;
    member: number;
    days: string[];
  };
  selectedDateId?: number;
}

export interface Order {
  id: number;
  assignments?: Assignment[];
  pdfPath?: string;
  status: ORDER_STATUS;
  documentDate: string;
  excludingVatAmount: number;
  includingVatAmount: number;
  vatAmount: number;
  vcs: string;
  comment: string;
  invoice?: Invoice;
  creditNotes?: CreditNote[];
  officialNumber: string;
  events?: Event | Cycle;
  appWorkload: AppWorkloadType;
  frequency: string;
  forcedRegistrationType?: string;
}

export enum ORDER_STATUS {
  OVER_PAID = 'OVER_PAID',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  UNDER_PAID = 'UNDER_PAID',
}

export enum ORDER_OPERATION_TYPE {
  BANK_TRANSFER = 'BANK_TRANSFER',
  MANUAL = 'MANUAL',
  OGONE = 'OGONE',
  PAYPAL = 'PAYPAL',
  MANGO_PAY = 'MANGO_PAY',
}

export interface Assignment {
  amount: number;
  dateTimeAssignment: string;
  documentId: number;
  id: number;
  ignored: boolean;
  // operation: {id: 2846, brand: "VISA", acceptance: "test123", payID: "3101118286", paymentMethod: "CreditCard",…}
  operationId: number;
  operationType: ORDER_OPERATION_TYPE;
}

export interface Invoice {
  id: number;
  includingVatAmount: number;
  excludingVatAmount: number;
  pdfPath?: string;
  status: ORDER_STATUS;
  officialNumber: string;
}
export interface CreditNote {
  id: number;
  comment: string;
  pdfPath: string;
  documentDate: string;
  officialNumber: string;
  vcs: string;
  excludingVatAmount: number;
  includingVatAmount: number;
  vatAmount: number;
  status: CREDIT_NOTE_STATUS;
}

export enum CREDIT_NOTE_STATUS {
  NOT_REFUNDED = 'NOT_REFUNDED',
  REFUNDED = 'REFUNDED',
}

export interface Reduction {
  excludingVatAmount: number;
  includingVatAmount: number;
  itemNameEn: string;
  itemNameFr: string;
  itemNameNl: string;
  type: REDUCTION_TYPE;
}
export enum REDUCTION_TYPE {
  EVENT_REGISTRATION = 'cycleEventRegistration',
}

export interface GuestState {
  processing: boolean;
  fetching: boolean;
  fetched: boolean;
  items: Guest[];
  error: any;
  coupons: Coupon[];
  checkedCoupon: Coupon | null;
  premiumGuestFetching: boolean;
  premiumGuestFetched: boolean;
  premiumGuest: Guest | null;
  premiumGuestStatus: number;
  addressUpdating: boolean;
  addressUpdated: boolean;
  nbResult: number;
}

export const FORCED_REGISTRATION_TYPE = 'FORCED';

export const RESET_GUESTS = 'RESET_GUESTS';
export const FETCH_GUESTS = 'FETCH_GUESTS';
export const FETCH_GUESTS_PENDING = 'FETCH_GUESTS_PENDING';
export const FETCH_GUESTS_FULFILLED = 'FETCH_GUESTS_FULFILLED';
export const FETCH_GUESTS_REJECTED = 'FETCH_GUESTS_REJECTED';

export const ACCEPT_REGISTRATION_GUESTS = 'ACCEPT_REGISTRATION_GUESTS';
export const ACCEPT_REGISTRATION_GUESTS_PENDING =
  'ACCEPT_REGISTRATION_GUESTS_PENDING';
export const ACCEPT_REGISTRATION_GUESTS_FULFILLED =
  'ACCEPT_REGISTRATION_GUESTS_FULFILLED';
export const ACCEPT_REGISTRATION_GUESTS_REJECTED =
  'ACCEPT_REGISTRATION_GUESTS_REJECTED';

export const DECLINE_REGISTRATION_GUESTS = 'DECLINE_REGISTRATION_GUESTS';
export const DECLINE_REGISTRATION_GUESTS_PENDING =
  'DECLINE_REGISTRATION_GUESTS_PENDING';
export const DECLINE_REGISTRATION_GUESTS_FULFILLED =
  'DECLINE_REGISTRATION_GUESTS_FULFILLED';
export const DECLINE_REGISTRATION_GUESTS_REJECTED =
  'DECLINE_REGISTRATION_GUESTS_REJECTED';

export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const FETCH_GUEST_DATA = 'FETCH_GUEST_DATA';
export const FETCH_GUEST_DATA_PENDING = 'FETCH_GUEST_DATA_PENDING';
export const FETCH_GUEST_DATA_FULFILLED = 'FETCH_GUEST_DATA_FULFILLED';
export const FETCH_GUEST_DATA_REJECTED = 'FETCH_GUEST_DATA_REJECTED';

export const FETCH_PREMIUM_GUEST = 'FETCH_PREMIUM_GUEST';
export const FETCH_PREMIUM_GUEST_PENDING = 'FETCH_PREMIUM_GUEST_PENDING';
export const FETCH_PREMIUM_GUEST_FULFILLED = 'FETCH_PREMIUM_GUEST_FULFILLED';
export const FETCH_PREMIUM_GUEST_REJECTED = 'FETCH_PREMIUM_GUEST_REJECTED';

export const FETCH_USER_PREMIUM_STATUS = 'FETCH_USER_PREMIUM_STATUS';
export const FETCH_USER_PREMIUM_STATUS_PENDING =
  'FETCH_USER_PREMIUM_STATUS_PENDING';
export const FETCH_USER_PREMIUM_STATUS_FULFILLED =
  'FETCH_USER_PREMIUM_STATUS_FULFILLED';
export const FETCH_USER_PREMIUM_STATUS_REJECTED =
  'FETCH_USER_PREMIUM_STATUS_REJECTED';

export const SAVE_GUEST_DATA = 'SAVE_GUEST_DATA';
export const SAVE_GUEST_DATA_PENDING = 'SAVE_GUEST_DATA_PENDING';
export const SAVE_GUEST_DATA_FULFILLED = 'SAVE_GUEST_DATA_FULFILLED';
export const SAVE_GUEST_DATA_REJECTED = 'SAVE_GUEST_DATA_REJECTED';

export const SAVE_GUEST = 'SAVE_GUEST';
export const SAVE_GUEST_PENDING = 'SAVE_GUEST_PENDING';
export const SAVE_GUEST_FULFILLED = 'SAVE_GUEST_FULFILLED';
export const SAVE_GUEST_REJECTED = 'SAVE_GUEST_REJECTED';

export const IMPORT_GUESTS = 'IMPORT_GUESTS';
export const IMPORT_GUESTS_PENDING = 'IMPORT_GUESTS_PENDING';
export const IMPORT_GUESTS_FULFILLED = 'IMPORT_GUESTS_FULFILLED';
export const IMPORT_GUESTS_REJECTED = 'IMPORT_GUESTS_REJECTED';

export const CHECK_COUPON = 'CHECK_COUPON';
export const CHECK_COUPON_PENDING = 'CHECK_COUPON_PENDING';
export const CHECK_COUPON_FULFILLED = 'CHECK_COUPON_FULFILLED';
export const CHECK_COUPON_REJECTED = 'CHECK_COUPON_REJECTED';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_PENDING = 'DELETE_ADDRESS_PENDING';
export const DELETE_ADDRESS_FULFILLED = 'DELETE_ADDRESS_FULFILLED';
export const DELETE_ADDRESS_REJECTED = 'DELETE_ADDRESS_REJECTED';

export const GENERATE_BADGE = 'GENERATE_BADGE';
export const GENERATE_BADGE_PENDING = 'GENERATE_BADGE_PENDING';
export const GENERATE_BADGE_FULFILLED = 'GENERATE_BADGE_FULFILLED';
export const GENERATE_BADGE_REJECTED = 'GENERATE_BADGE_REJECTED';

export const SEND_BADGE = 'SEND_BADGE';
export const SEND_BADGE_PENDING = 'SEND_BADGE_PENDING';
export const SEND_BADGE_FULFILLED = 'SEND_BADGE_FULFILLED';
export const SEND_BADGE_REJECTED = 'SEND_BADGE_REJECTED';

export const SEND_GUEST_CONFIRMATION = 'SEND_GUEST_CONFIRMATION';
export const SEND_GUEST_CONFIRMATION_PENDING =
  'SEND_GUEST_CONFIRMATION_PENDING';
export const SEND_GUEST_CONFIRMATION_FULFILLED =
  'SEND_GUEST_CONFIRMATION_FULFILLED';
export const SEND_GUEST_CONFIRMATION_REJECTED =
  'SEND_GUEST_CONFIRMATION_REJECTED';

export const FETCH_CERTIFICATES = 'FETCH_CERTIFICATES';
export const FETCH_CERTIFICATES_PENDING = 'FETCH_CERTIFICATES_PENDING';
export const FETCH_CERTIFICATES_FULFILLED = 'FETCH_CERTIFICATES_FULFILLED';
export const FETCH_CERTIFICATES_REJECTED = 'FETCH_CERTIFICATES_REJECTED';

export const GENERATE_CERTIFICATE = 'GENERATE_CERTIFICATE';
export const GENERATE_CERTIFICATE_PENDING = 'GENERATE_CERTIFICATE_PENDING';
export const GENERATE_CERTIFICATE_FULFILLED = 'GENERATE_CERTIFICATE_FULFILLED';
export const GENERATE_CERTIFICATE_REJECTED = 'GENERATE_CERTIFICATE_REJECTED';

export const CONFIRM_GUEST_OPTION = 'CONFIRM_GUEST_OPTION';
export const CONFIRM_GUEST_OPTION_PENDING = 'CONFIRM_GUEST_OPTION_PENDING';
export const CONFIRM_GUEST_OPTION_FULFILLED = 'CONFIRM_GUEST_OPTION_FULFILLED';
export const CONFIRM_GUEST_OPTION_REJECTED = 'CONFIRM_GUEST_OPTION_REJECTED';

export const REGISTER_PREMIUM_TO_EVENT = 'REGISTER_PREMIUM_TO_EVENT';
export const REGISTER_PREMIUM_TO_EVENT_PENDING =
  'REGISTER_PREMIUM_TO_EVENT_PENDING';
export const REGISTER_PREMIUM_TO_EVENT_FULFILLED =
  'REGISTER_PREMIUM_TO_EVENT_FULFILLED';
export const REGISTER_PREMIUM_TO_EVENT_REJECTED =
  'REGISTER_PREMIUM_TO_EVENT_REJECTED';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_PENDING = 'UPDATE_ADDRESS_PENDING';
export const UPDATE_ADDRESS_FULFILLED = 'UPDATE_ADDRESS_FULFILLED';
export const UPDATE_ADDRESS_REJECTED = 'UPDATE_ADDRESS_REJECTED';

export const CONFIRM_OPTIONS_REJECTION = 'CONFIRM_OPTIONS_REJECTION';
export const CONFIRM_OPTIONS_REJECTION_PENDING =
  'CONFIRM_OPTIONS_REJECTION_PENDING';
export const CONFIRM_OPTIONS_REJECTION_FULFILLED =
  'CONFIRM_OPTIONS_REJECTION_FULFILLED';
export const CONFIRM_OPTIONS_REJECTION_REJECTED =
  'CONFIRM_OPTIONS_REJECTION_REJECTED';

export interface ResetGuestsAction {
  type: typeof RESET_GUESTS;
}
export interface FetchGuestsAction {
  type:
    | typeof FETCH_GUESTS
    | typeof FETCH_GUESTS_PENDING
    | typeof FETCH_GUESTS_FULFILLED
    | typeof FETCH_GUESTS_REJECTED;
  payload: any;
}

export interface AcceptRegistrationGuestsAction {
  type:
    | typeof ACCEPT_REGISTRATION_GUESTS
    | typeof ACCEPT_REGISTRATION_GUESTS_PENDING
    | typeof ACCEPT_REGISTRATION_GUESTS_FULFILLED
    | typeof ACCEPT_REGISTRATION_GUESTS_REJECTED;
  payload: Promise<any>;
}

export interface DeclineRegistrationGuestsAction {
  type:
    | typeof DECLINE_REGISTRATION_GUESTS
    | typeof DECLINE_REGISTRATION_GUESTS_PENDING
    | typeof DECLINE_REGISTRATION_GUESTS_FULFILLED
    | typeof DECLINE_REGISTRATION_GUESTS_REJECTED;
  payload: Promise<any>;
}

export interface FetchGuestDataAction {
  type:
    | typeof FETCH_GUEST_DATA
    | typeof FETCH_GUEST_DATA_PENDING
    | typeof FETCH_GUEST_DATA_FULFILLED
    | typeof FETCH_GUEST_DATA_REJECTED;
  payload: any;
}

export interface FetchPremiumGuestAction {
  type:
    | typeof FETCH_PREMIUM_GUEST
    | typeof FETCH_PREMIUM_GUEST_PENDING
    | typeof FETCH_PREMIUM_GUEST_FULFILLED
    | typeof FETCH_PREMIUM_GUEST_REJECTED;
  payload: any;
}

export interface FetchUserPremiumStatusAction {
  type:
    | typeof FETCH_USER_PREMIUM_STATUS
    | typeof FETCH_USER_PREMIUM_STATUS_PENDING
    | typeof FETCH_USER_PREMIUM_STATUS_FULFILLED
    | typeof FETCH_USER_PREMIUM_STATUS_REJECTED;
  payload: any;
}

export interface SaveGuestDataAction {
  type:
    | typeof SAVE_GUEST_DATA
    | typeof SAVE_GUEST_DATA_PENDING
    | typeof SAVE_GUEST_DATA_FULFILLED
    | typeof SAVE_GUEST_DATA_REJECTED;
  payload: any;
}

export interface SaveGuestAction {
  type:
    | typeof SAVE_GUEST
    | typeof SAVE_GUEST_PENDING
    | typeof SAVE_GUEST_FULFILLED
    | typeof SAVE_GUEST_REJECTED;
  payload: any;
}

export interface ImportGuestsAction {
  type:
    | typeof IMPORT_GUESTS
    | typeof IMPORT_GUESTS_PENDING
    | typeof IMPORT_GUESTS_FULFILLED
    | typeof IMPORT_GUESTS_REJECTED;
  payload: any;
}

export interface checkCouponAction {
  type:
    | typeof CHECK_COUPON
    | typeof CHECK_COUPON_PENDING
    | typeof CHECK_COUPON_FULFILLED
    | typeof CHECK_COUPON_REJECTED;
  payload: any;
}

export interface deleteAddressAction {
  type:
    | typeof DELETE_ADDRESS
    | typeof DELETE_ADDRESS_PENDING
    | typeof DELETE_ADDRESS_FULFILLED
    | typeof DELETE_ADDRESS_REJECTED;
  payload: any;
}

export interface generateBadgeAction {
  type:
    | typeof GENERATE_BADGE
    | typeof GENERATE_BADGE_PENDING
    | typeof GENERATE_BADGE_FULFILLED
    | typeof GENERATE_BADGE_REJECTED;
  payload: any;
}

export interface SendBadgeAction {
  type:
    | typeof SEND_BADGE
    | typeof SEND_BADGE_PENDING
    | typeof SEND_BADGE_FULFILLED
    | typeof SEND_BADGE_REJECTED;
  payload: any;
}

export interface SendGuestConfirmationAction {
  type:
    | typeof SEND_GUEST_CONFIRMATION
    | typeof SEND_GUEST_CONFIRMATION_PENDING
    | typeof SEND_GUEST_CONFIRMATION_FULFILLED
    | typeof SEND_GUEST_CONFIRMATION_REJECTED;
  payload: any;
}

export interface FetchCertificatesAction {
  type:
    | typeof FETCH_CERTIFICATES
    | typeof FETCH_CERTIFICATES_PENDING
    | typeof FETCH_CERTIFICATES_FULFILLED
    | typeof FETCH_CERTIFICATES_REJECTED;
  payload: any;
}

export interface GenerateCertificateAction {
  type:
    | typeof GENERATE_CERTIFICATE
    | typeof GENERATE_CERTIFICATE_PENDING
    | typeof GENERATE_CERTIFICATE_FULFILLED
    | typeof GENERATE_CERTIFICATE_REJECTED;
  payload: any;
}

export interface confirmGuestOptionAction {
  type:
    | typeof CONFIRM_GUEST_OPTION
    | typeof CONFIRM_GUEST_OPTION_PENDING
    | typeof CONFIRM_GUEST_OPTION_FULFILLED
    | typeof CONFIRM_GUEST_OPTION_REJECTED;
  payload: any;
}

export interface registerPremiumToEvent {
  type:
    | typeof REGISTER_PREMIUM_TO_EVENT
    | typeof REGISTER_PREMIUM_TO_EVENT_PENDING
    | typeof REGISTER_PREMIUM_TO_EVENT_FULFILLED
    | typeof REGISTER_PREMIUM_TO_EVENT_REJECTED;
  payload: any;
}
export interface UpdateAddressAction {
  type:
    | typeof UPDATE_ADDRESS
    | typeof UPDATE_ADDRESS_PENDING
    | typeof UPDATE_ADDRESS_FULFILLED
    | typeof UPDATE_ADDRESS_REJECTED;
  payload: any;
}
export interface confirmOptionsRejectionAction {
  type:
    | typeof CONFIRM_OPTIONS_REJECTION
    | typeof CONFIRM_OPTIONS_REJECTION_PENDING
    | typeof CONFIRM_OPTIONS_REJECTION_FULFILLED
    | typeof CONFIRM_OPTIONS_REJECTION_REJECTED;
  payload: any;
}

export type GuestActionTypes =
  | FetchGuestsAction
  | FetchGuestDataAction
  | FetchPremiumGuestAction
  | SaveGuestDataAction
  | checkCouponAction
  | ResetGuestsAction
  | AcceptRegistrationGuestsAction
  | deleteAddressAction
  | DeclineRegistrationGuestsAction
  | generateBadgeAction
  | SendBadgeAction
  | SendGuestConfirmationAction
  | SaveGuestAction
  | ImportGuestsAction
  | FetchCertificatesAction
  | GenerateCertificateAction
  | FetchUserPremiumStatusAction
  | registerPremiumToEvent
  | UpdateAddressAction
  | confirmGuestOptionAction
  | confirmOptionsRejectionAction
  | FetchUserPremiumStatusAction;
