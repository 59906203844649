import urls from 'router/urls';
import { matchPath } from 'react-router-dom';
import _ from 'i18n';
import { reduce } from 'lodash';
import { Event, EVENT_TYPES } from 'store/Events/types';

export const GLOBAL_MARKET_TAB = 'gmarket';
export const PROGRAM_TAB = 'program';
export const MARKET_TAB = 'market';
export const CHAT_TAB = 'chat';
export const SESSIONS_TAB = 'sessions';
export const CONTACT_TAB = 'contact';
export const REGISTRATION_TAB = 'register';
export const EVALUATION_TAB = 'evaluation';

export const supportedTabs = [
  GLOBAL_MARKET_TAB,
  PROGRAM_TAB,
  MARKET_TAB,
  CHAT_TAB,
  SESSIONS_TAB,
  REGISTRATION_TAB,
  CONTACT_TAB,
  EVALUATION_TAB,
];

export type TabsType =
  | typeof GLOBAL_MARKET_TAB
  | typeof PROGRAM_TAB
  | typeof MARKET_TAB
  | typeof CHAT_TAB
  | typeof SESSIONS_TAB
  | typeof REGISTRATION_TAB
  | typeof CONTACT_TAB
  | typeof EVALUATION_TAB;

export const getPathsOfTabs = () => {
  const {
    webinarDocs,
    webinarExhibitors,
    webinarSessions,
    webinarStage,
    webinarAds,
    reception,
    speakers,
    // home,
    // webinarLive,
  } = urls.webinar;

  const { list, ...all } = webinarExhibitors;
  const { registration, ...allReception } = reception;

  const currentExhibitorRoutes = [...Object.values(all)];
  const listExhibitorRoute = list;

  const allExhibitorsRoutes = [...currentExhibitorRoutes, list];
  const allReceptionRoutes = [
    ...Object.values(registration),
    ...Object.values(allReception),
  ];

  return {
    [PROGRAM_TAB]: [
      // ...allReceptionRoutes,
      ...Object.values(webinarSessions),
      webinarStage,
    ],
    [GLOBAL_MARKET_TAB]: [listExhibitorRoute],
    [MARKET_TAB]: [...currentExhibitorRoutes],
    [CHAT_TAB]: [
      ...allReceptionRoutes,
      webinarStage,
      ...Object.values(webinarSessions),
      ...allExhibitorsRoutes,
      webinarDocs,
      webinarAds,
      speakers,
    ],
    [SESSIONS_TAB]: [...currentExhibitorRoutes],
    [CONTACT_TAB]: [webinarExhibitors.current],
    [CONTACT_TAB]: [webinarExhibitors.current],
    [REGISTRATION_TAB]: [...allReceptionRoutes],
    [EVALUATION_TAB]: [
      ...allReceptionRoutes,
      ...Object.values(webinarSessions),
      webinarStage,
      speakers,
      allExhibitorsRoutes,
    ],
  };
};

type Path = ReturnType<typeof getPathsOfTabs>;

export const getMatchedRoutes = (routes: string[][], pathName: string) => {
  return routes.filter((subRoutes: string[]) => {
    return !!matchPath(pathName, subRoutes);
  });
};

export const filterMatchedTabs = (
  paths: { [key: string]: string[] },
  pathName: string,
): { [key: string]: string[] } => {
  return reduce(
    paths,
    (acc, routes, index) => {
      if (!matchPath(pathName, routes)) return acc;
      return { ...acc, [index]: routes };
    },
    {},
  );
};

export const getTabs = (
  paths: Path,
  event: Event,
  params?: {
    showProgramActions?: boolean;
    isPartnerManager?: boolean;
    isRegistered?: boolean;
    summaryPack?: any;
    isEvaluationOpen?: boolean;
  },
) => {
  const {
    isPartnerManager,
    isRegistered,
    summaryPack,
    showProgramActions,
    isEvaluationOpen,
  } = params ?? {};

  const tabs = [
    {
      id: EVALUATION_TAB,
      allowedPaths: paths[EVALUATION_TAB],
      title: _('Evaluation'),
      isWide: true,
    },
    {
      id: PROGRAM_TAB,
      allowedPaths: paths[PROGRAM_TAB],
      title: _('My program'),
      isWide: true,
    },
    {
      id: REGISTRATION_TAB,
      allowedPaths: paths[REGISTRATION_TAB],
      title: _('My registration'),
      isWide: true,
    },
    {
      id: MARKET_TAB,
      allowedPaths: paths[GLOBAL_MARKET_TAB],
      title: _('Market'),
    },
    /*{
      id: CHAT_TAB,
      allowedPaths: paths[CHAT_TAB],
      title: _('Chat'),
    },*/
    {
      id: SESSIONS_TAB,
      allowedPaths: paths[SESSIONS_TAB],
      title: _('Sessions'),
    },
    {
      id: CONTACT_TAB,
      allowedPaths: paths[CONTACT_TAB],
      title: _('Contact'),
    },
  ];

  return tabs.filter((tab) => {
    if (tab.id === CONTACT_TAB && isPartnerManager) {
      return false;
    }

    if (tab.id === REGISTRATION_TAB && !isRegistered && !summaryPack) {
      return false;
    }

    if (tab.id === EVALUATION_TAB && !isEvaluationOpen) {
      return false;
    }
    if (
      tab.id === PROGRAM_TAB &&
      (event.type !== EVENT_TYPES.EVENT_FULL || !showProgramActions)
    ) {
      return false;
    }

    return true;
  });
};
