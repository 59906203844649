import React, { memo } from 'react';
import styles from './Session.module.scss';
import classNames from 'classnames';
import _ from 'i18n';
import { ReactComponent as SessionIcon } from 'assets/icons/session.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { SessionLayoutProps } from './types';
import { getByLanguage } from 'utils';
import { S3_FOLDER_URL } from 'config';
import { isEmpty } from 'lodash';
import { activityName } from 'store/Slots/types';
import Highlighter from 'react-highlight-words';

interface Props extends SessionLayoutProps {
  activityName?: activityName;
  showHeader?: boolean;
  renderExtras: () => JSX.Element | null;
}

export default memo(function Session({
  title,
  backgroundStyle,
  isSelected,
  activityName,
  speakers,
  partner,
  language,
  showHeader = true,
  isSoldOut,
  isWebinar,
  roomName,
  searchKeyword,
  renderExtras,
  renderActions,
  renderTime,
  onClick,
}: Props) {
  const activity = getByLanguage(activityName, 'name', language);

  const renderPartner = () => {
    if (partner) {
      const logoUrl = getByLanguage(partner, 'pictureUrl', language);
      const name = getByLanguage(partner, 'name', language);
      if (!isEmpty(logoUrl) && !isEmpty(name)) {
        return (
          <>
            <div className={styles.logoWrapper}>
              <span
                style={{
                  backgroundImage: `url(${S3_FOLDER_URL}/${
                    logoUrl?.split('/')[0] !== 'events-folder'
                      ? 'events-folder'
                      : ''
                  }${logoUrl})`,
                }}
              />
            </div>
            <div className={styles.title}>{name}</div>
          </>
        );
      }
    }
    return null;
  };

  const renderSlotMode = () => {
    if (isWebinar) {
      return (
        <div className={classNames(styles.block, 'm-t-xxs')}>
          <EarthIcon width="14" height="14" fill="#29394d" className="m-r-xs" />
          <span>{_('Webinar')}</span>
        </div>
      );
    }

    if (!isEmpty(roomName)) {
      return (
        <div className={classNames(styles.block, 'm-t-xxs')}>
          <MapPinIcon
            className="m-r-xs"
            width="14"
            height="14"
            fill="#29394d"
          />
          <span>{roomName}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      {/*
      {showHeader && partner && (
        <div className={styles.exhibitor}>{renderPartner()}</div>
      )}
*/}
      <div
        onClick={onClick}
        className={classNames(styles.session, isSelected && styles.hilighted)}
      >
        {isSoldOut && (
          <div className={styles.soldOut}>{_('session_sold_out')}</div>
        )}
        <div className={styles.sessionHeader}>
          <div className="flex-container">
            <div className={classNames(styles.block, styles.sessionTime)}>
              {renderTime()}
            </div>
            {!isEmpty(activity) && (
              <>
                <div className={styles.separator}>&#9679;</div>
                <div className={styles.block}>
                  <SessionIcon className="m-r-xs" width="16" stroke="#29394d" />
                  <span>{activity}</span>
                </div>
              </>
            )}
          </div>
          <div>{renderSlotMode()}</div>
        </div>
        <div className={styles.spacer} />
        <div style={{ height: '62px' }}>
          <h3>
            <Highlighter
              highlightClassName="marker"
              searchWords={[searchKeyword]}
              autoEscape={true}
              textToHighlight={title}
            />
          </h3>
          <h6 className="greetings">
            <Highlighter
              highlightClassName="marker"
              searchWords={[searchKeyword]}
              autoEscape={true}
              textToHighlight={speakers}
            />
          </h6>
        </div>
        <div className={styles.spacer} />

        <div className={styles.pictureBox}>
          {/* <div className={styles.label}>{_('Free')}</div> */}
          <div className={styles.actionWrapper}>{renderActions()}</div>
          {!isEmpty(backgroundStyle) && (
            <div
              className={styles.logo}
              style={{
                ...backgroundStyle,
              }}
            >
              {renderExtras()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
