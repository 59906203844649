import { FilterState } from './types';
import {
  Filter,
  SingleValueFilter,
  MultiValueFilter,
  SingleValueType,
  MultiValueType,
} from 'services/Filter';
import { SingleValueOperator, MultiValueOperator } from 'services/Filter/types';

export const selectFilter = (
  state: FilterState,
  filterProperty: string,
  filterOperator: string,
) =>
  state.filters.find(
    ({ property, operator }) =>
      filterProperty === property && filterOperator === operator,
  );

export const selectSingleValueFilter = (
  state: FilterState,
  filterProperty: string,
  filterOperator: SingleValueOperator,
): SingleValueFilter<SingleValueType> =>
  (state.filters.find(
    (filter) =>
      filter instanceof SingleValueFilter &&
      filterProperty === filter.property &&
      filterOperator === filter.operator,
  ) as SingleValueFilter<SingleValueType>) ??
  new SingleValueFilter<SingleValueType>(filterProperty, filterOperator, '');

export const selectMultiValueFilter = (
  state: FilterState,
  filterProperty: string,
  filterOperator: MultiValueOperator,
): MultiValueFilter<MultiValueType> =>
  (state.filters.find(
    (filter) =>
      filter instanceof MultiValueFilter &&
      filterProperty === filter.property &&
      filterOperator === filter.operator,
  ) as MultiValueFilter<MultiValueType>) ??
  new MultiValueFilter<MultiValueType>(filterProperty, filterOperator, []);

export const selectAllFilters = (state: FilterState, filterProperty: string) =>
  state.filters
    .filter(({ property }) => filterProperty === property)
    .reduce(
      (acc: { [key: string]: Filter[] }, filter) => ({
        ...acc,
        [filter.property]: [...(acc[filter.property] ?? []), filter],
      }),
      {},
    );
