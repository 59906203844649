import urls from 'router/urls';
import _ from 'i18n';

export const CHAT_TAB = 'chat';
export const REGISTRATION_TAB = 'register';
export type TabsType = typeof CHAT_TAB | typeof REGISTRATION_TAB;

export const getPathsOfTabs = () => {
  const { reception, events } = urls.cycle;

  const { registration, ...allReception } = reception;

  const allReceptionRoutes = [
    ...Object.values(registration),
    ...Object.values(allReception),
  ];

  return {
    [CHAT_TAB]: [...allReceptionRoutes, ...Object.values(events)],
    [REGISTRATION_TAB]: [...allReceptionRoutes],
  };
};

type Path = ReturnType<typeof getPathsOfTabs>;

export const getTabs = (paths: Path, isRegistered: boolean) => {
  const tabs = [
    {
      id: REGISTRATION_TAB,
      allowedPaths: paths[REGISTRATION_TAB],
      title: _('My registration'),
      isWide: true,
    },
    /*
    {
      id: CHAT_TAB,
      allowedPaths: paths[CHAT_TAB],
      title: _('Chat'),
    },
*/
  ];

  return tabs.filter((tab) => {
    if (tab.id === REGISTRATION_TAB && !isRegistered) {
      return false;
    }

    return true;
  });
};
