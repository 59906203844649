import _ from 'i18n';
import {
  COUPON_REDUCTION_TYPE,
  Coupon,
  Guest,
  GuestAddress,
  RegistredChoice,
} from 'store/Guests/types';
import { Address } from 'store/Guests/Invoicing/types';
import { parseJson, prepareGuestAddress } from 'utils';
import { EventPlanAndOptions } from 'store/Events/types';
import { PLAN_OPTION_TYPE, PLAN_STATUS } from 'interfaces/EventPlan';
import { isEmpty, isEqual, isNumber, unionWith } from 'lodash';
import { Language } from 'store/types';

export interface StepProps {
  nextStep: REGISTRATION_FULL_STEPS;
  previousStep: REGISTRATION_FULL_STEPS;
}

export enum REGISTRATION_FULL_STEPS {
  PERSONAL_DATA,
  PLAN,
  OPTIONS,
  ADDRESS,
  PAYMENT,
  END,
}
export interface GuestData extends GuestAddress {
  plan: number;
  options?: number[]; // "[421,423,428]"
  coupons?: string[];
  termsOfSales: '1' | '0';
  deliveryAddressId?: number;
}

export const renderliberFormLink = (language: Language) => {
  if (language == 'fr') {
    return 'https://www.liberform.be/fr_BE/aanvraag-login-premie';
  }

  if (language == 'nl') {
    return 'https://www.liberform.be/aanvraag-login-premie';
  }

  return 'https://www.liberform.be/fr_BE/aanvraag-login-premie';
};

export const findCouponWithHighestReduction = (coupons: Coupon[]) => {
  let maxReduction = -1;
  let couponWithHighestReduction = null;

  for (const coupon of coupons) {
    for (const reduction of coupon.reductions) {
      const reductionMembre = isNumber(reduction.reductionMembre)
        ? reduction.reductionMembre
        : parseInt(reduction.reductionMembre, 10);
      if (reductionMembre > maxReduction) {
        maxReduction = reductionMembre;
        couponWithHighestReduction = coupon;
      }
    }
  }

  return couponWithHighestReduction;
};

export const getInitialGuestData = (
  guest: Guest,
  addresses: Address[],
  plansAndOptions: EventPlanAndOptions | null,
  coupons?: Coupon[],
  selectedPlanId?: string | null,
  couponToSelect?: string | null,
): GuestData => {
  const registeringChoice: RegistredChoice | undefined = parseJson(
    guest.registeringChoice,
  );
  const registeredChoice: RegistredChoice | undefined = parseJson(
    guest.registeredChoice,
  );
  const { plans } = plansAndOptions ?? {};
  const addressData = prepareGuestAddress(guest, addresses);
  const firstCoupon = coupons?.[0];
  const guestCoupon = coupons?.find((coupon) => coupon.code === guest.coupon);
  const firstPlan = plans?.find(({ status }) => +status === PLAN_STATUS.ACTIVE);
  const recommendedPlan = plans?.find(
    ({ recommended, status }) =>
      +recommended > 0 && +status === PLAN_STATUS.ACTIVE,
  );
  let selectedPlan: number = 0;
  let options: string[] = [];

  const highestReductionCoupon =
    coupons && coupons.length > 1 && findCouponWithHighestReduction(coupons);

  let selectedCoupon = guestCoupon
    ? [guestCoupon.code]
    : firstCoupon
    ? [firstCoupon.code]
    : [];

  const defaultCoupons = coupons?.filter(({ code }) => code === couponToSelect);

  const coupon =
    defaultCoupons && !isEmpty(defaultCoupons)
      ? coupons?.find(({ code }) => code == defaultCoupons[0].code)
      : selectedCoupon.length > 0
      ? coupons?.find(({ code }) => code == selectedCoupon[0])
      : undefined;

  const reductionsPlan = coupon?.reductions.find(
    ({ type, autoselect }) => type == 'plan' && autoselect == '1',
  );

  const reductionsOptions = coupon?.reductions.filter(
    ({ type, autoselect }) => type == 'option' && autoselect == '1',
  );

  const isPlanActive = (planId: number) =>
    plans?.some(
      ({ id, status }) => +id === planId && +status === PLAN_STATUS.ACTIVE,
    );

  if (selectedPlanId && +selectedPlanId > 0 && isPlanActive(+selectedPlanId)) {
    selectedPlan = +selectedPlanId;
  } else if (
    registeringChoice?.plan &&
    +registeringChoice.plan > 0 &&
    isPlanActive(+registeringChoice.plan)
  ) {
    selectedPlan = +registeringChoice?.plan;
  } else if (registeredChoice?.plan && +registeredChoice.plan > 0) {
    selectedPlan = +registeredChoice?.plan;
  } else if (reductionsPlan) {
    selectedPlan = +reductionsPlan?.id;
  } else if (recommendedPlan) {
    selectedPlan = +recommendedPlan.id;
  } else if (firstPlan) {
    selectedPlan = +firstPlan.id;
  }

  if (selectedPlan > 0) {
    if (
      registeringChoice &&
      registeringChoice.options &&
      Array.isArray(registeringChoice.options) &&
      registeringChoice.options.length > 0
    ) {
      options = registeringChoice.options;
    } else if (
      registeredChoice &&
      registeredChoice.options &&
      Array.isArray(registeredChoice.options) &&
      registeredChoice.options.length > 0
    ) {
      options = registeredChoice.options;
    } else if (reductionsOptions && Array.isArray(reductionsOptions)) {
      options = reductionsOptions.map((op) => op.id.toString()) ?? [];
    }

    const plan = plans?.find((id) => +id === selectedPlan);
    const planOptions =
      plan?.options
        .filter(({ type }) => +type === PLAN_OPTION_TYPE.INCLUDED)
        .map((op) => +op.option) ?? [];

    /** Remove included options */
    options = options.filter((op) => planOptions.indexOf(+op) === -1);
  }

  return {
    termsOfSales: guest.termsOfSales == 1 ? '1' : '0',
    ...addressData,
    deliveryAddressId: guest.deliveryAddressId,
    coupons: guestCoupon
      ? [guestCoupon.code]
      : coupons && coupons?.length > 1 && highestReductionCoupon
      ? [highestReductionCoupon.code]
      : firstCoupon
      ? [firstCoupon.code]
      : [],
    plan: selectedPlan,
    options: options.map((op) => +op),
  };
};

export const filterCoupons = (
  coupons: Coupon[],
  allowedCoupons: COUPON_REDUCTION_TYPE[],
  checkedCoupon?: Coupon | null,
) => {
  const filteredCoupons = coupons?.filter(
    ({ reductions }) =>
      reductions.some((item) => allowedCoupons.includes(item.type)) ||
      reductions.length == 0,
  );

  return checkedCoupon
    ? unionWith([checkedCoupon], filteredCoupons, isEqual)
    : filteredCoupons;
};
