import {
  TTP_API_URL,
  COMMUNITY_NAME_MAX_LENGTH,
  COMMUNITY_DEFAULT_LOGO_URL,
} from 'config';
import { slugify, addLandaSize } from 'utils';
import { Organization } from 'store/types';
import { bindLinkData } from './app';
import { URLS } from 'router';
import { EventAuthorization, User, USER_ROLE } from 'store/Auth/types';

export const getCommunityDisplayName = (community: Organization): string => {
  return (
    community.abbreviation ||
    (community.name.length <= COMMUNITY_NAME_MAX_LENGTH
      ? community.name
      : community.name.substring(0, COMMUNITY_NAME_MAX_LENGTH) + ' ...')
  );
};

export const getCommunityRoute = (community?: Organization | null): string => {
  if (!community) {
    return '';
  }

  const communityUrl = String(
    community.url ? community.url : slugify(community.name),
  ).trim();

  return bindLinkData(URLS.community, [
    { key: ':communityId', value: community.id },
    {
      key: ':communityUrl',
      value: communityUrl.length > 0 ? communityUrl : 'community',
    },
  ]);
};

export const getCommunityLogoUrl = (community: Organization) => {
  if (!community) {
    return '';
  }
  return community.avatarUrl
    ? addLandaSize(community.avatarUrl, 600)
    : community.avatarWebPath
    ? `${TTP_API_URL}/${community.avatarWebPath}`
    : COMMUNITY_DEFAULT_LOGO_URL;
};

export const getLoggedAs = (
  authorizations?: EventAuthorization[],
  userId?: number,
  communityId?: number,
) => {
  if (!userId) {
    return USER_ROLE.ROLE_GUEST;
  }

  if (!communityId || !authorizations) {
    return USER_ROLE.ROLE_USER;
  }

  const authorization = authorizations.find(
    ({ client }) => +client.id === +communityId,
  );

  return authorization?.role ?? USER_ROLE.ROLE_USER;
};
