import { Filter, MultiValueFilter, SingleValueFilter } from 'services/Filter';
import { EVENT_STATUS } from 'store/Events/types';
import { replaceSpecialChars } from 'utils';

export const prepareFilter = (
  filters: Filter[],
  idCommunities: (string | number)[],
) => {
  const _filters = filters.map((filter) => {
    if (filter.property === 'search') {
      const searchFilter = filter.clone();
      searchFilter.value = replaceSpecialChars(searchFilter.value ?? '');
      return searchFilter;
    }

    return filter;
  });

  if (idCommunities.length > 0) {
    _filters.push(new MultiValueFilter('client', 'in', idCommunities));
  }

  return [
    ..._filters,
    new SingleValueFilter('status', 'neq', EVENT_STATUS.INACTIVE),
  ];
};
