import React, { memo } from 'react';
import { ModalState } from 'store/Modal/types';
import styles from './ModalInfo.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import CloseModal from '../CloseModal';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ModalDataInfoType } from 'store/Modal/Info/types';
import classNames from 'classnames';

interface Props {
  modal: ModalState<ModalDataInfoType>;
  onCloseModal: () => void;
}

const customModalStyles = {
  content: {
    backgroundColor: '#fff',
    border: 'none',
    overflow: 'none',
    padding: '15px',
    top: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(41, 57, 77, 0.04)',
    zIndex: 999,
    width: '566px',
    height: '286px',
  },
};

export default memo(function ModalInfo({ modal, onCloseModal }: Props) {
  const {
    data: { title, description, mainAction, icon },
  } = modal;

  const renderMainAction = () => {
    if (!mainAction) {
      return null;
    }

    const {
      text,
      to,
      isExternal,
      openInNewPage,
      type,
      onClick,
      icon,
    } = mainAction;

    let params: any = {
      className: classNames(styles.action, styles[type ?? 'blue']),
      onClick: onCloseModal,
    };

    if (to) {
      if (openInNewPage) {
        params = { ...params, target: '_blank', rel: 'noopener noreferrer' };
      }

      if (isExternal) {
        return (
          <a href={to} {...params}>
            <span>{text}</span>
          </a>
        );
      } else {
        return (
          <Link to={to} {...params}>
            <span>{text}</span>
          </Link>
        );
      }
    }
    if (onClick) {
      return (
        <button
          {...params}
          onClick={(e) => {
            onClick(e);
            onCloseModal();
          }}
        >
          {icon && (
            <div className="flex-container align-middle m-r-s">{icon}</div>
          )}
          <span>{text}</span>
        </button>
      );
    }

    return null;
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={customModalStyles}
      contentLabel="Media Viewer"
    >
      <div className={classnames('rmodal grid-y', styles.infosModal)}>
        <CloseModal onClose={onCloseModal} />
        <div className={classnames(styles.circle)}>
          {icon ?? <LockIcon fill="#F1F2F4" />}
        </div>
        <h3 className="m-b-m m-t-m">{title}</h3>
        <h4 className="m-b-l">{description}</h4>
        {renderMainAction()}
      </div>
    </Modal>
  );
});
