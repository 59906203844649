import React, { Fragment, useEffect, useMemo, useState } from 'react';
import s from './ShoppingCart.module.scss';
import cn from 'classnames';
import t from 'i18n';
import { ReactComponent as CartIcon } from 'assets/icons/shopping-cart.svg';
import { Order } from 'store/Guests/types';
import { useLanguage } from 'hooks/useLanguage';
import OrderPreview from 'components/Common/OrderPreview';
import { useOuterClick } from 'hooks/useOuterClick';
import { useLocation } from 'react-router-dom';
import { TTPSort } from 'store/types';
import { Filter } from 'services/Filter';
import { User } from 'store/Auth/types';
import { isEmpty } from 'lodash';

interface Props {
  orders: Order[];
  fetching: boolean;
  cancelOrder: (guestId: number, orderId: number) => any;
  fetchUserOrders: (
    userId: number,
    filters?: Filter[],
    sort?: TTPSort[],
  ) => void;
  user?: User | null;
  contentClassName?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  badgeClassName?: string;
  isExpanded?: boolean;
}

const ShoppingCart = ({
  orders,
  fetching,
  cancelOrder,
  fetchUserOrders,
  contentClassName,
  wrapperClassName,
  user,
  isExpanded,
  labelClassName,
  badgeClassName,
}: Props) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const innerRef = useOuterClick(() => setIsCartOpen(!isCartOpen));
  const { pathname } = useLocation();
  const language = useLanguage();
  const cartOrders = useMemo(
    () =>
      orders.filter(({ events }) => {
        return !isEmpty(events);
      }),
    [orders],
  );

  useEffect(() => {
    setIsCartOpen(false);
  }, [pathname]);

  const renderCartOrders = (orders: Order[]) => {
    if (!orders.length) {
      return (
        <div className={s.empty_cart}>
          <CartIcon width="40" height="40" fill="#B2BCC6" />
          <div>{t('Your shopping cart is empty')}</div>
        </div>
      );
    }

    return orders.map((order, index) => {
      return (
        <Fragment key={index}>
          <OrderPreview
            order={order}
            language={language}
            setIsCartOpen={setIsCartOpen}
            onDelete={cancelOrder}
            fetchUserOrders={fetchUserOrders}
            user={user}
          />
          <hr />
        </Fragment>
      );
    });
  };

  return (
    <div className={cn(s.wrapper, wrapperClassName)}>
      <button
        className={cn(s.cart_btn, isExpanded && s.expanded)}
        onClick={() => setIsCartOpen(!isCartOpen)}
        style={{ backgroundColor: isCartOpen ? '#c7e8fe' : '' }}
        disabled={fetching}
      >
        <CartIcon
          width="20"
          height="20"
          fill={isCartOpen ? '#29394d' : '#C7E8FE'}
        />
        {!fetching && cartOrders.length > 0 && (
          <div
            className={cn(s.badge, isExpanded && s.expanded, badgeClassName)}
          >
            {cartOrders.length}
          </div>
        )}
        {isExpanded && (
          <span
            className={cn(s.label, labelClassName)}
            style={{ color: isCartOpen ? '#29394d' : '#C7E8FE' }}
          >
            {t('My cart')}
          </span>
        )}
      </button>
      {isCartOpen && !fetching && (
        <div ref={innerRef} className={cn(s.cart_content, contentClassName)}>
          {renderCartOrders(cartOrders)}
        </div>
      )}
    </div>
  );
};

export default ShoppingCart;
