import React, { useState } from 'react';
import styles from './GuestAddress.module.scss';
import cn from 'classnames';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import _ from 'i18n';
import { LayoutTheme } from 'components/Layout/services';
import { useDispatch } from 'react-redux';
import { onError } from 'utils';
import { Address as AddressInterface, ADDRESS_TYPE } from 'interfaces/Address';
import { Address } from './Address';
import AddShippingAddress from './AddAddress/AddShippingAddress';
import { saveAddress } from 'store/Address/thunks';

interface Props {
  addresses: AddressInterface[];
  selectedAddress?: AddressInterface;
  defaultOpenForm?: boolean;
  theme?: LayoutTheme;
  titleClassName?: string;
  title?: string;
  horizontalInputs?: boolean;
  onSelectAddress?: (addressId: number | null, reset?: boolean) => void;
  onOpenForm?: (isOpened: boolean) => void;
}

export const ShippingAddress = ({
  theme,
  addresses,
  selectedAddress,
  defaultOpenForm,
  titleClassName,
  title,
  horizontalInputs,
  onSelectAddress,
  onOpenForm,
}: Props) => {
  const dispatch = useDispatch<any>();
  const [showForm, toggleShowForm] = useState(defaultOpenForm);
  const showSwitcher = addresses.length > 0;

  const handleShowForm = (isOpen: boolean) => {
    toggleShowForm(isOpen);
    onOpenForm?.(isOpen);
  };

  const handleAddAddress = async (data: Partial<AddressInterface>) => {
    return dispatch(saveAddress({ ...data, type: ADDRESS_TYPE.PERSONAL }))
      .then((resp: any) => {
        onSelectAddress?.(resp?.value?.data?.id ?? 0);
        handleShowForm(false);
      })
      .catch(onError);
  };

  return (
    <div className={styles.guestAddress}>
      <div className={styles.header}>
        <div className={cn(styles.title, titleClassName)}>
          {title ?? _('Billing data')} :
        </div>
        {showSwitcher && (
          <span
            className={styles.navTo}
            onClick={() => {
              onSelectAddress?.(null, showForm);
              handleShowForm(!showForm);
            }}
          >
            <span>{showForm ? _('Existing addresses') : _('New address')}</span>
            <ArrowRightIcon className="m-l-xs" width="12px" fill="#6D7F92" />
          </span>
        )}
      </div>
      <div className="m-t-s">
        {showForm || addresses.length === 0 ? (
          <AddShippingAddress
            theme={theme}
            horizontalInputs={horizontalInputs}
            onSubmit={handleAddAddress}
            onCancel={() => handleShowForm(false)}
          />
        ) : (
          addresses.map((address, index) => (
            <div className="m-b-s" key={`${address.id}-${index}`}>
              <Address
                theme={theme}
                data={{ type: 'ADDRESS', address }}
                onClick={() => onSelectAddress?.(address.id)}
                isSelected={selectedAddress?.id == address.id}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ShippingAddress;
