export type SourceTokenState = Array<SourceToken>;

export interface SourceToken {
  sourceName: string;
  sourceToken: any;
}

export const PUSH_SOURCE_TOKEN = 'PUSH_SOURCE_TOKEN';
export const CLEAR_SOURCES_TOKENS = 'CLEAR_SOURCES_TOKENS';

export interface PushSourceTokenAction {
  type: typeof PUSH_SOURCE_TOKEN;
  sourceName: string;
  sourceToken: any;
}

export interface ClearSourcesTokensAction {
  type: typeof CLEAR_SOURCES_TOKENS;
}

export type SourceTokenActionTypes =
  | PushSourceTokenAction
  | ClearSourcesTokensAction;
