import React, { memo } from 'react';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {}

export default memo(function TTPForm({ children, className, ...props }: Props) {
  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
    if (
      (keyEvent.charCode || keyEvent.keyCode) === 13 ||
      keyEvent.key === 'Enter'
    ) {
      keyEvent.preventDefault();
    }
  }

  return (
    <form className={className} onKeyDown={onKeyDown} {...props}>
      {children}
    </form>
  );
});
