export const fetchArticles = (resp) => ({
  type: 'FETCH_ARTICLES',
  payload: resp,
});

export const resetArticles = (resp) => ({
  type: 'RESET_ARTICLES',
});

export default {
  fetchArticles,
  resetArticles,
};
