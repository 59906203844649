import { Address } from 'store/Guests/Invoicing/types';
import { Coupon, Order, Reduction, RegistredChoice } from 'store/Guests/types';

export interface Subscription {
  id: number;
  startDateTime: string;
  endDateTime: string;
  step: number;
  user: number;
  registeredChoice: string;
  userInscriptionState: string;
  member: number | null;
  printed: boolean | null;
  fullName: string;
  cycleId: number | null;
  cycleTypeRegistration: number | null;
  forceParticipation: number | null;
  isBadgeForced: null;
  amountPaid: number | null;
  guestStatus: number;
  freeStatus: string | null;
  billingOrganization: string | null;
  billingWebOrganization: string | null;
  billingStreet: string | null;
  billingPostalCode: string | null;
  billingCountry: string | null;
  billingCompanyNumber: string | null;
  billingSubjectToVAT: boolean | 1 | 0 | '1' | '0';
  billingSignature: string | null;
  billingOrderNumber?: string | null;
  billingAddress2?: string | null;
  category: string | null;
  choice: number | null;
  coupons: string[] | null | '';
  coupon: string | null;
  barcode: string | null;
  registeringChoice: string | null;
  termsOfSales: boolean | 1 | 0 | '1' | '0';
  badgeUrl: string | null;
  internalComment: null;
  stepConfirmedByEmail: null;
  event: number;
  paymentUrl: string | null;
  couponsDetails?: {
    [key: string]: Coupon;
  };
  orders?: Order[];
  invoicingData?: Address[];
  reductions?: Reduction[];
  registeredForEvents?: {
    [key: number]: {
      eventId: string | number;
      guestId: string | number;
      registered_choice: RegistredChoice;
    };
  };
  response?: string;
}

export interface SubscriptionState {
  processing: boolean;
  fetching: boolean;
  fetched: boolean;
  items: Subscription[];
  error: any;
  coupons: Coupon[];
  nbResult: number;
}

export const RESET_SUBSCRIPTIONS = 'RESET_SUBSCRIPTIONS';

export const FETCH_SUBSCRIPTION_DATA = 'FETCH_SUBSCRIPTION_DATA';
export const FETCH_SUBSCRIPTION_DATA_PENDING =
  'FETCH_SUBSCRIPTION_DATA_PENDING';
export const FETCH_SUBSCRIPTION_DATA_FULFILLED =
  'FETCH_SUBSCRIPTION_DATA_FULFILLED';
export const FETCH_SUBSCRIPTION_DATA_REJECTED =
  'FETCH_SUBSCRIPTION_DATA_REJECTED';

export const SAVE_SUBSCRIPTION_DATA = 'SAVE_SUBSCRIPTION_DATA';
export const SAVE_SUBSCRIPTION_DATA_PENDING = 'SAVE_SUBSCRIPTION_DATA_PENDING';
export const SAVE_SUBSCRIPTION_DATA_FULFILLED =
  'SAVE_SUBSCRIPTION_DATA_FULFILLED';
export const SAVE_SUBSCRIPTION_DATA_REJECTED =
  'SAVE_SUBSCRIPTION_DATA_REJECTED';

export const CHECK_SUBSCRIPTION_COUPON = 'CHECK_SUBSCRIPTION_COUPON';
export const CHECK_SUBSCRIPTION_COUPON_PENDING =
  'CHECK_SUBSCRIPTION_COUPON_PENDING';
export const CHECK_SUBSCRIPTION_COUPON_FULFILLED =
  'CHECK_SUBSCRIPTION_COUPON_FULFILLED';
export const CHECK_SUBSCRIPTION_COUPON_REJECTED =
  'CHECK_SUBSCRIPTION_COUPON_REJECTED';

export const DELETE_SUBSCRIPTION_ADDRESS = 'DELETE_SUBSCRIPTION_ADDRESS';
export const DELETE_SUBSCRIPTION_ADDRESS_PENDING =
  'DELETE_SUBSCRIPTION_ADDRESS_PENDING';
export const DELETE_SUBSCRIPTION_ADDRESS_FULFILLED =
  'DELETE_SUBSCRIPTION_ADDRESS_FULFILLED';
export const DELETE_SUBSCRIPTION_ADDRESS_REJECTED =
  'DELETE_SUBSCRIPTION_ADDRESS_REJECTED';

export interface ResetSubscriptionsAction {
  type: typeof RESET_SUBSCRIPTIONS;
}

export interface FetchSubscriptionDataAction {
  type:
    | typeof FETCH_SUBSCRIPTION_DATA
    | typeof FETCH_SUBSCRIPTION_DATA_PENDING
    | typeof FETCH_SUBSCRIPTION_DATA_FULFILLED
    | typeof FETCH_SUBSCRIPTION_DATA_REJECTED;
  payload: any;
}

export interface SaveSubscriptionDataAction {
  type:
    | typeof SAVE_SUBSCRIPTION_DATA
    | typeof SAVE_SUBSCRIPTION_DATA_PENDING
    | typeof SAVE_SUBSCRIPTION_DATA_FULFILLED
    | typeof SAVE_SUBSCRIPTION_DATA_REJECTED;
  payload: any;
}

export interface checkCouponAction {
  type:
    | typeof CHECK_SUBSCRIPTION_COUPON
    | typeof CHECK_SUBSCRIPTION_COUPON_PENDING
    | typeof CHECK_SUBSCRIPTION_COUPON_FULFILLED
    | typeof CHECK_SUBSCRIPTION_COUPON_REJECTED;
  payload: any;
}

export interface deleteAddressAction {
  type:
    | typeof DELETE_SUBSCRIPTION_ADDRESS
    | typeof DELETE_SUBSCRIPTION_ADDRESS_PENDING
    | typeof DELETE_SUBSCRIPTION_ADDRESS_FULFILLED
    | typeof DELETE_SUBSCRIPTION_ADDRESS_REJECTED;
  payload: any;
}

export type SubscriptionActionTypes =
  | FetchSubscriptionDataAction
  | SaveSubscriptionDataAction
  | checkCouponAction
  | ResetSubscriptionsAction
  | deleteAddressAction;
